import { ResultsObject } from '../../../../../../models/generalTypes'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../models/reportModels/reportGeneralTypes'
import { ReactElement } from 'react'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import IncludeOptionComponent from './IncludeOptionComponent'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  visibleElements?: ExecutiveSummaryVisibleElements
  sectionIndex?: number
  setShowAWADetails?: (showAWADetails: boolean) => void
  valueOfCaseParagraph?: boolean
  paragraphImg?: string
  paragraphText: ReactElement<any, any>
  sideTitle: string
  executiveSummaryVisibleElement: ExecutiveSummaryVisibleElementsEnum
  id: string
  showIncludeOption?: ExecutiveSummaryVisibleElementsEnum | undefined
  setShowIncludeOption?: (
    showIncludeOption: ExecutiveSummaryVisibleElementsEnum | undefined,
  ) => void
  handleIncludeExcludeElement?: (
    includeElementName: ExecutiveSummaryVisibleElementsEnum,
  ) => void
  reportWhitelabel?: ReportWhitelabel
}

export default function ExecutiveSummaryParagraph(props: Props) {
  return (
    <div
      className={
        props.visibleElements
          ? 'executiveSummaryParagraphContainer forReport'
          : 'executiveSummaryParagraphContainer'
      }
      onMouseEnter={
        props.visibleElements
          ? () =>
              props.setShowIncludeOption!(props.executiveSummaryVisibleElement)
          : undefined
      }
      onMouseLeave={
        props.visibleElements
          ? () => props.setShowIncludeOption!(undefined)
          : undefined
      }
      data-openreplay-obscured
      id={`executiveSummaryParagraphContainer-${
        props.executiveSummaryVisibleElement
      }${props.visibleElements ? '-forReport' : ''}`}
    >
      {props.visibleElements &&
      props.showIncludeOption === props.executiveSummaryVisibleElement ? (
        <IncludeOptionComponent
          includeElement={
            props.visibleElements[props.executiveSummaryVisibleElement]
          }
          includeElementName={props.executiveSummaryVisibleElement}
          handleIncludeExcludeElement={props.handleIncludeExcludeElement!}
        />
      ) : null}
      <div
        className={
          props.visibleElements
            ? props.visibleElements[props.executiveSummaryVisibleElement] ===
              false
              ? 'executiveSummarySideTitleContainer forReport fadeText'
              : 'executiveSummarySideTitleContainer forReport'
            : 'executiveSummarySideTitleContainer'
        }
      >
        <p
          className={
            props.visibleElements
              ? props.visibleElements[props.executiveSummaryVisibleElement] ===
                false
                ? 'executiveSummarySideTitle forReport fadeText'
                : 'executiveSummarySideTitle forReport'
              : 'executiveSummarySideTitle'
          }
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
            false,
            props.visibleElements
              ? props.visibleElements[props.executiveSummaryVisibleElement] ===
                false
                ? true
                : false
              : false,
          )}
          id={`executiveSummarySideTitle-${
            props.executiveSummaryVisibleElement
          }${props.visibleElements ? '-forReport' : ''}`}
        >
          {props.sideTitle}
        </p>
      </div>
      <div
        className={`executiveSummaryParagraph ${
          props.valueOfCaseParagraph ? 'valueOfCaseParagraph' : ''
        } ${props.visibleElements ? 'forReport' : ''}`}
        id={`${props.id}-Paragraph`}
      >
        {props.visibleElements === undefined &&
        props.paragraphImg !== undefined ? (
          <img
            src={props.paragraphImg}
            alt="valueOfCaseImg"
            className={`valueOfCaseImg`}
            id={`${props.id}-image`}
          />
        ) : null}
        <div
          className={`executiveSummaryParagraphText ${
            props.valueOfCaseParagraph ? 'valueOfCaseText' : ''
          } ${
            props.visibleElements
              ? props.visibleElements[props.executiveSummaryVisibleElement] ===
                false
                ? 'fadeText forReport'
                : 'forReport'
              : 'hasMarginLeft'
          }`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            props.visibleElements
              ? props.visibleElements[props.executiveSummaryVisibleElement] ===
                false
                ? true
                : false
              : false,
          )}
          id={`${props.id}-Text${props.visibleElements ? '-forReport' : ''}`}
          data-openreplay-obscured
        >
          {props.paragraphText}
        </div>
      </div>
    </div>
  )
}
