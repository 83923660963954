export class LegalFeesDistributionObject {
  id: number
  value: number
  ratioCoveredByMe: number
  ratioToCover: number
  range: number[]

  constructor(
    id: number,
    value: number,
    ratioCoveredByMe: number,
    ratioToCover: number,
    range: number[],
  ) {
    this.id = id
    this.value = value
    this.ratioCoveredByMe = ratioCoveredByMe
    this.ratioToCover = ratioToCover
    this.range = [...range]
  }

  static EmptyObject() {
    let tempObject = new LegalFeesDistributionObject(1, 0, 0, 0, [0, 0])
    return tempObject
  }

  static toJson(data: LegalFeesDistributionObject): any {
    return {
      id: data.id,
      value: data.value,
      ratioCoveredByMe: data.ratioCoveredByMe,
      ratioToCover: data.ratioToCover,
      range: [...data.range],
    }
  }

  static FromJson(data: any): LegalFeesDistributionObject {
    return new LegalFeesDistributionObject(
      data.id,
      data.value,
      data.ratioCoveredByMe,
      data.ratioToCover,
      [...data.range],
    )
  }
}
