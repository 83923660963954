import { useRecoilValue } from 'recoil'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { TreeClaim } from '../../../../../../../models/treeModels/treeClaim'
import removeImg from '../../../../../../../resources/images/201-deleteBucketWIthBorder.svg'
import InputText from '../../../../../../../Components/Inputs/InputText/InputText'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import { AmountSignum, PartySignum } from '../../../../../../../models/enums'
import { useXarrow } from 'react-xarrows'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  nodeSelected: boolean
  highlighted: boolean
  active: boolean
  startAnimation: boolean
  treeIndex: number
  nodeId: NodeId
  eventIndex: number
  showTreeInfo: boolean
  debugging: boolean
  eventId: EventId
  eventsInNode: number
  showDeleteEvent: boolean
  eventDetails: EventDetails
  handleDeleteEventClick: (e: any) => void
  handleChangeTreeEventDetail: (
    targetId: string,
    eventDetailKey: keyof EventDetails,
    value:
      | never
      | AmountSignum
      | PartySignum
      | string
      | number
      | boolean
      | undefined
      | Date,
  ) => void
}

export default function EventTitleContainer(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const updateXarrow = useXarrow()

  return (
    <>
      <div
        className={`treeEventTitleContainer ${
          props.nodeSelected || props.highlighted ? 'selected' : ''
        } ${props.active ? 'active' : ''} ${
          props.startAnimation ? 'fadeout' : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventTitleContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        {props.showTreeInfo && props.debugging ? (
          <p className="treeEventNumber">
            {Object.keys(
              (
                scenarioSnapshot.currentSnapshot.claims[
                  props.treeIndex
                ] as TreeClaim
              ).treeDetails.events,
            ).map((eventId, index) => {
              if (props.eventId === eventId) {
                return index
              } else {
                return ''
              }
            })}
          </p>
        ) : null}
        {props.eventsInNode > 1 && props.showDeleteEvent ? (
          <>
            <div
              className="deleteTreeEventImgContainer"
              id={`deleteTreeEventImgContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              <img
                src={removeImg}
                alt="plus"
                className="deleteTreeEventImg"
                style={{ cursor: 'pointer' }}
                onClick={(e) => props.handleDeleteEventClick(e)}
                id={`deleteTreeEventImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              />
            </div>
          </>
        ) : null}
        <InputText
          label={getText('label-24', user.settings)}
          labelTextAttribute="label-24"
          id={`treeEventTitleInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          className="treeEventTitleInput"
          name={`treeEventTitleInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          width={212}
          onChange={(e: any) =>
            props.handleChangeTreeEventDetail(
              e.target.id,
              'eventTitle',
              e.target.value,
            )
          }
          value={props.eventDetails.eventTitle}
          smallField
          tabIndex={10 * props.eventIndex + 2}
          maxLength={48}
          multiline
          hideHelperText
          onFocus={() => updateXarrow()}
        />
      </div>
    </>
  )
}
