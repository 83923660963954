import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import disputedAmountInfoImg from '../../../../../resources/images/125-calculatedAmounts.svg'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'

import { formattedNumToString } from '../../../../../services/formatNum'
import { ClaimType } from '../../../../../models/enums'
import { userState } from '../../../../../states/UserState'
import { getText } from '../../../../../services/textFunctions'
import {
  findTotalClaimedAmount,
  findTotalCounterClaimedAmount,
} from '../../../../../services/disputedAmountFunctions'
import { TreeClaim } from '../../../../../models/treeModels/treeClaim'
import { logActivity } from '../../../../../services/commonFunctions'
import { freemiumState } from '../../../../../states/FreemiumState'
import Symbol from '../../../../../Components/symbol/Symbol'
import { krogerusColorsState } from '../../../../../states/KrogerusColorsState'

type Props = {
  calculateAllTrees: () => Promise<void>
}

export default function DisputedAmountInfo(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const [showHover, setShowHover] = useState(false)
  const [hasAllDisputedAmounts, sethasAllDisputedAmounts] = useState(
    scenarioSnapshot.currentSnapshot.claims.filter(
      (claim) =>
        claim.type === ClaimType.tree &&
        (claim as TreeClaim).totalClaimedAmount === undefined &&
        (claim as TreeClaim).totalCounterClaimedAmount === undefined,
    ).length === 0,
  )
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const totalClaimedAmount = findTotalClaimedAmount(
    scenarioSnapshot.currentSnapshot,
  )

  const totalCounterClaimedAmount = findTotalCounterClaimedAmount(
    scenarioSnapshot.currentSnapshot,
  )
  useEffect(() => {
    sethasAllDisputedAmounts(
      scenarioSnapshot.currentSnapshot.claims.filter(
        (claim) =>
          claim.type === ClaimType.tree &&
          !(claim as TreeClaim).totalClaimedAmount &&
          !(claim as TreeClaim).totalCounterClaimedAmount,
      ).length === 0,
    )
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.claims])

  return (
    <>
      <div
        className="disputedAmountInfoContainer"
        id="disputedAmountInfoContainer"
        onMouseLeave={() => setShowHover(false)}
        data-openreplay-obscured
      >
        <div
          className="disputedAmountInfoImgContainer"
          onMouseEnter={() => setShowHover(true)}
        >
          <img
            src={disputedAmountInfoImg}
            alt="disputedAmountInfoImg"
            className="disputedAmountInfoImg"
          />
        </div>

        {showHover ? (
          <div className="disputedAmountHoverContainer">
            {hasAllDisputedAmounts ? (
              <div className="disputedAmountColumn">
                <Symbol
                  symbolType={'client'}
                  targetId={'disputedAmountInfoTotalClaimAmount'}
                  targetType={'tileText'}
                />
                <div
                  id="disputedAmountInfoTotalClaimAmount"
                  className="disputedAmountInfoAmountContainer"
                >
                  <p
                    className={`disputedAmountInfoAmount ${
                      krogerusColors ? 'krogerus' : ''
                    }`}
                  >
                    {formattedNumToString(totalClaimedAmount, user.settings)}{' '}
                    {scenarioSnapshot.currentSnapshot.currency}
                  </p>
                </div>
                <Symbol
                  symbolType={'opposingParty'}
                  targetId={'disputedAmountInfoTotalCounterClaimAmount'}
                  targetType={'tileText'}
                />
                <div
                  id="disputedAmountInfoTotalCounterClaimAmount"
                  className="disputedAmountInfoAmountContainer"
                >
                  <p
                    className={`disputedAmountInfoAmount negative ${
                      krogerusColors ? 'krogerus' : ''
                    }`}
                  >
                    {formattedNumToString(
                      totalCounterClaimedAmount,
                      user.settings,
                      true,
                    )}{' '}
                    {scenarioSnapshot.currentSnapshot.currency}
                  </p>
                </div>
              </div>
            ) : (
              <div className="calculateAmountButtonContainer">
                <button
                  className="calculateAmountButton"
                  type="button"
                  onClick={() => {
                    props.calculateAllTrees()
                    //Mixpanel 16a (All)
                    logActivity(
                      freemium.isFreemium,
                      "Calculated disputed amount from 'Calculate Amount in Dispute' Button",
                    )
                  }}
                >
                  <p
                    className="calculateAmountsButtonText"
                    id="calculateAmountButtonText"
                    data-textattribute="button-22"
                  >
                    {getText('button-22', user.settings)}
                  </p>
                </button>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </>
  )
}
