import { useCallback, useEffect, useState } from 'react'
import logo from '../../resources/images/Eperoto-logo-blue.svg'
import {
  getFreemiumTopLeftForPopUp,
  getScaleForPopUp,
} from '../../services/popUpPositionFunctions'
import useWindowSize from '../../customHooks/useWindowSize'
import { getText } from '../../services/textFunctions'
import { useRecoilState } from 'recoil'
import { userState } from '../../states/UserState'
import InputText from '../../Components/Inputs/InputText/InputText'
import tickedImg from '../../resources/images/115-tickPurple.svg'
import freemiumImg from '../../resources/images/271-freemium.png'
import unTickedImg from '../../resources/images/018-untickedGrayBlue.svg'
import { CircularProgress } from '@mui/material'
import { freemiumLogin } from '../../freemiumServices/freemiumRequests'
import { User } from '../../models/user'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import {
  findHandlingErrorState,
  logActivity,
} from '../../services/commonFunctions'
import {
  getPreferencesFromStorage,
  syncAndGetUserid,
  syncAndSaveUserid,
} from '../../freemiumServices/freemiumServices'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

type Props = {
  setTrackerInfo: (user: User, forFreemium?: boolean) => void
  setAsFirstTimeLogin: () => void
}

const FreemiumUserPopUp = (props: Props) => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [ticked, setTicked] = useState(false)
  const [heightOfBackground, setHeightOfBackground] = useState(
    window.innerHeight < 750 ? 750 : '100vh',
  )
  const { width, height } = useWindowSize()
  const [user, setUser] = useRecoilState(userState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  const { executeRecaptcha } = useGoogleReCaptcha()

  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  const reportWindowSize = () => {
    setHeightOfBackground(window.innerHeight < 750 ? 750 : '100vh')
  }

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return
    }

    await executeRecaptcha('login')
  }, [executeRecaptcha])

  useEffect(() => {
    window.addEventListener('resize', reportWindowSize)

    return () => {
      window.removeEventListener('resize', reportWindowSize)
    }
    // eslint-disable-next-line
  }, [window.innerHeight])

  function handleClickAgreeTermsTick() {
    if (!ticked) {
      handleReCaptchaVerify()
    }
    document.getElementById('agreeTermsTicked')!.className = !ticked
      ? 'visibleTick'
      : 'hiddenTick'
    document.getElementById('agreeTermsUnticked')!.className = ticked
      ? 'visibleTick'
      : 'hiddenTick'

    setTicked((prevValue) => !prevValue)
  }

  const onSubmit = async (e: any) => {
    e?.preventDefault()

    if (email.trim() !== '') {
      if (!emailPattern.test(email.trim())) {
        setError(true)
        return
      }
    }

    setLoading(true)

    const res = await freemiumLogin(email.trim() !== '' ? email : undefined)
    if (!('errorCode' in res)) {
      syncAndSaveUserid(res.data.userid)
    } else {
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'freemiumLogin', true),
      )
    }

    const userPrefs = getPreferencesFromStorage()
    if (!userPrefs.checklist.introVideo) {
      props.setAsFirstTimeLogin()
    }

    // To make sure that the props.setAsFirstTimeLogin has changed the value
    setTimeout(() => {
      const newUser = User.EmptyUserFreemium()
      newUser.email = 'user'
      newUser.token = syncAndGetUserid() ?? ''

      setUser(newUser)

      //Mixpanel 1 (Freemium)
      logActivity(true, 'Logged in')

      props.setTrackerInfo(newUser, true)
    }, 100)

    setLoading(false)
  }

  return (
    <>
      <div className="userPopUp" style={{ height: heightOfBackground }}>
        <div className="header-out">
          <div className="header">
            <a className="navbar-brand" href="https://eperoto.com/">
              <img className="logo" src={logo} alt="Eperoto" />
            </a>
          </div>
        </div>
        <div
          className="loginBox freemium"
          style={{
            transform: `scale(${getScaleForPopUp(width, height, 530, 570)})`,
            top: `${getFreemiumTopLeftForPopUp(width, height, 530, 570)[1]}px`,
            left: `${getFreemiumTopLeftForPopUp(width, height, 530, 570)[0]}px`,
          }}
        >
          <form className="form freemium" onSubmit={onSubmit}>
            <div className="welcomeMessageContainer freemium">
              <h2
                className="welcome"
                id="loginTitle"
                data-textattribute="title-253"
              >
                {getText('title-253', user.settings)}
              </h2>
              <div
                className={`instructions`}
                id="loginInstructions"
                data-textattribute={'description-230'}
              >
                {getText('description-230', user.settings)}
              </div>
            </div>
            <div className="inputsContainer">
              <div className="inputEmail">
                <InputText
                  label={getText('label-55', user.settings)}
                  labelTextAttribute="label-55"
                  id="logInEmail"
                  width={400}
                  onChange={(e) => {
                    setError(false)
                    setEmail(e.target.value)
                  }}
                  value={email}
                  error={error}
                  errorMessage={getText('error-38', user.settings)}
                  errorTextAttribute={'error-38'}
                  autoComplete
                  alwaysShrink
                  name="logInEmail"
                  tabIndex={1}
                  maxLength={50}
                  noDelay
                />
              </div>
              <div className="showPassword">
                <img
                  className="visibleTick"
                  id="agreeTermsUnticked"
                  src={unTickedImg}
                  alt="untickedImg"
                  onClick={handleClickAgreeTermsTick}
                />
                <img
                  className="hiddenTick"
                  id="agreeTermsTicked"
                  src={tickedImg}
                  alt="tickedImg"
                  onClick={handleClickAgreeTermsTick}
                />
                <p
                  className="tickText freemium"
                  id="logintickText"
                  data-textattribute="description-231"
                >
                  {getText('description-231', user.settings)}{' '}
                  <span
                    style={{
                      color: '#FF703E',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      window.open(
                        'https://eperoto.com/starter-terms/',
                        '_blank',
                      )
                    }
                    data-textattribute="description-232"
                  >
                    {getText('description-232', user.settings)}
                  </span>
                </p>
              </div>
            </div>
            <div className="submitButtonContainer">
              <button
                type="submit"
                className={`submitButton freemium ${
                  loading || !ticked ? 'disabledButton' : ''
                }`}
                disabled={loading || !ticked}
                id="submitLoginButton"
                data-textattribute="button-157"
              >
                {loading ? (
                  <CircularProgress size={22} />
                ) : (
                  getText('button-157', user.settings)
                )}
              </button>
            </div>
          </form>
          <div
            style={{
              position: 'absolute',
              top: -70,
              right: -110,
            }}
          >
            <img src={freemiumImg} alt="freemiumImg" />
          </div>
        </div>
      </div>
    </>
  )
}

export default FreemiumUserPopUp
