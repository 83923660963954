import { AmountSignum } from './enums'

export class QuantumScenario {
  probability: number
  amount: number | undefined
  outOfCourtSignum: AmountSignum
  outOfCourtAmount: number | undefined
  liabilityOn: boolean

  constructor(
    probability: number,
    amount: number | undefined,
    outOfCourtSignum: AmountSignum,
    outOfCourtAmount: number | undefined,
    liabilityOn: boolean,
  ) {
    this.probability = probability
    this.amount = amount
    this.outOfCourtSignum = outOfCourtSignum
    this.outOfCourtAmount = outOfCourtAmount
    this.liabilityOn = liabilityOn
  }

  static InitialQuantumScenario(): QuantumScenario {
    let tempQuantumScenario = new QuantumScenario(
      1,
      undefined,
      AmountSignum.off,
      undefined,
      false,
    )

    return tempQuantumScenario
  }

  static AdditionalQuantumScenario(): QuantumScenario {
    let tempQuantumScenario = new QuantumScenario(
      0,
      undefined,
      AmountSignum.off,
      undefined,
      false,
    )

    return tempQuantumScenario
  }

  static AdditionalLiabilityQuantumScenario(
    liability: number,
  ): QuantumScenario {
    let tempQuantumScenario = new QuantumScenario(
      1 - liability,
      0,
      AmountSignum.off,
      undefined,
      true,
    )

    return tempQuantumScenario
  }

  static toJson(data: QuantumScenario): any {
    return {
      probability: data.probability,
      amount: data.amount,
      outOfCourtSignum: data.outOfCourtSignum,
      outOfCourtAmount: data.outOfCourtAmount,
      liabilityOn: data.liabilityOn,
    }
  }

  static FromJson(data: any): QuantumScenario {
    return new QuantumScenario(
      data.probability,
      data.amount,
      data.outOfCourtSignum,
      data.outOfCourtAmount,
      data.liabilityOn,
    )
  }
}
