import React from 'react'
import './Button.css'
import { CircularProgress } from '@mui/material'

type Props = {
  buttonText: string | JSX.Element
  buttonTextAttribute: `button-${string}` | `button-${number}` | undefined
  className?: string
  buttonType: 'outlined' | 'contained' | 'text' | 'nonactive' | 'nonactiveText'
  id: string
  type: 'button' | 'submit' | 'reset' | undefined
  icon?: string
  side: 'left' | 'right' | 'middle'
  NoUpperCase: boolean
  small: boolean
  verySmall?: boolean
  backGroundColor?: string
  textColor?: string
  onClick?: (param?: any) => void
  onMouseUp?: (param?: any) => void
  onMouseDown?: (param?: any) => void
  style?: { [key: string]: string | number }
  disabled?: boolean
  danger?: boolean
  loading?: boolean
  loadingWidth?: string
  loadingHeight?: string
  reference?: any
}

export const Button = (props: Props) => {
  function getStyle() {
    let tempStyle: { [key: string]: string | number } = props.backGroundColor
      ? { backgroundColor: props.backGroundColor }
      : {}

    if (props.style) {
      tempStyle = { ...tempStyle, ...props.style }
    }

    if (props.loading) {
      let loadingStyle = {
        width: props.loadingWidth!,
        height: props.loadingHeight!,
      }
      tempStyle = { ...tempStyle, ...loadingStyle }
    }

    return tempStyle
  }

  return (
    <button
      disabled={props.disabled}
      className={`
        button
        button-type-${props.buttonType} 
        ${props.side === 'middle' ? 'middle' : ''}
        ${props.small ? 'button-type-small' : ''} 
        ${props.verySmall ? 'button-type-very-small' : ''} 
        ${props.icon ? 'button-with-icon' : ''} 
        ${props.NoUpperCase ? 'button-NoUpperCase' : ''} 
        ${props.icon ? `button-icon-on-side-${props.icon && props.side}` : ''}  
        ${props.className && props.className} 
        ${props.danger ? 'danger' : ''} 
        ${props.loading ? 'loading' : ''} 
        ${props.buttonText === '' ? 'noText' : undefined}
        `}
      style={getStyle()}
      id={props.id}
      onClick={props.disabled ? undefined : props.onClick}
      onMouseUp={props.onMouseUp}
      onMouseDown={props.onMouseDown}
      type={props.type}
      ref={props.reference}
    >
      <div
        className={`button-text ${props.disabled ? 'disabled' : ''}`}
        style={
          props.disabled
            ? { color: 'white' }
            : props.textColor
            ? { color: props.textColor }
            : undefined
        }
        id={`${props.id}-button-text`}
        data-textattribute={props.buttonTextAttribute}
      >
        {props.loading ? <CircularProgress size={16} /> : props.buttonText}
      </div>
      <div
        className="button-container"
        id={`${props.id}-button-container`}
      ></div>
      {props.icon && (
        <img
          src={props.icon}
          alt="icon"
          className="button-icon"
          id={`${props.id}-button-icon`}
        />
      )}
    </button>
  )
}

export default Button
