import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { SectionId } from '../../../../../../../../models/treeModels/treeTypes'
import { getText } from '../../../../../../../../services/textFunctions'
import { reportState } from '../../../../../../../../states/ReportState'
import { userState } from '../../../../../../../../states/UserState'
import { SectionOrderComponent } from './SectionOrderComponent'

export function SectionOrderMenu() {
  const [sectionOrderArray, setSectionOrderArray] = useState<SectionId[]>([])
  const report = useRecoilValue(reportState)
  const user = useRecoilValue(userState)
  const [animationPair, setAnimationPair] = useState<SectionId[]>([])

  useEffect(() => {
    let tempSectionOrderArray: SectionId[] = []
    for (let section of report.reportData.sections) {
      tempSectionOrderArray[section.sectionOrder] = section.sectionId
    }
    setSectionOrderArray(tempSectionOrderArray)
  }, [report.reportData])

  return (
    <>
      <div className="sectionOrderMenuContainer">
        <div className="sectionOrderMenuTitleContainer">
          <p
            className="sectionOrderMenuTitle"
            data-textattribute="description-153"
          >
            {getText('description-153', user.settings)}
          </p>
        </div>
        <div className="sectionOrderMenuSectionsContainer">
          {sectionOrderArray.map((sectionId) => (
            <SectionOrderComponent
              sectionId={sectionId}
              key={sectionId}
              animationPair={animationPair}
              setAnimationPair={setAnimationPair}
            />
          ))}
        </div>
      </div>
    </>
  )
}
