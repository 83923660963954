import { CircularProgress } from '@mui/material'
import React, { FormEvent, useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import InputText from '../../../../../Components/Inputs/InputText/InputText'
import { RequestError } from '../../../../../models/enums'
import {
  changePasswordCheck,
  loggedInSendSms,
  setPassword,
} from '../../../../../services/requests'
import { errorMessages, getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import DoneScreen from '../../../../userLoginWorkflow/loginComponents/DoneScreen'
import OtpForm from '../../../../userLoginWorkflow/loginComponents/OtpForm'
import { User } from '../../../../../models/user'
import { logActivity } from '../../../../../services/commonFunctions'

type Props = {
  loading: boolean
  setLoading: (canClose: boolean) => void
  setOpenPopUp: (value: boolean) => void
}

const ChangePassword = (props: Props) => {
  const [user, setUser] = useRecoilState(userState)

  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [renewPassword, setRenewPassword] = useState('')
  const [weakPasswordMessage, setWeakPasswordMessage] = useState('')
  const [wrongPassword, setWrongPassword] = useState(false)
  const [done, setDone] = useState(false)
  const [tfa, setTfa] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [lastSmsTimer, setLastSmsTimer] = useState<number | undefined>(
    undefined,
  )
  const [otp, setOtp] = useState('')
  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >(undefined)

  useEffect(() => {
    if (!lastSmsTimer) {
      setLastSmsTimer(undefined)
    } else {
      setTimeout(() => {
        setLastSmsTimer((prevState) => (prevState ? prevState - 1 : undefined))
      }, 1000)
    }

    // eslint-disable-next-line
  }, [lastSmsTimer])

  const sendAgainSms = () => {
    loggedInSendSms('change')
    setLastSmsTimer(59)
  }

  const validation = () => {
    let numOfErrors = 0
    const newErrors: string[] = []

    if (oldPassword.trim() === '') {
      numOfErrors++
      newErrors.push('oldPassword')
    }

    if (newPassword.trim() === '') {
      numOfErrors++
      newErrors.push('newPassword')
    }

    if (renewPassword.trim() === '') {
      numOfErrors++
      newErrors.push('repeatNewPassword')
    } else if (renewPassword !== newPassword) {
      numOfErrors++
      newErrors.push('repeatNewPassword:validation')
    }

    setErrors([...newErrors])
    return numOfErrors === 0
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validation()) {
      return
    }

    if (tfa) {
      if (otp === '') {
        setValidationMessage(errorMessages(user.settings).NOT_ENOUGH_DIGITS)
        return
      }
      props.setLoading(true)

      setPassword({
        old_password: oldPassword,
        new_password: newPassword,
        otp: otp,
      }).then((res) => {
        if (!('errorCode' in res)) {
          setDone(true)
          const newUser = User.UserFromDB(res.data)
          setUser(newUser)
          //Mixpanel 96
          logActivity(false, 'Changed password in Account Details')
        } else {
          setValidationMessage(
            res.errorCode === RequestError.TIMEOUT ||
              res.errorCode === RequestError.SERVER_ERROR
              ? errorMessages(user.settings).OTP_REQUEST_FAILED
              : errorMessages(user.settings).WRONG_CODE,
          )
        }

        props.setLoading(false)
      })
    } else {
      props.setLoading(true)

      changePasswordCheck({
        old_password: oldPassword,
        new_password: newPassword,
      }).then((res) => {
        if ('errorCode' in res) {
          if (res.message.error) {
            setWeakPasswordMessage(res.message.error)
          } else if (res.errorCode === RequestError.TIMEOUT) {
            //id='weakPasswordErrorMessage'
            //data-textattribute='error-69'
            setWeakPasswordMessage(getText('error-69', user.settings))
          } else if (res.errorCode === RequestError.SERVER_ERROR) {
            //data-textattribute='error-70'
            setWeakPasswordMessage(getText('error-70', user.settings))
          } else if (res.errorCode === RequestError.FORBIDDEN) {
            setWrongPassword(true)
          } else {
            setWeakPasswordMessage('')
          }
          props.setLoading(false)
        } else {
          props.setLoading(false)
          setTfa(true)
          if (user.tfaType === 'sms') {
            loggedInSendSms('change')
          }

          //Mixpanel 96
          logActivity(false, 'Changed password in Account Details')
        }
      })
    }
  }

  return !done ? (
    <form className="form" onSubmit={onSubmit}>
      {tfa ? (
        <OtpForm
          setOtp={setOtp}
          otp={otp}
          forSms={user.tfaType === 'sms'}
          validationMessage={validationMessage}
          sendAgainSms={sendAgainSms}
          timeFromLastSms={lastSmsTimer}
          loading={props.loading}
          onBackButton={() => {
            setTfa(false)
          }}
          errors={errors}
        />
      ) : (
        <>
          <div className="welcomeMessageContainer">
            <h2
              className="welcome"
              id="changePasswordTitle"
              data-textattribute="title-169"
            >
              {getText('title-169', user.settings)}
            </h2>
            <p
              className="instructions"
              id="changePasswordInstructions"
              data-textattribute="description-130"
            >
              {getText('description-130', user.settings)}
            </p>
          </div>
          <div className="inputsContainer">
            <div className="inputResetPassword1 for-settings">
              <InputText
                label={getText('label-76', user.settings)}
                labelTextAttribute="label-76"
                id="oldPassword"
                type="password"
                width={470}
                onChange={(e: any) => {
                  setOldPassword(e.target.value)
                  setWrongPassword(false)
                }}
                value={oldPassword}
                name="oldPassword"
                error={errors.includes('oldPassword') || wrongPassword}
                errorMessage={
                  wrongPassword
                    ? getText('error-75', user.settings)
                    : errors.includes('oldPassword')
                    ? getText('error-76', user.settings)
                    : getText('error-44', user.settings)
                }
                errorTextAttribute={
                  wrongPassword
                    ? 'error-75'
                    : errors.includes('oldPassword')
                    ? 'error-76'
                    : 'error-44'
                }
                tabIndex={1}
                alwaysShrink
                noDelay
                maxLength={64}
              />
            </div>
            <div className="inputResetPassword2 for-settings">
              <InputText
                label={getText('label-77', user.settings)}
                labelTextAttribute="label-77"
                id="newPassword"
                type="password"
                width={470}
                onChange={(e: any) => {
                  setNewPassword(e.target.value)
                  setWeakPasswordMessage('')
                }}
                value={newPassword}
                name="newPassword"
                error={
                  errors.includes('newPassword') || weakPasswordMessage !== ''
                }
                errorMessage={
                  weakPasswordMessage !== ''
                    ? weakPasswordMessage
                    : errors.includes('newPassword')
                    ? getText('error-77', user.settings)
                    : getText('error-44', user.settings)
                }
                errorTextAttribute={
                  weakPasswordMessage !== ''
                    ? 'error-00, error-69, error-70'
                    : errors.includes('newPassword')
                    ? 'error-77'
                    : 'error-44'
                }
                tabIndex={2}
                alwaysShrink
                noDelay
                maxLength={64}
              />
            </div>
            <div>
              <InputText
                label={getText('label-78', user.settings)}
                labelTextAttribute="label-78"
                id="repeatNewPassword"
                type="password"
                width={470}
                onChange={(e: any) => setRenewPassword(e.target.value)}
                value={renewPassword}
                name="repeatNewPassword"
                error={
                  errors.includes('repeatNewPassword') ||
                  errors.includes('repeatNewPassword:validation')
                }
                errorMessage={
                  errors.includes('repeatNewPassword')
                    ? getText('error-78', user.settings)
                    : getText('error-46', user.settings)
                }
                errorTextAttribute={
                  errors.includes('repeatNewPassword') ? 'error-78' : 'error-46'
                }
                tabIndex={3}
                alwaysShrink
                noDelay
                maxLength={64}
              />
            </div>
          </div>
          <div className="submitButtonContainer">
            <button
              type="submit"
              className="submitButton"
              disabled={props.loading}
              id="submitChangePasswordButton"
              data-textattribute="button-112"
            >
              {props.loading ? (
                <CircularProgress size={22} />
              ) : (
                getText('button-112', user.settings)
              )}
            </button>
          </div>
        </>
      )}
    </form>
  ) : (
    <DoneScreen
      forPasswordChange
      forSettings
      onFinishSetup={() => props.setOpenPopUp(false)}
    />
  )
}

export default ChangePassword
