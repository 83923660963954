import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { viewerState } from '../../../states/ViewerState'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import { recoveryModeState } from '../../../states/RecoveryModeState'

type Props = {
  id: string
  value: string
  name?: string
  className?: string
  placeholder?: string
  onChange?: (e: any) => void
  updateLines?: () => void
  readOnly?: boolean
  maxLength?: number
  tabIndex?: number
  noGlobalStateChanges?: boolean
  placeholderTextAttribute: string
  disabled?: boolean
  disabledMessage?: string
  absoluteDisabledMessage?: boolean
  disabledMessageTextAttribute?: `message-${number}`
  withoutDisabledMessage?: boolean
  style?: any
}

const TextArea = (props: Props) => {
  const user = useRecoilValue(userState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)

  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)

  const [localValue, setLocalValue] = useState<string | undefined>(props.value)
  const [changeValue, setChangeValue] = useState<boolean>(false)
  const timer = useRef<NodeJS.Timeout | undefined>(undefined)

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  const onChange = (e: any) => {
    if (props.updateLines) {
      props.updateLines()
    }

    setLocalValue(e.target.value)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setChangeValue(true)
    }, 200)
  }

  useEffect(() => {
    if (changeValue && localValue !== props.value && props.onChange) {
      props.onChange({ target: { value: localValue, id: props.id } })
    }

    setChangeValue(false)
    // eslint-disable-next-line
  }, [changeValue])

  useEffect(() => {
    setLocalValue(props.value)
  }, [props.value])

  const onClickDisabled = () => {
    if (props.disabled) {
      if (
        !props.withoutDisabledMessage &&
        recoveryMode.recoveryPreview === 'none'
      ) {
        const element = document.getElementById(props.id)
        const viewportOffset = element!.getBoundingClientRect()

        let top = viewportOffset.top - 15
        const left = viewportOffset.left + 50
        const h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }
        setDisabledMessageCoordinates([top, left])
      }

      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
      }
    }
  }

  const onBlur = () => {
    if (localValue !== props.value && props.onChange) {
      props.onChange({ target: { value: localValue, id: props.id } })
    }
  }

  return (
    <>
      <div
        onClick={onClickDisabled}
        className={`container-${props.className}`}
        id={`container-${props.id}`}
      >
        <textarea
          className={props.className}
          id={props.id}
          placeholder={props.placeholder}
          value={localValue}
          onChange={onChange}
          maxLength={props.maxLength}
          tabIndex={props.tabIndex}
          data-textattribute={props.placeholderTextAttribute}
          readOnly={props.readOnly}
          rows={1}
          disabled={props.disabled}
          onBlur={onBlur}
          style={props.style}
        />
        {props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={onClickDisabled}
          ></div>
        )}
      </div>
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={props.disabledMessageTextAttribute}
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
          absolute={props.absoluteDisabledMessage}
        />
      )}
    </>
  )
}

export default TextArea
