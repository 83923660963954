import React from 'react'
import { useRecoilValue } from 'recoil'
import InputText from '../../../../../../../Components/Inputs/InputText/InputText'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'

type Props = {
  treeName: string
  handleChangeTreeName: (e: any) => void
  treeIndex: number
  fixed: boolean
  disabled?: boolean
  errors: string[]
}

export default function TreeNameComponent(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div
        className="treeNameInputContainer"
        id={`treeNameInputContainer-${props.treeIndex}`}
        style={props.fixed ? { visibility: 'hidden' } : undefined}
      >
        <InputText
          label={getText('label-32', user.settings)}
          labelTextAttribute="label-32"
          id={`treeNameInput-${props.treeIndex}`}
          className="treeNameInput"
          name={`treeNameInput-${props.treeIndex}`}
          width={340}
          maxLength={70}
          value={props.treeName}
          onChange={(e: any) => {
            props.handleChangeTreeName(e)
          }}
          error={props.errors.includes(`treeNameInput-${props.treeIndex}`)}
          tabIndex={1}
          disabled={props.disabled}
          absoluteDisabledMessage
          multiline
          hideHelperText={
            !props.errors.includes(`treeNameInput-${props.treeIndex}`)
          }
        />
      </div>
    </>
  )
}
