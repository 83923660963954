import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { PopperProps } from '@mui/material'

interface TooltipWithTextAttribute extends TooltipProps {
  textAttribute: `tooltip-${string}` | undefined | `tooltip-${string}`[]
  id: string
  zoomLevel?: number
}

interface ExtendedPopperProps extends PopperProps {
  'data-textattribute': `tooltip-${string}` | undefined | `tooltip-${string}`[]
}

export const WhiteTooltip = styled(
  ({ className, textAttribute, ...props }: TooltipWithTextAttribute) => (
    <Tooltip
      {...props}
      PopperProps={
        { 'data-textattribute': textAttribute } as ExtendedPopperProps
      }
      enterDelay={300}
      enterNextDelay={300}
      classes={{ popper: className }}
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#201a2d',
    fontFamily: 'poppins-local',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
  },
}))

export const WhiteTooltipMoreDelay = styled(
  ({ className, textAttribute, ...props }: TooltipWithTextAttribute) => (
    <Tooltip
      {...props}
      PopperProps={
        { 'data-textattribute': textAttribute } as ExtendedPopperProps
      }
      enterDelay={1000}
      enterNextDelay={1000}
      classes={{ popper: className }}
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#201a2d',
    fontFamily: 'poppins-local',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    zIndex: '11 !important',
  },
}))

export const WhiteTooltipDelay700 = styled(
  ({
    className,
    textAttribute,
    zoomLevel,
    ...props
  }: TooltipWithTextAttribute) => (
    <Tooltip
      {...props}
      PopperProps={
        {
          'data-textattribute': textAttribute,
        } as ExtendedPopperProps
      }
      enterDelay={1000}
      enterNextDelay={1000}
      classes={{ popper: className }}
      componentsProps={{
        tooltip: { sx: { fontSize: `${14 * zoomLevel!}px` } },
      }}
      data-openreplay-obscured
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#201a2d',
    fontFamily: 'poppins-local',
    boxShadow: theme.shadows[1],
    //fontSize: 14,
    fontWeight: 400,
    zIndex: 10,
  },
}))

export const WhiteTooltipPath = styled(
  ({ className, textAttribute, ...props }: TooltipWithTextAttribute) => (
    <Tooltip
      {...props}
      PopperProps={
        { 'data-textattribute': textAttribute } as ExtendedPopperProps
      }
      enterDelay={1000}
      enterNextDelay={1000}
      classes={{ popper: className }}
      placement="right-end"
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: '#201a2d',
    fontFamily: 'poppins-local',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 400,
    zIndex: 10,
    marginTop: 20,
  },
}))

export const ResultsTooltip = styled(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      placement="right-start"
      disableFocusListener
      disableHoverListener
      disableTouchListener
    />
  ),
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'transparent',
    maxWidth: '450px',
  },
}))
