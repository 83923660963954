import { useState, useEffect } from 'react'

import { AccessRole, VideoPostName } from '../../../../models/enums'
import Enforcement from './enforcementComponent/Enforcement'
import { ScenarioSnapshot } from '../../../../models/scenarioSnapshot'
import { getText } from '../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../states/UserState'

type Props = {
  setVideoPostName: (param: VideoPostName | undefined) => void
  errors: string[]
  ownRole: AccessRole
  handleChangeKeyValue: (
    e: any,
    key: keyof ScenarioSnapshot,
    number?: boolean,
  ) => void
}

export default function Advanced(props: Props) {
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const user = useRecoilValue(userState)
  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  return (
    <div className="tool-component" id="advancedComp">
      <h2
        className="tool-component-title"
        id="advanced-title"
        data-textattribute="title-243"
      >
        {getText('title-243', user.settings)}
      </h2>

      <Enforcement
        setVideoPostName={props.setVideoPostName}
        errors={props.errors}
        ownRole={props.ownRole}
        handleChangeKeyValue={props.handleChangeKeyValue}
      />
    </div>
  )
}
