export const getScaleForPopUp = (
  width: number,
  height: number,
  popUpWidth: number,
  popUpHeight: number,
  sso?: boolean,
) => {
  let widthScale = 1
  let heightScale = 1
  if (width < popUpWidth + 50) {
    widthScale = 1 - (popUpWidth + 50 - width) / popUpWidth
  }
  if (height < popUpHeight + 50) {
    heightScale = 1 - (popUpHeight + 50 - height) / popUpHeight
  }

  let result = widthScale < heightScale ? widthScale : heightScale
  if (sso) {
    result *= 1.8
    if (result > 1) {
      result = 1
    }
  }
  return result
}

export const getTopLeftForPopUp = (
  width: number,
  height: number,
  popUpWidth: number,
  popUpHeight: number,
  sso?: boolean,
) => {
  const realPopUpWidth =
    popUpWidth * getScaleForPopUp(width, height, popUpWidth, popUpHeight)
  let left = (width - realPopUpWidth) / 2
  left -= (1 - getScaleForPopUp(width, height, popUpWidth, popUpHeight)) * 300

  const realPopUpHeight =
    popUpHeight * getScaleForPopUp(width, height, popUpWidth, popUpHeight)
  let top = (height - realPopUpHeight) / 2
  top -= (1 - getScaleForPopUp(width, height, popUpWidth, popUpHeight)) * 320

  if (sso) {
    top += 50
  }
  return [left, top]
}

export const getFreemiumTopLeftForPopUp = (
  width: number,
  height: number,
  popUpWidth: number,
  popUpHeight: number,
) => {
  const realPopUpWidth =
    popUpWidth * getScaleForPopUp(width, height, popUpWidth, popUpHeight)
  let left = (width - realPopUpWidth) / 2
  left -= (1 - getScaleForPopUp(width, height, popUpWidth, popUpHeight)) * 300

  const realPopUpHeight =
    popUpHeight * getScaleForPopUp(width, height, popUpWidth, popUpHeight)
  let top = (height - realPopUpHeight) / 2
  top -= (1 - getScaleForPopUp(width, height, popUpWidth, popUpHeight)) * 240

  return [left, top]
}
