import React from 'react'
import { useRecoilValue } from 'recoil'
import Button from '../../../../../../../../Components/Buttons/Button/Button'
import {
  DeleteMenuType,
  DeleteNodeOrEventMenuObject,
  EventId,
  NodeId,
} from '../../../../../../../../models/treeModels/treeTypes'
import binImg from '../../../../../../../../resources/images/202-whiteBin.svg'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'

type Props = {
  treeIndex: number
  openDeleteNodeOrEventMenu: DeleteNodeOrEventMenuObject
  position: [number, number]
  handleDeleteTreeEvent: (
    withChildren: boolean,
    nodeId: NodeId,
    eventId: EventId,
    eventIndex: number,
  ) => void
  handleDeleteTreeNode: (
    withChildren: boolean,
    nodeId: NodeId,
    selectedNodes?: NodeId[],
  ) => void
  selectedNodes: NodeId[] | undefined
  setOpenDeleteNodeOrEventMenu: (
    openDeleteNodeOrEventMenu: DeleteNodeOrEventMenuObject | undefined,
  ) => void
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
}

export default function DeleteNodeOrEventMenu(props: Props) {
  const user = useRecoilValue(userState)

  const nodeId = props.openDeleteNodeOrEventMenu.nodeId
  const deleteMenuType = props.openDeleteNodeOrEventMenu.deleteMenuType
  const eventIndex = props.openDeleteNodeOrEventMenu.eventIndex
  const eventId = props.openDeleteNodeOrEventMenu.eventId

  return (
    <>
      <div
        className="deleteNodeOrEventMenuContainer"
        id={`deleteNodeOrEventMenu-${props.treeIndex}_${nodeId}`}
        style={{ top: props.position[0], left: props.position[1] }}
      >
        {deleteMenuType === DeleteMenuType.selectedNodesLastRoot ? (
          <>
            <Button
              buttonText={getText('button-105', user.settings)}
              buttonTextAttribute="button-105"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteJustSelectedTreeNodes-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() =>
                props.handleDeleteTreeNode(false, nodeId, props.selectedNodes)
              }
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Button
              buttonText={getText('button-128', user.settings)}
              buttonTextAttribute="button-128"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteWholeTree-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() => {
                props.removeClaim(
                  props.treeIndex,
                  `removeClaimImgPreview-${props.treeIndex}`,
                  `removeClaimImgPreview-${props.treeIndex}`,
                )
                props.setOpenDeleteNodeOrEventMenu(undefined)
              }}
              icon={binImg}
              side="left"
              backGroundColor={'#ac2828'}
              NoUpperCase={true}
              small={false}
            />
          </>
        ) : deleteMenuType === DeleteMenuType.deleteOnlySelectedNodes ? (
          <>
            <Button
              buttonText={getText('button-105', user.settings)}
              buttonTextAttribute="button-105"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteJustSelectedTreeNodes-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() =>
                props.handleDeleteTreeNode(false, nodeId, props.selectedNodes)
              }
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </>
        ) : deleteMenuType === DeleteMenuType.lastNode ? (
          <>
            <Button
              buttonText={getText('button-128', user.settings)}
              buttonTextAttribute="button-128"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteWholeTree-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() => {
                props.removeClaim(
                  props.treeIndex,
                  `removeClaimImgPreview-${props.treeIndex}`,
                  `removeClaimImgPreview-${props.treeIndex}`,
                )
                props.setOpenDeleteNodeOrEventMenu(undefined)
              }}
              icon={binImg}
              backGroundColor={'#ac2828'}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </>
        ) : deleteMenuType === DeleteMenuType.lastRoot ? (
          <>
            <Button
              buttonText={getText('button-103', user.settings)}
              buttonTextAttribute="button-103"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteJustTreeNode-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() => props.handleDeleteTreeNode(false, nodeId!)}
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Button
              buttonText={getText('button-128', user.settings)}
              buttonTextAttribute="button-128"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteWholeTree-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() => {
                props.removeClaim(
                  props.treeIndex,
                  `removeClaimImgPreview-${props.treeIndex}`,
                  `removeClaimImgPreview-${props.treeIndex}`,
                )
                props.setOpenDeleteNodeOrEventMenu(undefined)
              }}
              icon={binImg}
              side="left"
              backGroundColor={'#ac2828'}
              NoUpperCase={true}
              small={false}
            />
          </>
        ) : deleteMenuType === DeleteMenuType.node ? (
          <>
            <Button
              buttonText={getText('button-103', user.settings)}
              buttonTextAttribute="button-103"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteJustTreeNode-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() => props.handleDeleteTreeNode(false, nodeId!)}
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Button
              buttonText={getText('button-104', user.settings)}
              buttonTextAttribute="button-104"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteTreeNodeWithChildren-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() => props.handleDeleteTreeNode(true, nodeId)}
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </>
        ) : deleteMenuType === DeleteMenuType.selectedNodesWithChildren ? (
          <>
            <Button
              buttonText={getText('button-105', user.settings)}
              buttonTextAttribute="button-105"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteJustSelectedTreeNodes-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() =>
                props.handleDeleteTreeNode(false, nodeId, props.selectedNodes)
              }
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Button
              buttonText={getText('button-106', user.settings)}
              buttonTextAttribute="button-106"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteSelectedTreeNodesWithChildren-${props.treeIndex}_${nodeId}`}
              type="button"
              onClick={() =>
                props.handleDeleteTreeNode(true, nodeId, props.selectedNodes)
              }
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </>
        ) : (
          <>
            <Button
              buttonText={getText('button-107', user.settings)}
              buttonTextAttribute="button-107"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteJustTreeTreeEvent-${props.treeIndex}_${nodeId}!${eventIndex}`}
              type="button"
              onClick={() =>
                props.handleDeleteTreeEvent(
                  false,
                  nodeId,
                  eventId!,
                  eventIndex!,
                )
              }
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Button
              buttonText={getText('button-108', user.settings)}
              buttonTextAttribute="button-108"
              className="deleteNodeOrEventMenuButton"
              buttonType="contained"
              id={`deleteNodeOrEventMenuDeleteTreeTreeEventWithChildren-${props.treeIndex}_${nodeId}!${eventIndex}`}
              type="button"
              onClick={() =>
                props.handleDeleteTreeEvent(true, nodeId, eventId!, eventIndex!)
              }
              icon={binImg}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </>
        )}

        <Button
          buttonText={getText('button-109', user.settings)}
          buttonTextAttribute="button-109"
          className="deleteNodeOrEventMenuButton"
          buttonType="text"
          id={`deleteNodeOrEventMenuCancelDeleteTreeEvent-${props.treeIndex}_${nodeId}`}
          type="button"
          onClick={() => props.setOpenDeleteNodeOrEventMenu(undefined)}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      </div>
    </>
  )
}
