import { atom } from 'recoil'
import { Features } from '../models/generalTypes'

export const featuresState = atom<Features>({
  key: 'featuresState',
  default: {
    share_case: false,
    use_recordings: false,
    use_logs: false,
    use_sms: false,
    use_statistical_model: false,
    use_whitelabel: false,
    use_microsoft_sso: false,
  },
})
