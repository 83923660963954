import React from 'react'
import Xarrow from 'react-xarrows'
import { TreeLines } from '../../../../../../../models/treeModels/treeTypes'

type Props = {
  startId: string
  endRef: React.MutableRefObject<null>
  treeLines: TreeLines
}
export default function ConnectingLine(props: Props) {
  return (
    <>
      <Xarrow
        start={props.startId}
        startAnchor={'top'}
        end={props.endRef}
        endAnchor={'bottom'}
        color={'#a699f2'}
        strokeWidth={1}
        showHead={false}
        showTail={false}
        passProps={{
          id: `connectingLine-${props.startId}`,
          cursor: 'pointer',
        }}
        headShape="circle"
        tailShape="circle"
        arrowHeadProps={{
          fill: 'transparent',
          strokeWidth: '0.2',
          stroke: 'black',
          cursor: 'pointer',
        }}
        headSize={5}
        tailSize={5}
        arrowTailProps={{
          fill: 'transparent',
          strokeWidth: '0.2',
          stroke: 'black',
          cursor: 'pointer',
        }}
        path={props.treeLines}
        curveness={0.3}
      />
    </>
  )
}
