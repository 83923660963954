import React, { useEffect, useRef, useState } from 'react'
import { TutorialTileObject } from '../../../../../models/generalTypes'
import { updateUserChecklist } from '../../../../../services/requests'
import { getTextFromParsedArray } from '../../../../../services/wordpressFunctions'
import TutorialTemplateSideMenu from '../../../tutorialTemplatePages/userManualComponents/TutorialTemplateSideMenu'
import TutorialTemplateTextComponent from '../../../tutorialTemplatePages/userManualComponents/TutorialTemplateTextComponent'
import TutorialBreadCrumb from './TutorialBreadCrumb'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'
import { UserChecklist } from '../../../../../models/userChecklist'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../services/commonFunctions'
import { User } from '../../../../../models/user'
import { freemiumState } from '../../../../../states/FreemiumState'
import { saveFreemiumPreferences } from '../../../../../freemiumServices/freemiumStorage'

type Props = {
  tutorialObject: TutorialTileObject
  setOpenTutorial: (param: string | undefined) => void
}

export default function TutorialComponent(props: Props) {
  const [menuHighlight, setMenuHighlight] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [listOfTitles, setListOfTitles] = useState<string[][]>([])
  const [user, setUser] = useRecoilState(userState)
  const freemium = useRecoilValue(freemiumState)
  const initChecklistUpdate = useRef(false)

  useEffect(() => {
    initiliazeListOfTitles(props.tutorialObject.parsedMainText)
    //Mixpanel 82 (All)
    logActivity(freemium.isFreemium, 'Watched tutorial', {
      Tutorial: props.tutorialObject.title,
    })
    // eslint-disable-next-line

    if (!user.checklist.accessTutorial && !initChecklistUpdate.current) {
      const newChecklist = new UserChecklist(
        user.checklist.introVideo,
        true,
        user.checklist.createCase,
        user.checklist.analyzeScenario,
        user.checklist.downloadReport,
      )

      if (freemium.isFreemium) {
        saveFreemiumPreferences({
          settings: user.settings,
          checklist: newChecklist,
        })
        let newUser = deepCloneObject(user) as User
        newUser.checklist = newChecklist
        setUser(newUser)
      } else {
        updateUserChecklist(newChecklist).then((res) => {
          if (!('errorCode' in res)) {
            let newUser = deepCloneObject(user) as User
            newUser.checklist = UserChecklist.FromJson(res.data)
            setUser(newUser)
          }
        })
      }
      initChecklistUpdate.current = true
    }

    // eslint-disable-next-line
  }, [])

  function initiliazeListOfTitles(parsedTextArray: JSX.Element[]) {
    let tempListOfTitles: string[][] = []

    for (let i = 0; i < (parsedTextArray as any).length; i++) {
      if (
        parsedTextArray[i].type &&
        (parsedTextArray[i].type === 'h1' || parsedTextArray[i].type === 'h2')
      ) {
        let titleType = parsedTextArray[i].type
        let titleText = getTextFromParsedArray(parsedTextArray[i])
        let titleId = parsedTextArray[i].props.id
        tempListOfTitles.push([titleType, titleText, titleId])
      }
    }

    setListOfTitles(tempListOfTitles)
  }

  function scrollTo(id: string) {
    const offset = 200

    const element = document.getElementById(id)
    const container = document.getElementById(
      'userManualTextComponentContainer',
    )

    if (element && container) {
      container!.scroll({
        top: element!.offsetTop - offset,
        behavior: 'smooth',
      })
    }
  }

  const onScroll = (e: any) => {
    setScrollTop(
      document.getElementById('userManualTextComponentContainer')!.scrollTop,
    )
    if (
      listOfTitles.length > 0 &&
      document.getElementById(listOfTitles[0][2])
    ) {
      for (let i = 0; i < listOfTitles.length; i++) {
        let element = document.getElementById(listOfTitles[i][2])
        if (element) {
          if (scrollTop < element!.offsetTop + element!.offsetHeight - 400) {
            if (i === 0) {
              setMenuHighlight(0)
            } else {
              setMenuHighlight(i - 1)
            }
            break
          }
        }
      }
    }
  }

  useEffect(() => {
    const container = document.getElementById(
      'userManualTextComponentContainer',
    )

    if (container) {
      container.addEventListener('scroll', onScroll)
    }

    return () => {
      if (container) {
        container!.removeEventListener('scroll', onScroll)
      }
    }
    // eslint-disable-next-line
  }, [props.tutorialObject, scrollTop])

  return (
    <>
      <TutorialBreadCrumb
        templateTitle={props.tutorialObject.title}
        setOpenTutorial={props.setOpenTutorial}
      />
      <div className="sideMenuAndTextContainer tutorialComponent">
        <TutorialTemplateSideMenu
          menuHighlight={menuHighlight}
          listOfTitles={listOfTitles}
          scrollTo={scrollTo}
        />
        <TutorialTemplateTextComponent
          parsedText={props.tutorialObject.parsedMainText}
          templateTitle={props.tutorialObject.title}
        />
      </div>
    </>
  )
}
