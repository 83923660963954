import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { UserRole } from '../../../../../models/enums'
import {
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
} from '../../../../../services/commonFunctions'
import { updateCompanyDetails } from '../../../../../services/requests'
import { getText } from '../../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { userState } from '../../../../../states/UserState'
import SettingInputRow from './SettingInputRow'

type Props = {
  hoverElement: string
  setHoverElement: (value: string) => void
}

const OrganisationDetails = (props: Props) => {
  const [user, setUser] = useRecoilState(userState)
  const [loading, setLoading] = useState('')
  const [showSaved, setShowSaved] = useState('')
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  const onSave = (id: string, value: string) => {
    if (loading === '') {
      setLoading(id)

      updateCompanyDetails({ company_name: value.trim() }).then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'updateCompanyDetails'),
        )

        if (!('errorCode' in res)) {
          const newUser = deepCloneObject(user)
          newUser.companyName = res.data.company_name
          setUser(newUser)
          setShowSaved(id)
          setTimeout(() => {
            setShowSaved('')
          }, 2500)

          //Mixpanel 93
          logActivity(false, 'Changed the organisation name in Account Details')
        }

        setLoading('')
      })
    }
  }

  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id="organisationDetailsTitle"
        data-textattribute="title-173"
      >
        {getText('title-173', user.settings)}
      </h1>
      <SettingInputRow
        id={'companyName'}
        originalValue={user.companyName}
        loading={loading === 'companyName'}
        showSaved={showSaved === 'companyName'}
        errorText={getText('error-71', user.settings)}
        errorTextAttribute={'error-71'}
        labelText={getText('label-66', user.settings)}
        labelTextAttribute={'label-66'}
        infoButtonText={getText('infoButton-40', user.settings)}
        infoButtonTextAttribute={'infoButton-40'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1003}
        hoverElement={props.hoverElement}
        setHoverElement={props.setHoverElement}
        onSave={onSave}
        readOnly={
          user.role === UserRole.APP_VIEWER ||
          user.role === UserRole.SIMPLE_USER
        }
        showInfoButton
      />
      <SettingInputRow
        id={'companyDomain'}
        originalValue={user.companyDomain}
        loading={false}
        showSaved={false}
        errorText={''}
        labelText={getText('label-67', user.settings)}
        labelTextAttribute={'label-67'}
        infoButtonText={getText('infoButton-41', user.settings)}
        infoButtonTextAttribute={'infoButton-41'}
        buttonText={''}
        confirmationText={''}
        tabIndex={1004}
        hoverElement={props.hoverElement}
        setHoverElement={props.setHoverElement}
        onSave={() => {}}
        readOnly
        showInfoButton
      />
    </>
  )
}

export default OrganisationDetails
