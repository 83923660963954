import { formattedNumToString } from '../../services/formatNum'

export function findTodaysDate() {
  let today = new Date()
  let dd = String(today.getDate()).padStart(2, '0')
  let mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  let yyyy = today.getFullYear()

  today = yyyy + '/' + mm + '/' + dd
  return today
}

export function makeid(length) {
  var result = ''
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function toCapitals(mySentence) {
  const words = mySentence.split('_')

  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1)
  }

  return words.join(' ')
}

export function scrollTo(e) {
  const element = document.getElementById(e.target.className)

  var to = element.offsetTop
  var start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20
  var duration = 700

  var animateScroll = function () {
    currentTime += increment
    var val = Math.easeInOutQuad(currentTime, start, change, duration)
    element.scrollTop = val
    if (currentTime < duration) {
      setTimeout(animateScroll, increment)
    }
  }
  animateScroll()
}

//t = current time
//b = start value
//c = change in value
//d = duration
Math.easeInOutQuad = function (t, b, c, d) {
  t /= d / 2
  if (t < 1) return (c / 2) * t * t + b
  t--
  return (-c / 2) * (t * (t - 2) - 1) + b
}

export const numDisplay = (num) => {
  if (parseFloat(num) === 0) {
    return '0'
  }
  if (parseFloat(num) <= 0.0001) {
    return '~ 0'
  }
  let newNum = num.toString()
  if (newNum === '') {
    return ''
  }

  if (newNum.includes('.') || newNum.includes(',')) {
    // If decimal

    newNum.includes('.') && (newNum = newNum.split('.'))
    newNum.includes(',') && (newNum = newNum.split(','))

    if (newNum[0] === '') {
      newNum[0] = '0'
    }
    let wholeNum = newNum[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x) {
        return x.split('').reverse().join('')
      })
      .reverse()
    wholeNum.join(' ')

    let n = wholeNum.join(' ') + ',' + newNum[1]
    return n
  } else {
    // Without decimal
    let wholeNum = newNum
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x) {
        return x.split('').reverse().join('')
      })
      .reverse()
    let n = wholeNum.join(' ')

    return n
  }
}

export function roundTo2Decimals(value) {
  return Math.round((value + Number.EPSILON) * 100) / 100
}
export function roundTo4Decimals(value) {
  return Math.round((value + Number.EPSILON) * 10000) / 10000
}
export function stringAndRoundTo2Decimals(value, settings, absoluteValue) {
  if (absoluteValue) {
    return formattedNumToString(
      Math.abs(Math.round((value + Number.EPSILON) * 100) / 100),
      settings,
    )
  } else {
    return formattedNumToString(
      Math.round((value + Number.EPSILON) * 100) / 100,
      settings,
    )
  }
}

export function setCoordinates(id, y, x, xoffset) {
  var deleteButton = document.getElementById(id)
  var viewportOffset = deleteButton.getBoundingClientRect()
  // these are relative to the viewport, i.e. the window
  var top = viewportOffset.top + y
  var left = viewportOffset.left + x
  var w = window.innerHeight
  if (w - left < 200) {
    left -= xoffset
  }

  return [top, left]
}

export function openChat() {
  //noUse
}

export function handleUndoArrays(
  undoArray,
  undoHistory,
  undoArrayIndex,
  tempState,
  id,
  changeType,
  setUndoArray,
  setUndoArrayIndex,
  setUndoHistory,
) {
  var tempUndoArray = JSON.parse(JSON.stringify(undoArray))

  var tempUndoHistory = JSON.parse(JSON.stringify(undoHistory))
  if (undoArrayIndex < undoArray.length - 1) {
    tempUndoArray.splice(undoArrayIndex + 1)
    tempUndoHistory.splice(undoArrayIndex + 1)
  }

  tempUndoArray.push(tempState)
  var changeObject = {
    id: id,
    type: changeType,
  }
  tempUndoHistory.push(changeObject)
  var nextUndoArrayIndex = undoArrayIndex + 1
  if (nextUndoArrayIndex === 200) {
    tempUndoArray.shift()
    tempUndoHistory.shift()
    nextUndoArrayIndex = 199
  }

  setUndoArrayIndex(nextUndoArrayIndex)
  setUndoArray(tempUndoArray)
  setUndoHistory(tempUndoHistory)
}

export function calculateProbabilityToWinMoreThanTheValue(results) {
  let index = results.result.graph.findIndex((item) => {
    return item[0] < results.value_of_claim
  })
  if (index === 0) {
    return results.result.graph[index][1] * 100
  } else if (index > 0) {
    return results.result.graph[index - 1][1] * 100
  } else {
    return 0
  }
}
