import React, { useEffect, useRef, useState } from 'react'

//import undoBlueHover from '../../../resources/images/085-undoBlueHover.svg'
import undoBlue from '../../../resources/images/111-undo.svg'
import undoGray from '../../../resources/images/113-noUndo.svg'
import redoBlue from '../../../resources/images/110-redo.svg'
import redoGray from '../../../resources/images/112-noRedo.svg'
//import redoBlueHover from '../../../resources/images/082-redoBlueHover.svg'
import HoverComponent from '../../../Components/InfoHover/HoverComponent/HoverComponent'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import {
  connectIdWithTab,
  deepCloneObject,
  isInViewport,
  isMac,
  logActivity,
} from '../../../services/commonFunctions'
import { activeTabsState } from '../../../states/ActiveTabsState'
import {
  AccessRole,
  ClaimType,
  InterestViewOption,
  ToolTabs,
  UndoRedoType,
} from '../../../models/enums'
import { ScenarioSnapshot } from '../../../models/scenarioSnapshot'
import { treeTablesState } from '../../../states/TreeTablesState'
import { TreeTableInfo } from '../../../models/generalTypes'
import { pageDepthState } from '../../../states/PageDepthState'
import { getText } from '../../../services/textFunctions'
import { TreeClaim } from '../../../models/treeModels/treeClaim'
import { editTreeNodeFromUndoState } from '../../../states/EditTreeNodeFromUndo'
import { NodeId, TreeId } from '../../../models/treeModels/treeTypes'
import { findEditModePositionsOfTree } from '../../../services/treeFunctions/treeBasicFunctions'
import { nodesMovedForEditModeState } from '../../../states/NodesMovedForEditModeState'
import { userState } from '../../../states/UserState'
import { allowShortcutsState } from '../../../states/AllowShortcutsState'
import { freemiumState } from '../../../states/FreemiumState'

type Props = {
  ownRole: AccessRole
}

export default function UndoRedo(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const [isUndoAvailable, setIsUndoAvailable] = useState(false) // a boolean that defines if the undo button is enabled -- bool
  const [isRedoAvailable, setIsRedoAvailable] = useState(false) // a boolean that defines if the redo button is enabled -- bool
  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)
  const [doingRedoUndo, setDoingRedoUndo] = useState(false)

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature
  const pageDepth = useRecoilValue(pageDepthState)
  const setEditTreeNodeFromUndo = useSetRecoilState(editTreeNodeFromUndoState)
  const setNodesMovedForEditMode = useSetRecoilState(nodesMovedForEditModeState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  useEffect(() => {
    if (
      scenarioSnapshot.undoRedoIndex <=
      scenarioSnapshot.undoRedo.length - 1
    ) {
      if (scenarioSnapshot.undoRedoIndex === 0) {
        setIsUndoAvailable(false)
      } else {
        setIsUndoAvailable(true)
      }
      if (
        scenarioSnapshot.undoRedoIndex ===
        scenarioSnapshot.undoRedo.length - 1
      ) {
        setIsRedoAvailable(false)
      } else {
        setIsRedoAvailable(true)
      }
    }

    document.addEventListener('keydown', onKeyPress)

    return () => {
      document.removeEventListener('keydown', onKeyPress)
    }
    // eslint-disable-next-line
  }, [
    doingRedoUndo,
    isUndoAvailable,
    isRedoAvailable,
    scenarioSnapshot,
    allowShortcuts,
  ])

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }
  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  const onKeyPress = (e: KeyboardEvent) => {
    let activeId = document.activeElement
      ? document.activeElement.id
      : undefined

    if (pageDepth === 3 && allowShortcuts) {
      if (
        (e.code === 'KeyZ' && e.ctrlKey && !e.shiftKey && !isMac()) ||
        (e.code === 'KeyZ' && e.metaKey && !e.shiftKey && isMac())
      ) {
        if (!doingRedoUndo && isUndoAvailable) {
          if (activeId && document.getElementById(activeId)) {
            document.getElementById(activeId)!.blur()
            setTimeout(() => {
              if (activeId && document.getElementById(activeId)) {
                document.getElementById(activeId)!.focus()
              }
            }, 1400)
          }
          onClickUndo()
          //Mixpanel 63 (All)
          logActivity(freemium.isFreemium, 'Pressed undo', {
            'Undo Method': 'keyboard',
          })
        }
        e.preventDefault()
      } else if (
        (e.code === 'KeyZ' && e.ctrlKey && e.shiftKey && !isMac()) ||
        (e.code === 'KeyZ' && e.metaKey && e.shiftKey && isMac())
      ) {
        if (!doingRedoUndo && isRedoAvailable) {
          if (activeId && document.getElementById(activeId)) {
            document.getElementById(activeId)!.blur()
            setTimeout(() => {
              if (activeId && document.getElementById(activeId)) {
                document.getElementById(activeId)!.focus()
              }
            }, 1400)
          }

          onClickRedo()
          //Mixpanel 64 (All)
          logActivity(freemium.isFreemium, 'Pressed redo', {
            'Redo Method': 'keyboard',
          })
        }
        e.preventDefault()
      }
    }
  }

  function onClickUndo() {
    var nextUndoArrayIndex = scenarioSnapshot.undoRedoIndex - 1
    if (nextUndoArrayIndex < 0) {
      nextUndoArrayIndex = 0
    }

    undoRedoSetState(nextUndoArrayIndex, scenarioSnapshot.undoRedoIndex, 'undo')
  }
  function onClickRedo() {
    var nextUndoArrayIndex = scenarioSnapshot.undoRedoIndex + 1
    if (nextUndoArrayIndex > scenarioSnapshot.undoRedo.length - 1) {
      nextUndoArrayIndex = scenarioSnapshot.undoRedo.length - 1
    }
    undoRedoSetState(nextUndoArrayIndex, nextUndoArrayIndex, 'redo')
  }

  function undoRedoSetState(
    nextUndoArrayIndex: number,
    currentIndex: number,
    undoOrRedo: 'undo' | 'redo',
  ) {
    setDoingRedoUndo(true)
    //this should run from the undoRedo component
    const id = scenarioSnapshot.undoRedo[currentIndex].id
    const undoRedoType = scenarioSnapshot.undoRedo[currentIndex].type
    const nodesMovedForEditMode =
      scenarioSnapshot.undoRedo[currentIndex].nodesMovedForEditMode

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot.currentSnapshot = deepCloneObject(
      scenarioSnapshot.undoRedo[nextUndoArrayIndex].snapshot,
    )
    tempScenarioSnapshot.undoRedoIndex = nextUndoArrayIndex
    let tabUndoRedoHappens = connectIdWithTab(id)
    let claimUndoRedoHappens = activeTabs.claim
    const isUndoRedoOnTree =
      tempScenarioSnapshot.currentSnapshot.claims[claimUndoRedoHappens] &&
      tempScenarioSnapshot.currentSnapshot.claims[claimUndoRedoHappens].type ===
        'tree'

    if (isUndoRedoOnTree) {
      tempScenarioSnapshot.currentSnapshot.claims[
        claimUndoRedoHappens
      ].analysisResults = false
    }

    if (
      tabUndoRedoHappens === ToolTabs.claims &&
      !id.includes('PreviewButton') &&
      !id.includes('ListTitle')
    ) {
      let tempId = id
      if (typeof id !== 'string') {
        tempId = id[0]
      }
      let indexOfDash = tempId.indexOf('-')
      let indexOfUnderscore = tempId.indexOf('_')
      if (indexOfUnderscore < 0) {
        claimUndoRedoHappens = parseInt(
          (tempId as string).substring(indexOfDash + 1),
        )
      } else {
        claimUndoRedoHappens = parseInt(
          (tempId as string).substring(indexOfDash + 1, indexOfUnderscore),
        )
      }
    }

    if (id === 'addClaimContainer') {
      claimUndoRedoHappens =
        tempScenarioSnapshot.currentSnapshot.claims.length - 1
    }

    const changedId = getChangedTreeId(
      scenarioSnapshot.undoRedo[
        undoOrRedo === 'redo' ? currentIndex - 1 : currentIndex
      ].snapshot,
      scenarioSnapshot.undoRedo[nextUndoArrayIndex].snapshot,
    )

    if (changedId.action !== 'none') {
      if (changedId.action === 'add') {
        setTreeTables({
          ...treeTables,
          treeTablesInfo: [
            ...treeTables.treeTablesInfo,
            {
              treeId: changedId.treeId,
              results: undefined,
              currentValueOfTree: undefined,
              previousValueOfTree: undefined,
              showSelectionGraph: false,
              showTreeTable: false,
              treeInterestViewOption: InterestViewOption.interest1st,
            },
          ],
        })
      } else {
        const indexInAnalysis = treeTables.treeTablesInfo.findIndex(
          (el) => el.treeId === changedId.treeId,
        )
        let tempTablesInfo: TreeTableInfo[] = deepCloneObject(
          treeTables.treeTablesInfo,
        )
        if (indexInAnalysis > -1) {
          tempTablesInfo.splice(indexInAnalysis, 1)
        }
        setTreeTables({
          ...treeTables,
          treeTablesInfo: [...tempTablesInfo],
        })
      }
    }

    //Change in different tab but tabUndoRedoHappens !== Claims
    let timeouts = [0, 100, 400, 1000]

    if (
      (tabUndoRedoHappens === activeTabs.tab &&
        tabUndoRedoHappens !== ToolTabs.claims) ||
      (tabUndoRedoHappens === activeTabs.tab &&
        tabUndoRedoHappens === ToolTabs.claims &&
        claimUndoRedoHappens === activeTabs.claim)
    ) {
      //Change in the same Tab and claim
      if (
        undoRedoType === UndoRedoType.minimisedButton ||
        undoRedoType === UndoRedoType.minimisedInput
      ) {
        timeouts = [0, 0, 200, 750]
      } else {
        timeouts = [0, 0, 0, 650]
      }
    } else if (
      tabUndoRedoHappens !== activeTabs.tab &&
      tabUndoRedoHappens === ToolTabs.claims
    ) {
      //Change in different Tab and tabUndoRedoHappens === Claims
      timeouts = [0, 400, 1200, 1550]
    }

    //step 1: Go where the change happens for buttons
    setActiveTabs({ tab: tabUndoRedoHappens, claim: claimUndoRedoHappens })
    focusAndStyle(id, undoRedoType, 'step1', 0)

    timeouts.forEach((timeout, index) => {
      switch (index) {
        case 0:
          setTimeout(() => {
            //step 1: setEditTreeNodeFromUndo
            setActiveTabs({
              tab: tabUndoRedoHappens,
              claim: claimUndoRedoHappens,
            })
            focusAndStyle(id, undoRedoType, 'step1', 0)
          }, timeout)
          break
        case 1:
          setTimeout(() => {
            //step 2: Go where the change happens for buttons
            //step 2: Focus on the inputs
            focusAndStyle(id, undoRedoType, 'step2', timeout)
          }, timeout)
          break
        case 2:
          setTimeout(() => {
            //step 3: Highlight the change for the button
            focusAndStyle(id, undoRedoType, 'step3', timeout)
            //nodesMovedForEditMode exists when we have changed the node positions because a change happened in minimised version of the tree and we need to find out the editMode positions
            if (nodesMovedForEditMode && isUndoRedoOnTree) {
              tempScenarioSnapshot = findEditModePositionsOfTree(
                tempScenarioSnapshot,
                nodesMovedForEditMode,
                id,
              )
            }
            setNodesMovedForEditMode(nodesMovedForEditMode)
            //step 3: Make the change
            setScenarioSnapshot(tempScenarioSnapshot)
            setDoingRedoUndo(false)
          }, timeout)
          break
        case 3:
          setTimeout(() => {
            //step 4: Unhighlight the change
            focusAndStyle(id, undoRedoType, 'step4', timeout)
          }, timeout)
          break
        default:
          break
      }
    })
  }

  const focusAndStyle = (
    id: string | string[],
    undoRedoType: UndoRedoType,
    step: 'step1' | 'step2' | 'step3' | 'step4',
    timeout: number,
  ) => {
    let focusId: string, styleId: string, comesFromMUI
    if (typeof id !== 'string') {
      if (id.length === 2) {
        ;[focusId, styleId] = id
      } else {
        ;[focusId, styleId, comesFromMUI] = id
      }
    } else {
      focusId = id
      styleId = id
    }

    /*  console.log('timeout', timeout, step) */

    if (step === 'step1') {
      /*   console.log('focusId', focusId)
      console.log('styleId', styleId)
      console.log('isInput', undoRedoType) */
      if (
        undoRedoType === UndoRedoType.minimisedButton ||
        undoRedoType === UndoRedoType.minimisedInput
      ) {
        let nodeId = styleId.split('_')[1].split('!')[0]
        setEditTreeNodeFromUndo(nodeId as NodeId)
      } else {
        setEditTreeNodeFromUndo(undefined)
      }
    }

    //For the inputs we just need to focus on the element
    if (
      undoRedoType === UndoRedoType.minimisedInput ||
      undoRedoType === UndoRedoType.input
    ) {
      switch (step) {
        case 'step1':
          break
        case 'step2':
          // We check that for the date inputs because MUI changes the id.
          if (comesFromMUI && document.getElementById(`container-${styleId}`)) {
            setTimeout(() => {
              if (
                document.getElementById(`container-${focusId}`) &&
                document
                  .getElementById(`container-${focusId}`)!
                  .getElementsByTagName('input').length > 0
              )
                document
                  .getElementById(`container-${focusId}`)!
                  .getElementsByTagName('input')[0]
                  .focus()
            }, 300)
          } else {
            if (document.getElementById(focusId)) {
              document.getElementById(focusId)!.focus()
            }
          }
          break
        default:
          break
      }
    }

    //For the buttons
    if (
      undoRedoType !== UndoRedoType.minimisedInput &&
      undoRedoType !== UndoRedoType.input
    ) {
      let styleTimeout = 0

      if (
        (styleId.includes('treeEventAwardedAmountSignumSwitch') ||
          styleId.includes('treeEventReducedAmountPartySwitch') ||
          styleId.includes('treeEventOutOfCourtAmountSignumSwitch') ||
          styleId.includes('treeEventIncludeInterestButtonImg')) &&
        document.activeElement !== document.getElementById(focusId)
      ) {
        styleTimeout = 300
      }
      switch (step) {
        //Step 1 go where the change happens
        case 'step2':
          if (
            focusId === 'addClaimContainer' &&
            document.getElementById(focusId)
          ) {
            window.scrollTo(0, document.getElementById(focusId)!.offsetTop)
          }
          if (
            styleId.includes('treeEventAwardedAmountSignumSwitch') ||
            styleId.includes('treeEventReducedAmountPartySwitch') ||
            styleId.includes('treeEventOutOfCourtAmountSignumSwitch') ||
            styleId.includes('treeEventIncludeInterestButtonImg')
          ) {
            if (
              document.getElementById(focusId) &&
              !isInViewport(document.getElementById(focusId))
            ) {
              setTimeout(() => {
                if (document.getElementById(focusId)) {
                  document.getElementById(focusId)?.focus()
                }
              }, 800)
            } else if (
              document.getElementById(focusId) &&
              isInViewport(document.getElementById(focusId))
            ) {
              if (document.getElementById(focusId)) {
                document.getElementById(focusId)?.focus()
              }
            }
          }
          if (
            document.getElementById(styleId) &&
            !isInViewport(document.getElementById(styleId))
          ) {
            //  console.log(styleId, 'is not in viewport')

            if (styleId.includes('addRemoveClaimInterestContainer')) {
              document
                .getElementById(styleId)!
                .scrollIntoView({ block: 'center' })
            } else {
              setTimeout(
                () => {
                  if (document.getElementById(focusId)) {
                    document
                      .getElementById(focusId)!
                      .scrollIntoView({ block: 'center' })
                  } else {
                    if (document.getElementById(focusId)) {
                      setTimeout(() => {
                        document
                          .getElementById(focusId)!
                          .scrollIntoView({ block: 'center' })
                      }, 1000)
                    }
                  }
                },
                undoRedoType === UndoRedoType.minimisedButton ? 300 : 30,
              )
            }
            //window.scrollBy(0, -300)
          }
          // }
          break
        case 'step3':
          //Step 2 highlight the change
          if (!styleId.includes('connectionCircle')) {
            setTimeout(() => {
              if (document.getElementById(styleId)) {
                document.getElementById(styleId)!.style.border =
                  '1px solid #2c79f7'
                if (!styleId.includes('legalCostsSwitchButtonContainer')) {
                  document.getElementById(styleId)!.style.borderRadius = '5px'
                }
              }
            }, styleTimeout)
          }

          break

        case 'step4':
          //Step 2 unhighlight the change
          setTimeout(() => {
            if (document.getElementById(styleId)) {
              document.getElementById(styleId)!.style.removeProperty('border')
              document
                .getElementById(styleId)!
                .style.removeProperty('borderRadius')
            }
          }, styleTimeout)
          break
        default:
          break
      }
    }
  }

  const getChangedTreeId = (
    prevSnapshot: ScenarioSnapshot,
    nextSnapshot: ScenarioSnapshot,
  ) => {
    const firstIds: TreeId[] = []
    const secondIds: TreeId[] = []

    prevSnapshot.claims.forEach((claim) => {
      if (claim.type === ClaimType.tree) {
        firstIds.push((claim as TreeClaim).id)
      }
    })

    nextSnapshot.claims.forEach((claim) => {
      if (claim.type === ClaimType.tree) {
        secondIds.push((claim as TreeClaim).id)
      }
    })

    if (firstIds.length === secondIds.length) {
      return { action: 'none', treeId: 'tree0' as TreeId }
    }

    if (firstIds.length > secondIds.length) {
      for (let i = 0; i < firstIds.length; i++) {
        if (!secondIds.includes(firstIds[i])) {
          return { action: 'remove', treeId: firstIds[i] }
        }
      }
    }

    if (firstIds.length < secondIds.length) {
      for (let i = 0; i < secondIds.length; i++) {
        if (!firstIds.includes(secondIds[i])) {
          return { action: 'add', treeId: secondIds[i] }
        }
      }
    }

    return { action: 'none', treeId: 'tree0' as TreeId }
  }

  return (
    <>
      <div className="undoRedoContainer">
        <div
          className="undoContainer"
          style={isUndoAvailable ? { cursor: 'pointer' } : undefined}
          onMouseEnter={() => {
            secondaryHover.current = 'undo'
            setTimeout(() => {
              if (secondaryHover.current === 'undo') {
                setHoverElement('undo')
              }
            }, 1000)
          }}
          onMouseLeave={() => {
            secondaryHover.current = 'close'
            setHoverElement('')
          }}
        >
          {hoverElement === 'undo' ? (
            <div
              className="hoverComponentContainer"
              style={{ top: '30px', right: '10px' }}
            >
              <HoverComponent
                hoverText={getText('hover-1', user.settings)}
                textAttribute="hover-1"
                id="undo"
                style={{ width: '60px', textAlign: 'center' }}
              />
            </div>
          ) : null}
          {isUndoAvailable &&
          !doingRedoUndo &&
          props.ownRole !== AccessRole.VIEWER ? (
            <img
              src={undoBlue}
              alt="undoBlue"
              className="undoBlue"
              id="undoButton"
              onClick={() => {
                onClickUndo()
                //Mixpanel 63 (All)
                logActivity(freemium.isFreemium, 'Pressed undo', {
                  'Undo Method': 'click',
                })
              }}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <img src={undoGray} alt="undoGray" className="undoGray" />
          )}
        </div>

        <div
          className="redoContainer"
          style={isRedoAvailable ? { cursor: 'pointer' } : undefined}
          onMouseEnter={() => {
            secondaryHover.current = 'redo'
            setTimeout(() => {
              if (secondaryHover.current === 'redo') {
                setHoverElement('redo')
              }
            }, 1000)
          }}
          onMouseLeave={() => {
            secondaryHover.current = 'close'
            setHoverElement('')
          }}
        >
          {hoverElement === 'redo' ? (
            <div
              className="hoverComponentContainer"
              style={{ top: '30px', right: '-20px' }}
            >
              <HoverComponent
                hoverText={getText('hover-2', user.settings)}
                textAttribute="hover-2"
                id="redo"
                style={{ width: '60px', textAlign: 'center' }}
              />
            </div>
          ) : null}
          {isRedoAvailable &&
          !doingRedoUndo &&
          props.ownRole !== AccessRole.VIEWER ? (
            <img
              src={redoBlue}
              alt="redoBlue"
              className="redoBlue"
              id="redoButton"
              onClick={() => {
                onClickRedo()
                //Mixpanel 64 (All)
                logActivity(freemium.isFreemium, 'Pressed redo', {
                  'Redo Method': 'click',
                })
              }}
              style={{ cursor: 'pointer' }}
            />
          ) : (
            <img src={redoGray} alt="redoGray" className="redoGray" />
          )}
        </div>
      </div>
    </>
  )
}
