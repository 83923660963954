import { atom } from 'recoil'
import { FreemiumMessageType } from '../models/freemiumEnums'

export const freemiumState = atom<{
  isFreemium: boolean
  showMessage: undefined | FreemiumMessageType
}>({
  key: 'freemiumState',
  default: { isFreemium: false, showMessage: undefined },
})
