import { CircularProgress } from '@mui/material'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import Button from '../../../../Components/Buttons/Button/Button'
import DropDown from '../../../../Components/Inputs/DropDown/DropDown'
import { dropDownValidation } from '../../../../Modules/AppModules/ValidationFunctions'
import { getText } from '../../../../services/textFunctions'
import { userState } from '../../../../states/UserState'

type Props = {
  data: any
  onTransferCases: (usernames: string[], forUpdate?: boolean) => void
  loading: boolean
  onClose: () => void
  forOtherUser?: boolean
  forChangeRole?: boolean
}

const OnlyOwnerPopUp = (props: Props) => {
  const user = useRecoilValue(userState)

  const [values, setValues] = useState<string[]>(
    props.data.groups
      ? new Array<string>(props.data.groups.length).fill('ADMIN')
      : [],
  )

  const getAvailableReadableOptions = () => {
    const values = props.data.all_users.map(
      (user: any) => `${user.first_name} ${user.last_name}`,
    )

    return [getText('title-156', user.settings), ...values]
  }

  const getAvailableOptions = () => {
    const values = [
      'ADMIN',
      ...props.data.all_users.map((user: any) => user.username),
    ]

    return values
  }

  const onChange = (e: any, index: number) => {
    let newValues = [...values]
    newValues[index] = e.target.value
    setValues([...newValues])
  }

  return (
    <>
      <div className="welcomeMessageContainer only-owner">
        <h2
          className="welcome"
          id="transferCasesTitle"
          data-textattribute={props.forOtherUser ? 'title-189' : 'title-190'}
        >
          {props.forOtherUser
            ? getText('title-189', user.settings)
            : getText('title-190', user.settings)}
        </h2>
        <p
          className="instructions"
          id="transferCasesDescription"
          data-textattribute={
            values.length === 0
              ? 'description-137'
              : props.forOtherUser || props.forChangeRole
              ? 'description-138'
              : 'description-139'
          }
        >
          {values.length === 0
            ? getText('description-137', user.settings)
            : props.forOtherUser || props.forChangeRole
            ? getText('description-138', user.settings)
            : getText('description-139', user.settings)}
        </p>
      </div>
      {values.length > 0 && (
        <div className="selectOwnersContainer">
          <div className="titles">
            <p
              className="first"
              id="caseTitleText"
              data-textattribute="title-191"
            >
              {getText('title-191', user.settings)}
            </p>
            <p
              className="centered"
              id="transferToText"
              data-textattribute="title-192"
            >
              {getText('title-192', user.settings)}
            </p>
          </div>
          <div className="cases-column">
            {props.data.groups.map((group: any, index: number) => (
              <div className="case-row" key={index}>
                <div className="subrow withPadding" data-openreplay-obscured>
                  {group.group_name}
                </div>
                <div className="subrow">
                  <DropDown
                    id={'dropDownAccessRole'}
                    label={''}
                    name={'dropDownAccessRole'}
                    value={values[index]}
                    width={295}
                    validateFunction={(value: string) =>
                      dropDownValidation(value)
                    }
                    options={getAvailableOptions()}
                    readableOptions={getAvailableReadableOptions()}
                    onChange={(e) => onChange(e, index)}
                    labelTextAttribute={undefined}
                    forAccess
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="submitButtonContainer row only-owner">
        <Button
          id="cancelButtonOnlyOwner"
          buttonText={getText('button-102', user.settings)}
          buttonTextAttribute={'button-102'}
          buttonType="outlined"
          className="createCaseButton"
          type="button"
          onClick={props.onClose}
          side="left"
          NoUpperCase={true}
          small={false}
        />
        <div style={{ width: '40px' }}></div>
        <button
          type="button"
          className="submitButton only-owner"
          id="transferAccountButton"
          data-textattribute={
            props.loading
              ? ''
              : props.forOtherUser
              ? 'button-116'
              : props.forChangeRole
              ? 'button-117'
              : 'button-118'
          }
          style={{
            backgroundColor: props.forChangeRole ? '#331a5b' : '#ac2828', //lakersUniform - greekEaster
          }}
          onClick={() => props.onTransferCases(values, props.forChangeRole)}
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : props.forOtherUser ? (
            getText('button-116', user.settings)
          ) : props.forChangeRole ? (
            getText('button-117', user.settings)
          ) : (
            getText('button-118', user.settings)
          )}
        </button>
      </div>
    </>
  )
}

export default OnlyOwnerPopUp
