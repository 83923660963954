import React from 'react'

type Props = {
  id: string
  message: string
  cancelFunction: () => void
  position: [number, number]
  disabledMessageTextAttribute?: `message-${number}` | undefined
  absolute?: boolean
}

const DisabledWindow = (props: Props) => {
  return (
    <>
      <div
        className="deleteMessage disabledMessage"
        style={{
          top: props.absolute ? 15 : props.position[0],
          left: props.absolute ? 50 : props.position[1],
          position: props.absolute ? 'absolute' : 'fixed',
        }}
        onMouseUp={props.cancelFunction}
        id={`disabledWindow-${props.id}`}
      >
        <div className="deleteQuestion disabledMessage">
          <p
            id={`disabledMessageText-${props.id}`}
            data-textattribute={
              props.disabledMessageTextAttribute ?? 'message-67'
            }
          >
            {props.message}
          </p>
        </div>
      </div>
      <div
        onMouseUp={props.cancelFunction}
        style={{
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          left: '0',
          top: '0',
          zIndex: '99',
        }}
      ></div>
    </>
  )
}

export default DisabledWindow
