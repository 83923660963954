import { useEffect, useState } from 'react'
import premiumIcon from '../../resources/images/273-premium.svg'
import useWindowSize from '../../customHooks/useWindowSize'
import { freemiumState } from '../../states/FreemiumState'
import { useRecoilState } from 'recoil'
import { FreemiumMessageType } from '../../models/freemiumEnums'

type Props = {
  targetId: string
  forText?: boolean
  forTextWithSymbol?: boolean
  forDropDownMenu?: boolean
  forTreeDownload?: boolean
  forTreeInterest?: boolean
  forMoreMenu?: boolean
  forEnforcement?: boolean
}

const PremiumFeatureIcon = (props: Props) => {
  const [position, setPosition] = useState<[number, number]>([0, 0])
  const [showIcon, setShowIcon] = useState(false)
  const { width, height } = useWindowSize()
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  useEffect(() => {
    const el = document.getElementById(props.targetId)
    if (el) {
      setShowIcon(true)
      setPosition([el.offsetTop, el.offsetLeft + el.offsetWidth])
    } else if (props.forDropDownMenu) {
      setShowIcon(true)
    }

    // eslint-disable-next-line
  }, [props.targetId, width, height])

  const getFreemiumMessage = () => {
    switch (props.targetId) {
      case 'addNewCaseButton':
        return FreemiumMessageType.Add_Case
      case 'addNewScenarioButton':
        return FreemiumMessageType.Add_Scenario
      default:
        return FreemiumMessageType.General
    }
  }

  return (
    <div
      id={`${props.targetId}-premiumIcon`}
      style={
        props.forText
          ? {
              position: 'absolute',
              top: `${position[0] - 3}px`,
              left: `${position[1] + 3}px`,
            }
          : props.forTextWithSymbol
          ? {
              position: 'absolute',
              top: `${position[0] - 1}px`,
              left: `${position[1] + 85}px`,
            }
          : props.forDropDownMenu
          ? {
              position: 'absolute',
              top: 3,
              right: 16,
            }
          : props.forTreeDownload
          ? {
              position: 'absolute',
              top: `${position[0] - 5}px`,
              left: `${position[1] - 10}px`,
            }
          : props.forTreeInterest
          ? {
              position: 'absolute',
              top: `${position[0] + 3}px`,
              left: `${position[1] - 66}px`,
            }
          : props.forMoreMenu
          ? {
              position: 'absolute',
              top: `${position[0] + 11}px`,
              left: `${position[1] - 38}px`,
            }
          : props.forEnforcement
          ? {
              position: 'absolute',
              top: `${position[0] + 4}px`,
              left: `${position[1] + 40}px`,
            }
          : {
              position: 'absolute',
              top: `${position[0] - 13}px`,
              left: `${position[1] - 11}px`,
            }
      }
      onClick={() =>
        setFreemium({ ...freemium, showMessage: getFreemiumMessage() })
      }
    >
      {showIcon && (
        <img
          style={{ width: '18px', height: '17px' }}
          src={premiumIcon}
          alt="premiumIcon"
          onClick={() =>
            setFreemium({
              ...freemium,
              showMessage: getFreemiumMessage(),
            })
          }
        />
      )}
    </div>
  )
}

export default PremiumFeatureIcon
