import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import {
  AccessRole,
  SettingsTabs,
  ToolTabs,
  TutorialTabs,
  UserRole,
} from '../../models/enums'
import { TabsErrors } from '../../models/generalTypes'
import { scenarioIdentityState } from '../../states/ScenarioIdentityState'
import { userState } from '../../states/UserState'
import SingleTab from './SingleTab'
import { freemiumState } from '../../states/FreemiumState'

type Props = {
  activeTab: ToolTabs | TutorialTabs | SettingsTabs
  type: 'ToolTabs' | 'TutorialTabs' | 'SettingsTabs'
  setActiveTab: (param: any) => void
  setOpenTutorial?: (param: string | undefined) => void
  inactiveTabs?: boolean
  errors?: TabsErrors
  ownRole?: AccessRole
}

const Tabs = (props: Props) => {
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  let tabsMenu: ToolTabs[] | TutorialTabs[] | SettingsTabs[] = []
  if (props.type === 'ToolTabs') {
    tabsMenu = [
      ToolTabs.proceedings,
      ToolTabs.claims,
      ToolTabs.legalCosts,
      ToolTabs.legalCostsDistribution,
      ToolTabs.advanced,
      ToolTabs.results,
    ]
  } else if (props.type === 'TutorialTabs') {
    tabsMenu = [TutorialTabs.Tutorials, TutorialTabs.UserManual]
  } else if (props.type === 'SettingsTabs') {
    if (freemium.isFreemium) {
      tabsMenu = [SettingsTabs.Preferences]
    } else if (
      user.role === UserRole.APP_VIEWER ||
      user.role === UserRole.SIMPLE_USER
    ) {
      tabsMenu = [SettingsTabs.AccountDetails, SettingsTabs.Preferences]
    } else {
      tabsMenu = [
        SettingsTabs.AccountDetails,
        SettingsTabs.Preferences,
        SettingsTabs.Subscription,
        SettingsTabs.Users,
      ]
    }
  }

  // Tool page tabs errors
  const getErrors = (tab: ToolTabs) => {
    if (props.errors) {
      if (Object.keys(props.errors).includes(tab)) {
        if (tab === ToolTabs.claims) {
          if (typeof props.errors[tab as keyof TabsErrors] === 'number') {
            return props.errors[tab as keyof TabsErrors] as number
          } else {
            let claimsErrors = 0
            Object.values(
              props.errors[tab as keyof TabsErrors] as Record<number, number>,
            ).forEach((value) => (claimsErrors += value))
            return claimsErrors
          }
        } else {
          return props.errors[tab as keyof TabsErrors] as number
        }
      }
    }

    return 0
  }

  // onscroll for Tool page tabs
  const onToolPageScroll = (e: any) => {
    const topHeight =
      document.getElementById('claimsPreviewContainer') &&
      document.getElementById('tabsContainer')
        ? document.getElementById('claimsPreviewContainer')!.offsetHeight +
          document.getElementById('tabsContainer')!.offsetHeight +
          53 +
          33
        : undefined
    if (window.innerWidth < 836) {
      document.getElementById(
        `tabsContainer`,
      )!.style.transform = `translateX(0px)`
    } else if (
      document.getElementById(`tabsContainer`) &&
      topHeight !== undefined &&
      topHeight - window.scrollY < 0
    ) {
      document.getElementById(
        `tabsContainer`,
      )!.style.transform = `translateX(0px)`
    } else if (document.getElementById(`tabsContainer`)) {
      document.getElementById(
        `tabsContainer`,
      )!.style.transform = `translateX(${window.scrollX}px)`
    }
  }

  useEffect(() => {
    if (props.type === 'ToolTabs') {
      window.addEventListener('scroll', onToolPageScroll)
    }

    return () => {
      if (props.type === 'ToolTabs') {
        window.removeEventListener('scroll', onToolPageScroll)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      className={`tabsContainer ${
        props.type === 'TutorialTabs'
          ? 'tutorialsTabsContainer'
          : props.type === 'SettingsTabs'
          ? 'settingsTabsContainer'
          : ''
      }`}
      id="tabsContainer"
    >
      {tabsMenu.map((tab, index) => (
        <SingleTab
          key={`singleTab${index}`}
          type={props.type}
          name={tab}
          isActive={props.activeTab === tab}
          setActiveTab={props.setActiveTab}
          snapshotStatus={
            props.type === 'ToolTabs'
              ? scenarioIdentity.snapshotStatus
              : undefined
          }
          inactiveTabs={props.inactiveTabs}
          setOpenTutorial={props.setOpenTutorial}
          errors={
            props.type === 'ToolTabs' ? getErrors(tab as ToolTabs) : undefined
          }
          ownRole={props.ownRole}
        />
      ))}
    </div>
  )
}

export default Tabs
