import { useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../models/generalTypes'
import { userState } from '../../../../../../states/UserState'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import {
  ExecutiveSummaryVisibleElementsEnum,
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import { useEffect, useState } from 'react'
import { getText } from '../../../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import useWindowSize from '../../../../../../customHooks/useWindowSize'
import { WhiteTooltip } from '../../../../../../Components/tooltip/TooltipComponent'
import { removeUnderscore } from '../../../../../../services/commonFunctions'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { findDisputedAmount } from '../../../../../../services/disputedAmountFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  reportWhitelabel?: ReportWhitelabel
  fadeGraph: boolean
  zopaExists: boolean
  visibleElements?: ExecutiveSummaryVisibleElements
  oneOutcomeForOpposingParty: boolean
  resultsViewParty: 'client' | 'opposing'
}

export default function ZopaGraph(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const windowSize = useWindowSize()

  const [widthClient, setWidthClient] = useState(0)
  const [widthOpposingParty, setWidthOpposingParty] = useState(0)
  const [totalDistance, setTotalDistance] = useState(600)
  const [sortedLimits, setSortedLimits] = useState<number[]>([0, 0, 0, 0])

  const settlement1 =
    props.resultsFromBackend.settlement_amount ??
    props.resultsFromBackend.value_of_claim
  const valueAndSettlementDifference1 =
    settlement1 - props.resultsFromBackend.value_of_claim
  const best1 =
    props.resultsFromBackend.minmax.max.financial_outcome +
    valueAndSettlementDifference1
  const settlement2 = -props.resultsFromBackend.reversed!.settlement_amount
  const valueAndSettlementDifference2 =
    -settlement2 - props.resultsFromBackend.reversed!.value_of_claim
  const best2 = -(
    props.resultsFromBackend.reversed!.minmax.max.financial_outcome +
    valueAndSettlementDifference2
  )
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  const hasIncurred1 = valueAndSettlementDifference1 !== 0
  const hasIncurred2 = valueAndSettlementDifference2 !== 0

  const courtName =
    scenarioSnapshot.currentSnapshot.court !== 'Other'
      ? removeUnderscore(scenarioSnapshot.currentSnapshot.court!)
      : //data-textattribute="description-193"
        getText('description-193', user.settings)

  useEffect(() => {
    const leftLimit = Math.min(settlement1, best2)
    const rightLimit = Math.max(best1, settlement2)

    const tempTotalDistance = Math.abs(rightLimit - leftLimit)
    let tempSortedLimits = [settlement1, best1, settlement2, best2]
    tempSortedLimits.sort((a, b) => a - b)

    setSortedLimits(tempSortedLimits)
    const settlement1X =
      (Math.abs(settlement1 - best1) / tempTotalDistance) * 600

    const settlement2X =
      (Math.abs(settlement2 - best2) / tempTotalDistance) * 600

    setWidthClient(settlement1X)
    setWidthOpposingParty(settlement2X)
    setTotalDistance(tempTotalDistance)

    // eslint-disable-next-line
  }, [props.resultsFromBackend])

  return (
    <div className="zopaGraphOuterContainer">
      <div
        className="zopaInnerContainer"
        style={best2 > settlement2 ? { marginLeft: -60 } : undefined}
      >
        <div
          className="zopaGraphAppContainer"
          style={
            !props.visibleElements &&
            windowSize.width < 1500 &&
            windowSize.width > 840
              ? {
                  transform: `scale(${
                    windowSize.width > 1300
                      ? windowSize.width / 1500
                      : windowSize.width / 1600
                  })`,

                  // marginLeft: +160 - 160 * (1500 / windowSize.width),
                }
              : !props.visibleElements && windowSize.width <= 840
              ? { transform: `scale(${840 / 1600})` }
              : props.visibleElements
              ? { transform: `scale(${0.9})`, marginLeft: 0 }
              : undefined
          }
        >
          <div className="zopaGraphContainer">
            <div className="zopaGraph">
              <div
                className={`zopaBar ${krogerusColors ? 'krogerus' : ''}`}
                id="zopaGraphOpposingParty"
                style={{
                  marginLeft:
                    best2 < settlement2
                      ? (Math.abs(best2 - sortedLimits[0]) / totalDistance) *
                        600
                      : (Math.abs(settlement2 - sortedLimits[0]) /
                          totalDistance) *
                        600,
                  width: widthOpposingParty,
                }}
              >
                <div
                  className="zopaTick top"
                  style={{
                    left:
                      best2 < settlement2
                        ? (Math.abs(best2 - sortedLimits[0]) / totalDistance) *
                            600 +
                          92
                        : (Math.abs(settlement2 - sortedLimits[0]) /
                            totalDistance) *
                            600 +
                          92,
                  }}
                ></div>
                <div
                  className="zopaTickTop top"
                  style={{
                    left:
                      best2 < settlement2
                        ? (Math.abs(best2 - sortedLimits[0]) / totalDistance) *
                            600 +
                          92 -
                          7.5
                        : (Math.abs(settlement2 - sortedLimits[0]) /
                            totalDistance) *
                            600 +
                          92 -
                          7.5,
                  }}
                ></div>
                <WhiteTooltip
                  textAttribute={
                    best2 < settlement2 ? 'tooltip-56' : 'tooltip-00'
                  }
                  id={'bestSettlementForOpposingPartyTextLeft-tooltip'}
                  title={
                    best2 < settlement2
                      ? hasIncurred2
                        ? `${getText('tooltip-56', user.settings)}${
                            scenarioSnapshot.currentSnapshot.hasInsurance
                              ? ` ${getText('tooltip-57', user.settings)}`
                              : ''
                          } ${getText(
                            'tooltip-58',
                            user.settings,
                            partiesFormat,
                          )} ${getText(
                            'tooltip-60',
                            user.settings,
                            partiesFormat,
                          )} ${courtName}.`
                        : `${getText(
                            'tooltip-60',
                            user.settings,
                            partiesFormat,
                          )} ${courtName}.`
                      : ''
                  }
                >
                  <p
                    className="zopaTickText top left"
                    id="zopaTickTextTopLeft"
                    style={{
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                          ? true
                          : false,
                      ),
                      left:
                        best2 < settlement2
                          ? (Math.abs(best2 - sortedLimits[0]) /
                              totalDistance) *
                              600 +
                            91 -
                            240
                          : (Math.abs(settlement2 - sortedLimits[0]) /
                              totalDistance) *
                              600 +
                            91 -
                            240,
                      textAlign: best2 > settlement2 ? 'left' : 'right',
                    }}
                    text-dataattribute="description-215"
                  >
                    {best2 < settlement2
                      ? getText('description-215', user.settings, partiesFormat)
                      : getText(
                          'description-216',
                          user.settings,
                          partiesFormat,
                        )}
                  </p>
                </WhiteTooltip>
                {best2 < settlement2 && (
                  <WhiteTooltip
                    textAttribute={
                      best2 < settlement2 ? 'tooltip-56' : 'tooltip-00'
                    }
                    id={'bestSettlementForOpposingPartyValueLeft-tooltip'}
                    title={
                      best2 < settlement2
                        ? hasIncurred2
                          ? `${getText('tooltip-56', user.settings)}${
                              scenarioSnapshot.currentSnapshot.hasInsurance
                                ? ` ${getText('tooltip-57', user.settings)}`
                                : ''
                            } ${getText(
                              'tooltip-58',
                              user.settings,
                              partiesFormat,
                            )} ${getText(
                              'tooltip-60',
                              user.settings,
                              partiesFormat,
                            )} ${courtName}.`
                          : `${getText(
                              'tooltip-60',
                              user.settings,
                              partiesFormat,
                            )} ${courtName}.`
                        : ''
                    }
                  >
                    <p
                      className="zopaTickNumber robotoNumbers"
                      id="zopaTickNumberTopLeft"
                      style={{
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                          false,
                          props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                            ? true
                            : false,
                        ),
                        left:
                          best2 < settlement2
                            ? (Math.abs(best2 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              200 +
                              85
                            : (Math.abs(settlement2 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              200 +
                              85,
                      }}
                    >
                      {stringAndRoundBigNumbers(
                        -best2,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(scenarioSnapshot.currentSnapshot),
                      )}
                    </p>
                  </WhiteTooltip>
                )}

                {props.oneOutcomeForOpposingParty === false && (
                  <>
                    <div
                      className="zopaTick top"
                      style={{
                        left:
                          best2 < settlement2
                            ? (Math.abs(settlement2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91
                            : (Math.abs(best2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91,
                      }}
                    ></div>
                    <div
                      className="zopaTickTop top"
                      style={{
                        left:
                          best2 < settlement2
                            ? (Math.abs(settlement2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91
                            : (Math.abs(best2 - sortedLimits[0]) /
                                totalDistance) *
                                600 +
                              91,
                      }}
                    ></div>
                    <WhiteTooltip
                      textAttribute={
                        best2 > settlement2 ? 'tooltip-56' : 'tooltip-00'
                      }
                      id={'bestSettlementForOpposingPartyTextRight-tooltip'}
                      title={
                        best2 > settlement2
                          ? hasIncurred2
                            ? `${getText('tooltip-56', user.settings)}${
                                scenarioSnapshot.currentSnapshot.hasInsurance
                                  ? ` ${getText('tooltip-57', user.settings)}`
                                  : ''
                              } ${getText(
                                'tooltip-58',
                                user.settings,
                                partiesFormat,
                              )} ${getText(
                                'tooltip-60',
                                user.settings,
                                partiesFormat,
                              )} ${courtName}.`
                            : `${getText(
                                'tooltip-60',
                                user.settings,
                                partiesFormat,
                              )} ${courtName}.`
                          : ''
                      }
                    >
                      <p
                        className="zopaTickText top right"
                        id="zopaTickTextTopRight"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.regular,
                            'noRoboto',
                            false,
                            props.visibleElements &&
                              props.visibleElements[
                                ExecutiveSummaryVisibleElementsEnum.zopa
                              ] === false
                              ? true
                              : false,
                          ),
                          left:
                            best2 < settlement2
                              ? (Math.abs(settlement2 - sortedLimits[0]) /
                                  totalDistance) *
                                  600 +
                                102
                              : (Math.abs(best2 - sortedLimits[0]) /
                                  totalDistance) *
                                  600 -
                                10,
                        }}
                        text-dataattribute="description-216"
                      >
                        {best2 < settlement2
                          ? getText(
                              'description-216',
                              user.settings,
                              partiesFormat,
                            )
                          : getText(
                              'description-215',
                              user.settings,
                              partiesFormat,
                            )}
                      </p>
                    </WhiteTooltip>
                    {(!props.zopaExists || best2 > settlement2) && (
                      <WhiteTooltip
                        textAttribute={
                          best2 > settlement2 ? 'tooltip-56' : 'tooltip-00'
                        }
                        id={'bestSettlementForOpposingPartyValueRight-tooltip'}
                        title={
                          best2 > settlement2
                            ? hasIncurred2
                              ? `${getText('tooltip-56', user.settings)}${
                                  scenarioSnapshot.currentSnapshot.hasInsurance
                                    ? ` ${getText('tooltip-57', user.settings)}`
                                    : ''
                                } ${getText(
                                  'tooltip-58',
                                  user.settings,
                                  partiesFormat,
                                )} ${getText(
                                  'tooltip-60',
                                  user.settings,
                                  partiesFormat,
                                )} ${courtName}.`
                              : `${getText(
                                  'tooltip-60',
                                  user.settings,
                                  partiesFormat,
                                )} ${courtName}.`
                            : ''
                        }
                      >
                        <p
                          className="zopaTickNumber right robotoNumbers"
                          id="zopaTickNumberTopRight"
                          style={{
                            ...getStyleFromWhitelabel(
                              props.reportWhitelabel,
                              ReportWhitelabelKeys.sectionDescription,
                              FontLabels.regular,
                              'roboto',
                              false,
                              props.visibleElements &&
                                props.visibleElements[
                                  ExecutiveSummaryVisibleElementsEnum.zopa
                                ] === false
                                ? true
                                : false,
                            ),
                            left:
                              best2 < settlement2
                                ? (Math.abs(settlement2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  99
                                : (Math.abs(best2 - sortedLimits[0]) /
                                    totalDistance) *
                                    600 +
                                  99,
                          }}
                        >
                          {best2 < settlement2
                            ? `${stringAndRoundBigNumbers(
                                -settlement2,
                                user.settings,
                                resultsSettings.settings.roundedResults,
                                findDisputedAmount(
                                  scenarioSnapshot.currentSnapshot,
                                ),
                              )}${
                                props.resultsFromBackend &&
                                props.resultsFromBackend.model_used ===
                                  'statistical'
                                  ? '*'
                                  : ''
                              }`
                            : stringAndRoundBigNumbers(
                                -best2,
                                user.settings,
                                resultsSettings.settings.roundedResults,
                                findDisputedAmount(
                                  scenarioSnapshot.currentSnapshot,
                                ),
                              )}
                        </p>
                      </WhiteTooltip>
                    )}
                  </>
                )}
              </div>
              <div
                className={`zopaBar ${krogerusColors ? 'krogerus' : ''}`}
                id="zopaGraphZOPA"
                style={{
                  width: props.zopaExists
                    ? Math.max(
                        Math.abs(
                          (Math.abs(settlement2 - settlement1) /
                            totalDistance) *
                            600,
                        ),
                        1,
                      )
                    : 0,

                  marginLeft:
                    (Math.abs(settlement1 - sortedLimits[0]) / totalDistance) *
                    600,
                }}
              >
                {props.zopaExists ? (
                  <>
                    <WhiteTooltip
                      textAttribute={'tooltip-00'}
                      id={'zopaTickNumberZopaLeft-tooltip'}
                      title={`${getText(
                        'description-203c',
                        user.settings,
                        partiesFormat,
                      )} ${
                        settlement1 >= 0
                          ? getText('description-204a', user.settings)
                          : getText('description-204b', user.settings)
                      } ${stringAndRoundBigNumbers(
                        settlement1,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(scenarioSnapshot.currentSnapshot),
                        true,
                      )}${
                        props.resultsFromBackend &&
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''
                      } ${scenarioSnapshot.currentSnapshot.currency} ${
                        settlement1 >= 0
                          ? getText('description-206a', user.settings)
                          : getText('description-206b', user.settings)
                      } ${getText(
                        'description-207a',
                        user.settings,
                        partiesFormat,
                      )} ${getText('description-208', user.settings)}`}
                    >
                      <p
                        className="zopaTickNumber zopa robotoNumbersBold"
                        id="zopaTickNumberZopaLeft"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.bold,
                            'roboto',
                            false,
                            props.visibleElements &&
                              props.visibleElements[
                                ExecutiveSummaryVisibleElementsEnum.zopa
                              ] === false
                              ? true
                              : false,
                          ),
                          left:
                            (Math.abs(settlement1 - sortedLimits[0]) /
                              totalDistance) *
                              600 -
                            200 +
                            85,
                        }}
                      >
                        {stringAndRoundBigNumbers(
                          props.resultsViewParty === 'opposing'
                            ? -settlement1
                            : settlement1,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          findDisputedAmount(scenarioSnapshot.currentSnapshot),
                        )}
                        {props.resultsFromBackend &&
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''}
                      </p>
                    </WhiteTooltip>
                    <WhiteTooltip
                      textAttribute={'tooltip-00'}
                      id={'zopaTickNumberZopaRight-tooltip'}
                      title={`${getText(
                        'description-209',
                        user.settings,
                        partiesFormat,
                      )} 
                      ${
                        props.resultsFromBackend.reversed!.settlement_amount >=
                        0
                          ? getText('description-204a', user.settings)
                          : getText('description-204b', user.settings)
                      } 
                      ${stringAndRoundBigNumbers(
                        props.resultsFromBackend.reversed!.settlement_amount,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(scenarioSnapshot.currentSnapshot),
                        true,
                      )}${
                        props.resultsFromBackend &&
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''
                      } ${scenarioSnapshot.currentSnapshot.currency} ${
                        props.resultsFromBackend.reversed!.settlement_amount >=
                        0
                          ? getText('description-206a', user.settings)
                          : getText('description-206b', user.settings)
                      } ${getText(
                        'description-207b',
                        user.settings,
                        partiesFormat,
                      )} ${getText('description-208', user.settings)}`}
                    >
                      <p
                        className="zopaTickNumber right zopa robotoNumbersBold"
                        id="zopaTickNumberZopaRight"
                        style={{
                          ...getStyleFromWhitelabel(
                            props.reportWhitelabel,
                            ReportWhitelabelKeys.sectionDescription,
                            FontLabels.bold,
                            'roboto',
                            false,
                            props.visibleElements &&
                              props.visibleElements[
                                ExecutiveSummaryVisibleElementsEnum.zopa
                              ] === false
                              ? true
                              : false,
                          ),
                          left:
                            (Math.abs(settlement2 - sortedLimits[0]) /
                              totalDistance) *
                              600 +
                            99,
                        }}
                      >
                        {stringAndRoundBigNumbers(
                          props.resultsViewParty === 'opposing'
                            ? -settlement2
                            : settlement2,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          findDisputedAmount(scenarioSnapshot.currentSnapshot),
                        )}
                        {props.resultsFromBackend &&
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''}
                      </p>
                    </WhiteTooltip>
                  </>
                ) : (
                  <p
                    className="nozopaText"
                    id="noZopaText"
                    data-textattribute="description-219"
                    style={{
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                          ? true
                          : false,
                      ),
                    }}
                  >
                    {getText('description-219', user.settings)}
                  </p>
                )}
              </div>
              <div
                className={`zopaBar ${krogerusColors ? 'krogerus' : ''}`}
                id="zopaGraphClient"
                style={{
                  width: widthClient,
                  marginLeft:
                    settlement1 < best1
                      ? (Math.abs(settlement1 - sortedLimits[0]) /
                          totalDistance) *
                        600
                      : (Math.abs(best1 - sortedLimits[0]) / totalDistance) *
                        600,
                }}
              >
                <div
                  className="zopaTick bottom"
                  style={{
                    left:
                      settlement1 < best1
                        ? (Math.abs(settlement1 - sortedLimits[0]) /
                            totalDistance) *
                            600 +
                          92
                        : (Math.abs(best1 - sortedLimits[0]) / totalDistance) *
                            600 +
                          92,
                  }}
                ></div>
                <div
                  className="zopaTickTop bottom"
                  style={{
                    left:
                      settlement1 < best1
                        ? (Math.abs(settlement1 - sortedLimits[0]) /
                            totalDistance) *
                            600 +
                          92 -
                          7.5
                        : (Math.abs(best1 - sortedLimits[0]) / totalDistance) *
                            600 +
                          92 -
                          7.5,
                  }}
                ></div>
                <WhiteTooltip
                  textAttribute={
                    best2 > settlement2 ? 'tooltip-56' : 'tooltip-00'
                  }
                  id={'bestSettlementForClientTextLeft-tooltip'}
                  title={
                    best2 > settlement2
                      ? hasIncurred1
                        ? `${getText('tooltip-56', user.settings)}${
                            scenarioSnapshot.currentSnapshot.hasInsurance
                              ? ` ${getText('tooltip-57', user.settings)}`
                              : ''
                          } ${getText(
                            'tooltip-59',
                            user.settings,
                            partiesFormat,
                          )} ${getText(
                            'tooltip-61',
                            user.settings,
                            partiesFormat,
                          )} ${courtName}.`
                        : `${getText(
                            'tooltip-61',
                            user.settings,
                            partiesFormat,
                          )} ${courtName}.`
                      : ''
                  }
                >
                  <p
                    className="zopaTickText bottom left"
                    id="zopaTickTextBottomLeft"
                    style={{
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                          ? true
                          : false,
                      ),
                      left:
                        settlement1 < best1
                          ? (Math.abs(settlement1 - sortedLimits[0]) /
                              totalDistance) *
                              600 -
                            155
                          : (Math.abs(best1 - sortedLimits[0]) /
                              totalDistance) *
                              600 -
                            75,
                      top: settlement1 < best1 ? 110 : 135,
                    }}
                    text-dataattribute="description-217"
                  >
                    {settlement1 < best1
                      ? getText('description-217', user.settings, partiesFormat)
                      : getText(
                          'description-218',
                          user.settings,
                          partiesFormat,
                        )}
                  </p>
                </WhiteTooltip>
                {(!props.zopaExists || settlement1 > best1) && (
                  <WhiteTooltip
                    textAttribute={
                      best2 > settlement2 ? 'tooltip-56' : 'tooltip-00'
                    }
                    id={'bestSettlementForClientValueLeft-tooltip'}
                    title={
                      best2 > settlement2
                        ? hasIncurred1
                          ? `${getText('tooltip-56', user.settings)}${
                              scenarioSnapshot.currentSnapshot.hasInsurance
                                ? ` ${getText('tooltip-57', user.settings)}`
                                : ''
                            } ${getText(
                              'tooltip-59',
                              user.settings,
                              partiesFormat,
                            )} ${getText(
                              'tooltip-61',
                              user.settings,
                              partiesFormat,
                            )} ${courtName}.`
                          : `${getText(
                              'tooltip-61',
                              user.settings,
                              partiesFormat,
                            )} ${courtName}.`
                        : ''
                    }
                  >
                    <p
                      className="zopaTickNumber client robotoNumbers"
                      id="zopaTickNumberBottomLeft"
                      style={{
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                          false,
                          props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                            ? true
                            : false,
                        ),
                        left:
                          settlement1 < best1
                            ? (Math.abs(settlement1 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              200 +
                              85
                            : (Math.abs(best1 - sortedLimits[0]) /
                                totalDistance) *
                                600 -
                              200 +
                              85,
                      }}
                    >
                      {settlement1 < best1
                        ? `${stringAndRoundBigNumbers(
                            settlement1,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                            findDisputedAmount(
                              scenarioSnapshot.currentSnapshot,
                            ),
                          )}${
                            props.resultsFromBackend &&
                            props.resultsFromBackend.model_used ===
                              'statistical'
                              ? '*'
                              : ''
                          }`
                        : stringAndRoundBigNumbers(
                            best1,
                            user.settings,
                            resultsSettings.settings.roundedResults,
                            findDisputedAmount(
                              scenarioSnapshot.currentSnapshot,
                            ),
                          )}
                    </p>
                  </WhiteTooltip>
                )}
                <div
                  className="zopaTick bottom"
                  style={{
                    left:
                      settlement1 < best1
                        ? (Math.abs(best1 - sortedLimits[0]) / totalDistance) *
                            600 +
                          91
                        : (Math.abs(settlement1 - sortedLimits[0]) /
                            totalDistance) *
                            600 +
                          91,
                  }}
                ></div>
                <div
                  className="zopaTickTop bottom"
                  style={{
                    left:
                      settlement1 < best1
                        ? (Math.abs(best1 - sortedLimits[0]) / totalDistance) *
                            600 +
                          91
                        : (Math.abs(settlement1 - sortedLimits[0]) /
                            totalDistance) *
                            600 +
                          91,
                  }}
                ></div>
                <WhiteTooltip
                  textAttribute={
                    best2 < settlement2 ? 'tooltip-56' : 'tooltip-00'
                  }
                  id={'bestSettlementForClientTextRight-tooltip'}
                  title={
                    best2 < settlement2
                      ? hasIncurred1
                        ? `${getText('tooltip-56', user.settings)}${
                            scenarioSnapshot.currentSnapshot.hasInsurance
                              ? ` ${getText('tooltip-57', user.settings)}`
                              : ''
                          } ${getText(
                            'tooltip-59',
                            user.settings,
                            partiesFormat,
                          )} ${getText(
                            'tooltip-61',
                            user.settings,
                            partiesFormat,
                          )} ${courtName}.`
                        : `${getText(
                            'tooltip-61',
                            user.settings,
                            partiesFormat,
                          )} ${courtName}.`
                      : ''
                  }
                >
                  <p
                    className="zopaTickText bottom right"
                    id="zopaTickTextBottomRight"
                    style={{
                      ...getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'noRoboto',
                        false,
                        props.visibleElements &&
                          props.visibleElements[
                            ExecutiveSummaryVisibleElementsEnum.zopa
                          ] === false
                          ? true
                          : false,
                      ),
                      left:
                        settlement1 < best1
                          ? (Math.abs(best1 - sortedLimits[0]) /
                              totalDistance) *
                              600 +
                            105
                          : (Math.abs(settlement1 - sortedLimits[0]) /
                              totalDistance) *
                              600 +
                            105,
                      width: 170,
                    }}
                    text-dataattribute="description-218"
                  >
                    {settlement1 < best1
                      ? getText('description-218', user.settings, partiesFormat)
                      : getText(
                          'description-217',
                          user.settings,
                          partiesFormat,
                        )}
                  </p>
                </WhiteTooltip>

                {settlement1 < best1 && (
                  <WhiteTooltip
                    textAttribute={
                      best2 < settlement2 ? 'tooltip-56' : 'tooltip-00'
                    }
                    id={'bestSettlementForClientValueRight-tooltip'}
                    title={
                      best2 < settlement2
                        ? hasIncurred1
                          ? `${getText('tooltip-56', user.settings)}${
                              scenarioSnapshot.currentSnapshot.hasInsurance
                                ? ` ${getText('tooltip-57', user.settings)}`
                                : ''
                            } ${getText(
                              'tooltip-59',
                              user.settings,
                              partiesFormat,
                            )} ${getText(
                              'tooltip-61',
                              user.settings,
                              partiesFormat,
                            )} ${courtName}.`
                          : `${getText(
                              'tooltip-61',
                              user.settings,
                              partiesFormat,
                            )} ${courtName}.`
                        : ''
                    }
                  >
                    <p
                      className="zopaTickNumber right client robotoNumbers"
                      id="zopaTickNumberBottomRight"
                      style={{
                        ...getStyleFromWhitelabel(
                          props.reportWhitelabel,
                          ReportWhitelabelKeys.sectionDescription,
                          FontLabels.regular,
                          'roboto',
                          false,
                          props.visibleElements &&
                            props.visibleElements[
                              ExecutiveSummaryVisibleElementsEnum.zopa
                            ] === false
                            ? true
                            : false,
                        ),
                        left:
                          (Math.abs(best1 - sortedLimits[0]) / totalDistance) *
                            600 +
                          99,
                      }}
                    >
                      {stringAndRoundBigNumbers(
                        best1,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(scenarioSnapshot.currentSnapshot),
                      )}{' '}
                    </p>
                  </WhiteTooltip>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="zopaLegendContainer">
        <div className="zopaLegendRow">
          <div
            className={`zopaLegendColor opposingParty ${
              krogerusColors ? 'krogerus' : ''
            }`}
          ></div>
          <div
            className="zopaLegendText"
            id="zopaLegendTextOpposingParties"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            data-textattribute="description-211"
          >
            {getText('description-211', user.settings, partiesFormat)}
          </div>
        </div>
        <div className="zopaLegendRow">
          <div
            className={`zopaLegendColor black ${
              krogerusColors ? 'krogerus' : ''
            }`}
          ></div>
          <div
            className="zopaLegendText"
            id="zopaLegendTextZopa"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            data-textattribute="description-212"
          >
            {getText('description-212', user.settings, partiesFormat)}
          </div>
        </div>
        <div className="zopaLegendRow">
          <div
            className={`zopaLegendColor client ${
              krogerusColors ? 'krogerus' : ''
            }`}
          ></div>
          <div
            className="zopaLegendText"
            id="zopaLegendTextClient"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            data-textattribute="description-213"
          >
            {getText('description-213', user.settings, partiesFormat)}
          </div>
        </div>
      </div>
      {props.fadeGraph && <div className="hiddenSectionLayerContainer"></div>}
    </div>
  )
}
