import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Tabs from '../../Components/tabs/Tabs'
import { SettingsTabs } from '../../models/enums'
import { pageDepthState } from '../../states/PageDepthState'
import AccountDetails from './tabs/1_accountDetails/AccountDetails'
import Preferences from './tabs/2_preferences/Preferences'
import SubscriptionComponent from './tabs/3_subscription/SubscriptionComponent'
import Users from './tabs/4_users/Users'
import { freemiumState } from '../../states/FreemiumState'
import { logActivity } from '../../services/commonFunctions'

const Settings = () => {
  const setPageDepth = useSetRecoilState(pageDepthState)
  const freemium = useRecoilValue(freemiumState)
  const [activeTab, setActiveTab] = useState(
    freemium.isFreemium
      ? SettingsTabs.Preferences
      : SettingsTabs.AccountDetails,
  )

  useEffect(() => {
    setPageDepth(-1)
    //Mixpanel 91 (All)
    logActivity(freemium.isFreemium, 'Accessed Settings')
    // eslint-disable-next-line
  }, [])

  return (
    <div className="toolPageComponent">
      <div className="homescreen">
        <Tabs
          type="SettingsTabs"
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === SettingsTabs.AccountDetails ? (
          <AccountDetails />
        ) : activeTab === SettingsTabs.Preferences ? (
          <Preferences />
        ) : activeTab === SettingsTabs.Subscription ? (
          <SubscriptionComponent />
        ) : activeTab === SettingsTabs.Users ? (
          <Users />
        ) : null}
      </div>
    </div>
  )
}

export default Settings
