import { useState, useRef, useEffect } from 'react'

import HoverComponent from '../../../../Components/InfoHover/HoverComponent/HoverComponent'
import CancelCaseWindow from '../../../../Components/CancelCaseWindow/CancelCaseWindow'

import queuedBlue from '../../../../resources/images/178-loadingQueue.svg'
import calculationsBlue from '../../../../resources/images/177-loadingCalculations.svg'
import saveImg from '../../../../resources/images/179-loadingSave.svg'

import { SnapshotStatus, ToolTabs } from '../../../../models/enums'
import { ScenarioIdentity } from '../../../../models/generalTypes'
import { cancelJob } from '../../../../services/requests'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  calculationMessages,
  calculationMessagesForAWA,
} from '../../../../models/calculationMessages'
import { activeTabsState } from '../../../../states/ActiveTabsState'
import { handlingErrorsState } from '../../../../states/HandlingErrorsState'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../../services/commonFunctions'
import { onlineState } from '../../../../states/OnlineState'
import FailedResults from '../../../../Components/failedResultsComponent/FailedResults'
import { getText } from '../../../../services/textFunctions'
import { userState } from '../../../../states/UserState'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import animate1 from '../../../../resources/images/242-sampleFinder.svg'
import animate2 from '../../../../resources/images/243-sampleFinder.svg'
import animate3 from '../../../../resources/images/244-sampleFinder.svg'
import animate4 from '../../../../resources/images/245-sampleFinder.svg'
import animate5 from '../../../../resources/images/246-sampleFinder.svg'
import animate6 from '../../../../resources/images/247-sampleFinder.svg'
import animate7 from '../../../../resources/images/248-sampleFinder.svg'
import { AnimateSVG } from '../../../../Components/Animations/AnimateSVG'
import { featuresState } from '../../../../states/FeaturesState'
import {
  ANIMATION_DOTS_SPEED,
  AWA_CALCULATION_LIMIT,
} from '../../../../services/constants'
import { AnimationDots } from '../../../../Components/Animations/AnimationDots'
import { freemiumState } from '../../../../states/FreemiumState'
import { cancelFreemiumJob } from '../../../../freemiumServices/freemiumRequests'
import { scenarioIdentityState } from '../../../../states/ScenarioIdentityState'
import { snapshotHasLegalCosts } from '../../../../services/reportFunctions'

type Props = {
  scenarioIdentity: ScenarioIdentity
  cancelBecauseOfUpdateMessage: boolean
  setCancelBecauseOfUpdateMessage: (param: boolean) => void
  fakeQueue: number | undefined
  setFakeQueue: (value: number | undefined) => void
}

export default function LoadingResults(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const features = useRecoilValue(featuresState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)

  const [isCancelCase, setIsCancelCase] = useState(false) // a boolean that toggles the <CancelCaseWindow /> --bool
  const [cancellingMessage, setCancellingMessage] = useState<
    string | undefined
  >(undefined) // a state that defines the cancelling message -- false or str
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature
  const [calculationMessageText, setCalculationMessageText] = useState('')
  const isAwaUsed =
    features.use_statistical_model === true &&
    scenarioSnapshot.currentSnapshot.expectedResults! > AWA_CALCULATION_LIMIT
  const onMouseDown = (e: MouseEvent) => {
    setHoverElement('')

    // eslint-disable-next-line
  }
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [online, setOnline] = useRecoilState(onlineState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioIdentity, setScenarioIdentity] = useRecoilState(
    scenarioIdentityState,
  )
  const lowLimitForSample = snapshotHasLegalCosts(
    scenarioSnapshot.currentSnapshot,
  )
    ? 40
    : 46
  const highLimitForSample = snapshotHasLegalCosts(
    scenarioSnapshot.currentSnapshot,
  )
    ? 85
    : 89

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  async function stopAnalysis() {
    if (online.networkOn) {
      //id='stoppingAnalysisMessage-loadingResults'
      //data-textattribute='message-1'
      setCancellingMessage(getText('message-1', user.settings))

      if (freemium.isFreemium && props.fakeQueue !== undefined) {
        setStateAfterStopAnalysis(true)
        return
      }

      const res = freemium.isFreemium
        ? await cancelFreemiumJob()
        : await cancelJob(
            props.scenarioIdentity.caseId,
            props.scenarioIdentity.scenarioId,
            props.scenarioIdentity.snapshotId,
          )
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'cancelJob', true),
      )
      if (!('errorCode' in res)) {
        setStateAfterStopAnalysis(false)
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const setStateAfterStopAnalysis = (fake: boolean) => {
    setTimeout(
      () => {
        //id='stoppedAnalysisMessage-loadingResults'
        //data-textattribute='message-3'
        setCancellingMessage(getText('message-3', user.settings))
        props.setCancelBecauseOfUpdateMessage(false)

        if (fake) {
          setScenarioIdentity({
            ...scenarioIdentity,
            snapshotStatus: SnapshotStatus.Cancelled,
          })
          props.setFakeQueue(undefined)
        }

        setTimeout(() => {
          setActiveTabs({
            tab: ToolTabs.proceedings,
            claim: activeTabs.claim,
          })
        }, 500)
      },
      fake ? 300 : 0,
    )
    //Mixpanel 68 (All)
    logActivity(freemium.isFreemium, 'Stopped a scenario analysis', {
      'Stopped Analysis Location': 'Loading Results Page',
    })
  }

  useEffect(() => {
    setCalculationMessageText(findCalculationMessages())
    // eslint-disable-next-line
  }, [props.scenarioIdentity.snapshotProgress])

  function findCalculationMessages() {
    const x = props.scenarioIdentity.snapshotProgress as number
    let tempMessage = ''
    if (isAwaUsed) {
      switch (true) {
        case x <= 40:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[40]
          break
        case x <= 47:
          if (snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
            tempMessage = calculationMessagesForAWA(
              user.settings,
              partiesFormat,
            )[47]
          } else {
            tempMessage = calculationMessagesForAWA(
              user.settings,
              partiesFormat,
            )[48]
          }
          break
        case x <= 48:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[48]
          break
        case x <= 49:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[49]
          break
        case x <= 50:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[50]
          break
        case x <= 85:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[85]
          break
        case x <= 89:
          if (snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
            tempMessage = calculationMessagesForAWA(
              user.settings,
              partiesFormat,
            )[89]
          } else {
            tempMessage = calculationMessagesForAWA(
              user.settings,
              partiesFormat,
            )[85]
          }
          break
        case x <= 93:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[93]
          break
        case x <= 99:
          tempMessage = calculationMessagesForAWA(
            user.settings,
            partiesFormat,
          )[99]
          break
        default:
          break
      }
    } else {
      switch (true) {
        case x <= 13:
          tempMessage = calculationMessages(user.settings, partiesFormat)[13]
          break
        case x <= 14:
          if (scenarioSnapshot.currentSnapshot.hasSecondTrial) {
            tempMessage = calculationMessages(user.settings, partiesFormat)[14]
          } else {
            tempMessage = calculationMessages(user.settings, partiesFormat)[13]
          }
          break
        case x <= 24:
          if (snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
            tempMessage = calculationMessages(user.settings, partiesFormat)[24]
          } else {
            if (scenarioSnapshot.currentSnapshot.hasSecondTrial) {
              tempMessage = calculationMessages(
                user.settings,
                partiesFormat,
              )[14]
            } else {
              tempMessage = calculationMessages(
                user.settings,
                partiesFormat,
              )[13]
            }
          }
          break
        case x <= 26:
          if (scenarioSnapshot.currentSnapshot.hasBankruptcy) {
            tempMessage = calculationMessages(user.settings, partiesFormat)[26]
          } else {
            if (snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
              tempMessage = calculationMessages(
                user.settings,
                partiesFormat,
              )[24]
            } else {
              if (scenarioSnapshot.currentSnapshot.hasSecondTrial) {
                tempMessage = calculationMessages(
                  user.settings,
                  partiesFormat,
                )[14]
              } else {
                tempMessage = calculationMessages(
                  user.settings,
                  partiesFormat,
                )[13]
              }
            }
          }
          break
        case x <= 28:
          if (scenarioSnapshot.currentSnapshot.hasInsurance) {
            tempMessage = calculationMessages(user.settings, partiesFormat)[28]
          } else {
            if (scenarioSnapshot.currentSnapshot.hasBankruptcy) {
              tempMessage = calculationMessages(
                user.settings,
                partiesFormat,
              )[26]
            } else {
              if (snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
                tempMessage = calculationMessages(
                  user.settings,
                  partiesFormat,
                )[24]
              } else {
                if (scenarioSnapshot.currentSnapshot.hasSecondTrial) {
                  tempMessage = calculationMessages(
                    user.settings,
                    partiesFormat,
                  )[14]
                } else {
                  tempMessage = calculationMessages(
                    user.settings,
                    partiesFormat,
                  )[13]
                }
              }
            }
          }
          break
        case x <= 49:
          tempMessage = calculationMessages(user.settings, partiesFormat)[49]
          break
        case x <= 63:
          tempMessage = calculationMessages(user.settings, partiesFormat)[63]
          break
        case x <= 64:
          if (scenarioSnapshot.currentSnapshot.hasSecondTrial) {
            tempMessage = calculationMessages(user.settings, partiesFormat)[64]
          } else {
            tempMessage = calculationMessages(user.settings, partiesFormat)[63]
          }
          break
        case x <= 74:
          if (snapshotHasLegalCosts(scenarioSnapshot.currentSnapshot)) {
            tempMessage = calculationMessages(user.settings, partiesFormat)[74]
          } else {
            if (scenarioSnapshot.currentSnapshot.hasSecondTrial) {
              tempMessage = calculationMessages(
                user.settings,
                partiesFormat,
              )[64]
            } else {
              tempMessage = calculationMessages(
                user.settings,
                partiesFormat,
              )[63]
            }
          }
          break
        case x <= 99:
          tempMessage = calculationMessages(user.settings, partiesFormat)[99]
          break
        default:
          break
      }
    }
    return tempMessage
  }

  return (
    <>
      <div className="loadingResultsContainer">
        {cancellingMessage ? (
          <div className="cancellingMessageContainer">
            {cancellingMessage}
            {cancellingMessage === getText('message-1', user.settings) ? (
              <AnimationDots speed={ANIMATION_DOTS_SPEED} />
            ) : null}
          </div>
        ) : props.scenarioIdentity.snapshotStatus === 'Failed' ? (
          <FailedResults scenarioIdentity={props.scenarioIdentity} />
        ) : (
          <>
            <div className="progressImagesContainer">
              <div
                className="progressImageInnerContainer"
                style={
                  isAwaUsed === false ||
                  (isAwaUsed &&
                    props.scenarioIdentity.snapshotProgress !== undefined &&
                    ((props.scenarioIdentity.snapshotProgress >= 5 &&
                      props.scenarioIdentity.snapshotProgress <=
                        lowLimitForSample) ||
                      (props.scenarioIdentity.snapshotProgress >= 55 &&
                        props.scenarioIdentity.snapshotProgress <=
                          highLimitForSample)) === false)
                    ? { display: 'none' }
                    : undefined
                }
              >
                <AnimateSVG
                  svgImages={[
                    animate1,
                    animate2,
                    animate3,
                    animate4,
                    animate5,
                    animate6,
                    animate7,
                  ]}
                  imagesChangeSpeed={400}
                  className={'statusImage calculate'}
                />
              </div>
              {props.scenarioIdentity.snapshotStatus ===
              SnapshotStatus.CalculatingTrees ? (
                <>
                  <div className="progressImageInnerContainer">
                    <img
                      src={calculationsBlue}
                      alt="queuedBlueImg"
                      className="statusImage queue"
                    />
                  </div>
                  <p
                    className="progressImageLabel"
                    id="progressImageLabel-trees"
                    data-textattribute="message-35"
                  >
                    {getText('message-35', user.settings)}
                    <AnimationDots speed={ANIMATION_DOTS_SPEED} />
                  </p>
                </>
              ) : props.scenarioIdentity.snapshotStatus ===
                SnapshotStatus.Saving ? (
                <>
                  <div className="progressImageInnerContainer">
                    <img
                      src={saveImg}
                      alt="queuedBlueImg"
                      className="statusImage queue"
                    />
                  </div>
                  <p
                    className="progressImageLabel"
                    id="progressImageLabel-saving"
                    data-textattribute="message-36"
                  >
                    {getText('message-36', user.settings)}
                    <AnimationDots speed={ANIMATION_DOTS_SPEED} />
                  </p>
                </>
              ) : props.scenarioIdentity.snapshotStatus ===
                SnapshotStatus.Queuing ? (
                <>
                  <div className="progressImageInnerContainer">
                    <img
                      src={queuedBlue}
                      alt="queuedBlueImg"
                      className="statusImage queue"
                    />
                  </div>
                  <p
                    className="progressImageLabel"
                    id="progressImageLabel-SendingQueue"
                    data-textattribute="message-37"
                  >
                    {getText('message-37', user.settings)}
                    <AnimationDots speed={ANIMATION_DOTS_SPEED} />
                  </p>
                </>
              ) : props.scenarioIdentity.snapshotStatus ===
                SnapshotStatus.Queued ? (
                <>
                  <div className="progressImageInnerContainer">
                    <img
                      src={queuedBlue}
                      alt="queuedBlueImg"
                      className="statusImage queue"
                    />
                  </div>
                  <p
                    className="progressImageLabel"
                    id="progressImageLabel-1ueue"
                    data-textattribute="message-38, message-128"
                  >
                    {getText('message-38', user.settings)}
                    {props.scenarioIdentity.queueNumber
                      ? `. ${getText('message-128', user.settings)} ${
                          props.scenarioIdentity.queueNumber
                        }`
                      : ''}
                    <AnimationDots speed={ANIMATION_DOTS_SPEED} />
                  </p>
                </>
              ) : props.scenarioIdentity.snapshotStatus ===
                  SnapshotStatus.Processing ||
                SnapshotStatus.ProcessingReversed ? (
                <>
                  {isAwaUsed === false ||
                  (isAwaUsed &&
                    props.scenarioIdentity.snapshotProgress !== undefined &&
                    (props.scenarioIdentity.snapshotProgress <=
                      lowLimitForSample ||
                      (props.scenarioIdentity.snapshotProgress >= 55 &&
                        props.scenarioIdentity.snapshotProgress <=
                          highLimitForSample)) === false) ? (
                    <div className="progressImageInnerContainer">
                      <img
                        src={calculationsBlue}
                        alt="calculationsBlueImg"
                        className="statusImage calculate"
                      />
                    </div>
                  ) : null}

                  <p className="progressImageLabel">
                    {calculationMessageText}
                    <AnimationDots speed={ANIMATION_DOTS_SPEED} />
                  </p>
                </>
              ) : null}
              {props.cancelBecauseOfUpdateMessage ? (
                <p
                  className="cancelBecauseOfUpdateMessageContainer"
                  id="cancelBecauseOfUpdateMessage-loadingResults"
                  data-textattribute="message-65"
                >
                  {getText('message-65', user.settings)}
                </p>
              ) : null}
            </div>
            <div className="progressContainer">
              <div className="progressBarContainer">
                <div className="progressBarBackground">
                  <div
                    className="progressBar"
                    style={{
                      width: `${props.scenarioIdentity.snapshotProgress}%`,
                    }}
                  ></div>
                </div>

                {props.scenarioIdentity.snapshotStatus === 'Done' ? null : (
                  <>
                    {props.scenarioIdentity.snapshotStatus ===
                      SnapshotStatus.CalculatingTrees ||
                    props.scenarioIdentity.snapshotStatus ===
                      SnapshotStatus.Saving ||
                    props.scenarioIdentity.snapshotStatus ===
                      SnapshotStatus.Queuing ? null : (
                      <div
                        className="cancelContainer"
                        onMouseEnter={() => {
                          secondaryHover.current = 'cancelButton'
                          setTimeout(() => {
                            if (secondaryHover.current === 'cancelButton') {
                              setHoverElement('cancelButton')
                            }
                          }, 1000)
                        }}
                        onMouseLeave={() => {
                          secondaryHover.current = 'close'
                          setHoverElement('')
                        }}
                      >
                        {hoverElement === 'cancelButton' ? (
                          <div
                            className="hoverComponentContainer"
                            style={{ top: '35px', left: '-40px' }}
                          >
                            <HoverComponent
                              hoverText={getText('hover-24', user.settings)}
                              textAttribute="hover-24"
                              id="cancelButton"
                              style={{ width: '112px' }}
                            />
                          </div>
                        ) : null}
                        <div
                          className="cancelCase"
                          onClick={() => {
                            setIsCancelCase(true)
                          }}
                        >
                          <div
                            className="cancelCaseSquare"
                            onClick={() => {
                              setIsCancelCase(true)
                            }}
                          ></div>
                        </div>
                      </div>
                    )}
                    {isCancelCase ? (
                      <>
                        <CancelCaseWindow
                          stopAnalysis={stopAnalysis}
                          cancelFunction={() => {
                            setIsCancelCase(false)
                            setHoverElement('')
                          }}
                          position={[-100, -340]}
                        />
                        <div
                          className="transparent-Background"
                          onMouseUp={() => {
                            setIsCancelCase(false)
                            setHoverElement('')
                          }}
                        ></div>
                      </>
                    ) : null}
                  </>
                )}
              </div>
              <p
                className="progressText"
                id="progressText-loadingResults"
                data-textattribute="message-39"
              >
                {getText('message-39', user.settings)}{' '}
                {
                  // props.scenarioIdentity.snapshotStatus ===
                  //   SnapshotStatus.CalculatingTrees ||
                  // props.scenarioIdentity.snapshotStatus ===
                  //   SnapshotStatus.Saving ||
                  // props.scenarioIdentity.snapshotStatus ===
                  //   SnapshotStatus.Queuing ||
                  // props.scenarioIdentity.snapshotStatus === SnapshotStatus.Queued
                  //   ? 0
                  //   :
                  props.scenarioIdentity.snapshotProgress
                }
                %
              </p>
            </div>
          </>
        )}
      </div>
    </>
  )
}
