import { useState } from 'react'

import { AccessRole } from '../../../models/enums'
import { LegalFeesDistributionObject } from '../../../models/legalFeesDistributionObject'
import NewLegalFeesRange from './SingleSegmentComponents/NewLegalFeesRange'

type Props = {
  feeDistributions: LegalFeesDistributionObject[]
  singleSegmentIndex: number
  totalCounterClaimedAmount: number
  disputedAmount: number
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  pdfPreview?: boolean
  ownRole?: AccessRole
  absoluteSegmentHeight: { segmentIndex: number; top: number; height: number }
}

export default function NewSingleSegment(props: Props) {
  const [changeEffect, setChangeEffect] = useState(false) // a boolean that is responsible for the animation effect when the range is changing -- bool

  return (
    <>
      <div
        className={
          props.pdfPreview
            ? 'singleSegmentContainerNew pdfPreviewSingleSegment'
            : 'singleSegmentContainerNew'
        }
        data-openreplay-obscured
      >
        <NewLegalFeesRange
          singleSegmentIndex={props.singleSegmentIndex}
          feeDistributions={props.feeDistributions}
          changeEffect={changeEffect}
          setChangeEffect={setChangeEffect}
          pdfPreview={props.pdfPreview}
          isFirstLoad={props.isFirstLoad}
          setIsFirstLoad={props.setIsFirstLoad}
          ownRole={props.ownRole}
          totalCounterClaimedAmount={props.totalCounterClaimedAmount}
          disputedAmount={props.disputedAmount}
          absoluteSegmentHeight={props.absoluteSegmentHeight}
        />
      </div>
    </>
  )
}
