import React, { useEffect } from 'react'
import '../HoverComponent.css'
import infoCircle from '../../../resources/images/062-infoCircleGray.svg'
import infoCircleWhite from '../../../resources/images/066-infoCircleWhiteBlue.svg'
import { getText } from '../../../services/textFunctions'
import englishTexts from '../../../resources/texts/englishTexts.json'
import { userState } from '../../../states/UserState'
import { useRecoilValue } from 'recoil'
import { freemiumState } from '../../../states/FreemiumState'
import { logActivity } from '../../../services/commonFunctions'

type Props = {
  infoClickText: string
  infoClickTextAttribute:
    | `description-${number}`
    | `message-${number}`
    | `infoButton-${number}`
    | `infoButton-${string}`
    | undefined
  idName: string
  hoverElement: string
  setHoverElement: (hoverElement: string) => void
  style?: React.CSSProperties
  whiteCircle?: boolean
  width?: string
  noHelperText?: boolean
  withTitles?: boolean
  titlesAndTexts?: Record<string, keyof typeof englishTexts>
  titlesAndTextsAttributes?: [`title-${number}`, `description-${number}`][]
}

export default function InfoClickComponent(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (props.hoverElement === `${props.idName}InfoCircle`) {
      //Mixpanel 43 (All)
      logActivity(freemium.isFreemium, 'Pressed info button', {
        'Info Button Id': props.idName,
        'Info Button Text':
          typeof props.infoClickText === 'string'
            ? props.infoClickText.replaceAll('\u200B', '')
            : props.infoClickText,
      })
    }
    // eslint-disable-next-line
  }, [props.hoverElement])

  const finalText = props.infoClickText.includes('\\n') ? (
    <>
      <p>{props.infoClickText.split('\\n')[0]}</p>
      <p>{props.infoClickText.split('\\n')[1]}</p>
    </>
  ) : (
    props.infoClickText
  )

  return (
    <>
      <div
        className={
          props.noHelperText
            ? 'infoClickComponentContainer noHelperText'
            : 'infoClickComponentContainer'
        }
      >
        <img
          src={props.whiteCircle ? infoCircleWhite : infoCircle}
          alt="infoCircle"
          className="infoCircle"
          style={props.style}
          id={`${props.idName}InfoCircle`}
          onClick={() => props.setHoverElement(`${props.idName}InfoCircle`)}
        />
        {props.hoverElement === `${props.idName}InfoCircle` ? (
          <>
            <div className="infoClickTransparent"></div>
            <div
              className={`infoClickTextContainer ${
                props.withTitles ? 'withTitle' : ''
              }`}
              style={{ width: props.width }}
            >
              {props.withTitles ? (
                Object.keys(props.titlesAndTexts!).map((title, index) => (
                  <div key={index}>
                    <div
                      className="infoClickTitle"
                      id={`infoClickTitle-${props.idName}-${title}`}
                      data-textattribute={
                        props.titlesAndTextsAttributes![index][0]
                      }
                      data-openreplay-obscured
                    >
                      {title}
                    </div>
                    <div
                      className="infoClickText withTitle"
                      id={`infoClickText-${props.idName}-${title}`}
                      data-textattribute={
                        props.titlesAndTextsAttributes![index][1]
                      }
                    >
                      {getText(props.titlesAndTexts![title], user.settings)}
                    </div>
                  </div>
                ))
              ) : (
                <div
                  className="infoClickText"
                  id={`infoClickText-${props.idName}`}
                  data-textattribute={props.infoClickTextAttribute}
                  data-openreplay-obscured
                >
                  {finalText}
                </div>
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}
