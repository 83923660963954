import { SnapshotStatus, snapshotStatusFromString } from '../models/enums'
import {
  FreemiumPreferences,
  StorageCase,
} from '../models/freemiumGeneralTypes'
import { ResultsObject, ScenarioIdentity } from '../models/generalTypes'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { UserChecklist } from '../models/userChecklist'
import { UserSettings } from '../models/userSettings'
import {
  getFreemiumCase,
  getFreemiumPreferences,
  getUseridFromCookies,
  getUseridFromStorage,
  saveFreemiumCase,
  saveUseridToCookies,
  saveUseridToStorage,
} from './freemiumStorage'

export const getCaseFromStorage = () => {
  let caseExists = false
  let data = undefined

  const storageCase = getFreemiumCase()
  if (!storageCase) {
    return undefined
  }

  try {
    data = JSON.parse(storageCase)
    caseExists =
      Object.keys(data).includes('caseName') &&
      Object.keys(data).includes('caseId') &&
      Object.keys(data).includes('caseDateCreated')
  } catch (error) {
    return undefined
  }

  if (!caseExists) {
    return undefined
  }

  return data as StorageCase
}

export const getScenarioFromStorage = () => {
  let caseExists = false
  let data = undefined

  const storageCase = getFreemiumCase()
  if (!storageCase) {
    return undefined
  }

  try {
    data = JSON.parse(storageCase)
    caseExists =
      Object.keys(data).includes('caseName') &&
      Object.keys(data).includes('caseId') &&
      Object.keys(data).includes('caseDateCreated') &&
      Object.keys(data).includes('scenarioName') &&
      Object.keys(data).includes('scenarioId') &&
      Object.keys(data).includes('scenarioDateCreated') &&
      Object.keys(data).includes('snapshotId') &&
      Object.keys(data).includes('snapshot')
  } catch (error) {
    return undefined
  }

  if (!caseExists) {
    return undefined
  }

  return data as StorageCase
}

export const createIdentityFromStorage = (storageScenario: StorageCase) => {
  return {
    caseId: storageScenario.caseId,
    caseName: storageScenario.caseName,
    scenarioId: storageScenario.scenarioId!,
    scenarioName: storageScenario.scenarioName!,
    snapshotId: storageScenario.snapshotId!,
    snapshotStatus:
      storageScenario.results && storageScenario.validResults
        ? SnapshotStatus.Done
        : SnapshotStatus.None,
    snapshotProgress: 0,
    results:
      storageScenario.results && storageScenario.validResults
        ? storageScenario.results
        : undefined,
    hasReport: false,
  }
}

export const freemiumCreateIdentityFromDb = (
  item: any,
  currentIdentity: ScenarioIdentity,
) => {
  return {
    caseId: currentIdentity.caseId,
    caseName: currentIdentity.caseName,
    scenarioId: currentIdentity.scenarioId,
    scenarioName: currentIdentity.scenarioName,
    snapshotId: currentIdentity.snapshotId,
    snapshotStatus: item.status
      ? snapshotStatusFromString(item.status)
      : SnapshotStatus.None,
    snapshotProgress: item.progress < 5 ? 5 : item.progress,
    results: item.result_data ? item.result_data : undefined,
    hasReport: item.has_report,
    queueNumber:
      item.queue_number !== undefined ? item.queue_number : undefined,
  }
}

export const saveNewFreemiumSnapshot = (
  snapshot: ScenarioSnapshot,
  keepResults: boolean,
) => {
  let storageScenario = getScenarioFromStorage()
  if (storageScenario) {
    storageScenario.snapshot = snapshot
    storageScenario.validResults = keepResults
    saveFreemiumCase(storageScenario)
  }
}

export const saveFreemiumResults = (results: ResultsObject) => {
  let storageScenario = getScenarioFromStorage()
  if (storageScenario) {
    storageScenario.validResults = true
    storageScenario.results = results
    saveFreemiumCase(storageScenario)
  }
}

export const getPreferencesFromStorage = (): FreemiumPreferences => {
  const stringPrefs = getFreemiumPreferences()

  if (stringPrefs) {
    try {
      const data = JSON.parse(stringPrefs)
      const prefs = {
        settings: UserSettings.FromJson(data.settings),
        checklist: UserChecklist.FromJson(data.checklist),
      }

      return prefs
    } catch (error) {}
  }

  return {
    settings: UserSettings.FromJson(),
    checklist: UserChecklist.FromJson(),
  }
}

const syncUserid = () => {
  const storageUserid = getUseridFromStorage()
  const cookiesUserid = getUseridFromCookies()

  if (!storageUserid && !cookiesUserid) {
    return
  }

  if (storageUserid !== cookiesUserid) {
    if (!storageUserid) {
      saveUseridToStorage(cookiesUserid)
    } else if (!cookiesUserid) {
      saveUseridToCookies(storageUserid)
    }
  }
}

export const syncAndGetUserid = () => {
  syncUserid()

  return getUseridFromCookies()
}

export const syncAndSaveUserid = (userid: string) => {
  saveUseridToStorage(userid)
  saveUseridToCookies(userid)
}
