import { useEffect, useState } from 'react'
import Button from '../../../Components/Buttons/Button/Button'
import { AccessRole, ToolTabs } from '../../../models/enums'
import MoreOptionsPopUp from './MoreOptionsPopUp'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { activeTabsState } from '../../../states/ActiveTabsState'
import { onlineState } from '../../../states/OnlineState'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'
import {
  duplicateScenario,
  getScenarios,
  saveScenario,
  saveScenarioSnapshot,
} from '../../../services/requests'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../services/commonFunctions'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import { savedState } from '../../../states/SavedState'
import { useParams } from 'react-router-dom'
import { Params } from '../../../models/generalTypes'
import { reverseCase } from '../../../services/reverseCaseFunctions'
import { allowShortcutsState } from '../../../states/AllowShortcutsState'
import { userState } from '../../../states/UserState'
import { getText } from '../../../services/textFunctions'
import { globalPopUpsState } from '../../../states/GlobalPopUps'
import { freemiumState } from '../../../states/FreemiumState'
import PremiumFeatureIcon from '../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  ownRole: AccessRole
}

const HeaderMore = (props: Props) => {
  const params = useParams<Params>()

  const setActiveTabs = useSetRecoilState(activeTabsState)
  const [online, setOnline] = useRecoilState(onlineState)
  const [globalPopUps, setGlobalPopUps] = useRecoilState(globalPopUpsState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const user = useRecoilValue(userState)
  const savedSnapshot = useRecoilValue(savedState)
  const setAllowShortcuts = useSetRecoilState(allowShortcutsState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const [showMenu, setShowMenu] = useState(false)
  const [showPopUp, setShowPopUp] = useState('')

  const closeMenu = (e: any) => {
    if (showMenu && !e.target.id.includes('moreMenuButton')) {
      setShowMenu(false)
    }
  }

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setShowMenu(false)
    }
  }

  useEffect(() => {
    if (globalPopUps) {
      setAllowShortcuts(false)
      setShowPopUp('otherView')
      setGlobalPopUps(false)
    }
    // eslint-disable-next-line
  }, [globalPopUps])

  useEffect(() => {
    document.addEventListener('click', closeMenu)
    document.addEventListener('keydown', onKeyPress, false)

    return () => {
      document.removeEventListener('click', closeMenu)
      document.addEventListener('keydown', onKeyPress, false)
    }

    // eslint-disable-next-line
  }, [showMenu])

  useEffect(() => {
    if (document.getElementById('checklistContainer')) {
      document.getElementById('checklistContainer')!.style.zIndex =
        showPopUp !== '' ? '0' : '900'
    }
    if (document.getElementById('sideMenuContainer')) {
      document.getElementById('sideMenuContainer')!.style.zIndex =
        showPopUp !== '' ? '0' : '11'
    }

    // eslint-disable-next-line
  }, [showPopUp])

  async function handleSaveAsNewScenario(
    newScenarioName: string,
    reversed?: boolean,
  ) {
    if (!online.networkOn) {
      setOnline({ ...online, shaking: true })
      return 'error'
    }

    const res = await getScenarios(params.caseId!)
    setHandlingErrors(
      findHandlingErrorState(res, handlingErrors, 'getScenarios'),
    )

    if ('errorCode' in res) {
      return 'error'
    }

    let caseExists = false
    for (let scenario in res.data) {
      if (
        res.data[scenario]['name'].toLowerCase() ===
        newScenarioName.toLowerCase()
      ) {
        caseExists = true
        break
      }
    }
    if (caseExists) {
      return 'exists'
    }

    if (!reversed) {
      const res2 = await duplicateScenario(
        params.caseId!,
        params.scenarioId!,
        newScenarioName,
        params.caseId!,
      )
      setHandlingErrors(
        findHandlingErrorState(res2, handlingErrors, 'duplicateScenario'),
      )
      if ('errorCode' in res2) {
        return 'error'
      }

      const scenarioId = res2.data['caseid']

      if (!savedSnapshot.saved) {
        const res3 = await saveScenarioSnapshot(params.caseId!, scenarioId, {
          data: scenarioSnapshot.currentSnapshot,
          keep_results: savedSnapshot.keepResults,
          log_entry: 'Saved as new scenario',
        })
        setHandlingErrors(
          findHandlingErrorState(res3, handlingErrors, 'saveScenarioSnapshot'),
        )
        if ('errorCode' in res3) {
          return 'error'
        }
      }

      setActiveTabs({
        tab: ToolTabs.proceedings,
        claim: 0,
      })

      //Mixpanel 60
      logActivity(false, 'Saved as new scenario')

      return `/mycases/${params.caseId}/${scenarioId}/tool`
    } else {
      const res2 = await saveScenario(params.caseId!, newScenarioName)
      setHandlingErrors(
        findHandlingErrorState(res2, handlingErrors, 'saveScenario'),
      )
      if ('errorCode' in res2) {
        return 'error'
      }

      const scenarioId = res2.data['caseid']

      const res3 = await saveScenarioSnapshot(params.caseId!, scenarioId, {
        data: reverseCase(scenarioSnapshot.currentSnapshot),
        keep_results: false,
        log_entry: 'Reversed case',
      })
      setHandlingErrors(
        findHandlingErrorState(res3, handlingErrors, 'saveScenarioSnapshot'),
      )
      if ('errorCode' in res3) {
        return 'error'
      }

      //Mixpanel 169
      logActivity(
        false,
        'Created a scenario from the other party point of view',
      )

      return `/mycases/${params.caseId}/${scenarioId}/tool`
    }
  }

  return (
    <>
      <div className="saveAsButtonContainer">
        <Button
          buttonText={getText('button-149', user.settings)}
          buttonTextAttribute="button-149"
          buttonType={`${
            props.ownRole === AccessRole.VIEWER ? 'contained' : 'outlined'
          }`}
          type="button"
          id="moreMenuButton"
          disabled={props.ownRole === AccessRole.VIEWER}
          onClick={() => {
            if (!showMenu) {
              //Mixpanel 170 (All)
              logActivity(freemium.isFreemium, 'Clicked more on header')
            }
            setShowMenu((prevValue) => !prevValue)
          }}
          style={{ padding: '8px 10px 7px 10px' }}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      </div>
      {showMenu && (
        <div className="moreMenu">
          <div
            className="moreMenuOption"
            id="moreMenuOption-first"
            onClick={() => {
              if (freemium.isFreemium) {
                setFreemium({
                  ...freemium,
                  showMessage: FreemiumMessageType.General,
                })
              } else {
                setAllowShortcuts(false)
                setShowPopUp('newScenario')
              }
            }}
          >
            <p
              className="moreMenuText first"
              data-textattribute="submenu-20"
              id="moreMenuText-first"
            >
              {getText('submenu-20', user.settings)}
            </p>
          </div>
          {freemium.isFreemium && (
            <PremiumFeatureIcon targetId="moreMenuOption-first" forMoreMenu />
          )}
          <div className="moreMenuOption" id="moreMenuOption-second">
            <p
              className="moreMenuText last"
              onClick={() => {
                if (freemium.isFreemium) {
                  setFreemium({
                    ...freemium,
                    showMessage: FreemiumMessageType.General,
                  })
                } else {
                  setAllowShortcuts(false)
                  setShowPopUp('otherView')
                }
              }}
              id="moreMenuText-second"
              data-textattribute="submenu-21"
            >
              {getText('submenu-21', user.settings, partiesFormat)}
            </p>
          </div>
          {freemium.isFreemium && (
            <PremiumFeatureIcon targetId="moreMenuOption-second" forMoreMenu />
          )}
        </div>
      )}
      {showPopUp !== '' && (
        <MoreOptionsPopUp
          showPopUp={showPopUp}
          setShowPopUp={setShowPopUp}
          handleSaveAsNewScenario={handleSaveAsNewScenario}
        />
      )}
    </>
  )
}

export default HeaderMore
