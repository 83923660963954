import React, { useState, useRef, useEffect } from 'react'

import Button from '../../../Components/Buttons/Button/Button'
import HoverComponent from '../../../Components/InfoHover/HoverComponent/HoverComponent'

import savePurpleImg from '../../../resources/images/109-saveIconPurple.svg'
import saveAsImgDisabled from '../../../resources/images/195-saveAsImgDisabled.svg'

import { useRecoilValue } from 'recoil'
import { isMac, logActivity } from '../../../services/commonFunctions'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import { getText } from '../../../services/textFunctions'
import { AccessRole } from '../../../models/enums'
import { userState } from '../../../states/UserState'
import { reportState } from '../../../states/ReportState'

import HeaderMore from './HeaderMore'
import { allowShortcutsState } from '../../../states/AllowShortcutsState'
import { AnimationDots } from '../../../Components/Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../../services/constants'

type Props = {
  saving: string
  handleSave: (redirectBoolean: boolean) => void
  showSaveAsAndUndo: boolean
  savedMessage: string | undefined
  isSaveAsNew: boolean
  setIsSaveAsNew: (param: boolean) => void
  savedState: boolean
  hideSave?: boolean
  ownRole: AccessRole
}

export default function HeaderSave(props: Props) {
  const user = useRecoilValue(userState)

  const snapshot = useRecoilValue(scenarioSnapshotState)
  const report = useRecoilValue(reportState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature
  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  const onKeyPress_S = (e: KeyboardEvent) => {
    if (allowShortcuts) {
      if (
        (e.code === 'KeyS' && e.ctrlKey && !isMac()) ||
        (e.code === 'KeyS' && e.metaKey && isMac())
      ) {
        e.preventDefault()
        if (!props.savedState && props.saving === '') {
          props.handleSave(false)
        }
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', onKeyPress_S)

    return () => {
      document.removeEventListener('keydown', onKeyPress_S)
    }
    //eslint-disable-next-line
  }, [
    props.savedState,
    snapshot.currentSnapshot,
    props.saving,
    report,
    allowShortcuts,
  ])

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  return (
    <>
      {props.saving !== '' ? (
        <div className="savingCaseMessageBackground" id="savingTreeMessage">
          <div className="savingCaseMessageContainer">
            <p className="savingCaseMessageText">
              {props.saving}
              <AnimationDots speed={ANIMATION_DOTS_SPEED} />
            </p>
          </div>
        </div>
      ) : (
        <div className="saveCaseContainer">
          {props.showSaveAsAndUndo ? (
            <HeaderMore ownRole={props.ownRole} />
          ) : null}
          {!props.hideSave ? (
            <div className="saveButtonContainer">
              <div
                className="saveButton"
                onMouseEnter={() => {
                  secondaryHover.current = 'saveButton'
                  setTimeout(() => {
                    if (secondaryHover.current === 'saveButton') {
                      setHoverElement('saveButton')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                {hoverElement === 'saveButton' && !props.savedState ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '50px', right: '0px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-60', user.settings)}
                      textAttribute="hover-60"
                      id="saveButton"
                      style={{ width: '210px' }}
                    />
                  </div>
                ) : null}
                <Button
                  buttonText={
                    props.savedState
                      ? getText('button-71', user.settings)
                      : getText('button-72', user.settings)
                  }
                  buttonTextAttribute={
                    props.savedState ? 'button-71' : 'button-72'
                  }
                  buttonType={
                    props.savedState
                      ? props.ownRole === AccessRole.VIEWER
                        ? 'contained'
                        : 'nonactiveText'
                      : 'outlined'
                  }
                  id={props.savedState ? 'savedText' : 'saveScenario'}
                  icon={
                    props.ownRole === AccessRole.VIEWER
                      ? saveAsImgDisabled
                      : savePurpleImg
                  }
                  type="button"
                  className="saveButton"
                  disabled={props.ownRole === AccessRole.VIEWER}
                  onClick={
                    props.savedState
                      ? undefined
                      : () => {
                          props.handleSave(false)
                          //Mixpanel 58
                          logActivity(false, 'Saved scenario')
                        }
                  }
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              </div>

              {props.savedMessage === 'saved' ? (
                <div className="savedMessageContainer">
                  <p
                    className="savedScenarioText"
                    id="savedScenarioText-saved"
                    data-textattribute="message-49"
                  >
                    {getText('message-49', user.settings)}
                  </p>
                </div>
              ) : props.savedMessage === 'error' ? (
                <div className="savedErrorContainer">
                  <p
                    className="savedScenarioText"
                    id="savedScenarioText-notSaved"
                    data-textattribute="message-51"
                  >
                    {getText('message-51', user.settings)}
                  </p>
                </div>
              ) : props.savedMessage === 'savedAs' ? (
                <div className="savedMessageContainer left">
                  <p
                    className="savedScenarioText"
                    id="savedScenarioText-savedAsNew"
                    data-textattribute="message-52"
                  >
                    {getText('message-52', user.settings)}
                  </p>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="saveButtonContainer">
              {props.savedMessage === 'report saved' ? (
                <div
                  className="savedMessageContainer"
                  style={{ top: 100, right: 50 }}
                >
                  <p
                    className="savedScenarioText"
                    id="savedScenarioText-reportSaved"
                    data-textattribute="message-50"
                  >
                    {getText('message-50', user.settings)}
                  </p>
                </div>
              ) : props.savedMessage === 'error' ? (
                <div className="savedErrorContainer">
                  <p
                    className="savedScenarioText"
                    id="savedScenarioText-notSaved"
                    data-textattribute="message-51"
                  >
                    {getText('message-51', user.settings)}
                  </p>
                </div>
              ) : null}
            </div>
          )}
        </div>
      )}
    </>
  )
}
