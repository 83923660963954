import Draggable, { DraggableData } from 'react-draggable'
import { deepCloneObject } from '../../../../services/commonFunctions'
import { LegalFeesDistributionObject } from '../../../../models/legalFeesDistributionObject'
import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
} from '../../../../models/generalTypes'
import { UndoRedoType } from '../../../../models/enums'
import { changeGlobalSnapshot } from '../../../../services/changeGlobalSnapshot'
import {
  calculateAbsoluteStep,
  findAbsoluteSegmentHeights,
  findAcceptedRangesArray,
  findClosestValue,
} from '../../../../services/disputedAmountFunctions'

type Props = {
  previousSegmentHeights:
    | { segmentIndex: number; top: number; height: number }[]
    | undefined
  setPreviousSegmentHeights: (
    previousSegmentHeights:
      | { segmentIndex: number; top: number; height: number }[]
      | undefined,
  ) => void
  absoluteSegmentHeights: {
    segmentIndex: number
    top: number
    height: number
  }[]
  setAbsoluteSegmentHeights: (
    absoluteSegmentHeights: {
      segmentIndex: number
      top: number
      height: number
    }[],
  ) => void
  rangesBarHeight: number
  setTempSegmentHeight: (tempSegmentHeight: number | undefined) => void
  setTempSegmentAmount: (tempSegmentAmount: number | undefined) => void
  rangeBarIndex: number
  disputedAmount: number
  totalCounterClaimedAmount: number
  tempSegmentAmount: number | undefined
  tempFeeDistributions: LegalFeesDistributionObject[]
  setTempFeeDistributions: (
    tempFeeDistributions: LegalFeesDistributionObject[],
  ) => void
}

export default function RangesBarHandle(props: Props) {
  const nodeRef = useRef<HTMLDivElement>(null)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )

  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const feeDistributions = currentSnapshot.legalFeesDistribution
  const absoluteStepAmount = calculateAbsoluteStep(props.disputedAmount)
  const [acceptableRangesArray, setAcceptableRangedArray] = useState(
    findAcceptedRangesArray(
      props.totalCounterClaimedAmount,
      props.totalCounterClaimedAmount + props.disputedAmount,
      absoluteStepAmount,
    ),
  )

  const step = 100 / props.rangesBarHeight
  useEffect(() => {
    setAcceptableRangedArray(
      findAcceptedRangesArray(
        props.totalCounterClaimedAmount,
        props.totalCounterClaimedAmount + props.disputedAmount,
        absoluteStepAmount,
      ),
    )
    // eslint-disable-next-line
  }, [props.disputedAmount])

  function handleChangeSegmentHeights(index: number, data: DraggableData) {
    if (props.previousSegmentHeights !== undefined) {
      const snapshotSelectorObject: SnapshotSelectorObject = {
        targetId: `rangeBarHandle-${props.rangeBarIndex}`,
        undoRedoType: UndoRedoType.button,
        value: ['changeSegmentHeight', index, props.tempSegmentAmount],
        key: 'legalFeesDistribution',
      }

      let tempScenarioSnapshot = deepCloneObject(
        scenarioSnapshot,
      ) as DefaultSnapshotState

      tempScenarioSnapshot = changeGlobalSnapshot(
        snapshotSelectorObject,
        tempScenarioSnapshot,
      )
      setScenarioSnapshot(tempScenarioSnapshot)

      props.setAbsoluteSegmentHeights(
        findAbsoluteSegmentHeights(
          tempScenarioSnapshot.currentSnapshot.legalFeesDistribution,
          props.rangesBarHeight,
        ),
      )
    }
  }

  function handleChangeTempHeights(
    index: number,
    data: DraggableData,
    start?: boolean,
  ) {
    let tempFeeDistributions = deepCloneObject(
      props.tempFeeDistributions,
    ) as LegalFeesDistributionObject[]

    let feeDistributionIndex = tempFeeDistributions.length - 2 - index

    if (start) {
      props.setTempSegmentAmount(
        tempFeeDistributions[feeDistributionIndex].range[1] -
          Math.floor(step * data.y),
      )
    } else {
      if (
        findClosestValue(
          tempFeeDistributions[feeDistributionIndex].range[1] - step * data.y,
          acceptableRangesArray,
        ) > tempFeeDistributions[feeDistributionIndex - 1].range[1]
      ) {
        props.setTempSegmentHeight(data.y - 10)

        props.setTempSegmentAmount(
          findClosestValue(
            tempFeeDistributions[feeDistributionIndex].range[1] - step * data.y,
            acceptableRangesArray,
          ),
        )
        tempFeeDistributions[feeDistributionIndex].range[1] -= step * data.y
        tempFeeDistributions[feeDistributionIndex + 1].range[0] -= step * data.y
        props.setTempFeeDistributions(tempFeeDistributions)
      }
    }
  }

  return (
    <>
      <Draggable
        axis={'y'}
        handle=".rangeBarHandle"
        bounds={{
          top:
            props.absoluteSegmentHeights.length > 0
              ? -props.absoluteSegmentHeights[props.rangeBarIndex - 1].height +
                20
              : 0,
          bottom:
            props.absoluteSegmentHeights.length > 0
              ? props.absoluteSegmentHeights[props.rangeBarIndex].height - 20
              : 0,
        }}
        onStart={(e, data) => {
          props.setPreviousSegmentHeights([
            props.absoluteSegmentHeights[props.rangeBarIndex],
            props.absoluteSegmentHeights[props.rangeBarIndex + 1],
          ])
          handleChangeTempHeights(props.rangeBarIndex, data, true)
        }}
        onDrag={(e: any, data: DraggableData) => {
          handleChangeTempHeights(props.rangeBarIndex, data)
        }}
        onStop={(e: any, data: DraggableData) => {
          handleChangeSegmentHeights(props.rangeBarIndex, data)

          props.setPreviousSegmentHeights(undefined)
          props.setTempSegmentAmount(undefined)
          props.setTempSegmentHeight(undefined)
        }}
        nodeRef={nodeRef}
        position={{ x: 0, y: 0 }}
      >
        <div
          className="rangeBarHandle"
          ref={nodeRef}
          id={`rangeBarHandle-${
            feeDistributions.length - 2 - props.rangeBarIndex
          }`}
          data-openreplay-obscureds
        ></div>
      </Draggable>
    </>
  )
}
