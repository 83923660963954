import React, { useEffect, useState } from 'react'
import { Xwrapper } from 'react-xarrows'
import { TreeClaim } from '../../../../../../models/treeModels/treeClaim'
import { useRecoilValue } from 'recoil'
import { changeTextAreaHeight } from '../../../../../../services/treeFunctions/treeBasicFunctions'
import {
  changeNodesPositionsMinMax,
  findHeightOfCanvas,
  findWidthOfCanvas,
} from '../../../../../../services/treeFunctions/treePositioningFunctions'
import {
  EventId,
  NodeId,
  NodeMode,
} from '../../../../../../models/treeModels/treeTypes'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { deepCloneObject } from '../../../../../../services/commonFunctions'
import TreeNode from './treeNodeComponents/TreeNode'
import { AccessRole } from '../../../../../../models/enums'
import ConnectedLineReport from '../../../7_results/resultsComponents/5_pdfReport/pdfReportComponents/smallComponents/TreePdfPreviewComponents/ConnectedLineReport'
import TreeNodeForReport from '../../../7_results/resultsComponents/5_pdfReport/pdfReportComponents/smallComponents/TreePdfPreviewComponents/TreeNodeForReport'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'

type Props = {
  treeIndex: number
  zoomLevel: number
  mainTree: NodeId[]
  differenceFromTop: number
  differenceFromLeft: number
  canvasType: 'report' | 'downloadImage'
  canvasHeight?: number
  canvasWidth?: number
  maximised?: boolean
  nodesUnderZeroProbability: NodeId[]
  reportWhitelabel?: ReportWhitelabel
}

export default function TreeCanvasForImage(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [tempScenarioSnapshot, setTempScenarioSnapshot] = useState(
    deepCloneObject(scenarioSnapshot),
  )
  const [nodeProbabilities, setNodeProbabilities] = useState<{
    [nodeId: NodeId]: number[]
  }>({})
  const treeClaim = tempScenarioSnapshot.currentSnapshot.claims[
    props.treeIndex
  ] as TreeClaim

  const treeLines = treeClaim.treeLines
  const [canvasWidth, setCanvasWidth] = useState(700)
  const [canvasHeight, setCanvasHeight] = useState(800)
  const [connectedDot, setConnectedDot] = useState<
    EventId | NodeId | undefined
  >(undefined)

  useEffect(() => {
    let tempNodeProbabilities = { ...nodeProbabilities }

    for (let eventId of Object.keys(treeClaim.treeDetails.events)) {
      if (
        !Object.keys(tempNodeProbabilities).includes(
          treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId,
        )
      ) {
        tempNodeProbabilities[
          treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId
        ] = []
      }
      tempNodeProbabilities[
        treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId
      ][treeClaim.treeDetails.events[eventId as EventId].eventIndex] =
        treeClaim.treeDetails.events[
          eventId as EventId
        ].eventDetails.probability
    }
    setNodeProbabilities(tempNodeProbabilities)
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
      .treeDetails.events,
  ])

  useEffect(() => {
    let tempScenarioSnapshotLocal = deepCloneObject(scenarioSnapshot)
    if (
      (
        tempScenarioSnapshotLocal.currentSnapshot.claims[
          props.treeIndex
        ] as TreeClaim
      ).nodeMode === NodeMode.maximised &&
      props.canvasType === 'report'
    ) {
      ;(
        tempScenarioSnapshotLocal.currentSnapshot.claims[
          props.treeIndex
        ] as TreeClaim
      ).treeDetails = changeNodesPositionsMinMax(
        NodeMode.minimised,
        (
          tempScenarioSnapshotLocal.currentSnapshot.claims[
            props.treeIndex
          ] as TreeClaim
        ).treeDetails,
      )
      setTempScenarioSnapshot(tempScenarioSnapshotLocal)
    }
    // eslint-disable-next-line
  }, [])
  useEffect(() => {
    if (props.mainTree.length > 0) {
      findCanvasDimensions()
    }
    // eslint-disable-next-line
  }, [treeClaim, props.mainTree])

  useEffect(() => {
    //This useEffect fixes the textarea height when changing from tree to tree, since rerendering of react doesn't work properly
    setTimeout(() => {
      const tx = document.getElementsByClassName('treeNodeTitleTextarea')
      for (let i = 0; i < tx.length; i++) {
        if (tx[i]) {
          changeTextAreaHeight(tx[i])
        }
      }
    }, 100)
    const tx = document.getElementsByClassName('treeNodeTitleTextarea')
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute(
        'style',
        `height: ${tx[i].scrollHeight}px;overflow-y:hidden;`,
      )
      tx[i].addEventListener('input', () => changeTextAreaHeight(tx[i]), false)
    }
    // eslint-disable-next-line
  }, [props.treeIndex])

  function findCanvasDimensions() {
    if (props.canvasHeight && props.canvasWidth) {
      setCanvasWidth(props.canvasWidth)
      setCanvasHeight(props.canvasHeight)
    } else {
      let tempCanvasWidth = findWidthOfCanvas(
        treeClaim.nodeMode,
        treeClaim.treeDetails,
        props.mainTree,
      )
      let tempCanvasHeight = findHeightOfCanvas(
        treeClaim.nodeMode,
        treeClaim.treeDetails,
        props.treeIndex,
        props.mainTree,
      )

      setCanvasWidth(tempCanvasWidth)
      setCanvasHeight(tempCanvasHeight)
    }

    // eslint-disable-next-line
  }

  return (
    <div
      className="treeAppBackground"
      style={
        props.canvasType === 'downloadImage'
          ? {
              marginTop: 0,
              padding: '200px 100px 100px 100px',
              width: canvasWidth * props.zoomLevel + 100,
              height: canvasHeight * props.zoomLevel + 200,
              backgroundColor: 'white',
            }
          : {
              marginTop: 0,
              width: canvasWidth * props.zoomLevel,
              height: canvasHeight * props.zoomLevel,
            }
      }
      id={`treeAppBackgroundForImage-${props.treeIndex}`}
    >
      <div
        className="treeCanvasBackground"
        id={`treeCanvasBackgroundAbsolute-${props.treeIndex}`}
        style={
          props.canvasType === 'report'
            ? {
                position: 'absolute',
                width: 1200 / props.zoomLevel,
                height: canvasHeight,
                transform: `scale(${props.zoomLevel})`,
                transformOrigin: 'top left',
                marginLeft: 0,
              }
            : {
                position: 'absolute',
                width: canvasWidth,
                height: canvasHeight,
                transform: `scale(${props.zoomLevel})`,
                transformOrigin: 'top left',
              }
        }
      ></div>
      <Xwrapper>
        {Object.keys(treeClaim.treeDetails.events).map((eventId) =>
          treeClaim.treeDetails.events[eventId as EventId].childrenNodes
            .filter((chilldNodeId) => {
              const parentNodeId =
                treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId

              return (
                props.mainTree.includes(chilldNodeId) &&
                props.mainTree.includes(parentNodeId)
              )
            })
            .map((nodeId) => (
              <ConnectedLineReport
                key={`connectedLine-${nodeId}-${
                  treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId
                }!${
                  treeClaim.treeDetails.events[eventId as EventId].eventIndex
                }`}
                startId={`connectionCircle-${props.treeIndex}_${nodeId}`}
                endId={`treeAddNodeDot-${props.treeIndex}_${
                  treeClaim.treeDetails.events[eventId as EventId].nodeOfEventId
                }!${
                  treeClaim.treeDetails.events[eventId as EventId].eventIndex
                }`}
                nodeId={nodeId}
                eventId={eventId as EventId}
                events={treeClaim.treeDetails.events}
                zoomLevel={props.zoomLevel}
                type={props.canvasType}
                grayOut={props.nodesUnderZeroProbability.includes(nodeId)}
                treeLines={treeLines}
              />
            )),
        )}
        <div
          className="treeCanvasBackground"
          id={`treeCanvasBackground-${props.treeIndex}`}
          style={{
            width: props.canvasType === 'downloadImage' ? 400 : canvasHeight,
            height: props.canvasType === 'downloadImage' ? 700 : canvasWidth,
            transform: `scale(${props.zoomLevel})`,
            transformOrigin: 'top left',
            marginLeft: props.canvasType === 'downloadImage' ? 0 : 100,
          }}
        >
          {Object.keys(treeClaim.treeDetails.nodes)
            .filter((nodeId) => props.mainTree.includes(nodeId as NodeId))
            .map((nodeId, index) =>
              props.maximised ? (
                <TreeNode
                  nodeDetails={treeClaim.treeDetails.nodes[nodeId as NodeId]}
                  events={treeClaim.treeDetails.events}
                  nodeId={nodeId as NodeId}
                  zoomLevel={treeClaim.zoomLevel}
                  treeIndex={props.treeIndex}
                  root={treeClaim.treeDetails.nodes[nodeId as NodeId].root}
                  key={`nodeId-${nodeId}_${index}`}
                  setRenderLines={() => {}}
                  handleSetLineHover={() => {}}
                  handleSetLineClick={() => {}}
                  lineClick={['node-2', 'event-12']}
                  selectedNodes={[]}
                  setSelectedNodes={() => {}}
                  mainTree={props.mainTree}
                  orphanTrees={[]}
                  elementsToBeDeletedForAnimation={[]}
                  setElementsToBeDeletedForAnimation={() => {}}
                  setMainTree={() => {}}
                  setOrphanTrees={() => {}}
                  nodeCreatingLine={undefined}
                  setNodeCreatingLine={() => {}}
                  availableTargetsForLine={[]}
                  setAvailableTargetsForLine={() => {}}
                  setDragPosition={() => {}}
                  setChangingLineStartId={() => {}}
                  showTreeInfo={false}
                  editMode={undefined}
                  setEditMode={() => {}}
                  resetActivePath={false}
                  selectedPath={[]}
                  debugging={false}
                  shakeScenario={undefined}
                  openDeleteNodeOrEventMenu={undefined}
                  setOpenDeleteNodeOrEventMenu={() => {}}
                  nodeProbabilities={nodeProbabilities}
                  handleDeleteTreeEvent={() => {}}
                  handleDeleteTreeNode={() => {}}
                  connectedDot={connectedDot}
                  setConnectedDot={setConnectedDot}
                  openOrphanNodesWarningPopUp={false}
                  openZeroProbabilityWarningPopUp={false}
                  ownRole={AccessRole.OWNER}
                  toggleEditMode={() => {}}
                  errors={[]}
                  differenceFromTop={props.differenceFromTop - 20}
                  differenceFromLeft={props.differenceFromLeft - 50}
                  connectionCreatesLoop={undefined}
                  setConnectionCreatesLoop={() => {}}
                  nodesUnderZeroProbability={props.nodesUnderZeroProbability}
                />
              ) : (
                <TreeNodeForReport
                  nodeDetails={treeClaim.treeDetails.nodes[nodeId as NodeId]}
                  events={treeClaim.treeDetails.events}
                  nodeId={nodeId as NodeId}
                  zoomLevel={props.zoomLevel}
                  treeIndex={props.treeIndex}
                  root={treeClaim.treeDetails.nodes[nodeId as NodeId].root}
                  key={`nodeId-${nodeId}_${index}`}
                  mainTree={props.mainTree}
                  scenarioSnapshot={scenarioSnapshot}
                  differenceFromTop={
                    props.canvasType === 'downloadImage'
                      ? props.differenceFromTop - 20
                      : props.differenceFromTop - 20
                  }
                  differenceFromLeft={
                    props.canvasType === 'downloadImage'
                      ? props.differenceFromLeft - 50
                      : props.differenceFromLeft - 50
                  }
                  nodesUnderZeroProbability={props.nodesUnderZeroProbability}
                  reportWhitelabel={props.reportWhitelabel}
                />
              ),
            )}
        </div>
      </Xwrapper>
    </div>
  )
}
