import { CircularProgress } from '@mui/material'
import React, { FormEvent, useState } from 'react'
import InputText from '../../../../Components/Inputs/InputText/InputText'
import { getText } from '../../../../services/textFunctions'
import tickedImg from '../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../resources/images/018-untickedGrayBlue.svg'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../states/UserState'

type Props = {
  handleSubmit: (newPassword: string) => void
  loading: boolean
  weakPasswordMessage: string | string[]
  validationFailed: boolean
  setValidationFailed: (value: boolean) => void
}

const ResetPasswordForm = (props: Props) => {
  const user = useRecoilValue(userState)

  const [password, setPassword] = useState('')
  const [retypePassword, setRetypePassword] = useState('')
  const [errors, setErrors] = useState<string[]>([])

  function handleClickOnShowPasswordTick() {
    if (
      document.getElementById('showPasswordUnticked')!.className ===
      'visibleTick'
    ) {
      document.getElementById('showPasswordTicked')!.className = 'visibleTick'
      document.getElementById('showPasswordUnticked')!.className = 'hiddenTick'
      ;(document.getElementById('password') as HTMLInputElement).type = 'text'
      ;(document.getElementById('repeatPassword') as HTMLInputElement).type =
        'text'
    } else if (
      document.getElementById('showPasswordTicked')!.className === 'visibleTick'
    ) {
      document.getElementById('showPasswordTicked')!.className = 'hiddenTick'
      document.getElementById('showPasswordUnticked')!.className = 'visibleTick'
      ;(document.getElementById('password') as HTMLInputElement).type =
        'password'
      ;(document.getElementById('repeatPassword') as HTMLInputElement).type =
        'password'
    }
  }

  const validation = () => {
    let numOfErrors = 0
    const newErrors: string[] = []

    if (!password) {
      newErrors.push('password')
      numOfErrors++
    }

    if (!retypePassword || retypePassword !== password) {
      newErrors.push('repeatPassword')
      numOfErrors++
    }

    setErrors([...newErrors])
    return numOfErrors === 0
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validation()) {
      return
    }

    props.handleSubmit(password)
  }

  return (
    <form className="form" onSubmit={onSubmit}>
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="resetPasswordTitle"
          data-textattribute="title-119"
        >
          {getText('title-119', user.settings)}
        </h2>
        <p
          className="instructions"
          id="resetPasswordInstructions"
          data-textattribute="description-130"
        >
          {getText('description-130', user.settings)}
        </p>
      </div>
      <div className="inputsContainer">
        <div className="inputResetPassword1">
          <InputText
            label={getText('label-59', user.settings)}
            labelTextAttribute="label-59"
            id="password"
            type="password"
            width={400}
            onChange={(e: any) => {
              setPassword(e.target.value)
              props.setValidationFailed(false)
            }}
            value={password}
            name="password"
            error={errors.includes('password') || props.validationFailed}
            errorMessage={
              props.validationFailed
                ? props.weakPasswordMessage !== ''
                  ? props.weakPasswordMessage
                  : getText('error-44', user.settings)
                : getText('error-45', user.settings)
            }
            errorTextAttribute={
              props.validationFailed
                ? props.weakPasswordMessage !== ''
                  ? 'error-00, error-69, error-70'
                  : 'error-44'
                : 'error-45'
            }
            tabIndex={1}
            alwaysShrink
            noDelay
            maxLength={64}
          />
        </div>
        <div className="inputResetPassword2">
          <InputText
            label={getText('label-60', user.settings)}
            labelTextAttribute="label-60"
            id="repeatPassword"
            type="password"
            width={400}
            onChange={(e: any) => setRetypePassword(e.target.value)}
            value={retypePassword}
            name="repeatPassword"
            error={errors.includes('repeatPassword')}
            errorMessage={getText('error-46', user.settings)}
            errorTextAttribute="error-46"
            tabIndex={2}
            alwaysShrink
            noDelay
            maxLength={64}
          />
        </div>
        <div className="showPassword">
          <img
            className="visibleTick"
            id="showPasswordUnticked"
            src={unTickedImg}
            alt="untickedImg"
            onClick={handleClickOnShowPasswordTick}
          />
          <img
            className="hiddenTick"
            id="showPasswordTicked"
            src={tickedImg}
            alt="tickedImg"
            onClick={handleClickOnShowPasswordTick}
          />
          <p
            className="tickText"
            onClick={handleClickOnShowPasswordTick}
            id="resetPasswordtickText"
            data-textattribute="submenu-17"
          >
            {getText('submenu-17', user.settings)}
          </p>
        </div>
      </div>
      <div className="submitButtonContainer">
        <button
          type="submit"
          className="submitButton"
          disabled={props.loading}
          id="submitResetPasswordButton"
          data-textattribute="button-61"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : (
            getText('button-61', user.settings)
          )}
        </button>
      </div>
    </form>
  )
}

export default ResetPasswordForm
