import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { getWordpress, updateUserChecklist } from '../../services/requests'
import { initiliazeListOfTutorials } from '../../services/wordpressFunctions'
import { TutorialTileObject } from '../../models/generalTypes'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'
import { deepCloneObject } from '../../services/commonFunctions'
import { UserChecklist } from '../../models/userChecklist'
import { User } from '../../models/user'
import { getText } from '../../services/textFunctions'
import { freemiumState } from '../../states/FreemiumState'
import { getFreemiumWordpress } from '../../freemiumServices/freemiumRequests'
import { AxiosResponse } from 'axios'
import { saveFreemiumPreferences } from '../../freemiumServices/freemiumStorage'

type Props = {
  infoVideo: string
  closing: boolean
  cancelFunction?: () => void
}

const InfoVideo = (props: Props) => {
  const [child, setChild] = useState<TutorialTileObject | undefined>(undefined)
  const oneFetch = useRef(false)
  const [user, setUser] = useRecoilState(userState)
  const [loading, setLoading] = useState(false)
  const freemium = useRecoilValue(freemiumState)

  const textToSlug = () => props.infoVideo.toLowerCase().replaceAll(' ', '-')

  useEffect(() => {
    if (!oneFetch.current) {
      oneFetch.current = true

      if (freemium.isFreemium) {
        getFreemiumWordpress('checklist1', textToSlug()).then((result) => {
          if (!('errorCode' in result)) {
            setTutorialObj(result)
          }
        })
      } else {
        getWordpress('checklist1', textToSlug()).then((result) => {
          if (!('errorCode' in result)) {
            setTutorialObj(result)
          }
        })
      }
    }

    // eslint-disable-next-line
  }, [])

  const setTutorialObj = (result: AxiosResponse) => {
    let tempChild = deepCloneObject(initiliazeListOfTutorials(result.data)[0])

    tempChild.parsedMainText = [
      ...tempChild.parsedMainText.filter((obj: any) => typeof obj !== 'string'),
    ]

    for (let text of tempChild.parsedMainText) {
      text.props.id = 'popUp'
    }

    setChild(tempChild)
  }

  const onWatchIntroVideoDone = async () => {
    if (!user.checklist.introVideo) {
      setLoading(true)

      const newChecklist = new UserChecklist(
        true,
        user.checklist.accessTutorial,
        user.checklist.createCase,
        user.checklist.analyzeScenario,
        user.checklist.downloadReport,
      )

      if (freemium.isFreemium) {
        saveFreemiumPreferences({
          settings: user.settings,
          checklist: newChecklist,
        })

        let newUser = deepCloneObject(user) as User
        newUser.checklist = newChecklist
        setUser(newUser)
      } else {
        const res = await updateUserChecklist(newChecklist)

        if (!('errorCode' in res)) {
          let newUser = deepCloneObject(user) as User
          newUser.checklist = UserChecklist.FromJson(res.data)
          setUser(newUser)
        }
      }

      setLoading(false)
    }

    if (props.cancelFunction) {
      props.cancelFunction()
    }
  }

  return !child ? (
    <div
      id="popUp-loading"
      style={{
        height: '70vh',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CircularProgress id="popUp-loadingComponent" />{' '}
    </div>
  ) : (
    <div
      id="popUp-infoContainer"
      className="infoContainer"
      style={props.closing ? { opacity: 0.0 } : {}}
    >
      <div className="infoTitle" id="popUp-infoTitle">
        {child.parsedMainText[0].props.children.replace(
          '{user_first_name}',
          user.firstName,
        )}
      </div>
      {child.parsedMainText.slice(1).map((obj) => (
        <div
          id={`popUp-${obj.key}`}
          key={obj.key}
          className={`${
            obj.props.children.props && obj.props.children.props.src
              ? 'infoVideo'
              : 'infoText'
          }`}
        >
          {obj}
        </div>
      ))}
      <div className="infoActions" id="popUp-infoActions">
        {textToSlug() === 'watch-intro-video' && (
          <div
            className="cancelButton"
            id="popUp-cancelButton"
            onClick={props.cancelFunction}
          >
            <p
              className="text"
              id="popUp-cancelButtonText"
              data-textattribute="button-145"
            >
              {getText('button-145', user.settings)}
            </p>
          </div>
        )}
        <div
          className="doneButton"
          id="popUp-doneButton"
          onClick={
            textToSlug() !== 'watch-intro-video'
              ? props.cancelFunction
              : onWatchIntroVideoDone
          }
        >
          {loading ? (
            <CircularProgress size={20} />
          ) : (
            <p
              className="text"
              id="popUp-doneButtonText"
              data-textattribute="button-146"
            >
              {getText('button-146', user.settings)}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoVideo
