import React from 'react'
import { UserForAccess } from '../../models/generalTypes'
import { WhiteTooltip } from '../tooltip/TooltipComponent'

type Props = {
  firstName: string
  lastName: string
  users: UserForAccess[]
  empty?: boolean
  fullText?: string
  activeUser?: boolean
}

export default function UserThumbnail(props: Props) {
  return (
    <WhiteTooltip
      title={
        props.fullText ? (
          <p style={{ textAlign: 'center' }}>{props.fullText}</p>
        ) : (
          props.users.map((userObject: UserForAccess, index: number) => (
            <p className="userThumbnailHoverText" key={`userThumbnail${index}`}>
              {userObject.first_name} {userObject.last_name}
            </p>
          ))
        )
      }
      textAttribute={undefined}
      id="userThumbnailHoverContainer"
    >
      <div
        className={`userThumbnailContainer ${props.activeUser ? 'active' : ''}`}
      >
        <p className="userThumbnailText">
          {props.empty
            ? '...'
            : `${props.firstName.substring(0, 1).toUpperCase()}${props.lastName
                .substring(0, 1)
                .toUpperCase()}`}
        </p>
      </div>
    </WhiteTooltip>
  )
}
