import SearchBar from '../../../pages/myCases_myScenarios/myCasesComponents/SearchBar'
import {
  FreemiumListItem,
  FreemiumSortOption,
} from '../../../models/freemiumGeneralTypes'
import ShareCaseButtonMyScenarios from '../../../pages/myCases_myScenarios/myCasesComponents/ShareCaseButtonMyScenarios'
import PremiumFeatureIcon from '../../PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  itemName: 'Scenario' | 'Case'
  isMyScenariosPage?: boolean
  setSearchList: (param: FreemiumListItem[]) => void
  setSearchKey: (param: string) => void
  sortBy: (
    option: FreemiumSortOption,
    changeDirection: boolean,
    listToSort?: FreemiumListItem[] | undefined,
  ) => FreemiumListItem[]
  handleSearchList: (
    key: string,
    newList?: FreemiumListItem[],
  ) => FreemiumListItem[]
  currentSortOption: FreemiumSortOption
  tutorialId?: string
  openFreemiumMessage: (messageType: FreemiumMessageType) => void
}

const FreemiumTopPart = (props: Props) => {
  const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target?.value.trim().toLowerCase()

    let tempList = props.handleSearchList(key)
    tempList = props.sortBy(props.currentSortOption, false, tempList)

    props.setSearchList([...tempList])

    props.setSearchKey(key)
  }

  return (
    <div className="myCasesBox-searchBar">
      <SearchBar
        itemName={props.itemName}
        searchFor={searchFor}
        isMyScenariosPage={props.isMyScenariosPage}
      />
      {props.isMyScenariosPage && !props.tutorialId && (
        <>
          <ShareCaseButtonMyScenarios
            onContainerClick={() =>
              props.openFreemiumMessage(FreemiumMessageType.General)
            }
            usersList={[]}
            usersListWhenNoScenarios={undefined}
            showUsersFromImg={false}
            showUsersFromText={false}
            onImgMouseEnter={() => {}}
            onImgMouseLeave={() => {}}
            onTextMouseEnter={() => {}}
            onTextMouseLeave={() => {}}
          />
          <PremiumFeatureIcon targetId={`sharedWithContainer`} />
        </>
      )}
    </div>
  )
}

export default FreemiumTopPart
