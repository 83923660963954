import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  ListItem,
  SortOption,
  UserForAccess,
} from '../../../models/generalTypes'
import SearchBar from '../../../pages/myCases_myScenarios/myCasesComponents/SearchBar'
import ShareCaseButtonMyScenarios from '../../../pages/myCases_myScenarios/myCasesComponents/ShareCaseButtonMyScenarios'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import Button from '../../Buttons/Button/Button'

type Props = {
  itemName: 'Scenario' | 'Case'
  isMyScenariosPage?: boolean
  setSearchList: (param: ListItem[]) => void
  setSearchKey: (param: string) => void
  sortBy: (
    option: SortOption,
    changeDirection: boolean,
    listToSort?: ListItem[] | undefined,
  ) => ListItem[]
  usersList: UserForAccess[] | undefined
  onShareCaseButtonMyScenariosClick: () => void
  usersListWhenNoScenarios?: UserForAccess[]
  handleSearchList: (key: string, newList?: ListItem[]) => ListItem[]
  currentSortOption: SortOption
  showUsersShare: boolean
  showDeleted?: boolean
  setOpenEmptyBinPopUp: (value: boolean) => void
  isEmptyBinDisabled: boolean
  tutorialId?: string
}

const TopPart = (props: Props) => {
  const [showUsersFromText, setShowUsersFromText] = useState(false)
  const [showUsersFromImg, setShowUsersFromImg] = useState(false)
  const user = useRecoilValue(userState)

  const searchFor = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target?.value.trim().toLowerCase()

    let tempList = props.handleSearchList(key)
    tempList = props.sortBy(props.currentSortOption, false, tempList)

    props.setSearchList([...tempList])

    props.setSearchKey(key)
  }

  return (
    <div className="myCasesBox-searchBar">
      <SearchBar
        itemName={props.itemName}
        searchFor={searchFor}
        isMyScenariosPage={props.isMyScenariosPage}
      />
      {props.showDeleted ? (
        <Button
          id={'emptyBinButton'}
          buttonText={getText('button-131', user.settings)}
          buttonTextAttribute={'button-131'}
          buttonType="outlined"
          type="button"
          className="addNewCaseButton"
          onClick={() => {
            props.setOpenEmptyBinPopUp(true)
          }}
          disabled={props.isEmptyBinDisabled}
          side="left"
          NoUpperCase={false}
          small={false}
        />
      ) : props.isMyScenariosPage &&
        props.showUsersShare &&
        !props.tutorialId ? (
        <>
          <ShareCaseButtonMyScenarios
            onContainerClick={props.onShareCaseButtonMyScenariosClick}
            onImgMouseEnter={() => setShowUsersFromImg(true)}
            onImgMouseLeave={() => setShowUsersFromImg(false)}
            onTextMouseEnter={() => setShowUsersFromText(true)}
            onTextMouseLeave={() => setShowUsersFromText(false)}
            usersList={props.usersList}
            usersListWhenNoScenarios={props.usersListWhenNoScenarios}
            showUsersFromImg={showUsersFromImg}
            showUsersFromText={showUsersFromText}
          />
        </>
      ) : null}
    </div>
  )
}

export default TopPart
