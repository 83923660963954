export class LiabilityRequirement {
  title: string
  probability: number

  constructor(title: string, probability: number) {
    this.title = title
    this.probability = probability
  }

  static EmptyLiabilityRequirement(title: string): LiabilityRequirement {
    let tempLiabilityRequirement = new LiabilityRequirement(title, 100)
    return tempLiabilityRequirement
  }

  static toJson(data: LiabilityRequirement): any {
    return {
      title: data.title,
      probability: data.probability,
    }
  }

  static FromJson(data: any): LiabilityRequirement {
    return new LiabilityRequirement(data.title, data.probability)
  }
}
