import { AmountSignum, PartySignum } from '../enums'

export class EventDetails {
  eventTitle: string
  awardedAmount: number
  awardedAmountSignum: AmountSignum // on | off | get | pay => should become off | get | pay
  reducedAwardedAmount: number
  reducedAwardedAmountParty: PartySignum // off | client | opposingParty
  hasInterest: boolean
  interestRate: number // 0.08
  interestDate: string | undefined
  outOfCourtAmount: number
  outOfCourtAmountSignum: AmountSignum // on | off | get | pay => should become off | get | pay
  probability: number

  constructor(
    eventTitle: string,
    awardedAmount: number,
    awardedAmountSignum: AmountSignum,
    reducedAwardedAmount: number,
    reducedAwardedAmountParty: PartySignum,
    hasInterest: boolean,
    interestRate: number,
    interestDate: string | undefined,
    outOfCourtAmount: number,
    outOfCourtAmountSignum: AmountSignum,
    probability: number,
  ) {
    this.eventTitle = eventTitle
    this.awardedAmount = awardedAmount
    this.awardedAmountSignum = awardedAmountSignum
    this.reducedAwardedAmount = reducedAwardedAmount
    this.reducedAwardedAmountParty = reducedAwardedAmountParty
    this.hasInterest = hasInterest
    this.interestRate = interestRate
    this.interestDate = interestDate
    this.outOfCourtAmount = outOfCourtAmount
    this.outOfCourtAmountSignum = outOfCourtAmountSignum
    this.probability = probability
  }

  static defaultEventDetails(probability: 0 | 0.5): EventDetails {
    return new EventDetails(
      '',
      0,
      AmountSignum.off,
      0,
      PartySignum.off,
      false,
      0.08,
      undefined,
      0,
      AmountSignum.off,
      probability,
    )
  }
}
