import { CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import { UserForAccess } from '../../models/generalTypes'
import { Button } from '../Buttons/Button/Button'
import { getText } from '../../services/textFunctions'
import { AccessMenuAction } from './AccessComponent'
import { AllowedAccessRole } from '../../models/enums'
import { useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'

type Props = {
  type: AccessMenuAction
  user: UserForAccess
  tempAccessRole: AllowedAccessRole
  cancelFunction: () => void
  addRemoveAccess: (
    type: AccessMenuAction,
    username: string,
    role: AllowedAccessRole,
  ) => void
  changeAccepted: boolean
  setChangeAccepted: (param: boolean) => void
  changeForbidden: boolean
  loadingAccess: boolean
  setLoadingAccess: (param: boolean) => void
  isFirst?: boolean
}

export default function AddRemoveAccessMenu(props: Props) {
  const user = useRecoilValue(userState)

  const getUserFullName = () => {
    return `${props.user.first_name} ${props.user.last_name}`
  }

  useEffect(() => {
    if (props.type === 'change') {
      props.addRemoveAccess(
        props.type,
        props.user.username,
        props.tempAccessRole,
      )
      props.setLoadingAccess(true)
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div
        className="transparent-Background-2"
        id="popUpTransparent"
        style={{ width: 3000, height: 3000, zIndex: 1200 }}
        onClick={() => {
          props.setChangeAccepted(false)
          props.cancelFunction()
        }}
      ></div>
      <div
        className={`addRemoveAccessMenuContainer ${
          props.type === 'leave' ? 'big' : ''
        }`}
        id="popUpAddRemoveAccessMenuContainer"
        style={props.isFirst ? { top: -110 } : { top: -140 }}
      >
        {props.loadingAccess ? (
          <div className="circularProgressDiv" id={'popUp7'}>
            <div id={'popUp8'}>
              <CircularProgress size={40} />
            </div>
          </div>
        ) : props.changeAccepted ? (
          <p
            className="addRemoveAccessMenuAcceptedTextContainer"
            id="popUpaddRemoveAccessMenuAcceptedTextContainer"
            data-textattribute={
              props.type === 'add'
                ? 'message-4'
                : props.type === 'remove'
                ? 'message-5'
                : props.type === 'leave'
                ? 'message-75'
                : 'message-76'
            }
          >
            {props.type === 'add'
              ? `${getUserFullName()} ${getText('message-4', user.settings)}`
              : props.type === 'remove'
              ? `${getUserFullName()} ${getText('message-5', user.settings)}`
              : props.type === 'leave'
              ? getText('message-75', user.settings)
              : `${getUserFullName()}${getText('message-76', user.settings)}`}
          </p>
        ) : props.changeForbidden ? (
          <p
            className="addRemoveAccessMenuAcceptedTextContainer"
            id="popUpaddRemoveAccessMenuAcceptedTextContainer"
            data-textattribute={
              props.type === 'remove'
                ? `message-77`
                : props.type === 'leave'
                ? 'message-78'
                : `message-79`
            }
          >
            {props.type === 'remove'
              ? getText('message-77', user.settings)
              : props.type === 'leave'
              ? getText('message-78', user.settings)
              : getText('message-79', user.settings)}
          </p>
        ) : (
          <>
            <div
              className="addRemoveAccessMenuTextContainer"
              id="popUpAddRemoveAccessMenuTextContainer"
            >
              {props.type === 'add' ? (
                <p
                  className="addRemoveAccessMenuText"
                  id="popUpAddRemoveAccessMenuText"
                  data-textattribute="message-6"
                >
                  {getUserFullName()} {getText('message-6', user.settings)}
                </p>
              ) : props.type === 'remove' ? (
                <p
                  className="addRemoveAccessMenuText"
                  id="popUpAddRemoveAccessMenuText"
                  data-textattribute="message-7"
                >
                  {getUserFullName()} {getText('message-7', user.settings)}
                </p>
              ) : props.type === 'leave' ? (
                <p
                  className="addRemoveAccessMenuText"
                  id="popUpAddRemoveAccessMenuText"
                  data-textattribute="message-80"
                >
                  {getText('message-80', user.settings)}
                </p>
              ) : null}
            </div>
            <div
              className="addRemoveAccessMenuButtonsContainer"
              id="popUpAddRemoveAccessMenuButtonsContainer"
            >
              <Button
                buttonText={getText('button-17', user.settings)}
                buttonTextAttribute="button-17"
                buttonType="text"
                type="button"
                className="cancelButton"
                onClick={props.cancelFunction}
                id="popUpCancelButton"
                side="left"
                NoUpperCase={true}
                small={false}
              />
              <button
                className={
                  props.type === 'add'
                    ? 'addAccessButton'
                    : props.type === 'remove'
                    ? 'removeAccessButton'
                    : 'leaveAccessButton'
                }
                type="button"
                id="popUpAddRemoveAccessButton"
                onClick={() => {
                  props.addRemoveAccess(
                    props.type,
                    props.user.username,
                    props.tempAccessRole,
                  )
                  props.setLoadingAccess(true)
                }}
                data-textattribute={
                  props.type === 'add'
                    ? 'button-16'
                    : props.type === 'remove'
                    ? 'button-15'
                    : 'button-110'
                }
              >
                {props.type === 'add'
                  ? getText('button-16', user.settings)
                  : props.type === 'remove'
                  ? getText('button-15', user.settings)
                  : getText('button-110', user.settings)}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  )
}
