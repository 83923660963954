import { useRecoilValue } from 'recoil'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import removeImg from '../../../../../../../resources/images/201-deleteBucketWIthBorder.svg'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import { AmountSignum, PartySignum } from '../../../../../../../models/enums'
import { useXarrow } from 'react-xarrows'
import { useState } from 'react'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import { logActivity } from '../../../../../../../services/commonFunctions'
import addReducedAmountGray from '../../../../../../../resources/images/290-ReducedAmountButton.svg'
import addReducedAmountRed from '../../../../../../../resources/images/289-ReducedAmountButtonHover.svg'
import InputNum from '../../../../../../../Components/Inputs/InputNum/InputNum'
import SwitchButton from '../../../../../../../Components/Buttons/SwitchButton/SwitchButton'
import Symbol from '../../../../../../../Components/symbol/Symbol'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
  KROGERUS_OPPOSING_PARTY_COLOR,
  OPPOSING_PARTY_COLOR,
} from '../../../../../../../services/constants'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  active: boolean
  highlighted: boolean
  nodeHasAtLeastOneInterestMenuOn: boolean
  nodeHasAtLeastOneAwardedAmount: boolean
  treeIndex: number
  nodeId: NodeId
  eventIndex: number
  eventDetails: EventDetails
  editMode: NodeId | undefined
  errors: string[]
  nodeSelected: boolean
  startAnimation: boolean
  eventId: EventId
  handleChangeTreeEventDetail: (
    targetId: string,
    eventDetailKey: keyof EventDetails,
    value:
      | never
      | AmountSignum
      | PartySignum
      | string
      | number
      | boolean
      | undefined
      | Date,
  ) => void
  setEditMode: (editMode: NodeId | undefined) => void
}

export default function EventReducedAmountContainer(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const freemium = useRecoilValue(freemiumState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const user = useRecoilValue(userState)

  const updateXarrow = useXarrow()

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const [reducedAmountButtonHover, setReducedAmountButtonHover] =
    useState(false)
  const [showRemoveReducedAmount, setShowRemoveReducedAmount] = useState(false)

  return (
    <>
      <div
        className={`treeEventReducedAmountContainer ${
          props.nodeSelected || props.highlighted ? 'selected' : ''
        } ${props.active ? 'active' : ''} ${
          props.startAnimation ? 'fadeout' : ''
        } ${
          !props.nodeHasAtLeastOneInterestMenuOn &&
          props.nodeHasAtLeastOneAwardedAmount
            ? 'noInterest'
            : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventReducedAmountContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        {props.eventDetails.reducedAwardedAmountParty === PartySignum.off ? (
          <>
            <div className="treeEventAddSectionContainer">
              <div
                className={
                  reducedAmountButtonHover
                    ? 'treeEventAddSectionButton darkButton'
                    : 'treeEventAddSectionButton'
                }
                onMouseEnter={() => setReducedAmountButtonHover(true)}
                onMouseLeave={() => setReducedAmountButtonHover(false)}
                onClick={(e: any) => {
                  setReducedAmountButtonHover(false)

                  props.handleChangeTreeEventDetail(
                    `treeEventReducedAmountContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                    'reducedAwardedAmountParty',
                    PartySignum.client,
                  )
                  //Mixpanel 184 (All)
                  logActivity(
                    freemium.isFreemium,
                    'Added reduced awarded amount in legal tree',
                  )
                  setTimeout(() => {
                    if (
                      document.getElementById(
                        `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                      )
                    ) {
                      document
                        .getElementById(
                          `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        )!
                        .focus()
                      props.setEditMode(props.nodeId)
                    }
                  }, 200)
                }}
                id={`treeEventAddSectionButtonReducedAmount-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                <img
                  src={
                    reducedAmountButtonHover
                      ? addReducedAmountRed
                      : addReducedAmountGray
                  }
                  alt="plusImg"
                  className="treeEventAddSectionButtonImg"
                  id={`treeEventAddSectionButtonReducedAmountImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                />
                <p
                  className={
                    reducedAmountButtonHover
                      ? 'treeEventAddSectionButtonText dark'
                      : 'treeEventAddSectionButtonText'
                  }
                  id={`treeEventAddSectionButtonReducedAmountText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  data-textattribute="button-158"
                >
                  {getText('button-158', user.settings)}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="treeEventAmountInterestSection"
              id={`treeEventAmountInterestSection-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              <>
                <div
                  className="treeEventAmountSection"
                  onMouseEnter={() => setShowRemoveReducedAmount(true)}
                  onMouseLeave={() => setShowRemoveReducedAmount(false)}
                >
                  <InputNum
                    label={getText('label-96', user.settings)}
                    labelTextAttribute={'label-96'}
                    value={props.eventDetails.reducedAwardedAmount}
                    defaultZero
                    maxValue={99999999999}
                    maxNumberOfDecimals={3}
                    smallField
                    stickySuffix={')'}
                    stickySuffixMargin={44}
                    id={`treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    width={212}
                    onChange={(e: any) =>
                      props.handleChangeTreeEventDetail(
                        e.target.id,
                        'reducedAwardedAmount',
                        -e.target.value,
                      )
                    }
                    alwaysShrink
                    name={`treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    className="treeEventReducedAmountInput robotoNumbers"
                    localStyle={{
                      color:
                        props.eventDetails.reducedAwardedAmountParty ===
                        PartySignum.client
                          ? krogerusColors
                            ? KROGERUS_CLIENT_COLOR
                            : CLIENT_COLOR
                          : krogerusColors
                          ? KROGERUS_OPPOSING_PARTY_COLOR
                          : OPPOSING_PARTY_COLOR,
                    }}
                    prefix={
                      <div
                        style={{ width: '23px' }}
                        onClick={() =>
                          document
                            .getElementById(
                              `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                            )!
                            .focus()
                        }
                      >
                        <Symbol
                          symbolType={
                            props.eventDetails.reducedAwardedAmountParty ===
                            PartySignum.client
                              ? 'reducedClient'
                              : 'reducedOpposingParty'
                          }
                          targetId={`treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                          targetType={'treeReducedInput'}
                        />
                      </div>
                    }
                    onFocus={() => {
                      setTimeout(() => {
                        updateXarrow()
                      }, 100)
                    }}
                    suffix={scenarioSnapshot.currentSnapshot.currency}
                    tabIndex={10 * props.eventIndex + 3}
                  />
                  {showRemoveReducedAmount ? (
                    <>
                      <div
                        className="treeEventRemoveSectionContainer"
                        id={`treeEventRemoveReducedAmountSectionContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        onClick={(e: any) => {
                          props.handleChangeTreeEventDetail(
                            `treeEventReducedAmountContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                            'reducedAwardedAmountParty',
                            PartySignum.off,
                          )
                          //Mixpanel 185 (All)
                          logActivity(
                            freemium.isFreemium,
                            'Removed reduced awarded amount in legal tree',
                          )
                        }}
                      >
                        <img
                          src={removeImg}
                          alt="removeBigImg"
                          className="treeEventRemoveSectionImg"
                          id={`treeEventRemoveReducedAmountSectionImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
                {props.editMode === props.nodeId ? (
                  <div
                    className="treeEventAwardSignumSection"
                    id={`treeEventReducedPartySection-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  >
                    <SwitchButton
                      width="212px"
                      label1={
                        getText('title-223', user.settings, partiesFormat)
                          .length > 20
                          ? getText('title-223', user.settings, partiesFormat)
                              .substring(0, 20)
                              .trim() + "...'s"
                          : getText('title-223', user.settings, partiesFormat) +
                            "'s"
                      }
                      label1TextAttribute="title-223"
                      label2={
                        getText('title-224', user.settings, partiesFormat)
                          .length > 20
                          ? getText('title-224', user.settings, partiesFormat)
                              .substring(0, 20)
                              .trim() + "...'s"
                          : getText('title-224', user.settings, partiesFormat) +
                            "'s"
                      }
                      label2TextAttribute="title-224"
                      onClick={(num: number) => {
                        if (num === 1) {
                          props.handleChangeTreeEventDetail(
                            `treeEventReducedAmountPartySwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                            'reducedAwardedAmountParty',
                            PartySignum.client,
                          )
                        } else {
                          props.handleChangeTreeEventDetail(
                            `treeEventReducedAmountPartySwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                            'reducedAwardedAmountParty',
                            PartySignum.opposingParty,
                          )
                        }
                        if (
                          document.getElementById(
                            `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                          )
                        ) {
                          document
                            .getElementById(
                              `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                            )!
                            .focus()
                        }
                      }}
                      backgroundColor1="white"
                      backgroundColor2="white"
                      color1={
                        krogerusColors ? KROGERUS_CLIENT_COLOR : CLIENT_COLOR
                      }
                      color2={
                        krogerusColors
                          ? KROGERUS_OPPOSING_PARTY_COLOR
                          : OPPOSING_PARTY_COLOR
                      }
                      id={`treeEventReducedAmountPartySwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      id1={`treeEventReducedAmountPartySwitch1-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      id2={`treeEventReducedAmountPartySwitch2-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      defaultState={
                        props.eventDetails.reducedAwardedAmountParty ===
                        PartySignum.client
                          ? 1
                          : 2
                      }
                      marginLabel1={
                        getText('title-223', user.settings, partiesFormat)
                          .length > 19
                          ? '0 0 0 40px'
                          : '0 0 0 20px'
                      }
                      marginLabel2={
                        getText('title-224', user.settings, partiesFormat)
                          .length > 19
                          ? '0 30px 0 10px'
                          : '0 30px 0 10px'
                      }
                      inTree
                    />
                  </div>
                ) : null}
              </>
            </div>
          </>
        )}
      </div>
    </>
  )
}
