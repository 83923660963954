import { atom } from 'recoil'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'

export const recoveryModeState = atom<{
  recoveryPreview: 'old' | 'new' | 'none'
  shaking: boolean
  showLowScreenMessage: boolean
  keepResults: boolean
  old?: ScenarioSnapshot
  new?: ScenarioSnapshot
}>({
  key: 'recoveryModeState',
  default: {
    recoveryPreview: 'none',
    showLowScreenMessage: false,
    shaking: false,
    keepResults: false,
    old: undefined,
    new: undefined,
  },
})
