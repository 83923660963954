import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../states/UserState'
import { getText } from '../../../../services/textFunctions'
import InputNum from '../../../../Components/Inputs/InputNum/InputNum'
import {
  AccessRole,
  LegalCostInputType,
  LegalCostsParty,
  LegalCostsProceedings,
  LegalCostsSwitchButtonType,
  TypeOfInstance,
} from '../../../../models/enums'
import InfoClickComponent from '../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import { useEffect, useState } from 'react'
import LegalCostsSwitchButton from './LegalCostsSwitchButton'

type Props = {
  party: LegalCostsParty
  proceedings: LegalCostsProceedings
  estimatedCostsValue: number | undefined
  recoverableCostsType: LegalCostsSwitchButtonType
  recoverableCostsValue: number | undefined
  incurredCostsType: LegalCostsSwitchButtonType
  incurredCostsValue: number | undefined
  handleChangeLegalCostValue: (
    e: any,
    party: LegalCostsParty,
    proceedings: LegalCostsProceedings,
    legalCostInputType: LegalCostInputType,
    value: number | LegalCostsSwitchButtonType,
  ) => void
  errors: string[]
  ownRole: AccessRole
}

export default function LegalCostsInputs(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const user = useRecoilValue(userState)
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])
  const proceedingsTitle =
    props.proceedings === LegalCostsProceedings.firstProceedings
      ? currentSnapshot.hasSecondTrial
        ? getText('label-33', user.settings)
        : getText('label-34', user.settings)
      : getText('label-35', user.settings)

  return (
    <div
      className={
        props.proceedings === LegalCostsProceedings.additionalProceedings
          ? 'legalCostsInputOuterContainer additional'
          : 'legalCostsInputOuterContainer'
      }
      id={`legalCostsInputOuterContainer-${props.party}-${props.proceedings}`}
    >
      <p
        className="proceedingsTitle"
        id={`proceedingsTitle-${props.party}-${props.proceedings}`}
        style={
          props.ownRole === AccessRole.VIEWER
            ? {
                marginTop: '17px',
              }
            : undefined
        }
      >
        {proceedingsTitle}
      </p>
      <div
        className="legalCostsInputContainer"
        id={`legalCostsInputContainer-${props.party}-${props.proceedings}`}
      >
        <div
          className="estimatedCostsInputContainer"
          id={`estimatedCostsInputContainer-${props.party}-${props.proceedings}`}
        >
          <InputNum
            label={
              props.proceedings === LegalCostsProceedings.firstProceedings
                ? getText('label-88', user.settings)
                : getText('label-92', user.settings)
            }
            labelTextAttribute={
              props.proceedings === LegalCostsProceedings.firstProceedings
                ? 'label-88'
                : 'label-92'
            }
            suffix={currentSnapshot.currency}
            value={props.estimatedCostsValue}
            className="form-control robotoNumbers"
            placeholderPadding="70px"
            name={`estimatedCosts-${props.party}-${props.proceedings}`}
            maxValue={99999999999}
            maxNumberOfDecimals={3}
            onChange={(e: any) =>
              props.handleChangeLegalCostValue(
                e,
                props.party,
                props.proceedings,
                LegalCostInputType.estimatedCosts,
                e.target.value,
              )
            }
            id={`estimatedCosts-${props.party}-${props.proceedings}`}
            tabIndex={10003}
            width={265}
            error={props.errors.includes(
              `estimatedCosts-${props.party}-${props.proceedings}`,
            )}
            helperText={
              props.proceedings === LegalCostsProceedings.additionalProceedings
                ? getText('helperText-6', user.settings)
                : undefined
            }
            helperTextAttribute={
              props.proceedings === LegalCostsProceedings.additionalProceedings
                ? 'helperText-6'
                : undefined
            }
            errorMessage={getText('error-21', user.settings)}
            errorTextAttribute="error-21"
            readOnly={props.ownRole === AccessRole.VIEWER}
            disabled={props.ownRole === AccessRole.VIEWER}
          />
          {props.party === LegalCostsParty.clientLegalCosts ? (
            props.proceedings === LegalCostsProceedings.firstProceedings ? (
              <InfoClickComponent
                infoClickText={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('infoButton-20', user.settings)
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? getText('infoButton-21', user.settings)
                    : getText('infoButton-22', user.settings)
                }
                infoClickTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'infoButton-20'
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? 'infoButton-21'
                    : 'infoButton-22'
                }
                idName="legalFeesOwnFirst"
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />
            ) : (
              <InfoClickComponent
                infoClickText={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? getText('infoButton-23', user.settings)
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? getText('infoButton-24', user.settings)
                    : getText('infoButton-25', user.settings)
                }
                infoClickTextAttribute={
                  currentSnapshot.court === TypeOfInstance.Public_Court
                    ? 'infoButton-23'
                    : currentSnapshot.court === TypeOfInstance.Arbitration
                    ? 'infoButton-24'
                    : 'infoButton-25'
                }
                idName="legalFeesOwnSecond"
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />
            )
          ) : props.proceedings === LegalCostsProceedings.firstProceedings ? (
            <InfoClickComponent
              infoClickText={
                currentSnapshot.court === TypeOfInstance.Public_Court
                  ? getText('infoButton-29', user.settings, partiesFormat)
                  : currentSnapshot.court === TypeOfInstance.Arbitration
                  ? getText('infoButton-30', user.settings, partiesFormat)
                  : getText('infoButton-31', user.settings, partiesFormat)
              }
              infoClickTextAttribute={
                currentSnapshot.court === TypeOfInstance.Public_Court
                  ? 'infoButton-29'
                  : currentSnapshot.court === TypeOfInstance.Arbitration
                  ? 'infoButton-30'
                  : 'infoButton-31'
              }
              idName="legalFeesOtherFirst"
              hoverElement={hoverElement}
              setHoverElement={setHoverElement}
            />
          ) : (
            <InfoClickComponent
              infoClickText={
                currentSnapshot.court === TypeOfInstance.Public_Court
                  ? getText('infoButton-32', user.settings, partiesFormat)
                  : currentSnapshot.court === TypeOfInstance.Arbitration
                  ? getText('infoButton-33', user.settings, partiesFormat)
                  : getText('infoButton-34', user.settings, partiesFormat)
              }
              infoClickTextAttribute={
                currentSnapshot.court === TypeOfInstance.Public_Court
                  ? 'infoButton-32'
                  : currentSnapshot.court === TypeOfInstance.Arbitration
                  ? 'infoButton-33'
                  : 'infoButton-34'
              }
              idName="legalFeesOtherSecond"
              hoverElement={hoverElement}
              setHoverElement={setHoverElement}
            />
          )}
        </div>
        <div
          className="recoverableCostsInputOuterContainer"
          id={`recoverableCostsInputOuterContainer-${props.party}-${props.proceedings}`}
        >
          {props.ownRole !== AccessRole.VIEWER && (
            <LegalCostsSwitchButton
              party={props.party}
              proceedings={props.proceedings}
              type={LegalCostInputType.recoverableCosts}
              value={props.recoverableCostsType}
              handleChangeLegalCostValue={props.handleChangeLegalCostValue}
            />
          )}
          <div
            className="recoverableCostsInputContainer"
            id={`recoverableCostsInputContainer-${props.party}-${props.proceedings}`}
          >
            {props.recoverableCostsType ===
            LegalCostsSwitchButtonType.currency ? (
              <InputNum
                label={getText('label-89', user.settings)}
                labelTextAttribute={'label-89'}
                suffix={currentSnapshot.currency}
                value={props.recoverableCostsValue}
                className="form-control robotoNumbers"
                name={`recoverableCosts-${props.party}-${props.proceedings}`}
                maxValue={99999999999}
                maxNumberOfDecimals={3}
                onChange={(e: any) =>
                  props.handleChangeLegalCostValue(
                    e,
                    props.party,
                    props.proceedings,
                    LegalCostInputType.recoverableCosts,
                    e.target.value,
                  )
                }
                id={`recoverableCosts-${props.party}-${props.proceedings}`}
                tabIndex={10003}
                width={265}
                error={props.errors.includes(
                  `recoverableCosts-${props.party}-${props.proceedings}`,
                )}
                helperText={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? getText('helperText-7', user.settings)
                    : getText('helperText-9', user.settings)
                }
                helperTextAttribute={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? 'helperText-7'
                    : 'helperText-9'
                }
                errorMessage={
                  !props.recoverableCostsValue ||
                  props.recoverableCostsValue <= props.estimatedCostsValue!
                    ? getText('error-21', user.settings)
                    : props.proceedings ===
                      LegalCostsProceedings.firstProceedings
                    ? getText('error-92', user.settings)
                    : getText('error-94', user.settings)
                }
                errorTextAttribute={
                  !props.recoverableCostsValue ||
                  props.recoverableCostsValue <= props.estimatedCostsValue!
                    ? 'error-21'
                    : props.proceedings ===
                      LegalCostsProceedings.firstProceedings
                    ? 'error-92'
                    : 'error-94'
                }
                readOnly={props.ownRole === AccessRole.VIEWER}
                disabled={props.ownRole === AccessRole.VIEWER}
              />
            ) : (
              <InputNum
                label={getText('label-89', user.settings)}
                labelTextAttribute={'label-89'}
                //data-textattriute="label-90" or data-textattriute="label-93"
                suffix={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? getText('label-90', user.settings)
                    : getText('label-93', user.settings)
                }
                suffixFontSize={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? undefined
                    : 12
                }
                value={props.recoverableCostsValue}
                className="form-control robotoNumbers"
                name={`recoverableCosts-${props.party}-${props.proceedings}`}
                maxValue={100}
                alwaysShrink
                maxNumberOfDecimals={0}
                onChange={(e: any) =>
                  props.handleChangeLegalCostValue(
                    e,
                    props.party,
                    props.proceedings,
                    LegalCostInputType.recoverableCosts,
                    e.target.value,
                  )
                }
                id={`recoverableCosts-${props.party}-${props.proceedings}`}
                tabIndex={10003}
                width={265}
                error={props.errors.includes(
                  `recoverableCosts-${props.party}-${props.proceedings}`,
                )}
                helperText={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? getText('helperText-8', user.settings)
                    : getText('helperText-10', user.settings)
                }
                helperTextAttribute={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? 'helperText-8'
                    : 'helperText-10'
                }
                errorMessage={getText('error-21', user.settings)}
                errorTextAttribute="error-21"
                readOnly={props.ownRole === AccessRole.VIEWER}
                disabled={props.ownRole === AccessRole.VIEWER}
              />
            )}
          </div>
        </div>
        <div
          className="incurredCostsInputOuterContainer"
          id={`incurredCostsInputOuterContainer-${props.party}-${props.proceedings}`}
        >
          {props.ownRole !== AccessRole.VIEWER && (
            <LegalCostsSwitchButton
              party={props.party}
              proceedings={props.proceedings}
              type={LegalCostInputType.incurredCosts}
              value={props.incurredCostsType}
              handleChangeLegalCostValue={props.handleChangeLegalCostValue}
            />
          )}
          <div
            className="incurredCostsInputContainer"
            id={`incurredCostsInputContainer-${props.party}-${props.proceedings}`}
          >
            {props.incurredCostsType === LegalCostsSwitchButtonType.currency ? (
              <InputNum
                label={getText('label-91', user.settings)}
                labelTextAttribute={'label-91'}
                suffix={currentSnapshot.currency}
                value={props.incurredCostsValue}
                className="form-control robotoNumbers"
                name={`incurredCosts-${props.party}-${props.proceedings}`}
                maxValue={99999999999}
                maxNumberOfDecimals={3}
                placeholderPadding="70px"
                onChange={(e: any) =>
                  props.handleChangeLegalCostValue(
                    e,
                    props.party,
                    props.proceedings,
                    LegalCostInputType.incurredCosts,
                    e.target.value,
                  )
                }
                id={`incurredCosts-${props.party}-${props.proceedings}`}
                tabIndex={10003}
                width={265}
                error={props.errors.includes(
                  `incurredCosts-${props.party}-${props.proceedings}`,
                )}
                helperText={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? getText('helperText-11', user.settings)
                    : getText('helperText-13', user.settings)
                }
                helperTextAttribute={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? 'helperText-11'
                    : 'helperText-13'
                }
                errorMessage={
                  !props.incurredCostsValue ||
                  props.incurredCostsValue <= props.estimatedCostsValue!
                    ? getText('error-21', user.settings)
                    : props.proceedings ===
                      LegalCostsProceedings.firstProceedings
                    ? getText('error-93', user.settings)
                    : getText('error-95', user.settings)
                }
                errorTextAttribute={
                  !props.incurredCostsValue ||
                  props.incurredCostsValue <= props.estimatedCostsValue!
                    ? 'error-21'
                    : props.proceedings ===
                      LegalCostsProceedings.firstProceedings
                    ? 'error-93'
                    : 'error-95'
                }
                readOnly={props.ownRole === AccessRole.VIEWER}
                disabled={props.ownRole === AccessRole.VIEWER}
              />
            ) : (
              <InputNum
                label={getText('label-91', user.settings)}
                labelTextAttribute={'label-91'}
                //data-textattriute="label-90" or data-textattriute="label-93"
                suffix={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? getText('label-90', user.settings)
                    : getText('label-93', user.settings)
                }
                suffixFontSize={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? undefined
                    : 12
                }
                value={props.incurredCostsValue}
                className="form-control robotoNumbers"
                name={`incurredCosts-${props.party}-${props.proceedings}`}
                maxValue={100}
                alwaysShrink
                maxNumberOfDecimals={0}
                onChange={(e: any) =>
                  props.handleChangeLegalCostValue(
                    e,
                    props.party,
                    props.proceedings,
                    LegalCostInputType.incurredCosts,
                    e.target.value,
                  )
                }
                id={`incurredCosts-${props.party}-${props.proceedings}`}
                tabIndex={10003}
                width={265}
                error={props.errors.includes(
                  `incurredCosts-${props.party}-${props.proceedings}`,
                )}
                helperText={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? getText('helperText-12', user.settings)
                    : getText('helperText-14', user.settings)
                }
                helperTextAttribute={
                  props.proceedings === LegalCostsProceedings.firstProceedings
                    ? 'helperText-12'
                    : 'helperText-14'
                }
                errorMessage={getText('error-21', user.settings)}
                errorTextAttribute="error-21"
                readOnly={props.ownRole === AccessRole.VIEWER}
                disabled={props.ownRole === AccessRole.VIEWER}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
