import React from 'react'
import { WhiteTooltip } from '../../../../../../../Components/tooltip/TooltipComponent'

type Props = {
  treeIndex: number
  thumbnail1: string
  thumbnail2: string
  id: string
  selected: 1 | 2
  onClick: () => void
  textAttribute1: `tooltip-${number}`
  textAttribute2: `tooltip-${number}`
  tooltipText1: string
  tooltipText2: string
}

export default function TreeSideMenuSwitch(props: Props) {
  return (
    <div
      className="treeSideMenuSwitchContainer"
      id={`treeSideMenuSwitchContainer-${props.id}`}
    >
      <div
        className="sideMenuSwitchBackground"
        style={props.selected === 1 ? { top: 4 } : { top: 45 }}
      ></div>
      <WhiteTooltip
        title={props.tooltipText1}
        textAttribute={props.textAttribute1}
        id={`treeSideMenuSwitchTooltip1-${props.id}`}
        placement="right-end"
      >
        <div
          className={`treeSideMenuSwitch up ${
            props.selected === 1 ? 'selected' : ''
          }`}
          id={`treeSideMenuSwitch1-${props.treeIndex}_${props.id}`}
          onClick={props.onClick}
        >
          <img
            src={props.thumbnail1}
            alt="sideMenuSwitchImg1"
            className="treeSideMenuSwitchImg1"
            id={`treeSideMenuSwitchImg1-${props.treeIndex}_${props.id}`}
          />
        </div>
      </WhiteTooltip>
      <WhiteTooltip
        title={props.tooltipText2}
        textAttribute={props.textAttribute2}
        id={`treeSideMenuSwitchTooltip2-${props.id}`}
        placement="right-end"
      >
        <div
          className={`treeSideMenuSwitch down ${
            props.selected === 2 ? 'selected' : ''
          }`}
          id={`treeSideMenuSwitch2-${props.treeIndex}_${props.id}`}
          onClick={props.onClick}
        >
          <img
            src={props.thumbnail2}
            alt="sideMenuSwitchImg2"
            className="treeSideMenuSwitchImg2"
            id={`treeSideMenuSwitchImg2-${props.treeIndex}_${props.id}`}
          />
        </div>
      </WhiteTooltip>
    </div>
  )
}
