import { IndependentClaim } from '../models/independentClaim'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { EventId, NodeId } from '../models/treeModels/treeTypes'

export function snapshotChangeKillsResults(
  oldSnapshot: ScenarioSnapshot,
  newSnapshot: ScenarioSnapshot,
) {
  if (
    oldSnapshot.firstTrialDate !== newSnapshot.firstTrialDate ||
    oldSnapshot.secondTrialDate !== newSnapshot.secondTrialDate ||
    legalCostHaveChanges(oldSnapshot, newSnapshot) ||
    oldSnapshot.hasInsurance !== newSnapshot.hasInsurance ||
    oldSnapshot.insuranceExcessPercentage !==
      newSnapshot.insuranceExcessPercentage ||
    oldSnapshot.maxInsurance !== newSnapshot.maxInsurance ||
    oldSnapshot.minimumExcessFee !== newSnapshot.minimumExcessFee ||
    oldSnapshot.hasBankruptcy !== newSnapshot.hasBankruptcy ||
    oldSnapshot.bankruptcyPercentage !== newSnapshot.bankruptcyPercentage ||
    oldSnapshot.hasSecondTrial !== newSnapshot.hasSecondTrial ||
    oldSnapshot.probSecondTrial !== newSnapshot.probSecondTrial ||
    oldSnapshot.expectedResults !== newSnapshot.expectedResults ||
    JSON.stringify(oldSnapshot.legalFeesDistribution) !==
      JSON.stringify(newSnapshot.legalFeesDistribution) ||
    oldSnapshot.claims.length !== newSnapshot.claims.length
  ) {
    return true
  }

  for (const [
    oldSnapshotClaimIndex,
    oldSnapshotClaim,
  ] of oldSnapshot.claims.entries()) {
    const newSnapshotIndependentClaim = newSnapshot.claims[
      oldSnapshotClaimIndex
    ] as IndependentClaim
    const newSnapshoTreeClaim = newSnapshot.claims[
      oldSnapshotClaimIndex
    ] as TreeClaim
    if (oldSnapshotClaim.type !== newSnapshotIndependentClaim.type) {
      return true
    }
    if (oldSnapshotClaim.type !== 'tree') {
      if (
        oldSnapshotClaim.amount !== newSnapshotIndependentClaim.amount ||
        oldSnapshotClaim.isInterest !==
          newSnapshotIndependentClaim.isInterest ||
        oldSnapshotClaim.interestRate !==
          newSnapshotIndependentClaim.interestRate ||
        oldSnapshotClaim.interestDate !==
          newSnapshotIndependentClaim.interestDate ||
        oldSnapshotClaim.liability !== newSnapshotIndependentClaim.liability ||
        JSON.stringify(oldSnapshotClaim.quantumProbabilities) !==
          JSON.stringify(newSnapshotIndependentClaim.quantumProbabilities) ||
        oldSnapshotClaim.hasOutOfCourt !==
          newSnapshotIndependentClaim.hasOutOfCourt ||
        JSON.stringify(oldSnapshotClaim.quantumScenarios) !==
          JSON.stringify(newSnapshotIndependentClaim.quantumScenarios)
      ) {
        return true
      }
    } else {
      let oldSnapshotTreeClaim = oldSnapshotClaim as TreeClaim

      if (
        (oldSnapshotTreeClaim.totalClaimedAmount &&
          newSnapshoTreeClaim.totalClaimedAmount &&
          oldSnapshotTreeClaim.totalClaimedAmount !==
            newSnapshoTreeClaim.totalClaimedAmount) ||
        (!oldSnapshotTreeClaim.totalClaimedAmount &&
          newSnapshoTreeClaim.totalClaimedAmount) ||
        (oldSnapshotTreeClaim.totalClaimedAmount &&
          !newSnapshoTreeClaim.totalClaimedAmount)
      ) {
        return true
      }

      if (
        (oldSnapshotTreeClaim.totalCounterClaimedAmount &&
          newSnapshoTreeClaim.totalCounterClaimedAmount &&
          oldSnapshotTreeClaim.totalCounterClaimedAmount !==
            newSnapshoTreeClaim.totalCounterClaimedAmount) ||
        (!oldSnapshotTreeClaim.totalCounterClaimedAmount &&
          newSnapshoTreeClaim.totalCounterClaimedAmount) ||
        (oldSnapshotTreeClaim.totalCounterClaimedAmount &&
          !newSnapshoTreeClaim.totalCounterClaimedAmount)
      ) {
        return true
      }

      if (
        (oldSnapshotTreeClaim.treeWeightedValue &&
          newSnapshoTreeClaim.treeWeightedValue &&
          oldSnapshotTreeClaim.treeWeightedValue !==
            newSnapshoTreeClaim.treeWeightedValue) ||
        (!oldSnapshotTreeClaim.treeWeightedValue &&
          newSnapshoTreeClaim.treeWeightedValue) ||
        (oldSnapshotTreeClaim.treeWeightedValue &&
          !newSnapshoTreeClaim.treeWeightedValue)
      ) {
        return true
      }
      if (
        (oldSnapshotTreeClaim.totalClaimedAmountInterest1st &&
          newSnapshoTreeClaim.totalClaimedAmountInterest1st &&
          oldSnapshotTreeClaim.totalClaimedAmountInterest1st !==
            newSnapshoTreeClaim.totalClaimedAmountInterest1st) ||
        (!oldSnapshotTreeClaim.totalClaimedAmountInterest1st &&
          newSnapshoTreeClaim.totalClaimedAmountInterest1st) ||
        (oldSnapshotTreeClaim.totalClaimedAmountInterest1st &&
          !newSnapshoTreeClaim.totalClaimedAmountInterest1st)
      ) {
        return true
      }

      if (
        (oldSnapshotTreeClaim.totalCounterClaimedAmountInterest1st &&
          newSnapshoTreeClaim.totalCounterClaimedAmountInterest1st &&
          oldSnapshotTreeClaim.totalCounterClaimedAmountInterest1st !==
            newSnapshoTreeClaim.totalCounterClaimedAmountInterest1st) ||
        (!oldSnapshotTreeClaim.totalCounterClaimedAmountInterest1st &&
          newSnapshoTreeClaim.totalCounterClaimedAmountInterest1st) ||
        (oldSnapshotTreeClaim.totalCounterClaimedAmountInterest1st &&
          !newSnapshoTreeClaim.totalCounterClaimedAmountInterest1st)
      ) {
        return true
      }

      if (
        (oldSnapshotTreeClaim.treeWeightedValueInterest1st &&
          newSnapshoTreeClaim.treeWeightedValueInterest1st &&
          oldSnapshotTreeClaim.treeWeightedValueInterest1st !==
            newSnapshoTreeClaim.treeWeightedValueInterest1st) ||
        (!oldSnapshotTreeClaim.treeWeightedValueInterest1st &&
          newSnapshoTreeClaim.treeWeightedValueInterest1st) ||
        (oldSnapshotTreeClaim.treeWeightedValueInterest1st &&
          !newSnapshoTreeClaim.treeWeightedValueInterest1st)
      ) {
        return true
      }
      if (
        (oldSnapshotTreeClaim.totalClaimedAmountInterest2nd &&
          newSnapshoTreeClaim.totalClaimedAmountInterest2nd &&
          oldSnapshotTreeClaim.totalClaimedAmountInterest2nd !==
            newSnapshoTreeClaim.totalClaimedAmountInterest2nd) ||
        (!oldSnapshotTreeClaim.totalClaimedAmountInterest2nd &&
          newSnapshoTreeClaim.totalClaimedAmountInterest2nd) ||
        (oldSnapshotTreeClaim.totalClaimedAmountInterest2nd &&
          !newSnapshoTreeClaim.totalClaimedAmountInterest2nd)
      ) {
        return true
      }

      if (
        (oldSnapshotTreeClaim.totalCounterClaimedAmountInterest2nd &&
          newSnapshoTreeClaim.totalCounterClaimedAmountInterest2nd &&
          oldSnapshotTreeClaim.totalCounterClaimedAmountInterest2nd !==
            newSnapshoTreeClaim.totalCounterClaimedAmountInterest2nd) ||
        (!oldSnapshotTreeClaim.totalCounterClaimedAmountInterest2nd &&
          newSnapshoTreeClaim.totalCounterClaimedAmountInterest2nd) ||
        (oldSnapshotTreeClaim.totalCounterClaimedAmountInterest2nd &&
          !newSnapshoTreeClaim.totalCounterClaimedAmountInterest2nd)
      ) {
        return true
      }

      if (
        (oldSnapshotTreeClaim.treeWeightedValueInterest2nd &&
          newSnapshoTreeClaim.treeWeightedValueInterest2nd &&
          oldSnapshotTreeClaim.treeWeightedValueInterest2nd !==
            newSnapshoTreeClaim.treeWeightedValueInterest2nd) ||
        (!oldSnapshotTreeClaim.treeWeightedValueInterest2nd &&
          newSnapshoTreeClaim.treeWeightedValueInterest2nd) ||
        (oldSnapshotTreeClaim.treeWeightedValueInterest2nd &&
          !newSnapshoTreeClaim.treeWeightedValueInterest2nd)
      ) {
        return true
      }

      if (
        oldSnapshotTreeClaim.numOfTreeScenarios !==
          newSnapshoTreeClaim.numOfTreeScenarios ||
        JSON.stringify(oldSnapshotTreeClaim.trialDates) !==
          JSON.stringify(newSnapshoTreeClaim.trialDates) ||
        Object.keys(oldSnapshotTreeClaim.treeDetails.nodes).length !==
          Object.keys(newSnapshoTreeClaim.treeDetails.nodes).length ||
        Object.keys(oldSnapshotTreeClaim.treeDetails.events).length !==
          Object.keys(newSnapshoTreeClaim.treeDetails.events).length
      ) {
        return true
      }
      for (const nodeId of Object.keys(
        oldSnapshotTreeClaim.treeDetails.nodes,
      )) {
        if (
          Object.keys(newSnapshoTreeClaim.treeDetails.nodes).includes(nodeId)
        ) {
          if (
            oldSnapshotTreeClaim.treeDetails.nodes[nodeId as NodeId]
              .numberOfEvents !==
              newSnapshoTreeClaim.treeDetails.nodes[nodeId as NodeId]
                .numberOfEvents ||
            oldSnapshotTreeClaim.treeDetails.nodes[nodeId as NodeId].root !==
              newSnapshoTreeClaim.treeDetails.nodes[nodeId as NodeId].root
          ) {
            return true
          }
        } else {
          return true
        }
      }
      for (const eventId of Object.keys(
        oldSnapshotTreeClaim.treeDetails.events,
      )) {
        if (
          Object.keys(newSnapshoTreeClaim.treeDetails.events).includes(eventId)
        ) {
          if (
            JSON.stringify(
              oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
                .childrenNodes,
            ) !==
              JSON.stringify(
                newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                  .childrenNodes,
              ) ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.awardedAmount !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.awardedAmount ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.awardedAmountSignum !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.awardedAmountSignum ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.reducedAwardedAmount !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.reducedAwardedAmount ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.reducedAwardedAmountParty !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.reducedAwardedAmountParty ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.hasInterest !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.hasInterest ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.interestRate !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.interestRate ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.interestDate !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.interestDate ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.outOfCourtAmount !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.outOfCourtAmount ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.outOfCourtAmountSignum !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.outOfCourtAmountSignum ||
            oldSnapshotTreeClaim.treeDetails.events[eventId as EventId]
              .eventDetails.probability !==
              newSnapshoTreeClaim.treeDetails.events[eventId as EventId]
                .eventDetails.probability
          ) {
            return true
          }
        } else {
          return true
        }
      }
    }
  }
  return false
}

export function legalCostHaveChanges(
  oldSnapshot: ScenarioSnapshot,
  newSnapshot: ScenarioSnapshot,
) {
  return (
    oldSnapshot.legalCosts.included !== newSnapshot.legalCosts.included ||
    //Client Legal Costs - First Proceeding
    oldSnapshot.legalCosts.clientLegalCosts?.firstProceedings.estimatedCosts !==
      newSnapshot.legalCosts.clientLegalCosts?.firstProceedings
        .estimatedCosts ||
    oldSnapshot.legalCosts.clientLegalCosts?.firstProceedings.recoverableCosts
      .type !==
      newSnapshot.legalCosts.clientLegalCosts?.firstProceedings.recoverableCosts
        .type ||
    oldSnapshot.legalCosts.clientLegalCosts?.firstProceedings.recoverableCosts
      .value !==
      newSnapshot.legalCosts.clientLegalCosts?.firstProceedings.recoverableCosts
        .value ||
    oldSnapshot.legalCosts.clientLegalCosts?.firstProceedings.incurredCosts
      .type !==
      newSnapshot.legalCosts.clientLegalCosts?.firstProceedings.incurredCosts
        .type ||
    oldSnapshot.legalCosts.clientLegalCosts?.firstProceedings.incurredCosts
      .value !==
      newSnapshot.legalCosts.clientLegalCosts?.firstProceedings.incurredCosts
        .value ||
    //Client Legal Costs - Additional Proceedings
    oldSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
      ?.estimatedCosts !==
      newSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.estimatedCosts ||
    oldSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
      ?.recoverableCosts.type !==
      newSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.recoverableCosts.type ||
    oldSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
      ?.recoverableCosts.value !==
      newSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.recoverableCosts.value ||
    oldSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
      ?.incurredCosts.type !==
      newSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.incurredCosts.type ||
    oldSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
      ?.incurredCosts.value !==
      newSnapshot.legalCosts.clientLegalCosts?.additionalProceedings
        ?.incurredCosts.value ||
    //Opposing Party Legal Costs - First Proceeding
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
      .estimatedCosts !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .estimatedCosts ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
      .recoverableCosts.type !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .recoverableCosts.type ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
      .recoverableCosts.value !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .recoverableCosts.value ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
      .incurredCosts.type !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .incurredCosts.type ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
      .incurredCosts.value !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.firstProceedings
        .incurredCosts.value ||
    //Opposing Party Legal Costs - Additional Proceedings
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
      ?.estimatedCosts !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.estimatedCosts ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
      ?.recoverableCosts.type !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.recoverableCosts.type ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
      ?.recoverableCosts.value !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.recoverableCosts.value ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
      ?.incurredCosts.type !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.incurredCosts.type ||
    oldSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
      ?.incurredCosts.value !==
      newSnapshot.legalCosts.opposingPartyLegalCosts?.additionalProceedings
        ?.incurredCosts.value
  )
}
