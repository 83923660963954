import LogOutComponent from './LogOutComponent'
import UserAvatar from './UserAvatar'
import { debuggingState } from '../../../states/DebuggingState'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import { ToolTabs } from '../../../models/enums'
import { activeTabsState } from '../../../states/ActiveTabsState'
import { formattedNumToString } from '../../../services/formatNum'
import { userState } from '../../../states/UserState'
import {
  isDevEnv,
  isStagingEnv,
  isTestEnv,
} from '../../../services/commonFunctions'
import { useState } from 'react'

type Props = {
  showHide: boolean
  saveMenuOn?: undefined | string
  setSaveMenuOn?: (param: undefined | string) => void
  setShowHide: (param: boolean) => void
}

export default function SideMenuLogOut(props: Props) {
  const debugging = useRecoilValue(debuggingState)
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const activeToolTabs = useRecoilValue(activeTabsState)
  const [debugError, setDebugError] = useState<boolean>(false)

  return (
    <div className="sideMenuLogOutContainer">
      {(isDevEnv() || isStagingEnv() || isTestEnv()) && debugging && (
        <div className="mnmActivationContainer">
          {debugError && (
            <input
              id="_createErrorInput"
              name="_createErrorInput"
              type="hidden"
              style={{ display: 'none' }}
              value={scenarioSnapshot.currentSnapshot.claims[1000].name}
            />
          )}
          {activeToolTabs.tab !== ToolTabs.results ? (
            <>
              <div
                id="_createErrorButton"
                style={{
                  position: 'fixed',
                  bottom: 130,
                  left: props.showHide ? 180 : 70,
                  fontSize: '11px',
                  backgroundColor: 'pink',
                  borderRadius: '5px',
                  padding: '3px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setDebugError(true)
                }}
              >
                Create Error
              </div>
              <div
                id="_expectedResultsButton"
                style={{
                  position: 'fixed',
                  bottom: 70,
                  left: props.showHide ? 180 : 70,
                  fontSize: '11px',
                  backgroundColor: 'pink',
                  borderRadius: '5px',
                  padding: '3px',
                }}
              >
                <p>Expected Results</p>
                <p>
                  {formattedNumToString(
                    scenarioSnapshot.currentSnapshot.expectedResults!,
                    user.settings,
                  )}
                </p>
              </div>
            </>
          ) : null}
        </div>
      )}
      <UserAvatar showHide={props.showHide} />
      <LogOutComponent
        showHide={props.showHide}
        saveMenuOn={props.saveMenuOn}
        setSaveMenuOn={props.setSaveMenuOn}
        setShowHide={props.setShowHide}
      />
    </div>
  )
}
