import React from 'react'
import { TreeDetails } from '../../../../../../models/treeModels/treeClaim'
import {
  EventId,
  NodeId,
  NodesMovedForEditMode,
} from '../../../../../../models/treeModels/treeTypes'
import {
  changeEventKeysInNodeSiblingIndex,
  findNodeNumber,
} from '../../../../../../services/twoParentsFunctionsForNumberOfScenarios'

type Props = {
  treeDetails: TreeDetails
  selectedNodes: NodeId[]
  nodesMovedForEditMode: NodesMovedForEditMode | undefined
  editMode: NodeId | undefined
}

export default function TreeInfo(props: Props) {
  return (
    <>
      <div className="treeInfo">
        <div
          className="selectedNodes"
          style={{
            marginRight: '20px',
            fontSize: '9px',
            height: 'fit-content',
          }}
        >
          <h5
            style={{
              marginRight: '20px',
              fontSize: '12px',
            }}
          >
            EditMode:
          </h5>

          {props.editMode ?? 'undefined'}
        </div>
        <div
          className="selectedNodes"
          style={{
            marginRight: '20px',
            height: 'fit-content',
          }}
        >
          <h5>nodesMovedForEditMode:</h5>

          {props.nodesMovedForEditMode &&
            Object.keys(props.nodesMovedForEditMode).map(
              (nodeMovedForEditMode) => (
                <p key={`listOfSelectedNodes-${nodeMovedForEditMode}`}>
                  {nodeMovedForEditMode},
                </p>
              ),
            )}
        </div>
        <div className="selectedNodes" style={{ height: 'fit-content' }}>
          <h5>Selected Nodes :</h5>

          {props.selectedNodes.map((selectedNodeId) => (
            <p key={`listOfSelectedNodes-${selectedNodeId}`}>
              {findNodeNumber(selectedNodeId, props.treeDetails)} :{' '}
              {selectedNodeId} ,
            </p>
          ))}
        </div>
        <div className="nodesInfo">
          <h3>Nodes: {Object.keys(props.treeDetails.nodes).length}</h3>
          {Object.keys(props.treeDetails.nodes).map((nodeId, index) => {
            return (
              <div className="nodeSection" key={`info-${nodeId}`}>
                <h5>
                  <strong>{index}-</strong>NodeId: {nodeId}
                </h5>
                <h5>
                  nodeTitle:{' '}
                  {props.treeDetails.nodes[nodeId as NodeId].nodeTitle}
                </h5>
                <h5>
                  numberOfEvents:{' '}
                  {props.treeDetails.nodes[nodeId as NodeId].numberOfEvents}
                </h5>
                <h5>
                  nodeSiblingIndex:{' '}
                  {JSON.stringify(
                    changeEventKeysInNodeSiblingIndex(
                      props.treeDetails.nodes[nodeId as NodeId]
                        .nodeSiblingIndex,
                      props.treeDetails,
                    ),
                  )}
                </h5>
                <h5>root: {props.treeDetails.nodes[nodeId as NodeId].root}</h5>
              </div>
            )
          })}
        </div>
        <div className="eventsInfo">
          <h3>Events: {Object.keys(props.treeDetails.events).length}</h3>
          {Object.keys(props.treeDetails.events).map((eventId, index) => {
            return (
              <div className="eventsSection" key={`info-${eventId}`}>
                <h5>
                  <strong>{index}-</strong>EventId: {eventId}
                </h5>
                <h5>
                  Node Of Event Id:{' '}
                  {props.treeDetails.events[eventId as EventId].nodeOfEventId}
                </h5>
                <h5>
                  Event Index:{' '}
                  {props.treeDetails.events[eventId as EventId].eventIndex}
                </h5>
                <h5>Children Nodes:{' ['}</h5>
                {props.treeDetails.events[eventId as EventId].childrenNodes.map(
                  (childNode) => {
                    return <h5 key={`info2-${childNode}`}>{childNode},</h5>
                  },
                )}
                {']'}
                <h5>Event Details: </h5>
                <p>
                  <strong>eventTitle:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .eventTitle
                  }
                </p>
                <p>
                  <strong>awardedAmount:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .awardedAmount
                  }
                </p>
                <p>
                  <strong>awardedAmountSignum:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .awardedAmountSignum
                  }
                </p>
                <p>
                  <strong>hasInterest:</strong>{' '}
                  {props.treeDetails.events[eventId as EventId].eventDetails
                    .hasInterest
                    ? 'True'
                    : 'False'}
                </p>
                <p>
                  <strong>interestRate:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .interestRate
                  }
                </p>
                <p>
                  <strong>interestDate:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .interestDate
                  }
                </p>
                <p>
                  <strong>outOfCourtAmount:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .outOfCourtAmount
                  }
                </p>
                <p>
                  <strong>outOfCourtAmountSignum:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .outOfCourtAmountSignum
                  }
                </p>
                <p>
                  <strong>probability:</strong>{' '}
                  {
                    props.treeDetails.events[eventId as EventId].eventDetails
                      .probability
                  }
                </p>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
