import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getText } from '../../../../services/textFunctions'
import { userState } from '../../../../states/UserState'
import DeleteAccount from './components/DeleteAccount'
import Notifications from './components/Notifications'
import OrganisationDetails from './components/OrganisationDetails'
import Password from './components/Password'
import PersonalDetails from './components/PersonalDetails'
import TwoFactorAuth from './components/TwoFactorAuth'
import TermsOfUse from './components/TermsOfUse'
import { featuresState } from '../../../../states/FeaturesState'
import { UserRole } from '../../../../models/enums'

const AccountDetails = () => {
  const user = useRecoilValue(userState)
  const features = useRecoilValue(featuresState)

  const [hoverElement, setHoverElement] = useState('')

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  const onMouseDown = (e: any) => {
    setHoverElement('')
  }

  return (
    <div className="tool-component for-settings" id="accountDetailsComp">
      <h2
        className="tool-component-title"
        id="accountDetailsTitle"
        data-textattribute="title-177"
      >
        {getText('title-177', user.settings)}
      </h2>
      <PersonalDetails
        hoverElement={hoverElement}
        setHoverElement={setHoverElement}
      />
      <OrganisationDetails
        hoverElement={hoverElement}
        setHoverElement={setHoverElement}
      />
      <Notifications />
      {(features.use_microsoft_sso === false ||
        user.role === UserRole.EPEROTO_ADMIN) && <Password />}
      {(features.use_microsoft_sso === false ||
        user.role === UserRole.EPEROTO_ADMIN) && <TwoFactorAuth />}

      <DeleteAccount />
      <TermsOfUse />
      <div className="tool-component-spacer" style={{ height: '13px' }}></div>
    </div>
  )
}

export default AccountDetails
