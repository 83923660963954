import React, { useState, useLayoutEffect } from 'react'
import { WhiteTooltipPath } from '../../../../Components/tooltip/TooltipComponent'

type Props = {
  pathTitle: string
  isFinal: boolean
  isLink?: boolean
  id: string
  textAttribute: string
  hovering?: boolean
}

export default function CutPathName(props: Props) {
  const wordLimit = 15

  function useMediaQuery() {
    const [screenSize, setScreenSize] = useState([0, 0])

    useLayoutEffect(() => {
      function updateScreenSize() {
        setScreenSize([window.innerWidth, window.innerHeight])
      }
      window.addEventListener('resize', updateScreenSize)
      updateScreenSize()
      return () => window.removeEventListener('resize', updateScreenSize)
    }, [])

    return screenSize
  }

  const mediaQuery = useMediaQuery()

  const cutText = (string: string) => {
    let divideHere = 6
    if (mediaQuery[0] < 1040) {
      divideHere = 0
    }
    const newString = (
      <>
        <span
          id={`${props.id}1-span`}
          style={
            string.substring(0, string.length - divideHere)[
              string.substring(0, string.length - divideHere).length - 1
            ] === ' '
              ? { marginRight: '4px' }
              : undefined
          }
        >
          {string.substring(0, string.length - divideHere)}
        </span>
        <span
          id={`${props.id}2-p`}
          style={
            string.substring(
              string.length - divideHere,
              string.length - 1,
            )[0] === ' '
              ? { marginLeft: '4px' }
              : undefined
          }
        >
          {string.substring(string.length - divideHere, string.length)}
        </span>
      </>
    )

    return newString
  }

  function findClassName(action?: boolean) {
    let className = 'pathTitleText'
    className += props.isFinal ? ' final' : ''
    className += props.isLink ? ' link' : ''

    if (action) {
      className += ' wordLimit-cut'
    }
    return className
  }

  return (
    <>
      {props.pathTitle.length > wordLimit ? (
        <>
          <p className="hiddenPlaceholder word-limit" id={`${props.id}1`}>
            {cutText(props.pathTitle)}
          </p>
          <WhiteTooltipPath
            title={<p data-openreplay-obscured>{props.pathTitle}</p>}
            textAttribute={undefined}
            id={`breadcrumbPathTitleTooltip`}
          >
            <p
              className={findClassName(true)}
              style={
                props.hovering
                  ? {
                      color: '#201a2d',
                      fontWeight: '600',
                    }
                  : {}
              }
              id={`${props.id}2`}
              data-textattribute={props.textAttribute}
            >
              {cutText(props.pathTitle)}
            </p>
          </WhiteTooltipPath>
        </>
      ) : (
        <>
          <p
            className={findClassName()}
            style={
              props.hovering
                ? {
                    color: '#201a2d',
                    fontWeight: '600',
                  }
                : {}
            }
            id={`${props.id}1`}
            data-textattribute={props.textAttribute}
          >
            {props.pathTitle}
          </p>
          <p className="hiddenPlaceholder" id={`${props.id}2`}>
            {props.pathTitle}
          </p>
        </>
      )}
    </>
  )
}
