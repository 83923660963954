import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { SubscriptionPlan } from '../../../../models/generalTypes'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../../services/commonFunctions'
import { convertDateTimeToLocal } from '../../../../services/dateFunctions'
import { getCompanyDetails } from '../../../../services/requests'
import { getText } from '../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../states/HandlingErrorsState'
import { userState } from '../../../../states/UserState'
import ProceedingsPlaceholder from '../../../toolPage/tabs/1_proceedings/ProceedingsPlaceholder'
import SettingInfoRow from '../components/SettingInfoRow'

const SubscriptionComponent = () => {
  const user = useRecoilValue(userState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [loading, setLoading] = useState(true)
  const [subscriptionPlan, setSubscriptionPlan] = useState<
    SubscriptionPlan | undefined
  >(undefined)

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      getCompanyDetails().then((res) => {
        setHandlingErrors(
          findHandlingErrorState(
            res,
            handlingErrors,
            'getCompanyDetails',
            true,
          ),
        )

        if (!('errorCode' in res)) {
          setSubscriptionPlan({
            name: res.data.subscription_plan.name,
            expiration_date: res.data.subscription_plan.expiration_date,
            number_of_users: res.data.subscription_plan.number_of_users,
            allowed_number_of_users:
              res.data.subscription_plan.allowed_number_of_users,
            number_of_spectators:
              res.data.subscription_plan.number_of_spectators,
            allowed_number_of_spectators:
              res.data.subscription_plan.allowed_number_of_spectators,
            features: [...res.data.subscription_plan.features],
          })
        }

        setLoading(false)

        //Mixpanel 111
        logActivity(false, 'Visited subscription details')
      })
    }, 500)
    // eslint-disable-next-line
  }, [])

  return loading ? (
    <ProceedingsPlaceholder />
  ) : subscriptionPlan ? (
    <div className="tool-component for-settings" id="accountDetailsComp">
      <h2
        className="tool-component-title"
        id="subscriptionComponentTitle"
        data-textattribute="title-179"
      >
        {getText('title-179', user.settings)}
      </h2>
      <SettingInfoRow
        id="planNameInfo"
        withColor
        descriptionText={getText('description-113', user.settings)}
        descriptionTextAttribute="description-113"
        valueText={subscriptionPlan?.name}
        isFirst
      />
      <SettingInfoRow
        id="expirationDateInfo"
        withColor
        descriptionText={getText('description-114', user.settings)}
        descriptionTextAttribute="description-114"
        valueText={convertDateTimeToLocal(
          subscriptionPlan!.expiration_date,
          user.settings,
          true,
        )}
      />
      <div className="tool-component-spacer" style={{ height: '20px' }}></div>
      <SettingInfoRow
        id="registeredUsersInfo"
        withColor
        descriptionText={getText('description-115', user.settings)}
        descriptionTextAttribute="description-115"
        valueText={
          subscriptionPlan?.number_of_users.toString() +
          '/' +
          subscriptionPlan?.allowed_number_of_users.toString()
        }
      />
      {subscriptionPlan &&
        subscriptionPlan.allowed_number_of_spectators > 0 && (
          <SettingInfoRow
            id="registeredViewersInfo"
            withColor
            descriptionText={getText('description-116', user.settings)}
            descriptionTextAttribute="description-116"
            valueText={
              subscriptionPlan?.number_of_spectators.toString() +
              '/' +
              subscriptionPlan?.allowed_number_of_spectators.toString()
            }
          />
        )}
      <div className="tool-component-spacer" style={{ height: '50px' }}></div>
      <SettingInfoRow
        id="contactSupportInfo"
        descriptionText={getText('description-117', user.settings)}
        descriptionTextAttribute="description-117"
        coloredDescriptionText="support@eperoto.com"
      />
      <div className="tool-component-spacer" style={{ height: '13px' }}></div>
    </div>
  ) : null
}

export default SubscriptionComponent
