import React from 'react'
import { useRecoilValue } from 'recoil'
import bigCopy from '../../../resources/images/152-bigCopy.svg'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'

type Props = {
  onFinishSetup: () => void
  forPasswordChange?: boolean
  forSettings?: boolean
}

const DoneScreen = (props: Props) => {
  const user = useRecoilValue(userState)

  return (
    <>
      <div className="inputsContainer">
        <div className="inputsCenterContainer higher">
          <img className="bigDoneImage" src={bigCopy} alt="copyImage" />
          <p
            className="lettersContainer bigletters"
            id="doneScreenInstructions"
            data-textattribute="description-82a,description-82b"
          >
            {props.forPasswordChange
              ? 'Password Changed!'
              : getText('description-82a', user.settings)}
            {props.forPasswordChange ? null : <br />}
            {props.forPasswordChange
              ? null
              : getText('description-82b', user.settings)}
          </p>
        </div>
      </div>
      <div className="submitButtonContainer">
        <button
          className="submitButton"
          onClick={props.onFinishSetup}
          id="submitDoneSreenButton"
          data-textattribute={props.forSettings ? 'button-126' : 'button-51'}
        >
          {props.forSettings
            ? getText('button-126', user.settings)
            : getText('button-51', user.settings)}
        </button>
      </div>
    </>
  )
}

export default DoneScreen
