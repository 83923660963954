import { CircularProgress } from '@mui/material'
import React from 'react'
import Button from '../../../../../Components/Buttons/Button/Button'
import InfoClickComponent from '../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import DropDown from '../../../../../Components/Inputs/DropDown/DropDown'
import { dropDownValidation } from '../../../../../Modules/AppModules/ValidationFunctions'
import AutocompleteDropDown from '../../../../../Components/Inputs/DropDown/AutocompleteDropDown'

type Props = {
  ids: string[]
  values: string[]
  options: string[][]
  onChange: (newValue: string, id: string) => void
  hoverElement: string
  setHoverElement: (value: string) => void
  labelTextsArray: string[]
  titleText: string
  descriptionText: string
  infoButtonTextsArray: string[]
  confirmationText: string
  buttonText: string
  labelTextsArrayAttribute: `label-${number}`[]
  titleTextAttribute: `title-${number}`
  descriptionTextAttribute: `description-${number}`
  infoButtonTextsArrayAttribute: `infoButton-${number}`[]
  confirmationTextAttribute: `button-${number}`
  buttonTextAttribute: `button-${number}`
  showSaved: string
  showSaveButtons: string[]
  loading: string
  onSave: (id: string) => void
  errorMessage?: string
  errorTextAttribute?: `error-${number}`
  asAutocomplete?: boolean
  getDescriptions?: ((value: any) => string)[]
}

const FormatComponent = (props: Props) => {
  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id={`formatTitleText-${props.ids[0]}`}
        data-textattribute={props.titleTextAttribute}
      >
        {props.titleText}
      </h1>
      <div
        className="tool-component-third-title marginLeft6"
        id={`formatDescriptionText-${props.ids[0]}`}
        data-textattribute={props.descriptionTextAttribute}
      >
        {props.descriptionText}
      </div>
      <div className="formatDropdownOuterContainer">
        <div className="formatDropdownInnerContainer">
          {props.ids.map((id, index) => (
            <div
              className="tool-component-input-container marginLeft3 first-container"
              id={`formatDropdownContainer-${props.ids[0]}${index}`}
              key={index}
            >
              {props.asAutocomplete ? (
                <AutocompleteDropDown
                  id={id}
                  label={props.labelTextsArray[index]}
                  name={id}
                  options={props.options[index]}
                  value={props.values[index]}
                  width={275}
                  onChange={(e: any) => props.onChange(e.target.value, id)}
                  labelTextAttribute={props.labelTextsArrayAttribute[0]}
                  getDescriptions={
                    props.getDescriptions
                      ? props.getDescriptions[index]
                      : undefined
                  }
                  noHelperText={props.errorMessage ? false : true}
                  tabIndex={1001}
                  alwaysShrink
                />
              ) : (
                <DropDown
                  id={id}
                  name={id}
                  label={props.labelTextsArray[index]}
                  labelTextAttribute={props.labelTextsArrayAttribute[0]}
                  value={props.values[index]}
                  width={275}
                  options={props.options[index]}
                  validateFunction={(value: string) =>
                    dropDownValidation(value)
                  }
                  onChange={(e: any) => props.onChange(e.target.value, id)}
                  noHelperText={props.errorMessage ? false : true}
                  tabIndex={1001}
                  error={props.errorMessage !== undefined}
                  errorMessage={props.errorMessage}
                  errorTextAttribute={props.errorTextAttribute}
                />
              )}
              <InfoClickComponent
                infoClickText={props.infoButtonTextsArray[index]}
                infoClickTextAttribute={
                  props.infoButtonTextsArrayAttribute[index]
                }
                idName={id}
                hoverElement={props.hoverElement}
                setHoverElement={props.setHoverElement}
              />
            </div>
          ))}
        </div>
        <div className="formatDropdownSaveButtonContainer">
          {props.showSaved !== '' &&
            props.ids.includes(props.showSaved) &&
            props.showSaveButtons.filter((showSaveButtonId) =>
              props.ids.includes(showSaveButtonId),
            ).length === 0 && (
              <Button
                id={`saved${props.ids[0]}`}
                buttonText={props.confirmationText}
                buttonTextAttribute={props.confirmationTextAttribute}
                buttonType="nonactiveText"
                className="createCaseButton"
                type="button"
                onClick={() => {}}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            )}
          {props.showSaveButtons.filter((showSaveButtonId) =>
            props.ids.includes(showSaveButtonId),
          ).length > 0 && (
            <Button
              id={`save${props.ids[0]}`}
              buttonText={
                props.loading !== '' && props.ids.includes(props.loading) ? (
                  <div className="circularProgressDiv" id={'popUp7'}>
                    <div id={'popUp8'}>
                      <CircularProgress size={14} />
                      <div
                        className="hiddenTextForSize"
                        style={{ height: 0, visibility: 'hidden' }}
                        id="hiddenCreateCaseButtonText"
                        data-textattribute={props.buttonTextAttribute}
                      >
                        {props.buttonText}
                      </div>
                    </div>
                  </div>
                ) : (
                  props.buttonText
                )
              }
              buttonTextAttribute={props.buttonTextAttribute}
              buttonType="contained"
              className="createCaseButton"
              type="button"
              onClick={() => props.onSave(props.ids[0])}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default FormatComponent
