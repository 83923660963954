import React from 'react'
import pasteFromClipboardImg from '../../../../../resources/images/225-copy_to_clipboard.svg'
import HoverComponent from '../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import { getText } from '../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'

type Props = {
  onClick: () => void
  onMouseEnter: () => void
  onMouseLeave: () => void
  nothingToPaste: boolean
  isHovered: boolean
  forScenario?: boolean
}

const PasteClaimButton = (props: Props) => {
  const user = useRecoilValue(userState)

  return (
    <div
      className="pasteFromClipboardContainer"
      id="pasteFromClipboardContainer"
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onClick={props.onClick}
    >
      {props.isHovered && !props.nothingToPaste ? (
        <div
          className="hoverComponentContainer"
          style={{ top: '10px', left: '40px' }}
        >
          <HoverComponent
            textAttribute={props.forScenario ? 'hover-65' : 'hover-64'}
            hoverText={
              props.forScenario
                ? getText('hover-65', user.settings)
                : getText('hover-64', user.settings)
            }
            id="pasteFromClipboardContainer"
          />
        </div>
      ) : null}
      <img
        src={pasteFromClipboardImg}
        alt="pasteFromClipboardImg"
        className="pasteFromClipboardImg"
      />
      {props.nothingToPaste && (
        <div className="nothingToPasteTextContainer">
          <p className="nothingToPasteText" date-textattribute="message-106">
            {getText('message-106', user.settings)}
          </p>
        </div>
      )}
    </div>
  )
}

export default PasteClaimButton
