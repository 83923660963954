import React, { useState, useEffect, useRef } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'

import OutcomeTitle from './hoverResultsComponents/OutcomeTitle'
import Description from './hoverResultsComponents/Description'
import PrincipalAmount from './hoverResultsComponents/PrincipalAmount'
import InterestAwarded from './hoverResultsComponents/InterestAwarded'
import LegalCostsBy from './hoverResultsComponents/LegalCostsBy'
import LegalCostsTo from './hoverResultsComponents/LegalCostsTo'
import LegalCostsByInsurance from './hoverResultsComponents/LegalCostsByInsurance'
import ExternalConsequence from './hoverResultsComponents/ExternalConsequence'
import UnsuccessfulEnforcement from './hoverResultsComponents/UnsuccessfulEnforcement'
import ProbabilityOfOutcome from './hoverResultsComponents/ProbabilityOfOutcome'
import WeightedProbability from './hoverResultsComponents/WeightedProbability'
import HoverComponent from '../../../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import DescriptionTitleHover from './hoverResultsComponents/DescriptionTitleHover'

import arrowImg from '../../../../../../../resources/images/009-arrowDownThickBlue.svg'
import firstTrialImg from '../../../../../../../resources/images/137-first.svg'
import secondTrialImg from '../../../../../../../resources/images/140-second.svg'
import bankruptcyFirstTrialImg from '../../../../../../../resources/images/138-firstCourtBunkruptcy.svg'
import bankruptcySecondTrialImg from '../../../../../../../resources/images/139-secondCourtBunkruptcy.svg'

import { ScenarioSnapshot } from '../../../../../../../models/scenarioSnapshot'
import {
  isDevEnv,
  isStagingEnv,
  isTestEnv,
  resultsFontSize,
} from '../../../../../../../services/commonFunctions'
import { ResultsObject } from '../../../../../../../models/generalTypes'
import { TypeOfInstance } from '../../../../../../../models/enums'
import { useRecoilValue } from 'recoil'
import { debuggingState } from '../../../../../../../states/DebuggingState'
import { ResultsTooltip } from '../../../../../../../Components/tooltip/TooltipComponent'
import {
  initializeDataForTable,
  calculateHeight,
  showEnforcement,
  showInsurance,
  showInterest,
  showLegalFees,
  showOutOfCourt,
} from '../../../../../../../services/resultsFunctions'
import { userState } from '../../../../../../../states/UserState'
import {
  getSplitText,
  getText,
} from '../../../../../../../services/textFunctions'
import { snapshotHasEnforcementRisk } from '../../../../../../../services/reportFunctions'
import { stringAndRoundBigNumbers } from '../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { resultsSettingsState } from '../../../../../../../states/ResultsSettingsState'
import { findDisputedAmount } from '../../../../../../../services/disputedAmountFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  chipFilter: {
    chipFirstTrial: string
    chipSecondTrial: string
    chipBankruptcyFirstTrial: string
    chipBankruptcySecondTrial: string
  }
  resultsViewParty: 'client' | 'opposing'
}

export default function ResultsList(props: Props) {
  const debugging = useRecoilValue(debuggingState)
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]
  // eslint-disable-next-line
  const [dataForTable, setDataForTable] = useState(
    initializeDataForTable(
      props.resultsFromBackend.table,
      props.currentSnapshot,
    ),
  )
  const [sortedDataForTable, setSortedDataForTable] = useState(
    initializeDataForTable(
      props.resultsFromBackend.table,
      props.currentSnapshot,
    ),
  )
  const [sorting, setSorting] = useState<(boolean | string)[]>(
    new Array(13).fill(false),
  )
  const [hoverElement, setHoverElement] = useState('')
  const secondaryHover = useRef('open')
  const [gridWidth, setGridWidth] = useState(790)
  const [sortedOption, setSortedOption] = useState(0)
  //const [hoverWindowCoordinates, setHoverWindowCoordinates] = useState([0, 0])

  const onMouseDown = (e: any) => {
    setHoverElement('')
    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function findGridWidth() {
    if (document.getElementById('horizontalResultsContainer')) {
      let tempWidth =
        document.getElementById('horizontalResultsContainer')!.offsetWidth - 430
      setGridWidth(tempWidth)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', findGridWidth)

    return () => window.removeEventListener('resize', findGridWidth)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setDataForTable(
      initializeDataForTable(
        props.resultsFromBackend.table,
        props.currentSnapshot,
      ),
    )

    // eslint-disable-next-line
  }, [props.resultsFromBackend])

  //filter the results using the chipFilter
  useEffect(() => {
    let tempDataForTable = JSON.parse(JSON.stringify(dataForTable))

    if (props.chipFilter) {
      if (
        props.chipFilter.chipFirstTrial === 'hide' &&
        props.chipFilter.chipSecondTrial === 'hide' &&
        props.chipFilter.chipBankruptcyFirstTrial === 'hide' &&
        props.chipFilter.chipBankruptcySecondTrial === 'hide'
      ) {
      } else {
        if (props.chipFilter.chipFirstTrial === 'hide') {
          tempDataForTable = tempDataForTable.filter((item: number[]) => {
            if (item[1] === 0) {
              return false
            } else {
              return true
            }
          })
        }
        if (props.chipFilter.chipSecondTrial === 'hide') {
          tempDataForTable = tempDataForTable.filter((item: number[]) => {
            if (item[1] === 1) {
              return false
            } else {
              return true
            }
          })
        }
        if (props.chipFilter.chipBankruptcyFirstTrial === 'hide') {
          tempDataForTable = tempDataForTable.filter((item: number[]) => {
            if (item[1] === 2) {
              return false
            } else {
              return true
            }
          })
        }
        if (props.chipFilter.chipBankruptcySecondTrial === 'hide') {
          tempDataForTable = tempDataForTable.filter((item: number[]) => {
            if (item[1] === 3) {
              return false
            } else {
              return true
            }
          })
        }
      }
    }

    sortByFunction(
      sortedOption,
      tempDataForTable,
      props.chipFilter !== undefined,
    )
    // eslint-disable-next-line
  }, [props.chipFilter, dataForTable])

  const createPercentageSum = () => {
    var percentageSum = 0
    if (Object.keys(props.resultsFromBackend.table).length !== 0) {
      props.resultsFromBackend.table.map((item: any) => {
        percentageSum += item.probability_of_outcome * 100
        return null
      })
    }
    return percentageSum
  }

  function sortByFunction(
    option: number,
    dataForSorting: any,
    chipFilter?: boolean,
  ) {
    var tempSortedDataForTable = JSON.parse(JSON.stringify(dataForSorting))
    var tempSortArray = sorting
    var sortedArray = []
    if (!chipFilter) {
      tempSortArray = new Array(13).fill(false)

      if (sorting[option] === false) {
        tempSortArray[option] = 'asc'
      } else if (sorting[option] === 'asc') {
        tempSortArray[option] = 'des'
      }
      setSorting(tempSortArray)
    }
    function ComparatorAsc(a: any, b: any) {
      if (a[option] < b[option]) return -1
      if (a[option] > b[option]) return 1
      return 0
    }
    function ComparatorDes(a: any, b: any) {
      if (a[option] > b[option]) return -1
      if (a[option] < b[option]) return 1
      return 0
    }

    if (tempSortArray[option] === false) {
      sortedArray = tempSortedDataForTable.sort((a: any, b: any) => {
        if (a[0] < b[0]) return -1
        if (a[0] > b[0]) return 1
        return 0
      })
    } else if (tempSortArray[option] === 'asc') {
      sortedArray = tempSortedDataForTable.sort(ComparatorAsc)
    } else if (tempSortArray[option] === 'des') {
      sortedArray = tempSortedDataForTable.sort(ComparatorDes)
    }

    setSortedDataForTable(sortedArray)
  }

  function cellRenderer(propsOfRenderer: any) {
    return (
      <div
        className="resultsRows"
        key={`resultsRows-${propsOfRenderer.columnIndex}`}
        style={propsOfRenderer.style}
        id={`resultsRows-${propsOfRenderer.columnIndex}`}
      >
        <div className="title title1 resultsTitle">
          <ResultsTooltip
            open={
              hoverElement ===
              `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}OutcomeTitle`
                ? true
                : false
            }
            title={
              <OutcomeTitle
                id={propsOfRenderer.columnIndex}
                sortedDataForTable={sortedDataForTable}
                currentSnapshot={props.currentSnapshot}
                resultsViewParty={props.resultsViewParty}
              />
            }
          >
            <p
              className="textForHover"
              onClick={() =>
                setHoverElement(
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}OutcomeTitle`,
                )
              }
            >
              {(sortedDataForTable[propsOfRenderer.columnIndex][0] as number) +
                1}
            </p>
          </ResultsTooltip>
        </div>
        <div className="row row1">
          <ResultsTooltip
            open={
              hoverElement ===
              `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}Description`
                ? true
                : false
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <Description
                id={propsOfRenderer.columnIndex}
                type={
                  sortedDataForTable[propsOfRenderer.columnIndex][1] as number
                }
                state={props.currentSnapshot}
              />
            }
            placement="right-start"
            arrow
          >
            <div
              className="textForHover"
              onClick={() =>
                setHoverElement(
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}Description`,
                )
              }
            >
              {sortedDataForTable[propsOfRenderer.columnIndex][1] === 0 ? (
                <img
                  src={firstTrialImg}
                  alt="firstTrialImg"
                  id={`firstTrialImg-${propsOfRenderer.columnIndex}`}
                />
              ) : sortedDataForTable[propsOfRenderer.columnIndex][1] === 1 ? (
                <img
                  src={secondTrialImg}
                  alt="secondTrialImg"
                  id={`secondTrialImg-${propsOfRenderer.columnIndex}`}
                />
              ) : sortedDataForTable[propsOfRenderer.columnIndex][1] === 2 ? (
                <img
                  src={bankruptcyFirstTrialImg}
                  alt="bankruptcyFirstTrialImg"
                  id={`bankruptcyFirstTrialImg-${propsOfRenderer.columnIndex}`}
                />
              ) : (
                <img
                  src={bankruptcySecondTrialImg}
                  alt="bankruptcySecondTrialImg"
                  id={`bankruptcySecondTrialImg-${propsOfRenderer.columnIndex}`}
                />
              )}
            </div>
          </ResultsTooltip>
        </div>
        <div className="row row2">
          <ResultsTooltip
            open={
              hoverElement ===
              `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}ProbabilityOfOutcome`
                ? true
                : false
            }
            title={
              <ProbabilityOfOutcome
                index={propsOfRenderer.columnIndex}
                sortedDataForTable={sortedDataForTable}
                currentSnapshot={props.currentSnapshot}
                resultsViewParty={props.resultsViewParty}
              />
            }
          >
            <p
              className="textForHover robotoNumbers"
              onClick={() =>
                setHoverElement(
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}ProbabilityOfOutcome`,
                )
              }
            >
              {(sortedDataForTable[propsOfRenderer.columnIndex][2] as number) *
                100 <
              0.01
                ? '~0 %'
                : stringAndRoundBigNumbers(
                    (sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][2] as number) * 100,

                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                    false,
                    true,
                  ) + ' %'}
            </p>
          </ResultsTooltip>
        </div>
        <div className="title title2 resultsTitle"></div>
        <div className="row row3">
          <ResultsTooltip
            open={
              hoverElement ===
              `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}PrincipalAmount`
                ? true
                : false
            }
            title={
              <PrincipalAmount
                id={propsOfRenderer.columnIndex}
                sortedDataForTable={sortedDataForTable}
                currentSnapshot={props.currentSnapshot}
                resultsViewParty={props.resultsViewParty}
              />
            }
          >
            <p
              className={
                (sortedDataForTable[propsOfRenderer.columnIndex][3] as number) <
                0
                  ? 'textForHover negativeInResultsList robotoNumbers'
                  : 'textForHover robotoNumbers'
              }
              onClick={() =>
                setHoverElement(
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}PrincipalAmount`,
                )
              }
              id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}PrincipalAmountText`}
              style={resultsFontSize(
                stringAndRoundBigNumbers(
                  sortedDataForTable[propsOfRenderer.columnIndex][3] as number,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                  findDisputedAmount(props.currentSnapshot),
                ),
              )}
            >
              {stringAndRoundBigNumbers(
                sortedDataForTable[propsOfRenderer.columnIndex][3] as number,
                user.settings,
                resultsSettings.settings.roundedResults,
                findDisputedAmount(props.currentSnapshot),
              )}
            </p>
          </ResultsTooltip>
        </div>
        {showInterest(dataForTable) ? (
          <div className="row row4">
            <ResultsTooltip
              open={
                hoverElement ===
                `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}InterestAwarded`
                  ? true
                  : false
              }
              title={
                <InterestAwarded
                  id={propsOfRenderer.columnIndex}
                  sortedDataForTable={sortedDataForTable}
                  currentSnapshot={props.currentSnapshot}
                  resultsViewParty={props.resultsViewParty}
                />
              }
            >
              <p
                className={
                  (sortedDataForTable[
                    propsOfRenderer.columnIndex
                  ][4] as number) < 0
                    ? 'textForHover negativeInResultsList robotoNumbers'
                    : 'textForHover robotoNumbers'
                }
                onClick={() =>
                  setHoverElement(
                    `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}InterestAwarded`,
                  )
                }
                id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}InterestAwardedText`}
                style={resultsFontSize(
                  stringAndRoundBigNumbers(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][4] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                  ),
                )}
              >
                {stringAndRoundBigNumbers(
                  sortedDataForTable[propsOfRenderer.columnIndex][4] as number,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                  findDisputedAmount(props.currentSnapshot),
                )}
              </p>
            </ResultsTooltip>
          </div>
        ) : null}
        {showLegalFees(dataForTable) ? (
          <>
            <div className="row row5">
              <p
                className={
                  (sortedDataForTable[
                    propsOfRenderer.columnIndex
                  ][5] as number) < 0
                    ? 'negativeInResultsList robotoNumbers'
                    : 'robotoNumbers'
                }
                id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}clientLegalCosts`}
                style={resultsFontSize(
                  stringAndRoundBigNumbers(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][5] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                  ),
                )}
              >
                {stringAndRoundBigNumbers(
                  sortedDataForTable[propsOfRenderer.columnIndex][5] as number,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                  findDisputedAmount(props.currentSnapshot),
                )}
              </p>
            </div>
            <div className="row row6">
              <ResultsTooltip
                open={
                  hoverElement ===
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsBy`
                    ? true
                    : false
                }
                title={
                  <LegalCostsBy
                    id={propsOfRenderer.columnIndex}
                    sortedDataForTable={sortedDataForTable}
                    currentSnapshot={props.currentSnapshot}
                  />
                }
              >
                <p
                  className="textForHover robotoNumbers"
                  onClick={() =>
                    setHoverElement(
                      `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsBy`,
                    )
                  }
                  id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsByText`}
                  style={resultsFontSize(
                    stringAndRoundBigNumbers(
                      sortedDataForTable[
                        propsOfRenderer.columnIndex
                      ][6] as number,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    ),
                  )}
                >
                  {stringAndRoundBigNumbers(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][6] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                  )}
                </p>
              </ResultsTooltip>
            </div>
            <div className="row row7">
              <ResultsTooltip
                open={
                  hoverElement ===
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsTo`
                    ? true
                    : false
                }
                title={
                  <LegalCostsTo
                    id={propsOfRenderer.columnIndex}
                    sortedDataForTable={sortedDataForTable}
                    currentSnapshot={props.currentSnapshot}
                  />
                }
              >
                <p
                  className={
                    (sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][7] as number) < 0
                      ? 'textForHover negativeInResultsList robotoNumbers'
                      : 'textForHover robotoNumbers'
                  }
                  onClick={() =>
                    setHoverElement(
                      `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsTo`,
                    )
                  }
                  id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsToText`}
                  style={resultsFontSize(
                    stringAndRoundBigNumbers(
                      sortedDataForTable[
                        propsOfRenderer.columnIndex
                      ][7] as number,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    ),
                  )}
                >
                  {stringAndRoundBigNumbers(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][7] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                  )}
                </p>
              </ResultsTooltip>
            </div>
            {showInsurance(dataForTable) ||
            props.currentSnapshot.hasInsurance ? (
              <div className="row row8">
                <ResultsTooltip
                  open={
                    hoverElement ===
                    `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsByInsurance`
                      ? true
                      : false
                  }
                  title={
                    <LegalCostsByInsurance
                      id={propsOfRenderer.columnIndex}
                      sortedDataForTable={sortedDataForTable}
                      currentSnapshot={props.currentSnapshot}
                    />
                  }
                >
                  <p
                    className="textForHover robotoNumbers"
                    onClick={() =>
                      setHoverElement(
                        `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsByInsurance`,
                      )
                    }
                    id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}LegalCostsByInsuranceText`}
                    style={resultsFontSize(
                      stringAndRoundBigNumbers(
                        sortedDataForTable[
                          propsOfRenderer.columnIndex
                        ][8] as number,
                        user.settings,
                        resultsSettings.settings.roundedResults,
                        findDisputedAmount(props.currentSnapshot),
                      ),
                    )}
                  >
                    {stringAndRoundBigNumbers(
                      sortedDataForTable[
                        propsOfRenderer.columnIndex
                      ][8] as number,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    )}
                  </p>
                </ResultsTooltip>
              </div>
            ) : null}
          </>
        ) : null}
        {showOutOfCourt(dataForTable) ? (
          <div className="row row9">
            <ResultsTooltip
              open={
                hoverElement ===
                `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}ExternalConsequence`
                  ? true
                  : false
              }
              title={
                <ExternalConsequence
                  id={propsOfRenderer.columnIndex}
                  sortedDataForTable={sortedDataForTable}
                  currentSnapshot={props.currentSnapshot}
                />
              }
            >
              <p
                className={
                  (sortedDataForTable[
                    propsOfRenderer.columnIndex
                  ][9] as number) < 0
                    ? 'textForHover negativeInResultsList robotoNumbers'
                    : 'textForHover robotoNumbers'
                }
                onClick={() =>
                  setHoverElement(
                    `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}ExternalConsequence`,
                  )
                }
                id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}ExternalConsequenceText`}
                style={resultsFontSize(
                  stringAndRoundBigNumbers(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][9] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                  ),
                )}
              >
                {stringAndRoundBigNumbers(
                  sortedDataForTable[propsOfRenderer.columnIndex][9] as number,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                  findDisputedAmount(props.currentSnapshot),
                )}
              </p>
            </ResultsTooltip>
          </div>
        ) : null}
        {snapshotHasEnforcementRisk(props.currentSnapshot) &&
        showEnforcement(dataForTable) ? (
          <div className="row row10">
            <ResultsTooltip
              open={
                hoverElement ===
                `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}UnsuccessfulEnforcement`
                  ? sortedDataForTable[propsOfRenderer.columnIndex][1] === 0 ||
                    sortedDataForTable[propsOfRenderer.columnIndex][1] === 1
                    ? false
                    : true
                  : false
              }
              title={<UnsuccessfulEnforcement />}
            >
              <p
                className={
                  sortedDataForTable[propsOfRenderer.columnIndex][1] === 0 ||
                  sortedDataForTable[propsOfRenderer.columnIndex][1] === 1
                    ? 'textForHover robotoNumbers'
                    : 'textForHover negativeInResultsList robotoNumbers'
                }
                onClick={() =>
                  setHoverElement(
                    `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}UnsuccessfulEnforcement`,
                  )
                }
                id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}UnsuccessfulEnforcementText`}
                style={resultsFontSize(
                  sortedDataForTable[propsOfRenderer.columnIndex][1] === 0 ||
                    sortedDataForTable[propsOfRenderer.columnIndex][1] === 1
                    ? '-'
                    : '-' +
                        stringAndRoundBigNumbers(
                          sortedDataForTable[
                            propsOfRenderer.columnIndex
                          ][10] as number,
                          user.settings,
                          resultsSettings.settings.roundedResults,
                          findDisputedAmount(props.currentSnapshot),
                        ),
                )}
              >
                {sortedDataForTable[propsOfRenderer.columnIndex][1] === 0 ||
                sortedDataForTable[propsOfRenderer.columnIndex][1] === 1
                  ? '-'
                  : '-' +
                    stringAndRoundBigNumbers(
                      sortedDataForTable[
                        propsOfRenderer.columnIndex
                      ][10] as number,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    )}
              </p>
            </ResultsTooltip>
          </div>
        ) : null}
        <div className="line"></div>
        <div className="row row11">
          <p
            id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}financialOutcomeText`}
            style={resultsFontSize(
              stringAndRoundBigNumbers(
                sortedDataForTable[propsOfRenderer.columnIndex][11] as number,
                user.settings,
                resultsSettings.settings.roundedResults,
                findDisputedAmount(props.currentSnapshot),
              ) +
                ' ' +
                props.currentSnapshot.currency,
            )}
            className="robotoNumbersBold"
          >
            {stringAndRoundBigNumbers(
              sortedDataForTable[propsOfRenderer.columnIndex][11] as number,
              user.settings,
              resultsSettings.settings.roundedResults,
              findDisputedAmount(props.currentSnapshot),
            ) +
              ' ' +
              props.currentSnapshot.currency}
          </p>
        </div>
        <div className="row row12">
          <ResultsTooltip
            open={
              hoverElement ===
              `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}WeightedProbability`
                ? true
                : false
            }
            title={<WeightedProbability />}
          >
            <p
              className="textForHover robotoNumbers"
              onClick={() =>
                setHoverElement(
                  `${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}WeightedProbability`,
                )
              }
              id={`${propsOfRenderer.columnIndex}-${propsOfRenderer.rowIndex}WeightedProbabilityText`}
              style={resultsFontSize(
                Math.abs(
                  sortedDataForTable[propsOfRenderer.columnIndex][12] as number,
                ) < 0.01 &&
                  Math.abs(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][12] as number,
                  ) !== 0
                  ? `~0 ${props.currentSnapshot.currency}`
                  : stringAndRoundBigNumbers(
                      sortedDataForTable[
                        propsOfRenderer.columnIndex
                      ][12] as number,
                      user.settings,
                      resultsSettings.settings.roundedResults,
                      findDisputedAmount(props.currentSnapshot),
                    ) +
                      ' ' +
                      props.currentSnapshot.currency,
              )}
            >
              {Math.abs(
                sortedDataForTable[propsOfRenderer.columnIndex][12] as number,
              ) < 0.01 &&
              Math.abs(
                sortedDataForTable[propsOfRenderer.columnIndex][12] as number,
              ) !== 0
                ? `~0 ${props.currentSnapshot.currency}`
                : stringAndRoundBigNumbers(
                    sortedDataForTable[
                      propsOfRenderer.columnIndex
                    ][12] as number,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    findDisputedAmount(props.currentSnapshot),
                  ) +
                  ' ' +
                  props.currentSnapshot.currency}
            </p>
          </ResultsTooltip>
        </div>
        <div className="scrollBar"></div>
      </div>
    )
  }

  return (
    <>
      <div
        className="horizontalResultsContainer"
        id="horizontalResultsContainer"
      >
        <div className="headerBackground"></div>
        <div className="headersContainer">
          <div className="headersRows">
            <div
              className="title title1"
              onMouseEnter={() => {
                secondaryHover.current = 'outcomeID'
                setTimeout(() => {
                  if (secondaryHover.current === 'outcomeID') {
                    setHoverElement('outcomeID')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                onClick={() => {
                  sortByFunction(0, sortedDataForTable)
                  setSortedOption(0)
                }}
                id="resultsTableTitle-outcomeId"
                data-textattribute="title-54"
                className="resultsTableTitleInRow"
              >
                {getText('title-54', user.settings)}
              </p>

              {hoverElement === 'outcomeIDERROR INTENTIONALLY' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '80px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-33', user.settings)}
                    textAttribute="hover-33"
                    id="outcomeID"
                  />
                </div>
              ) : null}
              {sorting[0] === 'asc' ? (
                <img
                  src={arrowImg}
                  className="sortingArrow"
                  alt="arrowUp"
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    sortByFunction(0, sortedDataForTable)
                    setSortedOption(0)
                  }}
                />
              ) : null}
              {sorting[0] === 'des' ? (
                <img
                  src={arrowImg}
                  alt="arrowDown"
                  className="sortingArrow"
                  onClick={() => {
                    sortByFunction(0, sortedDataForTable)
                    setSortedOption(0)
                  }}
                />
              ) : null}
            </div>
            <div
              className="row row1"
              onMouseEnter={() => {
                secondaryHover.current = 'description'
                setTimeout(() => {
                  if (secondaryHover.current === 'description') {
                    setHoverElement('description')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                onClick={() => {
                  sortByFunction(1, sortedDataForTable)
                  setSortedOption(1)
                }}
                id="resultsTableTitle-description"
                data-textattribute="title-55"
                className="resultsTableTitleInRow"
              >
                {getText('title-55', user.settings)}
              </p>
              {hoverElement === 'description' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '-240px' }}
                >
                  <HoverComponent
                    hoverText={
                      <>
                        <DescriptionTitleHover
                          firstTrialImg={firstTrialImg}
                          secondTrialImg={secondTrialImg}
                          bankruptcyFirstTrialImg={bankruptcyFirstTrialImg}
                          bankruptcySecondTrialImg={bankruptcySecondTrialImg}
                        />
                      </>
                    }
                    textAttribute=""
                    id="description"
                    style={{ width: '520px' }}
                  />
                </div>
              ) : null}
              {sorting[1] === 'asc' ? (
                <img
                  src={arrowImg}
                  className="sortingArrow"
                  alt="arrowUp"
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    sortByFunction(1, sortedDataForTable)
                    setSortedOption(1)
                  }}
                />
              ) : null}
              {sorting[1] === 'des' ? (
                <img
                  src={arrowImg}
                  alt="arrowDown"
                  className="sortingArrow"
                  onClick={() => {
                    sortByFunction(1, sortedDataForTable)
                    setSortedOption(1)
                  }}
                />
              ) : null}
            </div>
            <div
              className="row row2"
              onMouseEnter={() => {
                secondaryHover.current = 'probability'
                setTimeout(() => {
                  if (secondaryHover.current === 'probability') {
                    setHoverElement('probability')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                onClick={() => {
                  sortByFunction(2, sortedDataForTable)
                  setSortedOption(2)
                }}
                id="resultsTableTitle-probability"
                data-textattribute="title-56"
                className="resultsTableTitleInRow"
              >
                {getText('title-56', user.settings)}
              </p>
              {hoverElement === 'probability' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '20px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-38', user.settings)}
                    textAttribute="hover-38"
                    id="probability"
                  />
                </div>
              ) : null}
              {sorting[2] === 'asc' ? (
                <img
                  src={arrowImg}
                  className="sortingArrow"
                  alt="arrowUp"
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    sortByFunction(2, sortedDataForTable)
                    setSortedOption(2)
                  }}
                />
              ) : null}
              {sorting[2] === 'des' ? (
                <img
                  src={arrowImg}
                  alt="arrowDown"
                  className="sortingArrow"
                  onClick={() => {
                    sortByFunction(2, sortedDataForTable)
                    setSortedOption(2)
                  }}
                />
              ) : null}
            </div>
            <div className="title title2">
              <p
                id="resultsTableTitle-monetaryOutcome"
                data-textattribute="title-57"
                className="resultsTableTitleInRow"
              >
                {getText('title-57', user.settings)}
              </p>
            </div>
            <div
              className="row row3"
              onMouseEnter={() => {
                secondaryHover.current = 'principalAmount'
                setTimeout(() => {
                  if (secondaryHover.current === 'principalAmount') {
                    setHoverElement('principalAmount')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                onClick={() => {
                  sortByFunction(3, sortedDataForTable)
                  setSortedOption(3)
                }}
                id="resultsTableTitle-amountAwarded"
                data-textattribute="title-58"
                className="resultsTableTitleInRow"
              >
                {getText('title-58', user.settings)}
              </p>
              {hoverElement === 'principalAmount' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '24px' }}
                >
                  <HoverComponent
                    hoverText={
                      props.currentSnapshot.court ===
                      TypeOfInstance.Public_Court
                        ? getText('hover-39', user.settings, partiesFormat)
                        : props.currentSnapshot.court ===
                          TypeOfInstance.Arbitration
                        ? getText('hover-40', user.settings, partiesFormat)
                        : getText('hover-41', user.settings, partiesFormat)
                    }
                    textAttribute={
                      props.currentSnapshot.court ===
                      TypeOfInstance.Public_Court
                        ? 'hover-39'
                        : props.currentSnapshot.court ===
                          TypeOfInstance.Arbitration
                        ? 'hover-40'
                        : 'hover-41'
                    }
                    id="principalAmount"
                  />
                </div>
              ) : null}
              {sorting[3] === 'asc' ? (
                <img
                  src={arrowImg}
                  className="sortingArrow"
                  alt="arrowUp"
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    sortByFunction(3, sortedDataForTable)
                    setSortedOption(3)
                  }}
                />
              ) : null}
              {sorting[3] === 'des' ? (
                <img
                  src={arrowImg}
                  alt="arrowDown"
                  className="sortingArrow"
                  onClick={() => {
                    sortByFunction(3, sortedDataForTable)
                    setSortedOption(3)
                  }}
                />
              ) : null}
            </div>
            {showInterest(dataForTable) ? (
              <div
                className="row row4"
                onMouseEnter={() => {
                  secondaryHover.current = 'interestAwarded'
                  setTimeout(() => {
                    if (secondaryHover.current === 'interestAwarded') {
                      setHoverElement('interestAwarded')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <p
                  onClick={() => {
                    sortByFunction(4, sortedDataForTable)
                    setSortedOption(4)
                  }}
                  id="resultsTableTitle-interest"
                  data-textattribute="title-59"
                  className="resultsTableTitleInRow"
                >
                  {getText('title-59', user.settings)}
                </p>
                {hoverElement === 'interestAwarded' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '10px', right: '24px' }}
                  >
                    <HoverComponent
                      hoverText={
                        props.currentSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? getText('hover-42', user.settings, partiesFormat)
                          : props.currentSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? getText('hover-43', user.settings, partiesFormat)
                          : getText('hover-44', user.settings, partiesFormat)
                      }
                      textAttribute={
                        props.currentSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? 'hover-42'
                          : props.currentSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? 'hover-43'
                          : 'hover-44'
                      }
                      id="interestAwarded"
                    />
                  </div>
                ) : null}
                {sorting[4] === 'asc' ? (
                  <img
                    src={arrowImg}
                    className="sortingArrow"
                    alt="arrowUp"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => {
                      sortByFunction(4, sortedDataForTable)
                      setSortedOption(4)
                    }}
                  />
                ) : null}
                {sorting[4] === 'des' ? (
                  <img
                    src={arrowImg}
                    alt="arrowDown"
                    className="sortingArrow"
                    onClick={() => {
                      sortByFunction(4, sortedDataForTable)
                      setSortedOption(4)
                    }}
                  />
                ) : null}
              </div>
            ) : null}
            {showLegalFees(dataForTable) ? (
              <>
                <div
                  className="row row5"
                  onMouseEnter={() => {
                    secondaryHover.current = 'legalCosts'
                    setTimeout(() => {
                      if (secondaryHover.current === 'legalCosts') {
                        setHoverElement('legalCosts')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <p
                    onClick={() => {
                      sortByFunction(5, sortedDataForTable)
                      setSortedOption(5)
                    }}
                    id="resultsTableTitle-clientLegalCost"
                    data-textattribute="title-60"
                    className="resultsTableTitleInRow"
                    data-openreplay-obscured
                  >
                    {getSplitText(
                      getText('title-60', user.settings, partiesFormat),
                      '’s',
                      39,
                      25,
                    )}
                  </p>
                  {hoverElement === 'legalCosts' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '10px', right: '22px' }}
                    >
                      <HoverComponent
                        hoverText={getText(
                          'hover-45',
                          user.settings,
                          partiesFormat,
                        )}
                        textAttribute="hover-45"
                        id="legalCosts"
                      />
                    </div>
                  ) : null}
                  {sorting[5] === 'asc' ? (
                    <img
                      src={arrowImg}
                      className="sortingArrow"
                      alt="arrowUp"
                      style={{ transform: 'rotate(180deg)' }}
                      onClick={() => {
                        sortByFunction(5, sortedDataForTable)
                        setSortedOption(5)
                      }}
                    />
                  ) : null}
                  {sorting[5] === 'des' ? (
                    <img
                      src={arrowImg}
                      alt="arrowDown"
                      className="sortingArrow"
                      onClick={() => {
                        sortByFunction(5, sortedDataForTable)
                        setSortedOption(5)
                      }}
                    />
                  ) : null}
                </div>
                <div
                  className="row row6"
                  onMouseEnter={() => {
                    secondaryHover.current = 'legalCostsCovered'
                    setTimeout(() => {
                      if (secondaryHover.current === 'legalCostsCovered') {
                        setHoverElement('legalCostsCovered')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <p
                    onClick={() => {
                      sortByFunction(6, sortedDataForTable)
                      setSortedOption(6)
                    }}
                    id="resultsTableTitle-legalCostsCovered"
                    data-textattribute="title-61, title-61b, title-61c"
                    className="resultsTableTitleInRow"
                    data-openreplay-obscured
                  >
                    {getText('title-61', user.settings)}{' '}
                    <span>{getText('title-61b', user.settings)}</span>{' '}
                    {getText('title-61c', user.settings, partiesFormat).length >
                    26
                      ? getText('title-61c', user.settings, partiesFormat)
                          .substring(0, 26)
                          .trim() + '...'
                      : getText('title-61c', user.settings, partiesFormat)}{' '}
                  </p>
                  {hoverElement === 'legalCostsCovered' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '10px', right: '-120px' }}
                    >
                      <HoverComponent
                        hoverText={
                          props.currentSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? getText('hover-46', user.settings, partiesFormat)
                            : props.currentSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? getText('hover-47', user.settings, partiesFormat)
                            : getText('hover-48', user.settings, partiesFormat)
                        }
                        textAttribute={
                          props.currentSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? 'hover-46'
                            : props.currentSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? 'hover-47'
                            : 'hover-48'
                        }
                        id="legalCostsCovered"
                      />
                    </div>
                  ) : null}
                  {sorting[6] === 'asc' ? (
                    <img
                      src={arrowImg}
                      className="sortingArrow"
                      alt="arrowUp"
                      style={{ transform: 'rotate(180deg)' }}
                      onClick={() => {
                        sortByFunction(6, sortedDataForTable)
                        setSortedOption(6)
                      }}
                    />
                  ) : null}
                  {sorting[6] === 'des' ? (
                    <img
                      src={arrowImg}
                      alt="arrowDown"
                      className="sortingArrow"
                      onClick={() => {
                        sortByFunction(6, sortedDataForTable)
                        setSortedOption(6)
                      }}
                    />
                  ) : null}
                </div>
                <div
                  className="row row7"
                  onMouseEnter={() => {
                    secondaryHover.current = 'legalCostsPaid'
                    setTimeout(() => {
                      if (secondaryHover.current === 'legalCostsPaid') {
                        setHoverElement('legalCostsPaid')
                      }
                    }, 1000)
                  }}
                  onMouseLeave={() => {
                    secondaryHover.current = 'close'
                    setHoverElement('')
                  }}
                >
                  <p
                    onClick={() => {
                      sortByFunction(7, sortedDataForTable)
                      setSortedOption(7)
                    }}
                    id="resultsTableTitle-legalCostsPaid"
                    data-textattribute="title-62, title-62b, title-62c"
                    className="resultsTableTitleInRow"
                    data-openreplay-obscured
                  >
                    {getText('title-62', user.settings)}{' '}
                    <span>{getText('title-62b', user.settings)}</span>{' '}
                    {getText('title-62c', user.settings, partiesFormat).length >
                    26
                      ? getText('title-62c', user.settings, partiesFormat)
                          .substring(0, 26)
                          .trim() + '...'
                      : getText('title-62c', user.settings, partiesFormat)}
                  </p>
                  {hoverElement === 'legalCostsPaid' ? (
                    <div
                      className="hoverComponentContainer"
                      style={{ top: '10px', right: '-128px' }}
                    >
                      <HoverComponent
                        hoverText={
                          props.currentSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? getText('hover-49', user.settings, partiesFormat)
                            : props.currentSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? getText('hover-50', user.settings, partiesFormat)
                            : getText('hover-51', user.settings, partiesFormat)
                        }
                        textAttribute={
                          props.currentSnapshot.court ===
                          TypeOfInstance.Public_Court
                            ? 'hover-49'
                            : props.currentSnapshot.court ===
                              TypeOfInstance.Arbitration
                            ? 'hover-50'
                            : 'hover-51'
                        }
                        id="legalCostsPaid"
                      />
                    </div>
                  ) : null}
                  {sorting[7] === 'asc' ? (
                    <img
                      src={arrowImg}
                      className="sortingArrow"
                      alt="arrowUp"
                      style={{ transform: 'rotate(180deg)' }}
                      onClick={() => {
                        sortByFunction(7, sortedDataForTable)
                        setSortedOption(7)
                      }}
                    />
                  ) : null}
                  {sorting[7] === 'des' ? (
                    <img
                      src={arrowImg}
                      alt="arrowDown"
                      className="sortingArrow"
                      onClick={() => {
                        sortByFunction(7, sortedDataForTable)
                        setSortedOption(7)
                      }}
                    />
                  ) : null}
                </div>
                {showInsurance(dataForTable) ||
                props.currentSnapshot.hasInsurance ? (
                  <div
                    className="row row8"
                    onMouseEnter={() => {
                      secondaryHover.current = 'legalCostsInsurance'
                      setTimeout(() => {
                        if (secondaryHover.current === 'legalCostsInsurance') {
                          setHoverElement('legalCostsInsurance')
                        }
                      }, 1000)
                    }}
                    onMouseLeave={() => {
                      secondaryHover.current = 'close'
                      setHoverElement('')
                    }}
                  >
                    <p
                      onClick={() => {
                        sortByFunction(8, sortedDataForTable)
                        setSortedOption(8)
                      }}
                      id="resultsTableTitle-legalCostsInsurance"
                      data-textattribute="title-63"
                      className="resultsTableTitleInRow"
                    >
                      {getText('title-63', user.settings)}
                    </p>
                    {hoverElement === 'legalCostsInsurance' ? (
                      <div
                        className="hoverComponentContainer"
                        style={{ top: '10px', right: '-100px' }}
                      >
                        <HoverComponent
                          hoverText={getText(
                            'hover-52',
                            user.settings,
                            partiesFormat,
                          )}
                          textAttribute="hover-52"
                          id="legalCostsInsurance"
                        />{' '}
                      </div>
                    ) : null}
                    {sorting[8] === 'asc' ? (
                      <img
                        src={arrowImg}
                        className="sortingArrow"
                        alt="arrowUp"
                        style={{ transform: 'rotate(180deg)' }}
                        onClick={() => {
                          sortByFunction(8, sortedDataForTable)
                          setSortedOption(8)
                        }}
                      />
                    ) : null}
                    {sorting[8] === 'des' ? (
                      <img
                        src={arrowImg}
                        alt="arrowDown"
                        className="sortingArrow"
                        onClick={() => {
                          sortByFunction(8, sortedDataForTable)
                          setSortedOption(8)
                        }}
                      />
                    ) : null}
                  </div>
                ) : null}
              </>
            ) : null}
            {showOutOfCourt(dataForTable) ? (
              <div
                className="row row9"
                onMouseEnter={() => {
                  secondaryHover.current = 'extrenalConsequence'
                  setTimeout(() => {
                    if (secondaryHover.current === 'extrenalConsequence') {
                      setHoverElement('extrenalConsequence')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <p
                  onClick={() => {
                    sortByFunction(9, sortedDataForTable)
                    setSortedOption(9)
                  }}
                  id="resultsTableTitle-outOfCourt"
                  data-textattribute="title-64"
                  className="resultsTableTitleInRow"
                >
                  {getText('title-64', user.settings)}
                </p>
                {hoverElement === 'extrenalConsequence' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '10px', right: '-50px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-53', user.settings)}
                      textAttribute="hover-53"
                      id="extrenalConsequence"
                    />{' '}
                  </div>
                ) : null}
                {sorting[9] === 'asc' ? (
                  <img
                    src={arrowImg}
                    className="sortingArrow"
                    alt="arrowUp"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => {
                      sortByFunction(9, sortedDataForTable)
                      setSortedOption(9)
                    }}
                  />
                ) : null}
                {sorting[9] === 'des' ? (
                  <img
                    src={arrowImg}
                    alt="arrowDown"
                    className="sortingArrow"
                    onClick={() => {
                      sortByFunction(9, sortedDataForTable)
                      setSortedOption(9)
                    }}
                  />
                ) : null}
              </div>
            ) : null}
            {snapshotHasEnforcementRisk(props.currentSnapshot) &&
            showEnforcement(dataForTable) ? (
              <div
                className="row row10"
                onMouseEnter={() => {
                  secondaryHover.current = 'enforcement'
                  setTimeout(() => {
                    if (secondaryHover.current === 'enforcement') {
                      setHoverElement('enforcement')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                <p
                  onClick={() => {
                    sortByFunction(10, sortedDataForTable)
                    setSortedOption(10)
                  }}
                  id="resultsTableTitle-enforcement"
                  data-textattribute="title-65"
                  className="resultsTableTitleInRow"
                >
                  {getText('title-65', user.settings)}
                </p>
                {hoverElement === 'enforcement' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '10px', right: '-50px' }}
                  >
                    <HoverComponent
                      hoverText={
                        props.currentSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? getText('hover-54', user.settings)
                          : props.currentSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? getText('hover-55', user.settings)
                          : getText('hover-56', user.settings)
                      }
                      textAttribute={
                        props.currentSnapshot.court ===
                        TypeOfInstance.Public_Court
                          ? 'hover-54'
                          : props.currentSnapshot.court ===
                            TypeOfInstance.Arbitration
                          ? 'hover-55'
                          : 'hover-56'
                      }
                      id="enforcement"
                    />
                  </div>
                ) : null}
                {sorting[10] === 'asc' ? (
                  <img
                    src={arrowImg}
                    className="sortingArrow"
                    alt="arrowUp"
                    style={{ transform: 'rotate(180deg)' }}
                    onClick={() => {
                      sortByFunction(10, sortedDataForTable)
                      setSortedOption(10)
                    }}
                  />
                ) : null}
                {sorting[10] === 'des' ? (
                  <img
                    src={arrowImg}
                    alt="arrowDown"
                    className="sortingArrow"
                    onClick={() => {
                      sortByFunction(10, sortedDataForTable)
                      setSortedOption(10)
                    }}
                  />
                ) : null}
              </div>
            ) : null}
            <div className="line"></div>
            <div
              className="row row11"
              onMouseEnter={() => {
                secondaryHover.current = 'financialOutcome'
                setTimeout(() => {
                  if (secondaryHover.current === 'financialOutcome') {
                    setHoverElement('financialOutcome')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                onClick={() => {
                  sortByFunction(11, sortedDataForTable)
                  setSortedOption(11)
                }}
                id="resultsTableTitle-financialOutcome"
                data-textattribute="title-66"
                className="resultsTableTitleInRow"
              >
                {getText('title-66', user.settings)}
              </p>
              {hoverElement === 'financialOutcome' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '-20px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-57', user.settings)}
                    textAttribute="hover-57"
                    id="financialOutcome"
                  />{' '}
                </div>
              ) : null}
              {sorting[11] === 'asc' ? (
                <img
                  src={arrowImg}
                  className="sortingArrow"
                  alt="arrowUp"
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    sortByFunction(11, sortedDataForTable)
                    setSortedOption(11)
                  }}
                />
              ) : null}
              {sorting[11] === 'des' ? (
                <img
                  src={arrowImg}
                  alt="arrowDown"
                  className="sortingArrow"
                  onClick={() => {
                    sortByFunction(11, sortedDataForTable)
                    setSortedOption(11)
                  }}
                />
              ) : null}
            </div>
            <div
              className="row row12"
              onMouseEnter={() => {
                secondaryHover.current = 'weightedProbability'
                setTimeout(() => {
                  if (secondaryHover.current === 'weightedProbability') {
                    setHoverElement('weightedProbability')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              <p
                onClick={() => {
                  sortByFunction(12, sortedDataForTable)
                  setSortedOption(12)
                }}
                id="resultsTableTitle-weightedProbability"
                data-textattribute="title-67"
                className="resultsTableTitleInRow"
              >
                {getText('title-67', user.settings)}
              </p>
              {hoverElement === 'weightedProbability' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', right: '-40px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-58', user.settings)}
                    textAttribute="hover-58"
                    id="weightedProbability"
                  />
                </div>
              ) : null}
              {sorting[12] === 'asc' ? (
                <img
                  src={arrowImg}
                  className="sortingArrow"
                  alt="arrowUp"
                  style={{ transform: 'rotate(180deg)' }}
                  onClick={() => {
                    sortByFunction(12, sortedDataForTable)
                    setSortedOption(12)
                  }}
                />
              ) : null}
              {sorting[12] === 'des' ? (
                <img
                  src={arrowImg}
                  alt="arrowDown"
                  className="sortingArrow"
                  onClick={() => {
                    sortByFunction(12, sortedDataForTable)
                    setSortedOption(12)
                  }}
                />
              ) : null}
            </div>
            <div className="scrollBar"></div>
          </div>
        </div>
        <div
          className="resultsColumnsContainer"
          style={
            dataForTable.length < 4
              ? { overflowX: 'hidden', width: '750px' }
              : undefined
          }
        >
          <Grid
            columnCount={sortedDataForTable.length}
            columnWidth={200}
            height={calculateHeight(dataForTable)}
            rowCount={1}
            width={gridWidth}
            rowHeight={calculateHeight(dataForTable)}
            className="horizontalResults"
          >
            {cellRenderer}
          </Grid>
        </div>
      </div>
      {(isDevEnv() || isStagingEnv() || isTestEnv()) && debugging ? (
        <div className="probabilitySum">
          Probability of Outcomes sum = {Math.round(createPercentageSum())} %
        </div>
      ) : null}
    </>
  )
}
