import React from 'react'
import { useRecoilValue } from 'recoil'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'

type Props = {
  id: string
}

export default function WarningSubTextHandlingErrors(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <p
      className="warningSubText"
      id={`popUpWarningSubText-${props.id}`}
      data-textattribute="description-20a, description-20b, description-20c"
    >
      {getText('description-20a', user.settings)}{' '}
      <a href="mailto:support@eperoto.com" className="link" id={'popUp11'}>
        {getText('description-20b', user.settings)}
      </a>{' '}
      {getText('description-20c', user.settings)}
    </p>
  )
}
