import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'

import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'

import binImg from '../../../../../../../resources/images/199-deleteNode.svg'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'

type Props = {
  setLineHover: (param: boolean) => void
  deleteLine: (nodeId: NodeId, eventId: EventId) => void
  nodeId: NodeId
  eventId: EventId
  zoomLevel: number
  treeIndex: number
}

export default function DeleteLineButton(props: Props) {
  const user = useRecoilValue(userState)
  const [hoverOn, setHoverOn] = useState(false)

  return (
    <>
      <div
        className="deleteLineButtonContainer"
        id={`deleteLineButtonContainer-${props.treeIndex}_${props.nodeId}!${props.eventId}`}
        onMouseEnter={() => {
          props.setLineHover(true)
          setHoverOn(true)
        }}
        onMouseLeave={() => {
          setHoverOn(false)
          props.setLineHover(false)
        }}
        onClick={() => props.deleteLine(props.nodeId, props.eventId)}
        style={{
          transform: `scale(${props.zoomLevel})`,
        }}
      >
        <div
          className={`deleteLineButtonImgContainer ${hoverOn ? 'hover' : ''}`}
          id={`deleteLineButtonImgContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <img
            src={binImg}
            alt="binImg"
            className="deleteLineButtonImg"
            id={`deleteLineButtonImg-${props.treeIndex}_${props.nodeId}`}
          />
        </div>
        <div
          className="deleteLineButtonTextContainer"
          id={`deleteLineButtonTextContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <p
            className={`deleteLineButtonText ${hoverOn ? 'hover' : ''}`}
            id={`deleteLineButtonText-${props.treeIndex}_${props.nodeId}`}
            data-textattribute="button-119"
          >
            {getText('button-119', user.settings)}
          </p>
        </div>
      </div>
    </>
  )
}
