import { DateTime } from 'luxon'
import { DateFormatOption } from '../models/enums'
import { UserSettings } from '../models/userSettings'

const getConvertFormatFromOption = (option: DateFormatOption) => {
  switch (option) {
    case DateFormatOption.YYYY_MM_DD:
      return "yyyy'/'MM'/'dd' 'HH':'mm"
    case DateFormatOption.DD_MM_YYYY:
      return "dd'/'MM'/'yyyy' 'HH':'mm"
    case DateFormatOption.MM_DD_YYYY:
      return "MM'/'dd'/'yyyy' 'HH':'mm"
  }
}

export const convertDateTimeToLocal = (
  date: string,
  settings: UserSettings,
  withoutHour?: boolean,
) => {
  let formattedDate = date
  if (formattedDate.includes(' ')) {
    formattedDate = date.replace(' ', 'T')
  }

  let localDate = DateTime.fromISO(formattedDate).toFormat(
    getConvertFormatFromOption(settings.formats.dateFormat),
  )

  if (withoutHour) {
    localDate = localDate.split(' ')[0]
  }

  return localDate
}

export const getInputDateFormat = (settings: UserSettings) => {
  switch (settings.formats.dateFormat) {
    case DateFormatOption.YYYY_MM_DD:
      return 'yyyy/MM/dd'
    case DateFormatOption.DD_MM_YYYY:
      return 'dd/MM/yyyy'
    case DateFormatOption.MM_DD_YYYY:
      return 'MM/dd/yyyy'
  }
}

export const getHelperText = (settings: UserSettings) => {
  switch (settings.formats.dateFormat) {
    case DateFormatOption.YYYY_MM_DD:
      return 'yyyy/mm/dd'
    case DateFormatOption.DD_MM_YYYY:
      return 'dd/mm/yyyy'
    case DateFormatOption.MM_DD_YYYY:
      return 'mm/dd/yyyy'
  }
}
