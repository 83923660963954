import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import Button from '../../Components/Buttons/Button/Button'
import { SaveMenuEnum } from '../../models/enums'
import { getText, saveMenuMessages } from '../../services/textFunctions'
import { userState } from '../../states/UserState'

type Props = {
  style?: { [key: string]: string }
  setSaveMenuOn: (param: undefined | string) => void
  continueWithoutSaving: () => void
  saveAndContinue: () => void
  id: string
  typeOfMenu: string
  loadingRequest?: boolean
  setShowHide?: (showHide: boolean) => void
}

export default function SavePopUp(props: Props) {
  const user = useRecoilValue(userState)

  const [message, setMessage] = useState('')
  //id='savePopUpButtonText'
  //data-textattribute='button-86'
  //CONTINUE
  const [buttonText, setButtonText] = useState(
    getText('button-86', user.settings),
  )

  function mouseDown(e: any) {
    if (!e.target.id.includes('savePopUp')) {
      props.setSaveMenuOn(undefined)
      if (props.setShowHide) {
        props.setShowHide(false)
      }
    }
  }
  useEffect(() => {
    let tempMessage = ''
    switch (props.typeOfMenu) {
      case SaveMenuEnum.report:
        tempMessage = saveMenuMessages(user.settings).closeReport
        break
      case SaveMenuEnum.academy:
        tempMessage = saveMenuMessages(user.settings).academy
        break
      case SaveMenuEnum.settings:
        tempMessage = saveMenuMessages(user.settings).settings
        break
      case SaveMenuEnum.mycases:
        tempMessage = saveMenuMessages(user.settings).mycases
        break
      case SaveMenuEnum.myscenarios:
        tempMessage = saveMenuMessages(user.settings).myscenarios
        break
      case SaveMenuEnum.mycasesSide:
        tempMessage = saveMenuMessages(user.settings).mycases
        break
      case SaveMenuEnum.logOut:
        tempMessage = saveMenuMessages(user.settings).logOut
        //id='savePopUpButtonText'
        //data-textattribute='button-87'
        //LOG OUT
        setButtonText(getText('button-87', user.settings))
        break
      default:
        tempMessage = saveMenuMessages(user.settings).anotherScenario
        break
    }
    setMessage(tempMessage)
    // eslint-disable-next-line
  }, [])
  return (
    <>
      <div className="saveMenuContainer" id={props.id} style={props.style}>
        {props.loadingRequest ? (
          <div className="circularProgressDiv" id={'savePopUp-popUp7'}>
            <div id={'savePopUp-popUp8'}>
              <CircularProgress size={40} />
            </div>
          </div>
        ) : (
          <>
            <div className="saveMenuMessage" id={'savePopUp-1'}>
              <p id={'savePopUp-2'}>{message}</p>
            </div>
            <div className="saveMenuButtons" id={'savePopUp-3'}>
              <Button
                id="savePopUpCancelButton-savePopUp-4"
                buttonText={getText('button-73', user.settings)}
                buttonTextAttribute="button-73"
                buttonType="text"
                type="button"
                onClick={() => {
                  props.setSaveMenuOn(undefined)
                  if (props.setShowHide) {
                    props.setShowHide(false)
                  }
                }}
                className="cancelButton"
                side="left"
                NoUpperCase={true}
                small={false}
              />
              <Button
                id="savePopUpWithoutSaveButton-savePopUp-5"
                buttonText={`${buttonText} ${getText(
                  'button-74',
                  user.settings,
                )}`}
                buttonTextAttribute="button-74"
                buttonType="text"
                type="button"
                onClick={props.continueWithoutSaving}
                className="continueButton"
                side="left"
                NoUpperCase={true}
                small={false}
              />
              <Button
                id="savePopUpSaveButton-savePopUp-6"
                buttonText={`${getText(
                  'button-75',
                  user.settings,
                )} ${buttonText}`}
                buttonTextAttribute="button-75"
                buttonType="contained"
                type="button"
                onClick={props.saveAndContinue}
                className="continueButton"
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </div>
          </>
        )}
      </div>
      <div
        className="transparent-Background"
        onClick={(e) => mouseDown(e)}
      ></div>
    </>
  )
}
