import { CircularProgress } from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Button from '../../../Components/Buttons/Button/Button'
import { SaveMenuEnum } from '../../../models/enums'
import { User } from '../../../models/user'
import logoutImg from '../../../resources/images/096-logout.svg'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../services/commonFunctions'
import { logout } from '../../../services/requests'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'
import { onlineState } from '../../../states/OnlineState'
import { pageDepthState } from '../../../states/PageDepthState'
import { reportMenuState } from '../../../states/ReportMenuState'
import { savedReportState } from '../../../states/SavedReportState'
import { savedState } from '../../../states/SavedState'
import { userState } from '../../../states/UserState'
import { getText } from '../../../services/textFunctions'
import { allowShortcutsState } from '../../../states/AllowShortcutsState'

type Props = {
  showHide: boolean
  saveMenuOn?: undefined | string
  setSaveMenuOn?: (param: undefined | string) => void
  setShowHide: (param: boolean) => void
}

export default function LogOutComponent(props: Props) {
  const [logOutMenu, setLogOutMenu] = useState(false)
  const [user, setUser] = useRecoilState(userState)
  const savedSnapshot = useRecoilValue(savedState)
  const savedReport = useRecoilValue(savedReportState)
  const pageDepth = useRecoilValue(pageDepthState)
  const logOutMenuContainerRef = useRef<HTMLDivElement>(null)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [loggingOut, setLoggingOut] = useState(false)
  const [online, setOnline] = useRecoilState(onlineState)
  const [reportMenu, setReportMenu] = useRecoilState(reportMenuState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    document.addEventListener('keydown', onKeyPress, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
      document.removeEventListener('keydown', onKeyPress, false)
    }
    // eslint-disable-next-line
  }, [logOutMenu, loggingOut, allowShortcuts])

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && !loggingOut && allowShortcuts) {
      closeLogOutMenu()
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    if (
      logOutMenuContainerRef &&
      logOutMenuContainerRef.current &&
      !logOutMenuContainerRef.current.contains(e.target as Node) &&
      !loggingOut
    ) {
      closeLogOutMenu()
    }
  }

  function openLogOutMenu() {
    setLogOutMenu(true)
  }
  function closeLogOutMenu() {
    setLoggingOut(false)

    props.setShowHide(false)
    setLogOutMenu(false)
  }
  function logOut() {
    if (
      pageDepth < 3 ||
      (pageDepth === 3 && savedSnapshot.saved) ||
      (pageDepth === 3.1 && savedReport.saved)
    ) {
      if (online.networkOn) {
        setLoggingOut(true)
        //Mixpanel 84
        logActivity(false, 'Logged out')
        logout().then((res) => {
          setHandlingErrors(
            findHandlingErrorState(res, handlingErrors, 'logout', true),
          )
          if (!('errorCode' in res)) {
            setUser(User.EmptyUser())
          }
          setLoggingOut(false)
        })
      } else {
        setOnline({ ...online, shaking: true })
      }
    } else if (props.setSaveMenuOn) {
      setLogOutMenu(false)
      setReportMenu({ ...reportMenu, downloadReportFixedHeaderOn: false })

      props.setSaveMenuOn(SaveMenuEnum.logOut)
    }
  }
  return (
    <div className="logOutContainer">
      {props.showHide ? (
        <p
          className="logoutText"
          onClick={openLogOutMenu}
          id="sideMenuLogOutText"
          data-textattribute="title-6"
        >
          {getText('title-6', user.settings)}
        </p>
      ) : null}
      <img
        src={logoutImg}
        alt="logoutImg"
        className="logout"
        onClick={openLogOutMenu}
      />
      {logOutMenu ? (
        <>
          <div className="logOutMenuContainer" ref={logOutMenuContainerRef}>
            {loggingOut ? (
              <>
                <div className="circularProgressDiv" id={'popUp7'}>
                  <div id={'popUp8'}>
                    <CircularProgress size={40} />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="logOutMenuTextContainer">
                  <p
                    className="logOutMenuText"
                    id="logOutMenuText"
                    data-textattribute="message-53"
                  >
                    {getText('message-53', user.settings)}
                  </p>
                </div>
                <div className="logOutMenuButtonsContainer">
                  <Button
                    buttonText={getText('button-76', user.settings)}
                    buttonTextAttribute="button-76"
                    buttonType="text"
                    type="button"
                    onMouseDown={() => closeLogOutMenu()}
                    className="cancelLogOut"
                    id="cancelLogOut"
                    side="left"
                    NoUpperCase={true}
                    small={false}
                  />
                  <Button
                    buttonText={getText('button-77', user.settings)}
                    buttonTextAttribute="button-77"
                    type="button"
                    buttonType="contained"
                    onClick={logOut}
                    className="logOutButton"
                    id="logOutButton"
                    side="left"
                    NoUpperCase={true}
                    small={false}
                  />
                </div>
              </>
            )}
          </div>
          <div
            className="transparent-Background"
            onMouseDown={loggingOut ? undefined : closeLogOutMenu}
          ></div>
        </>
      ) : null}
    </div>
  )
}
