import React, { useEffect, useState } from 'react'
import { getWordpress } from '../../../../services/requests'
import TutorialTile from './tutorialComponents/TutorialTile'
import { initiliazeListOfTutorials } from '../../../../services/wordpressFunctions'
import TutorialComponent from './tutorialComponents/TutorialComponent'
import { useRecoilState, useRecoilValue } from 'recoil'
import { handlingErrorsState } from '../../../../states/HandlingErrorsState'
import {
  findHandlingErrorState,
  isDevEnv,
  isTestEnv,
} from '../../../../services/commonFunctions'
import { freemiumState } from '../../../../states/FreemiumState'
import { getFreemiumWordpress } from '../../../../freemiumServices/freemiumRequests'

type Props = {
  loadingGif: string
  setHeaderTutorialTitle: (param: string) => void
  setOpenTutorial: (param: string | undefined) => void
  openTutorial: string | undefined
}

export default function Tutorials(props: Props) {
  const [loadingTutorilas, setloadingTutorials] = useState(true)
  const [listOfTutorials, setListOfTutorials] = useState<any[]>([])
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (freemium.isFreemium) {
      getFreemiumWordpress('tutorial').then((res: any) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getWordpress', true),
        )

        if (!('errorCode' in res)) {
          setListOfTutorials([...initiliazeListOfTutorials([...res.data])])
          setTimeout(() => {
            setloadingTutorials(false)
          }, 1200)
        }
      })
    } else {
      getWordpress('tutorial').then((res: any) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getWordpress', true),
        )

        if (!('errorCode' in res)) {
          if (isDevEnv() || isTestEnv()) {
            getWordpress('test_tutorials').then((res2: any) => {
              setHandlingErrors(
                findHandlingErrorState(
                  res,
                  handlingErrors,
                  'getWordpress',
                  true,
                ),
              )

              if (!('errorCode' in res2)) {
                setListOfTutorials([
                  ...initiliazeListOfTutorials([...res.data, ...res2.data]),
                ])
                setTimeout(() => {
                  setloadingTutorials(false)
                }, 1200)
              }
            })
          } else {
            setListOfTutorials([...initiliazeListOfTutorials([...res.data])])
            setTimeout(() => {
              setloadingTutorials(false)
            }, 1200)
          }
        }
      })
    }

    // eslint-disable-next-line
  }, [])

  return (
    <>
      {loadingTutorilas ? (
        <div className="loadingUserManualTextContainer">
          <img className="loadingGif" src={props.loadingGif} alt="loadingGif" />
        </div>
      ) : (
        <>
          {props.openTutorial ? (
            <TutorialComponent
              tutorialObject={
                listOfTutorials.filter(
                  (tutorial) => tutorial.slug === props.openTutorial,
                )[0]
              }
              setOpenTutorial={props.setOpenTutorial}
            />
          ) : (
            <div className="tutorialTilesContainer">
              {listOfTutorials.map((tutorial, index) => (
                <TutorialTile
                  tutorialObject={tutorial}
                  key={`tutorialTile${index}`}
                  setHeaderTutorialTitle={props.setHeaderTutorialTitle}
                  setOpenTutorial={props.setOpenTutorial}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  )
}
