import React, { useState } from 'react'
// import { useXarrow } from 'react-xarrows'

import {
  EventId,
  NodeId,
} from '../../../../../../../../models/treeModels/treeTypes'

import createLineImg from '../../../../../../../../resources/images/200-nodeLineHandle.svg'
import emptyImg from '../../../../../../../../resources/images/209-empty.svg'
import { logActivity } from '../../../../../../../../services/commonFunctions'
import { useRecoilValue } from 'recoil'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  nodeId: NodeId
  treeIndex: number
  zoomLevel: number
  nodeCreatingLine: NodeId | undefined
  setNodeCreatingLine: (nodeCreatingLine: NodeId | undefined) => void
  setNodeTitleHover: (param: boolean) => void
  setDragPosition: (param: any) => void
  connectedDot: EventId | NodeId | undefined
  setConnectedDot: (connectedDot: EventId | NodeId | undefined) => void
  handleConnectNewLineEvent: (
    eventId: EventId,
    connectionNodeId: NodeId,
  ) => void
}

export default function CreateLineConnection(props: Props) {
  // const updateXarrow = useXarrow()
  const freemium = useRecoilValue(freemiumState)
  const [dragTimeoutOn, setDragTimeoutOn] = useState(true)
  const img = new Image()
  img.src = emptyImg

  return (
    <>
      {props.nodeCreatingLine ? <div className="createLineDot"></div> : null}

      <div
        className="createLineContainer"
        id={`createLineContainer-${props.treeIndex}_${props.nodeId}`}
        onMouseDown={() => {
          props.setNodeCreatingLine(props.nodeId)
        }}
        draggable
        onDragStart={(e) => {
          if (e && e.dataTransfer) {
            e.dataTransfer.setDragImage(img, 0, 0)
          }
        }}
        onMouseUp={() => props.setNodeCreatingLine(undefined)}
        onDrag={(e) => {
          if (dragTimeoutOn) {
            setDragTimeoutOn(false)
            setTimeout(() => {
              // updateXarrow()
              props.setDragPosition({
                position: 'fixed',
                left: e.clientX,
                top: e.clientY,
                transform: 'none',
                opacity: 0,
              })
              setDragTimeoutOn(true)
            }, 30)
          }
        }}
        onDragEnd={(e) => {
          props.setNodeCreatingLine(undefined)
          props.setNodeTitleHover(false)
          props.setDragPosition(undefined)
          if (props.connectedDot) {
            //Mixpanel 130 (All)
            logActivity(
              freemium.isFreemium,
              'Created a line from a node to an event parent in legal tree',
            )
            props.handleConnectNewLineEvent(
              props.connectedDot as EventId,
              props.nodeId,
            )
          }
          props.setConnectedDot(undefined)
        }}
        style={
          props.nodeCreatingLine === props.nodeId
            ? { opacity: 0 }
            : { opacity: 1 }
        }
      >
        <img
          className="createLineImg"
          id={`createLineImg-${props.treeIndex}_${props.nodeId}`}
          src={createLineImg}
          alt="createLineImg"
        />
      </div>
    </>
  )
}
