import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { getText } from '../../../../../../../../../services/textFunctions'
import {
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../../models/enums'
import { User } from '../../../../../../../../../models/user'
import { ScenarioSnapshot } from '../../../../../../../../../models/scenarioSnapshot'
import { getStyleFromWhitelabel } from '../../../../../../../../../services/reportFunctions'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { stringAndRoundTo2Decimals } from '../../../../../../../../../Modules/DisputeModules/AppFunctions'
import { getLegalCosts } from '../../../../../../../../../services/legalCostsFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  party: LegalCostsParty
  user: User
  currentSnapshot: ScenarioSnapshot
  proceedings: LegalCostsProceedings
  legalCostsDescriptions?: ReportLegalCostsType
  reportWhitelabel?: ReportWhitelabel
}

export default function LegalCostsExecutiveSummary3Document(props: Props) {
  const styles = StyleSheet.create({
    legalCostsComponent: {
      marginTop: 3,
    },
    costsContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    legalCostsTitle: {
      color: '#8b8b8b',
      fontSize: '8px',
      marginLeft: '6px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).marginTop,
    },
    mediumText: {
      marginLeft: '0px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
    },
    mediumValue: {
      marginLeft: '0px',
      fontFamily: 'Roboto-Medium',
    },
    legalCostsValue: {
      fontSize: '8px',
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    span: {
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
  })
  const hasSecondTrial = props.currentSnapshot.probSecondTrial! > 0

  const legalCostsObj = props.currentSnapshot.legalCosts
  const firstProceedingsDefaultMessage = hasSecondTrial
    ? getText('title-129', props.user.settings)
    : getText('title-130', props.user.settings)

  const secondProceedingsDefaultMessage =
    props.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        getText('title-131a', props.user.settings)
      : //title-131b
        getText('title-131b', props.user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  const getLegalCostsSingleValue = (value: number | string) => {
    if (typeof value === 'string') {
      return `${value} ${props.currentSnapshot.currency}`
    }

    return `${stringAndRoundTo2Decimals(value, props.user.settings)} ${
      props.currentSnapshot.currency
    }`
  }

  return (
    <>
      <View style={styles.legalCostsComponent}>
        <View style={styles.costsContainer}>
          <Text style={{ ...styles.legalCostsTitle, ...styles.mediumText }}>
            {props.proceedings === LegalCostsProceedings.additionalProceedings
              ? secondProceedingsText.length > 0
                ? secondProceedingsText
                : secondProceedingsDefaultMessage
              : firstProceedingsText.length > 0
              ? firstProceedingsText
              : firstProceedingsDefaultMessage}
            :
          </Text>
          <Text style={{ ...styles.legalCostsValue, ...styles.mediumValue }}>
            {getLegalCostsSingleValue(
              props.party === LegalCostsParty.clientLegalCosts
                ? props.proceedings === LegalCostsProceedings.firstProceedings
                  ? legalCostsObj.clientLegalCosts.firstProceedings
                      .estimatedCosts!
                  : legalCostsObj.clientLegalCosts.additionalProceedings!
                      .estimatedCosts!
                : props.proceedings === LegalCostsProceedings.firstProceedings
                ? legalCostsObj.opposingPartyLegalCosts.firstProceedings
                    .estimatedCosts!
                : legalCostsObj.opposingPartyLegalCosts.additionalProceedings!
                    .estimatedCosts!,
            ).replace(props.currentSnapshot.currency, '')}
            <Text style={styles.span}> {props.currentSnapshot.currency}</Text>
          </Text>
        </View>
        <View style={styles.costsContainer}>
          <Text style={styles.legalCostsTitle}>
            {getText('label-89', props.user.settings)}:
          </Text>
          <Text style={styles.legalCostsValue}>
            {getLegalCostsSingleValue(
              getLegalCosts(
                'recoverableCosts',
                props.party,
                props.proceedings,
                props.currentSnapshot,
                props.user.settings,
              ),
            ).replace(props.currentSnapshot.currency, '')}
            <Text style={styles.span}> {props.currentSnapshot.currency}</Text>
          </Text>
        </View>
        <View style={styles.costsContainer}>
          <Text style={styles.legalCostsTitle}>
            {getText('label-91', props.user.settings)}:
          </Text>
          <Text style={styles.legalCostsValue}>
            {getLegalCostsSingleValue(
              getLegalCosts(
                'incurredCosts',
                props.party,
                props.proceedings,
                props.currentSnapshot,
                props.user.settings,
              ),
            ).replace(props.currentSnapshot.currency, '')}
            <Text style={styles.span}> {props.currentSnapshot.currency}</Text>
          </Text>
        </View>
      </View>
    </>
  )
}
