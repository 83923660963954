import React, { useEffect, useState } from 'react'
import Xarrow from 'react-xarrows'
import { AccessRole } from '../../../../../../../models/enums'
import { TreeEventClass } from '../../../../../../../models/treeModels/treeEvent'
import {
  EventId,
  NodeId,
  TreeLines,
} from '../../../../../../../models/treeModels/treeTypes'
import DeleteLineButton from './DeleteLineButton'

type Props = {
  startId: string
  endId: string
  nodeId: NodeId
  eventId: EventId
  events: {
    [eventId: EventId]: TreeEventClass
  }
  deleteLine: (nodeId: NodeId, EventId: EventId) => void
  zoomLevel: number
  lineHover: [NodeId, EventId] | undefined
  lineClick: [NodeId, EventId] | undefined
  selectedLine: boolean
  fadeOut: boolean
  treeIndex: number
  grayOut: boolean
  ownRole: AccessRole
  treeLines: TreeLines
  hanldeSetLineHover: (nodeId: NodeId, eventId: EventId, state: boolean) => void
}
export default function ConnectedLine(props: Props) {
  const lineHoverBoolean =
    JSON.stringify(props.lineHover) ===
    JSON.stringify([props.nodeId, props.eventId])
  const lineClickBoolean =
    JSON.stringify(props.lineClick) ===
    JSON.stringify([props.nodeId, props.eventId])
  const [lineHover, setLineHover] = useState(false)
  const [lineClick, setLineClick] = useState(false)

  useEffect(() => {
    if (!lineHover) {
      props.hanldeSetLineHover(props.nodeId, props.eventId, false)
    }
    // eslint-disable-next-line
  }, [lineHover])

  useEffect(() => {
    document.addEventListener('mousedown', removeLineClick, false)

    return () => {
      document.removeEventListener('mousedown', removeLineClick, false)
    }

    // eslint-disable-next-line
  }, [props.lineClick, lineClick])

  function removeLineClick(e: any) {
    if (
      !e.target.id.includes(
        `Line-${props.nodeId}-${
          props.events[props.eventId as EventId].nodeOfEventId
        }!${props.events[props.eventId as EventId].eventIndex}`,
      )
    ) {
      if (
        e.target.id.includes('connectionCircle') ||
        e.target.id.includes('treeEventAddNodeButton') ||
        e.target.id.includes('treeAddNodeDot')
      ) {
        if (
          e.target.id.includes('connectionCircle') &&
          props.nodeId === e.target.id.split('_')[1] &&
          e.target.id !== `connectionCircle-${props.treeIndex}_${props.nodeId}`
        ) {
          setLineClick(false)
        }
        if (
          e.target.id.includes('treeEventAddNodeButton') &&
          props.nodeId === e.target.id.split('_')[1].split('!')[0] &&
          e.target.id !==
            `treeAddNodeDot-${props.treeIndex}_${props.nodeId}!${
              props.events[props.eventId].eventIndex
            }`
        ) {
          setLineClick(false)
        }
      } else {
        setLineClick(false)
      }
    }
  }

  // if (
  //   props.endId.includes('noded3cd14c51697646202508!0') &&
  //   document.getElementById(
  //     `connectedLine-${props.nodeId}-${
  //       props.events[props.eventId as EventId].nodeOfEventId
  //     }!${props.events[props.eventId as EventId].eventIndex}`,
  //   )
  // )
  //   console.log(
  //     document
  //       .getElementById(
  //         `connectedLine-${props.nodeId}-${
  //           props.events[props.eventId as EventId].nodeOfEventId
  //         }!${props.events[props.eventId as EventId].eventIndex}`,
  //       )
  //       ?.getAttribute('d'),
  //   )

  return (
    <>
      <Xarrow
        start={props.startId}
        startAnchor={'top'}
        end={props.endId}
        endAnchor={'bottom'}
        color={
          lineClick || lineClickBoolean
            ? '#A699F2'
            : props.selectedLine
            ? '#ffc6af'
            : props.grayOut
            ? '#cbcbcb57'
            : 'black'
        }
        strokeWidth={1}
        showHead={false}
        showTail={false}
        passProps={{
          onMouseEnter: () => setLineHover(true),
          onClick: () => setLineClick(lineClick),
          onMouseLeave: () => setLineHover(false),
          id: `connectedLine-${props.nodeId}-${
            props.events[props.eventId as EventId].nodeOfEventId
          }!${props.events[props.eventId as EventId].eventIndex}`,
          className: `${props.fadeOut ? 'lineFadeOut' : ''}`,
          cursor: props.ownRole !== AccessRole.VIEWER ? 'pointer' : 'default',
        }}
        labels={
          (lineHover || lineHoverBoolean) && props.ownRole !== AccessRole.VIEWER
            ? {
                middle: (
                  <DeleteLineButton
                    setLineHover={setLineHover}
                    deleteLine={props.deleteLine}
                    nodeId={props.nodeId}
                    eventId={props.eventId}
                    zoomLevel={props.zoomLevel}
                    treeIndex={props.treeIndex}
                  />
                ),
              }
            : undefined
        }
        path={props.treeLines}
        curveness={0.3}
      />
    </>
  )
}
