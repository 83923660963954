import { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'

import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import { TreeNodeClass } from '../../../../../../../models/treeModels/treeNode'
import {
  AccessRole,
  AmountSignum,
  InterestViewOption,
  PartySignum,
  UndoRedoType,
} from '../../../../../../../models/enums'
import {
  DeleteMenuType,
  DeleteNodeOrEventMenuObject,
  EventId,
  NodeId,
  NodeMode,
  NodesMovedForEditMode,
  RootNodeType,
} from '../../../../../../../models/treeModels/treeTypes'
import { TreeClaim } from '../../../../../../../models/treeModels/treeClaim'
import { TreeEventClass } from '../../../../../../../models/treeModels/treeEvent'

import {
  deepCloneObject,
  generateId,
  roundNumberTo,
  transformDateToString,
} from '../../../../../../../services/commonFunctions'
import {
  changeTextAreaHeight,
  changeTreeClaim,
  findMainTreeAndOrphanTrees,
  findNewNodeSiblingIndex,
  treeHasInterestUsingSnapshot,
} from '../../../../../../../services/treeFunctions/treeBasicFunctions'
import {
  changeNodesPosition,
  findNewNodePosition,
  findNodesMovedForEdtiMode,
} from '../../../../../../../services/treeFunctions/treePositioningFunctions'

import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { nodesMovedForEditModeState } from '../../../../../../../states/NodesMovedForEditModeState'
import {
  checkIfChildIsUnderParent,
  heightOfNode,
} from '../../../../../../../services/treeFunctions/treeXandYFunctions'
import { treeTablesState } from '../../../../../../../states/TreeTablesState'
import {
  DefaultSnapshotState,
  TreeTablesState,
} from '../../../../../../../models/generalTypes'
import { findEventNumber } from '../../../../../../../services/twoParentsFunctionsForNumberOfScenarios'
import { snapshotChangeKillsResults } from '../../../../../../../services/killingTheResultsFunctions'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import { FreemiumMessageType } from '../../../../../../../models/freemiumEnums'
import EventTitleContainer from './EventTitleContainer'
import EventAwardedAmountContainer from './EventAwardedAmountContainer'
import EventExternalContainer from './EventExternalContainer'
import EventProbabilityContainer from './EventProbabilityContainer'
import EventReducedAmountContainer from './EventReducedAmountContainer'

type Props = {
  childrenNodes: NodeId[]
  eventDetails: EventDetails
  nodeMode: NodeMode
  nodeId: NodeId
  eventId: EventId
  eventIndex: number
  eventsInNode: number
  treeIndex: number
  handleChangeEventProbability: (value: number, eventIndex: number) => void
  nodeProbabilities: number[]
  shakeScenario: number | undefined
  editMode: NodeId | undefined
  setEditMode: (editMode: NodeId | undefined) => void
  zoomLevel: number
  nodeSelected: boolean
  elementsToBeDeletedForAnimation: (NodeId | EventId)[]
  setElementsToBeDeletedForAnimation: (param: (NodeId | EventId)[]) => void
  setMainTree: (mainTree: NodeId[]) => void
  setOrphanTrees: (orphanTrees: NodeId[][]) => void
  showTreeInfo: boolean
  resetActivePath: boolean
  selectedPath: number[]
  debugging: boolean
  nodeHasAtLeastOneInterestMenuOn: boolean
  nodeHasAtLeastOneAwardedAmount: boolean
  setOpenDeleteNodeOrEventMenu: (
    openDeleteNodeorEventMenu: DeleteNodeOrEventMenuObject | undefined,
  ) => void
  handleDeleteTreeEvent: (
    withChildren: boolean,
    nodeId: NodeId,
    eventId: EventId,
    eventIndex: number,
  ) => void
  highlighted: boolean
  errors: string[]
  connectionCreatesLoop: EventId | NodeId | undefined
  ownRole: AccessRole
}

export default function TreeEvent(props: Props) {
  const eventRef = useRef<HTMLDivElement>(null)
  const [active, setActive] = useState(false)

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)

  const treeDetails = (
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).treeDetails
  const [showDeleteEvent, setShowDeleteEvent] = useState(false)

  const [startAnimation, setStartAnimation] = useState(false)
  const treeNodeMode = (
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).nodeMode
  const [nodesMovedForEditMode, setNodesMovedForEditMode] = useRecoilState(
    nodesMovedForEditModeState,
  )
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  useEffect(() => {
    if (props.elementsToBeDeletedForAnimation.includes(props.eventId)) {
      setStartAnimation(true)
    }
    // eslint-disable-next-line
  }, [props.elementsToBeDeletedForAnimation])

  useEffect(() => {
    if (
      document.activeElement ===
        document.getElementById(
          `treeEventTitleInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
        ) ||
      document.activeElement ===
        document.getElementById(
          `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
        ) ||
      document.activeElement ===
        document.getElementById(
          `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
        ) ||
      document.activeElement ===
        document.getElementById(
          `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
        )
    ) {
      props.setEditMode(props.nodeId)
    }
    // eslint-disable-next-line
  }, [document.activeElement])

  useEffect(() => {
    /* 
    09/07/2021
    This function sets the active State when the selectedPath and resetActivePath change

    active State is used to define if this scenario is active or not (green or not)
    */
    if (!props.resetActivePath) {
      setActive(false)
    } else {
      if (props.selectedPath.length > 0) {
        for (let event_index of props.selectedPath) {
          if (event_index === findEventNumber(props.eventId, treeDetails)) {
            setActive(true)
            break
          } else {
            setActive(false)
          }
        }
      } else {
        setActive(false)
      }
    }
    // eslint-disable-next-line
  }, [props.selectedPath, props.resetActivePath])

  function handleChangeTreeEventDetail(
    targetId: string,
    eventDetailKey: keyof EventDetails,
    value:
      | never
      | AmountSignum
      | PartySignum
      | string
      | number
      | boolean
      | undefined
      | Date,
  ) {
    let tempScenarioSnapshot = deepCloneObject(
      scenarioSnapshot,
    ) as DefaultSnapshotState
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    let tempEventDetails =
      tempTreeClaim.treeDetails.events[props.eventId].eventDetails
    let tempTargetId: string | string[] = targetId
    let tempType =
      treeNodeMode === NodeMode.minimised
        ? UndoRedoType.minimisedInput
        : UndoRedoType.input
    let tempAnalysisResults = false

    switch (eventDetailKey) {
      case 'eventTitle':
        tempAnalysisResults = tempTreeClaim.analysisResults
        break
      case 'hasInterest':
        if (freemium.isFreemium && value) {
          setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
          return
        }

        tempTargetId = [
          `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
          `treeEventIncludeInterestButtonImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
        ]
        if (treeNodeMode === NodeMode.minimised) {
          tempType = UndoRedoType.minimisedButton
        } else {
          tempType = UndoRedoType.button
        }
        if (value === false) {
          tempEventDetails.interestDate = undefined
        }
        break
      case 'interestDate':
        tempTargetId = [
          `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
          `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
          'true',
        ]
        value = transformDateToString(value as Date) ?? undefined
        break
      case 'interestRate':
        value =
          value !== undefined ? roundNumberTo((value as number) * 0.01, 3) : 0.0
        break
      case 'awardedAmountSignum':
        tempTargetId = `treeEventAwardedAmountContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`
        if (treeNodeMode === NodeMode.minimised) {
          tempType = UndoRedoType.minimisedButton
        } else {
          tempType = UndoRedoType.button
        }

        if (
          targetId ===
          `treeEventAwardedAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`
        ) {
          tempTargetId = [
            `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
            `treeEventAwardedAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
          ]
        }
        if (value === AmountSignum.off) {
          tempEventDetails.awardedAmount = 0
          tempEventDetails.interestDate = undefined
          tempEventDetails.hasInterest = false
        }
        if (value === AmountSignum.get) {
          tempEventDetails.awardedAmount =
            typeof tempEventDetails.awardedAmount === 'number'
              ? Math.abs(tempEventDetails.awardedAmount)
              : 0
        }
        if (value === AmountSignum.pay) {
          tempEventDetails.awardedAmount =
            typeof tempEventDetails.awardedAmount === 'number'
              ? -Math.abs(tempEventDetails.awardedAmount)
              : 0
        }
        break
      case 'reducedAwardedAmountParty':
        tempTargetId = `treeEventReducedAmountContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`
        if (treeNodeMode === NodeMode.minimised) {
          tempType = UndoRedoType.minimisedButton
        } else {
          tempType = UndoRedoType.button
        }

        if (
          targetId ===
          `treeEventReducedAmountPartySwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`
        ) {
          tempTargetId = [
            `treeEventReducedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
            `treeEventReducedAmountPartySwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
          ]
        }
        if (value === PartySignum.off) {
          tempEventDetails.reducedAwardedAmount = 0
        }
        break
      case 'awardedAmount':
        value =
          value === undefined
            ? undefined
            : props.eventDetails.awardedAmountSignum === AmountSignum.get
            ? value
            : -value
        break
      case 'outOfCourtAmountSignum':
        tempTargetId = `treeEventAddSectionButtonOutOfCourtAmount-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`
        if (treeNodeMode === NodeMode.minimised) {
          tempType = UndoRedoType.minimisedButton
        } else {
          tempType = UndoRedoType.button
        }
        if (
          targetId ===
          `treeEventOutOfCourtAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`
        ) {
          tempTargetId = [
            `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
            `treeEventOutOfCourtAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
          ]
        }
        if (value === AmountSignum.off) {
          tempEventDetails.outOfCourtAmount = 0
        }
        if (value === AmountSignum.get) {
          tempEventDetails.outOfCourtAmount =
            typeof tempEventDetails.outOfCourtAmount === 'number'
              ? Math.abs(tempEventDetails.outOfCourtAmount)
              : 0
        }
        if (value === AmountSignum.pay) {
          tempEventDetails.outOfCourtAmount =
            typeof tempEventDetails.outOfCourtAmount === 'number'
              ? -Math.abs(tempEventDetails.outOfCourtAmount)
              : 0
        }
        break
      case 'outOfCourtAmount':
        value =
          value === undefined
            ? undefined
            : props.eventDetails.outOfCourtAmountSignum === AmountSignum.get
            ? value
            : -value
        break
      default:
        break
    }

    tempTreeClaim.treeDetails.events[props.eventId].eventDetails[
      eventDetailKey
    ] = value as never

    if (nodesMovedForEditMode) {
      let tempNodesMovedForEditMode: NodesMovedForEditMode | undefined

      let currentNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)

      tempNodesMovedForEditMode = findNodesMovedForEdtiMode(
        props.nodeId,
        tempTreeClaim.treeDetails,
        treeNodeMode,
        true,
        props.treeIndex,
      )
      for (let commonNode of Object.keys(currentNodesMovedForEditMode).filter(
        (nodeId) => Object.keys(tempNodesMovedForEditMode!).includes(nodeId),
      )) {
        tempNodesMovedForEditMode[commonNode].previousPosition =
          currentNodesMovedForEditMode[commonNode].previousPosition
      }

      for (let uniqueNode of Object.keys(currentNodesMovedForEditMode).filter(
        (nodeId) =>
          !Object.keys(tempNodesMovedForEditMode!).includes(nodeId) &&
          props.nodeId !== nodeId,
      )) {
        tempTreeClaim.treeDetails.nodes[
          uniqueNode as NodeId
        ].properties.position =
          currentNodesMovedForEditMode[uniqueNode].previousPosition
      }

      if (Object.keys(currentNodesMovedForEditMode).includes(props.nodeId)) {
        tempNodesMovedForEditMode[props.nodeId] =
          currentNodesMovedForEditMode[props.nodeId]
      }

      tempNodesMovedForEditMode = {
        ...currentNodesMovedForEditMode,
        ...tempNodesMovedForEditMode,
      }
      if (
        tempNodesMovedForEditMode &&
        Object.keys(tempNodesMovedForEditMode).length === 0
      ) {
        tempNodesMovedForEditMode = undefined
      }

      if (tempNodesMovedForEditMode !== undefined) {
        setNodesMovedForEditMode(tempNodesMovedForEditMode)

        for (let nodeId of Object.keys(tempNodesMovedForEditMode)) {
          tempTreeClaim.treeDetails.nodes[
            nodeId as NodeId
          ].properties.position = tempNodesMovedForEditMode[nodeId].newPosition
        }
        ;(
          tempScenarioSnapshot.currentSnapshot.claims[
            props.treeIndex
          ] as TreeClaim
        ).treeDetails = tempTreeClaim.treeDetails
        changeTextAreaHeight(
          document.getElementById(
            `treeNodeTitleTextarea-${props.treeIndex}_${props.nodeId}`,
          )!,
        )
      }
    }

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      tempTargetId,
      tempType,
      props.treeIndex,
      tempAnalysisResults,
      AccessRole.NONE,
      nodesMovedForEditMode,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    if (
      snapshotChangeKillsResults(
        scenarioSnapshot.currentSnapshot,
        tempScenarioSnapshot.currentSnapshot,
      )
    ) {
      //hide Treetable
      const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
        (treeTableInfo) =>
          treeTableInfo.treeId ===
          scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
      )

      let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
      if (
        tempTreeTables.treeTablesInfo &&
        tempTreeTables.treeTablesInfo[treeTableInfoIndex]
      ) {
        if (
          !treeHasInterestUsingSnapshot(
            tempScenarioSnapshot.currentSnapshot,
            props.treeIndex,
          )
        ) {
          tempTreeTables.treeTablesInfo[
            treeTableInfoIndex
          ].treeInterestViewOption = InterestViewOption.noInterest
        }
        tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
        setTreeTables(tempTreeTables)
      }
    }
  }

  function handleChangeEventProbability(e: any) {
    if (e.target.value === undefined) {
      e.target.value = 0
    }
    if (e.target.value.toString().startsWith('0')) {
      e.target.value = parseInt(e.target.value)
    }

    e.target.value = roundNumberTo(e.target.value / 100, 2)

    props.handleChangeEventProbability(e, props.eventIndex)
  }

  function handleDeleteEventClick(e: any) {
    let treeEventHasChildren =
      (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
        .treeDetails.events[props.eventId].childrenNodes.length > 0

    if (treeEventHasChildren) {
      props.setOpenDeleteNodeOrEventMenu({
        nodeId: props.nodeId,
        nodeMode: NodeMode.maximised,
        deleteMenuType: DeleteMenuType.event,
        eventId: props.eventId,
        eventIndex: props.eventIndex,
      })
    } else {
      props.handleDeleteTreeEvent(
        false,
        props.nodeId,
        props.eventId,
        props.eventIndex,
      )
    }
  }

  function handleAddNode(e: any) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    const newNodeId: NodeId = generateId('node') as NodeId
    const tempUndoRedoType =
      treeNodeMode === NodeMode.maximised
        ? UndoRedoType.button
        : UndoRedoType.minimisedButton
    const newNodeSiblingIndex = findNewNodeSiblingIndex(
      props.eventId,
      tempTreeClaim,
    )
    tempTreeClaim.treeDetails.events[props.eventId].childrenNodes.push(
      newNodeId,
    )

    let [newNodeX, newNodeY] = findNewNodePosition(
      props.eventId,
      props.nodeId,
      newNodeId,
      tempTreeClaim.treeDetails,
      props.nodeMode,
      tempTreeClaim.nodeMode,
      props.treeIndex,
      props.editMode === props.nodeId,
    )

    tempTreeClaim.treeDetails.nodes[newNodeId] = TreeNodeClass.defaultTreeNode(
      newNodeSiblingIndex,
      [newNodeX, newNodeY],
      RootNodeType.noRoot,
    )
    /*  console.warn(
      'After knowing the newNode perfect position I am checking the rest of the tree',
    ) */

    tempTreeClaim.treeDetails = changeNodesPosition(
      newNodeId,
      tempTreeClaim.treeDetails,
      tempTreeClaim.nodeMode,
      'addNode',
      props.treeIndex,
      props.editMode,
    )

    /* console.error('All positions found') */

    const newEventId1: EventId = generateId('event') as EventId
    tempTreeClaim.treeDetails.events[newEventId1] =
      TreeEventClass.defaultTreeEvent(
        newNodeId,
        0,
        EventDetails.defaultEventDetails(0.5),
      )
    const newEventId2: EventId = generateId('event') as EventId
    tempTreeClaim.treeDetails.events[newEventId2] =
      TreeEventClass.defaultTreeEvent(
        newNodeId,
        1,
        EventDetails.defaultEventDetails(0.5),
      )

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
    if (tempMainTree.includes('node123')) return
    props.setMainTree(tempMainTree)
    props.setOrphanTrees(tempOrphanTrees)

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      e.target.id,
      tempUndoRedoType,
      props.treeIndex,
      false,
      AccessRole.NONE,
      nodesMovedForEditMode,
    )

    if (props.editMode === props.nodeId) {
      if (
        checkIfChildIsUnderParent(
          (
            tempScenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ] as TreeClaim
          ).treeDetails,
          newNodeId,
          props.nodeId,
          treeNodeMode,
          props.treeIndex,
        )
      ) {
        let tempNodesMovedForEditMode = deepCloneObject({})
        let yDifference =
          heightOfNode(
            NodeMode.maximised,
            props.nodeId,
            props.treeIndex,
            treeDetails,
            true,
          ) -
          heightOfNode(
            treeNodeMode,
            props.nodeId,
            props.treeIndex,
            treeDetails,
            false,
          )
        if (nodesMovedForEditMode) {
          tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)

          for (let tempNodeId of Object.keys(tempNodesMovedForEditMode)) {
            if (
              tempNodesMovedForEditMode[tempNodeId].newPosition[1] -
                tempNodesMovedForEditMode[tempNodeId].previousPosition[1] >
              0
            ) {
              let tempyDifference =
                tempNodesMovedForEditMode[tempNodeId].newPosition[1] -
                tempNodesMovedForEditMode[tempNodeId].previousPosition[1]
              tempNodesMovedForEditMode[tempNodeId].previousPosition[0] =
                tempTreeClaim.treeDetails.nodes[
                  tempNodeId as NodeId
                ].properties.position[0]
              tempNodesMovedForEditMode[tempNodeId].previousPosition[1] =
                tempTreeClaim.treeDetails.nodes[tempNodeId as NodeId].properties
                  .position[1] - tempyDifference
            }
          }
        }

        tempNodesMovedForEditMode[newNodeId] = {
          newPosition: [
            tempTreeClaim.treeDetails.nodes[newNodeId as NodeId].properties
              .position[0],
            tempTreeClaim.treeDetails.nodes[newNodeId as NodeId].properties
              .position[1],
          ],
          previousPosition: [
            tempTreeClaim.treeDetails.nodes[newNodeId as NodeId].properties
              .position[0],
            tempTreeClaim.treeDetails.nodes[newNodeId as NodeId].properties
              .position[1] - yDifference,
          ],
        }

        setNodesMovedForEditMode(tempNodesMovedForEditMode)
      }
    }

    setScenarioSnapshot(tempScenarioSnapshot)
    //hide Treetable
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
      setTreeTables(tempTreeTables)
    }

    // updateXarrow()
  }

  return (
    <>
      <div
        className="treeEventBackground"
        id={`treeEventBackground-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
        onMouseEnter={() => setShowDeleteEvent(true)}
        onMouseLeave={() => setShowDeleteEvent(false)}
        ref={eventRef}
      >
        <EventTitleContainer
          nodeSelected={props.nodeSelected}
          highlighted={props.highlighted}
          active={active}
          startAnimation={startAnimation}
          treeIndex={props.treeIndex}
          nodeId={props.nodeId}
          eventIndex={props.eventIndex}
          showTreeInfo={props.showTreeInfo}
          debugging={props.debugging}
          eventId={props.eventId}
          eventsInNode={props.eventsInNode}
          showDeleteEvent={showDeleteEvent}
          eventDetails={props.eventDetails}
          handleDeleteEventClick={handleDeleteEventClick}
          handleChangeTreeEventDetail={handleChangeTreeEventDetail}
        />

        <EventAwardedAmountContainer
          active={active}
          highlighted={props.highlighted}
          nodeHasAtLeastOneInterestMenuOn={
            props.nodeHasAtLeastOneInterestMenuOn
          }
          nodeHasAtLeastOneAwardedAmount={props.nodeHasAtLeastOneAwardedAmount}
          treeIndex={props.treeIndex}
          nodeId={props.nodeId}
          eventIndex={props.eventIndex}
          eventDetails={props.eventDetails}
          editMode={props.editMode}
          errors={props.errors}
          nodeSelected={props.nodeSelected}
          startAnimation={startAnimation}
          eventId={props.eventId}
          handleChangeTreeEventDetail={handleChangeTreeEventDetail}
          setEditMode={props.setEditMode}
        />

        <EventReducedAmountContainer
          active={active}
          highlighted={props.highlighted}
          nodeHasAtLeastOneInterestMenuOn={
            props.nodeHasAtLeastOneInterestMenuOn
          }
          nodeHasAtLeastOneAwardedAmount={props.nodeHasAtLeastOneAwardedAmount}
          treeIndex={props.treeIndex}
          nodeId={props.nodeId}
          eventIndex={props.eventIndex}
          eventDetails={props.eventDetails}
          editMode={props.editMode}
          errors={props.errors}
          nodeSelected={props.nodeSelected}
          startAnimation={startAnimation}
          eventId={props.eventId}
          handleChangeTreeEventDetail={handleChangeTreeEventDetail}
          setEditMode={props.setEditMode}
        />

        <EventExternalContainer
          active={active}
          highlighted={props.highlighted}
          treeIndex={props.treeIndex}
          nodeId={props.nodeId}
          eventIndex={props.eventIndex}
          eventDetails={props.eventDetails}
          editMode={props.editMode}
          nodeSelected={props.nodeSelected}
          startAnimation={startAnimation}
          eventId={props.eventId}
          handleChangeTreeEventDetail={handleChangeTreeEventDetail}
          setEditMode={props.setEditMode}
        />

        <EventProbabilityContainer
          active={active}
          highlighted={props.highlighted}
          treeIndex={props.treeIndex}
          nodeId={props.nodeId}
          eventIndex={props.eventIndex}
          eventDetails={props.eventDetails}
          nodeSelected={props.nodeSelected}
          startAnimation={startAnimation}
          eventId={props.eventId}
          nodeProbabilities={props.nodeProbabilities}
          shakeScenario={props.shakeScenario}
          connectionCreatesLoop={props.connectionCreatesLoop}
          handleChangeEventProbability={handleChangeEventProbability}
          handleAddNode={handleAddNode}
        />
      </div>
    </>
  )
}
