import { keysIn } from 'lodash'
import englishTexts from '../resources/texts/englishTexts.json'

//Currency
export enum Currency {
  SEK = 'SEK',
  MSEK = 'MSEK',
  EUR = 'EUR',
  MEUR = 'MEUR',
  GBP = 'GBP',
  MGBP = 'MGBP',
  USD = 'USD',
  MUSD = 'MUSD',
  CAD = 'CAD',
  MCAD = 'MCAD',
  NOK = 'NOK',
  MNOK = 'MNOK',
  DKK = 'DKK',
  MDKK = 'MDKK',
  JPY = 'JPY',
  MJPY = 'MJPY',
  CHF = 'CHF',
  MCHF = 'MCHF',
  AUD = 'AUD',
  MAUD = 'MAUD',
  NZD = 'NZD',
  MNZD = 'MNZD',
  CNY = 'CNY',
  MCNY = 'MCNY',
  HKD = 'HKD',
  MHKD = 'MHKD',
  SGD = 'SGD',
  MSGD = 'MSGD',
}

type CurrencyString = `${Currency}`

export const currencyFromString = (value: string): Currency => {
  return Currency[value as CurrencyString]
}

export const getCurrencyDescription = (
  currency: Currency,
): keyof typeof englishTexts => {
  switch (currency) {
    case Currency.SEK:
    case Currency.MSEK:
      // Swedish Krona
      return 'currency-1'
    case Currency.EUR:
    case Currency.MEUR:
      // Euro
      return 'currency-2'
    case Currency.GBP:
    case Currency.MGBP:
      // British Pound Sterling
      return 'currency-3'
    case Currency.USD:
    case Currency.MUSD:
      // United States Dollar
      return 'currency-4'
    case Currency.CAD:
    case Currency.MCAD:
      // Canadian Dollar
      return 'currency-5'
    case Currency.NOK:
    case Currency.MNOK:
      // Norwegian Krone
      return 'currency-6'
    case Currency.DKK:
    case Currency.MDKK:
      // Danish Krone
      return 'currency-7'
    case Currency.JPY:
    case Currency.MJPY:
      // Japanese Yen
      return 'currency-8'
    case Currency.CHF:
    case Currency.MCHF:
      // Swiss Franc
      return 'currency-9'
    case Currency.AUD:
    case Currency.MAUD:
      // Australian Dollar
      return 'currency-10'
    case Currency.NZD:
    case Currency.MNZD:
      // New Zealand Dollar
      return 'currency-11'
    case Currency.CNY:
    case Currency.MCNY:
      // Chinese Yuan Renminbi
      return 'currency-12'
    case Currency.HKD:
    case Currency.MHKD:
      // Hong Kong Dollar
      return 'currency-13'
    case Currency.SGD:
    case Currency.MSGD:
      // Singapore Dollar
      return 'currency-14'
  }
}

export const isCurrencyInMillions = (currency: Currency) => {
  return currency.toString()[0] === 'M'
}

export const currencyToMillions = (currency: Currency) => {
  if (currency.toString()[0] === 'M') {
    return currency
  }

  return currencyFromString(`M${currency.toString()}`)
}

export const currencyFromMillions = (currency: Currency) => {
  if (currency.toString()[0] !== 'M') {
    return currency
  }

  return currencyFromString(`${currency.toString().substring(1)}`)
}

//TypeOfInstance

export enum TypeOfInstance {
  Arbitration = 'Arbitration',
  Public_Court = 'Public_Court',
  Other = 'Other',
}

type TypeOfInstanceString = `${TypeOfInstance}`

export const typeOfInstanceFromString = (value: string): TypeOfInstance => {
  return TypeOfInstance[value as TypeOfInstanceString]
}

//LegalFeesDistributionMethod

export enum LegalFeesDistributionMethod {
  Linear = 'Linear',
  Manual = 'Manual',
}

type LegalFeesDistributionMethodString = `${LegalFeesDistributionMethod}`

export const legalFeesDistributionMethodFromString = (
  value: string,
): LegalFeesDistributionMethod => {
  return LegalFeesDistributionMethod[value as LegalFeesDistributionMethodString]
}

//ClaimTypeString

export enum ClaimType {
  claim = 'claim',
  counterclaim = 'counterclaim',
  tree = 'tree',
}

type ClaimTypeString = `${ClaimType}`

export const claimTypeFromString = (value: string): ClaimType => {
  return ClaimType[value as ClaimTypeString]
}

//AmountSignum for amount and outOfcourt radioButtons
export enum AmountSignum {
  get = 'get',
  pay = 'pay',
  off = 'off',
}

//PartySignum for reduced awarded amount radioButtons
export enum PartySignum {
  client = 'client',
  opposingParty = 'opposingParty',
  off = 'off',
}

type AmountSignumString = `${AmountSignum}`

export const amountSignumFromString = (value: string): AmountSignum => {
  return AmountSignum[value as AmountSignumString]
}

//SnapshotStatus
export enum SnapshotStatus {
  CalculatingTrees = 'CalculatingTrees',
  Saving = 'Saving',
  Queuing = 'Queuing',
  Queued = 'Queued',
  Processing = 'Processing',
  ProcessingReversed = 'ProcessingReversed',
  Done = 'Done',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  None = 'None',
}

type SnapshotStatusString = `${SnapshotStatus}`

export const snapshotStatusFromString = (value: string): SnapshotStatus => {
  return SnapshotStatus[value as SnapshotStatusString]
}

//UndoRedoType

export enum UndoRedoType {
  input = 'input',
  button = 'button',
  div = 'div',
  addRemove = 'addRemove',
  minimisedInput = 'minimisedInput',
  minimisedButton = 'minimisedButton',
}

type UndoRedoTypeString = `${UndoRedoType}`

export const undoRedoTypeFromString = (value: string): UndoRedoType => {
  return UndoRedoType[value as UndoRedoTypeString]
}

export enum RequestError {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  LOCKED_ACCOUNT = 429,
  SERVER_ERROR = 500,
  NOT_EXIST = 404,
  TIMEOUT = 504,
  WRONG_REDUCED_DATES = 598,
  UNKNOWN_ERROR,
}

export enum ToolTabs {
  proceedings = 'Proceedings',
  claims = 'Claims',
  legalCosts = 'Legal Costs',
  legalCostsDistribution = 'Legal Costs Distribution',
  advanced = 'Advanced',
  analyze = 'ANALYSE',
  analyzing = 'ANALYSING...',
  results = 'RESULTS',
  failed = 'ANALYSIS FAILED',
}

export enum SaveMenuEnum {
  academy = 'Academy',
  settings = 'Settings',
  mycases = 'My Cases',
  myscenarios = 'My Scenarios',
  mycasesSide = 'My Cases - Side Menu',
  logOut = 'Log out - Side Menu',
  report = 'Report',
}

export enum IdsOfProceedings {
  currencyDropdown = 'currencyDropdown',
  typeOfInstance = 'typeOfInstance',
  firstTrialDate = 'firstTrialDate',
  secondTrialAddRemoveButton = 'secondTrialAddRemoveButton',
  probSecondTrialstep1 = 'probSecondTrialstep1',
  probSecondTrialstep2 = 'probSecondTrialstep2',
  secondTrialDate = 'secondTrialDate',
}

export enum IdsOfClaims {
  addClaimContainer = 'addClaimContainer',
  switchClaimTypeButton = 'switchClaimTypeButton',
  claimName = 'claimName',
  claimAmount = 'claimAmount',
  addRemoveClaimInterestContainer = 'addRemoveClaimInterestContainer',
  claimInterestRate = 'claimInterestRate',
  claimInterestDate = 'claimInterestDate',
  addLiabilityRequirement = 'addLiabilityRequirement',
  deleteRequirementImg = 'deleteRequirementImg',
  requirementText = 'requirementText',
  requirementLikelihood = 'requirementLikelihood',
  includeExternalConsequenceContainer = 'includeExternalConsequenceContainer',
  singleExternalConsequenceContainer = 'singleExternalConsequenceContainer',
  addRemoveExternal = 'addRemoveExternal',
  claimAltAmount = 'claimAltAmount',
  claimQuantumProbability = 'claimQuantumProbability',
  claimOutOfCourtAmount = 'claimOutOfCourtAmount',
  switchClaimOutOfCourtSignum = 'switchClaimOutOfCourtSignum',
  addClaimSingleQuantum = 'addClaimSingleQuantum',
  deleteClaimSingleQuantum = 'deleteClaimSingleQuantum',
  treeSideMenuButton = 'treeSideMenuButton',
  treeNameInput = 'treeNameInput',
  treeNodeTitleTextarea = 'treeNodeTitleTextarea',
  deleteNodeButtonImg = 'deleteNodeButtonImg',
  deleteTreeEventImg = 'deleteTreeEventImg',
  treeEventTitleInput = 'treeEventTitleInput',
  treeEventTitleContainer = 'treeEventTitleContainer',
  treeEventAwardedAmountContainer = 'treeEventAwardedAmountContainer',
  treeEventAddSectionButtonAwardedAmount = 'treeEventAddSectionButtonAwardedAmount',
  treeEventRemoveAmountInterestSectionContainer = 'treeEventRemoveAmountInterestSectionContainer',
  treeEventAwardedAmountInput = 'treeEventAwardedAmountInput',
  treeEventIncludeInterestButtonContainer = 'treeEventIncludeInterestButtonContainer',
  treeEventInterestRateInput = 'treeEventInterestRateInput',
  treeEventInterestDateInput = 'treeEventInterestDateInput',
  treeEventAddSectionButtonReducedAmount = 'treeEventAddSectionButtonReducedAmount',
  treeEventRemoveReducedAmountSectionContainer = 'treeEventRemoveReducedAmountSectionContainer',
  treeEventReducedAmountInput = 'treeEventReducedAmountInput',
  treeEventReducedAmountPartySwitch = 'treeEventReducedAmountPartySwitch',
  treeEventReducedAmountContainer = 'treeEventReducedAmountContainer',
  treeNodeMoveHandle = 'treeNodeMoveHandle',
  treeNodeTitle = 'treeNodeTitle',
  treeEventAddSectionButtonOutOfCourtAmount = 'treeEventAddSectionButtonOutOfCourtAmount',
  treeAmountOutOfCourtGetButton = 'treeAmountOutOfCourtGetButton',
  treeAmountOutOfCourtPayButton = 'treeAmountOutOfCourtPayButton',
  treeEventOutOfCourtAmountInput = 'treeEventOutOfCourtAmountInput',
  treeNode = 'treeNode',
  treeAddEvent = 'treeAddEvent',
  treeEventBackground = 'treeEventBackground',
  treeEventProbabilityInput = 'treeEventProbabilityInput',
  treeEventAddNodeButton = 'treeEventAddNodeButton',
  removeClaimImgPreview = 'removeClaimImgPreview',
  connectionCircle = 'connectionCircle',
  singleClaimPreviewContainer = 'singleClaimPreviewContainer',
  tilesPreviewButton = 'tilesPreviewButton',
  listPreviewButton = 'listPreviewButton',
  expandedPreviewButton = 'expandedPreviewButton',
  titleListTitle = 'titleListTitle',
  clientListTitle = 'clientListTitle',
  opposingListTitle = 'opposingListTitle',
  weightedListTitle = 'weightedListTitle',
  claimsInterestViewOptionDropdown = 'claimsInterestViewOptionDropdown',
}

export enum IdsOfLegalCosts {
  insuranceAddRemoveButton = 'insuranceAddRemoveButton',
  minimumExcessFee = 'minimumExcessFee',
  insuranceExcessPercentage = 'insuranceExcessPercentage',
  maxInsurance = 'maxInsurance',
  legalCostsAddRemoveButton = 'legalCostsAddRemoveButton',
  'estimatedCosts-clientLegalCosts-firstProceedings' = 'estimatedCosts-clientLegalCosts-firstProceedings',
  'recoverableCosts-clientLegalCosts-firstProceedings' = 'recoverableCosts-clientLegalCosts-firstProceedings',
  'legalCostsSwitchButtonContainer-clientLegalCosts-firstProceedings-recoverableCosts' = 'legalCostsSwitchButtonContainer-clientLegalCosts-firstProceedings-recoverableCosts',
  'incurredCosts-clientLegalCosts-firstProceedings' = 'incurredCosts-clientLegalCosts-firstProceedings',
  'legalCostsSwitchButtonContainer-clientLegalCosts-firstProceedings-incurredCosts' = 'legalCostsSwitchButtonContainer-clientLegalCosts-firstProceedings-incurredCosts',
  'estimatedCosts-clientLegalCosts-additionalProceedings' = 'estimatedCosts-clientLegalCosts-additionalProceedings',
  'recoverableCosts-clientLegalCosts-additionalProceedings' = 'recoverableCosts-clientLegalCosts-additionalProceedings',
  'legalCostsSwitchButtonContainer-clientLegalCosts-additionalProceedings-recoverableCosts' = 'legalCostsSwitchButtonContainer-clientLegalCosts-additionalProceedings-recoverableCosts',
  'incurredCosts-clientLegalCosts-additionalProceedings' = 'incurredCosts-clientLegalCosts-additionalProceedings',
  'legalCostsSwitchButtonContainer-clientLegalCosts-additionalProceedings-incurredCosts' = 'legalCostsSwitchButtonContainer-clientLegalCosts-additionalProceedings-incurredCosts',
  'estimatedCosts-opposingPartyLegalCosts-firstProceedings' = 'estimatedCosts-opposingPartyLegalCosts-firstProceedings',
  'recoverableCosts-opposingPartyLegalCosts-firstProceedings' = 'recoverableCosts-opposingPartyLegalCosts-firstProceedings',
  'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-firstProceedings-recoverableCosts' = 'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-firstProceedings-recoverableCosts',
  'incurredCosts-opposingPartyLegalCosts-firstProceedings' = 'incurredCosts-opposingPartyLegalCosts-firstProceedings',
  'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-firstProceedings-incurredCosts' = 'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-firstProceedings-incurredCosts',
  'estimatedCosts-opposingPartyLegalCosts-additionalProceedings' = 'estimatedCosts-opposingPartyLegalCosts-additionalProceedings',
  'recoverableCosts-opposingPartyLegalCosts-additionalProceedings' = 'recoverableCosts-opposingPartyLegalCosts-additionalProceedings',
  'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-additionalProceedings-recoverableCosts' = 'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-additionalProceedings-recoverableCosts',
  'incurredCosts-opposingPartyLegalCosts-additionalProceedings' = 'incurredCosts-opposingPartyLegalCosts-additionalProceedings',
  'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-additionalProceedings-incurredCosts' = 'legalCostsSwitchButtonContainer-opposingPartyLegalCosts-additionalProceedings-incurredCosts',
  legalFeesExcludeIncludeButton = 'legalFeesExcludeIncludeButton',
}

export enum IdsOfLegalCostsDistribution {
  addRemoveSegmentContainer = 'addRemoveSegmentContainer',
  legalFeesReangeSlider = 'legalFeesReangeSlider',
  linearDistributionButton = 'linearDistributionButton',
  resetDistributionButton = 'resetDistributionButton',
  resetRangesButton = 'resetRangesButton',
  rangeBarHandle = 'rangeBarHandle',
  rangesBarSegment = 'rangesBarSegment',
  rangeBarAmountText = 'rangeBarAmountText',
}

export enum IdsOfAdvanced {
  bankruptcyAddRemoveButton = 'bankruptcyAddRemoveButton',
  bankruptcyPercentage = 'bankruptcyPercentage',
}

export enum SideMenuPages {
  MyCases = 'My Cases',
  Academy = 'Academy',
  Settings = 'Settings',
}

export enum TutorialTabs {
  Tutorials = 'Tutorials',
  UserManual = 'User Manual',
}

export enum SettingsTabs {
  AccountDetails = 'Account Details',
  Preferences = 'Preferences',
  Subscription = 'Subscription',
  Users = 'Users',
}

export enum VideoPostName {
  proceedings = 'proceedings',
  claimsSingleClaim = 'claims-single-claim',
  claimsLegalTree = 'claims-legal-tree',
  claims = 'claims',
  legalCosts = 'legal-costs',
  legalCostsDistribution = 'legal-costs-distribution',
  enforcement = 'enforcement',
  resultsExecutiveSummary = 'results-executive-summary',
  resultsGraphs = 'results-graphs',
  resultsDetailedResults = 'results-detailed-results',
  resultsSettlement = 'results-settlement',
}

export enum UserRole {
  EPEROTO_ADMIN = 'Eperoto Admin',
  GLOBAL_ADMIN = 'Organisation Global Admin',
  COMPANY_ADMIN = 'Organisation Admin',
  SIMPLE_USER = 'Simple User',
  APP_VIEWER = 'Viewer',
}

export type UserRoleKeys =
  | 'EPEROTO_ADMIN'
  | 'GLOBAL_ADMIN'
  | 'COMPANY_ADMIN'
  | 'SIMPLE_USER'
  | 'APP_VIEWER'

export const userRoleStringToKeyString = (value: string) => {
  switch (value) {
    case 'Eperoto Admin':
      return 'EPEROTO_ADMIN'
    case 'Organisation Global Admin':
      return 'GLOBAL_ADMIN'
    case 'Organisation Admin':
      return 'COMPANY_ADMIN'
    case 'Simple User':
      return 'SIMPLE_USER'
    case 'Viewer':
      return 'APP_VIEWER'
    default:
      return ''
  }
}

export const userRoleFromString = (value: string) => {
  switch (value) {
    case 'Eperoto Admin':
      return UserRole.EPEROTO_ADMIN
    case 'Organisation Global Admin':
      return UserRole.GLOBAL_ADMIN
    case 'Organisation Admin':
      return UserRole.COMPANY_ADMIN
    case 'Simple User':
      return UserRole.SIMPLE_USER
    case 'Viewer':
      return UserRole.APP_VIEWER
    default:
      return UserRole.APP_VIEWER
  }
}

export enum AccessRole {
  ADMIN = 'Admin',
  OWNER = 'Owner',
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
  NONE = 'None',
}

export type AccessRoleKeys = 'ADMIN' | 'OWNER' | 'EDITOR' | 'VIEWER' | 'NONE'

export enum AllowedAccessRole {
  OWNER = 'Owner',
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
  NONE = 'Select Role',
}

export const AllowedAccessRoleFromString = (
  value: string,
): AllowedAccessRole => {
  switch (value) {
    case 'Owner':
      return AllowedAccessRole.OWNER
    case 'Editor':
      return AllowedAccessRole.EDITOR
    case 'Viewer':
      return AllowedAccessRole.VIEWER
    default:
      return AllowedAccessRole.NONE
  }
}

export const AllowedAccessRoleToKey = (value: AllowedAccessRole): string => {
  switch (value) {
    case AllowedAccessRole.OWNER:
      return 'OWNER'
    case AllowedAccessRole.EDITOR:
      return 'EDITOR'
    case AllowedAccessRole.VIEWER:
      return 'VIEWER'
    case AllowedAccessRole.NONE:
      return 'NONE'
  }
}

export enum DateFormatOption {
  MM_DD_YYYY = 'MM/DD/YYYY',
  DD_MM_YYYY = 'DD/MM/YYYY',
  YYYY_MM_DD = 'YYYY/MM/DD',
}

export const dateFormatOptionFromString = (value: string) => {
  switch (value) {
    case 'DD/MM/YYYY':
      return DateFormatOption.DD_MM_YYYY
    case 'MM/DD/YYYY':
      return DateFormatOption.MM_DD_YYYY
    case 'YYYY/MM/DD':
      return DateFormatOption.YYYY_MM_DD
  }

  return DateFormatOption.DD_MM_YYYY
}

export enum NumberFormatOption {
  SPACE_COMMA = '1 000 000,25',
  SPACE_DOT = '1 000 000.25',
  COMMA_DOT = '1,000,000.25',
  DOT_COMMA = '1.000.000,25',
}

export const numberFormatOptionFromFormat = (value: {
  thousandSeparator: string
  decimalSeparator: string
}) => {
  if (value.thousandSeparator === ' ') {
    if (value.decimalSeparator === ',') {
      return NumberFormatOption.SPACE_COMMA
    }
    if (value.decimalSeparator === '.') {
      return NumberFormatOption.SPACE_DOT
    }
  }

  if (value.thousandSeparator === ',' && value.decimalSeparator === '.') {
    return NumberFormatOption.COMMA_DOT
  }

  if (value.thousandSeparator === '.' && value.decimalSeparator === ',') {
    return NumberFormatOption.DOT_COMMA
  }

  return NumberFormatOption.SPACE_COMMA
}

//I don't put these words in the Text For App excel file
export enum PartyFormat {
  Client = 'Client',
  Opposing_Party = 'Opposing Party',
  Claimant = 'Claimant',
  Defendant = 'Defendant',
  Respondent = 'Respondent',
  Plaintif = 'Plaintiff',
  Insurer = 'Insurer',
  Owner_Member = 'Owner Member',
  Charterer_Member = 'Charterer Member',
}

export const partyFormatFromString = (
  value: string,
): keyof typeof PartyFormat | undefined => {
  switch (value) {
    case 'Client':
      return 'Client'
    case 'Opposing Party':
      return 'Opposing_Party'
    case 'Claimant':
      return 'Claimant'
    case 'Defendant':
      return 'Defendant'
    case 'Plaintiff':
      return 'Plaintif'
    case 'Insurer':
      return 'Insurer'
    case 'Respondent':
      return 'Respondent'
    case 'Owner Member':
      return 'Owner_Member'
    case 'Charterer Member':
      return 'Charterer_Member'
    default:
      return undefined
  }
}

export const getReadableValueFromPartyFormatForDropDown = (
  value: keyof typeof PartyFormat | string,
): string => {
  if (keysIn(PartyFormat).includes(value) && value !== '') {
    return PartyFormat[value as keyof typeof PartyFormat]
  }
  return 'Other (add party description)'
}

export const getReadableValueFromPartyFormatForReport = (
  value: keyof typeof PartyFormat | string,
): string => {
  if (keysIn(PartyFormat).includes(value) && value !== '') {
    return PartyFormat[value as keyof typeof PartyFormat]
  }
  return value
}

export enum ActionMessage {
  downloadingImage = 'Downloading Image',
  downloadingExcel = 'Downloading Results',
  downloadFailed = 'Download Failed',
}

export enum FeaturesFromBackend {
  share_case = 'SHARE_CASE',
  use_recordings = 'USE_RECORDINGS',
  use_logs = 'USE_LOGS',
  use_sms = 'USE_SMS',
  use_statistical_model = 'USE_STATISTICAL_MODEL',
  use_whitelabel = 'USE_WHITELABEL',
  use_microsoft_sso = 'USE_MICROSOFT_SSO',
}

export enum ClaimsPreviewStyle {
  tiles = 'tiles',
  list = 'list',
  expanded = 'expanded',
}

type ClaimsPreviewStyleString = `${ClaimsPreviewStyle}`

export const claimsPreviewStyleFromString = (
  value: string,
): ClaimsPreviewStyle => {
  return ClaimsPreviewStyle[value as ClaimsPreviewStyleString]
}

export enum LegalCostsSwitchButtonType {
  currency = 'currency',
  percentage = 'percentage',
}

export enum LegalCostsParty {
  clientLegalCosts = 'clientLegalCosts',
  opposingPartyLegalCosts = 'opposingPartyLegalCosts',
}

export enum LegalCostsProceedings {
  firstProceedings = 'firstProceedings',
  additionalProceedings = 'additionalProceedings',
}

export enum LegalCostInputType {
  estimatedCosts = 'estimatedCosts',
  recoverableCosts = 'recoverableCosts',
  incurredCosts = 'incurredCosts',
}

export enum InterestViewOption {
  noInterest = 'No Interest',
  interest1st = 'Interest 1st',
  interest2nd = 'Interest 2nd',
}
