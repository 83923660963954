import React, { CSSProperties, useState, useEffect, useRef } from 'react'
import { NumericFormat } from 'react-number-format'
import { useRecoilState, useRecoilValue } from 'recoil'
import { formattedStringToNum } from '../../../services/formatNum'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { viewerState } from '../../../states/ViewerState'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import down from '../down.svg'
import '../input.css'
import { recoveryModeState } from '../../../states/RecoveryModeState'

type Props = {
  id: string
  name: string
  value: number | undefined
  className?: string
  onChange?: (e: any) => void
  label?: string
  maxNumberOfDecimals?: number
  maxValue: number | undefined
  disabled?: boolean
  suffix?: string
  prefix?: string
  suffixImg?: any
  helperText?: string
  smallField?: boolean
  error?: boolean
  errorMessage?: string
  placeHolderMaxWidth?: string
  width: number
  tagcolor?: string
  borderColor?: string
  backgroundColor?: string
  boldBorder?: boolean
  onClick?: () => void
  onFocus?: () => void
  localStyle?: CSSProperties
  suffixStyle?: CSSProperties
  noButton?: boolean
  tabIndex?: number
  autoComplete?: boolean
  optionsList: string[]
  placeholder?: string
  inTree?: boolean
  treeStyle?: CSSProperties
  noFormat?: boolean
  alwaysShrink?: boolean
  labelTextAttribute: `label-${number}`
  errorTextAttribute: `error-${number}`
  disabledMessageTextAttribute?: `message-${number}`
  disabledMessage?: string
}

const defaultProps = {
  tagcolor: '#979797',
  readOnly: false,
  suffix: '',
  suffixImg: false,
  label: '',
  helperText: '',
  smallField: false,
  error: false,
  noButton: false,
  errorMessage: '',
  width: '200px',
  placeHolderMaxWidth: '100%',
  borderColor: '#818181',
  backgroundColor: '#fff',
  boldBorder: false,
}

export default function InputNumAutocomplete(givenProps: Props) {
  const props: Props = {
    ...defaultProps,
    ...givenProps,
    value: givenProps.value,
  }
  const labelShrink = props.alwaysShrink
  const [selected, setSelected] = useState(false)
  const [showDropDown, setShowDropDown] = useState(false)
  const user = useRecoilValue(userState)
  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const [localValue, setLocalValue] = useState<number | undefined>(props.value)
  const [changeValue, setChangeValue] = useState<boolean>(false)
  const timer = useRef<NodeJS.Timeout | undefined>(undefined)

  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  useEffect(() => {
    document.addEventListener('click', closeDropDown)
    return () => {
      document.removeEventListener('click', closeDropDown)
    }

    // eslint-disable-next-line
  }, [showDropDown])

  const closeDropDown = (e: any) => {
    if (showDropDown && !e.target.id.includes(props.id)) {
      setShowDropDown(false)
    }
  }

  const handleChange = (newValue: number | undefined) => {
    if (showDropDown) {
      setShowDropDown(false)
    }

    setLocalValue(newValue)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setChangeValue(true)
    }, 200)
  }

  useEffect(() => {
    if (changeValue && localValue !== props.value && props.onChange) {
      props.onChange({
        target: { value: localValue, id: props.id, name: props.name },
      })
    }

    setChangeValue(false)
    // eslint-disable-next-line
  }, [changeValue])

  useEffect(() => {
    setLocalValue(props.value)
  }, [props.value])

  const onBlur = () => {
    setSelected(false)

    if (localValue !== props.value && props.onChange) {
      props.onChange({
        target: { value: localValue, id: props.id, name: props.name },
      })
    }
  }

  const handleDropDown = () => {
    if (!showDropDown) {
      document.getElementById(props.id)!.focus()
      setShowDropDown(true)
    } else {
      setShowDropDown(false)
    }
  }

  const mainDivClassName = props.inTree
    ? 'inputNumTree'
    : `input ${props.optionsList ? 'dataList' : ''} ${
        props.disabled ? 'inputNum-RO' : 'inputNum'
      } ${props.boldBorder ? 'boldBorder' : ''}
    `

  const mainLabelClassName = `
    input-label
    ${props.error ? 'input-error' : ''}
    ${props.smallField ? 'input-smallField' : ''}
    ${props.className ?? ''}
  `

  const mainLabelClassNameTree = `
    inputNum-label
    ${props.error ? 'inputNum-error' : ''}
    ${props.smallField ? 'inputNum-smallField' : ''}
    ${props.className ?? ''}
  `

  const divLabelClassName = `input-label-text
  input-label-text-${labelShrink ? 'label' : 'placeholder'} ${
    props.smallField ? 'smallfield-label-num' : ''
  }
${selected ? 'input-label-selected' : ''}
`

  const pLabelStyle = labelShrink
    ? props.borderColor
      ? {
          maxWidth: props.width,
          color: props.error ? '#b00020' : props.tagcolor,
        }
      : {
          maxWidth: props.width,
          color: props.error ? '#b00020' : props.tagcolor,
        }
    : props.borderColor
    ? {
        maxWidth: `calc(${props.width} - 30px)`,
        color: props.borderColor,
      }
    : { maxWidth: `calc(${props.width} - 30px)` }

  const pLabelStyleTree = labelShrink
    ? { maxWidth: props.width, color: props.error ? '#b00020' : props.tagcolor }
    : { maxWidth: props.placeHolderMaxWidth }

  const inputClassName = `input-inputfield 
    ${selected && !props.disabled ? 'input-inputfield-selected' : ''} 
    ${props.className ?? ''}
    ${props.noButton ? 'input-noButton' : ''}
    ${props.smallField ? 'smallfield' : ''}
  `

  const inputClassNameTree = `inputNum-inputfield ${
    selected ? 'inputNum-inputfield-selected' : ''
  } 
    ${props.className ? props.className : ''}
    ${props.smallField ? 'smallfield' : ''}
    ${props.noButton ? 'inputNum-noButton' : ''}
  `

  const suffix = props.inTree ? (
    <p id={`${props.id}-suffix`} className="inputNum-suffix">
      {props.suffix}
    </p>
  ) : (
    <div
      id={`${props.id}-suffixInput`}
      className={`input-suffix 
        ${props.suffixImg ? 'suffix-image' : ''}
      `}
      onClick={props.optionsList ? handleDropDown : undefined}
      style={{ cursor: props.disabled ? 'not-allowed' : 'default' }}
    >
      <div
        id={`${props.id}-suffixContent`}
        className={
          labelShrink
            ? `input-suffix-content ${
                props.smallField ? 'input-suffix-content-smallfield' : ''
              }`
            : 'suffix-hidden'
        }
        style={{ cursor: props.disabled ? 'not-allowed' : 'default' }}
      >
        {props.suffixImg ? (
          <img
            id={`${props.id}-suffixImg`}
            src={props.suffixImg}
            className="suffixImg"
            alt="suffixImg"
            style={{ cursor: props.disabled ? 'not-allowed' : 'default' }}
          />
        ) : (
          props.suffix && (
            <p
              id={`${props.id}-suffixP`}
              style={{
                ...props.suffixStyle,
                cursor: props.disabled ? 'not-allowed' : 'default',
                color: props.disabled ? 'rgba(0, 0, 0, 0.23)' : 'black',
              }}
            >
              {props.suffix}
            </p>
          )
        )}
        {props.optionsList && !props.disabled && (
          <div
            id={`${props.id}-suffixDropdown`}
            className="input-dropDown-button"
          >
            <img
              id={`${props.id}-suffixDropdownImg`}
              alt="downArrow"
              src={down}
            />
          </div>
        )}
      </div>
    </div>
  )

  const prefix = props.prefix ? (
    <div id={`${props.id}-prefix`} className="inputNum-prefix">
      <p id={`${props.id}-prefixP`} className="input-prefix-content">
        {props.prefix}
      </p>
    </div>
  ) : null

  const onClickDisabled = () => {
    if (props.disabled) {
      const element = document.getElementById(`${props.id}-outerDiv`)
      const viewportOffset = element!.getBoundingClientRect()

      let top = viewportOffset.top - 15
      const left = viewportOffset.left + 50
      const h = window.innerHeight
      if (h - top < 200) {
        top -= 130
      }

      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
        setDisabledMessageCoordinates([top, left])
      }
    }
  }

  return (
    <>
      <div
        id={`${props.id}-outerDiv`}
        className={mainDivClassName}
        onMouseEnter={() => setSelected(true)}
        onMouseLeave={() => setSelected(false)}
        onClick={onClickDisabled}
        style={props.disabled ? { cursor: 'not-allowed' } : {}}
      >
        <label
          id={`${props.id}-label`}
          className={props.inTree ? mainLabelClassNameTree : mainLabelClassName}
          data-textattribute={props.labelTextAttribute}
        >
          {props.label && (
            <div id={`${props.id}-inLabel`} className={divLabelClassName}>
              <p
                id={`${props.id}-inLabelP`}
                className={
                  props.inTree ? 'inputNum-label-text-p' : 'input-label-text-p'
                }
                style={props.inTree ? pLabelStyleTree : pLabelStyle}
                data-openreplay-obscured
              >
                {props.label}
              </p>
              {labelShrink && (
                <div
                  id={`${props.id}-labelShrink`}
                  className={`input-label-background ${
                    props.smallField ? 'smallfield-label-background' : ''
                  }`}
                  style={{
                    backgroundColor: props.backgroundColor,
                    cursor: props.disabled ? 'not-allowed' : 'default',
                  }}
                ></div>
              )}
            </div>
          )}
          <div
            className={props.inTree ? 'inputNum-container' : 'input-container'}
            style={{ width: props.width }}
            id={`${props.id}-container`}
          >
            {prefix}

            <NumericFormat
              name={`numberFormat${props.name}`}
              thousandSeparator={
                props.noFormat
                  ? ''
                  : user.settings.formats.numberFormat.thousandSeparator
              }
              allowNegative={false}
              decimalSeparator={
                user.settings.formats.numberFormat.decimalSeparator
              }
              allowedDecimalSeparators={[',', '.']}
              onValueChange={(value, sourceInfo) => {
                // Prevents running handleChange from undo/redo
                if (sourceInfo.source === 'event') {
                  if (
                    sourceInfo.event &&
                    ((sourceInfo.event.nativeEvent as any).inputType ===
                      'deleteContentBackward' ||
                      (sourceInfo.event.nativeEvent as any).inputType ===
                        'deleteContentForward') &&
                    ((value.floatValue !== undefined &&
                      props.maxValue !== undefined &&
                      value.floatValue > props.maxValue) ||
                      props.maxValue === undefined)
                  ) {
                    handleChange(props.maxValue)
                  } else {
                    handleChange(value.floatValue)
                  }
                }
              }}
              data-textattribute={props.labelTextAttribute}
              id={props.id}
              value={localValue ?? ''}
              onClick={props.onClick}
              className={props.inTree ? inputClassNameTree : inputClassName}
              style={
                props.treeStyle ?? {
                  borderColor: props.error ? '#b00020' : props.borderColor,
                  backgroundColor: props.backgroundColor,
                  color: props.disabled ? 'rgba(0, 0, 0, 0.23)' : 'black',
                  paddingLeft: props.prefix
                    ? selected
                      ? '54px'
                      : '55px'
                    : selected
                    ? '13px'
                    : '14px',
                  ...props.localStyle,
                }
              }
              isAllowed={(values) => {
                const { floatValue } = values

                if (
                  floatValue !== undefined &&
                  localValue !== undefined &&
                  localValue > floatValue
                ) {
                  return true
                }

                return (
                  floatValue === undefined ||
                  (props.maxValue !== undefined &&
                    floatValue >= 0 &&
                    floatValue <= props.maxValue)
                )
              }}
              readOnly={props.disabled}
              disabled={props.disabled}
              autoComplete={props.autoComplete ? 'on' : 'off'}
              placeholder={props.placeholder}
              decimalScale={props.maxNumberOfDecimals}
              onFocus={() => {
                setSelected(true)
                if (props.onFocus) {
                  props.onFocus()
                }
              }}
              onBlur={onBlur}
            />
            {suffix}
          </div>
          {props.optionsList && showDropDown && !props.disabled && (
            <div
              id={`${props.id}-dropdownContainer`}
              className="input-dropdown-container"
              style={{ width: (props.width! as number) - 2 }}
              data-openreplay-obscured
            >
              <ul
                id={`${props.id}-dropdownList`}
                className="input-dropdown"
                style={{
                  backgroundColor: props.backgroundColor,
                  borderColor: props.error
                    ? '#b00020'
                    : props.borderColor === '#818181'
                    ? '#2c79f7'
                    : props.borderColor,
                  borderWidth: props.boldBorder ? '3px' : '2px',
                }}
              >
                {props.optionsList
                  .map((num) => ({
                    value: formattedStringToNum(num, user.settings),
                    title: num,
                  }))
                  .map((option, index) => (
                    <li
                      key={index}
                      value={option.value}
                      onMouseDown={() => handleChange(option.value)}
                      className={props.className}
                      id={`${props.id}-${index}`}
                    >
                      {option.title}
                    </li>
                  ))}
              </ul>
              <div
                id={`${props.id}-dropdownBackground`}
                className="input-dropdown-background"
              ></div>
            </div>
          )}
          <div
            id={`${props.id}-helper`}
            className={
              props.inTree ? 'inputNum-helper-text' : 'input-helper-text'
            }
          >
            {props.errorMessage && props.error ? (
              <p
                id={`${props.id}-errorHelper`}
                className={
                  props.inTree
                    ? 'inputNum-error-message'
                    : 'input-error-message'
                }
                data-textattribute={props.errorTextAttribute}
              >
                {props.errorMessage}
              </p>
            ) : (
              <p
                id={`${props.id}-normalHelper`}
                className={
                  props.error && props.inTree ? 'inputNum-text-error' : ''
                }
              >
                {props.helperText}
              </p>
            )}
          </div>
        </label>
        {props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={onClickDisabled}
          ></div>
        )}
      </div>
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={props.disabledMessageTextAttribute}
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
        />
      )}
    </>
  )
}
