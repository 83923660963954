import React from 'react'

import tickedImg from '../../../../../../../../resources/images/197-selected_tree.svg'
import unTickedImg from '../../../../../../../../resources/images/196-select_tree.svg'

import { NodeId } from '../../../../../../../../models/treeModels/treeTypes'
import { getText } from '../../../../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'

type Props = {
  nodeId: NodeId
  treeIndex: number
  nodeSelected: boolean
  handleSelectNodeClick: (e: any) => void
}

export default function SelectNodeButton(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div
        className={
          props.nodeSelected
            ? 'selectNodeContainer selected'
            : 'selectNodeContainer'
        }
        id={`selectNodeContainer-${props.treeIndex}_${props.nodeId}`}
        onClick={props.handleSelectNodeClick}
      >
        <div
          className="selectNodeTextContainer"
          id={`selectNodeTextContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <p
            className="selectNodeText"
            id={`selectNodeText-${props.treeIndex}_${props.nodeId}`}
            data-textattribute={
              props.nodeSelected ? 'button-122' : 'button-123'
            }
          >
            {props.nodeSelected
              ? getText('button-122', user.settings)
              : getText('button-123', user.settings)}
          </p>
        </div>
        <div
          className="selectNodeImgContainer"
          id={`selectNodeImgContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <img
            src={props.nodeSelected ? tickedImg : unTickedImg}
            alt="tickedImg"
            className="selectNodeImg"
            id={`selectNodeImg-${props.treeIndex}_${props.nodeId}`}
          />
        </div>
      </div>
    </>
  )
}
