import React, { useState } from 'react'
import copyLink from '../../../resources/images/155-copyLink.svg'
import smallCopy from '../../../resources/images/162-smallCopy.svg'
import tickedImg from '../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../resources/images/018-untickedGrayBlue.svg'
import CircularProgress from '@mui/material/CircularProgress'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  onDone: () => void
  backupCodes: string[]
  loading: boolean
  savedBackUpCodesFailed: string | undefined
  withoutSave?: boolean
  forSettings?: boolean
}

const BackupCodes = (props: Props) => {
  const user = useRecoilValue(userState)

  const [selected, setSelected] = useState(props.withoutSave)
  const [copied, setCopied] = useState(false)

  function handleClickOnShowPasswordTick() {
    setSelected(!selected)
  }

  return (
    <>
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="backUpCodesTitle"
          data-textattribute="title-107"
        >
          {getText('title-107', user.settings)}
        </h2>
        <p
          className="instructions"
          id="backUpCodesInstructions"
          data-textattribute="description-80"
        >
          {getText('description-80', user.settings)}
        </p>
        {props.savedBackUpCodesFailed !== undefined ? (
          <p className="errorClass">{props.savedBackUpCodesFailed}</p>
        ) : null}
      </div>
      <div className="inputsContainer">
        <div className="inputsRowContainer">
          <div className="inputsColumnContainer">
            {props.backupCodes.map((code) => (
              <p
                className="lettersContainer"
                style={{ fontSize: '16px', margin: '0px 0px 15px 0px' }}
                key={code}
                data-openreplay-obscured
              >
                {code}
              </p>
            ))}
          </div>
          <button
            className={`copyButton`}
            onClick={async () => {
              await navigator.clipboard.writeText(props.backupCodes.join('\n'))
              setCopied(true)
            }}
            id="copyBackUpCodesButton"
            data-textattribute={copied ? 'button-47' : 'button-48'}
          >
            {copied ? (
              <img className="copyImage" src={smallCopy} alt="copyImage" />
            ) : (
              <img className="copyImage" src={copyLink} alt="copyImage" />
            )}
            {copied
              ? getText('button-47', user.settings)
              : getText('button-48', user.settings)}
          </button>
        </div>
      </div>
      <div className="submitButtonContainer">
        {!props.withoutSave && (
          <div className="showPassword">
            <img
              className={selected ? 'hiddenTick' : 'visibleTick'}
              id="copyUnticked"
              src={unTickedImg}
              alt="untickedImg"
              onClick={handleClickOnShowPasswordTick}
            />
            <img
              className={selected ? 'visibleTick' : 'hiddenTick'}
              id="copyTicked"
              src={tickedImg}
              alt="tickedImg"
              onClick={handleClickOnShowPasswordTick}
            />
            <p
              className="tickText"
              onClick={handleClickOnShowPasswordTick}
              id="backUpCodestickText"
              data-textattribute="submenu-16"
            >
              {getText('submenu-16', user.settings)}
            </p>
          </div>
        )}
        <button
          className={`submitButton ${selected ? '' : 'disabledButton'}`}
          onClick={selected && !props.loading ? props.onDone : undefined}
          id="submitBackUpCodes"
          data-textattribute="button-49"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : props.forSettings ? (
            'BACK TO SETTINGS'
          ) : (
            getText('button-49', user.settings)
          )}
        </button>
      </div>
    </>
  )
}

export default BackupCodes
