import { useEffect, useRef } from 'react'
import { microsoftSsoGetUser } from '../../../services/requests'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { User } from '../../../models/user'
import { logActivity } from '../../../services/commonFunctions'
import { useRecoilState } from 'recoil'
import { userState } from '../../../states/UserState'
import { AnimationDots } from '../../../Components/Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../../services/constants'
import logo from '../../../resources/images/Eperoto-logo-blue.svg'
import { validateMicrosoftCode } from '../../../services/sso_functions'
import { getText } from '../../../services/textFunctions'

const MicrosoftLoginRedirectPage = () => {
  const initRequest = useRef(false)
  const searchParams = useSearchParams()[0]
  const navigate = useNavigate()
  const [user, setUser] = useRecoilState(userState)
  console.log(
    'MicrosoftLoginRedirectPage Component is shown, redirection from microsoft works',
  )

  useEffect(() => {
    if (!initRequest.current) {
      let microsoft_code = searchParams.get('code')
      let validate_microsoft_code = validateMicrosoftCode(microsoft_code)

      if (validate_microsoft_code) {
        console.log('Microsoft code validated')
        microsoftSsoGetUser(searchParams.get('code') ?? '').then((res) => {
          const redirectUrlFromLocalStorage =
            localStorage.getItem('redirectUrl')
          if (!('errorCode' in res)) {
            const newUser = User.UserFromDB(res.data)
            setUser(newUser)

            //Mixpanel 1
            logActivity(false, 'Logged in via Microsoft SSO')
            setTimeout(() => {
              if (redirectUrlFromLocalStorage) {
                navigate('/' + redirectUrlFromLocalStorage)
              } else {
                navigate('/')
              }
            }, 1000)
          } else {
            if (
              res.message &&
              res.message.unauthorized_user &&
              res.message.unauthorized_user.length > 0
            ) {
              console.log('logout happened')
              console.log(res.message.unauthorized_user)
              setUser(User.EmptyUserMicrosoftSSO(res.message.unauthorized_user))
              if (redirectUrlFromLocalStorage) {
                navigate('/' + redirectUrlFromLocalStorage)
              } else {
                navigate('/')
              }
            } else {
              setUser(User.EmptyUser())
              setTimeout(() => {
                if (redirectUrlFromLocalStorage) {
                  navigate('/' + redirectUrlFromLocalStorage)
                } else {
                  navigate('/')
                }
              }, 1000)
            }
          }
        })
        initRequest.current = true
      } else {
        console.log('microsoft_code could not be validated')
        navigate('/')
      }
    }

    // eslint-disable-next-line
  }, [])

  return (
    <div className="mainContainer">
      <div className="logoContainer">
        <img className="logoImg" src={logo} alt="Eperoto" />
      </div>
      <div className="messageContainer">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <p className="messageText" data-textattribute="message-130">
            {getText('message-130', user.settings)}
          </p>
          <AnimationDots speed={ANIMATION_DOTS_SPEED} />
        </div>
      </div>
    </div>
  )
}

export default MicrosoftLoginRedirectPage
