import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { User } from '../../../../../models/user'
import {
  findHandlingErrorState,
  getUserRoleDescription,
  logActivity,
} from '../../../../../services/commonFunctions'
import { updateMyInfo } from '../../../../../services/requests'
import { getText } from '../../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { userState } from '../../../../../states/UserState'
import SettingInfoRow from '../../components/SettingInfoRow'
import SettingInputRow from './SettingInputRow'

type Props = {
  hoverElement: string
  setHoverElement: (value: string) => void
}

const PersonalDetails = (props: Props) => {
  const [user, setUser] = useRecoilState(userState)
  const [loading, setLoading] = useState('')
  const [showSaved, setShowSaved] = useState('')
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  const onSave = (id: string, value: string) => {
    if (loading === '') {
      setLoading(id)
      const body =
        id === 'firstName'
          ? { first_name: value.trim() }
          : { last_name: value.trim() }

      updateMyInfo(body).then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'updateMyInfo'),
        )

        if (!('errorCode' in res)) {
          const newUser = User.UserFromDB(res.data)
          setUser(newUser)
          setShowSaved(id)
          setTimeout(() => {
            setShowSaved('')
          }, 2500)

          //Mixpanel 92
          logActivity(
            false,
            id === 'firstName'
              ? 'Changed the first name in Account Details'
              : 'Changed the last name in Account Details',
          )
        }

        setLoading('')
      })
    }
  }

  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id="personalDetailsTitle"
        data-textattribute="title-175"
      >
        {getText('title-175', user.settings)}
      </h1>
      <SettingInputRow
        id={'firstName'}
        originalValue={user.firstName}
        loading={loading === 'firstName'}
        showSaved={showSaved === 'firstName'}
        errorText={getText('error-72', user.settings)}
        errorTextAttribute={'error-72'}
        labelText={getText('label-68', user.settings)}
        labelTextAttribute={'label-68'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1001}
        onSave={onSave}
      />
      <SettingInputRow
        id={'lastName'}
        originalValue={user.lastName}
        loading={loading === 'lastName'}
        showSaved={showSaved === 'lastName'}
        errorText={getText('error-73', user.settings)}
        errorTextAttribute={'error-73'}
        labelText={getText('label-69', user.settings)}
        labelTextAttribute={'label-69'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1002}
        onSave={onSave}
      />
      <SettingInfoRow
        id="userRoleInfo"
        descriptionText={getText('description-112', user.settings)}
        descriptionTextAttribute="description-112"
        valueText={user.role.toString()}
        withColor
        isFirst
        hoverElement={props.hoverElement}
        setHoverElement={props.setHoverElement}
        //pass getText()
        infoButtonText={getText(
          getUserRoleDescription(user.role),
          user.settings,
        )}
        infoButtonTextAttribute={getUserRoleDescription(user.role)}
        showInfoButton
      />
    </>
  )
}

export default PersonalDetails
