import { useRecoilValue } from 'recoil'
import { fullscreenActionMessageState } from '../states/FullScreenActionMessageState'
import { getActionMessage } from '../services/textFunctions'
import { userState } from '../states/UserState'

type Props = {
  children: JSX.Element
}

const FullScreenActionMessagesHOC = (props: Props) => {
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const user = useRecoilValue(userState)

  return (
    <>
      {fullscreenActionMessage !== '' && (
        <div
          className="downloadingImageMessageBackground"
          id="downloadingImageMessage"
        >
          <div className="downloadingImageMessageContainer">
            <p className="downloadingImageMessageText">
              {getActionMessage(user.settings)[fullscreenActionMessage]}
            </p>
          </div>
        </div>
      )}
      {props.children}
    </>
  )
}

export default FullScreenActionMessagesHOC
