import React, { useEffect, useRef, useState } from 'react'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import {
  deepCloneObject,
  isJSON,
  isMac,
  logActivity,
  roundNumberTo,
} from '../../../../../../services/commonFunctions'
import {
  AccessRole,
  InterestViewOption,
  UndoRedoType,
} from '../../../../../../models/enums'
import {
  SnapshotSelectorObject,
  TabsErrors,
  TreeTableInfo,
  TreeAnalysisResults,
  TreeTablesState,
} from '../../../../../../models/generalTypes'
import { treeTablesState } from '../../../../../../states/TreeTablesState'
import { getText } from '../../../../../../services/textFunctions'
import TreeCanvas from './TreeCanvas'
import TreeTableContainer from '../treeMenusComponents/treeTable/TreeTableContainer'
import TreeSideMenu from '../treeMenusComponents/treeSideMenuComponents/TreeSideMenu'
import {
  EventId,
  NodeId,
  NodeMode,
  RootNodeType,
  TreeLines,
} from '../../../../../../models/treeModels/treeTypes'
import { TreeClaim } from '../../../../../../models/treeModels/treeClaim'
import {
  changeNodesPositionsMinMax,
  resetNodesPositions,
} from '../../../../../../services/treeFunctions/treePositioningFunctions'
import {
  changeTextAreaHeight,
  changeTreeClaim,
  findMainTreeAndOrphanTrees,
  pasteNodes,
} from '../../../../../../services/treeFunctions/treeBasicFunctions'
import TreeNameComponent from '../treeMenusComponents/treeSideMenuComponents/TreeNameComponent'
import { changeGlobalSnapshot } from '../../../../../../services/changeGlobalSnapshot'
import { userState } from '../../../../../../states/UserState'
import { nodesMovedForEditModeState } from '../../../../../../states/NodesMovedForEditModeState'
import TreeSelectionBox from './TreeSelectionBox'
import { allowShortcutsState } from '../../../../../../states/AllowShortcutsState'
import { v4 as uuid } from 'uuid'
import { TreeNodeClass } from '../../../../../../models/treeModels/treeNode'
import { TreeEventClass } from '../../../../../../models/treeModels/treeEvent'
import { EventDetails } from '../../../../../../models/treeModels/eventDetails'
import { freemiumState } from '../../../../../../states/FreemiumState'

type Props = {
  treeIndex: number
  currentTreeTableInfo: TreeTableInfo
  treeDirectCalculation: (
    treeIndex: number,
    message: string,
  ) => Promise<TreeAnalysisResults[][] | undefined>
  checkIfTreeHasErrors: (treeIndex: number) => boolean
  tabsErrors: TabsErrors | undefined
  ownRole: AccessRole
  treeWarningsIndex: number
  addCheckedIndex: (value: number) => void
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  errors: string[]
}

export default function TreeComponent(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const treeClaim = scenarioSnapshot.currentSnapshot.claims[
    props.treeIndex
  ] as TreeClaim

  const [activeRow, setActiveRow] = useState<number | undefined>(0) // the index of the row of the results table that is active -- integer >= 0
  const [selectedPath, setSelectedPath] = useState<number[]>([]) // an array with the names of the final children that form a selected path -- array of str
  const [resetActivePath, setResetActivePath] = useState(false) //a boolean that is used to activate/deactivate the green path -- bool
  //const [loadingTree, setLoadingTree] = useState(true)
  const [treeName, setTreeName] = useState(treeClaim.name)
  const [fixed, setFixed] = useState(false)
  const [renderLines, setRenderLines] = useState(false)
  const [mainTree, setMainTree] = useState<NodeId[]>([])
  const [orphanTrees, setOrphanTrees] = useState<NodeId[][]>([])
  const nodesUnderZeroProbability: NodeId[] = []
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const [openOrphanNodesWarningPopUp, setOpenOrphanNodesWarningPopUp] =
    useState(false)
  const [openZeroProbabilityWarningPopUp, setOpenZeroProbabilityWarningPopUp] =
    useState(false)
  const [editMode, setEditMode] = useState<NodeId | undefined>(undefined)
  const [nodesMovedForEditMode, setNodesMovedForEditMode] = useRecoilState(
    nodesMovedForEditModeState,
  )
  const [scrollWidth, setScrollWidth] = useState(
    Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.body.clientWidth,
      document.documentElement.clientWidth,
    ),
  )
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const canvasRef = useRef<HTMLDivElement>(null)
  const [selectionBox, setSelectionBox] = useState<
    | {
        startX: number
        startY: number
        width: number
        height: number
      }
    | undefined
  >(undefined)

  const [selectedNodes, setSelectedNodes] = useState<NodeId[]>([])
  const [shiftSelectedNodes, setShiftSelectedNodes] = useState<NodeId[]>([])

  useEffect(() => {
    let tempTreeDetails = deepCloneObject(treeClaim.treeDetails)
    const [, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeDetails)
    if (tempMainTree.includes('node123')) return
    setMainTree(tempMainTree)
    setOrphanTrees(tempOrphanTrees)
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    JSON.stringify(
      Object.keys(treeClaim.treeDetails.events).map(
        (eventId) =>
          treeClaim.treeDetails.events[eventId as EventId].childrenNodes,
      ),
    ),
    props.treeIndex,
  ])

  // nodesUnderZeroProbability
  //We decided to turn this feature off. I am keeping the code here in case we decide to bring it back

  // useEffect(() => {
  //  if(mainTree.length === 0) return
  // let eventsWithZeroProbability = findZeroProbabilityEventsInMainTree(
  //   treeClaim.treeDetails,
  //   orphanTrees.flat(),
  // )
  // let tempNodesUnderZeroProbability: NodeId[] = []
  // for (let eventId of eventsWithZeroProbability) {
  //   tempNodesUnderZeroProbability = tempNodesUnderZeroProbability.concat(
  //     findChildrenNodesFromEvent(
  //       eventId as EventId,
  //       treeClaim.treeDetails,
  //       [],
  //       false,
  //     ),
  //   )
  // }

  // tempNodesUnderZeroProbability = [...new Set(tempNodesUnderZeroProbability)]

  // let nodesWith2Parents = []
  // for (let nodeId of tempNodesUnderZeroProbability) {
  //   if (
  //     Object.keys(treeClaim.treeDetails.nodes[nodeId].nodeSiblingIndex)
  //       .length > 1
  //   ) {
  //     nodesWith2Parents.push(nodeId)
  //   }
  // }

  // let loop = 2
  // while (loop > 0) {
  //   loop--

  //   for (let nodeId of nodesWith2Parents) {
  //     let parentEvents = findParentEvents(
  //       nodeId as NodeId,
  //       treeClaim.treeDetails,
  //     )
  //     if (parentEvents) {
  //       for (let parentEvent of parentEvents) {
  //         if (
  //           !tempNodesUnderZeroProbability.includes(
  //             treeClaim.treeDetails.events[parentEvent as EventId]
  //               .nodeOfEventId,
  //           ) &&
  //           mainTree.includes(
  //             treeClaim.treeDetails.events[parentEvent as EventId]
  //               .nodeOfEventId,
  //           ) &&
  //           !eventsWithZeroProbability.includes(parentEvent)
  //         ) {
  //           let childrenNodes = findNodesOfTreeFromRoot(
  //             nodeId as NodeId,
  //             treeClaim.treeDetails,
  //             [],
  //             [],
  //             false,
  //           )
  //           tempNodesUnderZeroProbability =
  //             tempNodesUnderZeroProbability.filter(
  //               (tempNodeId) =>
  //                 tempNodeId !== nodeId &&
  //                 !childrenNodes.includes(tempNodeId),
  //             )
  //         }
  //       }
  //     }
  //   }
  // }

  //setNodesUnderZeroProbability(tempNodesUnderZeroProbability)

  // eslint-disable-next-line
  // }, [
  // eslint-disable-next-line
  //   JSON.stringify(
  //     Object.keys(treeClaim.treeDetails.events).map(
  //       (eventId) =>
  //         treeClaim.treeDetails.events[eventId as EventId].eventDetails
  //           .probability,
  //     ),
  //   ),
  //   orphanTrees,
  // ])

  useEffect(() => {
    setOpenZeroProbabilityWarningPopUp(false)
    setOpenOrphanNodesWarningPopUp(false)
    // eslint-disable-next-line
  }, [props.treeIndex])

  useEffect(() => {
    if (
      treeClaim.nodeMode === NodeMode.minimised &&
      props.tabsErrors &&
      typeof props.tabsErrors?.Claims !== 'number' &&
      props.tabsErrors?.Claims[props.treeIndex] > 0
    ) {
      handleMinMax(NodeMode.maximised)
    }

    // eslint-disable-next-line
  }, [props.tabsErrors, openZeroProbabilityWarningPopUp])

  function handleTreeExtraChanges(
    value: any,
    targetId: string,
    undoRedoType: UndoRedoType,
  ) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: targetId,
      undoRedoType: undoRedoType,
      value: value,
      key: 'claims',
      key2: 'tree',
      claimIndex: props.treeIndex,
    }

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  useEffect(() => {
    //setLoadingTree(true)

    let tempCurrentSnapshot = scenarioSnapshot.currentSnapshot
    let tempTreeClaim = tempCurrentSnapshot.claims[props.treeIndex] as TreeClaim
    setTreeName(tempTreeClaim.name)
    /* setTimeout(() => {
      setLoadingTree(false)
    }, 200) */

    // eslint-disable-next-line
  }, [props.treeIndex])

  useEffect(() => {
    if (props.ownRole === AccessRole.VIEWER) {
      handleMinMax(NodeMode.minimised)
    }

    // eslint-disable-next-line
  }, [props.ownRole, props.treeIndex])

  useEffect(() => {
    setTreeName(treeClaim.name)
    if (treeClaim.analysisResults === false) {
      setResetActivePath(false)
    } else {
      setResetActivePath(true)
    }
    // eslint-disable-next-line
  }, [treeClaim])

  useEffect(() => {
    if (props.ownRole !== AccessRole.VIEWER) {
      document.addEventListener('keydown', pasteNodesFromClipboard)
    }

    return () => {
      document.removeEventListener('keydown', pasteNodesFromClipboard)
    }
    // eslint-disable-next-line
  }, [allowShortcuts, treeClaim])

  function pasteNodesFromClipboard(e: any) {
    if (allowShortcuts) {
      if (
        ((e.code === 'KeyV' && e.ctrlKey && !isMac()) ||
          (e.code === 'KeyV' && e.metaKey && isMac())) &&
        e.target.tagName.toLowerCase() !== 'textarea' &&
        e.target.tagName.toLowerCase() !== 'input'
      ) {
        e.preventDefault()
        handlePasteNodes()
      }
    }
  }

  function handleMinMax(nodeMode: NodeMode) {
    if (
      props.ownRole === AccessRole.VIEWER &&
      nodeMode === NodeMode.maximised
    ) {
      return
    }
    const timeoutTime = editMode && nodeMode === NodeMode.maximised ? 250 : 0
    setTimeout(() => {
      if (
        nodeMode !==
        (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
          .nodeMode
      ) {
        setRenderLines(false)
        let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
        let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
          props.treeIndex
        ] as TreeClaim
        const tempId = nodeMode === NodeMode.maximised ? 'maxiTree' : 'miniTree'
        let tempTargetId = `treeSideMenuButton-${props.treeIndex}_${tempId}`
        let tempType = UndoRedoType.button
        let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)

        if (tempNodesMovedForEditMode) {
          for (let nodeId of Object.keys(tempNodesMovedForEditMode)) {
            if (Object.keys(tempTreeClaim.treeDetails.nodes).includes(nodeId)) {
              tempTreeClaim.treeDetails.nodes[
                nodeId as NodeId
              ].properties.position =
                tempNodesMovedForEditMode[nodeId].previousPosition
            }
          }
        }

        tempTreeClaim.nodeMode = nodeMode

        tempTreeClaim.treeDetails = changeNodesPositionsMinMax(
          tempTreeClaim.nodeMode,
          tempTreeClaim.treeDetails,
        )

        tempScenarioSnapshot = changeTreeClaim(
          tempScenarioSnapshot,
          scenarioSnapshot.currentSnapshot,
          tempTreeClaim,
          tempTargetId,
          tempType,
          props.treeIndex,
          tempTreeClaim.analysisResults,
          props.ownRole,
        )
        if (nodeMode === NodeMode.maximised) {
          //Mixpanel 112 (All)
          logActivity(freemium.isFreemium, 'Maximised legal tree')
        } else if (nodeMode === NodeMode.minimised) {
          //Mixpanel 113 (All)
          logActivity(freemium.isFreemium, 'Minimised legal tree')
        }

        setScenarioSnapshot(tempScenarioSnapshot)
        setTimeout(() => {
          for (let tempNodeId of Object.keys(tempTreeClaim.treeDetails.nodes)) {
            changeTextAreaHeight(
              document.getElementById(
                `treeNodeTitleTextarea-${props.treeIndex}_${tempNodeId}`,
              )!,
            )
          }
        }, 20)
        setTimeout(() => {
          setRenderLines(true)
        }, 10)
      }
    }, timeoutTime)
  }

  function handleZoomLevel(zoomMode: 'zoomIn' | 'zoomOut') {
    let tempZoomLevel = treeClaim.zoomLevel
    const zoomMax = 1.2
    const zoomMin = 0.3
    let changeMade = false
    if (zoomMode === 'zoomIn') {
      if (tempZoomLevel < zoomMax) {
        tempZoomLevel += 0.15
        changeMade = true
        //Mixpanel 46 (All)
        logActivity(freemium.isFreemium, 'Zoomed in legal tree')
      }
    } else {
      if (tempZoomLevel > zoomMin) {
        tempZoomLevel -= 0.15
        changeMade = true
        //Mixpanel 47 (All)
        logActivity(freemium.isFreemium, 'Zoomed out legal tree')
      }
    }
    if (changeMade) {
      //setRenderLines(false)
      let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
      let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
        props.treeIndex
      ] as TreeClaim
      let tempTargetId = `treeSideMenuButton-${props.treeIndex}_${zoomMode}`
      let tempType = UndoRedoType.button

      tempTreeClaim.zoomLevel = tempZoomLevel

      tempScenarioSnapshot = changeTreeClaim(
        tempScenarioSnapshot,
        scenarioSnapshot.currentSnapshot,
        tempTreeClaim,
        tempTargetId,
        tempType,
        props.treeIndex,
        tempTreeClaim.analysisResults,
        props.ownRole,
      )

      setScenarioSnapshot(tempScenarioSnapshot)
      setTimeout(() => {
        setRenderLines(true)
      }, 100)
    }
  }
  function handleTreeLines(treeLines: TreeLines) {
    let currentTreeLines = treeClaim.treeLines

    if (treeLines === currentTreeLines) {
      return
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    let tempTargetId = `treeSideMenuButton-${props.treeIndex}_${treeLines}Line`
    let tempType = UndoRedoType.button

    tempTreeClaim.treeLines = treeLines

    if (treeLines === TreeLines.grid) {
      //Mixpanel 180 (All)
      logActivity(freemium.isFreemium, 'Changed treeLines to broken')
    } else {
      //Mixpanel 181 (All)
      logActivity(freemium.isFreemium, 'Changed treeLines to smooth')
    }

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      tempTargetId,
      tempType,
      props.treeIndex,
      tempTreeClaim.analysisResults,
      props.ownRole,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    setTimeout(() => {
      setRenderLines(true)
    }, 100)
  }

  function resetTreePosition() {
    const timeoutTime = editMode ? 250 : 0
    setTimeout(() => {
      let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
      let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
        props.treeIndex
      ] as TreeClaim
      let tempTargetId = `treeSideMenuButton-${props.treeIndex}_rearrange`
      let tempType = UndoRedoType.button

      tempTreeClaim.treeDetails = resetNodesPositions(
        tempTreeClaim.nodeMode,
        tempTreeClaim.treeDetails,
        props.treeIndex,
      )

      if (
        JSON.stringify(tempTreeClaim.treeDetails) !==
        JSON.stringify(
          (
            scenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ] as TreeClaim
          ).treeDetails,
        )
      ) {
        //setRenderLines(false)
        tempScenarioSnapshot = changeTreeClaim(
          tempScenarioSnapshot,
          scenarioSnapshot.currentSnapshot,
          tempTreeClaim,
          tempTargetId,
          tempType,
          props.treeIndex,
          tempTreeClaim.analysisResults,
          props.ownRole,
        )
        //console.log('Rearrange Change')
        //Mixpanel 114 (All)
        logActivity(freemium.isFreemium, 'Aligned legal tree')
        setScenarioSnapshot(tempScenarioSnapshot)
        setTimeout(() => {
          setRenderLines(true)
        }, 100)
      }
    }, timeoutTime)
  }

  function handleShowResultsAfterWhichTrial(
    interestViewOption: InterestViewOption,
  ) {
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].treeInterestViewOption =
        interestViewOption
      setTreeTables(tempTreeTables)
    }
  }

  function handleChangeTreeName(e: any) {
    let newName = e.target.value

    setTreeName(newName)
    handleTreeExtraChanges(newName, e.target.id, UndoRedoType.input)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
    // eslint-disable-next-line
  }, [props.treeIndex])

  const onScroll = (e: any) => {
    const topHeight =
      document.getElementById('claimsPreviewContainer') &&
      document.getElementById('tabsContainer')
        ? document.getElementById('claimsPreviewContainer')!.offsetHeight +
          document.getElementById('tabsContainer')!.offsetHeight +
          125
        : undefined
    if (window.innerWidth < 836) {
      document.getElementById(
        `tabsContainer`,
      )!.style.transform = `translateX(0px)`
    } else if (
      document.getElementById(`translateTree-${props.treeIndex}`) &&
      topHeight !== undefined &&
      topHeight - window.scrollY < 0
    ) {
      setFixed(true)
      document.getElementById(
        `translateTree-${props.treeIndex}`,
      )!.style.transform = `translateX(0px)`
    } else if (document.getElementById(`translateTree-${props.treeIndex}`)) {
      setFixed(false)
      document.getElementById(
        `translateTree-${props.treeIndex}`,
      )!.style.transform = `translateX(${window.scrollX}px)`
    }
  }

  const calculateSumOfProbs = () => {
    const results = treeTables.treeTablesInfo.find(
      (el) => el.treeId === treeClaim.id,
    )?.results
    if (results) {
      let totalProbabilityOfAllScenarios = 0
      for (let i = 0; i < results[0].length; i++) {
        totalProbabilityOfAllScenarios += results[0][i][5]
      }

      return roundNumberTo(totalProbabilityOfAllScenarios, 2)
    }

    return 0
  }

  function findWindowSize() {
    const tempScrollWidth = Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.body.clientWidth,
      document.documentElement.clientWidth,
    )
    const tempInnerWidth = window.innerWidth

    setScrollWidth(tempScrollWidth)
    setInnerWidth(tempInnerWidth)
  }
  useEffect(() => {
    window.addEventListener('resize', findWindowSize)

    return () => window.removeEventListener('resize', findWindowSize)
    // eslint-disable-next-line
  }, [window.innerWidth])

  const handleMouseDown = (e: any) => {
    if (
      (e.target.className === 'treeCanvasBackground' ||
        e.target.className === 'treeContainer' ||
        e.target.className === 'treeCanvasBackground') &&
      e.button === 0
    ) {
      const canvas = canvasRef.current!
      if (!canvas) return

      const rect = canvas.getBoundingClientRect()
      const startX = e.clientX - rect.left
      const startY = e.clientY - rect.top

      const newSelectionBox = {
        startX,
        startY,
        width: 0,
        height: 0,
      }

      setSelectionBox(newSelectionBox)
      setShiftSelectedNodes(selectedNodes)
    }
  }

  const handleMouseMove = (e: any) => {
    if (!selectionBox) return

    const canvas = canvasRef.current!
    if (!canvas) return

    const rect = canvas.getBoundingClientRect()
    const currentX = e.clientX - rect.left
    const currentY = e.clientY - rect.top

    const width = currentX - selectionBox.startX
    const height = currentY - selectionBox.startY

    const newSelectionBox = {
      startX: selectionBox.startX,
      startY: selectionBox.startY,
      width: width,
      height: height,
    }

    setSelectionBox(newSelectionBox)
    if (selectionBox !== undefined) {
      e.preventDefault()
      let nodesToSelect: NodeId[] = [...selectedNodes]

      for (let nodeId of Object.keys(treeClaim.treeDetails.nodes)) {
        let nodeX =
          treeClaim.treeDetails.nodes[nodeId as NodeId].properties.position[0]
        let nodeY =
          treeClaim.treeDetails.nodes[nodeId as NodeId].properties.position[1]
        if (selectionBox.width < 0) {
          if (
            nodeX * treeClaim.zoomLevel >=
              selectionBox!.startX + selectionBox!.width - 150 &&
            nodeX * treeClaim.zoomLevel <= selectionBox!.startX - 150
          ) {
            if (selectionBox.height < 0) {
              if (
                nodeY * treeClaim.zoomLevel <= selectionBox!.startY - 150 &&
                nodeY * treeClaim.zoomLevel >=
                  selectionBox!.startY + selectionBox!.height - 150
              ) {
                if (!selectedNodes.includes(nodeId as NodeId)) {
                  nodesToSelect.push(nodeId as NodeId)
                }
              }
            } else {
              if (
                nodeY * treeClaim.zoomLevel >= selectionBox!.startY - 150 &&
                nodeY * treeClaim.zoomLevel <=
                  selectionBox!.startY + selectionBox!.height - 150
              ) {
                if (!selectedNodes.includes(nodeId as NodeId)) {
                  nodesToSelect.push(nodeId as NodeId)
                }
              }
            }
          } else {
            if (
              nodesToSelect.includes(nodeId as NodeId) &&
              !shiftSelectedNodes.includes(nodeId as NodeId)
            ) {
              const index = nodesToSelect.indexOf(nodeId as NodeId)
              if (index > -1) {
                nodesToSelect.splice(index, 1)
              }
            }
          }
        } else {
          if (
            nodeX * treeClaim.zoomLevel <=
              selectionBox!.startX + selectionBox!.width - 150 &&
            nodeX * treeClaim.zoomLevel >= selectionBox!.startX - 150
          ) {
            if (selectionBox.height < 0) {
              if (
                nodeY * treeClaim.zoomLevel <= selectionBox!.startY - 150 &&
                nodeY * treeClaim.zoomLevel >=
                  selectionBox!.startY + selectionBox!.height - 150
              ) {
                if (!selectedNodes.includes(nodeId as NodeId)) {
                  nodesToSelect.push(nodeId as NodeId)
                }
              }
            } else {
              if (
                nodeY * treeClaim.zoomLevel >= selectionBox!.startY - 150 &&
                nodeY * treeClaim.zoomLevel <=
                  selectionBox!.startY + selectionBox!.height - 150
              ) {
                if (!selectedNodes.includes(nodeId as NodeId)) {
                  nodesToSelect.push(nodeId as NodeId)
                }
              } else {
                if (
                  nodesToSelect.includes(nodeId as NodeId) &&
                  !shiftSelectedNodes.includes(nodeId as NodeId)
                ) {
                  const index = nodesToSelect.indexOf(nodeId as NodeId)
                  if (index > -1) {
                    nodesToSelect.splice(index, 1)
                  }
                }
              }
            }
          } else {
            if (
              nodesToSelect.includes(nodeId as NodeId) &&
              !shiftSelectedNodes.includes(nodeId as NodeId)
            ) {
              const index = nodesToSelect.indexOf(nodeId as NodeId)
              if (index > -1) {
                nodesToSelect.splice(index, 1)
              }
            }
          }
        }
      }

      setSelectedNodes(nodesToSelect)
    }
  }

  const handleMouseUp = () => {
    setSelectionBox(undefined)
  }

  const handlePasteNodes = async () => {
    let clipboardText = await navigator.clipboard.readText()
    if (!clipboardText || !isJSON(clipboardText)) {
      return
    }

    const nodesData = JSON.parse(clipboardText)
    if (!nodesData.copiedEvents || !nodesData.copiedNodes) {
      return
    }

    let tempSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim

    let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)
    let newSelectedNodes: NodeId[] = []
    ;[tempTreeClaim.treeDetails, tempNodesMovedForEditMode, newSelectedNodes] =
      pasteNodes(
        nodesData.copiedNodes,
        nodesData.copiedEvents,
        nodesData.nodeMode,
        tempTreeClaim.treeDetails,
        tempTreeClaim.nodeMode,
        tempNodesMovedForEditMode,
      )

    tempSnapshot = changeTreeClaim(
      tempSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      `treeNode-${props.treeIndex}_${newSelectedNodes[0]}`,
      UndoRedoType.addRemove,
      props.treeIndex,
      tempTreeClaim.analysisResults,
      props.ownRole,
    )

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
    if (tempMainTree.includes('node123')) return
    setMainTree(tempMainTree)
    setOrphanTrees(tempOrphanTrees)

    setSelectedNodes(newSelectedNodes)
    setNodesMovedForEditMode(tempNodesMovedForEditMode)
    setScenarioSnapshot(tempSnapshot)

    //Mixpanel 179 (All)
    logActivity(freemium.isFreemium, 'Pasted tree nodes from clipboard')

    setTimeout(() => {
      setRenderLines(true)
      for (let tempNodeId of Object.keys(tempTreeClaim.treeDetails.nodes)) {
        changeTextAreaHeight(
          document.getElementById(
            `treeNodeTitleTextarea-${props.treeIndex}_${tempNodeId}`,
          )!,
        )
      }
      let pastedFirstNodeElement = document.getElementById(
        `treeNodeTitleTextarea-${props.treeIndex}_${newSelectedNodes[0]}`,
      )
      if (pastedFirstNodeElement) {
        const elementRect = pastedFirstNodeElement.getBoundingClientRect()
        const scrollLeft =
          elementRect.left +
          window.scrollX -
          window.innerWidth / 2 +
          elementRect.width / 2
        const scrollTop =
          elementRect.top +
          window.scrollY -
          window.innerHeight / 2 +
          elementRect.height / 2

        window.scrollTo({
          left: scrollLeft,
          top: scrollTop,
          behavior: 'smooth',
        })
      }
    }, 100)
  }

  const handleAddFloatingNode = async () => {
    const newNodeId = `node${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`
    const newEventId1 = `event${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`
    const newEventId2 = `event${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`

    const newNode = TreeNodeClass.defaultTreeNode(
      {},
      [0, 40],
      RootNodeType.orphanTreeRoot,
    )

    const newEvent1 = TreeEventClass.defaultTreeEvent(
      newNodeId as NodeId,
      0,
      EventDetails.defaultEventDetails(0.5),
    )
    const newEvent2 = TreeEventClass.defaultTreeEvent(
      newNodeId as NodeId,
      1,
      EventDetails.defaultEventDetails(0.5),
    )

    let tempSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim

    let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)
    let newSelectedNodes: NodeId[] = []
    ;[tempTreeClaim.treeDetails, tempNodesMovedForEditMode, newSelectedNodes] =
      pasteNodes(
        { [newNodeId]: newNode },
        { [newEventId1]: newEvent1, [newEventId2]: newEvent2 },
        tempTreeClaim.nodeMode,
        tempTreeClaim.treeDetails,
        tempTreeClaim.nodeMode,
        tempNodesMovedForEditMode,
      )

    tempSnapshot = changeTreeClaim(
      tempSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      `treeSideMenuButton-${props.treeIndex}_addFloatingNode`,
      UndoRedoType.addRemove,
      props.treeIndex,
      tempTreeClaim.analysisResults,
      props.ownRole,
    )

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
    if (tempMainTree.includes('node123')) return
    setMainTree(tempMainTree)
    setOrphanTrees(tempOrphanTrees)

    setSelectedNodes(newSelectedNodes)
    setNodesMovedForEditMode(tempNodesMovedForEditMode)
    setScenarioSnapshot(tempSnapshot)

    //Mixpanel 179 (All)
    logActivity(freemium.isFreemium, 'Pasted tree nodes from clipboard')

    setTimeout(() => {
      setRenderLines(true)
      for (let tempNodeId of Object.keys(tempTreeClaim.treeDetails.nodes)) {
        changeTextAreaHeight(
          document.getElementById(
            `treeNodeTitleTextarea-${props.treeIndex}_${tempNodeId}`,
          )!,
        )
      }
      let pastedFirstNodeElement = document.getElementById(
        `treeNodeTitleTextarea-${props.treeIndex}_${newSelectedNodes[0]}`,
      )
      if (pastedFirstNodeElement) {
        const elementRect = pastedFirstNodeElement.getBoundingClientRect()
        const scrollLeft =
          elementRect.left +
          window.scrollX -
          window.innerWidth / 2 +
          elementRect.width / 2
        const scrollTop =
          elementRect.top +
          window.scrollY -
          window.innerHeight / 2 +
          elementRect.height / 2

        window.scrollTo({
          left: scrollLeft,
          top: scrollTop,
          behavior: 'smooth',
        })
      }
    }, 100)
  }

  return (
    <>
      {window.innerWidth < 836 ? (
        <div
          className="lineForResponsiveness"
          style={{ width: 2 * scrollWidth }}
        ></div>
      ) : null}
      <div
        className="treeComponent"
        id={`treeComponent-${props.treeIndex}`}
        ref={canvasRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        {selectionBox && (
          <TreeSelectionBox
            canvasRef={canvasRef}
            selectionBox={selectionBox}
            setSelectionBox={setSelectionBox}
            fixed={false}
          />
        )}
        <div
          className="treeContainer"
          style={{ width: window.innerWidth, height: window.innerHeight }}
        >
          <TreeCanvas
            treeIndex={props.treeIndex}
            selectedPath={selectedPath}
            resetActivePath={resetActivePath}
            renderLines={renderLines}
            setRenderLines={setRenderLines}
            mainTree={mainTree}
            setMainTree={setMainTree}
            orphanTrees={orphanTrees}
            setOrphanTrees={setOrphanTrees}
            openOrphanNodesWarningPopUp={openOrphanNodesWarningPopUp}
            openZeroProbabilityWarningPopUp={openZeroProbabilityWarningPopUp}
            ownRole={props.ownRole}
            editMode={editMode}
            setEditMode={setEditMode}
            removeClaim={props.removeClaim}
            errors={props.errors}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            nodesUnderZeroProbability={nodesUnderZeroProbability}
          />
        </div>

        <div
          className="translateTree"
          id={`translateTree-${props.treeIndex}`}
          style={
            fixed
              ? {
                  position: 'fixed',
                  top: 43,
                  left: 49,
                  borderTop: 'None',
                }
              : innerWidth < 836
              ? { width: 1400, borderTop: 'None' }
              : undefined
          }
        >
          <TreeTableContainer
            fixed={fixed}
            treeIndex={props.treeIndex}
            currentTreeTableInfo={props.currentTreeTableInfo}
            calculationOfTreeArray={
              treeClaim.analysisResults
                ? treeTables.treeTablesInfo.find(
                    (res) => res.treeId === treeClaim.id,
                  )?.results
                : undefined
            }
            handleShowResultsAfterWhichTrial={handleShowResultsAfterWhichTrial}
            treeInterestViewOption={
              props.currentTreeTableInfo
                ? props.currentTreeTableInfo.treeInterestViewOption
                : InterestViewOption.interest1st
            }
            sumOfProbs={calculateSumOfProbs()}
            selectedPath={selectedPath}
            treeDirectCalculation={() => {
              props
                .treeDirectCalculation(
                  props.treeIndex,
                  //id='calculatingMessage'
                  //data-textattribute='message-61'
                  getText('message-61', user.settings),
                )
                .then((res) => {
                  if (res) {
                    if (res[0].length > 0) {
                      setSelectedPath(JSON.parse(res[0][0][0]))
                      setActiveRow(1)
                    }
                  }
                })
            }}
            setSelectedPath={setSelectedPath}
            activeRow={activeRow}
            setActiveRow={setActiveRow}
            orphanTrees={orphanTrees}
            treeDetails={treeClaim.treeDetails}
            openOrphanNodesWarningPopUp={openOrphanNodesWarningPopUp}
            setOpenOrphanNodesWarningPopUp={setOpenOrphanNodesWarningPopUp}
            openZeroProbabilityWarningPopUp={openZeroProbabilityWarningPopUp}
            setOpenZeroProbabilityWarningPopUp={
              setOpenZeroProbabilityWarningPopUp
            }
            checkIfTreeHasErrors={props.checkIfTreeHasErrors}
            treeWarningsIndex={props.treeWarningsIndex}
            addCheckedIndex={props.addCheckedIndex}
          />
          <TreeSideMenu
            treeIndex={props.treeIndex}
            treeClaim={treeClaim}
            handleMinMax={handleMinMax}
            resetTreePosition={resetTreePosition}
            handleZoomLevel={handleZoomLevel}
            handleTreeLines={handleTreeLines}
            fixed={fixed}
            ownRole={props.ownRole}
            handlePasteNodes={handlePasteNodes}
            handleAddFloatingNode={handleAddFloatingNode}
          />
          <TreeNameComponent
            handleChangeTreeName={handleChangeTreeName}
            treeName={treeName}
            treeIndex={props.treeIndex}
            fixed={fixed}
            disabled={props.ownRole === AccessRole.VIEWER}
            errors={props.errors}
          />
        </div>

        {/* {loadingTree ? <div className="overlay"></div> : null} */}

        <div className="lower-menu-background"></div>
      </div>
    </>
  )
}
