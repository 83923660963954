import { WhiteTooltip } from '../tooltip/TooltipComponent'
import downloadImageImg from '../../resources/images/215-downloadImgBlue.svg'
import downloadExcelImg from '../../resources/images/301-downloadExcel.svg'
import downloadExcelInactiveImg from '../../resources/images/302-downloadExcelInactive.svg'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { fullscreenActionMessageState } from '../../states/FullScreenActionMessageState'
import { ActionMessage } from '../../models/enums'
import * as htmlToImage from 'html-to-image'
import { freemiumState } from '../../states/FreemiumState'
import PremiumFeatureIcon from '../PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../models/freemiumEnums'
import { logActivity } from '../../services/commonFunctions'
import { exportResults } from '../../services/requests'

type Props = {
  id: string
  tooltipText: string
  tooltipTextAttribute: `tooltip-${number}`
  imageElementId: string
  saveName: string
  downloadImageType: 'tree' | 'results' | 'excel' | 'excel-inactive'
  resultsViewParty?: 'client' | 'opposing'
  snapshotId?: string
}

export default function DownloadImage(props: Props) {
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const setFullscreenActionMessage = useSetRecoilState(
    fullscreenActionMessageState,
  )

  function downloadImage() {
    if (freemium.isFreemium) {
      setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      return
    }

    setFullscreenActionMessage(ActionMessage.downloadingImage)

    setTimeout(() => {
      if (document.getElementById(props.imageElementId)) {
        //Mixpanel 1 - TODO add device info - maybe from the backend
        logActivity(false, `Downloaded Image for ${props.id}`)
        saveClaimImages().then(() => setFullscreenActionMessage(''))
      } else {
        setFullscreenActionMessage(ActionMessage.downloadFailed)
        setTimeout(() => {
          setFullscreenActionMessage('')
        }, 400)
      }
    }, 400)
  }

  async function saveClaimImages() {
    //setReportMenu({ ...reportMenu, reportMenuOn: false })
    const imageElement = document.getElementById(props.imageElementId)
    // save the element margin
    const margin = imageElement!.style.margin
    imageElement!.style.margin = '0'
    // remove the margin from the element
    // give the margin to the image again

    const imageJpeg = await htmlToImage
      .toPng(imageElement!, { quality: 1 })
      .then(function (dataUrl) {
        var link = document.createElement('a')
        link.download = `${props.saveName}.jpeg`
        link.href = dataUrl
        link.click()
      })

    imageElement!.style.margin = margin
    return imageJpeg
  }

  const downloadExcel = (data: any) => {
    const xlsxBlob = new Blob([data])
    const url = window.URL.createObjectURL(xlsxBlob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', props.saveName)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }

  const onExportResults = () => {
    if (freemium.isFreemium) {
      setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      return
    }

    setFullscreenActionMessage(ActionMessage.downloadingExcel)

    setTimeout(() => {
      if (props.snapshotId && props.resultsViewParty) {
        //Mixpanel 187 (All)
        logActivity(freemium.isFreemium, `Downloaded Results in Excel`)

        exportResults(props.snapshotId, props.resultsViewParty).then((res) => {
          if (!('errorCode' in res)) {
            downloadExcel(res.data)
            setFullscreenActionMessage('')
          } else {
            setFullscreenActionMessage(ActionMessage.downloadFailed)
            setTimeout(() => {
              setFullscreenActionMessage('')
            }, 400)
          }
        })
      } else {
        setFullscreenActionMessage(ActionMessage.downloadFailed)
        setTimeout(() => {
          setFullscreenActionMessage('')
        }, 400)
      }
    }, 400)
  }

  return (
    <>
      <WhiteTooltip
        textAttribute={props.tooltipTextAttribute}
        id={`downloadImageTooltip-${props.id}`}
        title={props.tooltipText}
        placement="right-start"
      >
        <div
          className={
            props.downloadImageType === 'tree'
              ? 'treeSideMenuButton'
              : props.downloadImageType === 'excel-inactive'
              ? 'downloadImageContainer inactive'
              : 'downloadImageContainer'
          }
          onClick={
            props.downloadImageType === 'excel'
              ? onExportResults
              : props.downloadImageType === 'excel-inactive'
              ? undefined
              : downloadImage
          }
          id={`container-downloadImageImg-${props.id}`}
        >
          <img
            src={
              props.downloadImageType === 'excel'
                ? downloadExcelImg
                : props.downloadImageType === 'excel-inactive'
                ? downloadExcelInactiveImg
                : downloadImageImg
            }
            alt="downloadImageImg"
            className="dowlonadImage"
            id={`downloadImageImg-${props.id}`}
          />
        </div>
      </WhiteTooltip>
      {freemium.isFreemium && (
        <PremiumFeatureIcon
          targetId={`container-downloadImageImg-${props.id}`}
          forTreeDownload={props.downloadImageType === 'tree'}
        />
      )}
    </>
  )
}
