import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { UserRole } from '../../../../../models/enums'
import { User } from '../../../../../models/user'
import {
  findHandlingErrorState,
  logActivity,
} from '../../../../../services/commonFunctions'
import { removePassword, removeTfa } from '../../../../../services/requests'
import { getText } from '../../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { userState } from '../../../../../states/UserState'
import SettingInfoRow from '../../components/SettingInfoRow'
import LocalWindow from './LocalWindow'

type Props = {
  user: User
  changeUser: (newUserData?: any) => void
}

const Security = (props: Props) => {
  const adminUser = useRecoilValue(userState)
  const [resetPasswordCoordinates, setResetPasswordCoordinates] = useState([
    0, 0,
  ])
  const [resetTfaCoordinates, setResetTfaCoordinates] = useState([0, 0])
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [showResetTfa, setShowResetTfa] = useState(false)
  const [loading, setLoading] = useState(false)
  const [reseted, setReseted] = useState(false)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  useEffect(() => {
    window.addEventListener('resize', handleWindowsPosition)
    window.addEventListener('scroll', handleWindowsPosition)
    document.addEventListener('mousedown', onMouseDown, false)

    return () => {
      window.removeEventListener('resize', handleWindowsPosition)
      window.removeEventListener('scroll', handleWindowsPosition)
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [showResetPassword, showResetTfa])

  const handleWindowsPosition = () => {
    if (document.getElementById(`resetPassword`)) {
      let resetButton = document.getElementById(`resetPassword`)
      let viewportOffset = resetButton?.getBoundingClientRect()

      if (viewportOffset) {
        // these are relative to the viewport, i.e. the window
        let top = viewportOffset.top + 22
        let left = viewportOffset.left + 29
        let h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }

        setResetPasswordCoordinates([top, left])
      }
    }

    if (document.getElementById(`resetTfa`)) {
      let resetButton = document.getElementById(`resetTfa`)
      let viewportOffset = resetButton?.getBoundingClientRect()

      if (viewportOffset) {
        // these are relative to the viewport, i.e. the window
        let top = viewportOffset.top + 22
        let left = viewportOffset.left + 29
        let h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }
        setResetTfaCoordinates([top, left])
      }
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    if (e.target && !loading) {
      const id = (e.target as HTMLImageElement).id
      if (!id.includes('resetPassword')) {
        if (!showResetTfa) {
          setReseted(false)
        }
        setShowResetPassword(false)
      }
      if (!id.includes('resetTfa')) {
        if (!showResetPassword) {
          setReseted(false)
        }
        setShowResetTfa(false)
      }
    }
  }

  const onResetPasswordLink = () => {
    handleWindowsPosition()
    setShowResetPassword(true)
  }

  const onResetPassword = () => {
    setLoading(true)
    removePassword({
      email: props.user.email,
    }).then((res) => {
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'removePassword'),
      )

      if (!('errorCode' in res)) {
        setReseted(true)
        //Mixpanel 108
        logActivity(
          false,
          "Reset user's password in Security of User Details pop up",
        )
      }

      setLoading(false)
    })
  }

  const onResetTfaLink = () => {
    handleWindowsPosition()
    setShowResetTfa(true)
  }

  const onResetTfa = () => {
    setLoading(true)
    removeTfa({ username: props.user.email }).then((res) => {
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'removeTfa'),
      )

      if (!('errorCode' in res)) {
        setReseted(true)
        props.changeUser()
        //Mixpanel 109
        logActivity(
          false,
          "Reset user's tfa in Security of User Details pop up",
        )
      }

      setLoading(false)
    })
  }

  const allowedToReset = () => {
    if (
      adminUser.role === UserRole.COMPANY_ADMIN &&
      props.user.role === UserRole.GLOBAL_ADMIN
    ) {
      return false
    }

    return true
  }

  return (
    <>
      <div className="titleContainer no-first">
        <h2
          className="welcome"
          id="securityTitle"
          data-textattribute="title-182"
        >
          {getText('title-182', adminUser.settings)}
        </h2>
      </div>

      <SettingInfoRow
        id="tfaStatusInfo"
        descriptionText={getText('description-120', adminUser.settings)}
        descriptionTextAttribute="description-120"
        secondaryDescriptionText={
          props.user.tfaType
            ? getText('description-121', adminUser.settings)
            : getText('description-122', adminUser.settings)
        }
        secondaryDescriptionTextAttribute={
          props.user.tfaType ? 'description-121' : 'description-122'
        }
        forDetails
        isFirst
      />
      <SettingInfoRow
        id="resetPasswordInfo"
        valueText={getText('message-71', adminUser.settings)}
        valueTextAttribute={'message-71'}
        forDetails
        isFirst
        isClickable
        onClick={onResetPasswordLink}
        clickableId="resetPassword"
        disabled={!allowedToReset()}
      />
      <SettingInfoRow
        id="resetTfaInfo"
        valueText={getText('message-72', adminUser.settings)}
        valueTextAttribute={'message-72'}
        forDetails
        isFirst
        isClickable
        onClick={onResetTfaLink}
        clickableId="resetTfa"
        disabled={!allowedToReset() || !props.user.tfaType}
      />
      {showResetPassword && (
        <LocalWindow
          id="resetPasswordWindow"
          resetFunction={onResetPassword}
          cancelFunction={() => {
            if (!loading) {
              setShowResetPassword(false)
              setReseted(false)
            }
          }}
          position={resetPasswordCoordinates}
          type="password"
          reseted={reseted}
          loading={loading}
          fullname={props.user.fullName()}
        />
      )}
      {showResetTfa && (
        <LocalWindow
          id="resetTfaWindow"
          resetFunction={onResetTfa}
          cancelFunction={() => {
            if (!loading) {
              setShowResetTfa(false)
              setReseted(false)
            }
          }}
          position={resetTfaCoordinates}
          type="tfa"
          reseted={reseted}
          loading={loading}
          fullname={props.user.fullName()}
        />
      )}
    </>
  )
}

export default Security
