import { ResultsObject } from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { LegalFeesDistributionObject } from '../models/legalFeesDistributionObject'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { TreeClaim } from '../models/treeModels/treeClaim'

export function findTotalClaimedAmount(state: ScenarioSnapshot) {
  var totalClaimedAmount = 0
  for (let i in state.claims) {
    if (
      state.claims[i].type === 'tree' &&
      (state.claims[i] as TreeClaim).totalClaimedAmount !== undefined
    ) {
      totalClaimedAmount += (state.claims[i] as TreeClaim).totalClaimedAmount!
    } else if (state.claims[i].type === 'claim') {
      totalClaimedAmount +=
        (state.claims[i] as IndependentClaim).amount !== undefined
          ? (state.claims[i] as IndependentClaim).amount!
          : 0
    }
  }
  return totalClaimedAmount
}

export function findTotalCounterClaimedAmount(state: ScenarioSnapshot) {
  var totalCounterClaimedAmount = 0
  for (let i in state.claims) {
    if (
      state.claims[i].type === 'tree' &&
      (state.claims[i] as TreeClaim).totalCounterClaimedAmount !== undefined
    ) {
      totalCounterClaimedAmount += (state.claims[i] as TreeClaim)
        .totalCounterClaimedAmount!
    } else if (state.claims[i].type === 'counterclaim') {
      totalCounterClaimedAmount +=
        (state.claims[i] as IndependentClaim).amount !== undefined
          ? (state.claims[i] as IndependentClaim).amount!
          : 0
    }
  }
  return totalCounterClaimedAmount
}

export function findDisputedAmount(state: ScenarioSnapshot) {
  const totalClaimedAmount = findTotalClaimedAmount(state)
  const totalCounterClaimedAmount = findTotalCounterClaimedAmount(state)

  return totalClaimedAmount - totalCounterClaimedAmount
}

export function findAbsoluteSegmentHeights(
  feeDistributions: LegalFeesDistributionObject[],
  rangesBarHeight: number,
) {
  let tempAbsoluteSegmentHeights: {
    segmentIndex: number
    top: number
    height: number
  }[] = []
  let startingHeight = 0
  let tempIndex = feeDistributions.length - 1
  for (let feeDistribution of [...feeDistributions].reverse()) {
    if (feeDistribution !== feeDistributions[feeDistributions.length - 1]) {
      tempAbsoluteSegmentHeights.push({
        segmentIndex: tempIndex,
        top: startingHeight,
        height:
          ((feeDistribution.range[1] - feeDistribution.range[0]) / 100) *
          rangesBarHeight,
      })

      startingHeight +=
        ((feeDistribution.range[1] - feeDistribution.range[0]) / 100) *
        rangesBarHeight
    }
    tempIndex--
  }
  return tempAbsoluteSegmentHeights
}

export function findDistributionPercentage(
  value: number,
  disputedAmount: number,
  totalCounterClaimedAmount: number,
) {
  return (value - totalCounterClaimedAmount) / disputedAmount
}

export function findSegmentAmount(
  disputedAmount: number,
  totalCounterClaimedAmount: number,
  rangeUpperLimitPercentage: number,
) {
  return (
    totalCounterClaimedAmount +
    (rangeUpperLimitPercentage * disputedAmount) / 100
  )
}

export function calculateAbsoluteStep(disputedAmount: number) {
  const numDigits: number = Math.floor(Math.log10(disputedAmount)) + 1
  const step: number = Math.pow(10, numDigits - 2)
  return step
}

export function findAcceptedRangesArray(
  start: number,
  end: number,
  step: number,
) {
  let acceptedRangesArray = []
  let firstAmount = start
  if (start % step !== 0) {
    firstAmount = start + step - (start % step)
    acceptedRangesArray.push(firstAmount)
  }
  for (let i = firstAmount + step; i < end; i += step) {
    acceptedRangesArray.push(i)
  }

  for (let i in acceptedRangesArray) {
    acceptedRangesArray[i] =
      ((acceptedRangesArray[i] - start) / (end - start)) * 100
  }

  return acceptedRangesArray
}

export function findClosestValue(
  datay: number,
  acceptedRangesArray: number[],
): number {
  let z: number = acceptedRangesArray[0]
  for (let i = 0; i < acceptedRangesArray.length; i++) {
    if (datay > acceptedRangesArray[i]) {
      z = acceptedRangesArray[i]
    } else {
      break
    }
  }

  return z
}

export function calculateValuesForBestWorst(backendResults: ResultsObject) {
  let values = {
    amountForBest: 0,
    singleAmountForBest: true,
    probabilityForBest: 0,
    amountForWorst: 0,
    singleAmountForWorst: true,
    probabilityForWorst: 0,
  }

  const grossDisputedAmount = Math.abs(
    backendResults.minmax.max.financial_outcome -
      backendResults.minmax.min.financial_outcome,
  )
  const fivePercentOfDisputedAmount = grossDisputedAmount * 0.05
  const amountThresholdForBest =
    backendResults.minmax.max.financial_outcome - fivePercentOfDisputedAmount
  const amountThresholdForWorst =
    backendResults.minmax.min.financial_outcome + fivePercentOfDisputedAmount

  for (let fop of backendResults.result.graph.toReversed()) {
    if (fop[0] >= amountThresholdForBest) {
      values.amountForBest = fop[0]
      values.singleAmountForBest =
        fop[0] === backendResults.minmax.max.financial_outcome
      values.probabilityForBest = fop[1] * 100
      break
    }
  }

  if (values.probabilityForBest <= 1) {
    for (let fop of backendResults.result.graph) {
      if (fop[1] >= 0.01) {
        values.amountForBest = fop[0]
        values.singleAmountForBest =
          fop[0] === backendResults.minmax.max.financial_outcome
        values.probabilityForBest = fop[1] * 100
        break
      }
    }
  }

  values.probabilityForBest = 0
  for (let fop of backendResults.result.graph) {
    if (fop[0] === values.amountForBest) {
      values.probabilityForBest = fop[1] * 100
    }
  }

  for (let [index, fop] of backendResults.result.graph.toReversed().entries()) {
    if (fop[0] <= amountThresholdForWorst) {
      values.amountForWorst = fop[0]
      values.singleAmountForWorst =
        fop[0] === backendResults.minmax.min.financial_outcome
      values.probabilityForWorst =
        (1 - backendResults.result.graph.toReversed()[index + 1][1]) * 100
      break
    }
  }
  if (values.probabilityForWorst <= 1) {
    for (let [index, fop] of backendResults.result.graph
      .toReversed()
      .entries()) {
      if (fop[1] <= 0.99) {
        values.amountForWorst = fop[0]
        values.singleAmountForWorst =
          fop[0] === backendResults.minmax.min.financial_outcome
        if (backendResults.result.graph.toReversed()[index + 1]) {
          values.probabilityForWorst =
            (1 - backendResults.result.graph.toReversed()[index + 1][1]) * 100
        }
        break
      }
    }
  }
  values.probabilityForWorst = 0

  for (let [index, fop] of backendResults.result.graph.toReversed().entries()) {
    if (fop[0] === values.amountForWorst) {
      if (backendResults.result.graph.toReversed()[index + 1]) {
        values.probabilityForWorst =
          (1 - backendResults.result.graph.toReversed()[index + 1][1]) * 100
      }
    }
  }

  return values
}
