import React from 'react'
import Xarrow from 'react-xarrows'
import { TreeEventClass } from '../../../../../../../../../models/treeModels/treeEvent'
import {
  EventId,
  NodeId,
  TreeLines,
} from '../../../../../../../../../models/treeModels/treeTypes'

type Props = {
  startId: string
  endId: string
  nodeId: NodeId
  eventId: EventId
  events: {
    [eventId: EventId]: TreeEventClass
  }
  zoomLevel: number
  type: 'report' | 'downloadImage'
  grayOut: boolean
  treeLines: TreeLines
}

export default function ConnectedLineReport(props: Props) {
  return (
    <>
      <Xarrow
        start={props.startId}
        startAnchor={'top'}
        end={props.endId}
        endAnchor={'bottom'}
        color={props.grayOut ? '#cbcbcb57' : 'black'}
        strokeWidth={props.type === 'report' ? 1 : 3}
        showHead={false}
        showTail={false}
        passProps={{
          id: `connectedLine-${props.nodeId}-${
            props.events[props.eventId as EventId].nodeOfEventId
          }!${props.events[props.eventId as EventId].eventIndex}`,
        }}
        path={props.treeLines}
        curveness={0.3}
      />
    </>
  )
}
