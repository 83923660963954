import React, { useEffect, useState } from 'react'

import { TreeClaim } from '../../../../../../../models/treeModels/treeClaim'
import {
  NodeMode,
  TreeLines,
} from '../../../../../../../models/treeModels/treeTypes'

import TreeSideMenuButton from './TreeSideMenuButton'

import zoomInImg from '../../../../../../../resources/images/191-plusnew.svg'
import zoomOutImg from '../../../../../../../resources/images/190-minusnew.svg'
import maxiTreeImg from '../../../../../../../resources/images/265-maxitree.svg'
import miniTreeImg from '../../../../../../../resources/images/267-minitree.svg'
import gridLineImg from '../../../../../../../resources/images/264-gridLine.svg'
import smoothLineImg from '../../../../../../../resources/images/263-smoothLine.svg'
import maxiTreeSelectedImg from '../../../../../../../resources/images/266-maxitreeSelected.svg'
import miniTreeSelectedImg from '../../../../../../../resources/images/268-minTreeSelected.svg'
import rearrangeImg from '../../../../../../../resources/images/192-rearange.svg'
import copyPasteClipboardImg from '../../../../../../../resources/images/225-copy_to_clipboard.svg'
import addFloatingImg from '../../../../../../../resources/images/270-addFloating.svg'

import { AccessRole } from '../../../../../../../models/enums'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { useRecoilValue } from 'recoil'
import DownloadImage from '../../../../../../../Components/downloadImages/DownloadImage'
import { scenarioIdentityState } from '../../../../../../../states/ScenarioIdentityState'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import TreeSideMenuSwitch from './TreeSideMenuSwitch'

type Props = {
  treeIndex: number
  treeClaim: TreeClaim
  handleMinMax: (nodeMode: NodeMode) => void
  resetTreePosition: () => void
  handleZoomLevel: (zoomMode: 'zoomIn' | 'zoomOut') => void
  handleTreeLines: (treeLines: TreeLines) => void
  fixed: boolean
  ownRole: AccessRole
  handlePasteNodes: () => void
  handleAddFloatingNode: () => void
}

export default function TreeSideMenu(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [saveTreeImageName, setSaveTreeImageName] = useState('')

  useEffect(() => {
    let tempSaveTreeImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${
      (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
        .name
    }`
    setSaveTreeImageName(tempSaveTreeImageName)
    // eslint-disable-next-line
  }, [scenarioIdentity, scenarioSnapshot])

  return (
    <div
      className="treeSideMenuContainer"
      id={`treeSideMenuContainer-${props.treeIndex}`}
      style={props.fixed ? { left: 28, top: 40 } : undefined}
    >
      <TreeSideMenuButton
        treeIndex={props.treeIndex}
        thumbnail={zoomInImg}
        id={'zoomIn'}
        selected={false}
        textAttribute={'tooltip-26'}
        onClick={() => props.handleZoomLevel('zoomIn')}
        tooltipText={getText('tooltip-26', user.settings)}
      />

      <TreeSideMenuButton
        treeIndex={props.treeIndex}
        thumbnail={zoomOutImg}
        id={'zoomOut'}
        selected={false}
        textAttribute={'tooltip-27'}
        onClick={() => props.handleZoomLevel('zoomOut')}
        tooltipText={getText('tooltip-27', user.settings)}
      />

      {props.ownRole !== AccessRole.VIEWER && (
        <TreeSideMenuSwitch
          treeIndex={props.treeIndex}
          thumbnail1={
            props.treeClaim.nodeMode === NodeMode.maximised
              ? maxiTreeSelectedImg
              : maxiTreeImg
          }
          thumbnail2={
            props.treeClaim.nodeMode === NodeMode.minimised
              ? miniTreeSelectedImg
              : miniTreeImg
          }
          id={'maxMinTree'}
          selected={props.treeClaim.nodeMode === NodeMode.maximised ? 1 : 2}
          onClick={
            props.treeClaim.nodeMode === NodeMode.maximised
              ? () => props.handleMinMax(NodeMode.minimised)
              : () => props.handleMinMax(NodeMode.maximised)
          }
          textAttribute1={'tooltip-28'}
          textAttribute2={'tooltip-29'}
          tooltipText1={getText('tooltip-28', user.settings)}
          tooltipText2={getText('tooltip-29', user.settings)}
        />
      )}

      {props.ownRole !== AccessRole.VIEWER && (
        <TreeSideMenuSwitch
          treeIndex={props.treeIndex}
          thumbnail1={smoothLineImg}
          thumbnail2={gridLineImg}
          id={'treeLine'}
          selected={props.treeClaim.treeLines === TreeLines.grid ? 2 : 1}
          onClick={
            props.treeClaim.treeLines === TreeLines.grid
              ? () => props.handleTreeLines(TreeLines.smooth)
              : () => props.handleTreeLines(TreeLines.grid)
          }
          textAttribute1={'tooltip-54'}
          textAttribute2={'tooltip-53'}
          tooltipText1={getText('tooltip-54', user.settings)}
          tooltipText2={getText('tooltip-53', user.settings)}
        />
      )}

      <TreeSideMenuButton
        treeIndex={props.treeIndex}
        thumbnail={rearrangeImg}
        id={'rearrange'}
        selected={false}
        textAttribute={'tooltip-30'}
        onClick={props.resetTreePosition}
        tooltipText={getText('tooltip-30', user.settings)}
      />

      {props.ownRole !== AccessRole.VIEWER && (
        <TreeSideMenuButton
          treeIndex={props.treeIndex}
          thumbnail={addFloatingImg}
          id={'addFloatingNode'}
          selected={false}
          textAttribute={'tooltip-55'}
          onClick={() => props.handleAddFloatingNode()}
          tooltipText={getText('tooltip-55', user.settings)}
        />
      )}
      <DownloadImage
        id={`legalTree-${props.treeIndex}`}
        tooltipText={getText('tooltip-31', user.settings)}
        tooltipTextAttribute={'tooltip-31'}
        imageElementId={`treePreviewForDownload-${props.treeIndex}`}
        saveName={saveTreeImageName}
        downloadImageType="tree"
      />

      {props.ownRole !== AccessRole.VIEWER && (
        <TreeSideMenuButton
          treeIndex={props.treeIndex}
          thumbnail={copyPasteClipboardImg}
          id={'pasteTreeNodes'}
          selected={false}
          textAttribute={'tooltip-52'}
          onClick={() => props.handlePasteNodes()}
          tooltipText={getText('tooltip-52', user.settings)}
        />
      )}
    </div>
  )
}
