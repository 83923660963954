import { useRecoilValue } from 'recoil'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'
import logo from '../../resources/images/Eperoto-logo-blue.svg'
import eperotoImg from '../../resources/images/271-freemium.png'
import useWindowSize from '../../customHooks/useWindowSize'
import { useEffect, useState } from 'react'

export default function NotSupportedBrowser() {
  const user = useRecoilValue(userState)
  const [smallerFonts, setSmallerFonts] = useState(false)

  const { width, height } = useWindowSize()

  useEffect(() => {
    if (height / width > 1.603) {
      setSmallerFonts(true)
    } else {
      setSmallerFonts(false)
    }
  }, [width, height])

  return (
    <div className="notSupportedBackground">
      <div className="notSupportedMessageBox">
        <p
          style={{ fontSize: smallerFonts ? '22px' : '28px' }}
          className="titleText"
          data-textattribute="description-227"
        >
          {getText('description-227', user.settings)}
        </p>
        <p
          style={{ fontSize: smallerFonts ? '16px' : '20px' }}
          className="descriptionText"
          data-textattribute="description-228"
        >
          {getText('description-228', user.settings)}
        </p>
        <p
          style={{ fontSize: smallerFonts ? '16px' : '20px' }}
          className="mailText"
          data-textattribute="description-229"
        >
          {getText('description-229', user.settings)}{' '}
          <a href="mailto:support@eperoto.com" className="link">
            {getText('description-179b', user.settings)}
          </a>
        </p>
        <img
          src={eperotoImg}
          alt="eperotoImg"
          className="eperotoIcon"
          id="eperotoImg"
        />
      </div>
      <img
        className="eperotoLogo"
        src={logo}
        alt="Eperoto"
        onClick={() => {
          window.open('https://eperoto.com/', '_self')
        }}
      />
    </div>
  )
}
