import React, { ReactElement } from 'react'
import { useRecoilValue } from 'recoil'
import Button from '../../../../../../../Components/Buttons/Button/Button'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'

type Props = {
  treeIndex: number
  id: string
  message: ReactElement<any, any>
  buttonText: string
  buttonTextAttribute: `button-${number}` | `button-${number}${string}`
  onClick: () => void
  onCancel: () => void
}

export default function TreeTableWarningPopUp(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div
        className="warningPopUpContainer"
        id={`${props.id}WarningPopUpContainer-${props.treeIndex}`}
      >
        <div
          className="warningPopUpMessageContainer"
          id={`${props.id}warningPopUpMessageContainer-${props.treeIndex}`}
        >
          {props.message}
        </div>
        <div
          className="warningPopUpButtonsContainer"
          id={`${props.id}warningPopUpButtonsContainer-${props.treeIndex}`}
        >
          <Button
            id={`${props.id}cancelButton-${props.treeIndex}`}
            buttonText={getText('button-127', user.settings)}
            buttonTextAttribute={'button-127'}
            className={`cancelButton`}
            type={'button'}
            buttonType="text"
            onClick={props.onCancel}
            side="left"
            NoUpperCase={true}
            small={false}
          />
          <Button
            id={`${props.id}ignoreButton-${props.treeIndex}`}
            type={'button'}
            buttonText={props.buttonText}
            buttonTextAttribute={props.buttonTextAttribute}
            buttonType="contained"
            backGroundColor={'#ff703e'}
            textColor={'white'}
            onClick={props.onClick}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        </div>
      </div>
    </>
  )
}
