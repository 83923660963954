import Button from '../../Buttons/Button/Button'
import plus from '../../../resources/images/003-plusSignThinWhite.svg'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { useRecoilValue } from 'recoil'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import smallDeleteImg from '../../../resources/images/219-smallDeleteImg.svg'
import PremiumFeatureIcon from '../../PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  handleAddNewItem: () => void
  itemName: 'Scenario' | 'Case'
  isMyScenariosPage?: boolean
  widthOfCaseContainer?: number
  openFreemiumMessage: (messageType: FreemiumMessageType) => void
  isTutorial: boolean
  showPremiumIcon: boolean
  showPremiumIconForAdd: boolean
}

const BottomPart = (props: Props) => {
  const user = useRecoilValue(userState)

  return (
    <div
      className={`addNewCaseContainer`}
      style={{ width: props.widthOfCaseContainer }}
    >
      <div className="createCaseContainer">
        <Button
          id={
            props.isMyScenariosPage
              ? 'addNewScenarioButton'
              : 'addNewCaseButton'
          }
          buttonText={
            props.itemName === 'Case'
              ? getText('button-9', user.settings)
              : getText('button-10', user.settings)
          }
          buttonTextAttribute={
            props.itemName === 'Case' ? 'button-9' : 'button-10'
          }
          buttonType="contained"
          type="button"
          icon={plus}
          className="addNewCaseButton"
          onClick={() => {
            props.handleAddNewItem()
          }}
          side="left"
          NoUpperCase={true}
          small={false}
        />
        {props.showPremiumIconForAdd && (
          <PremiumFeatureIcon
            targetId={
              props.isMyScenariosPage
                ? 'addNewScenarioButton'
                : 'addNewCaseButton'
            }
          />
        )}
      </div>
      <div className="actionsContainer">
        <WhiteTooltip
          textAttribute={props.isMyScenariosPage ? 'tooltip-39' : 'tooltip-40'}
          id={'deletedCasesButtonTooltip'}
          title={
            props.isMyScenariosPage
              ? getText('tooltip-39', user.settings)
              : getText('tooltip-40', user.settings)
          }
        >
          <div>
            <Button
              id={'deletedCasesButton'}
              buttonText={
                <img
                  src={smallDeleteImg}
                  height={23}
                  width={26}
                  alt="smallDeleteImg"
                />
              }
              buttonTextAttribute={'button-'}
              buttonType="outlined"
              type="button"
              className="addNewCaseButton"
              onClick={() =>
                props.openFreemiumMessage(FreemiumMessageType.General)
              }
              verySmall
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </div>
        </WhiteTooltip>
        {props.showPremiumIcon && (
          <PremiumFeatureIcon targetId={'deletedCasesButton'} />
        )}
      </div>
    </div>
  )
}

export default BottomPart
