import { getText } from '../../services/textFunctions'
import { UserSettings } from '../userSettings'

export enum SectionTitleKeys {
  ExecutiveSummary = 'ExecutiveSummary',
  Proceedings = 'Proceedings',
  ClaimsOverview = 'ClaimsOverview',
  Claims = 'Claims',
  LegalCosts = 'LegalCosts',
  LegalCostsDistribution = 'LegalCostsDistribution',
  EnforcementRisk = 'EnforcementRisk',
  SelectionGraph = 'SelectionGraph',
  ProbabilityDistributionGraph = 'ProbabilityDistributionGraph',
  GetPayGraph = 'GetPayGraph',
  BestWorstScenario = 'BestWorstScenario',
  CustomSection = 'CustomSection',
  ExpectedValueBreakdown = 'ExpectedValueBreakdown',
}

export enum ExecutiveSummaryVisibleElementsEnum {
  valueOfCase = 'valueOfCase',
  bestWorstScenario = 'bestWorstScenario',
  requestedAmounts = 'requestedAmounts',
  proceedings = 'proceedings',
  legalCosts = 'legalCosts',
  enforcement = 'enforcement',
  settlement = 'settlement',
  zopa = 'zopa',
  claimsAndLegalCosts = 'claimsAndLegalCosts',
  expectedValue = 'expectedValue',
  newBestWorstScenario = 'newBestWorstScenario',
  newZopa = 'newZopa',
}

export enum ReportWhitelabelKeys {
  reportTitle = 'reportTitle',
  reportSubtitle = 'reportSubtitle',
  sectionTitle = 'sectionTitle',
  sectionDescription = 'sectionDescription',
  disclaimer = 'disclaimer',
}

export enum FontLabels {
  none = '',
  italic = 'Italic',
  regular = 'Regular',
  light = 'Light',
  lightItalic = 'Light-Italic',
  bold = 'Bold',
  medium = 'Medium',
  semiBold = 'SemiBold',
}

export function findPlaceholderForClaim(id: string, settings: UserSettings) {
  //data-textattribute='placeholder-15'
  //id='claimReportPlaceholder'
  return `${id} ${getText('placeholder-15', settings)}`
}
