import React from 'react'
import { ListItem, SortDirection } from '../../../models/generalTypes'
import englishTexts from '../../../resources/texts/englishTexts.json'
import aTozImg from '../../../resources/images/131-AtoZ.svg'
import zToaImg from '../../../resources/images/130-ZtoA.svg'
import oldToNewImg from '../../../resources/images/132-OldtoNew.svg'
import newToOldImg from '../../../resources/images/133-NewtoOld.svg'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  onClick?: () => void
  sortOption: keyof ListItem
  sortOptionOfThisTitle?: keyof ListItem
  sortDirection: SortDirection
  textAttribute: keyof typeof englishTexts
  id: string
  className: string
  style: React.CSSProperties | undefined
  noText?: boolean
}

export default function TitleComponent(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <h5
      className={props.className}
      onClick={props.onClick}
      style={props.style}
      id={props.id}
      data-textattribute={props.textAttribute}
    >
      {props.noText ? '' : getText(props.textAttribute, user.settings)}
      {props.sortOptionOfThisTitle === 'title' &&
      props.sortOption === 'title' &&
      props.sortDirection === 'up' ? (
        <img src={aTozImg} className="sortImgContainer" alt="aTozImg" />
      ) : props.sortOptionOfThisTitle === 'title' &&
        props.sortOption === 'title' &&
        props.sortDirection === 'down' ? (
        <img src={zToaImg} alt="zToaImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'dateCreated' &&
        props.sortOption === 'dateCreated' &&
        props.sortDirection === 'up' ? (
        <img src={newToOldImg} className="sortImgContainer" alt="oldToNewImg" />
      ) : props.sortOptionOfThisTitle === 'dateCreated' &&
        props.sortOption === 'dateCreated' &&
        props.sortDirection === 'down' ? (
        <img src={oldToNewImg} alt="newToOldImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'createdBy' &&
        props.sortOption === 'createdBy' &&
        props.sortDirection === 'up' ? (
        <img src={aTozImg} alt="aTozImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'createdBy' &&
        props.sortOption === 'createdBy' &&
        props.sortDirection === 'down' ? (
        <img src={zToaImg} alt="zToaImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'lastEdited' &&
        props.sortOption === 'lastEdited' &&
        props.sortDirection === 'up' ? (
        <img src={newToOldImg} className="sortImgContainer" alt="oldToNewImg" />
      ) : props.sortOptionOfThisTitle === 'lastEdited' &&
        props.sortOption === 'lastEdited' &&
        props.sortDirection === 'down' ? (
        <img src={oldToNewImg} alt="newToOldImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'lastEditedBy' &&
        props.sortOption === 'lastEditedBy' &&
        props.sortDirection === 'up' ? (
        <img src={aTozImg} alt="aTozImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'lastEditedBy' &&
        props.sortOption === 'lastEditedBy' &&
        props.sortDirection === 'down' ? (
        <img src={zToaImg} alt="zToaImg" className="sortImgContainer" />
      ) : props.sortOptionOfThisTitle === 'status' &&
        props.sortOption === 'status' &&
        props.sortDirection === 'up' ? (
        <img src={aTozImg} className="sortImgContainer" alt="aTozImg" />
      ) : props.sortOptionOfThisTitle === 'status' &&
        props.sortOption === 'status' &&
        props.sortDirection === 'down' ? (
        <img src={zToaImg} alt="zToaImg" className="sortImgContainer" />
      ) : null}
    </h5>
  )
}
