import React, { useEffect, useState } from 'react'
import Button from '../Buttons/Button/Button'
import { ClaimType } from '../../models/enums'
import { CircularProgress } from '@mui/material'
import { getText } from '../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'

type Props = {
  id: string
  claimIndex?: number
  claimType:
    | 'tree'
    | ClaimType
    | 'case'
    | 'scenario'
    | 'case_tutorial'
    | 'scenario_tutorial'
    | 'case_permanent'
    | 'scenario_permanent'
    | 'disabledAction'
    | 'case_anotherUserActive'
    | 'scenario_anotherUserActive'
  claimName?: string
  deleteFunction: () => void
  cancelFunction: () => void
  position: number[]
  fixed?: boolean
  requestLoading?: undefined | string
}

export default function DeleteClaimWindow(props: Props) {
  const user = useRecoilValue(userState)
  const [claimTypeName, setClaimTypeName] = useState('')

  useEffect(() => {
    if (props.claimType === 'claim' || props.claimType === 'counterclaim') {
      //id='typeForDeleteQuestion-claim'
      //data-textattribute='message-9'
      setClaimTypeName(getText('message-9', user.settings))
    } else if (props.claimType === 'tree') {
      //id='typeForDeleteQuestion-tree'
      //data-textattribute='message-10'
      setClaimTypeName(getText('message-10', user.settings))
    } else if (props.claimType === 'case') {
      //id='typeForDeleteQuestion-case'
      //data-textattribute='message-11'
      setClaimTypeName(getText('message-11', user.settings))
    } else if (props.claimType === 'case_permanent') {
      //id='typeForDeleteQuestion-case_permanent'
      //data-textattribute='message-97'
      setClaimTypeName(getText('message-97', user.settings))
    } else if (props.claimType === 'scenario') {
      //id='typeForDeleteQuestion-scenario'
      //data-textattribute='message-12'
      setClaimTypeName(getText('message-12', user.settings))
    } else if (props.claimType === 'disabledAction') {
      //id='typeForDeleteQuestion-disabledAction'
      //data-textattribute='message-98'
      setClaimTypeName(getText('message-98', user.settings))
    } else if (props.claimType === 'case_anotherUserActive') {
      //id='typeForDeleteQuestion-case_anotherUserActive'
      //data-textattribute='message-107'
      setClaimTypeName(getText('message-107', user.settings))
    } else if (props.claimType === 'scenario_anotherUserActive') {
      //id='typeForDeleteQuestion-scenario_anotherUserActive'
      //data-textattribute='message-108'
      setClaimTypeName(getText('message-108', user.settings))
    } else if (props.claimType === 'scenario_permanent') {
      //id='typeForDeleteQuestion-scenario_permanent'
      //data-textattribute='message-99'
      setClaimTypeName(getText('message-99', user.settings))
    } else if (props.claimType === 'scenario_tutorial') {
      //id='typeForDeleteQuestion-scenario_tutorial'
      //data-textattribute='message-101'
      setClaimTypeName(getText('message-101', user.settings))
    } else if (props.claimType === 'case_tutorial') {
      //id='typeForDeleteQuestion-case_tutorial'
      //data-textattribute='message-102'
      setClaimTypeName(getText('message-102', user.settings))
    }

    // eslint-disable-next-line
  }, [props.claimType])

  const isDisabbledAction = () => {
    return (
      props.claimType === 'disabledAction' ||
      props.claimType === 'case_anotherUserActive' ||
      props.claimType === 'scenario_anotherUserActive'
    )
  }

  return (
    <>
      <div
        id={`deleteWindow-${props.id}`}
        className={`deleteMessage ${
          isDisabbledAction() ? 'for-disabled-action' : ''
        } ${props.claimType.includes('permanent') ? 'permanent' : ''}
        `}
        style={{
          top: props.position[0],
          left: props.position[1],
          position: props.fixed ? 'fixed' : 'absolute',
        }}
        onClick={isDisabbledAction() ? props.cancelFunction : undefined}
      >
        {props.requestLoading === 'windowDeleteButton' ? (
          <div className="circularProgressDiv" id={'popUp7'}>
            <div id={`popUp8-${props.id}`}>
              <CircularProgress size={40} />
            </div>
          </div>
        ) : (
          <>
            <div
              className={`deleteQuestion ${
                isDisabbledAction() ? 'disabledMessage' : ''
              }`}
            >
              {props.claimType === 'case' ||
              props.claimType === 'scenario' ||
              isDisabbledAction() ? (
                <>
                  <p
                    id={`deleteQuestionText-${props.id}`}
                    data-textattribute="message-8"
                  >
                    {isDisabbledAction()
                      ? ''
                      : getText('message-8', user.settings)}{' '}
                    {claimTypeName}
                  </p>
                  {!isDisabbledAction() && (
                    <div className="claimNameContainer">
                      <p
                        style={{
                          maxWidth: '190px',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                        }}
                      >
                        "{props.claimName}
                      </p>
                      <p>"?</p>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <p
                    style={{
                      fontSize:
                        props.claimType.includes('permanent') ||
                        props.claimType.includes('tutorial')
                          ? '16px'
                          : '20px',
                    }}
                    id={`deleteQuestionText-${props.id}`}
                    data-textattribute={
                      props.claimType.includes('tutorial')
                        ? 'message-103'
                        : 'message-8'
                    }
                  >
                    {props.claimType.includes('tutorial')
                      ? getText('message-103', user.settings)
                      : getText('message-8', user.settings)}{' '}
                    {claimTypeName}?
                  </p>
                </>
              )}
            </div>
            {!isDisabbledAction() && (
              <div
                className="buttonsContainer"
                id={`buttonsContainer-${props.id}`}
              >
                <Button
                  id={`windowCancelButton-${props.id}`}
                  buttonText={getText('button-3', user.settings)}
                  buttonTextAttribute="button-3"
                  buttonType="text"
                  type="button"
                  className="cancelButton"
                  onMouseUp={props.cancelFunction}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
                <Button
                  id={`windowDeleteButton-${props.id}`}
                  buttonText={
                    props.claimType === 'case_tutorial'
                      ? getText('button-135', user.settings)
                      : props.claimType === 'scenario_tutorial'
                      ? getText('button-136', user.settings)
                      : getText('button-4', user.settings)
                  }
                  buttonTextAttribute={
                    props.claimType === 'case_tutorial'
                      ? 'button-135'
                      : props.claimType === 'scenario_tutorial'
                      ? 'button-136'
                      : 'button-4'
                  }
                  buttonType="contained"
                  type="button"
                  className="DeleteButton"
                  onMouseUp={() => {
                    props.deleteFunction()
                  }}
                  danger={props.claimType.includes('permanent')}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              </div>
            )}
          </>
        )}
      </div>
      <div
        className="deleteMessage-background"
        onMouseDown={props.cancelFunction}
      ></div>
    </>
  )
}
