import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { RequestError } from '../../../../../models/enums'
import {
  loggedInSendSms,
  showBackupCodes,
} from '../../../../../services/requests'
import { errorMessages } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import BackupCodes from '../../../../userLoginWorkflow/loginComponents/BackupCodes'
import OtpForm from '../../../../userLoginWorkflow/loginComponents/OtpForm'
import SettingPopUp from '../../components/SettingPopUp'
import { logActivity } from '../../../../../services/commonFunctions'

type Props = {
  onClose: () => void
}

const ShowRetrievalCodes = (props: Props) => {
  const user = useRecoilValue(userState)
  const [showConfirmOtp, setShowConfirmOtp] = useState(false)
  const [otp, setOtp] = useState('')
  const [lastSmsTimer, setLastSmsTimer] = useState<number | undefined>(
    undefined,
  )
  const [backupCodes, setBackupCodes] = useState<string[]>([])
  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >(undefined)
  const [loading, setLoading] = useState(false)
  const initSmsSent = useRef(false)

  useEffect(() => {
    setShowConfirmOtp(true)
    if (user.tfaType === 'sms' && !initSmsSent.current) {
      loggedInSendSms('change')
      initSmsSent.current = true
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!lastSmsTimer) {
      setLastSmsTimer(undefined)
    } else {
      setTimeout(() => {
        setLastSmsTimer((prevState) => (prevState ? prevState - 1 : undefined))
      }, 1000)
    }

    // eslint-disable-next-line
  }, [lastSmsTimer])

  const sendAgainSms = () => {
    loggedInSendSms('change')
    setLastSmsTimer(59)
  }

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (otp === '') {
      setValidationMessage(errorMessages(user.settings).NOT_ENOUGH_DIGITS)
      return
    }

    setLoading(true)
    setValidationMessage(undefined)
    showBackupCodes(otp).then((res) => {
      if ('errorCode' in res) {
        if (
          res.errorCode === RequestError.TIMEOUT ||
          res.errorCode === RequestError.SERVER_ERROR
        ) {
          setValidationMessage(errorMessages(user.settings).OTP_REQUEST_FAILED)
        } else {
          setLoading(false)
          setValidationMessage(
            user.tfaType === 'sms'
              ? errorMessages(user.settings).WRONG_CODE_SMS
              : errorMessages(user.settings).WRONG_CODE,
          )
        }
      } else {
        setShowConfirmOtp(false)
        setBackupCodes([...res.data.codes])
        //Mixpanel 99
        logActivity(
          false,
          'Watched retrieval codes after inserting one time password in Account Details (Show retrieval codes)',
        )
      }
      setLoading(false)
    })
  }

  const onClose = () => {
    if (!loading) {
      setShowConfirmOtp(false)
      setOtp('')
      setValidationMessage(undefined)
      setLastSmsTimer(undefined)
      setBackupCodes([])
      props.onClose()
    }
  }

  return (
    <SettingPopUp onClose={onClose}>
      {showConfirmOtp ? (
        <form className="form" onSubmit={handleSubmit}>
          <OtpForm
            setOtp={setOtp}
            otp={otp}
            forSms={user.tfaType === 'sms'}
            validationMessage={validationMessage}
            sendAgainSms={sendAgainSms}
            timeFromLastSms={lastSmsTimer}
            loading={loading}
            errors={[]}
          />
        </form>
      ) : (
        <BackupCodes
          withoutSave
          forSettings
          onDone={onClose}
          backupCodes={backupCodes}
          loading={false}
          savedBackUpCodesFailed={undefined}
        />
      )}
    </SettingPopUp>
  )
}

export default ShowRetrievalCodes
