import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { RequestError } from '../models/enums'
import { RequestErrorType, ResultsSettings } from '../models/generalTypes'
import { ReportDataType } from '../models/reportModels/reportGeneralTypes'
import { UserSettings } from '../models/userSettings'
import { getUrl } from './constants'
import { deleteR, get, patch, post } from './requestsTemplate'
import { getRefreshToken, getToken, saveToken } from './storage'
import { UserChecklist } from '../models/userChecklist'
import { TreeObjectForCalculation } from '../models/treeModels/treeTypes'

//POST @api_login
export const login = async (body: { username: string; password: string }) => {
  try {
    return await post(getUrl('LOGIN_URL'), {}, body)
  } catch (error) {
    return handleError(error)
  }
}

//POST @api_confirm_otp
export const confirmOtp = async (body: { otp: string }, tempToken: string) => {
  try {
    return await post(
      getUrl('CONFIRM_OTP_URL'),
      {
        Authorization: `Bearer ${tempToken}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @api_logout
export const logout = async () => {
  try {
    return await get(getUrl('LOGOUT_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, logout)
  }
}

//POST @api_reset_password
export const resetPassword = async (body: { email: string }) => {
  try {
    return await post(getUrl('RESET_PASSWORD_URL'), {}, body)
  } catch (error) {
    return handleError(error)
  }
}

//POST @new_registration_link
export const newRegistrationLink = async (body: { username: string }) => {
  try {
    return await post(getUrl('NEW_REGISTRATION_LINK_URL'), {}, body)
  } catch (error) {
    return handleError(error)
  }
}

//POST @api_new_password
export const newPassword = async (body: {
  token: string
  new_password: string
}) => {
  try {
    return await post(getUrl('NEW_PASSWORD_URL'), {}, body)
  } catch (error: any) {
    return handleError(error)
  }
}

//POST @api_set_password
export const setPassword = async (body: {
  old_password: string
  new_password: string
  otp: string
}) => {
  try {
    return await post(
      getUrl('SET_PASSWORD_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, setPassword, [body])
  }
}

//POST @api_change_password_check
export const changePasswordCheck = async (body: {
  old_password: string
  new_password: string
}) => {
  try {
    return await post(
      getUrl('CHANGE_PASSWORD_CHECK_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, changePasswordCheck, [body])
  }
}

//GET @api_new_token
export const newToken = async () => {
  try {
    return await get(
      getUrl('NEW_TOKEN_URL'),
      {
        Authorization: `Bearer ${getRefreshToken() ? getRefreshToken() : ''}`,
      },
      3000,
    )
  } catch (error: any) {
    return handleError(error)
  }
}

//GET @create_otp_device
export const setupTotpDevice = async (tempToken: string) => {
  try {
    return await get(getUrl('SET_UP_TOTP_DEVICE_URL'), {
      Authorization: `Bearer ${tempToken}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//POST @create_sms_device
export const setupSmsDevice = async (
  body: { phoneNumber: string; phoneRegion: string },
  tempToken: string,
) => {
  try {
    return await post(
      getUrl('SET_UP_SMS_DEVICE_URL'),
      { Authorization: `Bearer ${tempToken}` },
      body,
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @send_sms
export const sendSms = async (tempToken: string) => {
  try {
    return await get(getUrl('SEND_SMS_URL'), {
      Authorization: `Bearer ${tempToken}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @create_static_device
export const getBackupCodes = async (token: string) => {
  try {
    return await get(getUrl('GET_BACKUP_CODES_URL'), {
      Authorization: `Bearer ${token}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @has_saved_backup_codes
export const hasSavedBackupCodes = async (token: string) => {
  try {
    return await get(getUrl('HAS_SAVED_BACKUP_CODES_URL'), {
      Authorization: `Bearer ${token}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @set_saved_backup_codes
export const setSavedBackupCodes = async (token: string) => {
  try {
    return await get(getUrl('SET_SAVED_BACKUP_CODES_URL'), {
      Authorization: `Bearer ${token}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//POST @check_backup_codes
export const checkBackupCodes = async (
  body: { codes: string[] },
  tempToken: string,
) => {
  try {
    return await post(
      getUrl('CHECK_BACKUP_CODES_URL'),
      { Authorization: `Bearer ${tempToken}` },
      body,
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @api_me
export const me = async () => {
  try {
    return await get(getUrl('ME_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, me)
  }
}

//PATCH @api_me
export const updateMyInfo = async (body: {
  first_name?: string
  last_name?: string
}) => {
  try {
    return await patch(
      getUrl('ME_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, updateMyInfo, [body])
  }
}

//GET @api_grouplist
export const getCases = async () => {
  try {
    return await get(getUrl('CASES_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getCases)
  }
}

//GET @api_grouplist_deleted
export const getDeletedCases = async () => {
  try {
    return await get(getUrl('DELETED_CASES_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getDeletedCases)
  }
}

//DELETE @api_grouplist_deleted
export const deleteAllDeletedCases = async () => {
  try {
    return await deleteR(getUrl('DELETED_CASES_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, deleteAllDeletedCases)
  }
}

//POST @api_grouplist
export const saveCase = async (name: string) => {
  try {
    return await post(
      getUrl('CASES_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { name: name },
    )
  } catch (error) {
    return handleError(error, saveCase, [name])
  }
}

//GET @api_caselist
export const getScenarios = async (caseId: string) => {
  try {
    return await get(getUrl('SCENARIOS_URL', [caseId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getScenarios, [caseId])
  }
}

//GET @api_caselist_deleted
export const getDeletedScenarios = async (caseId: string) => {
  try {
    return await get(getUrl('DELETED_SCENARIOS_URL', [caseId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getDeletedScenarios, [caseId])
  }
}

//DELETE @api_caselist_deleted
export const deleteAllDeletedScenarios = async (caseId: string) => {
  try {
    return await deleteR(getUrl('DELETED_SCENARIOS_URL', [caseId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, deleteAllDeletedScenarios, [caseId])
  }
}

//POST @api_caselist
export const saveScenario = async (caseId: string, name: string) => {
  try {
    return await post(
      getUrl('SCENARIOS_URL', [caseId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { name: name },
    )
  } catch (error) {
    return handleError(error, saveScenario, [caseId, name])
  }
}

//PATCH @api_groupdetail
export const changeCaseName = async (caseId: string, name: string) => {
  try {
    return await patch(
      getUrl('SINGLE_CASE_URL', [caseId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { name: name },
    )
  } catch (error) {
    return handleError(error, changeCaseName, [caseId, name])
  }
}

//DELETE @api_groupdetail
export const deleteCase = async (caseId: string, force?: boolean) => {
  try {
    return await deleteR(
      getUrl('SINGLE_CASE_URL', [caseId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { force },
    )
  } catch (error) {
    return handleError(error, deleteCase, [caseId])
  }
}

//GET @api_grouprestore
export const restoreCase = async (caseId: string) => {
  try {
    return await get(getUrl('RESTORE_CASE_URL', [caseId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, restoreCase, [caseId])
  }
}

//GET @api_casedetails
export const getScenario = async (caseId: string, scenarioId: string) => {
  try {
    return await get(getUrl('SINGLE_SCENARIO_URL', [caseId, scenarioId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getScenario, [caseId, scenarioId])
  }
}

//POST @api_casedetails
export const saveScenarioSnapshot = async (
  caseId: string,
  scenarioId: string,
  body: { data: any; keep_results: boolean; log_entry: string },
) => {
  try {
    return await post(
      getUrl('SINGLE_SCENARIO_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, saveScenarioSnapshot, [caseId, scenarioId, body])
  }
}

//PATCH @api_casedetails
export const changeScenarioName = async (
  caseId: string,
  scenarioId: string,
  name: string,
) => {
  try {
    return await patch(
      getUrl('SINGLE_SCENARIO_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { name: name },
    )
  } catch (error) {
    return handleError(error, changeScenarioName, [caseId, scenarioId, name])
  }
}

//DELETE @api_casedetails
export const deleteScenario = async (
  caseId: string,
  scenarioId: string,
  force?: boolean,
) => {
  try {
    return await deleteR(
      getUrl('SINGLE_SCENARIO_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { force },
    )
  } catch (error) {
    return handleError(error, deleteScenario, [caseId, scenarioId])
  }
}

//GET @api_caserestore
export const restoreScenario = async (caseId: string, scenarioId: string) => {
  try {
    return await get(getUrl('RESTORE_SCENARIO_URL', [caseId, scenarioId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, restoreScenario, [caseId, scenarioId])
  }
}

//DELETE @api_caserecovery
export const deleteRecoverySnapshot = async (
  caseId: string,
  scenarioId: string,
) => {
  try {
    return await deleteR(
      getUrl('RECOVERY_SCENARIO_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
    )
  } catch (error) {
    return handleError(error, deleteRecoverySnapshot, [caseId, scenarioId])
  }
}

//POST @api_groupduplicate
export const duplicateCase = async (caseId: string, name: string) => {
  try {
    return await post(
      getUrl('DUPLICATE_CASE_URL', [caseId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { name: name },
    )
  } catch (error) {
    return handleError(error, duplicateCase, [caseId, name])
  }
}

//POST @api_caseduplicate
export const duplicateScenario = async (
  caseId: string,
  scenarioId: string,
  name: string,
  newCaseId: string,
) => {
  try {
    return await post(
      getUrl('DUPLICATE_SCENARIO_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { name: name, new_groupid: newCaseId },
    )
  } catch (error) {
    return handleError(error, duplicateScenario, [
      caseId,
      scenarioId,
      name,
      newCaseId,
    ])
  }
}

//GET @api_reportdata
export const getReportDetail = async (caseId: string, scenarioId: string) => {
  try {
    return await get(getUrl('REPORT_DETAIL_URL', [caseId, scenarioId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getReportDetail, [caseId, scenarioId])
  }
}

//POST @api_reportdata
export const createReportDetail = async (
  caseId: string,
  scenarioId: string,
  body: { reportData: ReportDataType },
) => {
  try {
    return await post(
      getUrl('REPORT_DETAIL_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, createReportDetail, [caseId, scenarioId, body])
  }
}

//PATCH @api_reportdata
export const saveReportDetail = async (
  caseId: string,
  scenarioId: string,
  body: { reportData: ReportDataType },
) => {
  try {
    return await patch(
      getUrl('REPORT_DETAIL_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, saveReportDetail, [caseId, scenarioId, body])
  }
}

//POST @api_caseresultscancel
export const cancelJob = async (
  caseId: string,
  scenarioId: string,
  snapshotId: string,
) => {
  try {
    return await post(
      getUrl('CANCEL_JOB_URL', [caseId, scenarioId, snapshotId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { body: 'Cancel Job' },
    )
  } catch (error) {
    return handleError(error, cancelJob, [caseId, scenarioId, snapshotId])
  }
}

//POST @api_caseresultsdetails
export const analyze = async (
  caseId: string,
  scenarioId: string,
  snapshotId: string,
  data: any,
  calculatingZopa?: boolean,
) => {
  try {
    return await post(
      getUrl('RESULTS_URL', [caseId, scenarioId, snapshotId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { data: data, calculating_zopa: calculatingZopa },
    )
  } catch (error) {
    return handleError(error, analyze, [caseId, scenarioId, snapshotId, data])
  }
}

//POST @api_calculate_tree_direct
export const calculateTree = async (
  body: TreeObjectForCalculation,
  bigTimeout?: boolean,
) => {
  try {
    return await post(
      getUrl('CALCULATE_TREE_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
      bigTimeout ? 60000 : 20000,
    )
  } catch (error) {
    return handleError(error, calculateTree, [body], true)
  }
}

//GET @all_users
export const getAllUsers = async () => {
  try {
    return await get(getUrl('ALL_USERS_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getAllUsers)
  }
}

//GET @api_group_roles - not used yet
export const getUsersGroupRoles = async (caseId: string) => {
  try {
    return await get(getUrl('GROUP_ROLES_URL', [caseId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getUsersGroupRoles, [caseId])
  }
}

//POST @api_group_roles
export const addUserToGroup = async (caseId: string, body: any) => {
  try {
    return await post(
      getUrl('GROUP_ROLES_URL', [caseId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, addUserToGroup, [caseId, body])
  }
}

//DELETE @api_group_roles
export const deleteUserFromGroup = async (caseId: string, body: any) => {
  try {
    return await deleteR(
      getUrl('GROUP_ROLES_URL', [caseId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, deleteUserFromGroup, [caseId, body])
  }
}

//GET @api_features
export const getAvailableFeatures = async () => {
  try {
    return await get(getUrl('FEATURES_URL'), {})
  } catch (error) {
    return handleError(error, getAvailableFeatures)
  }
}

//GET @api_wordpress
export const getWordpress = async (post_type: string, slug?: string) => {
  try {
    return await get(
      getUrl('WORDPRESS_URL', [post_type, slug ? slug : 'no-slug']),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
    )
  } catch (error) {
    return handleError(error, getWordpress, [post_type])
  }
}

//GET @company_details
export const getCompanyDetails = async () => {
  try {
    return await get(getUrl('COMPANY_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getCompanyDetails)
  }
}

//PATCH @company_details
export const updateCompanyDetails = async (body: { company_name: string }) => {
  try {
    return await patch(
      getUrl('COMPANY_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, updateCompanyDetails, [body])
  }
}

//POST @api_log
export const createLogs = async (eventName: string, props?: any) => {
  let body: { eventName: string; props?: any } = { eventName }
  if (props) {
    body = { ...body, props: props }
  }
  try {
    return await post(
      getUrl('LOGS_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      const res = await newToken()
      if (!('errorCode' in res)) {
        saveToken(res.data.token)
        try {
          return await post(
            getUrl('LOGS_URL'),
            {
              Authorization: `Bearer ${getToken() ? getToken() : ''}`,
            },
            body,
          )
        } catch (error) {
          return
        }
      }
    }
    return
  }
}

//GET @user_settings
export const getUserSettings = async () => {
  try {
    return await get(getUrl('USER_SETTINGS_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getUserSettings)
  }
}

//PATCH @user_settings
export const saveUserSettings = async (settings: UserSettings) => {
  try {
    return await patch(
      getUrl('USER_SETTINGS_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      UserSettings.ToJson(settings),
    )
  } catch (error) {
    return handleError(error, saveUserSettings, [settings])
  }
}

//POST @check_reset_token
export const checkResetToken = async (token: string) => {
  try {
    return await post(getUrl('CHECK_RESET_TOKEN_URL'), {}, { token: token })
  } catch (error) {
    return handleError(error, checkResetToken, [token])
  }
}

//POST @add_user
export const addUser = async (body: {
  email: string
  first_name: string
  last_name: string
  role: string
}) => {
  try {
    return await post(
      getUrl('ADD_USER_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, addUser, [body])
  }
}

//POST @microsoft_sso_add_user
export const addSSOUser = async (body: { email: string; role: string }) => {
  try {
    return await post(
      getUrl('MICROSOFT_SSO_ADD_USER_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, addUser, [body])
  }
}

//GET @get_tfa_device_type
export const getTwoFactorDeviceType = async () => {
  try {
    return await get(getUrl('GET_TFA_DEVICE_TYPE_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getTwoFactorDeviceType)
  }
}

//GET @show_backup_codes
export const showBackupCodes = async (otp: string) => {
  try {
    return await post(
      getUrl('SHOW_BACKUP_CODES_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { otp },
    )
  } catch (error) {
    return handleError(error, showBackupCodes, [{ otp }])
  }
}

//GET @get_tfa_device_type
export const loggedInSendSms = async (mode: 'change' | 'create') => {
  try {
    return await get(getUrl('LOGGED_IN_SEND_SMS_URL', [mode]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, loggedInSendSms)
  }
}

//POST @change_tfa_confirm
export const changeTfaDevice = async (body: {
  codes?: string[]
  otp?: string
  mode?: 'change' | 'create'
}) => {
  try {
    return await post(
      getUrl('CHANGE_TFA_DEVICE_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, changeTfaDevice, [body])
  }
}

//POST @change_tfa_set_up_totp_device
export const loggedInSetupTotpDevice = async () => {
  try {
    return await get(getUrl('LOGGED_IN_SET_UP_TOTP_DEVICE_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, loggedInSetupTotpDevice)
  }
}

//POST @change_tfa_set_up_sms_device
export const loggedInSetupSmsDevice = async (body: {
  phoneNumber: string
  phoneRegion: string
}) => {
  try {
    return await post(
      getUrl('LOGGED_IN_SET_UP_SMS_DEVICE_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, loggedInSetupSmsDevice, [body])
  }
}

//POST @delete_account
export const deleteAccount = async (
  password: string,
  force_delete?: boolean,
) => {
  try {
    return await post(
      getUrl('DELETE_ACCOUNT_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { password, force_delete },
    )
  } catch (error) {
    return handleError(error, deleteAccount, [password])
  }
}

//POST @delete_own_sso_account
export const deleteOwnSSOAccount = async (
  email: string,
  force_delete?: boolean,
) => {
  try {
    return await post(
      getUrl('DELETE_OWN_SSO_ACCOUNT_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { email, force_delete },
    )
  } catch (error) {
    return handleError(error, deleteAccount, [email])
  }
}

//GET @detailed_users
export const getDetailedUsers = async () => {
  try {
    return await get(getUrl('DETAILED_USERS_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getDetailedUsers)
  }
}

//POST @update_user
export const updateUser = async (body: {
  username: string
  first_name?: string
  last_name?: string
  role?: string
}) => {
  try {
    return await post(
      getUrl('UPDATE_USER_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, updateUser, [body])
  }
}

//POST @remove_user
export const removeUser = async (body: {
  username: string
  force_delete?: boolean
}) => {
  try {
    return await post(
      getUrl('REMOVE_USER_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, removeUser, [body])
  }
}

//POST @send_welcome_email
export const sendWelcomeEmail = async (body: { username: string }) => {
  try {
    return await post(
      getUrl('SEND_WELCOME_EMAIL_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, sendWelcomeEmail, [body])
  }
}

//POST @remove_tfa_device
export const removeTfa = async (body: { username: string }) => {
  try {
    return await post(
      getUrl('REMOVE_TFA_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, removeTfa, [body])
  }
}

//POST @remove_password
export const removePassword = async (body: { email: string }) => {
  try {
    return await post(
      getUrl('REMOVE_PASSWORD_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, removePassword, [body])
  }
}

//POST @transfer_cases
export const transferCases = async (
  body:
    | { case_to_user: { case: string; user: string }[] }
    | { transfer_to: string; transfer_from: string },
) => {
  try {
    return await post(
      getUrl('TRANSFER_CASES_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, transferCases, [body])
  }
}

//GET @api_create_all_tutorials
export const createAllTutorials = async () => {
  try {
    return await get(getUrl('CREATE_ALL_TUTORIALS_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, createAllTutorials)
  }
}

//GET @api_create_tutorial
export const createTutorial = async (tutorialId: string) => {
  try {
    return await get(getUrl('CREATE_TUTORIAL_URL', [tutorialId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, createTutorial, [tutorialId])
  }
}

//GET @api_get_tutorial
export const getTutorial = async (tutorialId: string) => {
  try {
    return await get(getUrl('GET_TUTORIAL_URL', [tutorialId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getTutorial, [tutorialId])
  }
}

//PATCH @user_checklist
export const updateUserChecklist = async (checklist: UserChecklist) => {
  try {
    return await patch(
      getUrl('UPDATE_CHECKLIST_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      UserChecklist.ToJson(checklist),
    )
  } catch (error) {
    return handleError(error, updateUserChecklist, [checklist])
  }
}

//GET @whitelabel_properties
export const getWhitelabelProperties = async () => {
  try {
    return await get(getUrl('WHITELABEL_PROPERTIES_URL'), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getWhitelabelProperties)
  }
}

//GET @api_export_results
export const exportResults = async (snapshotid: string, party: string) => {
  const config: AxiosRequestConfig = {
    method: 'GET',
    url: getUrl('EXPORT_RESULTS_URL', [snapshotid, party]),
    responseType: 'arraybuffer',
    headers: {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      'Content-Type': 'blob',
    },
  }
  try {
    return await axios(config)
  } catch (error) {
    return handleError(error, exportResults, [snapshotid])
  }
}

//GET @api_results_settings
export const getResultsSettings = async (
  caseId: string,
  scenarioId: string,
) => {
  try {
    return await get(getUrl('RESULTS_SETTINGS_URL', [caseId, scenarioId]), {
      Authorization: `Bearer ${getToken() ? getToken() : ''}`,
    })
  } catch (error) {
    return handleError(error, getResultsSettings, [caseId, scenarioId])
  }
}

//PATCH @api_results_settings
export const saveResultsSettings = async (
  caseId: string,
  scenarioId: string,
  body: ResultsSettings,
) => {
  try {
    return await patch(
      getUrl('RESULTS_SETTINGS_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error, saveResultsSettings, [caseId, scenarioId, body])
  }
}

//POST @microsoft_sso_login
export const microsoftSsoLogin = async (
  loginType: 'direct' | 'choose_account',
) => {
  try {
    return await post(
      getUrl('MICROSOFT_SSO_LOGIN_URL'),
      {},
      { login_type: loginType },
    )
  } catch (error) {
    return handleError(error)
  }
}

//POST @microsoft_sso_get_user
export const microsoftSsoGetUser = async (code: string) => {
  try {
    return await post(
      getUrl('MICROSOFT_SSO_GET_USER_URL'),
      {},
      { microsoft_code: code },
    )
  } catch (error) {
    return handleError(error)
  }
}

//POST @microsoft_sso_check_user
export const microsoftSsoCheckUser = async (username: string) => {
  try {
    return await post(
      getUrl('MICROSOFT_SSO_CHECK_USER_URL'),
      {
        Authorization: `Bearer ${getToken() ? getToken() : ''}`,
      },
      { username: username },
    )
  } catch (error) {
    return handleError(error)
  }
}

const handleError = async (
  error: any,
  func?: Function,
  attr?: any[],
  failOnTimeout?: boolean,
): Promise<AxiosResponse<any> | RequestErrorType> => {
  if (!error.response) {
    if (func && !failOnTimeout) {
      const res = await newToken()
      if (!('errorCode' in res)) {
        saveToken(res.data.token)
        if (attr) {
          return await func(...attr)
        } else {
          return await func()
        }
      }
    }

    return {
      errorCode: RequestError.TIMEOUT,
      message: error.message,
    }
  }

  switch (error.response.status) {
    case 401:
      if (func) {
        const res = await newToken()
        if (!('errorCode' in res)) {
          saveToken(res.data.token)
          if (attr) {
            return await func(...attr)
          } else {
            return await func()
          }
        }
      }

      return {
        errorCode: RequestError.UNAUTHORIZED,
        message: error.response.data,
      }
    case 403:
      return { errorCode: RequestError.FORBIDDEN, message: error.response.data }
    case 404:
      return { errorCode: RequestError.NOT_EXIST, message: error.response.data }
    case 429:
      return {
        errorCode: RequestError.LOCKED_ACCOUNT,
        message: error.response.data,
      }
    case 400:
      return {
        errorCode: RequestError.BAD_REQUEST,
        message: error.response.data,
      }
    case 500:
      return {
        errorCode: RequestError.SERVER_ERROR,
        message: error.response.data,
      }
    case 599:
      return {
        errorCode: RequestError.WRONG_REDUCED_DATES,
        message:
          'Wrong Interest Dates for Reduced Awarded Amounts in Legal Tree',
      }
    default:
      return {
        errorCode: RequestError.UNKNOWN_ERROR,
        message: error.response.data,
      }
  }
}
