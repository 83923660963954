import {
  AmountSignum,
  InterestViewOption,
  typeOfInstanceFromString,
} from '../models/enums'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
} from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { LegalFeesDistributionObject } from '../models/legalFeesDistributionObject'
import { QuantumScenario } from '../models/quantumScenario'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { NodeId, NodesMovedForEditMode } from '../models/treeModels/treeTypes'
import { changeProbabilities } from '../Modules/TreeModules/TreeFunctions'
import {
  claimtypeIdArray,
  handleClaimtypeIdChange,
  getClaimListSortingMethod,
} from './claimFunctions'
import {
  addUnderscore,
  calculateExpectedResults,
  deepCloneObject,
} from './commonFunctions'
import { TREE_CALCULATION_TIMER } from './constants'
import { atLeastOneClaimHasInterest } from './resultsFunctions'
import { resetNodeSiblingsIndex } from './treeFunctions/treeBasicFunctions'
import { calculateNumberOfScenarios } from './twoParentsFunctionsForNumberOfScenarios'
import { secondTrialDateIsValid } from './validateGlobalState'

export function changeGlobalSnapshot(
  snapshotSelectorObject: SnapshotSelectorObject,
  tempScenarioSnapshot: DefaultSnapshotState,
  nodesMovedForEditMode?: NodesMovedForEditMode,
): DefaultSnapshotState {
  const {
    targetId,
    undoRedoType,
    value,
    key,
    claimIndex,
    secondaryIndex,
    key2,
    key3,
  } = snapshotSelectorObject

  const { currentSnapshot } = tempScenarioSnapshot
  let tempValue = value

  switch (key) {
    case 'partyFormatOwn':
      if (tempValue === 'switch') {
      } else {
        tempScenarioSnapshot.currentSnapshot.partyFormatOwn = tempValue
      }
      break
    case 'firstTrialDate':
    case 'secondTrialDate':
      for (let i in tempScenarioSnapshot.currentSnapshot.claims) {
        if (tempScenarioSnapshot.currentSnapshot.claims[i].type === 'tree') {
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).trialDates[key] = value
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).analysisResults = false
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).totalClaimedAmountInterest1st = undefined
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).totalClaimedAmountInterest2nd = undefined
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).totalCounterClaimedAmountInterest1st = undefined
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).totalCounterClaimedAmountInterest2nd = undefined
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).treeWeightedValueInterest1st = undefined
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).treeWeightedValueInterest2nd = undefined
        }
      }

      tempScenarioSnapshot.currentSnapshot[key] = value
      break
    case 'hasSecondTrial':
      for (let i in tempScenarioSnapshot.currentSnapshot.claims) {
        if (tempScenarioSnapshot.currentSnapshot.claims[i].type === 'tree') {
          if (value === false) {
            ;(
              tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
            ).trialDates['secondTrialDate'] = undefined
          }
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).analysisResults = false
        }
      }
      tempScenarioSnapshot.currentSnapshot[key] = value
      break
    case 'probSecondTrialstep1':
    case 'probSecondTrialstep2':
      for (let i in tempScenarioSnapshot.currentSnapshot.claims) {
        if (tempScenarioSnapshot.currentSnapshot.claims[i].type === 'tree') {
          ;(
            tempScenarioSnapshot.currentSnapshot.claims[i] as TreeClaim
          ).analysisResults = false
        }
      }
      tempScenarioSnapshot.currentSnapshot[key] = value
      break
    case 'court':
      currentSnapshot.court = typeOfInstanceFromString(addUnderscore(value))
      break

    case 'legalFeesDistribution':
      let feeDistributions = currentSnapshot.legalFeesDistribution
      if (key2 === 'value') {
        if (value > 0) {
          feeDistributions[secondaryIndex!].ratioCoveredByMe = value / 10
          feeDistributions[secondaryIndex!].ratioToCover = 0.0
          if (
            secondaryIndex === feeDistributions.length - 2 &&
            currentSnapshot.includeTopLFDLimit !== true
          ) {
            feeDistributions[feeDistributions.length - 1!].ratioCoveredByMe =
              value / 10
            feeDistributions[feeDistributions.length - 1!].ratioToCover = 0.0
            feeDistributions[feeDistributions.length - 1!].value = value
          }
          if (
            secondaryIndex === 1 &&
            currentSnapshot.includeBottomLFDLimit !== true
          ) {
            feeDistributions[0].ratioCoveredByMe = value / 10
            feeDistributions[0].ratioToCover = 0.0
            feeDistributions[0].value = value
          }
        } else {
          feeDistributions[secondaryIndex!].ratioToCover = -(value / 10)
          feeDistributions[secondaryIndex!].ratioCoveredByMe = 0.0
          if (
            secondaryIndex === feeDistributions.length - 2 &&
            currentSnapshot.includeTopLFDLimit !== true
          ) {
            feeDistributions[feeDistributions.length - 1!].ratioToCover = -(
              value / 10
            )
            feeDistributions[
              feeDistributions.length - 1!
            ].ratioCoveredByMe = 0.0
            feeDistributions[feeDistributions.length - 1!].value = value
          }
          if (
            secondaryIndex === 1 &&
            currentSnapshot.includeBottomLFDLimit !== true
          ) {
            feeDistributions[0].ratioToCover = -(value / 10)
            feeDistributions[0].ratioCoveredByMe = 0.0
            feeDistributions[0].value = value
          }
        }
        feeDistributions[secondaryIndex!].value = value
      }

      if (value[0] === 'add') {
        let feeDistributionIndex = feeDistributions.length - 2 - value[1]
        let tempFeeDistributions = deepCloneObject(
          feeDistributions,
        ) as LegalFeesDistributionObject[]
        tempFeeDistributions.splice(
          feeDistributionIndex,
          0,
          deepCloneObject(
            feeDistributions[feeDistributionIndex],
          ) as LegalFeesDistributionObject,
        )
        tempFeeDistributions[feeDistributionIndex].range[1] =
          (feeDistributions[feeDistributionIndex].range[0] +
            feeDistributions[feeDistributionIndex].range[1]) /
          2
        tempFeeDistributions[feeDistributionIndex + 1].range[0] =
          (feeDistributions[feeDistributionIndex].range[0] +
            feeDistributions[feeDistributionIndex].range[1]) /
          2
        for (let i = 0; i < tempFeeDistributions.length; i++) {
          tempFeeDistributions[i].id = i
        }
        currentSnapshot.legalFeesDistribution = tempFeeDistributions
      }

      if (value[0] === 'remove') {
        let feeDistributionIndex = feeDistributions.length - 2 - value[1]
        let tempFeeDistributions = deepCloneObject(
          feeDistributions,
        ) as LegalFeesDistributionObject[]
        tempFeeDistributions[feeDistributionIndex + 1].range[0] =
          feeDistributions[feeDistributionIndex].range[0]

        tempFeeDistributions.splice(feeDistributionIndex, 1)
        for (let i = 0; i < tempFeeDistributions.length; i++) {
          tempFeeDistributions[i].id = i
        }
        currentSnapshot.legalFeesDistribution = tempFeeDistributions
      }
      if (value[0] === 'changeSegmentHeight') {
        let feeDistributionIndex = feeDistributions.length - 2 - value[1]
        feeDistributions[feeDistributionIndex].range[1] = value[2]

        feeDistributions[feeDistributionIndex + 1].range[0] = value[2]
      }
      if (value[0] === 'changeSegmentAmount') {
        let feeDistributionIndex = feeDistributions.length - 2 - value[1]
        let objectExists = false
        for (let feeDistributionObject of feeDistributions) {
          if (
            Math.abs(feeDistributionObject.range[1] - value[2] * 100) <=
              0.001 &&
            feeDistributionObject.id !== feeDistributionIndex
          ) {
            objectExists = true
            break
          }
        }

        let tempFeeDistributions = deepCloneObject(
          feeDistributions,
        ) as LegalFeesDistributionObject[]
        let myFeeDistribution = deepCloneObject(
          tempFeeDistributions[feeDistributionIndex],
        ) as LegalFeesDistributionObject

        myFeeDistribution.range[1] = value[2] * 100
        tempFeeDistributions[feeDistributionIndex + 1].range[0] =
          myFeeDistribution.range[0]
        myFeeDistribution.range[0] = myFeeDistribution.range[1]
        tempFeeDistributions.splice(feeDistributionIndex, 1)

        if (!objectExists) {
          let spliceIndex = 0

          for (let i = 0; i < tempFeeDistributions.length; i++) {
            if (myFeeDistribution.range[1] < tempFeeDistributions[i].range[1]) {
              spliceIndex = i
              break
            }
          }

          tempFeeDistributions.splice(spliceIndex, 0, myFeeDistribution)
          tempFeeDistributions[spliceIndex].range[0] =
            tempFeeDistributions[spliceIndex - 1].range[1]
          tempFeeDistributions[spliceIndex + 1].range[0] =
            tempFeeDistributions[spliceIndex].range[1]
        }

        for (let i = 0; i < tempFeeDistributions.length; i++) {
          tempFeeDistributions[i].id = i
        }
        currentSnapshot.legalFeesDistribution = tempFeeDistributions
      }

      if (value === 'linear') {
        let feeDistributionsLength = feeDistributions.length
        let numberOfSegments = feeDistributionsLength - 2
        let divisionSegment =
          numberOfSegments === 1 ? 20 : 20 / (numberOfSegments - 1)
        feeDistributions[0].value = -10
        feeDistributions[0].ratioCoveredByMe = 0
        feeDistributions[0].ratioToCover = 1
        feeDistributions[feeDistributionsLength - 1].value = 10
        feeDistributions[feeDistributionsLength - 1].ratioCoveredByMe = 1
        feeDistributions[feeDistributionsLength - 1].ratioToCover = 0
        for (let i = 0; i < numberOfSegments; i++) {
          feeDistributions[i + 1].value =
            numberOfSegments === 1 ? 0 : Math.round(-10 + i * divisionSegment)
          if (feeDistributions[i + 1].value <= 0) {
            feeDistributions[i + 1].ratioCoveredByMe = 0
            feeDistributions[i + 1].ratioToCover =
              -feeDistributions[i + 1].value / 10
          } else {
            feeDistributions[i + 1].ratioCoveredByMe =
              feeDistributions[i + 1].value / 10
            feeDistributions[i + 1].ratioToCover = 0
          }
        }
      }
      if (value === 'reset') {
        let feeDistributionsLength = feeDistributions.length
        for (let i = 0; i < feeDistributionsLength; i++) {
          feeDistributions[i].value = 0
          feeDistributions[i].ratioCoveredByMe = 0
          feeDistributions[i].ratioToCover = 0
        }
      }
      if (value === 'resetRanges') {
        let feeDistributionsLength = feeDistributions.length
        let numberOfRanges = feeDistributionsLength - 2
        let rangeHeight = 100 / numberOfRanges
        let startingRange = 0
        let endingRange = rangeHeight
        for (let i = 1; i < feeDistributionsLength - 1; i++) {
          feeDistributions[i].range[0] = startingRange
          feeDistributions[i].range[1] = endingRange
          startingRange += rangeHeight
          endingRange += rangeHeight
        }
      }

      break
    case 'includeTopLFDLimit':
      let tempFeeDistributions = currentSnapshot.legalFeesDistribution

      if (value === false) {
        tempFeeDistributions[tempFeeDistributions.length - 1].value =
          tempFeeDistributions[tempFeeDistributions.length - 2].value
        tempFeeDistributions[tempFeeDistributions.length - 1].ratioCoveredByMe =
          tempFeeDistributions[tempFeeDistributions.length - 2].ratioCoveredByMe
        tempFeeDistributions[tempFeeDistributions.length - 1].ratioToCover =
          tempFeeDistributions[tempFeeDistributions.length - 2].ratioToCover
      }
      currentSnapshot.includeTopLFDLimit = value
      break
    case 'includeBottomLFDLimit':
      let tempFeeDistributions2 = currentSnapshot.legalFeesDistribution

      if (value === false) {
        tempFeeDistributions2[0].value = tempFeeDistributions2[1].value
        tempFeeDistributions2[0].ratioCoveredByMe =
          tempFeeDistributions2[1].ratioCoveredByMe
        tempFeeDistributions2[0].ratioToCover =
          tempFeeDistributions2[1].ratioToCover
      }
      currentSnapshot.includeBottomLFDLimit = value

      break
    case 'claims':
      const currentClaim = currentSnapshot.claims[
        claimIndex!
      ] as IndependentClaim
      let claimsArray
      switch (key2) {
        case 'tree':
          let tempTargetId = targetId
          if (typeof targetId !== 'string') {
            tempTargetId = targetId[1]
          }
          if (tempTargetId.includes('treeNameInput')) {
            ;(currentSnapshot.claims[claimIndex!] as TreeClaim).name = value
          } else if (
            tempTargetId.includes('zoomInBtn') ||
            tempTargetId.includes('zoomOutBtn')
          ) {
            ;(currentSnapshot.claims[claimIndex!] as TreeClaim).zoomLevel =
              value
          } else {
            currentSnapshot.claims[claimIndex!] = value

            if (
              !tempTargetId.includes('treeNodeMoveHandle') &&
              !tempTargetId.includes('treeNodeTitleTextarea') &&
              !tempTargetId.includes('treeEventTitleInput') &&
              !tempTargetId.includes('treeEventAwardedAmountInput') &&
              !tempTargetId.includes('treeEventOutOfCourtAmountInput') &&
              !tempTargetId.includes('treeEventOutOfCourtAmountSignumSwitch') &&
              !tempTargetId.includes('treeEventIncludeInterestButtonImg') &&
              !tempTargetId.includes('treeEventProbabilityInput') &&
              !tempTargetId.includes('treeEventAwardedAmountContainer') &&
              !tempTargetId.includes(
                'treeEventAddSectionButtonOutOfCourtAmount',
              ) &&
              !tempTargetId.includes('treeEventInterestDateInput') &&
              !tempTargetId.includes('treeEventInterestRateInput') &&
              !tempTargetId.includes('treeEventAwardedAmountSignumSwitch') &&
              !tempTargetId.includes('treeSideMenuButton')
            ) {
              let startTime = performance.now()

              let numberOfScenarios = calculateNumberOfScenarios(
                (currentSnapshot.claims[claimIndex!] as TreeClaim).treeDetails,
                startTime,
                TREE_CALCULATION_TIMER,
              )

              if (typeof numberOfScenarios === 'string') {
                numberOfScenarios = 45000
              }

              ;(
                currentSnapshot.claims[claimIndex!] as TreeClaim
              ).numOfTreeScenarios = numberOfScenarios

              for (let nodeId of Object.keys(
                (currentSnapshot.claims[claimIndex!] as TreeClaim).treeDetails
                  .nodes,
              )) {
                ;(
                  currentSnapshot.claims[claimIndex!] as TreeClaim
                ).treeDetails = resetNodeSiblingsIndex(
                  nodeId as NodeId,
                  (currentSnapshot.claims[claimIndex!] as TreeClaim)
                    .treeDetails,
                )
              }
            }
          }

          break
        case 'add':
          currentSnapshot.claims.push(value)
          claimsArray = claimtypeIdArray(currentSnapshot)
          tempScenarioSnapshot.currentSnapshot = handleClaimtypeIdChange(
            claimsArray[0],
            currentSnapshot,
          )
          break
        case 'duplicate':
          currentSnapshot.claims.splice(value[0], 0, value[1])
          claimsArray = claimtypeIdArray(currentSnapshot)
          tempScenarioSnapshot.currentSnapshot = handleClaimtypeIdChange(
            claimsArray[0],
            currentSnapshot,
          )
          break
        case 'changeOrder':
          let claimToReorder = currentSnapshot.claims[value[0]]
          currentSnapshot.claims.splice(value[0], 1)
          currentSnapshot.claims.splice(value[1], 0, claimToReorder)
          claimsArray = claimtypeIdArray(currentSnapshot)
          tempScenarioSnapshot.currentSnapshot = handleClaimtypeIdChange(
            claimsArray[0],
            currentSnapshot,
          )
          break

        case 'convertToTree':
          currentSnapshot.claims[value[0]] = value[1]
          claimsArray = claimtypeIdArray(currentSnapshot)
          tempScenarioSnapshot.currentSnapshot = handleClaimtypeIdChange(
            claimsArray[0],
            currentSnapshot,
          )
          break
        case 'type':
          // change the claims.[claimIndex]type to 'claim' or 'counterclaim'
          currentSnapshot.claims[claimIndex!].type = value
          let tempAmount = (
            currentSnapshot.claims[claimIndex!]! as IndependentClaim
          ).amount

          // change the amount and each quantumScenario amount to positive if 'claim' or to negative if 'counterclaim'
          if (value === 'claim') {
            if (tempAmount) {
              ;(
                currentSnapshot.claims[claimIndex!]! as IndependentClaim
              ).amount = Math.abs(tempAmount)
            }
            ;(
              currentSnapshot.claims[claimIndex!]! as IndependentClaim
            ).quantumScenarios.map(
              (item) =>
                (item.amount =
                  item.amount !== undefined
                    ? Math.abs(item.amount)
                    : undefined),
            )
          } else if (value === 'counterclaim') {
            if (tempAmount) {
              ;(
                currentSnapshot.claims[claimIndex!]! as IndependentClaim
              ).amount = -Math.abs(tempAmount)
            }
            ;(
              currentSnapshot.claims[claimIndex!]! as IndependentClaim
            ).quantumScenarios.map(
              (item) =>
                (item.amount =
                  item.amount !== undefined
                    ? -Math.abs(item.amount)
                    : undefined),
            )
          }

          //change the claimtypeId of each claim
          claimsArray = claimtypeIdArray(currentSnapshot)

          tempScenarioSnapshot.currentSnapshot = handleClaimtypeIdChange(
            claimsArray[0],
            currentSnapshot,
          )

          break
        case 'amount':
          // change the claim amount signum
          if (tempValue === undefined) {
            tempValue = undefined
          } else {
            if (currentSnapshot.claims[claimIndex!].type === 'counterclaim') {
              tempValue = -tempValue
            }
          }

          // set the claim amount
          ;(currentSnapshot[key][claimIndex!] as IndependentClaim).amount =
            tempValue

          // change the amount in the first quantumScenarios object
          ;(
            currentSnapshot[key][claimIndex!] as IndependentClaim
          ).quantumScenarios[0].amount = tempValue

          break
        case 'isInterest':
          if (value === false) {
            ;(
              currentSnapshot[key][claimIndex!] as IndependentClaim
            ).interestRate = undefined
          }
          ;(currentSnapshot[key][claimIndex!] as IndependentClaim).isInterest =
            value
          break
        case 'interestRate':
          ;(
            currentSnapshot[key][claimIndex!] as IndependentClaim
          ).interestRate = value
          break
        case 'liabilityRequirements':
          switch (key3) {
            case 'title':
              currentClaim.liabilityRequirements[secondaryIndex!].title = value
              break
            case 'probability':
              // if probability of liability is '' or undefined set it to 0
              tempValue = value

              currentClaim.liabilityRequirements[secondaryIndex!].probability =
                tempValue

              // calculate the new liability and set it to state
              let tempLiability = 1
              currentClaim.liabilityRequirements.forEach((item) => {
                let tempProbability = item.probability
                if (tempProbability === undefined) {
                  tempProbability = 0
                }
                tempLiability *= item.probability / 100
              })
              currentClaim.liability = tempLiability

              // if the new liability is less than 1 change the liabilityOn element or add a new one
              if (tempLiability < 1) {
                if (
                  currentClaim.quantumScenarios[
                    currentClaim.quantumScenarios.length - 1
                  ].liabilityOn
                ) {
                  //change the liabilityOn element's probability to 1 - liability
                  currentClaim.quantumScenarios[
                    currentClaim.quantumScenarios.length - 1
                  ].probability = 1 - tempLiability

                  // multiply each quantumProbabilities's probability with tempLiability /100 and set it to quantumScenarios
                  for (
                    let i = 0;
                    i < currentClaim.quantumScenarios.length - 1;
                    i++
                  ) {
                    currentClaim.quantumScenarios[i].probability =
                      (currentClaim.quantumProbabilities[i] * tempLiability) /
                      100
                  }
                } else {
                  //if there was no liabilityOn element before, multiply each quantum with templiabiity and push the liabilityOn element
                  currentClaim.quantumScenarios.forEach(
                    (item, index) =>
                      (item.probability =
                        (currentClaim.quantumProbabilities[index] *
                          tempLiability) /
                        100),
                  )

                  currentClaim.quantumScenarios.push(
                    QuantumScenario.AdditionalLiabilityQuantumScenario(
                      tempLiability,
                    ),
                  )
                }
              } else if (tempLiability === 1) {
                //if tempLiability is 1, then erase the liabilityOn scenario
                if (
                  currentClaim.quantumScenarios[
                    currentClaim.quantumScenarios.length - 1
                  ].liabilityOn
                ) {
                  currentClaim.quantumScenarios.pop()
                }
                currentClaim.quantumScenarios.forEach(
                  (item, index) =>
                    (item.probability =
                      (currentClaim.quantumProbabilities[index] *
                        tempLiability) /
                      100),
                )
              }

              break
            default:
              break
          }
          if (value === 'remove') {
            currentClaim.liabilityRequirements.splice(secondaryIndex!, 1)
            let tempLiability = 1
            currentClaim.liabilityRequirements.forEach((item) => {
              tempLiability *= item.probability / 100
            })
            currentClaim.liability = tempLiability

            // if the new liability is less than 1 change the liabilityOn element or add a new one
            if (tempLiability < 1) {
              if (
                currentClaim.quantumScenarios[
                  currentClaim.quantumScenarios.length - 1
                ].liabilityOn
              ) {
                //change the liabilityOn element's probability to 1 - liability
                currentClaim.quantumScenarios[
                  currentClaim.quantumScenarios.length - 1
                ].probability = 1 - tempLiability

                // multiply each quantumProbabilities's probability with tempLiability /100 and set it to quantumScenarios
                for (
                  let i = 0;
                  i < currentClaim.quantumScenarios.length - 1;
                  i++
                ) {
                  currentClaim.quantumScenarios[i].probability =
                    (currentClaim.quantumProbabilities[i] * tempLiability) / 100
                }
              } else {
                //if there was no liabilityOn element before, multiply each quantum with templiabiity and push the liabilityOn element
                currentClaim.quantumScenarios.forEach(
                  (item, index) =>
                    (item.probability =
                      (currentClaim.quantumProbabilities[index] *
                        tempLiability) /
                      100),
                )

                currentClaim.quantumScenarios.push(
                  QuantumScenario.AdditionalLiabilityQuantumScenario(
                    tempLiability,
                  ),
                )
              }
            } else if (tempLiability === 1) {
              //if tempLiability is 1, then erase the liabilityOn scenario
              if (
                currentClaim.quantumScenarios[
                  currentClaim.quantumScenarios.length - 1
                ].liabilityOn
              ) {
                currentClaim.quantumScenarios.pop()
              }
              currentClaim.quantumScenarios.forEach(
                (item, index) =>
                  (item.probability =
                    (currentClaim.quantumProbabilities[index] * tempLiability) /
                    100),
              )
            }
          }
          break
        case 'quantumProbabilities':
          if (value === 'add') {
            currentClaim.quantumProbabilities.push(0)
            if (currentClaim.liability === 1) {
              currentClaim.quantumScenarios.push(
                QuantumScenario.AdditionalQuantumScenario(),
              )
            } else {
              currentClaim.quantumScenarios.splice(
                currentClaim.quantumScenarios.length - 1,
                0,
                QuantumScenario.AdditionalQuantumScenario(),
              )
            }
          } else if (value === 'remove') {
            let tempProbabilities = [...currentClaim.quantumProbabilities]
            let lastProbability = tempProbabilities.pop()
            tempProbabilities[tempProbabilities.length - 1] =
              tempProbabilities[tempProbabilities.length - 1] + lastProbability!
            currentClaim.quantumProbabilities = tempProbabilities

            currentClaim.quantumScenarios.splice(secondaryIndex!, 1)
            currentClaim.quantumProbabilities.forEach((item, index) => {
              if (!currentClaim.quantumScenarios[index].liabilityOn) {
                currentClaim.quantumScenarios[index].probability =
                  (item * currentClaim.liability) / 100
              }
            })
          }
          break
        case 'hasOutOfCourt':
          if (value === true) {
            currentClaim.hasOutOfCourt = true
          } else {
            currentClaim.hasOutOfCourt = false
            currentClaim.quantumScenarios.forEach((item) => {
              item.outOfCourtSignum = AmountSignum.off
              item.outOfCourtAmount = 0
            })
          }
          break
        case 'quantumScenarios':
          switch (key3) {
            case 'amount':
              if (typeof tempValue === 'number') {
                if (currentClaim.type === 'counterclaim') {
                  tempValue = -tempValue
                }
              }

              currentClaim.quantumScenarios[secondaryIndex!].amount = tempValue
              break
            case 'probability':
              if (!value || value === '') {
                tempValue = 0
              }
              let currentProbabilities = currentClaim.quantumProbabilities
              currentProbabilities = changeProbabilities(
                currentProbabilities,
                secondaryIndex,
                tempValue,
              )

              currentProbabilities.forEach((item, index) => {
                if (!currentClaim.quantumScenarios[index].liabilityOn) {
                  currentClaim.quantumScenarios[index].probability =
                    (item * currentClaim.liability) / 100
                }
              })

              break
            case 'outOfCourtAmount':
              if (
                currentClaim.quantumScenarios[secondaryIndex!]
                  .outOfCourtSignum === AmountSignum.pay &&
                value !== undefined
              ) {
                tempValue = -Math.abs(value)
              }
              currentClaim.quantumScenarios[secondaryIndex!].outOfCourtAmount =
                tempValue
              break
            case 'outOfCourtSignum':
              if (value === 'get') {
                currentClaim.quantumScenarios[
                  secondaryIndex!
                ].outOfCourtSignum = AmountSignum.get
                currentClaim.quantumScenarios[
                  secondaryIndex!
                ].outOfCourtAmount =
                  currentClaim.quantumScenarios[secondaryIndex!]
                    .outOfCourtAmount !== undefined
                    ? Math.abs(
                        currentClaim.quantumScenarios[secondaryIndex!]
                          .outOfCourtAmount!,
                      )
                    : undefined
              } else if (value === 'pay') {
                currentClaim.quantumScenarios[
                  secondaryIndex!
                ].outOfCourtSignum = AmountSignum.pay
                currentClaim.quantumScenarios[
                  secondaryIndex!
                ].outOfCourtAmount =
                  currentClaim.quantumScenarios[secondaryIndex!]
                    .outOfCourtAmount !== undefined
                    ? -Math.abs(
                        currentClaim.quantumScenarios[secondaryIndex!]
                          .outOfCourtAmount!,
                      )
                    : undefined
              } else if (value === 'off') {
                currentClaim.quantumScenarios[
                  secondaryIndex!
                ].outOfCourtSignum = AmountSignum.off
                currentClaim.quantumScenarios[
                  secondaryIndex!
                ].outOfCourtAmount = undefined
              }
              break
            default:
              break
          }
          break
        case 'remove':
          //does nothing because I remove the claim in ToolPage in order to update the claimtypeId
          break
        default:
          ;(currentSnapshot[key][claimIndex!] as any)[key2] = value
      }
      break
    case 'claimsPreviewStyle':
      if (!key2) {
        ;(currentSnapshot[key] as any) = value
      } else {
        currentSnapshot.claims.sort(getClaimListSortingMethod(value))
      }
      break
    default:
      ;(currentSnapshot[key] as any) = value
  }

  if (
    !secondTrialDateIsValid(tempScenarioSnapshot.currentSnapshot) ||
    !atLeastOneClaimHasInterest(tempScenarioSnapshot.currentSnapshot)
  ) {
    if (
      tempScenarioSnapshot.currentSnapshot.interestViewOption ===
      InterestViewOption.interest1st
    ) {
      if (tempScenarioSnapshot.currentSnapshot.firstTrialDate === undefined) {
        tempScenarioSnapshot.currentSnapshot.interestViewOption =
          InterestViewOption.noInterest
      }
    } else {
      tempScenarioSnapshot.currentSnapshot.interestViewOption =
        InterestViewOption.noInterest
    }
  }

  tempScenarioSnapshot.currentSnapshot.expectedResults =
    calculateExpectedResults(tempScenarioSnapshot.currentSnapshot)

  let tempUndoRedo = {
    id: targetId,
    type: undoRedoType,
    snapshot: deepCloneObject(tempScenarioSnapshot.currentSnapshot),
    time: new Date(),
    nodesMovedForEditMode: nodesMovedForEditMode,
  }

  const { undoRedoIndex, undoRedo } = tempScenarioSnapshot

  if (undoRedoIndex < undoRedo.length - 1) {
    undoRedo.splice(undoRedoIndex + 1)
  }

  undoRedo.push(tempUndoRedo)
  tempScenarioSnapshot.undoRedoIndex++
  if (undoRedoIndex >= 200) {
    undoRedo.shift()
    tempScenarioSnapshot.undoRedoIndex = 199
  }
  return tempScenarioSnapshot
}
