import React, { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import Tabs from '../../Components/tabs/Tabs'
import { TutorialTabs } from '../../models/enums'
import { pageDepthState } from '../../states/PageDepthState'
import Tutorials from './tabs/1_tutorials/Tutorials'
import UserManual from './tabs/2_userManual/UserManual'
import { freemiumState } from '../../states/FreemiumState'
import { logActivity } from '../../services/commonFunctions'

type Props = {
  loadingGif: string
  setHeaderTutorialTitle: (param: string) => void
}

export default function Academy(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const [activeTurorialTab, setActiveTutorialTab] = useState(
    TutorialTabs.Tutorials,
  )

  const [openTutorial, setOpenTutorial] = useState<undefined | string>(
    undefined,
  )
  const setPageDepth = useSetRecoilState(pageDepthState)

  useEffect(() => {
    setPageDepth(0.9)
    //Mixpanel 81 (All)
    logActivity(freemium.isFreemium, 'Accessed Academy')
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="toolPageComponent tutorialPageComponent">
        <div className="homescreen tutorialHomescreen">
          <Tabs
            type="TutorialTabs"
            activeTab={activeTurorialTab}
            setActiveTab={setActiveTutorialTab}
            setOpenTutorial={setOpenTutorial}
          />
        </div>
        {activeTurorialTab === TutorialTabs.UserManual ? (
          <UserManual loadingGif={props.loadingGif} />
        ) : activeTurorialTab === TutorialTabs.Tutorials ? (
          <Tutorials
            loadingGif={props.loadingGif}
            setHeaderTutorialTitle={props.setHeaderTutorialTitle}
            setOpenTutorial={setOpenTutorial}
            openTutorial={openTutorial}
          />
        ) : null}
      </div>
    </>
  )
}
