import { atom } from 'recoil'
import { HandlingErrorType } from '../models/generalTypes'

export const handlingErrorsState = atom<HandlingErrorType>({
  key: 'handlingErrorsState',
  default: {
    unauthorized: false,
    forbidden: false,
    bad_request: false,
    server_error: false,
    timeout: false,
    fatal: false,
    not_exist: false,
    wrong_reduced_dates: false,
  },
})
