type Props = {
  maxAmount: number
  minAmount: number
  widthOfDiv: number
  sampleData: number[][]
  customWidth?: number
  spotGraph?: boolean
  zeroIndex?: number
  [key: string]: any
}

export default function BarSvg(props: Props) {
  const offsetY = props.height

  const y = props.y
  //const depth = y === offsetY - 50 ? 0 : 3
  let offsetX = props.spotGraph ? 0 : 12.5

  const width = () => {
    if (props.sampleData.length < 2) {
      return 0
    }
    if (props.customWidth) {
      return props.customWidth
    }
    let barWidth = 0
    if (props.maxAmount - props.minAmount !== 0) {
      barWidth =
        (props.widthOfDiv *
          Math.abs(
            props.sampleData[parseInt(props.index)][0] -
              props.sampleData[parseInt(props.index)][1],
          )) /
        Math.abs(props.maxAmount - props.minAmount)
    }

    if (
      props.index === 0 &&
      Math.abs(
        props.sampleData[parseInt(props.index)][0] -
          props.sampleData[parseInt(props.index)][1],
      ) <
        Math.abs(
          props.sampleData[parseInt(props.index) + 1][0] -
            props.sampleData[parseInt(props.index) + 1][1],
        )
    ) {
      offsetX = props.spotGraph
        ? 0
        : (Math.abs(
            props.sampleData[parseInt(props.index)][0] -
              props.sampleData[parseInt(props.index)][1],
          ) /
            Math.abs(
              props.sampleData[parseInt(props.index) + 1][0] -
                props.sampleData[parseInt(props.index) + 1][1],
            )) *
          12.5
    }

    if (
      props.index === props.sampleData.length - 1 &&
      Math.abs(
        props.sampleData[parseInt(props.index)][0] -
          props.sampleData[parseInt(props.index)][1],
      ) <
        Math.abs(
          props.sampleData[parseInt(props.index) - 1][0] -
            props.sampleData[parseInt(props.index) - 1][1],
        )
    ) {
      offsetX = props.spotGraph
        ? 0
        : (Math.abs(
            props.sampleData[parseInt(props.index)][0] -
              props.sampleData[parseInt(props.index)][1],
          ) /
            Math.abs(
              props.sampleData[parseInt(props.index) - 1][0] -
                props.sampleData[parseInt(props.index) - 1][1],
            )) *
          12.5
    }

    return barWidth - (1 / 3) * barWidth
  }

  const x1 = props.x - width() / 2

  return (
    <>
      <svg
        height={offsetY}
        width={props.width}
        id={`distributionBar-${props.index}`}
      >
        <path
          //main rectangle
          fill={
            props.index === props.zeroIndex
              ? 'gray'
              : props.datum[0] >= 0
              ? '#47743d'
              : '#ac2828'
          } //infantryWest - //greekEaster
          d={`M${x1 + offsetX} ${offsetY - 50} L${x1} ${offsetY - 50} L${
            x1 + offsetX + width()
          } ${offsetY - 50} L${x1 + offsetX + width()} ${y} L${
            x1 + offsetX
          } ${y}
          `}
        />
        {/* <path
          //shadow rectangle
          fill={props.datum[0] >= 0 ? '#c7ffba' : '#ffdddd'} //fujiApple
          d={`M${x1 + width()} ${offsetY - 50} L${x1 + width() + depth} ${
            offsetY - 50 - depth
          } L${x1 + width() + depth} ${y - depth} L${x1 + depth} ${
            y - depth
          } L${x1} ${y} L${x1 + width()} ${y}
          `}
        /> */}
        {/* <line
          //line
          style={
            props.datum[0] >= 0
              ? { stroke: '#201a2d', strokeWidth: 0.3 }
              : { stroke: '#201a2d', strokeWidth: 0.3 }
          }
          x1={x1 + width() + depth}
          y1={y - depth}
          x2={x1 + width()}
          y2={y}
        /> */}
        {props.spotGraph && props.datum[1] !== undefined ? (
          <circle
            // circle on top of the line
            id={`distributionBarCircle-${props.index}`}
            cx={x1 + width() / 2} // X-coordinate of the center of the circle
            cy={y} // Y-coordinate of the center of the circle
            r={2} // radius of the circle
            fill={'white'} //infantryWest - //greekEaster
            stroke={
              props.index === props.zeroIndex
                ? 'gray'
                : props.datum[0] >= 0
                ? '#47743d'
                : '#ac2828'
            }
            strokeWidth={1}
          />
        ) : (
          <circle
            // circle on top of the line
            id={`distributionBarCircle-${props.index}`}
            cx={x1 + width() / 2} // X-coordinate of the center of the circle
            cy={y} // Y-coordinate of the center of the circle
            r={2} // radius of the circle
            fill={'transparent'} //infantryWest - //greekEaster
          />
        )}
      </svg>
    </>
  )
}
