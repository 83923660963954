import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Button from '../../../../../Components/Buttons/Button/Button'
import InfoClickComponent from '../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import DropDown from '../../../../../Components/Inputs/DropDown/DropDown'
import InputText from '../../../../../Components/Inputs/InputText/InputText'
import {
  UserRole,
  UserRoleKeys,
  userRoleStringToKeyString,
} from '../../../../../models/enums'
import { SubscriptionPlan } from '../../../../../models/generalTypes'
import { User } from '../../../../../models/user'
import { dropDownValidation } from '../../../../../Modules/AppModules/ValidationFunctions'
import {
  findHandlingErrorState,
  getFullUserRolesAndDescriptions,
  getFullUserRolesAndDescriptionsTetxAttributes,
  getUserRoleDescription,
  logActivity,
} from '../../../../../services/commonFunctions'
import { convertDateTimeToLocal } from '../../../../../services/dateFunctions'
import { sendWelcomeEmail } from '../../../../../services/requests'
import { getText } from '../../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { userState } from '../../../../../states/UserState'
import SettingInfoRow from '../../components/SettingInfoRow'

type Props = {
  detailedUser: User
  hoverElement: string
  setHoverElement: (value: string) => void
  loading: boolean
  setLoading: (value: boolean) => void
  onUpdateUser: (body: {
    username: string
    first_name?: string
    last_name?: string
    role?: string
  }) => void
  showSaved: boolean
  showUpdateButton: boolean
  setShowUpdateButton: (value: boolean) => void
  subscriptionPlan: SubscriptionPlan
}

const UserDetails = (props: Props) => {
  const user = useRecoilValue(userState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [firstNameError, setFirstNameError] = useState(false)
  const [lastNameError, setLastNameError] = useState(false)
  const [userRoleError, setUserRoleError] = useState<string | undefined>(
    undefined,
  )
  const [firstName, setFirstName] = useState(props.detailedUser.firstName)
  const [lastName, setLastName] = useState(props.detailedUser.lastName)
  const [userRole, setUserRole] = useState(props.detailedUser.role.toString())
  const [hasSentEmail, setHasSentEmail] = useState(false)

  useEffect(() => {
    setUserRole(props.detailedUser.role.toString())
  }, [props.detailedUser.role])

  const checkUpdateButton = (id: string) => {
    switch (id) {
      case 'firstName':
        if (
          lastName !== props.detailedUser.lastName ||
          userRole !== props.detailedUser.role.toString()
        ) {
          props.setShowUpdateButton(true)
        } else {
          props.setShowUpdateButton(false)
        }
        break
      case 'lastName':
        if (
          firstName !== props.detailedUser.firstName ||
          userRole !== props.detailedUser.role.toString()
        ) {
          props.setShowUpdateButton(true)
        } else {
          props.setShowUpdateButton(false)
        }
        break
      case 'userRole':
        if (
          lastName !== props.detailedUser.lastName ||
          firstName !== props.detailedUser.firstName
        ) {
          props.setShowUpdateButton(true)
        } else {
          props.setShowUpdateButton(false)
        }
        break
    }
  }

  const onUpdateUser = () => {
    props.setLoading(true)
    let body: {
      username: string
      first_name?: string
      last_name?: string
      role?: string
    } = {
      username: props.detailedUser.email,
    }

    if (firstName !== props.detailedUser.firstName) {
      body.first_name = firstName
    }
    if (lastName !== props.detailedUser.lastName) {
      body.last_name = lastName
    }
    if (userRole !== props.detailedUser.role.toString()) {
      body.role = userRoleStringToKeyString(userRole)
    }

    props.onUpdateUser(body)
  }

  const onReminderEmail = () => {
    sendWelcomeEmail({ username: props.detailedUser.email }).then((res) => {
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'updateUser'),
      )

      if (!('errorCode' in res)) {
        setHasSentEmail(true)
        //Mixpanel 107
        logActivity(false, 'Pressed "Send reminder email" in User Details')
      }
    })
  }

  const getAvailableOptions = () => {
    const availableOptions = [
      UserRole.APP_VIEWER.toString(),
      UserRole.SIMPLE_USER.toString(),
      UserRole.COMPANY_ADMIN.toString(),
    ]

    if (
      user.role === UserRole.GLOBAL_ADMIN ||
      user.role === UserRole.EPEROTO_ADMIN ||
      !allowedToChangeRole()
    ) {
      availableOptions.push(UserRole.GLOBAL_ADMIN.toString())
    }

    if (
      user.role === UserRole.EPEROTO_ADMIN &&
      props.detailedUser.role === UserRole.EPEROTO_ADMIN
    ) {
      availableOptions.push(UserRole.EPEROTO_ADMIN.toString())
    }

    return availableOptions
  }

  const allowedToChangeRole = () => {
    if (
      (user.role === UserRole.COMPANY_ADMIN &&
        props.detailedUser.role === UserRole.GLOBAL_ADMIN) ||
      props.detailedUser.role === UserRole.EPEROTO_ADMIN
    ) {
      return false
    }

    return true
  }

  const checkSubscriptionError = (newRole: UserRole) => {
    if (
      props.subscriptionPlan.allowed_number_of_spectators <=
        props.subscriptionPlan.number_of_spectators &&
      newRole === UserRole.APP_VIEWER &&
      props.detailedUser.role !== UserRole.APP_VIEWER
    ) {
      //data-textattribute = 'message-89'
      setUserRoleError(
        `${props.subscriptionPlan.number_of_spectators}/${
          props.subscriptionPlan.allowed_number_of_spectators
        } ${getText('message-89', user.settings)}`,
      )
      return true
    } else if (
      props.subscriptionPlan.allowed_number_of_users <=
        props.subscriptionPlan.number_of_users &&
      newRole !== UserRole.APP_VIEWER &&
      props.detailedUser.role === UserRole.APP_VIEWER
    ) {
      //data-textattribute = 'message-90'
      setUserRoleError(
        `${props.subscriptionPlan.number_of_users}/${
          props.subscriptionPlan.allowed_number_of_users
        } ${getText('message-90', user.settings)}`,
      )
      return true
    }

    return false
  }

  return (
    <>
      <div className="titleContainer">
        <h2
          className="welcome"
          id="userDetailsTitle"
          data-textattribute="title-183"
        >
          {getText('title-183', user.settings)}
        </h2>
      </div>
      <SettingInfoRow
        id="userEmailInfo"
        descriptionText={getText('description-123', user.settings)}
        descriptionTextAttribute="description-123"
        secondaryDescriptionText={props.detailedUser.email}
        forDetails
        isFirst
        obscureDescriptionText
      />
      <p
        className="rowContainer"
        style={{ marginTop: '2px', color: 'grey', fontSize: '15px' }}
        id="userDetailsDescriptionText"
        data-textattribute="description-132"
      >
        {getText('description-132', user.settings)}
      </p>
      <SettingInfoRow
        id="userStatusInfo"
        descriptionText={getText('description-124', user.settings)}
        descriptionTextAttribute="description-124"
        secondaryDescriptionText={
          props.detailedUser.hasRegistered === 'Yes'
            ? getText('description-125', user.settings)
            : getText('description-126', user.settings)
        }
        secondaryDescriptionTextAttribute={
          props.detailedUser.hasRegistered === 'Yes'
            ? 'description-125'
            : 'description-126'
        }
        forDetails
        valueText={
          props.detailedUser.hasRegistered === 'Yes'
            ? convertDateTimeToLocal(
                props.detailedUser.registeredDate!.toString(),
                user.settings,
                true,
              )
            : hasSentEmail
            ? getText('message-73', user.settings)
            : getText('message-74', user.settings)
        }
        valueTextAttribute={
          props.detailedUser.hasRegistered === 'Yes'
            ? undefined
            : hasSentEmail
            ? 'message-73'
            : 'message-74'
        }
        isClickable={
          props.detailedUser.hasRegistered !== 'Yes' && !hasSentEmail
        }
        onClick={
          props.detailedUser.hasRegistered !== 'Yes' && !hasSentEmail
            ? onReminderEmail
            : undefined
        }
        justBlack={hasSentEmail}
      />
      <div className="rowContainer input-container-first">
        <InputText
          label={getText('label-81', user.settings)}
          labelTextAttribute={'label-81'}
          value={firstName}
          className="form-control"
          name={'firstName'}
          onChange={(e) => {
            if (e.target.value.trim().length === 0) {
              setFirstNameError(true)
            } else {
              setFirstNameError(false)
            }

            if (e.target.value.trim() !== props.detailedUser.firstName) {
              props.setShowUpdateButton(true)
            } else {
              checkUpdateButton('firstName')
            }

            setFirstName(e.target.value)
          }}
          id={'firstName'}
          tabIndex={1001}
          width={400}
          error={firstNameError}
          errorMessage={getText('error-82', user.settings)}
          errorTextAttribute={'error-82'}
          alwaysShrink
        />
      </div>
      <div className="rowContainer input-container">
        <InputText
          label={getText('label-82', user.settings)}
          labelTextAttribute={'label-82'}
          value={lastName}
          className="form-control"
          name={'lastName'}
          onChange={(e) => {
            if (e.target.value.trim().length === 0) {
              setLastNameError(true)
            } else {
              setLastNameError(false)
            }

            if (e.target.value.trim() !== props.detailedUser.lastName) {
              props.setShowUpdateButton(true)
            } else {
              checkUpdateButton('lastName')
            }

            setLastName(e.target.value)
          }}
          id={'lastName'}
          tabIndex={1002}
          width={400}
          error={lastNameError}
          errorMessage={getText('error-83', user.settings)}
          errorTextAttribute={'error-83'}
          alwaysShrink
        />
      </div>
      <div className="rowContainer input-container">
        <DropDown
          id={'userRole'}
          name={'userRole'}
          label={getText('label-75', user.settings)}
          labelTextAttribute={'label-75'}
          value={userRole}
          width={400}
          options={getAvailableOptions()}
          validateFunction={(value: string) => dropDownValidation(value)}
          onChange={(e: any) => {
            if (
              checkSubscriptionError(
                UserRole[
                  userRoleStringToKeyString(e.target.value) as UserRoleKeys
                ],
              )
            ) {
              props.setShowUpdateButton(false)
            } else if (e.target.value !== props.detailedUser.role.toString()) {
              props.setShowUpdateButton(true)
              setUserRoleError(undefined)
            } else {
              checkUpdateButton('userRole')
              setUserRoleError(undefined)
            }

            setUserRole(e.target.value)
          }}
          helperText={getText(
            getUserRoleDescription(
              UserRole[userRoleStringToKeyString(userRole) as UserRoleKeys],
            ),
            user.settings,
          )}
          helperTextAttribute={getUserRoleDescription(
            UserRole[userRoleStringToKeyString(userRole) as UserRoleKeys],
          )}
          tabIndex={1003}
          disabled={!allowedToChangeRole()}
          error={userRoleError !== undefined}
          errorMessage={userRoleError}
        />
        <div
          style={{
            marginTop:
              userRole === 'Simple User' ||
              userRole === 'Eperoto Admin' ||
              userRoleError
                ? '-25px'
                : '-45px',
            marginRight:
              userRole === 'Simple User' ||
              userRole === 'Eperoto Admin' ||
              userRoleError
                ? '0px'
                : '40px',
          }}
        >
          <InfoClickComponent
            infoClickText={''}
            infoClickTextAttribute={undefined}
            idName={'userRole'}
            hoverElement={props.hoverElement}
            setHoverElement={props.setHoverElement}
            withTitles
            //pass getText() in titlesAndTexts
            titlesAndTexts={getFullUserRolesAndDescriptions()}
            titlesAndTextsAttributes={getFullUserRolesAndDescriptionsTetxAttributes()}
          />
        </div>
      </div>

      {props.showSaved && !props.showUpdateButton && (
        <div className={`rowContainer`}>
          <Button
            id={`updateUserButtonSaved`}
            buttonText={getText('button-100', user.settings)}
            buttonTextAttribute={'button-100'}
            buttonType="nonactiveText"
            className="createCaseButton"
            type="button"
            onClick={() => {}}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        </div>
      )}
      {props.showUpdateButton && !firstNameError && !lastNameError && (
        <div className={`rowContainer`}>
          <Button
            id={`updateUserButton`}
            buttonText={
              props.loading ? (
                <div className="circularProgressDiv" id={'popUp7'}>
                  <div id={'popUp8'}>
                    <CircularProgress size={14} />
                    <div
                      className="hiddenTextForSize"
                      style={{ height: 0, visibility: 'hidden' }}
                      id="hiddenCreateCaseButtonText"
                      data-textattribute={'button-101'}
                    >
                      {getText('button-101', user.settings)}
                    </div>
                  </div>
                </div>
              ) : (
                getText('button-101', user.settings)
              )
            }
            buttonTextAttribute={'button-101'}
            buttonType="contained"
            className="createCaseButton"
            type="button"
            onClick={onUpdateUser}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        </div>
      )}
    </>
  )
}

export default UserDetails
