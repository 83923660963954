import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { UserForAccess } from '../../models/generalTypes'
import {
  findHandlingErrorState,
  logActivity,
} from '../../services/commonFunctions'
import {
  addUserToGroup,
  deleteUserFromGroup,
  getAllUsers,
} from '../../services/requests'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import { onlineState } from '../../states/OnlineState'
import { userState } from '../../states/UserState'
import UsersForAccess from './popUpSmallComponents/UsersForAccess'
import { getText } from '../../services/textFunctions'
import {
  AccessRole,
  AllowedAccessRole,
  AllowedAccessRoleToKey,
  UserRole,
  UserRoleKeys,
} from '../../models/enums'
import { useNavigate } from 'react-router-dom'

type Props = {
  caseId: string
  caseName: string
  users: UserForAccess[]
  setUpdateUsersAccess?: (param: number, users: any) => void
  updateUsersAccess?: number
  cancelFunction: () => void
  loading: boolean
  setLoading: (param: boolean) => void
  setTempUsersData: (value: any) => void
  tempUsersData: any | undefined
}

export type AccessMenuAction = 'add' | 'remove' | 'leave' | 'change'

export default function AccessComponent(props: Props) {
  const timeoutMessage = 3000

  const navigate = useNavigate()

  useEffect(() => {
    props.setLoading(true)
    // eslint-disable-next-line
  }, [])
  const user = useRecoilValue(userState)
  const [listOfUsers, setListOfUsers] = useState<UserForAccess[] | undefined>(
    undefined,
  )
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [online, setOnline] = useRecoilState(onlineState)

  const [availableUsers, setAvailableUsers] = useState<
    UserForAccess[] | undefined
  >(undefined)

  const [accessMenu, setAccessMenu] = useState<
    [string | undefined, AccessMenuAction | undefined]
  >([undefined, undefined])
  const [changeAccepted, setChangeAccepted] = useState(false)
  const [changeForbidden, setChangeForbidden] = useState(false)
  const [loadingAccess, setLoadingAccess] = useState(false)

  useEffect(() => {
    let tempListOfUsers: UserForAccess[] = []

    props.users.forEach((userInUsers) => {
      tempListOfUsers.push({
        ...userInUsers,
      })
    })
    setListOfUsers(tempListOfUsers)

    let tempAvailableUsers: UserForAccess[] = []

    getUsers().then((res) => {
      if (res) {
        let tempUsernamesList: string[] = []
        tempListOfUsers.forEach((user) => {
          tempUsernamesList.push(user.username)
        })
        res.data.forEach((userInData: any) => {
          if (!tempUsernamesList.includes(userInData.username)) {
            if (userInData.username !== user.email) {
              tempAvailableUsers.push({
                first_name: userInData.first_name,
                last_name: userInData.last_name,
                user_role: UserRole[userInData.user_role as UserRoleKeys],
                username: userInData.username,
                self: false,
              })
            } else {
              tempAvailableUsers.push({
                first_name: userInData.first_name,
                last_name: userInData.last_name,
                user_role: UserRole[userInData.user_role as UserRoleKeys],
                username: userInData.username,
                self: true,
              })
            }
          }
        })
        setAvailableUsers(tempAvailableUsers)
        props.setLoading(false)
      } else {
        props.cancelFunction()
      }
    })

    // eslint-disable-next-line
  }, [props.users])

  const getUsers = async () => {
    if (online.networkOn) {
      const res = await getAllUsers()
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'getAllUsers'),
      )
      if ('errorCode' in res) {
        return undefined
      }
      return res
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const actionForAccess = (
    action: AccessMenuAction,
    username: string,
    role: AllowedAccessRole,
  ) => {
    if (action === 'add' || action === 'change') {
      addAccess(username, AllowedAccessRoleToKey(role)).then((res) => {
        if (res) {
          if (typeof res === 'string') {
            setChangeForbidden(true)
            setTimeout(() => {
              setChangeForbidden(false)
              setAccessMenu([undefined, undefined])
            }, timeoutMessage)
          } else {
            setChangeAccepted(true)
            props.setTempUsersData(res.data)
            setTimeout(() => {
              setChangeAccepted(false)
              setAccessMenu([undefined, undefined])
              props.setUpdateUsersAccess!(
                props.updateUsersAccess! + 1,
                res.data,
              )
              props.setTempUsersData(undefined)
            }, timeoutMessage)
          }
        } else {
          setChangeAccepted(false)
          setAccessMenu([undefined, undefined])
        }

        setLoadingAccess(false)
      })
    } else if (action === 'remove' || 'leave') {
      removeAccess(username).then((res) => {
        if (res) {
          if (typeof res === 'string') {
            setChangeForbidden(true)
            setTimeout(() => {
              setChangeForbidden(false)
              setAccessMenu([undefined, undefined])
            }, timeoutMessage)
          } else {
            setChangeAccepted(true)
            props.setTempUsersData(res.data)
            setTimeout(() => {
              setChangeAccepted(false)
              setAccessMenu([undefined, undefined])
              if (action === 'leave') {
                props.cancelFunction()
                navigate('/', { replace: true })
                window.location.reload()
              } else {
                props.setUpdateUsersAccess!(
                  props.updateUsersAccess! + 1,
                  res.data,
                )
                props.setTempUsersData(undefined)
              }
            }, timeoutMessage)
          }
        } else {
          setChangeAccepted(false)
          setAccessMenu([undefined, undefined])
        }

        setLoadingAccess(false)
      })
    }
  }

  const addAccess = async (username: string, role: string) => {
    if (online.networkOn) {
      const res = await addUserToGroup(props.caseId, {
        username: username,
        role: role,
      })

      if ('errorCode' in res) {
        if (
          res['errorCode'] === 403 &&
          res.message.includes('the only owner')
        ) {
          return 'forbidden'
        }

        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'addUserToGroup'),
        )
        return undefined
      } else {
        //Mixpanel 19
        logActivity(false, 'Gave access to a user')
      }
      return res
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const removeAccess = async (username: string) => {
    if (online.networkOn) {
      const res = await deleteUserFromGroup(props.caseId, {
        username: username,
      })

      if ('errorCode' in res) {
        if (
          res['errorCode'] === 403 &&
          res.message.includes('the only owner')
        ) {
          return 'forbidden'
        }

        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'deleteUserFromGroup'),
        )
        return undefined
      } else {
        //Mixpanel 20
        logActivity(false, 'Removed access from a user')
      }
      return res
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const getOwnRole = () => {
    const filtered = listOfUsers?.filter((user) => user.self)

    if (filtered && filtered.length === 0) {
      if (
        user.role === UserRole.EPEROTO_ADMIN ||
        user.role === UserRole.GLOBAL_ADMIN
      ) {
        return AccessRole.ADMIN
      }

      return AccessRole.NONE
    } else if (filtered) {
      return filtered[0].access_role!
    } else {
      return AccessRole.NONE
    }
  }

  const hasOwnerAccess = () => {
    return (
      getOwnRole() === AccessRole.ADMIN || getOwnRole() === AccessRole.OWNER
    )
  }

  const cancelFunction = () => {
    setChangeAccepted(false)
    setChangeForbidden(false)
    setAccessMenu([undefined, undefined])
    if (props.tempUsersData) {
      props.setUpdateUsersAccess!(
        props.updateUsersAccess! + 1,
        props.tempUsersData,
      )
      props.setTempUsersData(undefined)
    }
  }

  return (
    <>
      {props.loading ? (
        <div className="circularProgressDiv" id={'popUp7'}>
          <div id={'popUp8'}>
            <CircularProgress size={40} />
          </div>
        </div>
      ) : (
        <div
          className="accessComponentContainer"
          id={'popUpaccessComponentContainer1'}
        >
          <div className="caseTitleContainer" id={'popUpcaseTitleContainer1'}>
            <p
              className="caseTitleText"
              id="popUpcaseTitleText1"
              data-textattribute="title-30"
            >
              {getText('title-30', user.settings)}
            </p>
            <p
              className="caseTitleNameText"
              id={'popUpcaseTitleNameText1'}
              data-openreplay-obscured
            >
              {props.caseName}
            </p>
          </div>
          <div
            className="usersWithAccessContainer"
            id={'popUpusersWithAccessContainer1'}
          >
            <div
              className="usersTitleContainer"
              id={'popUpusersTitleContainer1'}
            >
              <div
                className="usersTitleTextContainer"
                id={'popUpUsernamesContainer1'}
              >
                <p
                  className="usersTitleText"
                  id="popUpusersTitleText1"
                  data-textattribute="title-84"
                >
                  {getText('title-84', user.settings)}
                </p>
              </div>
              <div
                className="usersTitleTextContainer"
                id={'popUpuserRoleContainer1'}
              >
                <p
                  className="usersTitleText"
                  id="popUpuserRoleText1"
                  data-textattribute="title-160"
                >
                  {getText('title-160', user.settings)}
                </p>
              </div>
              <div
                className="usersTitleTextContainer"
                id={'popUpActionContainer1'}
              >
                <p className="usersTitleText" id="popUpusersTitleText1">
                  {' '}
                </p>
              </div>
            </div>
            <div className="usersListContainer" id={'popUpusersListContainer1'}>
              <UsersForAccess
                listOfUsers={listOfUsers}
                id="usersWithAccess"
                onButtonClick={(username: string, type: AccessMenuAction) => {
                  setAccessMenu([username, type])
                }}
                accessMenu={accessMenu}
                accessMenuCancelFunction={cancelFunction}
                actionForAccess={actionForAccess}
                changeAccepted={changeAccepted}
                setChangeAccepted={setChangeAccepted}
                changeForbidden={changeForbidden}
                loadingAccess={loadingAccess}
                setLoadingAccess={setLoadingAccess}
                ownRole={getOwnRole()}
              />
            </div>
          </div>
          <div
            className="usersWithNoAccessContainer"
            id={'popUpusersNoAccessContainer2'}
          >
            <div
              className="usersTitleContainer"
              id={'popUpusersTitleContainer2'}
            >
              {hasOwnerAccess() ? (
                <>
                  <div
                    className="usersTitleTextContainer"
                    id={'popUpUsernamesContainer2'}
                  >
                    <p
                      className="usersTitleText"
                      id="popUpusersTitleText2"
                      data-textattribute="title-85"
                    >
                      {getText('title-85', user.settings)}
                    </p>
                  </div>
                  <div
                    className="usersTitleTextContainer"
                    id={'popUpuserRoleContainer2'}
                  >
                    <p
                      className="usersTitleText"
                      id="popUpuserRoleContainer2"
                      data-textattribute="title-160"
                    >
                      {getText('title-160', user.settings)}
                    </p>
                  </div>
                  <div
                    className="usersTitleTextContainer"
                    id={'popUpActionsContainer2'}
                  >
                    <p className="usersTitleText" id="popUpusersTitleText1">
                      {' '}
                    </p>
                  </div>
                </>
              ) : (
                <div
                  className="usersTitleTextContainer alone"
                  id={'popUpNoAccessContainer2'}
                >
                  <p
                    className="usersTitleText"
                    id="popUpusersTitleTextNotOwner"
                    data-textattribute="title-161"
                  >
                    {getText('title-161', user.settings)}
                  </p>
                </div>
              )}
            </div>
            {hasOwnerAccess() && (
              <div
                className="usersListContainer"
                id={'popUpusersListContainer2'}
              >
                <UsersForAccess
                  listOfUsers={availableUsers}
                  id="availableUsers"
                  onButtonClick={(username: string, type: AccessMenuAction) => {
                    setAccessMenu([username, type])
                  }}
                  accessMenu={accessMenu}
                  accessMenuCancelFunction={cancelFunction}
                  actionForAccess={actionForAccess}
                  changeAccepted={changeAccepted}
                  setChangeAccepted={setChangeAccepted}
                  changeForbidden={changeForbidden}
                  loadingAccess={loadingAccess}
                  setLoadingAccess={setLoadingAccess}
                  ownRole={getOwnRole()}
                  forInviting
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
