import React from 'react'
import tick from '../../../../resources/images/152-bigCopy.svg'
import { getText } from '../../../../services/textFunctions'
import CircularProgress from '@mui/material/CircularProgress'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../states/UserState'

type Props = {
  backToLogin: () => void
  type: 'success' | 'failure' | 'loading'
}

export const SuccessFailureLoadingComponent = (props: Props) => {
  const user = useRecoilValue(userState)

  return props.type === 'loading' ? (
    <CircularProgress size={60} />
  ) : (
    <div className="submitButtonContainer">
      <div className="successfullMessageContainer">
        {
          //TODO: Add failure image
          props.type === 'success' ? (
            <img src={tick} alt="tick" className="successfullImg" />
          ) : (
            <></>
          )
        }
        <p
          className="successfullTitle"
          id="successfullPasswordChangeTitle"
          data-textattribute="title-118"
        >
          {
            //TODO: Add failure title
            props.type === 'success' ? getText('title-118', user.settings) : ''
          }
        </p>
        <p
          className="successfullSubTitle"
          id="successfullPasswordChangeSubTitle"
          data-textattribute="description-91"
        >
          {
            //TODO: Add failure description
            props.type === 'success'
              ? getText('description-91', user.settings)
              : ''
          }
        </p>
      </div>
      <button
        type="button"
        className="submitButton"
        onClick={props.backToLogin}
        id="backToLoginButton"
        data-textattribute="button-60"
      >
        {getText('button-60', user.settings)}
      </button>
    </div>
  )
}

export default SuccessFailureLoadingComponent
