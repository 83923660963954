import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'

import duplicateImg from '../../../../../../../../resources/images/198-copyNode.svg'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'

type Props = {
  nodeId: string
  treeIndex: number
  handleDuplicateNodeClick: (e: any) => void
}

export default function DuplicateNodeButton(props: Props) {
  const user = useRecoilValue(userState)
  const [hoverOn, setHoverOn] = useState(false)

  return (
    <>
      <div
        className="duplicateNodeContainer"
        id={`duplicateNodeContainer-${props.treeIndex}_${props.nodeId}`}
        onClick={props.handleDuplicateNodeClick}
        onMouseEnter={() => setHoverOn(true)}
        onMouseLeave={() => setHoverOn(false)}
      >
        <div
          className={`duplicateNodeImgContainer ${hoverOn ? 'hover' : ''}`}
          id={`duplicateNodeImgContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <img
            src={duplicateImg}
            alt="duplicateImg"
            className="duplicateNodeImg"
            id={`duplicateNodeImg-${props.treeIndex}_${props.nodeId}`}
          />
        </div>
        <div
          className="duplicateNodeTextContainer"
          id={`duplicateNodeTextContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <p
            className={`duplicateNodeText ${hoverOn ? 'hover' : ''}`}
            id={`duplicateNodeText-${props.treeIndex}_${props.nodeId}`}
            data-textattribute="button-121"
          >
            {getText('button-121', user.settings)}
          </p>
        </div>
      </div>
    </>
  )
}
