import React from 'react'
import { useRecoilValue } from 'recoil'
import searchImg from '../../../resources/images/098-searchGlass.svg'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { logActivity } from '../../../services/commonFunctions'
import { freemiumState } from '../../../states/FreemiumState'

type Props = {
  itemName: 'Scenario' | 'Case' | 'User'
  searchFor: (e: React.ChangeEvent<HTMLInputElement>) => void
  isMyScenariosPage?: boolean
  isSettingsPage?: boolean
}

export default function SearchBar(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  return (
    <div className="searchBarContainer">
      <img src={searchImg} alt="searchTool" className="searchBarIcon" />
      <input
        type="text"
        id="searchBarCaseInputId"
        className="searchBarInput"
        data-textattribute={
          props.itemName === 'Scenario'
            ? 'placeholder-1'
            : props.itemName === 'User'
            ? 'placeholder-16'
            : 'placeholder-2'
        }
        placeholder={
          props.itemName === 'Scenario'
            ? getText('placeholder-1', user.settings)
            : props.itemName === 'User'
            ? getText('placeholder-16', user.settings)
            : getText('placeholder-2', user.settings)
        }
        onChange={props.searchFor}
        onFocus={() => {
          if (props.isMyScenariosPage) {
            //Mixpanel 13 (All)
            logActivity(freemium.isFreemium, 'Searched for a scenario')
          } else if (props.isSettingsPage) {
            //Mixpanel 90
            logActivity(false, 'Searched for a user in settings')
          } else {
            //Mixpanel 12 (All)
            logActivity(freemium.isFreemium, 'Searched for a case')
          }
        }}
        autoComplete="off"
      />
    </div>
  )
}
