import React, { useState } from 'react'
import SettingPopUp from '../../../pages/settings/tabs/components/SettingPopUp'
import Button from '../../Buttons/Button/Button'
import { getText } from '../../../services/textFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { CircularProgress } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { Params } from '../../../models/generalTypes'
import {
  deleteAllDeletedCases,
  deleteAllDeletedScenarios,
} from '../../../services/requests'
import { findHandlingErrorState } from '../../../services/commonFunctions'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'

type Props = {
  setOpenPopUp: (value: boolean) => void
  isMyScenariosPage?: boolean
}

const EmptyBinPopUp = (props: Props) => {
  const user = useRecoilValue(userState)
  const [loading, setLoading] = useState(false)
  const [completed, setCompleted] = useState(false)
  const params = useParams<Params>()
  const navigate = useNavigate()
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  const onExit = () => {
    if (!loading || completed) {
      props.setOpenPopUp(false)
    }
  }

  const onEmptyBin = async () => {
    setLoading(true)

    const res = props.isMyScenariosPage
      ? await deleteAllDeletedScenarios(params.caseId!)
      : await deleteAllDeletedCases()

    setHandlingErrors(
      findHandlingErrorState(
        res,
        handlingErrors,
        props.isMyScenariosPage
          ? 'deleteAllDeletedScenarios'
          : 'deleteAllDeletedCases',
      ),
    )

    if (!('errorCode' in res)) {
      setCompleted(true)
      setTimeout(() => {
        props.setOpenPopUp(false)
        if (props.isMyScenariosPage) {
          navigate(`/mycases/${params.caseId!}`)
        } else {
          navigate('/')
        }
      }, 2000)
    }

    setLoading(false)
  }

  return (
    <SettingPopUp verySmall onClose={completed ? undefined : onExit}>
      <div className={`welcomeMessageContainer ${completed ? 'middle' : ''}`}>
        <h2
          className="welcome"
          id="emptyBinPopUpTitle"
          data-textattribute={
            !completed
              ? props.isMyScenariosPage
                ? 'title-193'
                : 'title-194'
              : props.isMyScenariosPage
              ? 'title-195'
              : 'title-196'
          }
        >
          {!completed
            ? props.isMyScenariosPage
              ? getText('title-193', user.settings)
              : getText('title-194', user.settings)
            : props.isMyScenariosPage
            ? getText('title-195', user.settings)
            : getText('title-196', user.settings)}
        </h2>
        {!completed && (
          <p
            className="instructions"
            id="emptyBinInstructions"
            data-textattribute={
              props.isMyScenariosPage ? 'description-143' : 'description-144'
            }
          >
            {props.isMyScenariosPage
              ? getText('description-143', user.settings)
              : getText('description-144', user.settings)}
          </p>
        )}
      </div>
      {!completed && (
        <div className="submitButtonContainer row">
          <Button
            id="cancelEmptyBin"
            buttonText={getText('button-93', user.settings)}
            buttonTextAttribute={'button-93'}
            buttonType="outlined"
            className="createCaseButton"
            type="button"
            onClick={onExit}
            side="left"
            NoUpperCase={true}
            small={false}
          />
          <div style={{ width: '40px' }}></div>
          <button
            className="button submitButton small button-type-contained"
            id="confirmEmptyBinButton"
            data-textattribute="button-131"
            style={{ backgroundColor: '#ac2828', color: 'white' }} //greekEaster
            onClick={onEmptyBin}
          >
            {loading ? (
              <CircularProgress size={22} />
            ) : (
              getText('button-131', user.settings)
            )}
          </button>
        </div>
      )}
    </SettingPopUp>
  )
}

export default EmptyBinPopUp
