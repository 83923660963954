import React from 'react'
import { useRecoilValue } from 'recoil'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'

type Props = {
  firstTrialImg: string
  secondTrialImg: string
  bankruptcyFirstTrialImg: string
  bankruptcySecondTrialImg: string
}

export default function DescriptionTitleHover(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <div data-openreplay-obscured>
      <div className="descriptionRow">
        <div className="descriptionImgContainer">
          <img
            src={props.firstTrialImg}
            alt="firstTrialImg"
            className="descriptionImg"
          />
        </div>
        <p
          className="descriptionText"
          data-textattribute="hover-34"
          id="firstTrialDescriptionText"
        >
          {getText('hover-34', user.settings)}
        </p>
      </div>
      <div className="descriptionRow">
        <div className="descriptionImgContainer">
          <img
            src={props.secondTrialImg}
            alt="secondTrialImg"
            className="descriptionImg"
          />
        </div>
        <p
          className="descriptionText"
          data-textattribute="hover-35"
          id="secondTrialDescriptionText"
        >
          {getText('hover-35', user.settings)}
        </p>
      </div>
      <div className="descriptionRow">
        <div className="descriptionImgContainer">
          <img
            src={props.bankruptcyFirstTrialImg}
            alt="bankruptcyFirstTrialImg"
            className="descriptionImg"
          />
        </div>
        <p
          className="descriptionText"
          data-textattribute="hover-36"
          id="bankruptcyFirstTrialDescriptionText"
        >
          {getText('hover-36', user.settings)}
        </p>
      </div>
      <div className="descriptionRow">
        <div className="descriptionImgContainer">
          <img
            src={props.bankruptcySecondTrialImg}
            alt="bankruptcySecondTrialImg"
            className="descriptionImg"
          />
        </div>
        <p
          className="descriptionText"
          data-textattribute="hover-37"
          id="bankruptcySecondTrialDescriptionText"
        >
          {getText('hover-37', user.settings)}
        </p>
      </div>
    </div>
  )
}
