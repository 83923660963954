import React from 'react'
import aTozImg from '../../../../../resources/images/131-AtoZ.svg'
import zToaImg from '../../../../../resources/images/130-ZtoA.svg'

import {
  SortDirection,
  UsersSortOption,
} from '../../../../../models/generalTypes'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import { useRecoilValue } from 'recoil'
import useWindowSize from '../../../../../customHooks/useWindowSize'

type Props = {
  widthOfCaseContainer: number | undefined
  sortOption: UsersSortOption
  sortDirection: SortDirection
  onClick: (option: UsersSortOption) => void
}

const UsersListTitles = (props: Props) => {
  const user = useRecoilValue(userState)
  const { width } = useWindowSize()

  return (
    <div
      className="myCasesList-titles"
      style={{ width: props.widthOfCaseContainer }}
      id="userList-titles"
    >
      <div
        className={`${
          width < 836
            ? 'myCasesList-titles-left-userlist'
            : 'myCasesList-titles-left userlist'
        }`}
        id="userList-titles-left"
      >
        <h5
          onClick={() => props.onClick('name')}
          className={'caseDetailsContainer-title'}
          style={{ cursor: 'pointer' }}
          id={'user-name-title'}
          data-textattribute={'title-184'}
        >
          {getText('title-184', user.settings)}
          {props.sortOption === 'name' && props.sortDirection === 'up' ? (
            <img src={aTozImg} className="sortImgContainer" alt="aTozImg" />
          ) : props.sortOption === 'name' && props.sortDirection === 'down' ? (
            <img src={zToaImg} className="sortImgContainer" alt="zToaImg" />
          ) : null}
        </h5>
      </div>
      <div className="myCasesList-titles-right" id="userList-titles-right">
        <h5
          onClick={() => props.onClick('role')}
          className={'caseDetailsContainer-title  caseDetailsContainer-small'}
          style={{ marginLeft: '3px', cursor: 'pointer' }}
          id={'user-role-title'}
          data-textattribute={'title-185'}
        >
          {getText('title-185', user.settings)}
          {props.sortOption === 'role' && props.sortDirection === 'up' ? (
            <img src={aTozImg} className="sortImgContainer" alt="aTozImg" />
          ) : props.sortOption === 'role' && props.sortDirection === 'down' ? (
            <img src={zToaImg} className="sortImgContainer" alt="zToaImg" />
          ) : null}
        </h5>
        <h5
          onClick={() => props.onClick('state')}
          className={'caseDetailsContainer-title  caseDetailsContainer-small'}
          style={{ cursor: 'pointer' }}
          id={'user-state-title'}
          data-textattribute={'title-186'}
        >
          {getText('title-186', user.settings)}
          {props.sortOption === 'state' && props.sortDirection === 'up' ? (
            <img src={aTozImg} className="sortImgContainer" alt="aTozImg" />
          ) : props.sortOption === 'state' && props.sortDirection === 'down' ? (
            <img src={zToaImg} className="sortImgContainer" alt="zToaImg" />
          ) : null}
        </h5>
        <h5
          className={
            'caseDetailsContainer-title-user-actions caseDetailsContainer-small'
          }
          id={'user-actions-title'}
          data-textattribute={'title-187'}
        >
          {getText('title-187', user.settings)}
        </h5>
      </div>
    </div>
  )
}

export default UsersListTitles
