import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import {
  LegalCostsParty,
  LegalCostsProceedings,
  TypeOfInstance,
} from '../../../../../../../../models/enums'
import {
  ExecutiveSummaryVisibleElements,
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { stringAndRoundTo2Decimals } from '../../../../../../../../Modules/DisputeModules/AppFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { getLegalCosts } from '../../../../../../../../services/legalCostsFunctions'

type Props = {
  legalCostsDescriptions?: ReportLegalCostsType
  visibleElements?: ExecutiveSummaryVisibleElements
  party: LegalCostsParty
  proceedings: LegalCostsProceedings
  reportWhitelabel?: ReportWhitelabel
  fade: boolean | undefined
}

export default function LegalCostsExecutiveSummary3(props: Props) {
  const user = useRecoilValue(userState)

  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const hasSecondTrial = scenarioSnapshot.currentSnapshot.probSecondTrial! > 0

  const legalCostsObj = scenarioSnapshot.currentSnapshot.legalCosts
  const firstProceedingsDefaultMessage = hasSecondTrial
    ? getText('title-129', user.settings)
    : getText('title-130', user.settings)

  const secondProceedingsDefaultMessage =
    scenarioSnapshot.currentSnapshot.court === TypeOfInstance.Public_Court
      ? //title-131a
        getText('title-131a', user.settings)
      : //title-131b
        getText('title-131b', user.settings)

  const firstProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.firstOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.firstOwnLegalCostsDescription
      : ''
  const secondProceedingsText =
    props.legalCostsDescriptions &&
    props.legalCostsDescriptions.secondOwnLegalCostsDescription.length > 0
      ? props.legalCostsDescriptions.secondOwnLegalCostsDescription
      : ''

  const getLegalCostsSingleValue = (value: number | string) => {
    if (typeof value === 'string') {
      return `${value} ${scenarioSnapshot.currentSnapshot.currency}`
    }

    return `${stringAndRoundTo2Decimals(value, user.settings)} ${
      scenarioSnapshot.currentSnapshot.currency
    }`
  }

  return (
    <div
      className="legalCostsComponent"
      id={`legalCostsComponent-${props.party}${
        props.visibleElements ? '-forReport' : ''
      }`}
      data-openreplay-obscured
    >
      <div
        className="estimatedLegalCostsContainer"
        id={`estimatedLegalCostsContainer-${props.proceedings}-${props.party}${
          props.visibleElements ? '-forReport' : ''
        }`}
      >
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
            false,
            true,
          )}
          className={`legalCostsTitle medium ${props.fade ? 'fadeText' : ''} ${
            props.visibleElements ? 'forReport' : ''
          }
           ${props.fade ? 'fade' : ''}
          `}
          id={`costsOfProceedingsTitle3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {props.proceedings === LegalCostsProceedings.additionalProceedings
            ? secondProceedingsText.length > 0
              ? secondProceedingsText
              : secondProceedingsDefaultMessage
            : firstProceedingsText.length > 0
            ? firstProceedingsText
            : firstProceedingsDefaultMessage}
          :
        </p>
        <p
          className={`legalCostsValue bold robotoNumbersBold ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'roboto',
            false,
            props.fade,
          )}
          id={`estimatedLegalCostsValue3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getLegalCostsSingleValue(
            props.party === LegalCostsParty.clientLegalCosts
              ? props.proceedings === LegalCostsProceedings.firstProceedings
                ? legalCostsObj.clientLegalCosts.firstProceedings
                    .estimatedCosts!
                : legalCostsObj.clientLegalCosts.additionalProceedings!
                    .estimatedCosts!
              : props.proceedings === LegalCostsProceedings.firstProceedings
              ? legalCostsObj.opposingPartyLegalCosts.firstProceedings
                  .estimatedCosts!
              : legalCostsObj.opposingPartyLegalCosts.additionalProceedings!
                  .estimatedCosts!,
          )}
        </p>
      </div>
      <div className="recoverableCostsContainer">
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            true,
          )}
          className={`legalCostsTitle description ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          data-textattribute="label-89"
          id={`recoverableCostsTitle3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getText('label-89', user.settings)}:
        </p>
        <p
          className={`legalCostsValue robotoNumbers ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
            false,
            props.fade,
          )}
          id={`recoverableLegalCostsValue3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'recoverableCosts',
              props.party,
              props.proceedings,
              scenarioSnapshot.currentSnapshot,
              user.settings,
            ),
          )}
        </p>
      </div>
      <div className="incurredCostsContainer">
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            true,
          )}
          className={`legalCostsTitle description ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          data-textattribute="label-91"
          id={`incurredCostsTitle3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getText('label-91', user.settings)}:
        </p>
        <p
          className={`legalCostsValue robotoNumbers ${
            props.fade ? 'fadeText' : ''
          } ${props.visibleElements ? 'forReport' : ''}`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'roboto',
            false,
            props.fade,
          )}
          id={`incurredLegalCostsValue3-${
            props.proceedings === LegalCostsProceedings.firstProceedings
              ? 'first'
              : 'second'
          }-${props.party}${props.visibleElements ? '-forReport' : ''}`}
        >
          {getLegalCostsSingleValue(
            getLegalCosts(
              'incurredCosts',
              props.party,
              props.proceedings,
              scenarioSnapshot.currentSnapshot,
              user.settings,
            ),
          )}
        </p>
      </div>
    </div>
  )
}
