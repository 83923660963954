type Props = {
  title: string
  value: string
  currency: string
  image?: string
  id: string
}

export default function ValueOfIndependentClaimSection(props: Props) {
  return (
    <div
      className="valuOfIndependentClaimSection"
      id={`valuOfIndependentClaimSection-${props.id}`}
    >
      <div
        className="valuOfIndependentClaimSectionHeader"
        id={`valuOfIndependentClaimSectionHeader-${props.id}`}
      >
        <p
          className="valuOfIndependentClaimSectionTitle"
          id={`valuOfIndependentClaimSectionTitle-${props.id}`}
        >
          {props.title}
        </p>
        {props.image && (
          <img
            src={props.image}
            alt="titleImg"
            className="valuOfIndependentClaimSectionImage"
          />
        )}
      </div>
      <p
        className="valuOfIndependentClaimSectionValue robotoNumbers"
        id={`valuOfIndependentClaimSectionValue-${props.id}`}
      >
        {props.value} {props.currency}
      </p>
    </div>
  )
}
