import React from 'react'
import { useRecoilValue } from 'recoil'
import { getText } from '../../../../../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../../../states/UserState'
//import { stringAndRoundTo2Decimals } from '../../../../../../Modules/DisputeModules/AppFunctions'

export default function UnsuccessfulEnforcement() {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 0,1,2 or 3
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
   { amount_received , amount_received_out_of_court , claim_name, claim_type, claim_type_id, interest_date, interest_rate, interest_received, outcome_probability, scenario_name, tree_name}
    17 - name
    */

  //Fix the conditional for this
  return (
    <div className="hoverBackground" data-openreplay-obscured>
      <div className="hoverTextContainer">
        <p
          className="hoverText"
          style={{ fontWeight: 'bold', color: 'red' }}
          id="detailedResultsHoverComponentDescription-enforcement"
          data-textattribute="description-73"
          data-openreplay-obscured
        >
          {getText('description-73', user.settings, partiesFormat)}
        </p>
      </div>
    </div>
  )
}
