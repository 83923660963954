import React from 'react'
import TextArea from '../../../../../../../../../Components/Inputs/TextArea/TextArea'

import { TreeNodeClass } from '../../../../../../../../../models/treeModels/treeNode'
import {
  EventId,
  NodeId,
  NodeMode,
  RootNodeType,
} from '../../../../../../../../../models/treeModels/treeTypes'
import { TreeEventClass } from '../../../../../../../../../models/treeModels/treeEvent'
import { DefaultSnapshotState } from '../../../../../../../../../models/generalTypes'
import { TreeDetails } from '../../../../../../../../../models/treeModels/treeClaim'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'
import { krogerusColorsState } from '../../../../../../../../../states/KrogerusColorsState'
import { useRecoilValue } from 'recoil'

type Props = {
  nodeId: NodeId
  root: RootNodeType
  treeIndex: number
  events: { [eventId: EventId]: TreeEventClass }
  nodeDetails: TreeNodeClass
  children: any
  nodeMode: NodeMode
  zoomLevel: number
  scenarioSnapshot: DefaultSnapshotState
  treeDetails: TreeDetails
  reportWhitelabel?: ReportWhitelabel
}

export default function TreeNodeTitleComponentForReport(props: Props) {
  const krogerusColors = useRecoilValue(krogerusColorsState)

  return (
    <>
      <div
        className={
          props.nodeMode === NodeMode.maximised
            ? 'treeNodeBackground'
            : 'treeNodeBackground minimised'
        }
      >
        <div
          className={`treeNodeTitle viewerPlaceholder ${
            krogerusColors ? 'krogerus' : ''
          }`}
          id={`treeNodeTitle-${props.treeIndex}_${props.nodeId}`}
        >
          <div
            className={`connectionCircle ${
              props.root === RootNodeType.mainTreeRoot ? 'invisible' : null
            }`}
            id={`connectionCircle-${props.treeIndex}_${props.nodeId}`}
          ></div>
          {props.nodeDetails && props.nodeDetails.nodeTitle !== '' && (
            <TextArea
              className={`treeNodeTitleTextarea ${
                props.nodeMode === NodeMode.maximised ? '' : 'minimised'
              }`}
              id={`treeNodeTitleTextarea-${props.treeIndex}_${props.nodeId}`}
              placeholderTextAttribute=""
              value={props.nodeDetails ? props.nodeDetails.nodeTitle : ''}
              onChange={() => {}}
              maxLength={162}
              tabIndex={
                props.nodeDetails
                  ? 1000 + 100 * props.nodeDetails.properties.position[1]
                  : 0
              }
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                true,
              )}
            />
          )}
          <div className="treeNodeTItleHoverBottomReference"></div>
        </div>
        <div
          className={
            props.nodeMode === NodeMode.maximised
              ? 'treeEventsContainer'
              : 'treeEventsContainer minimised'
          }
          id={`treeEventsContainer-${props.treeIndex}_${props.nodeId}`}
        >
          {props.children}
        </div>
      </div>
    </>
  )
}
