import React from 'react'
import { useRecoilValue } from 'recoil'
import { AccessRole } from '../../../../models/enums'
import {
  TabsErrors,
  TreeTableInfo,
  TreeAnalysisResults,
} from '../../../../models/generalTypes'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import IndependentSingleClaim from './independentClaim/IndependentSingleClaim'
import TreeComponent from './tree/treeCanvasAndMainComponents/TreeComponent'
import { treeTablesState } from '../../../../states/TreeTablesState'

type Props = {
  claimIndex: number
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  updateRef: boolean
  treeDirectCalculation: (
    treeIndex: number,
    message: string,
  ) => Promise<TreeAnalysisResults[][] | undefined>
  changeTreeTablesInfo: (treeTableInfo: TreeTableInfo) => void
  ownRole: AccessRole
  checkIfTreeHasErrors: (treeIndex: number) => boolean
  tabsErrors: TabsErrors | undefined
  treeWarningsIndex: number
  addCheckedIndex: (value: number) => void
  errors: string[]
}

export default function SingleClaim(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const isClaimTree =
    scenarioSnapshot.currentSnapshot.claims[props.claimIndex] &&
    scenarioSnapshot.currentSnapshot.claims[props.claimIndex].type === 'tree'
  const isIndependentClaim =
    scenarioSnapshot.currentSnapshot.claims[props.claimIndex] &&
    scenarioSnapshot.currentSnapshot.claims[props.claimIndex].type !== 'tree'
  const treeTables = useRecoilValue(treeTablesState)

  function findTheCurrentTreeTableInfo() {
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.claimIndex].id,
    )
    return treeTables.treeTablesInfo[treeTableInfoIndex]
  }

  return (
    <>
      {isClaimTree ? (
        <TreeComponent
          treeIndex={props.claimIndex}
          currentTreeTableInfo={findTheCurrentTreeTableInfo()}
          treeDirectCalculation={props.treeDirectCalculation}
          checkIfTreeHasErrors={props.checkIfTreeHasErrors}
          tabsErrors={props.tabsErrors}
          ownRole={props.ownRole}
          treeWarningsIndex={props.treeWarningsIndex}
          addCheckedIndex={props.addCheckedIndex}
          removeClaim={props.removeClaim}
          errors={props.errors}
        />
      ) : isIndependentClaim ? (
        <IndependentSingleClaim
          claimType={
            scenarioSnapshot.currentSnapshot.claims[props.claimIndex].type
          }
          claimIndex={props.claimIndex}
          removeClaim={props.removeClaim}
          updateRef={props.updateRef}
          ownRole={props.ownRole}
          errors={props.errors}
        />
      ) : null}
      <div className="emptySpace"></div>
    </>
  )
}
