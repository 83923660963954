import { useEffect, useState, useRef } from 'react'

import Liability from './liability/Liability'
import Quantum from './quantum/Quantum'
import BarChart from './barChart/BarChart'

import addSignImg from '../../../../../resources/images/013-plusSignThinYellowBlue.svg'
import removeSignImg from '../../../../../resources/images/014-xSignGrayWhite.svg'
import exitImg from '../../../../../resources/images/187-newExitImg.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import { IndependentClaim } from '../../../../../models/independentClaim'
import { SnapshotSelectorObject } from '../../../../../models/generalTypes'
import {
  AccessRole,
  ClaimType,
  TypeOfInstance,
  UndoRedoType,
} from '../../../../../models/enums'
import {
  deepCloneObject,
  logActivity,
  transformDateToString,
} from '../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import { getAbs } from '../../../../../services/formatNum'
import SwitchButton from '../../../../../Components/Buttons/SwitchButton/SwitchButton'
import InfoClickComponent from '../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import HoverComponent from '../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import DeleteClaimWindow from '../../../../../Components/DeleteWindow/DeleteWindow'
import InputText from '../../../../../Components/Inputs/InputText/InputText'
import InputNum from '../../../../../Components/Inputs/InputNum/InputNum'
import InputDate from '../../../../../Components/Inputs/InputDate/InputDate'
import { userState } from '../../../../../states/UserState'
import { getSplitText, getText } from '../../../../../services/textFunctions'
import ValueOfIndependentClaim from './ValueOfIndependentClaim'
import { freemiumState } from '../../../../../states/FreemiumState'
import PremiumFeatureIcon from '../../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../../../models/freemiumEnums'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
  KROGERUS_OPPOSING_PARTY_COLOR,
  OPPOSING_PARTY_COLOR,
} from '../../../../../services/constants'
import Symbol from '../../../../../Components/symbol/Symbol'
import { krogerusColorsState } from '../../../../../states/KrogerusColorsState'

type Props = {
  claimType: ClaimType // the type of the claim, for the Claim it can be 'claim' or 'counterclaim'undefinedefault obj of an empty claim -- obj
  claimIndex: number // the index of this treeThumb counting all the claims -- number >=0
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  updateRef: boolean
  ownRole: AccessRole
  errors: string[]
}

export default function IndependentSingleClaim(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const [deleteMessage, setDeleteMessage] = useState(false) // a boolean that defines if the delete pop up is on or off -- bool

  const [hoverElement, setHoverElement] = useState('') // a currentSnapshot that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature

  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const onMouseDown = (e: any) => {
    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  const onMouseUp = () => {
    if (deleteMessage === true) {
      setDeleteMessage(!deleteMessage)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp, false)
    return () => {
      document.removeEventListener('mouseup', onMouseUp, false)
    }
    // eslint-disable-next-line
  }, [deleteMessage])

  function handleClaimTypeChange(num: number) {
    let value = 'claim'
    if (num === 2) {
      value = 'counterclaim'
    }
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `switchClaimTypeButton-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'claims',
      claimIndex: props.claimIndex,
      key2: 'type',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleClaimInputChange(e: any, key2: keyof IndependentClaim) {
    let tempValue = e.target.value
    let tempId = e.target.id

    if (key2 === 'name') {
      tempValue = tempValue.toString()
    } else if (key2 === 'interestDate') {
      tempValue = transformDateToString(e.target.value)
      tempId = [e.target.id, e.target.id, true]
    }

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: tempId,
      undoRedoType: UndoRedoType.input,
      value: tempValue,
      key: 'claims',
      claimIndex: props.claimIndex,
      key2: key2,
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function handleToggleIsInterest(value: boolean) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `addRemoveClaimInterestContainer-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'claims',
      claimIndex: props.claimIndex,
      key2: 'isInterest',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function toggleDeleteMessage() {
    setDeleteMessage(!deleteMessage)
  }

  if (!props.updateRef) {
    return <div className="IndependentSingleClaimPlaceholder"></div>
  }

  return (
    <div
      className="tool-component claimContainer"
      id={`claim${props.claimIndex} menuChild`}
    >
      <div
        className={`claimTitleContainer ${krogerusColors ? 'krogerus' : ''}`}
      >
        <div
          className="tool-component-title"
          id={`claimTitle-${props.claimIndex}`}
        >
          <p
            className="titleClaimName"
            id={`titleClaimName-${props.claimIndex}`}
            data-openreplay-obscured
          >
            {currentSnapshot.claims[props.claimIndex].name}
          </p>
        </div>
      </div>

      <div className="tool-component-switch-container marginLeft3">
        <SwitchButton
          width="230px"
          label1={getSplitText(
            getText('switchButton-1', user.settings, partiesFormat),
            ' Claim',
            22,
            15,
          )}
          label1TextAttribute="switchButton-1"
          label2={getSplitText(
            getText('switchButton-2', user.settings, partiesFormat),
            ' Claim',
            22,
            15,
          )}
          label2TextAttribute="switchButton-2"
          onClick={(num: number) => {
            handleClaimTypeChange(num)
          }}
          backgroundColor1="#FFFFFF"
          backgroundColor2="#FFFFFF"
          color1={krogerusColors ? KROGERUS_CLIENT_COLOR : CLIENT_COLOR}
          color2={
            krogerusColors
              ? KROGERUS_OPPOSING_PARTY_COLOR
              : OPPOSING_PARTY_COLOR
          }
          id={`switchClaimTypeButton-${props.claimIndex}`}
          id1={`switchClaimTypeButtonState1-${props.claimIndex}`}
          id2={`switchClaimTypeButtonState2-${props.claimIndex}`}
          defaultState={props.claimType === 'claim' ? 1 : 2}
          marginLabel1={
            getText('switchButton-1', user.settings, partiesFormat).length > 18
              ? '0 0 0 40px'
              : '0 0 0 30px'
          }
          marginLabel2="0 35px 0 15px"
          disabled={props.ownRole === AccessRole.VIEWER}
        />
      </div>
      <div className="tool-component-spacer" style={{ height: '13px' }}></div>
      <div className="tool-component-input-container marginLeft3 first-container">
        <InputText
          label={getText('label-12', user.settings)}
          labelTextAttribute="label-12"
          id={`claimName-${props.claimIndex}`}
          value={
            currentSnapshot.claims[props.claimIndex]
              ? `${currentSnapshot.claims[props.claimIndex].name}`
              : undefined
          }
          className={`claim${props.claimIndex + 1} claimTitle`}
          tabIndex={1}
          name={`claimName-${props.claimIndex}`}
          onChange={(e: any) => handleClaimInputChange(e, 'name')}
          error={props.errors.includes(`claimName-${props.claimIndex}`)}
          errorMessage={getText('error-34', user.settings)}
          errorTextAttribute="error-34"
          maxLength={70}
          width={280}
          disabled={props.ownRole === AccessRole.VIEWER}
          multiline
        />
        <InfoClickComponent
          infoClickText={getText('infoButton-13', user.settings)}
          infoClickTextAttribute="infoButton-13"
          idName={`claimName-${props.claimIndex}`}
          hoverElement={hoverElement}
          setHoverElement={setHoverElement}
        />

        <InputNum
          label={getText('label-13', user.settings)}
          labelTextAttribute="label-13"
          suffix={currentSnapshot.currency}
          width={250}
          value={
            (currentSnapshot.claims[props.claimIndex] as IndependentClaim)
              .amount !== undefined
              ? getAbs(
                  (currentSnapshot.claims[props.claimIndex] as IndependentClaim)
                    .amount!,
                )
              : undefined
          }
          className={`claim${props.claimIndex + 1} robotoNumbers`}
          tabIndex={2}
          id={`claimAmount-${props.claimIndex}`}
          name={`claimAmount-${props.claimIndex}`}
          maxValue={99999999999}
          maxNumberOfDecimals={3}
          onChange={(e: any) => handleClaimInputChange(e, 'amount')}
          error={props.errors.includes(`claimAmount-${props.claimIndex}`)}
          errorMessage={getText('error-16', user.settings)}
          errorTextAttribute="error-16"
          disabled={props.ownRole === AccessRole.VIEWER}
          readOnly={props.ownRole === AccessRole.VIEWER}
          prefix={
            <div style={{ width: '8px' }}>
              <Symbol
                symbolType={
                  props.claimType === 'claim' ? 'client' : 'opposingParty'
                }
                targetId={`claimAmount-${props.claimIndex}`}
                targetType={'input'}
              />
            </div>
          }
          localStyle={{
            color:
              props.claimType === 'claim'
                ? krogerusColors
                  ? KROGERUS_CLIENT_COLOR
                  : CLIENT_COLOR
                : krogerusColors
                ? KROGERUS_OPPOSING_PARTY_COLOR
                : OPPOSING_PARTY_COLOR,
          }}
        />
        <InfoClickComponent
          infoClickText={getText('infoButton-14', user.settings)}
          infoClickTextAttribute="infoButton-14"
          idName={`claimAmount-${props.claimIndex}`}
          hoverElement={hoverElement}
          setHoverElement={setHoverElement}
        />
      </div>
      {(currentSnapshot.claims[props.claimIndex] as IndependentClaim)
        .isInterest ? (
        <>
          <div
            className="tool-component-submenu-container"
            style={{
              maxWidth: '747px',
              paddingBottom: props.errors.includes(
                `claimInterestDate-${props.claimIndex}:validation`,
              )
                ? 55
                : undefined,
            }}
            id={`addRemoveClaimInterestContainer-${props.claimIndex}`}
          >
            <div
              className="tool-component-remove-button-container"
              onClick={() => {
                handleToggleIsInterest(false)
                //Mixpanel 38
                logActivity(
                  false,
                  'Removed interest submenu in independent claim',
                )
              }}
            >
              {props.ownRole !== AccessRole.VIEWER && (
                <div className="addSignContainer">
                  <img src={removeSignImg} alt="removeSignImg" />
                </div>
              )}
              <p
                id={`interestSubmenuTitleOn-${props.claimIndex}`}
                data-textattribute="submenu-5​"
              >
                {getText('submenu-5', user.settings)}
              </p>
            </div>
            <div className="tool-component-submenu-input-container">
              <InputNum
                label={getText('label-14', user.settings)}
                labelTextAttribute="label-14"
                suffix="%"
                maxValue={100}
                className={`claim${props.claimIndex + 1} robotoNumbers`}
                maxNumberOfDecimals={2}
                width={280}
                value={
                  (currentSnapshot.claims[props.claimIndex] as IndependentClaim)
                    .interestRate
                }
                id={`claimInterestRate-${props.claimIndex}`}
                tabIndex={3}
                name={`claimInterestRate-${props.claimIndex}`}
                onChange={(e: any) => handleClaimInputChange(e, 'interestRate')}
                error={
                  props.errors.includes(
                    `claimInterestRate-${props.claimIndex}`,
                  ) ||
                  props.errors.includes(
                    `claimInterestRate-${props.claimIndex}:validation`,
                  )
                }
                errorMessage={
                  props.errors.includes(
                    `claimInterestRate-${props.claimIndex}:validation`,
                  )
                    ? getText('error-17', user.settings)
                    : getText('error-18', user.settings)
                }
                errorTextAttribute={
                  props.errors.includes(`claimInterestRate-${props.claimIndex}`)
                    ? 'error-17'
                    : 'error-18'
                }
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
                readOnly={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
              />
              <InfoClickComponent
                infoClickText={getText('infoButton-15', user.settings)}
                infoClickTextAttribute="infoButton-15"
                idName={`claimInterestRate-${props.claimIndex}`}
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />

              <InputDate
                label={getText('label-15', user.settings)}
                labelTextAttribute="label-15"
                width="250px"
                value={
                  (currentSnapshot.claims[props.claimIndex] as IndependentClaim)
                    .interestDate
                }
                className={`claim${props.claimIndex + 1} robotoNumbers`}
                id={`claimInterestDate-${props.claimIndex}`}
                tabIndex={4}
                name={`claimInterestDate-${props.claimIndex}`}
                onChange={(e: any) => handleClaimInputChange(e, 'interestDate')}
                error={
                  props.errors.includes(
                    `claimInterestDate-${props.claimIndex}`,
                  ) ||
                  props.errors.includes(
                    `claimInterestDate-${props.claimIndex}:validation`,
                  )
                }
                errorMessage={
                  props.errors.includes(
                    `claimInterestDate-${props.claimIndex}:validation`,
                  )
                    ? currentSnapshot.court === TypeOfInstance.Public_Court
                      ? getText('error-9', user.settings)
                      : currentSnapshot.court === TypeOfInstance.Arbitration
                      ? getText('error-10', user.settings)
                      : getText('error-11', user.settings)
                    : getText('error-5', user.settings)
                }
                errorTextAttribute={
                  props.errors.includes(
                    `claimInterestDate-${props.claimIndex}:validation`,
                  )
                    ? currentSnapshot.court === TypeOfInstance.Public_Court
                      ? 'error-9'
                      : currentSnapshot.court === TypeOfInstance.Arbitration
                      ? 'error-10'
                      : 'error-11'
                    : 'error-5'
                }
                disabled={
                  props.ownRole === AccessRole.VIEWER || freemium.isFreemium
                }
              />

              <InfoClickComponent
                infoClickText={getText('infoButton-16', user.settings)}
                infoClickTextAttribute="infoButton-16"
                idName={`claimInterestDate-${props.claimIndex}`}
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
              />
            </div>
          </div>
          <div
            className="tool-component-spacer"
            style={{ height: '13px' }}
          ></div>
        </>
      ) : (
        <>
          {props.ownRole !== AccessRole.VIEWER && (
            <>
              <div
                className="tool-component-add-button-container marginLeft2"
                id={`addRemoveClaimInterestContainer-${props.claimIndex}`}
                onClick={() => {
                  if (freemium.isFreemium) {
                    setFreemium({
                      ...freemium,
                      showMessage: FreemiumMessageType.General,
                    })
                  } else {
                    handleToggleIsInterest(true)
                    //Mixpanel 37
                    logActivity(
                      false,
                      'Added interest submenu in independent claim',
                    )
                  }
                }}
                onMouseEnter={() => {
                  secondaryHover.current = 'addSecondInstance'
                  setTimeout(() => {
                    if (secondaryHover.current === 'addSecondInstance') {
                      setHoverElement('addSecondInstance')
                    }
                  }, 1000)
                }}
                onMouseLeave={() => {
                  secondaryHover.current = 'close'
                  setHoverElement('')
                }}
              >
                {hoverElement === 'addSecondInstance' ? (
                  <div
                    className="hoverComponentContainer"
                    style={{ top: '-10px', right: '-220px' }}
                  >
                    <HoverComponent
                      hoverText={getText('hover-7', user.settings)}
                      textAttribute="hover-7"
                      id="addSecondInstance"
                    />
                  </div>
                ) : null}
                <div className="addSignContainer">
                  <img src={addSignImg} alt="addSign" />
                </div>
                <p
                  id={`interestSubmenuTitleOff-${props.claimIndex}`}
                  data-textattribute="submenu-6​"
                >
                  {getText('submenu-6', user.settings)}
                </p>
              </div>
              {freemium.isFreemium &&
                !(currentSnapshot.claims[props.claimIndex] as IndependentClaim)
                  .isInterest && (
                  <PremiumFeatureIcon
                    targetId={`addRemoveClaimInterestContainer-${props.claimIndex}`}
                    forText
                  />
                )}
            </>
          )}
          <div
            className="tool-component-spacer"
            style={{ height: '13px' }}
          ></div>
        </>
      )}
      <Liability
        claimIndex={props.claimIndex}
        errors={props.errors}
        ownRole={props.ownRole}
      />
      <Quantum
        claimIndex={props.claimIndex}
        errors={props.errors}
        ownRole={props.ownRole}
        claimType={props.claimType}
      />
      <BarChart claimIndex={props.claimIndex} />
      <ValueOfIndependentClaim
        claimIndex={props.claimIndex}
        claimInfo={currentSnapshot.claims[props.claimIndex] as IndependentClaim}
        firstTrialDate={currentSnapshot.firstTrialDate}
        secondTrialDate={currentSnapshot.secondTrialDate}
      />
      <span
        className="delete"
        onClick={() => {
          toggleDeleteMessage()
          secondaryHover.current = 'close'
          setHoverElement('')
        }}
      >
        {hoverElement === `deleteClaim${props.claimIndex}` ? (
          <div
            className="hoverComponentContainer"
            style={{ top: '36px', right: '-20px' }}
          >
            <HoverComponent
              hoverText={getText('hover-8', user.settings)}
              textAttribute="hover-8"
              id={`deleteClaim${props.claimIndex}`}
              style={{
                width: 'fit-content',
                minWidth: 'none',
                padding: '4px 20px',
              }}
            />
          </div>
        ) : null}
        {deleteMessage ? (
          <DeleteClaimWindow
            id="claim"
            claimIndex={props.claimIndex}
            claimName={''}
            claimType={props.claimType}
            cancelFunction={toggleDeleteMessage}
            deleteFunction={() =>
              props.removeClaim(
                props.claimIndex,
                `switchClaimTypeButton-${props.claimIndex}`,
                `claimTitle-${props.claimIndex}`,
              )
            }
            position={[0, -344]}
          />
        ) : props.ownRole !== AccessRole.VIEWER ? (
          <img
            src={exitImg}
            alt="exitImg"
            className="exitImg"
            onMouseEnter={() => {
              secondaryHover.current = `deleteClaim${props.claimIndex}`
              setTimeout(() => {
                if (
                  secondaryHover.current === `deleteClaim${props.claimIndex}` &&
                  !deleteMessage
                ) {
                  setHoverElement(`deleteClaim${props.claimIndex}`)
                }
              }, 1000)
            }}
            onMouseLeave={() => {
              secondaryHover.current = 'close'
              setHoverElement('')
            }}
          />
        ) : null}
      </span>
    </div>
  )
}
