import React from 'react'
import { SortDirection } from '../../../models/generalTypes'
import TitleComponent from './TitleComponent'
import {
  FreemiumListItem,
  FreemiumSortOption,
} from '../../../models/freemiumGeneralTypes'

type Props = {
  widthOfCaseContainer: number | undefined
  isMyScenariosPage: boolean | undefined
  setSearchList: (value: React.SetStateAction<FreemiumListItem[]>) => void
  sortBy: (
    option: FreemiumSortOption,
    changeDirection: boolean,
    listToSort?: FreemiumListItem[] | undefined,
  ) => FreemiumListItem[]
  sortOption: keyof FreemiumListItem
  sortDirection: SortDirection
  hasStorageCase: boolean
}

export default function FreemiumMyCasesTitles(props: Props) {
  return (
    <div
      className="myCasesList-titles"
      style={{ width: props.widthOfCaseContainer }}
    >
      <div
        className="myCasesList-titles-left "
        style={props.isMyScenariosPage ? { marginRight: '7px' } : undefined}
      >
        <TitleComponent
          sortOption={props.sortOption}
          sortOptionOfThisTitle="title"
          sortDirection={props.sortDirection}
          onClick={() => props.setSearchList(props.sortBy('title', true))}
          textAttribute={props.isMyScenariosPage ? 'title-7' : 'title-8'}
          id="caseDetailsContainer-title-name"
          className="caseDetailsContainer-title"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className="myCasesList-titles-right">
        {!props.isMyScenariosPage && (
          <TitleComponent
            sortOption={props.sortOption}
            sortDirection={props.sortDirection}
            textAttribute="title-9"
            id="caseDetailsContainer-title-shareWith"
            className="caseDetailsContainer-title-owner caseDetailsContainer-small"
            style={{ cursor: 'pointer' }}
            noText={!props.hasStorageCase}
          />
        )}
        <TitleComponent
          textAttribute="title-10"
          id="caseDetailsContainer-title-created"
          className="caseDetailsContainer-title caseDetailsContainer-title-date-created caseDetailsContainer-small"
          style={{ marginLeft: '3px' }}
          sortOption={props.sortOption}
          sortDirection={props.sortDirection}
        />
        {props.isMyScenariosPage && (
          <TitleComponent
            sortOption={props.sortOption}
            sortDirection={props.sortDirection}
            textAttribute="title-12"
            id="caseDetailsContainer-title-status"
            className="caseDetailsContainer-title-status caseDetailsContainer-title caseDetailsContainer-small"
            style={{ cursor: 'pointer' }}
          />
        )}
        <TitleComponent
          sortOption={props.sortOption}
          sortDirection={props.sortDirection}
          textAttribute="title-13"
          id="caseDetailsContainer-title-actions"
          className="caseDetailsContainer-title-actions caseDetailsContainer-small"
          style={props.isMyScenariosPage ? { marginRight: '15px' } : undefined}
          noText={!props.hasStorageCase}
        />
      </div>
    </div>
  )
}
