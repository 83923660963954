import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { RecoilRoot } from 'recoil'
import { isDevEnv, isStagingEnv, isTestEnv } from './services/commonFunctions'
import DebugComponent from './Components/debugComponent/DebugComponent'

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <RecoilRoot>
      {isDevEnv() || isStagingEnv() || isTestEnv() ? <DebugComponent /> : null}
      <App />
    </RecoilRoot>
  </React.StrictMode>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals()
