import React from 'react'
import { useRecoilValue } from 'recoil'
import { ScenarioIdentity } from '../../models/generalTypes'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'

type Props = {
  scenarioIdentity: ScenarioIdentity
}

export default function FailedResults(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <div className="failedCaseMessageContainer">
      <h2
        className="failedCaseMessageTitle"
        id="failedCaseMessageTitle"
        data-textattribute="title-220"
      >
        {getText('title-220', user.settings)}
      </h2>
      <div className="failedCaseMessageTextContainer">
        <p
          className="failedCaseMessageText"
          id="failedCaseMessageText-1"
          data-textattribute="description-178a,description-178b,description-178c"
        >
          {getText('description-178a', user.settings)}
          {props.scenarioIdentity.scenarioName}
          {getText('description-178b', user.settings)}{' '}
          {props.scenarioIdentity.snapshotId}{' '}
          {getText('description-178c', user.settings)}
        </p>
        <br></br>
        <p
          className="failedCaseMessageText"
          id="failedCaseMessageText-2"
          style={{ marginTop: 6 }}
          data-textattribute="description-179a,description-179b,description-179c"
        >
          {getText('description-179a', user.settings)}{' '}
          <a href="mailto:support@eperoto.com" className="link">
            {getText('description-179b', user.settings)}
          </a>
          {getText('description-179c', user.settings)}
        </p>
      </div>
    </div>
  )
}
