import React, { useCallback, useEffect, useState } from 'react'
import { FixedSizeGrid as Grid } from 'react-window'

import {
  formattedNumToString,
  stringToNumForCalculations,
} from '../../../../../../../services/formatNum'
import { TreeAnalysisResults } from '../../../../../../../models/generalTypes'
import {
  isDevEnv,
  isStagingEnv,
  isTestEnv,
  roundNumberTo,
  treeTableFontSize,
} from '../../../../../../../services/commonFunctions'
import { useRecoilValue } from 'recoil'
import { debuggingState } from '../../../../../../../states/DebuggingState'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import {
  checkIfTreeHasInterest,
  treeHasOnlyAmount,
  treeHasOutOfCourt,
  treeTableWidth,
} from '../../../../../../../services/resultsFunctions'
import { userState } from '../../../../../../../states/UserState'
import TreeTableTitle from './TreeTableTitle'
import { InterestViewOption } from '../../../../../../../models/enums'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  calculationOfTreeArray: TreeAnalysisResults[]
  sumOfProbs: number
  setSelectedPath: (path: number[]) => void
  selectedPath: number[]
  activeRow: number | undefined
  setActiveRow: (param: number | undefined) => void
  treeInterestViewOption: InterestViewOption
  treeIndex: number
}

export default function TreeTable(props: Props) {
  const debugging = useRecoilValue(debuggingState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const user = useRecoilValue(userState)

  const [sorting, setSorting] = useState<(boolean | string)[]>(
    new Array(6).fill(false),
  )

  const [sortedDataForTable, setSortedDataForTable] = useState(
    initializeDataForTable(),
  )
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot

  const tableWidth = treeTableWidth(
    props.calculationOfTreeArray,
    currentSnapshot,
  )

  function initializeDataForTable() {
    var tempData: (string | number | string[] | number[])[][] = []
    if (props.calculationOfTreeArray.length > 0) {
      tempData = props.calculationOfTreeArray.map((item, id) => [
        `${id + 1}`,
        formattedNumToString(roundNumberTo(item[1], 3), user.settings),
        formattedNumToString(roundNumberTo(item[3], 3), user.settings),
        props.treeInterestViewOption === InterestViewOption.noInterest
          ? '-'
          : formattedNumToString(roundNumberTo(item[2], 3), user.settings),
        props.treeInterestViewOption === InterestViewOption.noInterest
          ? formattedNumToString(
              roundNumberTo(item[4] - item[2], 3),
              user.settings,
            )
          : formattedNumToString(roundNumberTo(item[4], 3), user.settings),
        item[5] * 100,
        item[0],
      ])

      return tempData
    }
  }

  useEffect(() => {
    setSortedDataForTable(initializeDataForTable())
    setSorting(new Array(6).fill(false))
    // eslint-disable-next-line
  }, [props.treeIndex])

  useEffect(() => {
    var tempData: (string | number | string[] | number[])[][] = []
    if (props.calculationOfTreeArray.length > 0) {
      tempData = props.calculationOfTreeArray.map((item, id) => [
        `${id + 1}`,
        formattedNumToString(roundNumberTo(item[1], 3), user.settings),
        formattedNumToString(roundNumberTo(item[3], 3), user.settings),
        props.treeInterestViewOption === InterestViewOption.noInterest
          ? '-'
          : formattedNumToString(roundNumberTo(item[2], 3), user.settings),
        props.treeInterestViewOption === InterestViewOption.noInterest
          ? formattedNumToString(
              roundNumberTo(item[4] - item[2], 3),
              user.settings,
            )
          : formattedNumToString(roundNumberTo(item[4], 3), user.settings),
        item[5] * 100,
        item[0],
      ])
      let sortingIndex = sorting.findIndex((column) => column !== false)
      if (sortingIndex !== -1) {
        sortByFunction(sortingIndex, tempData, true)
      } else {
        setSortedDataForTable(tempData)
      }
    }
    // eslint-disable-next-line
  }, [props.treeInterestViewOption])

  useEffect(() => {
    if (props.selectedPath.length === 0) {
      props.setActiveRow(undefined)
    }
    // eslint-disable-next-line
  }, [props.selectedPath, props.activeRow])

  function sortByFunction(
    option: number,
    dataForSorting: any,
    noChange?: boolean,
  ) {
    var tempSortedDataForTable = JSON.parse(JSON.stringify(dataForSorting))
    var tempSortArray = new Array(6).fill(false)
    var sortedArray = []
    if (noChange) {
      tempSortArray[option] = sorting[option]
    } else if (sorting[option] === false) {
      tempSortArray[option] = 'asc'
    } else if (sorting[option] === 'asc') {
      tempSortArray[option] = 'des'
    }
    setSorting(tempSortArray)

    function ComparatorAsc(a: any, b: any) {
      if (
        stringToNumForCalculations(a[option].toString(), user.settings) <
        stringToNumForCalculations(b[option].toString(), user.settings)
      )
        return -1
      if (
        stringToNumForCalculations(a[option].toString(), user.settings) >
        stringToNumForCalculations(b[option].toString(), user.settings)
      )
        return 1
      return 0
    }
    function ComparatorDes(a: any, b: any) {
      if (
        stringToNumForCalculations(a[option].toString(), user.settings) >
        stringToNumForCalculations(b[option].toString(), user.settings)
      )
        return -1
      if (
        stringToNumForCalculations(a[option].toString(), user.settings) <
        stringToNumForCalculations(b[option].toString(), user.settings)
      )
        return 1
      return 0
    }

    if (tempSortArray[option] === false) {
      sortedArray = tempSortedDataForTable.sort((a: any, b: any) => {
        if (
          stringToNumForCalculations(a[0], user.settings) <
          stringToNumForCalculations(b[0], user.settings)
        )
          return -1
        if (
          stringToNumForCalculations(a[0], user.settings) >
          stringToNumForCalculations(b[0], user.settings)
        )
          return 1
        return 0
      })
    } else if (tempSortArray[option] === 'asc') {
      sortedArray = tempSortedDataForTable.sort(ComparatorAsc)
    } else if (tempSortArray[option] === 'des') {
      sortedArray = tempSortedDataForTable.sort(ComparatorDes)
    }

    setSortedDataForTable(sortedArray)
  }

  function selectRow(path: string, id: number) {
    if (path === JSON.stringify(props.selectedPath)) {
      props.setSelectedPath([])
      props.setActiveRow(undefined)
    } else {
      props.setActiveRow(undefined)
      props.setSelectedPath([])

      setTimeout(() => {
        props.setSelectedPath(JSON.parse(path))
        props.setActiveRow(id)
      }, 200)
    }
  }

  const cellRenderer = useCallback(
    (propsOfRenderer: any) => {
      return (
        <div
          className={`treeTableRow row row${propsOfRenderer.rowIndex} ${
            props.activeRow ===
            parseInt(sortedDataForTable![propsOfRenderer.rowIndex][0] as string)
              ? 'activeRow'
              : ''
          } ${krogerusColors ? 'krogerus' : ''}`}
          key={`treeTableRow-${propsOfRenderer.rowIndex}`}
          style={{ ...propsOfRenderer.style, width: tableWidth - 15 }}
          onClick={() =>
            selectRow(
              sortedDataForTable![propsOfRenderer.rowIndex][6] as string,
              parseInt(
                sortedDataForTable![propsOfRenderer.rowIndex][0] as string,
              ),
            )
          }
          data-openreplay-obscured
        >
          <div className="treeTableCellContainer column1">
            <p className={`treeTableCell robotoNumbers column1`}>
              {sortedDataForTable![propsOfRenderer.rowIndex][0]}
            </p>
          </div>
          <div className="treeTableCellContainer column2">
            <p
              className={`treeTableCell robotoNumbers column2`}
              style={treeTableFontSize(
                sortedDataForTable![propsOfRenderer.rowIndex][1] as string,
              )}
            >
              {sortedDataForTable![propsOfRenderer.rowIndex][1]}
            </p>
          </div>
          {treeHasOutOfCourt(props.calculationOfTreeArray) ? (
            <div className="treeTableCellContainer column3">
              <p
                className={`treeTableCell robotoNumbers column3`}
                style={treeTableFontSize(
                  sortedDataForTable![propsOfRenderer.rowIndex][2] as string,
                )}
              >
                {sortedDataForTable![propsOfRenderer.rowIndex][2]}
              </p>
            </div>
          ) : null}
          {checkIfTreeHasInterest(
            props.calculationOfTreeArray,
            currentSnapshot,
          ) ? (
            <div className="treeTableCellContainer column4">
              <p
                className={`treeTableCell robotoNumbers column4`}
                style={treeTableFontSize(
                  sortedDataForTable![propsOfRenderer.rowIndex][3] as string,
                )}
              >
                {sortedDataForTable![propsOfRenderer.rowIndex][3]}
              </p>
            </div>
          ) : null}
          {treeHasOnlyAmount(props.calculationOfTreeArray) === false ? (
            <div className="treeTableCellContainer column5">
              <p
                className={`treeTableCell robotoNumbers column5`}
                style={treeTableFontSize(
                  sortedDataForTable![propsOfRenderer.rowIndex][4] as string,
                )}
              >
                {sortedDataForTable![propsOfRenderer.rowIndex][4]}
              </p>
            </div>
          ) : null}
          <div className="treeTableCellContainer column6">
            <p
              className={`treeTableCell robotoNumbers column6`}
              style={treeTableFontSize(
                sortedDataForTable![propsOfRenderer.rowIndex][5] as string,
              )}
            >
              {(sortedDataForTable![propsOfRenderer.rowIndex][5] as number) <
                0.01 &&
              (sortedDataForTable![propsOfRenderer.rowIndex][5] as number) !== 0
                ? '~ 0 %'
                : `${formattedNumToString(
                    roundNumberTo(
                      sortedDataForTable![
                        propsOfRenderer.rowIndex
                      ][5] as number,
                      2,
                    ),
                    user.settings,
                  )} %`}
            </p>
          </div>
        </div>
      )
    },
    // eslint-disable-next-line
    [
      user.settings,
      currentSnapshot,
      sortedDataForTable,
      props.activeRow,
      props.treeIndex,
    ],
  )

  return (
    <div className="treeTableContainer">
      <div
        className="treeTableTitleContainer"
        style={{ width: tableWidth - 20 }}
      >
        <TreeTableTitle
          onClick={() => sortByFunction(0, sortedDataForTable)}
          sorting={sorting[0]}
          columnClass={'column1'}
          dataTextAttribute={'title-48'}
          id={'id'}
        />
        <TreeTableTitle
          onClick={() => sortByFunction(1, sortedDataForTable)}
          sorting={sorting[1]}
          columnClass={'column2'}
          dataTextAttribute={'title-49'}
          id={'amount'}
        />

        {treeHasOutOfCourt(props.calculationOfTreeArray) ? (
          <TreeTableTitle
            onClick={() => sortByFunction(2, sortedDataForTable)}
            sorting={sorting[2]}
            columnClass={'column3'}
            dataTextAttribute={'title-50'}
            id={'outOfCourtAmount'}
          />
        ) : null}
        {checkIfTreeHasInterest(
          props.calculationOfTreeArray,
          currentSnapshot,
        ) ? (
          <TreeTableTitle
            onClick={() => sortByFunction(3, sortedDataForTable)}
            sorting={sorting[3]}
            columnClass={'column4'}
            dataTextAttribute={'title-51'}
            id={'interest'}
          />
        ) : null}
        {treeHasOnlyAmount(props.calculationOfTreeArray) === false ? (
          <TreeTableTitle
            onClick={() => sortByFunction(4, sortedDataForTable)}
            sorting={sorting[4]}
            columnClass={'column5'}
            dataTextAttribute={'title-52'}
            id={'totalAmount'}
          />
        ) : null}
        <TreeTableTitle
          onClick={() => sortByFunction(5, sortedDataForTable)}
          sorting={sorting[5]}
          columnClass={'column6'}
          dataTextAttribute={'title-53'}
          id={'probability'}
        />
      </div>
      <div className="tableRows">
        <Grid
          columnCount={1}
          width={tableWidth}
          height={
            sortedDataForTable!.length === 1
              ? 30
              : sortedDataForTable!.length === 2
              ? 60
              : sortedDataForTable!.length === 3
              ? 90
              : 120
          }
          rowCount={sortedDataForTable!.length}
          rowHeight={27}
          className={
            sortedDataForTable!.length > 4
              ? 'tableTreeResults'
              : 'tableTreeResults noScroll'
          }
          columnWidth={tableWidth}
        >
          {cellRenderer}
        </Grid>
      </div>
      {(isDevEnv() || isStagingEnv() || isTestEnv()) && debugging ? (
        <h6 className="tableInfo">
          Total percentage: {props.sumOfProbs * 100}%{'              '}, Number
          of Scenarios: {props.calculationOfTreeArray.length}
        </h6>
      ) : null}
    </div>
  )
}
