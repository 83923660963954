import { useState } from 'react'
import useWindowSize from '../../customHooks/useWindowSize'
import { getScaleForPopUp } from '../../services/popUpPositionFunctions'
import Button from '../Buttons/Button/Button'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'
import { useRecoilValue } from 'recoil'

type Props = {
  changeSnapshotPreview: (value: 'old' | 'new') => void
}

const RecoveryScenarioPopUp = (props: Props) => {
  const { width, height } = useWindowSize()
  const [closing, setClosing] = useState(false)
  const user = useRecoilValue(userState)

  return (
    <div
      id="recoveryScenarioBackgroundPopUp"
      className={`recoveryBackgroundPopUp`}
    >
      <div
        className={`recoveryPopUp ${closing ? 'close' : ''}`}
        style={{
          transform: `scale(${getScaleForPopUp(width, height, 630, 225)})`,
        }}
      >
        <div
          className="title"
          id="recoverySnapshotFoundTitle"
          data-textattribute={'title-233'}
        >
          {getText('title-233', user.settings)}
        </div>
        <div
          className="subTitle"
          id="recoverySnapshotFoundSubTitle"
          data-textattribute="description-189"
        >
          {getText('description-189', user.settings)}
        </div>
        <div className="buttonsContainer">
          <Button
            buttonText={getText('button-151', user.settings)}
            buttonTextAttribute={'button-151'}
            id="oldVersionButton"
            type="button"
            buttonType="text"
            onClick={() => {
              setClosing(true)
              props.changeSnapshotPreview('old')
            }}
            side="left"
            NoUpperCase={true}
            small={false}
          />
          <Button
            buttonText={getText('button-152', user.settings)}
            buttonTextAttribute={'button-152'}
            buttonType="contained"
            id="newVersionButton"
            type="button"
            onClick={() => {
              setClosing(true)
              props.changeSnapshotPreview('new')
            }}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        </div>
      </div>
    </div>
  )
}

export default RecoveryScenarioPopUp
