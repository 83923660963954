import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import Button from '../../../Components/Buttons/Button/Button'
import FullScreenPopMessage from '../../../Components/popUps/FullScreenPopMessage'
import { User } from '../../../models/user'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'
import { userState } from '../../../states/UserState'
import { useNavigate } from 'react-router-dom'
import WarningSubTextHandlingErrors from '../../../Components/popUps/popUpSmallComponents/WarningSubTextHandlingErrors'
import { getText } from '../../../services/textFunctions'

export default function HandlingErrorsComponent() {
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [user, setUser] = useRecoilState(userState)
  const navigate = useNavigate()
  const [timer, setTimer] = useState(0)
  const timeoutRef = useRef<NodeJS.Timeout | undefined>(undefined)
  const zIndex = 6001

  useEffect(() => {
    if (handlingErrors.not_exist === true) {
      setTimer(5)
      const handler = setTimeout(() => {
        setHandlingErrors({ ...handlingErrors, not_exist: false })
        navigate('/')
        timeoutRef.current = undefined
      }, 5000)
      timeoutRef.current = handler
    }
    // eslint-disable-next-line
  }, [handlingErrors])

  useEffect(() => {
    if (timer !== 0) {
      setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)
    }
  }, [timer])

  return (
    <>
      {handlingErrors.server_error ? (
        <FullScreenPopMessage
          id="serverError"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-77"
          warningTitle={getText('title-77', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-serverError"
                data-textattribute="description-19"
              >
                {getText('description-19', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="serverError" />
          }
          cancelFunction={() =>
            setHandlingErrors({ ...handlingErrors, server_error: false })
          }
          blur
        />
      ) : handlingErrors.unauthorized ? (
        <FullScreenPopMessage
          id="unauthorized"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-78"
          warningTitle={getText('title-78', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-unauthorized"
                data-textattribute="description-21"
              >
                {getText('description-21', user.settings)}
              </p>
              <Button
                id="loginButton-popUp08"
                buttonText={getText('button-66', user.settings)}
                buttonType="contained"
                type="button"
                className="warningButton"
                onClick={() => setUser(User.EmptyUser())}
                buttonTextAttribute="button-66"
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="unauthorized" />
          }
          blur
        />
      ) : handlingErrors.forbidden ? (
        <FullScreenPopMessage
          id="forbidden"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-79"
          warningTitle={getText('title-79', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-forbidden1"
                data-textattribute="description-22"
              >
                {getText('description-22', user.settings)}
              </p>
              <p
                className="warningText"
                id="popUpWarningText-forbidden2"
                data-textattribute="description-23"
              >
                {getText('description-23', user.settings)}
              </p>
              <Button
                id="goToMyCasesButton-popUp08"
                buttonText={getText('button-89', user.settings)}
                buttonType="contained"
                type="button"
                className="warningButton"
                onClick={() => {
                  clearTimeout(timeoutRef.current)
                  setTimer(0)
                  setHandlingErrors({ ...handlingErrors, forbidden: false })
                  setTimeout(() => {
                    navigate('/')
                  }, 200)
                }}
                buttonTextAttribute="button-89"
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="forbidden" />
          }
          cancelFunction={() => {
            setHandlingErrors({ ...handlingErrors, forbidden: false })
            setTimeout(() => {
              navigate('/')
            }, 200)
          }}
          blur
        />
      ) : handlingErrors.bad_request ? (
        <FullScreenPopMessage
          id="badRequest"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-80"
          warningTitle={getText('title-80', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-badRequest"
                data-textattribute="description-24"
              >
                {getText('description-24', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="badRequest" />
          }
          cancelFunction={() =>
            setHandlingErrors({ ...handlingErrors, bad_request: false })
          }
          blur
        />
      ) : handlingErrors.timeout ? (
        <FullScreenPopMessage
          id="timeout"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-81"
          warningTitle={getText('title-81', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-timeout"
                data-textattribute="description-25"
              >
                {getText('description-25', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="timeout" />
          }
          cancelFunction={() =>
            setHandlingErrors({ ...handlingErrors, timeout: false })
          }
          blur
          noConnection
        />
      ) : handlingErrors.fatal ? (
        <FullScreenPopMessage
          id="fatal"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-82"
          warningTitle={getText('title-82', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-fatal"
                data-textattribute="description-26"
              >
                {getText('description-26', user.settings)}
              </p>
              <Button
                id="reloadPage-popUp08"
                buttonText={getText('button-67', user.settings)}
                buttonTextAttribute="button-67"
                buttonType="contained"
                type="button"
                className="warningButton"
                onClick={() => window.location.reload()}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </>
          }
          warningSubTextContainer={<WarningSubTextHandlingErrors id="fatal" />}
          blur
        />
      ) : handlingErrors.not_exist ? (
        <FullScreenPopMessage
          id="notExist"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-83"
          warningTitle={getText('title-83', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-notExist"
                style={{ lineHeight: 1.3 }}
                data-textattribute="description-27a,description-27b, description-27c"
              >
                {getText('description-27a', user.settings)}
                <br />
                {getText('description-27b', user.settings)} {timer}{' '}
                {getText('description-27c', user.settings)}
              </p>
              <Button
                id="goToMyCasesButton-popUp08"
                buttonText={getText('button-89', user.settings)}
                buttonType="contained"
                type="button"
                className="warningButton"
                onClick={() => {
                  clearTimeout(timeoutRef.current)
                  setTimer(0)
                  setHandlingErrors({ ...handlingErrors, not_exist: false })
                  setTimeout(() => {
                    navigate('/')
                  }, 400)
                }}
                buttonTextAttribute="button-89"
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="notExist" />
          }
          blur
        />
      ) : handlingErrors.wrong_reduced_dates ? (
        <FullScreenPopMessage
          id="wrongReducedDates"
          zIndex={zIndex}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-262"
          warningTitle={getText('title-262', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-notExist"
                style={{ lineHeight: 1.3, width: 800 }}
                data-textattribute="description-246, description-247, description-248"
              >
                {getText('description-246', user.settings)}
                <br />
                <br />
                {getText('description-247', user.settings)}
                <br />
                <br />
                {getText('description-248', user.settings)}
              </p>
            </>
          }
          warningSubTextContainer={
            <WarningSubTextHandlingErrors id="wrongReducedDates" />
          }
          blur
          cancelFunction={() =>
            setHandlingErrors({ ...handlingErrors, wrong_reduced_dates: false })
          }
        />
      ) : null}
    </>
  )
}
