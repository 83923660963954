import { getText } from '../services/textFunctions'
import { UserSettings } from './userSettings'

export const calculationMessages = (
  settings: UserSettings,
  partiesFormat: string[],
) => {
  return {
    //data-textattribute='message-19'
    13: getText('message-19', settings),
    //data-textattribute='message-20'
    14: getText('message-20', settings),
    //data-textattribute='message-21'
    24: getText('message-21', settings),
    //data-textattribute='message-22'
    26: getText('message-22', settings),
    //data-textattribute='message-23'
    28: getText('message-23', settings),
    //data-textattribute='message-24'
    49: getText('message-24', settings),
    //data-textattribute='message-118'
    63: getText('message-118', settings, partiesFormat),
    //data-textattribute='message-119'
    64: getText('message-119', settings, partiesFormat),
    //data-textattribute='message-120'
    74: getText('message-120', settings, partiesFormat),
    //data-textattribute='message-121'
    99: getText('message-121', settings, partiesFormat),
  }
}

export const calculationMessagesForAWA = (
  settings: UserSettings,
  partiesFormat: string[],
) => {
  return {
    //data-textattribute='message-110'
    40: getText('message-110', settings),
    //data-textattribute='message-21'
    47: getText('message-21', settings),
    //data-textattribute='message-24'
    48: getText('message-24', settings),
    //data-textattribute='message-26'
    49: getText('message-26', settings),
    //data-textattribute='message-27'
    50: getText('message-27', settings),
    //data-textattribute='message-122'
    85: getText('message-122', settings, partiesFormat),
    //data-textattribute='message-120'
    89: getText('message-120', settings, partiesFormat),
    //data-textattribute='message-121'
    93: getText('message-121', settings, partiesFormat),
    //data-textattribute='message-123'
    99: getText('message-123', settings, partiesFormat),
  }
}
