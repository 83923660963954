import { ResultsObject } from '../../models/generalTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../models/reportModels/reportEnums'
import {
  ExecutiveSummaryVisibleElements,
  ReportWhitelabel,
} from '../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../models/scenarioSnapshot'
import { User } from '../../models/user'
import { stringAndRoundTo2Decimals } from '../../Modules/DisputeModules/AppFunctions'
import { getStyleFromWhitelabel } from '../../services/reportFunctions'
import { getText } from '../../services/textFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  reportWhitelabel?: ReportWhitelabel
  user: User
  currentSnapshot: ScenarioSnapshot
  type:
    | 'selectionGraph'
    | 'distributionGraph'
    | 'getPayGraph'
    | 'expectedValueBreakdown'
    | 'executiveSummary'
    | 'newExecutiveSummary'
  pdfReport?: boolean
  visibleElements?: ExecutiveSummaryVisibleElements
  setShowAWADetails?: (showAWADetails: boolean) => void
  style?: React.CSSProperties
  downloadingImage?: boolean
}

export default function AwaLegendComponent(props: Props) {
  return (
    <>
      {props.resultsFromBackend &&
      props.resultsFromBackend.model_used === 'statistical' ? (
        props.type !== 'expectedValueBreakdown' ? (
          <p
            className={`asteriskMessageContainer ${
              props.visibleElements
                ? props.visibleElements!.valueOfCase
                  ? 'previewPDF'
                  : 'fadeText previewPDF'
                : ''
            } ${
              props.pdfReport === undefined &&
              props.type === 'distributionGraph'
                ? 'distribution'
                : ''
            } ${props.type}`}
            id={`${props.type}-asteriskMessageContainer${
              props.pdfReport ? '-forReport' : ''
            }`}
            data-textattribute={'description-176'}
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                props.type === 'executiveSummary' ? false : true,
              ),
              ...props.style,
            }}
            data-openreplay-obscured
          >
            {getText('description-176', props.user.settings)}{' '}
            <span
              className="noBold robotoNumbers"
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'roboto',
                false,
                props.type === 'executiveSummary' ? false : true,
              )}
            >
              {stringAndRoundTo2Decimals(
                props.resultsFromBackend.difference_from_value,
                props.user.settings,
              )}{' '}
              {props.currentSnapshot.currency}
            </span>
            .{' '}
            {props.pdfReport !== true && props.downloadingImage !== true ? (
              <span
                className="asteriskMessageContainer-learnMoreSpan"
                id={`asteriskMessageContainer-learnMoreSpan-${props.type}${
                  props.pdfReport ? '-forReport' : ''
                }`}
                onClick={() => props.setShowAWADetails!(true)}
                data-textattribute={'description-177'}
              >
                {getText('description-177', props.user.settings)}
              </span>
            ) : null}
          </p>
        ) : (
          <p
            className={`asteriskMessageContainer ${
              props.visibleElements
                ? props.visibleElements!.valueOfCase
                  ? 'previewPDF'
                  : 'fadeText previewPDF'
                : ''
            }`}
            id={`${props.type}-asteriskMessageContainer${
              props.pdfReport ? '-forReport' : ''
            }`}
            data-openreplay-obscured
            style={{
              ...getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
                false,
                true,
              ),
              ...props.style,
            }}
            data-textattribute={'description-276'}
            //newText DONE
          >
            {getText('description-276', props.user.settings)}{' '}
            {props.pdfReport !== true && props.downloadingImage !== true ? (
              <span
                className="asteriskMessageContainer-learnMoreSpan"
                id={`asteriskMessageContainer-learnMoreSpan-${props.type}`}
                onClick={() => props.setShowAWADetails!(true)}
                data-textattribute={'description-177'}
              >
                {getText('description-177', props.user.settings)}
              </span>
            ) : null}
          </p>
        )
      ) : null}
    </>
  )
}
