import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { WhiteTooltipDelay700 as WhiteTooltipZoom } from '../../../../../../../Components/tooltip/TooltipComponent'
import {
  AccessRole,
  AmountSignum,
  PartySignum,
} from '../../../../../../../models/enums'
import { DefaultSnapshotState } from '../../../../../../../models/generalTypes'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import { TreeClaim } from '../../../../../../../models/treeModels/treeClaim'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import {
  roundNumberTo,
  treeAmountFontSize,
} from '../../../../../../../services/commonFunctions'
import { convertDateTimeToLocal } from '../../../../../../../services/dateFunctions'
import { formattedNumToString } from '../../../../../../../services/formatNum'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { findEventNumber } from '../../../../../../../services/twoParentsFunctionsForNumberOfScenarios'
import Symbol from '../../../../../../../Components/symbol/Symbol'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  eventDetails: EventDetails
  nodeId: NodeId
  eventId: EventId
  eventIndex: number
  treeIndex: number
  nodeSelected: boolean
  nodeHasAtLeastOneAwardedAmount: boolean
  nodeHasAtLeastOneOutOfCourtAmount: boolean
  nodeHasAtLeastOneInterestMenuOn: boolean
  nodeHasAtLeastOneReducedAmount: boolean
  nodeHasAtLeastOneEventTitle: boolean
  showTreeInfo: boolean
  scenarioSnapshot: DefaultSnapshotState
  resetActivePath: boolean
  selectedPath: number[]
  debugging: boolean
  highlighted: boolean
  zoomLevel: number
  connectionCreatesLoop: EventId | NodeId | undefined
  ownRole: AccessRole
}

export default function TreeEventMinimised(props: Props) {
  const currency = props.scenarioSnapshot.currentSnapshot.currency
  const treeDetails = (
    props.scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).treeDetails
  const partiesFormat = [
    props.scenarioSnapshot.currentSnapshot.partyFormatOwn,
    props.scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const [active, setActive] = useState(false)
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  useEffect(() => {
    if (!props.resetActivePath) {
      setActive(false)
    } else {
      if (props.selectedPath.length > 0) {
        for (let eventId of props.selectedPath) {
          if (eventId === findEventNumber(props.eventId, treeDetails)) {
            setActive(true)
            break
          } else {
            setActive(false)
          }
        }
      } else {
        setActive(false)
      }
    }
    // eslint-disable-next-line
  }, [props.selectedPath, props.resetActivePath])

  return (
    <div
      className="treeEventBackgroundMinimised"
      id={`treeEventBackgroundMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
    >
      <div
        className={`treeEventTitleContainerMinimised ${
          active ? 'active' : ''
        } ${!props.nodeHasAtLeastOneEventTitle ? 'noEventTitle' : ''} ${
          props.nodeSelected || props.highlighted ? 'selected' : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventTitleContainerMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
        data-openreplay-obscured
      >
        {props.showTreeInfo ? (
          <p className="treeEventNumber">
            {Object.keys(
              (
                props.scenarioSnapshot.currentSnapshot.claims[
                  props.treeIndex
                ] as TreeClaim
              ).treeDetails.events,
            ).map((eventId, index) => {
              if (props.eventId === eventId) {
                return index
              } else {
                return ''
              }
            })}
          </p>
        ) : null}
        <p
          className="treeEventTitleMinimised"
          id={`treeEventTitleMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
        >
          {props.eventDetails.eventTitle}
        </p>
      </div>

      <div
        className={`treeEventDetailsContainerMinimised ${
          active ? 'active' : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventDetailsContainerMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
        data-openreplay-obscured
      >
        {props.nodeHasAtLeastOneAwardedAmount ? (
          <>
            <WhiteTooltipZoom
              textAttribute={
                props.eventDetails.awardedAmountSignum === AmountSignum.get
                  ? 'tooltip-21'
                  : props.eventDetails.awardedAmountSignum === AmountSignum.pay
                  ? 'tooltip-22'
                  : undefined
              }
              id={'treeEventAwardedAmountTextMinimised'}
              title={
                props.eventDetails.awardedAmountSignum === AmountSignum.get
                  ? getText('tooltip-21', user.settings, partiesFormat)
                  : props.eventDetails.awardedAmountSignum === AmountSignum.pay
                  ? getText('tooltip-22', user.settings, partiesFormat)
                  : ''
              }
              placement="right"
              zoomLevel={props.zoomLevel}
            >
              <div
                className="treeEventAwardedAmountTextMinimisedContainer"
                id={`treeEventAwardedAmountTextMinimisedContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                {props.eventDetails.awardedAmountSignum !==
                  AmountSignum.off && (
                  <Symbol
                    symbolType={
                      props.eventDetails.awardedAmountSignum ===
                      AmountSignum.get
                        ? 'client'
                        : 'opposingParty'
                    }
                    targetId={`treeEventAwardedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    targetType={'treeAwardedText'}
                  />
                )}

                <p
                  className={`treeEventAwardedAmountTextMinimised robotoNumbers ${
                    props.eventDetails.awardedAmountSignum === AmountSignum.get
                      ? 'positive'
                      : props.eventDetails.awardedAmountSignum ===
                        AmountSignum.pay
                      ? 'negative'
                      : ''
                  } ${krogerusColors ? 'krogerus' : ''}`}
                  style={treeAmountFontSize(
                    `${formattedNumToString(
                      Math.abs(props.eventDetails.awardedAmount),
                      user.settings,
                    )} ${currency}`,
                  )}
                  id={`treeEventAwardedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                >
                  {props.eventDetails.awardedAmountSignum !== AmountSignum.off
                    ? `${formattedNumToString(
                        Math.abs(props.eventDetails.awardedAmount),
                        user.settings,
                      )} ${currency}`
                    : '-'}
                </p>
              </div>
            </WhiteTooltipZoom>

            {props.nodeHasAtLeastOneInterestMenuOn ? (
              props.eventDetails.hasInterest &&
              props.eventDetails.interestRate &&
              props.eventDetails.interestDate ? (
                <WhiteTooltipZoom
                  title={getText('tooltip-23', user.settings)}
                  textAttribute={'tooltip-23'}
                  id={'treeEventInterestTextMinimised'}
                  placement="right"
                  className="treeTooltip"
                  zoomLevel={props.zoomLevel}
                >
                  <div
                    className={`treeEventInterestTextMinimised`}
                    id={`treeEventInterestTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  >
                    <p
                      className="treeEventInterestRateTextMinimised robotoNumbers"
                      id={`treeEventInterestRateTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      style={
                        `${formattedNumToString(
                          roundNumberTo(
                            props.eventDetails.interestRate * 100,
                            1,
                          ),
                          user.settings,
                        )}%`.length > 3
                          ? { marginRight: 3, marginLeft: -7, fontSize: '14px' }
                          : undefined
                      }
                    >
                      {`${formattedNumToString(
                        roundNumberTo(props.eventDetails.interestRate * 100, 1),
                        user.settings,
                      )}%`}
                    </p>
                    <p
                      className="treeEventInterestDateTextMinimised robotoNumbers"
                      id={`treeEventInterestDateTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    >
                      {convertDateTimeToLocal(
                        props.eventDetails.interestDate,
                        user.settings,
                        true,
                      )}
                    </p>
                  </div>
                </WhiteTooltipZoom>
              ) : props.nodeHasAtLeastOneAwardedAmount ? (
                <p
                  className="treeEventNoInterestTextMinimised"
                  id={`treeEventInterestTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                >
                  -
                </p>
              ) : null
            ) : null}
          </>
        ) : null}
        {(props.nodeHasAtLeastOneOutOfCourtAmount ||
          props.nodeHasAtLeastOneReducedAmount) && (
          <div className="gapBetweenAwards" style={{ height: 12 }}></div>
        )}
        {props.nodeHasAtLeastOneReducedAmount ? (
          <WhiteTooltipZoom
            textAttribute={
              props.eventDetails.reducedAwardedAmountParty ===
              PartySignum.client
                ? 'tooltip-62'
                : 'tooltip-63'
            }
            id={'treeEventReducedAmountTextMinimised'}
            title={
              props.eventDetails.reducedAwardedAmountParty ===
              PartySignum.client
                ? getText('tooltip-62', user.settings, partiesFormat)
                : getText('tooltip-63', user.settings, partiesFormat)
            }
            placement="right"
            zoomLevel={props.zoomLevel}
          >
            <div>
              {props.eventDetails.reducedAwardedAmountParty !==
                PartySignum.off && (
                <Symbol
                  symbolType={
                    props.eventDetails.reducedAwardedAmountParty ===
                    PartySignum.client
                      ? 'reducedClientNoParenthesis'
                      : 'reducedOpposingPartyNoParenthesis'
                  }
                  targetId={`treeEventReducedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  targetType={'treeReducedText'}
                />
              )}

              <p
                className={`treeEventReducedAmountTextMinimised robotoNumbers ${
                  props.eventDetails.reducedAwardedAmountParty ===
                  PartySignum.client
                    ? 'positive'
                    : props.eventDetails.reducedAwardedAmountParty ===
                      PartySignum.opposingParty
                    ? 'negative'
                    : ''
                } ${krogerusColors ? 'krogerus' : ''}`}
                style={treeAmountFontSize(
                  `(${formattedNumToString(
                    props.eventDetails.reducedAwardedAmount ?? 0,
                    user.settings,
                  )} ${currency})`,
                )}
                id={`treeEventReducedAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                {props.eventDetails.reducedAwardedAmountParty !==
                PartySignum.off
                  ? `(${formattedNumToString(
                      props.eventDetails.reducedAwardedAmount ?? 0,
                      user.settings,
                    )} ${currency})`
                  : '-'}
              </p>
            </div>
          </WhiteTooltipZoom>
        ) : null}
        {props.nodeHasAtLeastOneOutOfCourtAmount &&
          props.nodeHasAtLeastOneReducedAmount && (
            <div className="gapBetweenAwards" style={{ height: 4 }}></div>
          )}
        {props.nodeHasAtLeastOneOutOfCourtAmount ? (
          <WhiteTooltipZoom
            textAttribute={
              props.eventDetails.outOfCourtAmount > 0
                ? 'tooltip-24'
                : props.eventDetails.outOfCourtAmount < 0
                ? 'tooltip-25'
                : undefined
            }
            id={'treeEventOutOfCourtAmountTextMinimised'}
            title={
              props.eventDetails.outOfCourtAmountSignum === AmountSignum.get
                ? getText('tooltip-24', user.settings)
                : getText('tooltip-25', user.settings)
            }
            placement="right"
            zoomLevel={props.zoomLevel}
          >
            <div>
              {props.eventDetails.outOfCourtAmountSignum !==
                AmountSignum.off && (
                <Symbol
                  symbolType={'positiveExternalClient'}
                  targetId={`treeEventOutOfCourtAmountTextMinimisedContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  targetType={'treeExternalText'}
                />
              )}
              <div
                className="treeEventOutOfCourtAmountTextMinimisedContainer"
                id={`treeEventOutOfCourtAmountTextMinimisedContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                <p
                  className={`treeEventOutOfCourtAmountTextMinimised robotoNumbers ${
                    props.eventDetails.outOfCourtAmountSignum !==
                    AmountSignum.off
                      ? `border positive ${krogerusColors ? 'krogerus' : ''}`
                      : undefined
                  }`}
                  style={treeAmountFontSize(
                    `${formattedNumToString(
                      props.eventDetails.outOfCourtAmount,
                      user.settings,
                    )} ${currency}`,
                  )}
                  id={`treeEventOutOfCourtAmountTextMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                >
                  {props.eventDetails.outOfCourtAmountSignum !==
                  AmountSignum.off
                    ? `${formattedNumToString(
                        props.eventDetails.outOfCourtAmount,
                        user.settings,
                      )} ${currency}`
                    : '-'}
                </p>
              </div>
            </div>
          </WhiteTooltipZoom>
        ) : null}
        <div
          className="treeEventProbabilityBottomSpaceMinimised"
          id={`treeEventProbabilityBottomSpaceMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
          style={
            !props.nodeHasAtLeastOneAwardedAmount &&
            !props.nodeHasAtLeastOneOutOfCourtAmount &&
            !props.nodeHasAtLeastOneReducedAmount
              ? { height: 10 }
              : { height: 22 }
          }
        ></div>
      </div>
      <div
        className="treeEventProbabilityContainerMinimised"
        id={`treeEventProbabilityContainerMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        <div
          className="treeEventProbabilityCircleMinimised"
          id={`treeEventProbabilityCircleMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
        >
          <p
            className="treeEventProbabilityMinimised robotoNumbersBold"
            id={`treeEventProbabilityMinimised-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            data-openreplay-obscured
          >
            {Math.round(props.eventDetails.probability * 100)}%
          </p>
          {props.connectionCreatesLoop === props.eventId ? (
            <div className="connectionCreatesLoopMessageContainer shake">
              <p
                className="connectionCreatesLoopMessageText"
                id={`connectionCreatesLoopMessageText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                This connection creates a loop
              </p>
            </div>
          ) : undefined}
        </div>
      </div>
    </div>
  )
}
