import React from 'react'
import { useRecoilValue } from 'recoil'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'

export default function WeightedProbability() {
  const user = useRecoilValue(userState)

  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 1,2,3 or 4
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
    17 - name
    */
  return (
    <div className="hoverBackground" data-openreplay-obscured>
      <div className="hoverTextContainer">
        <p
          className="hoverText"
          id="detailedResultsHoverComponentDescription-weigthedValue"
          data-textattribute="description-74"
        >
          {getText('description-74', user.settings)}
        </p>
      </div>
    </div>
  )
}
