import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import { User } from '../../../../../models/user'
import { UserSettings } from '../../../../../models/userSettings'
import {
  deepCloneObject,
  findHandlingErrorState,
  logActivity,
} from '../../../../../services/commonFunctions'
import { saveUserSettings } from '../../../../../services/requests'
import { getText } from '../../../../../services/textFunctions'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { userState } from '../../../../../states/UserState'
import SettingSwitchRow from './SettingSwitchRow'

type Props = {}

const Notifications = (props: Props) => {
  const [user, setUser] = useRecoilState(userState)
  const [loading, setLoading] = useState('')
  const [showSaved, setShowSaved] = useState('')
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)

  const onSave = (id: string, value: boolean) => {
    if (loading === '') {
      setLoading(id)
      let newSettings: UserSettings = deepCloneObject(user.settings)
      switch (id) {
        case 'sharedCaseSwitch':
          newSettings.notifications.sharedCase = value
          break
        case 'removedCaseSwitch':
          newSettings.notifications.removedCase = value
          break
        case 'changeRoleSwitch':
          newSettings.notifications.changeRoleCase = value
          break
        case 'checklistReminderSwitch':
          newSettings.notifications.checklistReminder = value
          break
      }

      saveUserSettings(newSettings).then((res) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'saveUserSettings'),
        )

        if (!('errorCode' in res)) {
          let newUser: User = deepCloneObject(user)
          newUser.settings = UserSettings.FromJson(res.data)

          setUser(newUser)
          setShowSaved(id)
          setTimeout(() => {
            setShowSaved('')
          }, 2500)

          //Mixpanel 94
          logActivity(false, 'Changed notifications in Account Details', {
            notificationName: id.substring(0, id.length - 6),
            value: value ? 'Turned on' : 'Turned off',
          })
        }

        setLoading('')
      })
    }
  }

  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id="notificationsTitle"
        data-textattribute="title-172"
      >
        {getText('title-172', user.settings)}
      </h1>
      <div
        className="tool-component-third-title marginLeft6"
        data-textattribute="submenu-19"
        id="notificationsInstructions"
      >
        {getText('submenu-19', user.settings)}
      </div>
      <SettingSwitchRow
        id={'sharedCaseSwitch'}
        originalValue={user.settings.notifications.sharedCase}
        loading={loading === 'sharedCaseSwitch'}
        showSaved={showSaved === 'sharedCaseSwitch'}
        titleText={getText('title-157', user.settings)}
        titleTextAttribute={'title-157'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1005}
        onSave={onSave}
        isFirst
      />
      <SettingSwitchRow
        id={'removedCaseSwitch'}
        originalValue={user.settings.notifications.removedCase}
        loading={loading === 'removedCaseSwitch'}
        showSaved={showSaved === 'removedCaseSwitch'}
        titleText={getText('title-158', user.settings)}
        titleTextAttribute={'title-158'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1006}
        onSave={onSave}
      />
      <SettingSwitchRow
        id={'changeRoleSwitch'}
        originalValue={user.settings.notifications.changeRoleCase}
        loading={loading === 'changeRoleSwitch'}
        showSaved={showSaved === 'changeRoleSwitch'}
        titleText={getText('title-159', user.settings)}
        titleTextAttribute={'title-159'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1007}
        onSave={onSave}
      />
      <SettingSwitchRow
        id={'checklistReminderSwitch'}
        originalValue={user.settings.notifications.checklistReminder}
        loading={loading === 'checklistReminderSwitch'}
        showSaved={showSaved === 'checklistReminderSwitch'}
        titleText={getText('title-229', user.settings)}
        titleTextAttribute={'title-000'}
        buttonText={getText('button-90', user.settings)}
        buttonTextAttribute={'button-90'}
        confirmationText={getText('button-91', user.settings)}
        confirmationTextAttribute={'button-91'}
        tabIndex={1008}
        onSave={onSave}
      />
    </>
  )
}

export default Notifications
