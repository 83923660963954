import { useEffect, useState } from 'react'
import HoverComponent from '../InfoHover/HoverComponent/HoverComponent'
import useWindowSize from '../../customHooks/useWindowSize'

import clientImg from '../../resources/images/279-clientSymbol.svg'
import krogerusClientImg from '../../resources/images/303-clientSymbolKrogerus.svg'
import opposingPartyImg from '../../resources/images/280-opposingPartySymbol.svg'
import krogerusOpposingPartyImg from '../../resources/images/304-krogerusOpposingPartySymbol.svg'
import firstProceedingsImg from '../../resources/images/281-firstProceedingsSymbol.svg'
import secondProceedingsImg from '../../resources/images/282-secondProceedingsSymbol.svg'
import bankruptcyImg from '../../resources/images/283-bankruptcySymbol.svg'
import positiveExternalClientImg from '../../resources/images/284-positiveExternalClient.svg'
import krogerusPositiveExternalClientImg from '../../resources/images/305-krogerusPositiveExternalClient.svg'
import negativeExternalClientImg from '../../resources/images/298-negativeExternalClient.svg'
import krogerusNegativeExternalClientImg from '../../resources/images/306-krogerusNegativeExternalClient.svg'
import reducedClientImg from '../../resources/images/299-reduceClientAwardedAmount.svg'
import krogerusReducedClientImg from '../../resources/images/307-krogerusReduceClientAwardedAmount.svg'
import reducedOpposingPartyImg from '../../resources/images/300-reduceOpposingPartyAwardedAmount.svg'
import krogerusReducedOpposingPartyImg from '../../resources/images/308-krogerusReduceOpposingPartyAwardedAmount.svg'
import reducedClientNoParenthesisImg from '../../resources/images/295-reducedInputClient-NoParenthesis.svg'
import krogerusReducedClientNoParenthesisImg from '../../resources/images/309-krogerusReducedInputClient-NoParenthesis.svg'
import reducedOpposingPartyNoParenthesisImg from '../../resources/images/296-reducedInputOpposingParty-NoParenthesis.svg'
import krogerusReducedOpposingPartyNoParenthesisImg from '../../resources/images/310-krogerusReducedInputOpposingParty-NoParenthesis.svg'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../states/ScenarioSnapshotState'
import { krogerusColorsState } from '../../states/KrogerusColorsState'

type Props = {
  symbolType:
    | 'client'
    | 'opposingParty'
    | 'firstProceedings'
    | 'secondProceedings'
    | 'bankruptcy'
    | 'positiveExternalClient'
    | 'negativeExternalClient'
    | 'reducedClient'
    | 'reducedOpposingParty'
    | 'reducedClientNoParenthesis'
    | 'reducedOpposingPartyNoParenthesis'
  targetId: string
  targetType:
    | 'dropdown'
    | 'input'
    | 'treeAwardedInput'
    | 'treeAwardedText'
    | 'treeReducedInput'
    | 'treeReducedText'
    | 'treeExternalInput'
    | 'treeExternalText'
    | 'button'
    | 'firstProceedingsText'
    | 'secondProceedingsText'
    | 'bankruptcyTitleText'
    | 'bankruptcyAddRemoveText'
    | 'tileText'
    | 'quantumText'
    | 'legalCostsText'

  hoverMessageId?: string
  customOffset?: [number, number]
}

const Symbol = (props: Props) => {
  const [isHovered, setIsHovered] = useState(false)
  const [position, setPosition] = useState<[number, number] | undefined>(
    undefined,
  )
  const [initialCalc, setInitialCalc] = useState(false)
  const { width, height } = useWindowSize()
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  useEffect(() => {
    if (!initialCalc) {
      setTimeout(() => {
        setInitialCalc(true)
      }, 20)
    } else {
      const el = document.getElementById(props.targetId)
      const calculateRight =
        props.targetType === 'secondProceedingsText' ||
        props.targetType === 'bankruptcyTitleText' ||
        props.targetType === 'bankruptcyAddRemoveText' ||
        props.targetType === 'legalCostsText'

      if (el) {
        if (props.customOffset) {
          setPosition([
            el.offsetTop + props.customOffset[0],
            calculateRight
              ? el.offsetLeft + el.offsetWidth + props.customOffset[0]
              : el.offsetLeft + props.customOffset[0],
          ])
        } else {
          setPosition([
            el.offsetTop,
            calculateRight ? el.offsetLeft + el.offsetWidth : el.offsetLeft,
          ])
        }
      }
    }

    // eslint-disable-next-line
  }, [
    props.targetId,
    width,
    height,
    initialCalc,
    scenarioSnapshot.currentSnapshot.hasInsurance,
  ])

  const getSymbolSize = () => {
    switch (props.symbolType) {
      case 'client':
        return '14px'
      case 'opposingParty':
        return '16px'
      case 'firstProceedings':
        return '24px'
      case 'secondProceedings':
        return '55px'
      case 'bankruptcy':
        return '35px'
      case 'positiveExternalClient':
        return '14px'
      case 'negativeExternalClient':
        return '26px'
      case 'reducedClient':
        return '40px'
      case 'reducedOpposingParty':
        return '40px'
      case 'reducedClientNoParenthesis':
        return '40px'
      case 'reducedOpposingPartyNoParenthesis':
        return '40px'
      default:
        return '17px'
    }
  }

  const getSymbolImg = () => {
    switch (props.symbolType) {
      case 'client':
        return [krogerusColors ? krogerusClientImg : clientImg, 'clientImg']
      case 'opposingParty':
        return [
          krogerusColors ? krogerusOpposingPartyImg : opposingPartyImg,
          'opposingPartyImg',
        ]
      case 'firstProceedings':
        return [firstProceedingsImg, 'firstProceedingsImg']
      case 'secondProceedings':
        return [secondProceedingsImg, 'secondProceedingsImg']
      case 'bankruptcy':
        return [bankruptcyImg, 'bankruptcyImg']
      case 'positiveExternalClient':
        return [
          krogerusColors
            ? krogerusPositiveExternalClientImg
            : positiveExternalClientImg,
          'positiveExternalClientImg',
        ]
      case 'negativeExternalClient':
        return [
          krogerusColors
            ? krogerusNegativeExternalClientImg
            : negativeExternalClientImg,
          'negativeExternalClientImg',
        ]
      case 'reducedClient':
        return [
          krogerusColors ? krogerusReducedClientImg : reducedClientImg,
          'reducedClientImg',
        ]
      case 'reducedOpposingParty':
        return [
          krogerusColors
            ? krogerusReducedOpposingPartyImg
            : reducedOpposingPartyImg,
          'reducedOpposingPartyImg',
        ]
      case 'reducedClientNoParenthesis':
        return [
          krogerusColors
            ? krogerusReducedClientNoParenthesisImg
            : reducedClientNoParenthesisImg,
          'reducedClientNoParenthesisImg',
        ]
      case 'reducedOpposingPartyNoParenthesis':
        return [
          krogerusColors
            ? krogerusReducedOpposingPartyNoParenthesisImg
            : reducedOpposingPartyNoParenthesisImg,
          'reducedOpposingPartyNoParenthesisImg',
        ]
      default:
        return ['', '']
    }
  }

  const getSymbolPosition = () => {
    if (!position) {
      return [0, 0]
    }

    switch (props.targetType) {
      case 'dropdown':
        return [position[0] + 22, position[1] - 23]
      case 'treeReducedInput':
        return [position[0] + 12, position[1] - 41]
      case 'treeAwardedText':
        return [position[0] + 1, position[1] - 14]
      case 'treeReducedText':
        return [position[0] - 2, position[1] - 8]
      case 'treeExternalText':
        return [position[0] + 1, position[1] - 2]
      case 'treeExternalInput':
        return [position[0] + 13, position[1] - 26]

      case 'treeAwardedInput':
        return [
          position[0] + (props.symbolType === 'client' ? 13 : 12),
          position[1] - 22,
        ]
      case 'input':
        return [
          position[0] + 21,
          position[1] - (props.symbolType.includes('External') ? 27 : 21),
        ]
      case 'button':
        return [position[0] + 10, position[1] + 10]
      case 'firstProceedingsText':
        return [position[0] + 5, position[1] + 210]
      case 'secondProceedingsText':
        return [position[0], position[1] + 12]
      case 'bankruptcyTitleText':
        return [position[0], position[1] + 6]
      case 'bankruptcyAddRemoveText':
        return [position[0] + 4, position[1] + 8]
      case 'tileText':
        return [
          position[0] + 3.5,
          position[1] - (props.symbolType === 'opposingParty' ? 3 : 2),
        ]
      case 'quantumText':
        return [position[0] + 8, position[1] + 100]
      case 'legalCostsText':
        return [position[0] + 8, position[1] + 8]
      default:
        return [0, 0]
    }
  }

  return position ? (
    <>
      <img
        id={`${props.targetId}-symbol`}
        src={getSymbolImg()[0]}
        alt={getSymbolImg()[1]}
        style={{
          width: getSymbolSize(),
          position: 'absolute',
          top: `${getSymbolPosition()[0]}px`,
          left: `${getSymbolPosition()[1]}px`,
          marginTop: '0px',
          marginBottom: '0px',
          marginLeft: '0px',
          marginRight: '0px',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {props.hoverMessageId && isHovered && (
        <HoverComponent
          hoverText={props.hoverMessageId}
          textAttribute={'hover-...'}
          id={`${props.targetId}-symbol`}
          style={{
            position: 'absolute',
            zIndex: 900,
            top: '0px',
            left: '0px',
          }}
        />
      )}
    </>
  ) : (
    <></>
  )
}

export default Symbol
