import { UserSettings } from '../models/userSettings'

export const formattedNumToString = (
  value: number,
  settings: UserSettings,
  noNegative?: boolean,
) => {
  const stringNum = value < 0 ? value.toString().substring(1) : value.toString()

  let thousandPart = stringNum.split('.')[0]
  let decimaPart = ''
  if (stringNum.includes('.')) {
    decimaPart = stringNum.split('.')[1]
  }

  if (thousandPart.length > 3) {
    thousandPart = thousandPart
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)!
      .map(function (x: string) {
        return x.split('').reverse().join('')
      })
      .reverse()
      .join(settings.formats.numberFormat.thousandSeparator)
  }

  if (decimaPart === '') {
    if (value < 0 && !noNegative) {
      return `-${thousandPart}`
    } else {
      return thousandPart
    }
  } else {
    if (value < 0 && !noNegative) {
      return `-${[thousandPart, decimaPart].join(
        settings.formats.numberFormat.decimalSeparator,
      )}`
    } else {
      return [thousandPart, decimaPart].join(
        settings.formats.numberFormat.decimalSeparator,
      )
    }
  }
}

export function getAbs(value: any) {
  if (value === undefined) {
    return undefined
  } else if (!isNaN(value)) {
    return Math.abs(value)
  } else {
    if (value[0] === '-') {
      value.replace('-', '')
    }
  }

  return value
}

export const formattedStringToNum = (value: string, settings: UserSettings) => {
  if (value === '') {
    return undefined
  }

  return parseFloat(
    value
      .replaceAll(settings.formats.numberFormat.thousandSeparator, '')
      .replaceAll(settings.formats.numberFormat.decimalSeparator, '.'),
  )
}

export const stringToNumForCalculations = (
  value: string,
  settings: UserSettings,
) => {
  let negative = false

  if (value === '') {
    return 0
  }

  if (value.startsWith('-')) {
    value = value.substring(1)
    negative = true
  }

  let newStringArray = value.split(
    settings.formats.numberFormat.decimalSeparator,
  )
  let formattedString =
    newStringArray.length > 1
      ? `${newStringArray[0].replaceAll(
          settings.formats.numberFormat.thousandSeparator,
          '',
        )}.${newStringArray[1]}`
      : `${newStringArray[0].replaceAll(
          settings.formats.numberFormat.thousandSeparator,
          '',
        )}`

  if (negative) {
    return -parseFloat(formattedString)
  } else {
    return parseFloat(formattedString)
  }
}

export const numDisplay = (num: any) => {
  if (parseFloat(num) === 0) {
    return '0'
  }
  if (parseFloat(num) <= 0.0001) {
    return '~ 0'
  }
  let newNum = num.toString()
  if (newNum === '') {
    return ''
  }

  if (newNum.includes('.') || newNum.includes(',')) {
    // If decimal

    newNum.includes('.') && (newNum = newNum.split('.'))
    newNum.includes(',') && (newNum = newNum.split(','))

    if (newNum[0] === '') {
      newNum[0] = '0'
    }
    let wholeNum = newNum[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x: any) {
        return x.split('').reverse().join('')
      })
      .reverse()
    wholeNum.join(' ')

    let n = wholeNum.join(' ') + ',' + newNum[1]
    return n
  } else {
    // Without decimal
    let wholeNum = newNum
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x: any) {
        return x.split('').reverse().join('')
      })
      .reverse()
    let n = wholeNum.join(' ')

    return n
  }
}

export const decimalLimit = (value: any, limit: number) => {
  value = value.toString()

  if (value.includes('.') || value.includes(',')) {
    let newNum = []
    value.includes('.') && (newNum = value.split('.'))
    value.includes(',') && (newNum = value.split(','))
    let newDec = newNum[1].substr(0, limit)
    return newNum[0] + ',' + newDec
  }
  return value
}

export const limitValue = (
  value: any,
  minValue?: number,
  maxValue?: number,
) => {
  const minCheck = minValue ? parseFloat(value) < minValue : false
  const maxCheck = maxValue ? parseFloat(value) > maxValue : false
  // minValue is quite lazily done since it never will be used, just a precaution
  if (value === '') {
    return ''
  }

  if (minCheck || maxCheck) {
    if (minCheck) {
      return minValue
    } else if (maxCheck) {
      return maxValue
    }

    return minValue
  }
  return value
}

export function allowedKeys(e: any) {
  return (
    e.key !== 'Backspace' &&
    e.key !== 'Delete' &&
    e.key !== 'ArrowLeft' &&
    e.key !== 'ArrowRight' &&
    e.key !== 'ArrowDown' &&
    e.key !== 'ArrowUp'
  )
}

export function forbiddenKeys(e: any) {
  return (
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowRight' ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowUp'
  )
}
export function forbiddenKeys2(e: any) {
  return (
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowRight' ||
    e.key === 'ArrowDown' ||
    e.key === 'ArrowUp' ||
    e.key === 'Shift' ||
    e.key === 'Control' ||
    e.key === 'Alt' ||
    e.key === 'Meta'
  )
}

export function regexForNumbers(e: any) {
  let regex = /d/
  return regex.test(e.key)
}

// Keep track on arrow
export const moveCursor = (e: any, indexTracker: number, id: string) => {
  forbiddenKeys2(e) && e.preventDefault()
  let tempIndexTracker = indexTracker
  let valueArray = e.target.value.split('')
  let start = tempIndexTracker
  let end = tempIndexTracker

  if (e.key === 'ArrowLeft') {
    tempIndexTracker =
      tempIndexTracker <= 0
        ? 0
        : valueArray[tempIndexTracker - 2] === ' '
        ? tempIndexTracker - 2
        : tempIndexTracker - 1
    start = tempIndexTracker
    end = tempIndexTracker
    if (e.shiftKey) {
      if (e.target.selectionDirection === 'none') {
        e.target.selectionDirection = 'left'
      }
      end = e.target.selectionEnd
    }
  }

  if (e.key === 'ArrowRight') {
    tempIndexTracker =
      tempIndexTracker + 1 > valueArray.length
        ? valueArray.length
        : valueArray[tempIndexTracker] === ' '
        ? tempIndexTracker + 2
        : tempIndexTracker + 1
    start = tempIndexTracker
    end = tempIndexTracker
    if (e.shiftKey) {
      start = e.target.selectionStart
    }
  }
  if (e.key === 'ArrowDown') {
    if (e.shiftKey) {
      start = 0
      end = e.target.selectionEnd
    } else {
      start = 0
      end = 0
    }
  }
  if (e.key === 'ArrowUp') {
    if (e.shiftKey) {
      start = e.target.selectionStart

      end = valueArray.length
    } else {
      start = valueArray.length
      end = valueArray.length
    }
  }
  if (
    e.key === 'Shift' ||
    e.key === 'Control' ||
    e.key === 'Alt' ||
    e.key === 'Meta'
  ) {
  } else {
    document.getElementById(id)!.focus()
    ;(document.getElementById(id) as HTMLInputElement).setSelectionRange(
      start,
      end,
    )
  }

  return tempIndexTracker
}

export const deleteFunction = (
  e: any,
  indexTracker: number,
  handleChange: (e: any, tempIndexTracker: number | undefined) => void,
) => {
  let valueArray = e.target.value.split('')
  let tempIndexTracker: number | undefined = indexTracker
  if (indexTracker === valueArray.length) {
    e.preventDefault()
    return
  }
  if (valueArray[indexTracker] === ' ') {
    valueArray.splice(indexTracker, 2)
    tempIndexTracker++
    if (valueArray.length < 4) {
      tempIndexTracker--
    } else if (indexTracker === 1) {
      tempIndexTracker--
    }
  } else {
    valueArray.splice(indexTracker, 1)
    tempIndexTracker = undefined
  }
  e.target.value = valueArray.join('')
  handleChange(e, tempIndexTracker)
}

export const backspaceFunction = (
  e: any,
  indexTracker: number,
  handleChange: (e: any, tempIndexTracker: number | undefined) => void,
) => {
  let valueArray = e.target.value.split('')
  let tempIndexTracker: number | undefined = indexTracker
  if (indexTracker === 0) {
    e.preventDefault()
    return
  } else if (valueArray[indexTracker - 1] === ' ') {
    valueArray.splice(indexTracker - 2, 2)
    tempIndexTracker -= 2
  } else {
    valueArray.splice(indexTracker - 1, 1)
    tempIndexTracker = undefined
  }
  e.target.value = valueArray.join('')
  handleChange(e, tempIndexTracker)
}

export const doubleComma = (e: any, prevValue: string) => {
  if (prevValue.includes(',')) {
    e.preventDefault()
  }
  return prevValue.includes(',')
}

export const handleComma = (
  e: any,
  indexTracker: number,
  handleChange: (e: any, tempIndexTracker: number | undefined) => void,
) => {
  e.preventDefault()
  let tempIndexTracker: number | undefined = indexTracker

  let mainPart = e.target.value.substr(0, tempIndexTracker)
  let decimalPart = e.target.value.substr(tempIndexTracker)
  let valueArray = []
  valueArray.push(mainPart)
  valueArray.push(',')

  if (decimalPart.length > 0) {
    decimalPart = parseFloat(decimalPart.replaceAll(' ', ''))
    valueArray.push(decimalPart)
  }

  e.target.value = valueArray.join('')

  if (tempIndexTracker < e.target.value.length) {
    tempIndexTracker++
  }
  handleChange(e, tempIndexTracker)
  /*   
  if (indexTracker === 0) {
    e.preventDefault()
    return
  } else if (valueArray[indexTracker - 1] === ' ') {
    valueArray.splice(indexTracker - 2, 2)
    tempIndexTracker -= 2
  } else {
    valueArray.splice(indexTracker - 1, 1)
    tempIndexTracker = undefined
  }
  e.target.value = valueArray.join('')
  */
}

export const selectionFunction = (
  e: any,
  handleChange: (e: any, tempIndexTracker: number | undefined) => void,
  selectionStart: number,
  selectionEnd: number,
) => {
  let valueArray = e.target.value.split('')
  let tempIndexTracker: number | undefined = e.target.selectionStart
  if (regexForNumbers(e)) {
    valueArray.splice(selectionStart, selectionEnd - selectionStart, e.key)
  } else if (!forbiddenKeys2(e)) {
    valueArray.splice(selectionStart, selectionEnd - selectionStart)
  } else {
    return
  }
  e.target.value = valueArray.join('')
  handleChange(e, tempIndexTracker)
}

export const cursorPlacement = (
  before: string,
  after: string,
  currentIndex: number,
  keyRef: any,
) => {
  let beforeArray = before.split('')
  let afterArray = after.split('')

  let finalIndex = currentIndex

  // If more than 2 characters are added/removed jump to end
  // (Nr is probably copy pasted or edited)
  if (
    afterArray.length > beforeArray.length + 2 ||
    beforeArray.length > afterArray.length + 2
  ) {
    finalIndex = afterArray.length
  } else if (beforeArray.length > afterArray.length) {
    //remove letters
    var spaces = 0

    for (let i = 0; i < currentIndex - 1; i++) {
      if (beforeArray[i] === ' ') {
        spaces--
      }
    }
    for (let i = 0; i < currentIndex - 1; i++) {
      if (afterArray[i] === ' ') {
        spaces++
      }
    }

    if (afterArray[currentIndex - 2 + spaces] === ' ') {
      spaces--
    }

    keyRef === 'Delete'
      ? (finalIndex = currentIndex + spaces)
      : (finalIndex = currentIndex - 1 + spaces)
  } else if (beforeArray.length < afterArray.length) {
    //add letters
    spaces = 0

    for (let i = 0; i < currentIndex; i++) {
      if (beforeArray[i] === ' ') {
        spaces--
      }
    }
    for (let i = 0; i < currentIndex; i++) {
      if (afterArray[i] === ' ') {
        spaces++
      }
    }
    let mainPart = []
    if (beforeArray.includes(',')) {
      let mainPartArray = before.split(',')
      mainPart = mainPartArray[0].split(' ')
    } else {
      mainPart = before.split(' ')
    }

    if (mainPart[0].length === 3 && currentIndex === 1) {
      spaces++
    }
    finalIndex = currentIndex + 1 + spaces
  }

  if (afterArray[finalIndex - 1] === ' ') {
    finalIndex--
  }
  if (
    afterArray.length < beforeArray.length &&
    afterArray.length === 1 &&
    afterArray[0] === '0'
  ) {
    finalIndex = 0
  }
  return finalIndex
}

export const inputClick = (
  selectedIndex: number,
  value: string | number | string[],
) => {
  let tempCursorIndex = selectedIndex
  let valueArray = (value as string).split('')

  if (valueArray[selectedIndex - 1] === ' ') {
    tempCursorIndex--
  }

  return tempCursorIndex
}

export const numToStringForCursor = (num: any) => {
  let newNum
  if (typeof num === 'string') {
    if (num.includes('-')) {
      let tempArray = num.split('')
      tempArray.shift()
      newNum = tempArray.join('')
    } else {
      newNum = num
    }
  } else {
    newNum = num.toString()
  }

  if (newNum === '') {
    return newNum
  }

  if (newNum.includes('.') || newNum.includes(',')) {
    // If decimal
    newNum.includes('.') && (newNum = newNum.split('.'))
    newNum.includes(',') && (newNum = newNum.split(','))

    if (newNum[0] === '') {
      newNum[0] = '0'
    }
    let wholeNum = newNum[0]
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x: any) {
        return x.split('').reverse().join('')
      })
      .reverse()
    wholeNum.join(' ')

    let n = wholeNum.join(' ') + ',' + newNum[1]

    return n
  } else {
    // Without decimal

    let wholeNum = newNum
      .split('')
      .reverse()
      .join('')
      .match(/.{1,3}/g)
      .map(function (x: any) {
        return x.split('').reverse().join('')
      })
      .reverse()
    let n = wholeNum.join(' ')

    return n
  }
}

export const positiveAmountAsString = (tempValue: string | number) => {
  if (tempValue === undefined || tempValue === '') {
    tempValue = 0
  } else if (typeof tempValue === 'number') {
    tempValue = Math.abs(tempValue)
  } else {
    let tempArray = tempValue.split('')
    if (tempArray[0] === '-') {
      tempArray.shift()
    }
    tempValue = tempArray.join('')
    tempValue = tempValue.replace(',', '.')
  }

  return tempValue
}

export const negativeAmountAsString = (tempValue: string | number) => {
  if (tempValue === undefined || tempValue === '') {
    tempValue = 0
  } else if (typeof tempValue === 'number') {
    tempValue = -Math.abs(tempValue)
  } else {
    let tempArray = tempValue.split('')
    tempArray.unshift('-')
    tempValue = tempArray.join('')
    tempValue = tempValue.replace(',', '.')
  }

  return tempValue
}
