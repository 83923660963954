import React, { useEffect, useRef, useState } from 'react'
import { TreeClaim } from '../../../../../../../../../models/treeModels/treeClaim'
import { TreeEventClass } from '../../../../../../../../../models/treeModels/treeEvent'
import { TreeNodeClass } from '../../../../../../../../../models/treeModels/treeNode'
import {
  checkIfNodeHasAtLeastOneAwardedAmount,
  checkIfNodeHasAtLeastOneEventTitle,
  checkIfNodeHasAtLeastOneInterestMenuOn,
  checkIfNodeHasAtLeastOneOutOfCourtAmount,
  checkIfNodeHasAtLeastOneReducedAmount,
} from '../../../../../../../../../services/treeFunctions/treeBasicFunctions'
import {
  EventId,
  NodeId,
  NodeMode,
  RootNodeType,
} from '../../../../../../../../../models/treeModels/treeTypes'
import TreeNodeTitleComponentForReport from './TreeNodeTitleComponentForReport'
import TreeEventMinimisedForReport from './TreeEventMinimisedForReport'
import Draggable from 'react-draggable'
import { DefaultSnapshotState } from '../../../../../../../../../models/generalTypes'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'

type Props = {
  nodeDetails: TreeNodeClass
  events: { [eventId: EventId]: TreeEventClass }
  nodeId: NodeId
  zoomLevel: number
  treeIndex: number
  root: RootNodeType
  mainTree: NodeId[]
  differenceFromTop: number
  differenceFromLeft: number
  scenarioSnapshot: DefaultSnapshotState
  nodesUnderZeroProbability: NodeId[]
  reportWhitelabel?: ReportWhitelabel
}

export default function TreeNodeForReport(props: Props) {
  const nodeRef = useRef<HTMLDivElement>(null)

  const treeDetails = (
    props.scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).treeDetails
  const [nodeHasAtLeastOneEventTitle, setNodeHasAtLeastOneEventTitle] =
    useState(false)

  const [nodeHasAtLeastOneAwardedAmount, setNodeHasAtLeastOneAwardedAmount] =
    useState(false)
  const [
    nodeHasAtLeastOneOutOfCourtAmount,
    setNodeHasAtLeastOneOutOfCourtAmount,
  ] = useState(false)
  const [nodeHasAtLeastOneInterestMenuOn, setNodeHasAtLeastOneInterestMenuOn] =
    useState(false)

  const [nodeHasAtLeastOneReducedAmount, setNodeHasAtLeastOneReducedAmount] =
    useState(false)

  useEffect(() => {
    setNodeHasAtLeastOneEventTitle(
      checkIfNodeHasAtLeastOneEventTitle(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneAwardedAmount(
      checkIfNodeHasAtLeastOneAwardedAmount(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneOutOfCourtAmount(
      checkIfNodeHasAtLeastOneOutOfCourtAmount(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneInterestMenuOn(
      checkIfNodeHasAtLeastOneInterestMenuOn(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneReducedAmount(
      checkIfNodeHasAtLeastOneReducedAmount(props.nodeId, treeDetails),
    )

    // eslint-disable-next-line
  }, [treeDetails.events])

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        position={{
          x:
            props.nodeDetails.properties.position[0] - props.differenceFromLeft,
          y: props.nodeDetails.properties.position[1] - props.differenceFromTop,
        }}
        scale={props.zoomLevel}
        bounds={{
          left: 0,
          top: 0,
        }}
      >
        <div
          className={`treeNode minimised`}
          id={`treeNode-${props.treeIndex}_${props.nodeId}`}
          ref={nodeRef}
          style={
            props.nodesUnderZeroProbability.includes(props.nodeId)
              ? { opacity: 0.2 }
              : { opacity: 1 }
          }
        >
          <TreeNodeTitleComponentForReport
            nodeId={props.nodeId}
            root={props.root}
            events={props.events}
            treeIndex={props.treeIndex}
            nodeDetails={props.nodeDetails}
            nodeMode={NodeMode.minimised}
            zoomLevel={props.zoomLevel}
            scenarioSnapshot={props.scenarioSnapshot}
            treeDetails={treeDetails}
            reportWhitelabel={props.reportWhitelabel}
          >
            {Object.keys(props.events)
              .filter(
                (eventId) =>
                  props.events[eventId as EventId].nodeOfEventId ===
                  props.nodeId,
              )
              .map((eventId, index) => (
                <React.Fragment key={`treeEvent-${eventId}`}>
                  <div
                    className="treeEventMinimisedShadow"
                    style={{ left: index * 157 }}
                  ></div>
                  <TreeEventMinimisedForReport
                    eventDetails={props.events[eventId as EventId].eventDetails}
                    key={eventId}
                    nodeId={props.nodeId}
                    eventId={eventId as EventId}
                    eventIndex={props.events[eventId as EventId].eventIndex}
                    treeIndex={props.treeIndex}
                    nodeHasAtLeastOneAwardedAmount={
                      nodeHasAtLeastOneAwardedAmount
                    }
                    nodeHasAtLeastOneOutOfCourtAmount={
                      nodeHasAtLeastOneOutOfCourtAmount
                    }
                    nodeHasAtLeastOneInterestMenuOn={
                      nodeHasAtLeastOneInterestMenuOn
                    }
                    nodeHasAtLeastOneReducedAmount={
                      nodeHasAtLeastOneReducedAmount
                    }
                    nodeHasAtLeastOneEventTitle={nodeHasAtLeastOneEventTitle}
                    scenarioSnapshot={props.scenarioSnapshot}
                    reportWhitelabel={props.reportWhitelabel}
                  />

                  <div
                    className={'treeAddNodeDotContainer minimised'}
                    id={`treeAddNodeDotContainer-${props.treeIndex}_${
                      props.nodeId
                    }!${props.events[eventId as EventId].eventIndex}`}
                    style={{ top: '10px', backgroundColor: 'transparent' }}
                  >
                    <div
                      className={`treeAddNodeDot minimised`}
                      id={`treeAddNodeDot-${props.treeIndex}_${props.nodeId}!${
                        props.events[eventId as EventId].eventIndex
                      }`}
                    ></div>
                  </div>
                </React.Fragment>
              ))}
          </TreeNodeTitleComponentForReport>
        </div>
      </Draggable>
    </>
  )
}
