import React, { useEffect, useState } from 'react'
import { TreeClaim } from '../../../../../../models/treeModels/treeClaim'
import { NodeId, NodeMode } from '../../../../../../models/treeModels/treeTypes'
/* import { deepCloneObject } from '../../../../../../services/commonFunctions'
import { changeNodesPositionsMinMax } from '../../../../../../services/treeFunctions/treePositioningFunctions'
 */
import {
  findBottomMostNodeOfMainTree,
  findHeightOfTitle,
  findLeftMostNodeOfMainTree,
  findRightMostNodeOfMainTree,
  findTopMostNodeOfMainTree,
  heightOfNode,
  widthOfNode,
} from '../../../../../../services/treeFunctions/treeXandYFunctions'
import TreeCanvasForImage from './TreeCanvasForImage'
import { changeTextAreaHeight } from '../../../../../../services/treeFunctions/treeBasicFunctions'
// import { useXarrow } from 'react-xarrows'

type Props = {
  treeClaim: TreeClaim
  treeIndex: number
  mainTree: NodeId[]
  setRenderLines: (value: boolean) => void
  nodesUnderZeroProbability: NodeId[]
}

export default function TreePreviewForImage(props: Props) {
  const [differenceFromTop, setDifferenceFromTop] = useState<
    undefined | number
  >()
  const [differenceFromLeft, setDifferenceFromLeft] = useState<
    undefined | number
  >()
  /* const [tempTreeClaim, setTempTreeClaim] = useState(
    deepCloneObject(props.treeClaim),
  ) */
  const zoomLevel = 4
  const [canvasHeight, setCanvasHeight] = useState(400)
  const [canvasWidth, setCanvasWidth] = useState(700)
  // const updateXarrow = useXarrow()

  /* useEffect(() => {
    if (props.treeClaim.nodeMode === NodeMode.maximised) {
      let localTempTreeClaim = deepCloneObject(props.treeClaim)

      localTempTreeClaim.treeDetails = changeNodesPositionsMinMax(
        NodeMode.minimised,
        localTempTreeClaim.treeDetails,
        props.treeIndex,
      )
      setTempTreeClaim(localTempTreeClaim)
    }
    // eslint-disable-next-line
  }, []) */

  useEffect(() => {
    // eslint-disable-next-line

    const topNodeOfMainTree = findTopMostNodeOfMainTree(
      props.treeClaim.treeDetails,
      props.mainTree,
      props.treeIndex,
      props.treeClaim.nodeMode,
    )

    const leftNodeOfMainTree = findLeftMostNodeOfMainTree(
      props.treeClaim.treeDetails,
      props.mainTree,
    )
    const bottomNodeOfMainTree = findBottomMostNodeOfMainTree(
      props.treeClaim.treeDetails,
      props.mainTree,
      props.treeClaim.nodeMode,
      props.treeIndex,
    )

    const rightNodeOfMainTree = findRightMostNodeOfMainTree(
      props.treeClaim.treeDetails,
      props.mainTree,
      props.treeClaim.nodeMode,
    )

    const tempCanvasWidth =
      props.treeClaim.treeDetails.nodes[rightNodeOfMainTree as NodeId]
        .properties.position[0] +
      widthOfNode(
        rightNodeOfMainTree as NodeId,
        props.treeClaim.nodeMode,
        props.treeClaim.treeDetails,
      ) -
      props.treeClaim.treeDetails.nodes[leftNodeOfMainTree as NodeId].properties
        .position[0] +
      100

    let tempCanvasHeight =
      props.treeClaim.treeDetails.nodes[bottomNodeOfMainTree as NodeId]
        .properties.position[1] +
      heightOfNode(
        props.treeClaim.nodeMode,
        bottomNodeOfMainTree as NodeId,
        props.treeIndex,
        props.treeClaim.treeDetails,
        false,
      ) -
      props.treeClaim.treeDetails.nodes[topNodeOfMainTree as NodeId].properties
        .position[1] +
      findHeightOfTitle(
        topNodeOfMainTree as NodeId,
        props.treeIndex,
        props.treeClaim.nodeMode,
      )

    setCanvasWidth(tempCanvasWidth)
    if (props.treeClaim.nodeMode === NodeMode.maximised) {
      tempCanvasHeight += 80
    }
    setCanvasHeight(tempCanvasHeight)

    setDifferenceFromTop(
      props.treeClaim.treeDetails.nodes[topNodeOfMainTree as NodeId].properties
        .position[1] -
        findHeightOfTitle(
          topNodeOfMainTree as NodeId,
          props.treeIndex,
          props.treeClaim.nodeMode,
        ),
    )

    setDifferenceFromLeft(
      props.treeClaim.treeDetails.nodes[leftNodeOfMainTree as NodeId].properties
        .position[0],
    )

    // eslint-disable-next-line
  }, [props.treeClaim, props.treeClaim])

  useEffect(() => {
    //This useEffect fixes the textarea height when changing from tree to tree, since rerendering of react doesn't work properly

    setTimeout(() => {
      const tx = document.getElementsByClassName('treeNodeTitleTextarea')
      for (let i = 0; i < tx.length; i++) {
        if (tx[i]) {
          changeTextAreaHeight(tx[i])
        }
      }
    }, 100)

    const tx = document.getElementsByClassName('treeNodeTitleTextarea')
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute(
        'style',
        `height: ${tx[i].scrollHeight}px;overflow-y:hidden;`,
      )
      tx[i].addEventListener('input', () => changeTextAreaHeight(tx[i]), false)
    }

    setTimeout(() => {
      props.setRenderLines(true)
      //updateXarrow()
    }, 300)

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div
        className="treePreviewForDownload"
        id={`treePreviewForDownload-${props.treeIndex}`}
        style={{
          height: (canvasHeight + 100) * zoomLevel,
          width: (canvasWidth + 50) * zoomLevel,
          left: 0,
          top: 0,
          backgroundColor: 'white',
        }}
      >
        <div className="treeContainer">
          <TreeCanvasForImage
            treeIndex={props.treeIndex}
            zoomLevel={zoomLevel}
            mainTree={props.mainTree}
            differenceFromTop={differenceFromTop ?? 0}
            differenceFromLeft={differenceFromLeft ?? 0}
            canvasHeight={canvasHeight}
            canvasWidth={canvasWidth}
            canvasType="downloadImage"
            maximised={props.treeClaim.nodeMode === NodeMode.maximised}
            nodesUnderZeroProbability={props.nodesUnderZeroProbability}
          />
        </div>
      </div>
    </>
  )
}
