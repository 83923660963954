import { useRecoilValue } from 'recoil'
import {
  LegalCostInputType,
  LegalCostsParty,
  LegalCostsProceedings,
  LegalCostsSwitchButtonType,
} from '../../../../models/enums'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { useEffect, useState } from 'react'

type Props = {
  party: LegalCostsParty
  proceedings: LegalCostsProceedings
  type: LegalCostInputType.recoverableCosts | LegalCostInputType.incurredCosts
  value: LegalCostsSwitchButtonType
  handleChangeLegalCostValue: (
    e: any,
    party: LegalCostsParty,
    proceedings: LegalCostsProceedings,
    legalCostInputType: LegalCostInputType,
    value: number | LegalCostsSwitchButtonType,
  ) => void
}

export default function LegalCostsSwitchButton(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [nextValue, setNextValue] = useState(props.value)

  useEffect(() => {
    const tempNextValue =
      props.value === LegalCostsSwitchButtonType.currency
        ? LegalCostsSwitchButtonType.percentage
        : LegalCostsSwitchButtonType.currency
    setNextValue(tempNextValue)
  }, [props.value])

  return (
    <div
      className="legalCostsSwitchButtonContainer"
      id={`legalCostsSwitchButtonContainer-${props.party}-${props.proceedings}-${props.type}`}
      onClick={(e: any) => {
        props.handleChangeLegalCostValue(
          e,
          props.party,
          props.proceedings,
          props.type,
          nextValue,
        )
        setTimeout(() => {
          let inputElement = document.getElementById(
            `${props.type}-${props.party}-${props.proceedings}`,
          )
          if (inputElement) {
            inputElement.focus()
          }
        }, 40)
      }}
    >
      <div
        className={
          props.value === LegalCostsSwitchButtonType.currency
            ? 'legalCostsSwitchButton selected'
            : 'legalCostsSwitchButton'
        }
        id={`legalCostsSwitchButtonCurrencyContainer-${props.party}-${props.proceedings}-${props.type}`}
      >
        <p
          className="legalCostsSwitchButtonText currency"
          id={`legalCostsSwitchButtonCurrency-${props.party}-${props.proceedings}-${props.type}`}
        >
          {scenarioSnapshot.currentSnapshot.currency}
        </p>
      </div>
      <div
        className={
          props.value === LegalCostsSwitchButtonType.percentage
            ? 'legalCostsSwitchButton selected'
            : 'legalCostsSwitchButton'
        }
        id={`legalCostsSwitchButtonPercentageContainer-${props.party}-${props.proceedings}-${props.type}`}
      >
        <p
          className="legalCostsSwitchButtonText"
          id={`legalCostsSwitchButtonPercentage-${props.party}-${props.proceedings}-${props.type}`}
        >
          %
        </p>
      </div>
    </div>
  )
}
