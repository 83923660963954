import { View, StyleSheet, Text } from '@react-pdf/renderer'
import { getText } from '../../../../../../../../../services/textFunctions'
import {
  LegalCostsParty,
  LegalCostsProceedings,
} from '../../../../../../../../../models/enums'
import { User } from '../../../../../../../../../models/user'
import { ScenarioSnapshot } from '../../../../../../../../../models/scenarioSnapshot'
import { stringAndRoundBigNumbers } from '../../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  findTotalClaimedAmount,
  findTotalCounterClaimedAmount,
} from '../../../../../../../../../services/disputedAmountFunctions'
import {
  getStyleFromWhitelabel,
  snapshotHasLegalCosts,
} from '../../../../../../../../../services/reportFunctions'
import LegalCostsExecutiveSummary3Document from './LegalCostsExecutiveSummary3Document'
import {
  ReportLegalCostsType,
  ReportWhitelabel,
} from '../../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../../models/reportModels/reportEnums'

/* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */
type Props = {
  party: LegalCostsParty
  user: User
  currentSnapshot: ScenarioSnapshot
  legalCostsDescriptions: ReportLegalCostsType
  hasSecondTrial: boolean
  reportWhitelabel?: ReportWhitelabel
}

export default function ClaimsAndLegalCostsDocument(props: Props) {
  const styles = StyleSheet.create({
    partyClaimsAndLegalCostsComponent: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '3px',
      marginRight: '35px',
    },
    partyNameContainer: {
      marginBottom: '1px',
      maxWidth: '230px',
    },
    partyName: {
      fontSize: '11px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',

      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    claimsAndLegalCostsContainer: {
      border: '0.8px',
      borderColor: '#8b8b8b',
      borderRadius: '3px',
      width: '225px',
      padding: '2px 5px 4px 5px',
    },
    claimedAmountContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    claimedAmountText: {
      color: '#8b8b8b',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.light,
        'noRoboto',
        true,
      ).marginTop,
    },
    claimedAmountValue: {
      fontFamily: 'Roboto-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      fontSize: '9px',
    },
    sup: {
      fontSize: '4px',
      position: 'absolute',
      top: '1px',
      right: '0px',
      fontFamily: 'Roboto-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    legalCostsContainer: {},
  })

  const partiesFormat = [
    props.currentSnapshot.partyFormatOwn,
    props.currentSnapshot.partyFormatOther,
  ]

  const marginStyle =
    props.party === LegalCostsParty.clientLegalCosts
      ? { marginRight: '35px' }
      : { marginRight: '0px' }

  return (
    <>
      <View
        style={{ ...styles.partyClaimsAndLegalCostsComponent, ...marginStyle }}
      >
        <View style={styles.partyNameContainer}>
          <Text style={styles.partyName}>
            {props.party === LegalCostsParty.clientLegalCosts
              ? getText(`title-223`, props.user.settings, partiesFormat)
              : getText(`title-224`, props.user.settings, partiesFormat)}
          </Text>
        </View>
        <View style={styles.claimsAndLegalCostsContainer}>
          <View style={styles.claimedAmountContainer}>
            <Text style={styles.claimedAmountText}>
              {getText('title-273', props.user.settings)}
            </Text>
            <Text style={styles.claimedAmountValue}>
              {stringAndRoundBigNumbers(
                props.party === LegalCostsParty.clientLegalCosts
                  ? Math.abs(findTotalClaimedAmount(props.currentSnapshot))
                  : Math.abs(
                      findTotalCounterClaimedAmount(props.currentSnapshot),
                    ),
                props.user.settings,
                false,
                0,
              )}{' '}
              {props.currentSnapshot.currency}
            </Text>
            <Text style={styles.sup}>1</Text>
          </View>
          {snapshotHasLegalCosts(props.currentSnapshot) && (
            <>
              <LegalCostsExecutiveSummary3Document
                party={props.party}
                user={props.user}
                currentSnapshot={props.currentSnapshot}
                proceedings={LegalCostsProceedings.firstProceedings}
                legalCostsDescriptions={props.legalCostsDescriptions}
                reportWhitelabel={props.reportWhitelabel}
              />
              {props.hasSecondTrial && (
                <LegalCostsExecutiveSummary3Document
                  party={props.party}
                  user={props.user}
                  currentSnapshot={props.currentSnapshot}
                  proceedings={LegalCostsProceedings.additionalProceedings}
                  legalCostsDescriptions={props.legalCostsDescriptions}
                  reportWhitelabel={props.reportWhitelabel}
                />
              )}
            </>
          )}
        </View>
      </View>
    </>
  )
}
