import { atom } from 'recoil'
import { ReportStateType } from '../models/reportModels/reportGeneralTypes'

export const savedReportState = atom<{
  saved: boolean
  savedReportState: ReportStateType
}>({
  key: 'savedReportState',
  default: { saved: true, savedReportState: {} as ReportStateType },
})
