import { useRecoilValue } from 'recoil'
// import handleImg from '../../../../../../../../resources/images/271-selectionHandle.svg'
import addSelectionImg from '../../../../../../../../resources/images/144-plus.svg'
import handleDeleteImg from '../../../../../../../../resources/images/234-delete-segment.svg'
import { userState } from '../../../../../../../../states/UserState'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
type Props = {
  referenceNode: React.RefObject<HTMLDivElement>
  className: string
  height: number
  handlesXpercentages: number[]
  limitAmount: number[]
  statsticalModel: boolean
  handleIndex: number
  tree?: boolean
  negativeGraph: boolean
  showAddSelection: boolean
  setShowAddSelection: (showAddSelection: boolean) => void
  showDeleteButton: boolean
  setShowDeleteButton: (showDeleteButton: boolean) => void
  setDraggingFromAddButton: (draggingFromAddButton: boolean) => void
  draggingFromAddButton: boolean
  addNewSelectionArea: (place: 'left' | 'right') => void
  deleteHandleLine: (handleIndex: number) => void
  topLeftOffset: number[]
  doubleLimitValues: boolean
  hoverArea: undefined | number
  setHoverArea: (hoverArea: undefined | number) => void
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
}

export default function HandleComponent(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const allowAddSelection =
    !props.tree &&
    props.handlesXpercentages.length >= 2 &&
    props.handlesXpercentages.length < 9 &&
    ((props.handleIndex === 0 &&
      props.handlesXpercentages[props.handleIndex] > 0.11) ||
      (props.handleIndex === props.handlesXpercentages.length - 1 &&
        props.handlesXpercentages[props.handleIndex] < 0.89))

  const limitForSmall = props.tree ? 0.85 : 0.94

  return (
    <>
      {props.limitAmount && (
        <>
          <p
            className={`amountText ${props.tree ? 'tree' : ''} ${
              props.negativeGraph ? 'negativeGraph' : ''
            } ${
              props.handlesXpercentages[props.handleIndex] > limitForSmall &&
              (stringAndRoundBigNumbers(
                props.limitAmount.length > 0 ? props.limitAmount[0] : 0,
                user.settings,
                resultsSettings.settings.roundedResults && !props.tree,
                0,
                false,
              ).length > 7 ||
                props.tree)
                ? 'small'
                : ''
            } ${
              props.doubleLimitValues &&
              props.className.includes('handleMiddle')
                ? 'doubleLeft'
                : ''
            } ${
              (props.handleIndex === 0 &&
                props.hoverArea === props.handleIndex) ||
              props.hoverArea === props.handleIndex - 1
                ? 'highlight'
                : props.hoverArea !== undefined
                ? 'notHighlight'
                : ''
            } robotoNumbers`}
            id={`amountText-${props.handleIndex}${
              props.pdfPreview ? '-preview' : ''
            }`}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
            )}
          >
            {stringAndRoundBigNumbers(
              props.limitAmount.length > 0 ? props.limitAmount[0] : 0,
              user.settings,
              resultsSettings.settings.roundedResults && !props.tree,
              0,
              false,
            )}
            {props.statsticalModel ? '*' : ''}{' '}
            {scenarioSnapshot.currentSnapshot.currency}
          </p>
          {props.doubleLimitValues &&
            props.className.includes('handleMiddle') && (
              <p
                className={`amountText ${props.tree ? 'tree' : ''} ${
                  props.negativeGraph ? 'negativeGraph' : ''
                } ${
                  props.handlesXpercentages[props.handleIndex] >
                    limitForSmall &&
                  (stringAndRoundBigNumbers(
                    props.limitAmount.length > 0 ? props.limitAmount[1] : 0,
                    user.settings,
                    resultsSettings.settings.roundedResults,
                    0,
                    false,
                  ).length > 7 ||
                    props.tree)
                    ? 'small'
                    : ''
                } doubleRight ${
                  props.hoverArea === props.handleIndex
                    ? 'highlight'
                    : props.hoverArea !== undefined
                    ? 'notHighlight'
                    : ''
                } robotoNumbers`}
                id={`amountText-${props.handleIndex}-right${
                  props.pdfPreview ? '-preview' : ''
                }`}
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.regular,
                  'roboto',
                )}
              >
                {stringAndRoundBigNumbers(
                  props.limitAmount.length > 0 ? props.limitAmount[1] : 0,
                  user.settings,
                  resultsSettings.settings.roundedResults,
                  0,
                  false,
                )}
                {props.statsticalModel ? '*' : ''}{' '}
                {scenarioSnapshot.currentSnapshot.currency}
              </p>
            )}
        </>
      )}

      {allowAddSelection && (
        <div
          className={`${props.className}-button addSelectionAreaContainer`}
          id={`addSelectionAreaContainer-${props.handleIndex}`}
          style={{
            height: props.height,
          }}
          onMouseEnter={() => {
            if (!props.draggingFromAddButton) props.setShowAddSelection(true)
          }}
          onMouseLeave={() => {
            props.setShowAddSelection(false)
            props.setShowDeleteButton(false)
          }}
          onMouseDown={() => props.setDraggingFromAddButton(true)}
          onDrag={() => props.setDraggingFromAddButton(true)}
        >
          {props.showAddSelection && (
            <img
              src={addSelectionImg}
              className={`${props.className}-button addSelectionImg ${
                props.tree ? 'tree' : ''
              }`}
              alt="addSelectionImg"
              id={`addSelectionImg-${props.handleIndex}`}
              onMouseEnter={() => props.setShowAddSelection(true)}
              onMouseDown={() => props.setDraggingFromAddButton(true)}
              onDrag={() => props.setDraggingFromAddButton(true)}
              onClick={() => {
                props.setShowAddSelection(false)
                let place: 'left' | 'right' =
                  props.handleIndex === 0 ? 'left' : 'right'
                props.addNewSelectionArea(place)
              }}
            />
          )}
        </div>
      )}
      {['left', 'right'].map((type, index) => (
        <div
          key={`handlePlaceholder-${props.handleIndex}-${index}`}
          ref={props.referenceNode}
          className={`${props.className} handlePlaceholder ${
            props.tree ? 'tree' : ''
          } ${type}`}
          style={{
            height: props.height,
          }}
          id={`handlePlaceholder-${props.handleIndex}-${index}`}
          onMouseEnter={() => {
            if (props.handlesXpercentages.length > 2)
              props.setShowDeleteButton(true)
            if (!props.draggingFromAddButton) props.setShowAddSelection(true)
            if (type === 'left') {
              if (props.handleIndex !== 0) {
                props.setHoverArea(props.handleIndex - 1)
              }
            } else {
              if (props.handleIndex !== props.handlesXpercentages.length - 1) {
                props.setHoverArea(props.handleIndex)
              }
            }
          }}
          onMouseLeave={(e: any) => {
            props.setShowAddSelection(false)
            if (
              (e.relatedTarget.className &&
                e.relatedTarget.className.length > 0 &&
                !e.relatedTarget.className.includes('handleDelete')) ||
              typeof e.relatedTarget.className !== 'string'
            ) {
              props.setShowDeleteButton(false)
            }
          }}
        />
      ))}
      <div
        className={`${props.className} handleLine`}
        style={{
          height: props.height,
        }}
        onMouseEnter={() => {
          if (props.handlesXpercentages.length > 2)
            props.setShowDeleteButton(true)
          if (!props.draggingFromAddButton) props.setShowAddSelection(true)
        }}
        onMouseLeave={(e: any) => {
          props.setShowAddSelection(false)
          if (
            (e.relatedTarget.className &&
              e.relatedTarget.className.length > 0 &&
              !e.relatedTarget.className.includes('handleDelete')) ||
            typeof e.relatedTarget.className !== 'string'
          ) {
            props.setShowDeleteButton(false)
          }
        }}
      />
      {/* <img
        src={handleImg}
        className={`${props.className} handleImage ${props.tree ? 'tree' : ''}`}
        alt="handle"
        onMouseLeave={() => {
          props.setShowDeleteButton(false)
        }}
      /> */}
      {props.showDeleteButton && (
        <div
          className="handleDeleteButton"
          onMouseDown={() => props.deleteHandleLine(props.handleIndex)}
          id={`handleDeleteButton-${props.handleIndex}`}
        >
          <img
            src={handleDeleteImg}
            className={`${props.className} handleDeleteImage ${
              props.tree ? 'tree' : ''
            }`}
            alt="handleDelete"
            onMouseDown={() => props.deleteHandleLine(props.handleIndex)}
          />
        </div>
      )}
    </>
  )
}
