import { useEffect, useState } from 'react'

type Props = {
  speed: number //milliseconds of loop
}

export function AnimationDots(props: Props) {
  const [dot, setDot] = useState(1)

  useEffect(() => {
    const timer = setInterval(() => {
      // Increment the current image index and loop back to the first image when reaching the end
      let tempDot = dot + 1 === 4 ? 1 : dot + 1
      setDot(tempDot)
    }, props.speed)

    return () => {
      clearInterval(timer)
    }
    // eslint-disable-next-line
  }, [dot])

  return (
    <>
      <span>.</span>
      <span style={dot < 2 ? { visibility: 'hidden' } : undefined}>.</span>
      <span style={dot < 3 ? { visibility: 'hidden' } : undefined}>.</span>
    </>
  )
}
