import React from 'react'
import { UserForAccess } from '../../../models/generalTypes'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import UserThumbnailHover from './UserThumbnailHover'
import UserThumbnail from '../../../Components/userThumbnail/UserThumbnail'
import sharedWithImg from '../../../resources/images/135-sharedUsers.svg'
import addUserButtonImg from '../../../resources/images/166-addUserButtonImg.svg'
import { getText } from '../../../services/textFunctions'

type Props = {
  onContainerClick: () => void
  onImgMouseEnter: () => void
  onImgMouseLeave: () => void
  onTextMouseEnter: () => void
  onTextMouseLeave: () => void
  usersListWhenNoScenarios: UserForAccess[] | undefined
  usersList?: UserForAccess[]
  showUsersFromImg: boolean
  showUsersFromText: boolean
}

export default function ShareCaseButtonMyScenarios(props: Props) {
  const user = useRecoilValue(userState)

  const usersList = props.usersListWhenNoScenarios
    ? props.usersListWhenNoScenarios
    : props.usersList
    ? props.usersList
    : []

  const onlyUser =
    usersList.length === 1 && usersList[0].username === user.email

  return (
    <div
      id="sharedWithContainer"
      className="sharedWithContainer"
      onClick={props.onContainerClick}
    >
      <div
        //Image div with users hover
        className="sharedWithImgContainer inMenu3"
        onMouseEnter={props.onImgMouseEnter}
        onMouseLeave={props.onImgMouseLeave}
      >
        <img
          src={sharedWithImg}
          alt="sharedWithImg"
          className="shaerdWithImg"
        />
        {onlyUser ? null : props.showUsersFromImg ? (
          <UserThumbnailHover usersList={usersList} user={user} />
        ) : null}
      </div>
      <div
        //Text div with users hover
        className="sharedWithTextContainer inMenu3"
        onMouseEnter={props.onTextMouseEnter}
        onMouseLeave={props.onTextMouseLeave}
      >
        {onlyUser ? (
          <p
            className="sharedWithText"
            id="myScenariosShareCaseText"
            data-textattribute="button-78"
          >
            {getText('button-78', user.settings)}
          </p>
        ) : (
          <>
            <p
              className="sharedWithText"
              id="myScenarioSharedWithText"
              data-textattribute="button-79"
            >
              {getText('button-79', user.settings)}
            </p>
            {props.showUsersFromText ? (
              <UserThumbnailHover usersList={usersList} user={user} />
            ) : null}
          </>
        )}
        {onlyUser ? null : props.showUsersFromText ? (
          <UserThumbnailHover usersList={usersList} user={user} />
        ) : null}
      </div>
      <div
        className="sharedWithThumbnailsContainer inMenu3"
        //thumbnails div
      >
        {usersList
          .filter((userObject) => userObject.username !== user.email)
          .map((userObject: UserForAccess, index: number) => {
            return index < 5 ? (
              <div
                className="userThumbnailObjectContainer"
                key={`userThumbnailObjectContainer${index}`}
              >
                <UserThumbnail
                  firstName={userObject.first_name}
                  lastName={userObject.last_name}
                  users={usersList.filter(
                    (userObject) => userObject.username !== user.email,
                  )}
                />
              </div>
            ) : index === 5 &&
              usersList.filter(
                (userObject) => userObject.username !== user.email,
              ).length > 6 ? (
              <div
                className="userThumbnailObjectContainer inMenu"
                key={`userThumbnailObjectContainer${index}`}
              >
                <UserThumbnail
                  firstName=""
                  lastName=""
                  users={usersList.filter(
                    (userObject) => userObject.username !== user.email,
                  )}
                  empty
                />
              </div>
            ) : index === 5 ? (
              <div
                className="userThumbnailObjectContainer inMenu"
                key={`userThumbnailObjectContainer${index}`}
              >
                <UserThumbnail
                  firstName={userObject.first_name}
                  lastName={userObject.last_name}
                  users={usersList.filter(
                    (userObject) => userObject.username !== user.email,
                  )}
                />
              </div>
            ) : null
          })}
        <div className="addUserButton">
          <img
            src={addUserButtonImg}
            alt="addUserButtonImg"
            className="addUserButtonImg"
          />
        </div>
      </div>
    </div>
  )
}
