import React, { useEffect, useState } from 'react'
import './SwitchButton.css'
import { useRecoilState } from 'recoil'
import { recoveryModeState } from '../../../states/RecoveryModeState'
import { viewerState } from '../../../states/ViewerState'

type Props = {
  width: string
  label1: string
  label2: string
  label1TextAttribute: string
  label2TextAttribute: string
  onClick?: (num: 1 | 2) => void
  defaultState: 1 | 2
  id: string
  id1: string
  id2: string
  marginLabel1?: string
  marginLabel2?: string
  backgroundColor1?: string
  backgroundColor2?: string
  color1?: string
  color2?: string
  inTree?: boolean
  disabled?: boolean
  className?: string
}
export default function SwitchButton(props: Props) {
  const [switchState, setSwitchState] = useState(props.defaultState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)

  useEffect(() => {
    setSwitchState(props.defaultState)
  }, [props.defaultState])

  const onClickDisabled = () => {
    if (props.disabled) {
      if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
      }
    }
  }

  return (
    <>
      <div
        className={`switchContainer ${props.className}`}
        style={{ width: props.width }}
        onClick={
          props.onClick && !props.disabled
            ? () => {
                if (switchState === 1) {
                  props.onClick!(2)
                } else {
                  props.onClick!(1)
                }
              }
            : props.disabled
            ? onClickDisabled
            : undefined
        }
        id={props.id}
      >
        <div
          className={`switchMainButton ${
            switchState === 2 ? 'switchState2' : undefined
          }`}
          style={
            switchState === 1
              ? { backgroundColor: props.backgroundColor1, width: props.width }
              : { backgroundColor: props.backgroundColor2, width: props.width }
          }
          id={switchState === 1 ? props.id1 : props.id2}
          data-openreplay-obscured
        >
          <div
            className={`${
              switchState === 2 ? 'switchState2' : undefined
            } switchLabelTextContainer ${props.inTree ? 'inTree' : undefined}`}
            style={
              switchState === 1
                ? { margin: props.marginLabel1 }
                : { margin: props.marginLabel2 }
            }
          >
            <p
              className={`${switchState === 2 ? 'switchState2' : undefined} ${
                props.inTree ? 'switchLabel inTree' : 'switchLabel'
              }`}
              id={`${props.id}(text)`}
              style={
                switchState === 1
                  ? { color: props.color1 }
                  : { color: props.color2 }
              }
              data-textattribute={
                switchState === 1
                  ? props.label1TextAttribute
                  : props.label2TextAttribute
              }
            >
              {switchState === 1 ? props.label1 : props.label2}
            </p>
          </div>
        </div>
        <div
          id={`${props.id}(circle)`}
          className={
            switchState === 1 ? 'switchCircle-left' : 'switchCircle-right'
          }
          style={
            switchState === 1
              ? { borderColor: props.color1 }
              : { borderColor: props.color2 }
          }
        ></div>
      </div>
    </>
  )
}
