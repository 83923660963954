import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import exitImg from '../../../resources/images/187-newExitImg.svg'
import { findHandlingErrorState } from '../../../services/commonFunctions'
import { getWordpress } from '../../../services/requests'
import { handlingErrorsState } from '../../../states/HandlingErrorsState'
import parse from 'html-react-parser'
import { freemiumState } from '../../../states/FreemiumState'
import { AxiosResponse } from 'axios'
import { getFreemiumWordpress } from '../../../freemiumServices/freemiumRequests'

type Props = {
  id: string
  backGroundColor: string
  videoPostName: string | undefined
  blur?: boolean
  closePopUpFunction: () => void
  zIndex: number
  noConnection?: boolean
}

export default function VideoInfoButtonPopUp(props: Props) {
  const [loading, setLoading] = useState(true)
  const [videoFromCms, setVideoFromCms] = useState<string>('')
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const freemium = useRecoilValue(freemiumState)

  function clickOnEmptySpace(e: any) {
    if (!e.target.id.includes('videoInfo')) {
      if (props.closePopUpFunction) {
        props.closePopUpFunction()
      }
    }
  }

  useEffect(() => {
    if (freemium.isFreemium) {
      getFreemiumWordpress('videoinfobutton').then((res: any) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getWordpress'),
        )

        if (!('errorCode' in res)) {
          setVideoFromResult(res)
        } else {
          props.closePopUpFunction()
        }
      })
    } else {
      getWordpress('videoinfobutton').then((res: any) => {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'getWordpress'),
        )

        if (!('errorCode' in res)) {
          setVideoFromResult(res)
        } else {
          props.closePopUpFunction()
        }
      })
    }

    // eslint-disable-next-line
  }, [])

  const setVideoFromResult = (res: AxiosResponse) => {
    if (res.data) {
      const wordpressContent = parse(
        res.data.filter((post: any) => {
          if (post.slug) {
            return post.slug === props.videoPostName
          } else return [{ content: { rendered: [<div></div>] } }]
        })[0].content.rendered,
      )
      if ((wordpressContent as JSX.Element[])[0] === <div></div>) {
        props.closePopUpFunction()
      } else {
        setVideoFromCms(
          (wordpressContent as JSX.Element[]).filter(
            (element) => element.type === 'figure',
          )[0].props.children.props.src,
        )
        setLoading(false)
      }
    } else {
      props.closePopUpFunction()
    }
  }

  return (
    <div className="videoInfoButtonContainer">
      <div
        id="fullScreenPopMessageBackground"
        className="fullScreenPopMessageBackground"
        onClick={clickOnEmptySpace}
        style={
          props.blur
            ? {
                backgroundColor: props.backGroundColor,
                opacity: 0.8,
                zIndex: props.zIndex,
              }
            : {
                backgroundColor: props.backGroundColor,
                opacity: 1,
                zIndex: props.zIndex,
              }
        }
      ></div>
      <div
        className="fullScreenPopMessageContainer"
        onClick={clickOnEmptySpace}
        style={{ zIndex: props.zIndex + 1 }}
      >
        <div className="videoInfoContainer" id={'videoInfoContainer'}>
          {loading ? (
            <div
              className="circularProgressDiv"
              id={'videoInfo-circularContainer'}
            >
              <div id={'videoInfo-circular'}>
                <CircularProgress size={40} />
              </div>
            </div>
          ) : (
            <>
              <video
                autoPlay
                controls
                controlsList="nodownload"
                src={videoFromCms}
                className="videoFromCms"
                id={`videoInfo-${props.id}`}
              ></video>
              {props.closePopUpFunction ? (
                <div
                  className="exitButtonContainer"
                  onClick={props.closePopUpFunction}
                  id={`exitButtonContainer-${props.id}`}
                >
                  {props.noConnection ? (
                    <p className="exitX">X</p>
                  ) : (
                    <img
                      src={exitImg}
                      alt="exitImg"
                      className="exitButtonImg"
                      id={`exitButtonImg-${props.id}`}
                    />
                  )}
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
