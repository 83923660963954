import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { getText } from '../../../services/textFunctions'

type Props = {
  showHide: boolean
}

export default function UserAvatar(props: Props) {
  const [hover, setHover] = useState(false)
  const user = useRecoilValue(userState)

  function showHover() {
    setHover(true)
  }
  function hideHover() {
    setHover(false)
  }
  return (
    <div className="userAvatarContainer">
      <div
        className={
          props.showHide
            ? 'userThumbnailContainer bigUserThumbnailContainer'
            : 'userThumbnailContainer'
        }
        onMouseEnter={showHover}
        onMouseLeave={hideHover}
      >
        <p className="userThumbnailText" data-openreplay-obscured>
          {user.firstName.substring(0, 1).toUpperCase()}
          {user.lastName.substring(0, 1).toUpperCase()}
        </p>
      </div>

      {hover && props.showHide ? (
        <div className="userHoverContainer" data-openreplay-obscured>
          <p
            className="greeting"
            id="greetingText"
            data-textattribute="hover-61"
          >
            {getText('hover-61', user.settings)}
          </p>
          <p className="userName" id="userNameText">
            {user.fullName()}!
          </p>
        </div>
      ) : null}
    </div>
  )
}
