import { TextField } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

import '../../../Components/Inputs/input.css'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { viewerState } from '../../../states/ViewerState'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import { recoveryModeState } from '../../../states/RecoveryModeState'
import { freemiumState } from '../../../states/FreemiumState'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  id: string
  name: string
  value?: string
  label: string
  disabled?: boolean
  suffix?: string
  onKeyDown?: (e: any) => void
  helperText?: string
  smallField?: boolean
  className?: string
  error?: boolean
  errorMessage?: string | string[]
  onChange: (e: any) => void
  width: number
  type?: string
  autoComplete?: boolean
  tabIndex?: number
  maxLength?: number
  alwaysShrink?: boolean
  hideHelperText?: boolean
  labelTextAttribute: `label-${number}`
  errorTextAttribute?: `error-${string}`
  helperTextAttribute?: `helperText-${number}`
  multiline?: boolean
  onFocus?: () => void
  disabledMessage?: string
  withoutDisabledMessage?: boolean
  absoluteDisabledMessage?: boolean
  disabledMessageTextAttribute?: `message-${number}`
  noDelay?: boolean
}

const defaultProps: Partial<Props> = {
  disabled: false,
  suffix: '',
  label: '',
  className: '',
  smallField: false,
  errorMessage: '',
  type: 'text',
  autoComplete: false,
  maxLength: 250,
}

const InputText = (givenProps: Props) => {
  const user = useRecoilValue(userState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const props: Props = { ...defaultProps, ...givenProps }
  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)

  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [localErrorMessage, setLocalErrorMessage] = useState('')
  const [localValue, setLocalValue] = useState<string | undefined>(props.value)
  const [changeValue, setChangeValue] = useState<boolean>(false)
  const [hasChange, setHasChange] = useState<boolean>(false)
  const timer = useRef<NodeJS.Timeout | undefined>(undefined)

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  const handleChange = (e: any) => {
    if (props.multiline) {
      setTimeout(() => {
        if (props.onFocus) {
          props.onFocus()
        }
      }, 100)
    }

    if (props.noDelay) {
      props.onChange(e)
    } else {
      setLocalValue(e.target.value)
      setHasChange(true)

      if (timer.current) {
        clearTimeout(timer.current)
      }

      timer.current = setTimeout(() => {
        setChangeValue(true)
        setHasChange(false)
      }, 200)
    }
  }

  useEffect(() => {
    if (changeValue && localValue !== props.value && !props.noDelay) {
      props.onChange({ target: { value: localValue, id: props.id } })
    }

    setChangeValue(false)
    // eslint-disable-next-line
  }, [changeValue])

  useEffect(() => {
    if (!props.noDelay && !hasChange) {
      setLocalValue(props.value)
    }

    // eslint-disable-next-line
  }, [props.value])

  useEffect(() => {
    if (props.errorMessage && typeof props.errorMessage !== 'string') {
      setLocalErrorMessage(props.errorMessage[0])
    } else if (props.errorMessage !== undefined) {
      setLocalErrorMessage(props.errorMessage)
    }
  }, [props.errorMessage])

  const onClickDisabled = () => {
    if (props.disabled) {
      if (
        !props.withoutDisabledMessage &&
        recoveryMode.recoveryPreview === 'none'
      ) {
        const element = document.getElementById(`${props.id}-container`)
        const viewportOffset = element!.getBoundingClientRect()

        let top = viewportOffset.top - 15
        const left = viewportOffset.left + 50
        const h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }
        setDisabledMessageCoordinates([top, left])
      }

      if (freemium.isFreemium) {
        setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      } else if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
      }
    }
  }

  const onBlur = () => {
    if (localValue !== props.value && !props.noDelay) {
      props.onChange({ target: { value: localValue, id: props.id } })
    }
  }

  return (
    <>
      <div
        className={`input inputText`}
        id={`${props.id}-container`}
        onClick={onClickDisabled}
        style={props.disabled ? { cursor: 'not-allowed' } : {}}
      >
        <TextField
          label={props.label}
          id={props.id}
          value={props.noDelay ? props.value : localValue}
          type={props.type}
          variant="outlined"
          className={props.className}
          name={props.name}
          onChange={handleChange}
          onKeyDown={props.onKeyDown}
          error={props.error}
          helperText={
            props.hideHelperText
              ? ''
              : props.error === true
              ? localErrorMessage
              : props.helperText
              ? props.helperText
              : ' '
          }
          data-textattribute={props.labelTextAttribute}
          data-errortextattribute={props.errorTextAttribute}
          data-helpertextattribute={props.helperTextAttribute}
          InputLabelProps={{ shrink: props.alwaysShrink }}
          inputProps={{
            readOnly: props.disabled,
            maxLength: props.maxLength,
            autoComplete: props.autoComplete ? 'on' : 'off',
          }}
          sx={{
            width: props.width,
            height: props.multiline ? undefined : props.smallField ? 35 : 50,
            '& label': props.disabled
              ? {
                  color: 'rgba(0, 0, 0, 0.38)',
                  cursor: 'not-allowed',
                }
              : {},
          }}
          size={props.smallField ? 'small' : 'medium'}
          onFocus={() => {
            setTimeout(() => {
              if (props.onFocus) {
                props.onFocus()
              }
            }, 100)
          }}
          disabled={props.disabled}
          multiline={props.multiline}
          maxRows={3}
          minRows={1}
          onBlur={onBlur}
        />
        {props.disabled && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
            }}
            onClick={onClickDisabled}
          ></div>
        )}
      </div>
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={props.disabledMessageTextAttribute}
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
          absolute={props.absoluteDisabledMessage}
        />
      )}
    </>
  )
}

export default InputText
