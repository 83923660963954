import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import LowScreenMessage from '../Components/popUps/LowScreenMessage'
import { getText } from '../services/textFunctions'
import { recoveryModeState } from '../states/RecoveryModeState'
import { userState } from '../states/UserState'
import { useEffect, useState } from 'react'
import { onlineState } from '../states/OnlineState'
import { debuggingState } from '../states/DebuggingState'
import useNetworkStatus from '../customHooks/useNetworkStatus'
import { useParams } from 'react-router-dom'
import { Params } from '../models/generalTypes'
import { viewerState } from '../states/ViewerState'
import { deleteRecoverySnapshot } from '../services/requests'
import { savedState } from '../states/SavedState'

type Props = {
  children: JSX.Element
}

const LowScreenMessagesHOC = (props: Props) => {
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const user = useRecoilValue(userState)
  const [online, setOnline] = useRecoilState(onlineState)
  const debugging = useRecoilValue(debuggingState)
  const networkStatus = useNetworkStatus()
  const params = useParams<Params>()
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [allowClickRecoveryPreview, setAllowClickRecoveryPreview] =
    useState(true)
  const setSavedSnapshot = useSetRecoilState(savedState)

  useEffect(() => {
    if (debugging) {
      setOnline({ ...online, networkOn: true })
    } else {
      setOnline({
        ...online,
        networkOn: networkStatus.online,
        showPopUp: networkStatus.online ? false : online.showPopUp,
      })
    }
    // eslint-disable-next-line
  }, [networkStatus, debugging])

  return (
    <>
      {!online.networkOn ? (
        <LowScreenMessage
          backGroundColor="#ac2828" //greekEaster
          message={getText('message-16', user.settings)}
          messageTextAttribute="message-16"
          hoverColor="noInternetHover" //greekEasterHover
          color="white"
          onClick={() => setOnline({ ...online, showPopUp: true })}
          shaking={online.shaking}
          removeShaking={() => setOnline({ ...online, shaking: false })}
          id="noInternet"
        />
      ) : isViewer.isViewer ? (
        <LowScreenMessage
          backGroundColor="white"
          message={getText('message-81', user.settings)}
          messageTextAttribute="message-81"
          hoverColor="noInternetHover"
          color="black"
          bold
          onClick={() => setIsViewer({ ...isViewer, showPopUp: true })}
          shaking={isViewer.shaking}
          removeShaking={() => setIsViewer({ ...isViewer, shaking: false })}
          id="isViewer"
        />
      ) : recoveryMode.showLowScreenMessage ? (
        <LowScreenMessage
          backGroundColor="white"
          message={
            recoveryMode.recoveryPreview === 'old'
              ? getText('message-112', user.settings)
              : getText('message-113', user.settings)
          }
          messageTextAttribute={
            recoveryMode.recoveryPreview === 'old'
              ? 'message-112'
              : 'message-113'
          }
          clickableMessage={
            recoveryMode.recoveryPreview === 'old'
              ? getText('message-114', user.settings)
              : getText('message-115', user.settings)
          }
          clickableMessageTextAttribute={
            recoveryMode.recoveryPreview === 'old'
              ? 'message-114'
              : 'message-115'
          }
          buttonText={getText('button-150', user.settings)}
          buttonTextAttribute="button-150"
          hoverColor="noInternetHover"
          color="black"
          onMessageClick={
            allowClickRecoveryPreview
              ? () => {
                  setRecoveryMode((prevValue) => ({
                    ...recoveryMode,
                    recoveryPreview:
                      prevValue.recoveryPreview === 'new' ? 'old' : 'new',
                  }))
                  setAllowClickRecoveryPreview(false)
                  setTimeout(() => {
                    setAllowClickRecoveryPreview(true)
                  }, 1000)
                }
              : undefined
          }
          onButtonClick={() => {
            if (recoveryMode.recoveryPreview === 'old') {
              setSavedSnapshot({
                saved: true,
                keepResults: recoveryMode.keepResults,
              })

              deleteRecoverySnapshot(params.caseId!, params.scenarioId!)
            }

            setRecoveryMode({
              recoveryPreview: 'none',
              shaking: false,
              showLowScreenMessage: false,
              keepResults: false,
              old: undefined,
              new: undefined,
            })
          }}
          shaking={recoveryMode.shaking}
          removeShaking={() =>
            setRecoveryMode({ ...recoveryMode, shaking: false })
          }
          id="recoveryMode"
        />
      ) : null}
      {props.children}
    </>
  )
}

export default LowScreenMessagesHOC
