import { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import rangeBackground from '../../../../resources/images/126-distributionBackground.svg'
import krogerusRangeBackground from '../../../../resources/images/314-krogerusDistributionBackground.svg'
import { AccessRole, UndoRedoType } from '../../../../models/enums'
import { SnapshotSelectorObject } from '../../../../models/generalTypes'
import { changeGlobalSnapshot } from '../../../../services/changeGlobalSnapshot'
import {
  deepCloneObject,
  logActivity,
} from '../../../../services/commonFunctions'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { krogerusColorsState } from '../../../../states/KrogerusColorsState'

type Props = {
  defaultRangeValue: number
  singleSegmentIndex: number
  setChangeEffect: (param1: boolean) => void
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  findLegalFeesRangeText: (param: number) => void
  ownRole?: AccessRole
}

export default function LegalFeesRangeSlider(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const krogerusColors = useRecoilValue(krogerusColorsState)

  const [value, setValue] = useState(props.defaultRangeValue)

  useEffect(() => {
    setValue(props.defaultRangeValue)
    // eslint-disable-next-line
  }, [props.defaultRangeValue])

  useEffect(() => {
    let handle: NodeJS.Timeout

    if (props.defaultRangeValue !== value) {
      handle = setTimeout(() => handleChangeRangeValue(), 300)
    }
    return () => clearTimeout(handle)
    // eslint-disable-next-line
  }, [value])

  function handleChangeRangeValue() {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `legalFeesReangeSlider-${props.singleSegmentIndex}`,
      undoRedoType: UndoRedoType.button,
      value: value,
      key: 'legalFeesDistribution',
      secondaryIndex: props.singleSegmentIndex,
      key2: 'value',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)

    if (props.isFirstLoad) {
      //Mixpanel 30
      logActivity(false, 'Changed legal costs distribution')
      props.setIsFirstLoad!(false)
    }
  }

  return (
    <>
      <input
        type="range"
        min={-10}
        max={10}
        value={value ? value : 0}
        className="legalFeesRangeSlider"
        id={`legalFeesReangeSlider-${props.singleSegmentIndex}`}
        tabIndex={29999 - props.singleSegmentIndex}
        onChange={(e: any) => {
          if (props.ownRole !== AccessRole.VIEWER) {
            setValue(parseInt(e.target.value))
            props.findLegalFeesRangeText(e.target.value)
          }
        }}
        onMouseDown={() => {
          if (props.ownRole !== AccessRole.VIEWER) {
            props.setChangeEffect(true)
          }
        }}
        onMouseUp={() => {
          if (props.ownRole !== AccessRole.VIEWER) {
            setTimeout(() => {
              props.setChangeEffect(false)
            }, 400)
          }
        }}
        data-openreplay-obscured
      />
      <img
        src={krogerusColors ? krogerusRangeBackground : rangeBackground}
        alt="rangeBackground"
        className="rangeBackground"
      />
    </>
  )
}
