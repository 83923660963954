import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AccessRole,
  ClaimType,
  ClaimsPreviewStyle,
  Currency,
  UndoRedoType,
} from '../../../../../models/enums'
import {
  DefaultSnapshotState,
  SnapshotSelectorObject,
  TreeAnalysisResults,
} from '../../../../../models/generalTypes'
import { IndependentClaim } from '../../../../../models/independentClaim'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../services/commonFunctions'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import IndependentClaimTile from './independentClaimTile/IndependentClaimTile'
import TreeTile from './treeTile/TreeTile'
import IndependentClaimList from './independentClaimList/IndependentClaimList'
import TreeList from './treeList/TreeList'
import { freemiumState } from '../../../../../states/FreemiumState'

type Sorting = {
  option: 'ascending' | 'descending' | 'none'
  value: 'title' | 'client' | 'opposing' | 'weighted' | 'none'
}

type Props = {
  claimInfo: IndependentClaim | any
  claimIndex: number
  claimType: ClaimType
  currency: Currency
  activeClaim: number
  setActiveClaim: (claimIndex: number, noRerender?: boolean) => void
  claimPreviewId: string
  errors: number
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  treeDirectCalculation: (
    treeIndex: number,
    message: string,
  ) => Promise<TreeAnalysisResults[][] | undefined>
  ownRole: AccessRole
  claimPositionHighlight: number | undefined
  setClaimPositionHighlight: (
    claimPositionHighlight: number | undefined,
  ) => void
  claimIsDragging: number | undefined
  setClaimIsDragging: (claimIsDragging: number | undefined) => void
  calculateAllTrees: () => Promise<void>
  forceTiles: boolean
  expanded: boolean
  setExpanded: (value: boolean, id: string) => void
  sorting: Sorting
  changeSortingOption: (
    newValue: 'title' | 'client' | 'opposing' | 'weighted' | 'none',
    remove?: boolean,
  ) => void
  setIsClaimMenuOn: (value: boolean, index: number) => void
  isClaimMenuOn: boolean
}

export default function ClaimTile(props: Props) {
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const freemium = useRecoilValue(freemiumState)

  function handleRearrangeClaim() {
    if (props.claimPositionHighlight !== undefined) {
      props.changeSortingOption('none', true)

      const snapshotSelectorObject: SnapshotSelectorObject = {
        targetId: `singleClaimPreviewContainer-${props.claimIndex}`,
        undoRedoType: UndoRedoType.button,
        value: [props.claimIndex, props.claimPositionHighlight],
        key: 'claims',
        key2: 'changeOrder',
      }

      const activeClaimId =
        scenarioSnapshot.currentSnapshot.claims[props.activeClaim].id

      let tempScenarioSnapshot: DefaultSnapshotState =
        deepCloneObject(scenarioSnapshot)
      tempScenarioSnapshot = changeGlobalSnapshot(
        snapshotSelectorObject,
        tempScenarioSnapshot,
      )

      props.setActiveClaim(
        tempScenarioSnapshot.currentSnapshot.claims.findIndex(
          (claim) => claim.id === activeClaimId,
        ),
        true,
      )

      setScenarioSnapshot(tempScenarioSnapshot)
    }

    props.setClaimIsDragging(undefined)
    props.setClaimPositionHighlight(undefined)
    let draggedTile = document.getElementById(
      `singleClaimPreviewContainer-${props.claimIndex}`,
    )
    if (draggedTile) draggedTile.style.zIndex = '0'
    //Mixpanel 162 (All)
    logActivity(freemium.isFreemium, 'Rearranged claims position', {
      'Preview Style':
        scenarioSnapshot.currentSnapshot.claimsPreviewStyle.toString(),
    })
  }

  function handleDraggingClaim(data: any) {
    props.setClaimIsDragging(props.claimIndex)
    let draggedTile = document.getElementById(
      `singleClaimPreviewContainer-${props.claimIndex}`,
    )
    if (draggedTile) draggedTile.style.zIndex = '9999'

    for (let claimIndex in scenarioSnapshot.currentSnapshot.claims) {
      if (parseInt(claimIndex) !== props.claimIndex) {
        const draggingClaimBeforeDestinationClaim =
          props.claimIndex < parseInt(claimIndex)
        const containerRect = document
          .getElementById('claimsPreviewContainer')!
          .getBoundingClientRect()
        const destinationClaimRect = document
          .getElementById(`singleClaimPreviewContainer-${claimIndex}`)!
          .getBoundingClientRect()
        const draggingClaimRect = document
          .getElementById(`singleClaimPreviewContainer-${props.claimIndex}`)!
          .getBoundingClientRect()
        const destinationClaimAbsolutePosition = {
          x: destinationClaimRect.left - containerRect.left,
          y: destinationClaimRect.top - containerRect.top,
        }
        const draggingClaimPosition = {
          x: draggingClaimRect.left - containerRect.left,
          y: draggingClaimRect.top - containerRect.top,
        }
        const distanceXFromDestinationClaim =
          draggingClaimBeforeDestinationClaim
            ? draggingClaimPosition.x -
              (destinationClaimAbsolutePosition.x + 330)
            : draggingClaimPosition.x - destinationClaimAbsolutePosition.x
        const distanceYFromDestinationClaim =
          draggingClaimPosition.y - destinationClaimAbsolutePosition.y

        if (
          scenarioSnapshot.currentSnapshot.claimsPreviewStyle !==
          ClaimsPreviewStyle.tiles
        ) {
          if (Math.abs(distanceYFromDestinationClaim) < 15) {
            props.setClaimPositionHighlight(parseInt(claimIndex))
          }
          if (Math.abs(data.y) < 15) {
            props.setClaimPositionHighlight(undefined)
          }
        } else {
          if (
            Math.abs(distanceXFromDestinationClaim) < 165 &&
            Math.abs(distanceYFromDestinationClaim) < 110
          ) {
            props.setClaimPositionHighlight(parseInt(claimIndex))
          }
          if (Math.abs(data.x) < 165 && Math.abs(data.y) < 110) {
            props.setClaimPositionHighlight(undefined)
          }
        }
      }
    }
  }
  return (
    <>
      {props.claimType === ClaimType.tree ? (
        scenarioSnapshot.currentSnapshot.claimsPreviewStyle !==
          ClaimsPreviewStyle.tiles && !props.forceTiles ? (
          <TreeList
            claimInfo={props.claimInfo}
            claimIndex={props.claimIndex}
            currency={props.currency}
            isActive={props.activeClaim === props.claimIndex}
            setActiveClaim={props.setActiveClaim}
            claimPreviewId={props.claimPreviewId}
            errors={props.errors}
            removeClaim={props.removeClaim}
            ownRole={props.ownRole}
            claimPositionHighlight={props.claimPositionHighlight}
            claimIsDragging={props.claimIsDragging}
            handleDraggingClaim={handleDraggingClaim}
            handleChangeClaimOrder={handleRearrangeClaim}
            calculateAllTrees={props.calculateAllTrees}
            sorting={props.sorting}
            changeSortingOption={props.changeSortingOption}
            isClaimMenuOn={props.isClaimMenuOn}
            setIsClaimMenuOn={props.setIsClaimMenuOn}
            expanded={
              scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
              ClaimsPreviewStyle.expanded
            }
          />
        ) : (
          <TreeTile
            claimInfo={props.claimInfo}
            claimIndex={props.claimIndex}
            claimType={props.claimType}
            currency={props.currency}
            isActive={props.activeClaim === props.claimIndex}
            setActiveClaim={props.setActiveClaim}
            claimPreviewId={props.claimPreviewId}
            errors={props.errors}
            removeClaim={props.removeClaim}
            calculateAllTrees={props.calculateAllTrees}
            ownRole={props.ownRole}
            claimPositionHighlight={props.claimPositionHighlight}
            claimIsDragging={props.claimIsDragging}
            handleDraggingClaim={handleDraggingClaim}
            handleChangeClaimOrder={handleRearrangeClaim}
          />
        )
      ) : scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
          ClaimsPreviewStyle.tiles || props.forceTiles ? (
        <IndependentClaimTile
          claimInfo={props.claimInfo}
          claimIndex={props.claimIndex}
          claimType={props.claimType}
          currency={props.currency}
          isActive={props.activeClaim === props.claimIndex}
          setActiveClaim={props.setActiveClaim}
          claimPreviewId={props.claimPreviewId}
          errors={props.errors}
          removeClaim={props.removeClaim}
          ownRole={props.ownRole}
          claimPositionHighlight={props.claimPositionHighlight}
          claimIsDragging={props.claimIsDragging}
          handleDraggingClaim={handleDraggingClaim}
          handleChangeClaimOrder={handleRearrangeClaim}
        />
      ) : (
        <IndependentClaimList
          claimInfo={props.claimInfo}
          claimIndex={props.claimIndex}
          claimType={props.claimType}
          currency={props.currency}
          isActive={props.activeClaim === props.claimIndex}
          setActiveClaim={props.setActiveClaim}
          claimPreviewId={props.claimPreviewId}
          errors={props.errors}
          removeClaim={props.removeClaim}
          ownRole={props.ownRole}
          claimPositionHighlight={props.claimPositionHighlight}
          claimIsDragging={props.claimIsDragging}
          handleDraggingClaim={handleDraggingClaim}
          handleChangeClaimOrder={handleRearrangeClaim}
          expanded={props.expanded}
          setExpanded={props.setExpanded}
          sorting={props.sorting}
          changeSortingOption={props.changeSortingOption}
          isClaimMenuOn={props.isClaimMenuOn}
          setIsClaimMenuOn={props.setIsClaimMenuOn}
        />
      )}
    </>
  )
}
