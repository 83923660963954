import { LegalCostsSwitchButtonType } from '../enums'
import { LegalCostsPerProceedings } from './LegalCostsPerProceedings'

export class LegalCostsObject {
  included: boolean
  clientLegalCosts: {
    firstProceedings: LegalCostsPerProceedings
    additionalProceedings: LegalCostsPerProceedings | undefined
  }

  opposingPartyLegalCosts: {
    firstProceedings: LegalCostsPerProceedings
    additionalProceedings: LegalCostsPerProceedings | undefined
  }

  constructor(
    included: boolean,
    clientLegalCosts: {
      firstProceedings: LegalCostsPerProceedings
      additionalProceedings: LegalCostsPerProceedings | undefined
    },
    opposingPartyLegalCosts: {
      firstProceedings: LegalCostsPerProceedings
      additionalProceedings: LegalCostsPerProceedings | undefined
    },
  ) {
    this.included = included
    this.clientLegalCosts = clientLegalCosts
    this.opposingPartyLegalCosts = opposingPartyLegalCosts
  }

  static DefaultLegalCosts(): LegalCostsObject {
    return {
      included: false,
      clientLegalCosts: {
        firstProceedings: {
          estimatedCosts: undefined,
          recoverableCosts: {
            type: LegalCostsSwitchButtonType.percentage,
            value: 100,
          },
          incurredCosts: {
            type: LegalCostsSwitchButtonType.currency,
            value: 0,
          },
        },
        additionalProceedings: undefined,
      },
      opposingPartyLegalCosts: {
        firstProceedings: {
          estimatedCosts: undefined,
          recoverableCosts: {
            type: LegalCostsSwitchButtonType.percentage,
            value: 100,
          },
          incurredCosts: {
            type: LegalCostsSwitchButtonType.currency,
            value: 0,
          },
        },
        additionalProceedings: undefined,
      },
    }
  }
}
