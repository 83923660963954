import React from 'react'
import {
  ListItem,
  SortDirection,
  SortOption,
} from '../../../models/generalTypes'
import TitleComponent from './TitleComponent'

type Props = {
  widthOfCaseContainer: number | undefined
  isMyScenariosPage: boolean | undefined
  setSearchList: (value: React.SetStateAction<ListItem[]>) => void
  sortBy: (
    option: SortOption,
    changeDirection: boolean,
    listToSort?: ListItem[] | undefined,
  ) => ListItem[]
  sortOption: keyof ListItem
  sortDirection: SortDirection
  showUsersShare: boolean
  showDeleted?: boolean
}

export default function MyCasesTitles(props: Props) {
  return (
    <div
      className="myCasesList-titles"
      style={{ width: props.widthOfCaseContainer }}
    >
      <div
        className="myCasesList-titles-left "
        style={props.isMyScenariosPage ? { marginRight: '7px' } : undefined}
      >
        <TitleComponent
          sortOption={props.sortOption}
          sortOptionOfThisTitle="title"
          sortDirection={props.sortDirection}
          onClick={() => props.setSearchList(props.sortBy('title', true))}
          textAttribute={props.isMyScenariosPage ? 'title-7' : 'title-8'}
          id="caseDetailsContainer-title-name"
          className="caseDetailsContainer-title"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div className="myCasesList-titles-right">
        {!props.isMyScenariosPage &&
        props.showUsersShare &&
        !props.showDeleted ? (
          <TitleComponent
            sortOption={props.sortOption}
            sortDirection={props.sortDirection}
            textAttribute="title-9"
            id="caseDetailsContainer-title-shareWith"
            className="caseDetailsContainer-title-owner caseDetailsContainer-small"
            style={{ cursor: 'pointer' }}
          />
        ) : null}
        <TitleComponent
          onClick={
            (props.sortOption === 'dateCreated' &&
              props.sortDirection === 'up') ||
            (props.sortOption === 'createdBy' && props.sortDirection === 'down')
              ? () => props.setSearchList(props.sortBy('dateCreated', true))
              : () => props.setSearchList(props.sortBy('createdBy', true))
          }
          sortOption={props.sortOption}
          sortOptionOfThisTitle={
            props.sortOption === 'dateCreated'
              ? 'dateCreated'
              : props.sortOption === 'createdBy'
              ? 'createdBy'
              : undefined
          }
          sortDirection={props.sortDirection}
          textAttribute="title-10"
          id="caseDetailsContainer-title-created"
          className="caseDetailsContainer-title caseDetailsContainer-title-date-created caseDetailsContainer-small"
          style={{ cursor: 'pointer', marginLeft: '3px' }}
        />
        <TitleComponent
          onClick={
            (props.sortOption === 'lastEdited' &&
              props.sortDirection === 'up') ||
            (props.sortOption === 'lastEditedBy' &&
              props.sortDirection === 'down')
              ? () => props.setSearchList(props.sortBy('lastEdited', true))
              : () => props.setSearchList(props.sortBy('lastEditedBy', true))
          }
          sortOption={props.sortOption}
          sortOptionOfThisTitle={
            props.sortOption === 'lastEdited'
              ? 'lastEdited'
              : props.sortOption === 'lastEditedBy'
              ? 'lastEditedBy'
              : undefined
          }
          sortDirection={props.sortDirection}
          textAttribute={props.showDeleted ? 'title-197' : 'title-11'}
          id="caseDetailsContainer-title-lastEdited"
          className="caseDetailsContainer-title caseDetailsContainer-title-lastEdited caseDetailsContainer-small"
          style={{ cursor: 'pointer' }}
        />

        {props.isMyScenariosPage && !props.showDeleted ? (
          <TitleComponent
            onClick={() => props.setSearchList(props.sortBy('status', true))}
            sortOption={props.sortOption}
            sortOptionOfThisTitle="status"
            sortDirection={props.sortDirection}
            textAttribute="title-12"
            id="caseDetailsContainer-title-status"
            className="caseDetailsContainer-title-status caseDetailsContainer-title caseDetailsContainer-small"
            style={{ cursor: 'pointer' }}
          />
        ) : null}
        <TitleComponent
          sortOption={props.sortOption}
          sortDirection={props.sortDirection}
          textAttribute="title-13"
          id="caseDetailsContainer-title-actions"
          className="caseDetailsContainer-title-actions caseDetailsContainer-small"
          style={props.isMyScenariosPage ? { marginRight: '15px' } : undefined}
        />
      </div>
    </div>
  )
}
