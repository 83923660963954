import React from 'react'
import useWindowSize from '../../../../customHooks/useWindowSize'
import exitImg from '../../../../resources/images/187-newExitImg.svg'
import {
  getScaleForPopUp,
  getTopLeftForPopUp,
} from '../../../../services/popUpPositionFunctions'

type Props = {
  onClose?: () => void
  children: any
  small?: boolean
  verySmall?: boolean
  big?: boolean
  bigForm?: boolean
  noAbsolute?: boolean
  onlyOwner?: boolean
  smallOwner?: boolean
}

const SettingPopUp = (props: Props) => {
  const { width, height } = useWindowSize()

  function clickOnEmptySpace(e: any) {
    if (e.target.id === 'backgroundPopUp' && props.onClose) {
      props.onClose()
    }
  }

  const getWidthAndHeightOfPopUp = () => {
    let height = 620
    let width = 550

    if (props.bigForm) {
      height = 680
    } else if (props.small) {
      height = 400
    } else if (props.verySmall) {
      height = 300
    } else if (props.onlyOwner) {
      width = 700
      height = 700
    } else if (props.smallOwner) {
      width = 700
      height = 300
    }

    return [width, height]
  }

  return (
    <div
      id="backgroundPopUp"
      className={`userPopUp ${props.noAbsolute ? 'for-users' : 'for-settings'}`}
      style={{ height: height }}
      onMouseDown={clickOnEmptySpace}
    >
      <div
        className={
          props.big
            ? 'popUpBox'
            : `loginBox ${props.small ? 'small' : ''} ${
                props.verySmall ? 'very-small' : ''
              } ${props.bigForm ? 'big-form' : ''} ${
                props.onlyOwner ? 'only-owner' : ''
              } ${props.smallOwner ? 'small-owner' : ''}`
        }
        style={
          props.big
            ? {}
            : {
                transform: `scale(${getScaleForPopUp(
                  width,
                  height,
                  550,
                  620,
                )})`,
                top: `${
                  getTopLeftForPopUp(
                    width,
                    height,
                    getWidthAndHeightOfPopUp()[0],
                    getWidthAndHeightOfPopUp()[1],
                  )[1]
                }px`,
                left: `${
                  getTopLeftForPopUp(
                    width,
                    height,
                    getWidthAndHeightOfPopUp()[0],
                    getWidthAndHeightOfPopUp()[1],
                  )[0]
                }px`,
              }
        }
      >
        {props.children}
        {props.onClose && (
          <div onClick={props.onClose} className="exitButtonImgContainer">
            <img
              src={exitImg}
              alt="exitImg"
              className="exitButtonImg"
              id={'popUp2'}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default SettingPopUp
