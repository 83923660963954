import positiveBackgroundImg from '../../../../resources/images/236-possitiveRange.svg'
import krogerusPositiveBackgroundImg from '../../../../resources/images/313-krogerusPositiveRange.svg'
import negativeBackgroundImg from '../../../../resources/images/235-neggativeRange.svg'
import krogerusNegativeBackgroundImg from '../../../../resources/images/312-krogerusNegativeRange.svg'
import { useRecoilValue } from 'recoil'
import { krogerusColorsState } from '../../../../states/KrogerusColorsState'

type Props = {
  totalCounterClaimedAmount: number
  disputedAmount: number
  rangesBarHeight: number
}

export default function RangesBarBackground(props: Props) {
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const counterClaimedAmountPercentage =
    props.disputedAmount !== 0
      ? Math.abs(props.totalCounterClaimedAmount ?? 0) / props.disputedAmount
      : 0

  return (
    <>
      <div className="rangeBarContainerBackground">
        <img
          src={
            krogerusColors
              ? krogerusPositiveBackgroundImg
              : positiveBackgroundImg
          }
          alt="rangeBarPositive"
          className="rangeBarPositive"
          style={{
            height:
              (1 - counterClaimedAmountPercentage) * props.rangesBarHeight,
          }}
        />
        <img
          src={
            krogerusColors
              ? krogerusNegativeBackgroundImg
              : negativeBackgroundImg
          }
          alt="rangeBarNegative"
          className="rangeBarNegative"
          style={{
            height: counterClaimedAmountPercentage * props.rangesBarHeight,
          }}
        />
      </div>
    </>
  )
}
