import React from 'react'

import { PageType } from '../UserPopUp'
import backCircleImg from '../../../resources/images/134-backLogin.svg'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  setPageType: (value: PageType) => void
}

export default function CheckEmail(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <img
        className="backImg"
        src={backCircleImg}
        alt="backImg"
        onClick={() => props.setPageType('Login')}
      />

      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="checkEmailTitle"
          data-textattribute="title-108"
        >
          {getText('title-108', user.settings)}
        </h2>
        <p
          className="instructions"
          id="checkEmailInstructions"
          data-textattribute="description-81a,description-81b, description-81c"
        >
          {getText('description-81a', user.settings)}
          <br />
          <br /> {getText('description-81b', user.settings)}{' '}
          <a className="mailTo" href="mailto: support@eperoto.com">
            {getText('description-81c', user.settings)}
          </a>
        </p>
      </div>

      <div className="submitButtonContainer">
        <button
          type="button"
          className="submitButton"
          onClick={() => props.setPageType('Login')}
          id="submitCheckEmailButton"
          data-textattribute="button-50"
        >
          {getText('button-50', user.settings)}
        </button>
      </div>
    </>
  )
}
