import { atom } from 'recoil'
import { ToolTabs } from '../models/enums'

export const activeTabsState = atom({
  key: 'activeTabsState',
  default: {
    tab: ToolTabs.proceedings,
    claim: 0,
  },
})
