import fitty from 'fitty'
import { useEffect, useRef, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import DeleteClaimWindowForPreview from '../../../../../../Components/DeleteWindow/DeleteWindowForPreview'
import {
  AccessRole,
  ClaimType,
  ClaimsPreviewStyle,
  Currency,
} from '../../../../../../models/enums'
import { roundNumberTo } from '../../../../../../services/commonFunctions'
import { formattedNumToString } from '../../../../../../services/formatNum'
import { userState } from '../../../../../../states/UserState'
import { getText } from '../../../../../../services/textFunctions'
import { TreeClaim } from '../../../../../../models/treeModels/treeClaim'
import { editTreeNodeFromUndoState } from '../../../../../../states/EditTreeNodeFromUndo'
import { treeTablesState } from '../../../../../../states/TreeTablesState'
import ClaimTile3DotsMenu from '../ClaimTile3DotsMenu'
import Draggable from 'react-draggable'
import Symbol from '../../../../../../Components/symbol/Symbol'
import { krogerusColorsState } from '../../../../../../states/KrogerusColorsState'

type Props = {
  claimInfo: TreeClaim
  claimIndex: number
  claimType: ClaimType
  currency: Currency
  isActive: boolean
  setActiveClaim: (claimIndex: number, noRerender?: boolean) => void
  claimPreviewId: string
  errors: number
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  calculateAllTrees: () => Promise<void>
  ownRole?: AccessRole
  claimPositionHighlight: number | undefined
  claimIsDragging: number | undefined
  handleDraggingClaim: (data: any) => void
  handleChangeClaimOrder: () => void
}

export default function TreeTile(props: Props) {
  const nodeRef = useRef<HTMLDivElement>(null)

  const [threeDotsMenuOn, setThreeDotsMenuOn] = useState(false)
  const [hoverClaimTile, setHoverClaimTile] = useState(false)
  const [removeClaimMenuOn, setRemoveClaimMenuOn] = useState(false)
  const [hasDisputedAmount, setHasDisputedAmount] = useState(
    props.claimInfo.totalClaimedAmount !== undefined &&
      props.claimInfo.totalCounterClaimedAmount !== undefined,
  )
  const user = useRecoilValue(userState)
  const treeTables = useRecoilValue(treeTablesState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const setEditTreeNodeFromUndo = useSetRecoilState(editTreeNodeFromUndoState)
  const [isDragging, setIsDragging] = useState(false)

  const onSelectClaim = (e: any) => {
    const classesToIgnore = [
      'calculateAmountButton',
      'calculateAmountsButtonText',
    ]

    const includesClassToIgnore =
      e.target &&
      classesToIgnore.filter((className) =>
        e.target.className.includes(className),
      ).length > 0

    if (!includesClassToIgnore && !isDragging) {
      props.setActiveClaim(props.claimIndex)
      setEditTreeNodeFromUndo(undefined)
    }
  }

  useEffect(() => {
    setHasDisputedAmount(
      props.claimInfo.totalClaimedAmount !== undefined &&
        props.claimInfo.totalCounterClaimedAmount !== undefined,
    )
  }, [
    props.claimInfo.totalCounterClaimedAmount,
    props.claimInfo.totalClaimedAmount,
  ])

  useEffect(() => {
    setTimeout(() => {
      fitty('.claimAmountText', {
        minSize: 9,
        maxSize: 16,
        multiLine: false,
      })
    }, 10)
  }, [props.claimInfo, treeTables])

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        handle=".moveTileTransparentCover, .singlClaimUpperPartTree"
        onDrag={(e: any, data) => {
          if (data.x !== 0 || data.y !== 0) {
            setIsDragging(true)
          }

          props.handleDraggingClaim(data)
        }}
        onStop={() => {
          setTimeout(() => {
            setIsDragging(false)
          }, 20)

          props.handleChangeClaimOrder()
        }}
        defaultPosition={{ x: 0, y: 0 }}
        position={{ x: 0, y: 0 }}
      >
        <div
          className="singleClaimPreviewContainer"
          id={`singleClaimPreviewContainer-${props.claimIndex}`}
          onMouseEnter={() => setHoverClaimTile(true)}
          onMouseLeave={() => setHoverClaimTile(false)}
          ref={nodeRef}
          onClick={onSelectClaim}
        >
          <div
            className={`singlClaimUpperPartTree ${
              props.isActive ? 'active' : ''
            } ${krogerusColors ? 'krogerus' : ''}`}
            id={`singlClaimUpperPartTree-${props.claimIndex}`}
          >
            <div
              className="previewClaimName"
              id={`previewClaimName-${props.claimIndex}`}
              data-openreplay-obscured
            >
              {props.claimInfo.name}
            </div>
            {props.errors > 0 && <div className="errors">{props.errors}</div>}
          </div>
          <div className="claimPreviewPartLine"></div>
          <div
            className="singlClaimDownPartTree"
            id={`singlClaimDownPartTree-${props.claimIndex}`}
          >
            {!hasDisputedAmount ? (
              <button
                className="calculateAmountButton"
                type="button"
                onClick={() => {
                  props.calculateAllTrees()
                }}
              >
                <p
                  className="calculateAmountsButtonText"
                  id={`calculateAmountsButtonTextInPreview${props.claimIndex}`}
                  data-textattribute="button-27"
                >
                  {getText('button-27', user.settings)}
                </p>
              </button>
            ) : (
              <>
                <div className="row">
                  <Symbol
                    symbolType="client"
                    targetId={`claimAmountTextContainer-${props.claimIndex}`}
                    targetType="tileText"
                  />
                  <div
                    className="claimAmountTextContainer"
                    id={`claimAmountTextContainer-${props.claimIndex}`}
                  >
                    <p
                      className={`claimAmountText robotoNumbers ${
                        krogerusColors ? 'krogerus' : ''
                      }`}
                      data-openreplay-obscured
                    >
                      {formattedNumToString(
                        Math.abs(
                          roundNumberTo(
                            props.claimInfo.totalClaimedAmount as number,
                            3,
                          ),
                        ),
                        user.settings,
                      ) || 0}{' '}
                      {props.currency}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <Symbol
                    symbolType="opposingParty"
                    targetId={`counterClaimAmountTextContainer-${props.claimIndex}`}
                    targetType="tileText"
                  />
                  <div
                    className="claimAmountTextContainer"
                    id={`counterClaimAmountTextContainer-${props.claimIndex}`}
                  >
                    <p
                      className={`claimAmountText robotoNumbers negative ${
                        krogerusColors ? 'krogerus' : ''
                      }`}
                      data-openreplay-obscured
                    >
                      {formattedNumToString(
                        Math.abs(
                          roundNumberTo(
                            props.claimInfo.totalCounterClaimedAmount as number,
                            3,
                          ),
                        ),
                        user.settings,
                      ) || 0}{' '}
                      {props.currency}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>

          {(hoverClaimTile ||
            threeDotsMenuOn ||
            props.claimIsDragging === props.claimIndex) &&
            !removeClaimMenuOn &&
            (props.claimIsDragging !== undefined
              ? props.claimIsDragging === props.claimIndex
              : true) &&
            props.ownRole !== AccessRole.VIEWER && (
              <ClaimTile3DotsMenu
                claimIndex={props.claimIndex}
                threeDotsMenuOn={threeDotsMenuOn}
                setThreeDotsMenuOn={setThreeDotsMenuOn}
                setRemoveClaimMenuOn={setRemoveClaimMenuOn}
                claimType={ClaimType.tree}
                setActiveClaim={props.setActiveClaim}
              />
            )}
          {removeClaimMenuOn && (
            <div className="claimRemoveMenuContainer">
              <DeleteClaimWindowForPreview
                claimIndex={props.claimIndex}
                claimType={props.claimType}
                previewStyle={ClaimsPreviewStyle.tiles}
                deleteFunction={() => {
                  props.removeClaim(
                    props.claimIndex,
                    `removeClaimImgPreview-${props.claimIndex}`,
                    `removeClaimImgPreview-${props.claimIndex}`,
                  )
                  setRemoveClaimMenuOn(false)
                }}
                cancelFunction={() => setRemoveClaimMenuOn(false)}
              />
            </div>
          )}
          {props.claimPositionHighlight === props.claimIndex && (
            <div
              className={
                props.claimPositionHighlight > props.claimIsDragging!
                  ? 'claimPositionHighlight right'
                  : 'claimPositionHighlight left'
              }
              id={`claimPositionHighlight-${props.claimIndex}`}
            ></div>
          )}
        </div>
      </Draggable>
    </>
  )
}
