import { TreeDetails } from '../../models/treeModels/treeClaim'
import {
  EventId,
  HeightOfNodeState,
  NodeId,
  NodeMode,
} from '../../models/treeModels/treeTypes'
import {
  findEventsOfNode,
  findHeightOfNodeState,
  findNodesOfTreeFromRoot,
} from './treeBasicFunctions'
import {
  defaultEventWidth,
  defaultEventWidthMinimised,
  defaultHeightsOfNode,
  defaultMaximisedRightSpace,
  defaultNodeHandlerWidth,
} from './treePositioningFunctions'

export function widthOfNode(
  nodeId: NodeId | 'defaultNewNode',
  nodeMode: NodeMode,
  treeDetails: TreeDetails,
): number {
  const eventWidth =
    nodeMode === NodeMode.maximised
      ? defaultEventWidth
      : defaultEventWidthMinimised

  const extraWidth =
    nodeMode === NodeMode.maximised
      ? defaultNodeHandlerWidth + defaultMaximisedRightSpace
      : defaultNodeHandlerWidth
  if (nodeId === 'defaultNewNode') {
    return 2 * eventWidth + extraWidth
  } else {
    return treeDetails.nodes[nodeId].numberOfEvents * eventWidth + extraWidth
  }
}

export function heightOfNode(
  nodeMode: NodeMode,
  nodeId: NodeId | 'defaultNewNode',
  treeIndex: number,
  treeDetails: TreeDetails,
  editMode: boolean,
) {
  let heightOfTitle = 3
  let heightOfNodeState = HeightOfNodeState.noAmounts_1
  if (nodeId !== 'defaultNewNode') {
    heightOfTitle = findHeightOfTitle(nodeId, treeIndex, nodeMode)
    heightOfNodeState = findHeightOfNodeState(nodeId, treeDetails)
  }

  if (nodeMode === NodeMode.minimised && !editMode) {
    if (
      document.getElementById(
        `treeEventTitleContainerMinimised-${treeIndex}_${nodeId}!0`,
      )
    ) {
      heightOfTitle +=
        document.getElementById(
          `treeEventTitleContainerMinimised-${treeIndex}_${nodeId}!0`,
        )!.scrollHeight - 44
    }
  } else {
    if (
      document.getElementById(
        `treeEventTitleContainer-${treeIndex}_${nodeId}!0`,
      )
    ) {
      heightOfTitle +=
        document.getElementById(
          `treeEventTitleContainer-${treeIndex}_${nodeId}!0`,
        )!.scrollHeight - 72
    }
  }

  if (editMode) {
    return (
      heightOfTitle + defaultHeightsOfNode.editMode.state[heightOfNodeState]
    )
  } else {
    return (
      heightOfTitle + defaultHeightsOfNode[nodeMode].state[heightOfNodeState]
    )
  }
}

export function calculateHeightDiffForMinMax(
  nodeId: NodeId,
  treeDetails: TreeDetails,
) {
  let eventsOfNode = findEventsOfNode(nodeId, treeDetails)
  let eventWithLongestTitle = eventsOfNode[0]
  for (let eventId of eventsOfNode) {
    if (
      treeDetails.events[eventId].eventDetails.eventTitle.length >
      treeDetails.events[eventWithLongestTitle].eventDetails.eventTitle.length
    ) {
      eventWithLongestTitle = eventId
    }
  }
  let heightOfNodeState = findHeightOfNodeState(nodeId, treeDetails)

  let heightOfTitleAccordingToLetters_maximised =
    findHeightOfTitleAccordingToLetters(nodeId, treeDetails, NodeMode.maximised)
  let heightOfEventTitleAccordingToLetters_maximised =
    findHeightOfEventTitleAccordingToLetters(
      eventWithLongestTitle,
      treeDetails,
      NodeMode.maximised,
    )
  let heightOfMaximisedNode =
    defaultHeightsOfNode.maximised.state[heightOfNodeState]

  let maximisedHeight =
    heightOfTitleAccordingToLetters_maximised +
    heightOfEventTitleAccordingToLetters_maximised +
    heightOfMaximisedNode

  let heightOfTitleAccordingToLetters_minimised =
    findHeightOfTitleAccordingToLetters(nodeId, treeDetails, NodeMode.minimised)

  let heightOfEventTitleAccordingToLetters_minimised =
    findHeightOfEventTitleAccordingToLetters(
      eventWithLongestTitle,
      treeDetails,
      NodeMode.minimised,
    )
  let heightOfMinimisedNode =
    defaultHeightsOfNode.minimised.state[heightOfNodeState]

  let minimisedHeight =
    heightOfTitleAccordingToLetters_minimised +
    heightOfEventTitleAccordingToLetters_minimised +
    heightOfMinimisedNode

  return maximisedHeight - minimisedHeight
}

export function nodeX(nodeId: NodeId, treeDetails: TreeDetails): number {
  return treeDetails.nodes[nodeId].properties.position[0]
}

export function nodeY(nodeId: NodeId, treeDetails: TreeDetails): number {
  return treeDetails.nodes[nodeId].properties.position[1]
}

export function eventXFromNodeX(
  parentEventId: EventId,
  nodeMode: NodeMode,
  treeDetails: TreeDetails,
): number {
  const eventWidth =
    nodeMode === NodeMode.maximised
      ? defaultEventWidth
      : defaultEventWidthMinimised

  return (
    treeDetails.events[parentEventId].eventIndex * eventWidth +
    defaultNodeHandlerWidth -
    eventWidth / 2
  )
}

export function findLimitsOfNode(
  nodeId: NodeId,
  treeDetails: TreeDetails,
  dimension: 'x' | 'y',
  nodeMode: NodeMode,
  treeIndex: number,
  editMode: boolean,
) {
  if (dimension === 'x') {
    return [
      findLeftPointOfNode(nodeId as NodeId, treeDetails, nodeMode),
      findLeftPointOfNode(nodeId as NodeId, treeDetails, nodeMode) +
        widthOfNode(nodeId, nodeMode, treeDetails),
    ]
  } else {
    return [
      findTopPointOfNode(
        nodeId as NodeId,
        treeDetails,
        findHeightOfTitle(nodeId as NodeId, treeIndex, nodeMode),
      ),
      findTopPointOfNode(
        nodeId as NodeId,
        treeDetails,
        findHeightOfTitle(nodeId as NodeId, treeIndex, nodeMode),
      ) + heightOfNode(nodeMode, nodeId, treeIndex, treeDetails, editMode),
    ]
  }
}

export function findTopMostNode(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).reduce((a, b) =>
    nodeY(a as NodeId, treeDetails) < nodeY(b as NodeId, treeDetails) ? a : b,
  )
}
export function findTopMostNodeOfMainTree(
  treeDetails: TreeDetails,
  mainTree: NodeId[],
  treeIndex: number,
  nodeMode: NodeMode,
) {
  let mainTreeNodes = Object.keys(treeDetails.nodes).filter((nodeId) =>
    mainTree.includes(nodeId as NodeId),
  )

  if (mainTreeNodes.length > 0) {
    return mainTreeNodes.reduce((a, b) =>
      nodeY(a as NodeId, treeDetails) -
        findHeightOfTitle(a as NodeId, treeIndex, nodeMode) <
      nodeY(b as NodeId, treeDetails) -
        findHeightOfTitle(b as NodeId, treeIndex, nodeMode)
        ? a
        : b,
    )
  } else {
    return []
  }
}
export function findBottomMostNodeOfMainTree(
  treeDetails: TreeDetails,
  mainTree: NodeId[],
  nodeMode: NodeMode,
  treeIndex: number,
) {
  return Object.keys(treeDetails.nodes)
    .filter((nodeId) => mainTree.includes(nodeId as NodeId))
    .reduce((a, b) =>
      nodeY(a as NodeId, treeDetails) +
        heightOfNode(nodeMode, a as NodeId, treeIndex, treeDetails, false) >
      nodeY(b as NodeId, treeDetails) +
        heightOfNode(nodeMode, b as NodeId, treeIndex, treeDetails, false)
        ? a
        : b,
    )
}

export function findLeftMostNodeOfMainTree(
  treeDetails: TreeDetails,
  mainTree: NodeId[],
) {
  let mainTreeNodes = Object.keys(treeDetails.nodes).filter((nodeId) =>
    mainTree.includes(nodeId as NodeId),
  )

  if (mainTreeNodes.length > 0) {
    return mainTreeNodes.reduce((a, b) =>
      nodeX(a as NodeId, treeDetails) < nodeX(b as NodeId, treeDetails) ? a : b,
    )
  } else {
    return []
  }
}

export function findRightMostNodeOfMainTree(
  treeDetails: TreeDetails,
  mainTree: NodeId[],
  nodeMode: NodeMode,
) {
  return Object.keys(treeDetails.nodes)
    .filter((nodeId) => mainTree.includes(nodeId as NodeId))
    .reduce((a, b) =>
      nodeX(a as NodeId, treeDetails) +
        widthOfNode(a as NodeId, nodeMode, treeDetails) >
      nodeX(b as NodeId, treeDetails) +
        widthOfNode(b as NodeId, nodeMode, treeDetails)
        ? a
        : b,
    )
}
export function findLeftMostNode(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).reduce((a, b) =>
    nodeX(a as NodeId, treeDetails) < nodeX(b as NodeId, treeDetails) ? a : b,
  )
}

export function findRightMostNode(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).reduce((a, b) =>
    nodeX(a as NodeId, treeDetails) +
      widthOfNode(a as NodeId, NodeMode.maximised, treeDetails) >
    nodeX(b as NodeId, treeDetails) +
      widthOfNode(b as NodeId, NodeMode.maximised, treeDetails)
      ? a
      : b,
  )
}

export function findRightMostNodeOfSubTree(
  rootNodeId: NodeId,
  treeDetails: TreeDetails,
) {
  const allNodesOfSubTree = findNodesOfTreeFromRoot(
    rootNodeId,
    treeDetails,
    [],
    [],
    false,
  )

  return allNodesOfSubTree.reduce((a, b) =>
    findLeftPointOfNode(a as NodeId, treeDetails, NodeMode.maximised) +
      widthOfNode(a as NodeId, NodeMode.maximised, treeDetails) >
    findLeftPointOfNode(b as NodeId, treeDetails, NodeMode.maximised) +
      widthOfNode(b as NodeId, NodeMode.maximised, treeDetails)
      ? a
      : b,
  )
}

export function findRightMostNodeOfTree(treeDetails: TreeDetails) {
  const allNodes = Object.keys(treeDetails.nodes)

  return allNodes.reduce((a, b) =>
    findLeftPointOfNode(a as NodeId, treeDetails, NodeMode.maximised) +
      widthOfNode(a as NodeId, NodeMode.maximised, treeDetails) >
    findLeftPointOfNode(b as NodeId, treeDetails, NodeMode.maximised) +
      widthOfNode(b as NodeId, NodeMode.maximised, treeDetails)
      ? a
      : b,
  )
}

export function findClosestToLeftCornerNode(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).reduce((a, b) =>
    nodeX(a as NodeId, treeDetails) ** 2 +
      nodeY(a as NodeId, treeDetails) ** 2 <
    nodeX(b as NodeId, treeDetails) ** 2 + nodeY(b as NodeId, treeDetails) ** 2
      ? a
      : b,
  )
}

export function findClosestToRightCornerNode(treeDetails: TreeDetails) {
  return Object.keys(treeDetails.nodes).reduce((a, b) =>
    nodeX(a as NodeId, treeDetails) ** 2 +
      nodeY(a as NodeId, treeDetails) ** 2 >
    nodeX(b as NodeId, treeDetails) ** 2 + nodeY(b as NodeId, treeDetails) ** 2
      ? a
      : b,
  )
}

export function findLeftPointOfNode(
  nodeId: NodeId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
) {
  if (nodeMode === NodeMode.maximised) {
    return treeDetails.nodes[nodeId as NodeId].properties.position[0] - 10
  } else {
    return treeDetails.nodes[nodeId as NodeId].properties.position[0] + 10
  }
}

export function findTopPointOfNode(
  nodeId: NodeId,
  treeDetails: TreeDetails,
  heightOfTitle: number,
) {
  return (
    treeDetails.nodes[nodeId as NodeId].properties.position[1] - heightOfTitle
  )
}

export function findHeightOfTitle(
  nodeId: NodeId,
  treeIndex: number,
  nodeMode: NodeMode,
) {
  if (document.getElementById(`treeNodeTitleTextarea-${treeIndex}_${nodeId}`)) {
    const heightOfScroll = document.getElementById(
      `treeNodeTitleTextarea-${treeIndex}_${nodeId}`,
    )!.scrollHeight
    if (nodeMode === NodeMode.maximised) {
      return heightOfScroll - 36
    }
    return heightOfScroll - 36
  } else return 0
}

export function checkIfChildIsUnderParent(
  treeDetails: TreeDetails,
  childNodeId: NodeId,
  parentNodeId: NodeId,
  nodeMode: NodeMode,
  treeIndex: number,
) {
  return (
    findLimitsOfNode(
      parentNodeId,
      treeDetails,
      'x',
      NodeMode.maximised,
      treeIndex,
      true,
    )[0] <
      findLimitsOfNode(
        childNodeId,
        treeDetails,
        'x',
        nodeMode,
        treeIndex,
        false,
      )[1] &&
    findLimitsOfNode(
      parentNodeId,
      treeDetails,
      'x',
      NodeMode.maximised,
      treeIndex,
      true,
    )[1] >
      findLimitsOfNode(
        childNodeId,
        treeDetails,
        'x',
        nodeMode,
        treeIndex,
        false,
      )[0]
  )
}

export function findHeightOfTitleAccordingToLetters(
  nodeId: NodeId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
) {
  let titleLength = treeDetails.nodes[nodeId].nodeTitle.length
  let numberOfEvents = treeDetails.nodes[nodeId].numberOfEvents
  let thresholds: number[] = []
  let heightOfTitle = 3
  if (nodeMode === NodeMode.minimised) {
    switch (numberOfEvents) {
      case 1:
        thresholds = [
          10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150,
        ]
        break
      case 2:
        thresholds = [25, 50, 75, 100, 125, 150]
        break
      case 3:
        thresholds = [60, 120, 180]
        break
      case 4:
        thresholds = [90, 180]
        break
      case 5:
        thresholds = [110, 220]
        break
      case 6:
        thresholds = [125, 240]
        break
      case 7:
        thresholds = [150, 300]
        break
      case 8:
        thresholds = [150, 300]
        break
      case 9:
        thresholds = [170, 340]
        break
      default:
        break
    }
  } else {
    switch (numberOfEvents) {
      case 1:
        thresholds = [22, 44, 66, 88, 110, 132, 154, 176, 198]
        break
      case 2:
        thresholds = [52, 104, 156, 208]
        break
      case 3:
        thresholds = [82, 164, 246]
        break
      case 4:
        thresholds = [122, 244]
        break
      case 5:
        thresholds = [157, 314]
        break
      default:
        break
    }
  }
  for (const threshold of thresholds) {
    if (titleLength < threshold) {
      break
    }
    heightOfTitle += 24
  }
  return heightOfTitle
}
export function findHeightOfEventTitleAccordingToLetters(
  eventId: EventId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
) {
  let titleLength = treeDetails.events[eventId].eventDetails.eventTitle.length
  if (titleLength === 0 && nodeMode === NodeMode.minimised) {
    return 0
  }
  let thresholds: number[] = []
  let heightOfTitle = 0
  if (nodeMode === NodeMode.minimised) {
    thresholds = [16, 32, 48, 64]
  } else {
    thresholds = [21, 42, 63]
  }
  for (const threshold of thresholds) {
    if (titleLength < threshold) {
      break
    }
    heightOfTitle += nodeMode === NodeMode.maximised ? 23 : 13
  }
  return heightOfTitle
}
