import { roundTo4Decimals } from '../Modules/DisputeModules/AppFunctions'
import { AmountSignum, ClaimType, InterestViewOption } from '../models/enums'
import { ClaimTypeId } from '../models/generalTypes'
import { IndependentClaim } from '../models/independentClaim'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { EventDetails } from '../models/treeModels/eventDetails'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { TreeEventClass } from '../models/treeModels/treeEvent'
import { EventId, NodeId } from '../models/treeModels/treeTypes'
import { UserSettings } from '../models/userSettings'
import { generateId, roundNumberTo } from './commonFunctions'
import { formattedNumToString } from './formatNum'
import { getText } from './textFunctions'
import { createNewNode } from './treeFunctions/treeBasicFunctions'
import { resetNodesPositions } from './treeFunctions/treePositioningFunctions'

export function claimtypeIdArray(state: ScenarioSnapshot) {
  let tempClaimsArray = []
  let tempClaimsCompArray = []

  if (state.claims && state.claims.length > 0) {
    let claimCounter = 1
    let counterclaimCounter = 1
    let treecounter = 1
    for (let i = 0; i < state.claims.length; i++) {
      if (state.claims[i].type === 'tree') {
        //Keep the name in state as Decision Tree, change the way is translated into text using translateClaimTypeId()
        tempClaimsArray.push(`Decision Tree ${treecounter}`)
        tempClaimsCompArray.push(`claim${i} menuChild`)
      } else if (state.claims[i].type === 'claim') {
        //Keep the name in state as Client Claim, change the way is translated into text using translateClaimTypeId()
        tempClaimsArray.push(`Client Claim ${claimCounter}`)
        tempClaimsCompArray.push(`claim${i} menuChild`)
        claimCounter++
      } else {
        //Keep the name in state as Opposing Party Claim, change the way is translated into text using translateClaimTypeId()
        tempClaimsArray.push(`Opposing Party Claim ${counterclaimCounter}`)
        tempClaimsCompArray.push(`claim${i} menuChild`)

        counterclaimCounter++
      }
    }
  }

  return [tempClaimsArray, tempClaimsCompArray]
}

export function findNextClaimTypeId(
  state: ScenarioSnapshot,
  typeOfClaim: ClaimType,
): ClaimTypeId {
  let claimCounter = 1
  let counterclaimCounter = 1
  let treecounter = 1

  for (let i = 0; i < state.claims.length; i++) {
    if (state.claims[i].type === 'tree') {
      treecounter++
    } else if (state.claims[i].type === 'claim') {
      claimCounter++
    } else {
      counterclaimCounter++
    }
  }

  switch (typeOfClaim) {
    case ClaimType.tree:
      return `Decision Tree ${treecounter}`
    case ClaimType.claim:
      return `Client Claim ${claimCounter}`
    case ClaimType.counterclaim:
      return `Opposing Party Claim ${counterclaimCounter}`
  }
}

export function handleClaimtypeIdChange(
  tempClaimsArray: string[],
  state: ScenarioSnapshot,
) {
  let tempState = JSON.parse(JSON.stringify(state))
  for (let i in tempClaimsArray) {
    tempState.claims[i].claimtypeId = tempClaimsArray[i]
  }

  return tempState
}

export function claimPreviewIdArrayFunction(
  state: ScenarioSnapshot,
  settings: UserSettings,
) {
  let tempClaimsPreviewIdArray = []

  if (state.claims && state.claims.length > 0) {
    let claimCounter = 1
    let treecounter = 1
    for (let i = 0; i < state.claims.length; i++) {
      if (state.claims[i].type === 'tree') {
        //id='claimTypetIdContainer'
        // data-attribute='title-42'
        tempClaimsPreviewIdArray.push(
          `${getText('title-42', settings)} ${treecounter}`,
        )
        treecounter++
      } else {
        //id='claimTypetIdContainer'
        // data-attribute='title-43'
        tempClaimsPreviewIdArray.push(
          `${getText('title-43', settings)} ${claimCounter}`,
        )
        claimCounter++
      }
    }
  }
  return tempClaimsPreviewIdArray
}

export function claimPreviewIdArrayFunction____new(
  state: ScenarioSnapshot,
  settings: UserSettings,
) {
  let tempClaimsPreviewIdArray = []

  if (state.claims && state.claims.length > 0) {
    for (let i = 0; i < state.claims.length; i++) {
      if (state.claims[i].type === 'tree') {
        //id='claimTypetIdContainer'
        // data-attribute='title-42'
        tempClaimsPreviewIdArray.push(`${getText('title-42', settings)}`)
      } else {
        //id='claimTypetIdContainer'
        // data-attribute='title-43'
        tempClaimsPreviewIdArray.push(`${getText('title-43', settings)}`)
      }
    }
  }
  return tempClaimsPreviewIdArray
}

export const findDuplicationNameOfClaim = (
  name: string,
  currentSnapshot: ScenarioSnapshot,
) => {
  let listOfNames = []
  for (let claim of currentSnapshot.claims) {
    listOfNames.push(claim.name)
  }

  let nameExists = true
  let endingRegexCopy = /\w+ - Copy$/
  let endingRegexCopyWithNumber = /\w+ - Copy\(\d+\)$/
  let alreadyAcopyWithNumber = endingRegexCopyWithNumber.test(name)
  let numberOfCopy = alreadyAcopyWithNumber ? 3 : 2
  let newName = name

  while (nameExists) {
    let alreadyAcopy = endingRegexCopy.test(newName)
    let alreadyAcopyWithNumber = endingRegexCopyWithNumber.test(newName)
    // eslint-disable-next-line no-loop-func
    if (listOfNames.find((claimName) => claimName === newName)) {
      if (alreadyAcopy || alreadyAcopyWithNumber) {
        if (numberOfCopy === 2) {
          newName += `(${numberOfCopy})`
        } else {
          let newNameArray = newName.split(' - ')
          newNameArray[newNameArray.length - 1] = newNameArray[
            newNameArray.length - 1
          ].replace(`(${numberOfCopy - 1})`, `(${numberOfCopy})`)
          newName = newNameArray.join(' - ')
        }
        numberOfCopy++
      } else {
        newName += ' - Copy'
      }
    } else {
      nameExists = false
    }
  }

  return newName
}

export function convertClaimToTree(
  claim: IndependentClaim,
  currentSnapshot: ScenarioSnapshot,
) {
  if (claim.type !== 'tree') {
    let tempClaim = TreeClaim.initialTree(
      claim.name,
      {
        firstTrialDate: currentSnapshot.firstTrialDate,
        secondTrialDate: currentSnapshot.secondTrialDate,
      },
      findNextClaimTypeId(
        currentSnapshot,
        ClaimType.tree,
      ) as `Decision Tree ${number}`,
      generateId('node') as NodeId,
      generateId('event') as EventId,
      generateId('event') as EventId,
    )
    let nodeIndex = 0
    let eventIndex = 0
    let firstNodeId = Object.keys(tempClaim.treeDetails.nodes)[
      nodeIndex
    ] as NodeId

    tempClaim.treeDetails.nodes[firstNodeId].properties.position = [0, 0]
    if (claim.type === 'counterclaim') {
      tempClaim.totalCounterClaimedAmount = claim.amount ? claim.amount : 0
    } else {
      tempClaim.totalClaimedAmount = claim.amount ? claim.amount : 0
    }

    for (let liabilityRequirement of claim.liabilityRequirements) {
      let nodeId = Object.keys(tempClaim.treeDetails.nodes)[nodeIndex] as NodeId
      tempClaim.treeDetails.nodes[nodeId].nodeTitle = liabilityRequirement.title
      let eventId1 = Object.keys(tempClaim.treeDetails.events)[
        eventIndex
      ] as EventId
      let eventId2 = Object.keys(tempClaim.treeDetails.events)[
        eventIndex + 1
      ] as EventId
      tempClaim.treeDetails.events[eventId1].eventDetails.eventTitle = 'Yes'
      tempClaim.treeDetails.events[eventId1].eventDetails.probability =
        liabilityRequirement.probability / 100
      tempClaim.treeDetails.events[eventId2].eventDetails.eventTitle = 'No'
      tempClaim.treeDetails.events[eventId2].eventDetails.probability =
        1 - liabilityRequirement.probability / 100

      tempClaim = createNewNode(
        tempClaim,
        Object.keys(tempClaim.treeDetails.events)[eventIndex] as EventId,
      )
      eventIndex += 2
      nodeIndex += 1
      tempClaim.numOfTreeScenarios++
    }
    let nodeId = Object.keys(tempClaim.treeDetails.nodes)[nodeIndex] as NodeId
    tempClaim.treeDetails.nodes[nodeId].nodeTitle = 'Quantum'
    tempClaim.treeDetails.nodes[nodeId].numberOfEvents =
      claim.quantumProbabilities.length
    let probabilityIndex = 0
    for (let probability of claim.quantumProbabilities) {
      if (probabilityIndex > 1) {
        const newEventId1: EventId = generateId('event') as EventId
        tempClaim.treeDetails.events[newEventId1] =
          TreeEventClass.defaultTreeEvent(
            nodeId,
            probabilityIndex,
            EventDetails.defaultEventDetails(0),
          )
        tempClaim.numOfTreeScenarios++
      }
      let eventId = Object.keys(tempClaim.treeDetails.events)[
        eventIndex
      ] as EventId
      tempClaim.treeDetails.events[eventId].eventDetails.probability =
        probability / 100
      if (claim.type === 'counterclaim') {
        tempClaim.treeDetails.events[eventId].eventDetails.awardedAmountSignum =
          AmountSignum.pay
        tempClaim.treeDetails.events[eventId].eventDetails.awardedAmount =
          -Math.abs(
            claim.quantumScenarios[probabilityIndex].amount !== undefined
              ? claim.quantumScenarios[probabilityIndex].amount!
              : 0,
          )
      } else {
        tempClaim.treeDetails.events[eventId].eventDetails.awardedAmountSignum =
          AmountSignum.get
        tempClaim.treeDetails.events[eventId].eventDetails.awardedAmount =
          Math.abs(
            claim.quantumScenarios[probabilityIndex].amount !== undefined
              ? claim.quantumScenarios[probabilityIndex].amount!
              : 0,
          )
      }
      if (claim.isInterest) {
        tempClaim.treeDetails.events[eventId].eventDetails.hasInterest = true
        tempClaim.treeDetails.events[eventId].eventDetails.interestRate =
          claim.interestRate ? claim.interestRate / 100 : 0
        tempClaim.treeDetails.events[eventId].eventDetails.interestDate =
          claim.interestDate!
      }
      if (claim.hasOutOfCourt) {
        tempClaim.treeDetails.events[
          eventId
        ].eventDetails.outOfCourtAmountSignum =
          claim.quantumScenarios[probabilityIndex].outOfCourtSignum

        if (
          claim.quantumScenarios[probabilityIndex].outOfCourtAmount !==
          undefined
        ) {
          tempClaim.treeDetails.events[eventId].eventDetails.outOfCourtAmount =
            claim.quantumScenarios[probabilityIndex].outOfCourtAmount!
        }
      }
      tempClaim.treeDetails.events[eventId].eventDetails.probability =
        probability / 100

      eventIndex++
      probabilityIndex++
    }
    let eventId = Object.keys(tempClaim.treeDetails.events)[eventIndex]
    if (claim.quantumProbabilities.length === 1) {
      delete tempClaim.treeDetails.events[eventId as EventId]
    }

    tempClaim.treeDetails = resetNodesPositions(
      tempClaim.nodeMode,
      tempClaim.treeDetails,
      0,
    )

    return tempClaim
  }
  return claim
}

export const getClaimWeightedValue = (claim: IndependentClaim | TreeClaim) => {
  if (claim.type !== ClaimType.tree) {
    let claimRes = 0

    if (claim.amount !== undefined) {
      for (const quantumScenario of claim.quantumScenarios) {
        if (
          !quantumScenario.liabilityOn &&
          quantumScenario.amount !== undefined
        ) {
          if (
            quantumScenario.outOfCourtSignum !== AmountSignum.off &&
            quantumScenario.outOfCourtAmount !== undefined
          ) {
            claimRes +=
              (quantumScenario.amount + quantumScenario.outOfCourtAmount) *
              quantumScenario.probability
          } else {
            claimRes += quantumScenario.amount * quantumScenario.probability
          }
        }
      }
    }

    return roundTo4Decimals(claimRes)
  } else {
    return (claim as TreeClaim).treeWeightedValue ?? 0
  }
}

export const calculateValueOfIndependentClaim = (
  claim: IndependentClaim,
  interest: InterestViewOption,
  firstTrialDate?: string,
  secondTrialDate?: string,
) => {
  let valueOfClaim = 0

  if (claim.amount !== undefined) {
    for (const quantumScenario of claim.quantumScenarios) {
      if (
        !quantumScenario.liabilityOn &&
        quantumScenario.amount !== undefined
      ) {
        let scenarioAmount = quantumScenario.amount
        if (
          interest === InterestViewOption.interest1st &&
          firstTrialDate &&
          claim.interestDate &&
          claim.interestRate &&
          calculateDateDifference(claim.interestDate, firstTrialDate) > 0
        ) {
          let days = calculateDateDifference(claim.interestDate, firstTrialDate)
          scenarioAmount +=
            (scenarioAmount * days * claim.interestRate) / 100 / 365
        }

        if (
          interest === InterestViewOption.interest2nd &&
          firstTrialDate &&
          secondTrialDate &&
          claim.interestDate &&
          claim.interestRate &&
          calculateDateDifference(firstTrialDate, secondTrialDate) > 0
        ) {
          let days = calculateDateDifference(
            claim.interestDate,
            secondTrialDate,
          )
          scenarioAmount +=
            (scenarioAmount * days * claim.interestRate) / 100 / 365
        }
        if (
          quantumScenario.outOfCourtSignum !== AmountSignum.off &&
          quantumScenario.outOfCourtAmount !== undefined
        ) {
          scenarioAmount += quantumScenario.outOfCourtAmount
        }
        valueOfClaim += scenarioAmount * quantumScenario.probability
      }
    }
  }

  return roundTo4Decimals(valueOfClaim)
}

export const getClaimListSortingMethod = (newSort: {
  option: 'ascending' | 'descending' | 'none'
  value: 'title' | 'client' | 'opposing' | 'weighted' | 'none'
}) => {
  switch (newSort.value) {
    case 'title':
      return (
        a: IndependentClaim | TreeClaim,
        b: IndependentClaim | TreeClaim,
      ) => {
        if (newSort.option === 'ascending') {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
        } else {
          return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1
        }
      }
    case 'client':
      return (
        a: IndependentClaim | TreeClaim,
        b: IndependentClaim | TreeClaim,
      ) => {
        let aValue =
          a.type === ClaimType.tree
            ? (a as TreeClaim).totalClaimedAmount ?? 0
            : a.type === ClaimType.claim
            ? a.amount ?? 0
            : 0
        let bValue =
          b.type === ClaimType.tree
            ? (b as TreeClaim).totalClaimedAmount ?? 0
            : b.type === ClaimType.claim
            ? b.amount ?? 0
            : 0

        if (aValue === bValue) {
          aValue =
            a.type === ClaimType.tree
              ? (a as TreeClaim).totalCounterClaimedAmount ?? 0
              : a.type === ClaimType.counterclaim
              ? a.amount ?? 0
              : 0
          bValue =
            b.type === ClaimType.tree
              ? (b as TreeClaim).totalCounterClaimedAmount ?? 0
              : b.type === ClaimType.counterclaim
              ? b.amount ?? 0
              : 0
        }

        if (newSort.option === 'ascending') {
          return aValue < bValue ? -1 : 1
        } else {
          return aValue > bValue ? -1 : 1
        }
      }
    case 'opposing':
      return (
        a: IndependentClaim | TreeClaim,
        b: IndependentClaim | TreeClaim,
      ) => {
        let aValue =
          a.type === ClaimType.tree
            ? (a as TreeClaim).totalCounterClaimedAmount ?? 0
            : a.type === ClaimType.counterclaim
            ? a.amount ?? 0
            : 0
        let bValue =
          b.type === ClaimType.tree
            ? (b as TreeClaim).totalCounterClaimedAmount ?? 0
            : b.type === ClaimType.counterclaim
            ? b.amount ?? 0
            : 0

        if (aValue === bValue) {
          aValue =
            a.type === ClaimType.tree
              ? (a as TreeClaim).totalClaimedAmount ?? 0
              : a.type === ClaimType.claim
              ? a.amount ?? 0
              : 0
          bValue =
            b.type === ClaimType.tree
              ? (b as TreeClaim).totalClaimedAmount ?? 0
              : b.type === ClaimType.claim
              ? b.amount ?? 0
              : 0
        }

        if (newSort.option === 'ascending') {
          return aValue < bValue ? -1 : 1
        } else {
          return aValue > bValue ? -1 : 1
        }
      }
    case 'weighted':
      return (
        a: IndependentClaim | TreeClaim,
        b: IndependentClaim | TreeClaim,
      ) => {
        if (newSort.option === 'ascending') {
          return getClaimWeightedValue(a) < getClaimWeightedValue(b) ? -1 : 1
        } else {
          return getClaimWeightedValue(a) > getClaimWeightedValue(b) ? -1 : 1
        }
      }
    default:
      return (
        a: IndependentClaim | TreeClaim,
        b: IndependentClaim | TreeClaim,
      ) => 1
  }
}

export function calculateDateDifference(
  startDateStr: string,
  endDateStr: string,
): number {
  const startDate = new Date(startDateStr)
  const endDate = new Date(endDateStr)

  // Calculate the difference in milliseconds
  const differenceInMs = endDate.getTime() - startDate.getTime()

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const differenceInDays = Math.floor(differenceInMs / (1000 * 60 * 60 * 24))

  return differenceInDays
}

export function findIndependentClaimedAndWeightedValues(
  claimInfo: IndependentClaim,
  firstTrialDate?: string,
  secondTrialDate?: string,
) {
  let claimAmount = claimInfo.amount ?? 0
  let totalClaimedAmountInterest1st = claimInfo.amount ?? 0
  let totalClaimedAmountInterest2nd = claimInfo.amount ?? 0

  if (claimInfo.interestDate && firstTrialDate && claimInfo.interestRate) {
    let daysFirst = calculateDateDifference(
      claimInfo.interestDate,
      firstTrialDate,
    )
    totalClaimedAmountInterest1st =
      claimAmount +
      (claimAmount * daysFirst * claimInfo.interestRate) / 100 / 365
  }
  if (claimInfo.interestDate && secondTrialDate && claimInfo.interestRate) {
    let daysSecond = calculateDateDifference(
      claimInfo.interestDate,
      secondTrialDate,
    )
    totalClaimedAmountInterest2nd =
      claimAmount +
      (claimAmount * daysSecond * claimInfo.interestRate) / 100 / 365
  }

  let weightedValue = calculateValueOfIndependentClaim(
    claimInfo,
    InterestViewOption.noInterest,
  )
  let weightedValueInterest1st = calculateValueOfIndependentClaim(
    claimInfo,
    InterestViewOption.interest1st,
    firstTrialDate,
  )
  let weightedValueInterest2nd = calculateValueOfIndependentClaim(
    claimInfo,
    InterestViewOption.interest2nd,
    firstTrialDate,
    secondTrialDate,
  )

  let claimedAndWeightedValues = {
    totalClaimedAmount: claimAmount,
    totalClaimedAmountInterest1st: totalClaimedAmountInterest1st,
    totalClaimedAmountInterest2nd: totalClaimedAmountInterest2nd,
    weightedValue: weightedValue,
    weightedValueInterest1st: weightedValueInterest1st,
    weightedValueInterest2nd: weightedValueInterest2nd,
  }

  return claimedAndWeightedValues
}

export function getQuantumScenarioAmountWithInterest(
  quantumScenarioAmount: number | undefined,
  interestViewOption: InterestViewOption,
  interestRate?: number,
  interestDate?: string,
  firstTrialDate?: string,
  secondTrialDate?: string,
) {
  let claimAmount = quantumScenarioAmount ?? 0
  switch (interestViewOption) {
    case InterestViewOption.noInterest:
      break
    case InterestViewOption.interest1st:
      if (
        interestDate &&
        firstTrialDate &&
        interestRate &&
        firstTrialDate > interestDate
      ) {
        let days = calculateDateDifference(interestDate, firstTrialDate)
        claimAmount += (claimAmount * days * interestRate) / 100 / 365
      }
      break
    case InterestViewOption.interest2nd:
      if (
        interestDate &&
        firstTrialDate &&
        secondTrialDate &&
        interestRate &&
        secondTrialDate > interestDate &&
        firstTrialDate > interestDate
      ) {
        let days = calculateDateDifference(interestDate, secondTrialDate)
        claimAmount += (claimAmount * days * interestRate) / 100 / 365
      }
      break
    default:
      break
  }

  return claimAmount
}

export function hasClaimAmount(claim: IndependentClaim | TreeClaim) {
  if (claim.type === 'tree') {
    return (
      (claim as TreeClaim).totalClaimedAmount !== undefined &&
      (claim as TreeClaim).totalClaimedAmount !== 0
    )
  } else {
    return claim.type === ClaimType.claim && claim.amount !== undefined
  }
}
export function hasCounterClaimAmount(claim: IndependentClaim | TreeClaim) {
  if (claim.type === 'tree') {
    return (
      (claim as TreeClaim).totalCounterClaimedAmount !== undefined &&
      (claim as TreeClaim).totalCounterClaimedAmount !== 0
    )
  } else {
    return claim.type === ClaimType.counterclaim && claim.amount !== undefined
  }
}

export function getClientAwardedAmountSum(
  currentSnapshot: ScenarioSnapshot,
  userSettings: UserSettings,
) {
  return formattedNumToString(
    Math.abs(
      roundNumberTo(
        currentSnapshot.claims
          .map((claim) => {
            let claimedAmount = 0

            if (claim.type === ClaimType.claim) {
              const independentClaimedAndWeightedValues =
                findIndependentClaimedAndWeightedValues(
                  claim,
                  currentSnapshot.firstTrialDate,
                  currentSnapshot.secondTrialDate,
                )
              switch (currentSnapshot.interestViewOption) {
                case InterestViewOption.noInterest:
                  claimedAmount =
                    independentClaimedAndWeightedValues.totalClaimedAmount
                  break
                case InterestViewOption.interest1st:
                  claimedAmount =
                    independentClaimedAndWeightedValues.totalClaimedAmountInterest1st
                  break
                case InterestViewOption.interest2nd:
                  claimedAmount =
                    independentClaimedAndWeightedValues.totalClaimedAmountInterest2nd

                  break
                default:
                  break
              }
            } else if (
              claim.type === ClaimType.tree &&
              (claim as TreeClaim).analysisResults &&
              (claim as TreeClaim).totalClaimedAmount
            ) {
              switch (currentSnapshot.interestViewOption) {
                case InterestViewOption.noInterest:
                  claimedAmount = (claim as TreeClaim).totalClaimedAmount!
                  break
                case InterestViewOption.interest1st:
                  claimedAmount =
                    (claim as TreeClaim).totalClaimedAmountInterest1st ??
                    (claim as TreeClaim).totalClaimedAmount!
                  break
                case InterestViewOption.interest2nd:
                  claimedAmount =
                    (claim as TreeClaim).totalClaimedAmountInterest2nd ??
                    (claim as TreeClaim).totalClaimedAmount!
                  break
                default:
                  break
              }
            }

            return claimedAmount
          })
          .reduce((sum, value) => sum + value, 0),
        2,
      ),
    ),
    userSettings,
  )
}

export function getOpposingPartyAwardedAmountSum(
  currentSnapshot: ScenarioSnapshot,
  userSettings: UserSettings,
) {
  return formattedNumToString(
    Math.abs(
      roundNumberTo(
        currentSnapshot.claims
          .map((claim) => {
            let counterClaimedAmount = 0
            if (claim.type === ClaimType.counterclaim) {
              const independentClaimedAndWeightedValues =
                findIndependentClaimedAndWeightedValues(
                  claim,
                  currentSnapshot.firstTrialDate,
                  currentSnapshot.secondTrialDate,
                )
              switch (currentSnapshot.interestViewOption) {
                case InterestViewOption.noInterest:
                  counterClaimedAmount =
                    independentClaimedAndWeightedValues.totalClaimedAmount
                  break
                case InterestViewOption.interest1st:
                  counterClaimedAmount =
                    independentClaimedAndWeightedValues.totalClaimedAmountInterest1st
                  break
                case InterestViewOption.interest2nd:
                  counterClaimedAmount =
                    independentClaimedAndWeightedValues.totalClaimedAmountInterest2nd

                  break
                default:
                  break
              }
            } else if (
              claim.type === ClaimType.tree &&
              (claim as TreeClaim).analysisResults &&
              (claim as TreeClaim).totalCounterClaimedAmount
            ) {
              switch (currentSnapshot.interestViewOption) {
                case InterestViewOption.noInterest:
                  counterClaimedAmount = (claim as TreeClaim)
                    .totalCounterClaimedAmount!
                  break
                case InterestViewOption.interest1st:
                  counterClaimedAmount =
                    (claim as TreeClaim).totalCounterClaimedAmountInterest1st ??
                    (claim as TreeClaim).totalCounterClaimedAmount!
                  break
                case InterestViewOption.interest2nd:
                  counterClaimedAmount =
                    (claim as TreeClaim).totalCounterClaimedAmountInterest2nd ??
                    (claim as TreeClaim).totalCounterClaimedAmount!
                  break
                default:
                  break
              }
            }

            return counterClaimedAmount
          })
          .reduce((sum, value) => sum + value, 0),
        2,
      ),
    ),
    userSettings,
  )
}
