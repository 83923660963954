/* 
This function is finds the level of a ClaimObject or a Scenario by its name. It finds all letters in the name given and returns the biggest letter. This is the level we are looking for.
*/

export function findLevelFromName(name) {
  /* The biggest letter in a name of a claim or scenario defines the levelLetter*/
  var biggestLetter = ''
  for (var i = 0; i < name.length; i++) {
    if (name[i] >= 'A' && name[i] <= 'z') {
      if (name[i] > biggestLetter) biggestLetter = name[i]
    }
  }
  return biggestLetter
}
/* 
This function takes the letter that describes the level of an Element and transforms it in Index. levelIndex for 'A' is 0, for 'B' is 1, for 'C' is 2 etc.
*/

export function findLevelIndexFromLevelLetter(levelLetter) {
  /* 
  It uses the levelLetter and gives the index of that letter
  */
  var levelIndex = levelLetter.charCodeAt(0) - 65

  return levelIndex
}

export function findParent(scenarioId) {
  /* 
  I am using the name of the scenario to define its previous level parent
  DONE fix the new format
  */

  if (scenarioId.length > 9) {
    return scenarioId.substring(0, scenarioId.length - 9)
  } else {
    return undefined
  }
}

export function findParentId(claimObjectId) {
  /* 
  I am using the name of the scenario to define its previous level parent
  DONE fix the new format
  */

  if (claimObjectId.length > 9) {
    let parentIdIndex =
      parseInt(
        claimObjectId.substring(
          claimObjectId.length - 8,
          claimObjectId.length - 5,
        ),
      ) - 1

    return claimObjectId.substring(0, claimObjectId.length - 8) + parentIdIndex
  } else {
    return undefined
  }
}

export function findTreeOfKey(key, claimsTree) {
  var path = findPathFromName(key)
  var level = path.length
  var treeOfKey = {}

  function findTree(tree, level) {
    if (level > 1) {
      findTree(tree[path[path.length - level]], level - 1)
    } else {
      treeOfKey = { [key]: tree[key] }
    }
  }

  findTree(claimsTree, level)

  return treeOfKey
}

export function findFinalScenarios(claimsTree) {
  var tempClaimsTree = JSON.parse(JSON.stringify(claimsTree))

  /* 
  finalScenarios is an object that will have as keys all the final Scenarios of the given tree. I am using an object and not an array, because it is easier to delete the unwanted scenarios in a later stage.
  */
  var finalScenarios = {}

  /* 
  I am using the recursive function findAllChildren to find all the final scenarios of a given tree or sub-tree. I am searching for all the keys in the tree that have the property 'finalScenario'=true.
  */
  function findAllChildren(tree) {
    for (let claimName in tree) {
      if (claimName.startsWith('A')) {
        for (let scenario in tree[claimName]) {
          if (scenario.startsWith('A')) {
            if (tree[claimName][scenario]['finalScenario']) {
              finalScenarios[scenario] = true
            }
            findAllChildren(tree[claimName][scenario])
          }
        }
      }
    }
  }

  findAllChildren(tempClaimsTree)

  return finalScenarios
}

export function findAllScenariosOfFirstChildrenInTree(tree, headObject) {
  /* A function that returns an object that contains all the final scenarios of the given sub-tree */
  var finalScenariosOfTree = findFinalScenarios(tree)

  /* 
  For every final Scenario in finalScenariosOfTree I am doing a checking in the name of the scenario that defines if this scenario belongs to a first Children.

  In order for a scenario to belong in a first child, it has to have in his name number 1 in the position after every letter. For example A1.1-B1.1 belongs to a first child, but A1.1-B2.1 doesn't, because it has number 2 after B.
  
  If the scenario doesn't belong to a first child I delete it from the finalScenariosOfTree, so this function only returns the scenarios of the first children.

  I am setting as first i = headObject.length + 4, so that this functions checks the scenarios of first children in the tree that starts with the headObject.By that way I can find the first Children of a Claim that is not a first Child. I need that for the recursion, in order to find the cousins of the cousins of the cousins etc.


   
  */
  for (let finalScenario in finalScenariosOfTree) {
    for (let i = headObject.length + 4; i < finalScenario.length; i += 5) {
      if (finalScenario.charAt(i) !== '1') {
        delete finalScenariosOfTree[finalScenario]
      }
    }
  }
  return finalScenariosOfTree
}

export function findPathFromName(name) {
  //DONE change the slice and step to be compatible with the new format of '001'
  var path = []
  for (let i = 1; i < name.length; i += 9) {
    path.push(name.slice(0, i + 3))
    if (name.slice(0, i + 3) === name) {
      return path
    } else {
      path.push(name.slice(0, i + 7))
    }
  }

  return path
}

export function changeCurrency(currencyNum) {
  var currencyArray = ['SEK', 'MSEK', 'NOK', 'MNOK', '€', 'M€', '$', 'M$']
  var tempCurrencyNum = currencyNum + 1

  if (tempCurrencyNum === currencyArray.length) {
    tempCurrencyNum = 0
  }
  return currencyArray[tempCurrencyNum]
}

export function claimsPath(claimObjectId, levelNum, numOfLevels) {
  /* 
  07/07/2021
This function creates the path until that ClaimObject, including the ClaimObject. 
It has the parameters: 
claimObjectID (the name of the ClaimObject that is going to be generated)
levelNum (the index that correpsonds to the level of the ClaimObject, which is the same as independentClaimIndex).

It is called from claimsPath prop in each ClaimObject Component that is generated in IndependentClaim.js.

STEPS:

Finally,it pushes the claimObjectId and sorts the array from shortest to largest string, so the order of claimObjects is correct.
*/
  //Step 1: creates the path array that will contain all the names of claimObjects and scenarios until the claimObjectId that calls this function
  var path = []
  //Step 2: Maps the numOfLevels array and checks if claimObjectId string includes the elements of numOfLevels.
  //If yes, it creates the correct substring of claimObject to represent its parent and pushes it in the path.
  for (let i = 0; i < levelNum; i++) {
    for (let j = 0; j < numOfLevels[i].length; j++) {
      if (claimObjectId.includes(numOfLevels[i][j])) {
        path.push(`${numOfLevels[i][j]}`)
        //TO DO fix the scenarioId to have the format '001'
        if (i === 0) {
          var scenarioId = claimObjectId.substring(0, claimObjectId.length - 3)
        } else {
          //TO DO fix the scenarioId to have the format '001'
          scenarioId = numOfLevels[i][j]
            .toString()
            .substring(0, numOfLevels[i][j].length - 3)
        }
        path.push(scenarioId)
      }
    }
  }

  //Step 3: It pushes the current claimObjectId in the path
  path.push(`${claimObjectId}`)
  //Step 4: It sorts the path from smallest name to biggest
  path.sort(function (a, b) {
    // ASC  -> a.length - b.length
    // DESC -> b.length - a.length
    return a.length - b.length
  })

  //Step 5: returns the path array
  return path
}

export function changeProbabilities(
  tempScenarioPercentagesOfClaim,
  scenarioIndex,
  value,
) {
  const numberOfScenarios = tempScenarioPercentagesOfClaim.length
  //Step 1: finds the sum of probabilities before the scenarioIndex
  var sumOfProbabilitiesBefore = 0
  for (let i = 0; i < scenarioIndex; i++) {
    sumOfProbabilitiesBefore += tempScenarioPercentagesOfClaim[i]
  }

  //Rule 1:
  if (value > 100 - sumOfProbabilitiesBefore) {
    tempScenarioPercentagesOfClaim[scenarioIndex] =
      100 - sumOfProbabilitiesBefore
    for (let i = scenarioIndex + 1; i < numberOfScenarios; i++) {
      tempScenarioPercentagesOfClaim[i] = 0
    }
  }
  //Rule 2:
  else if (value > tempScenarioPercentagesOfClaim[scenarioIndex]) {
    for (let i = scenarioIndex + 1; i < numberOfScenarios; i++) {
      if (tempScenarioPercentagesOfClaim[i] > 0) {
        if (value - tempScenarioPercentagesOfClaim[scenarioIndex] === 0) {
          break
        } else if (
          value - tempScenarioPercentagesOfClaim[scenarioIndex] >
          tempScenarioPercentagesOfClaim[i]
        ) {
          tempScenarioPercentagesOfClaim[scenarioIndex] +=
            tempScenarioPercentagesOfClaim[i]
          tempScenarioPercentagesOfClaim[i] = 0
        } else if (
          value - tempScenarioPercentagesOfClaim[scenarioIndex] <=
          tempScenarioPercentagesOfClaim[i]
        ) {
          tempScenarioPercentagesOfClaim[i] -=
            value - tempScenarioPercentagesOfClaim[scenarioIndex]
          tempScenarioPercentagesOfClaim[scenarioIndex] = value
          break
        }
      }
    }
  }
  //Rule 3:
  else if (value < tempScenarioPercentagesOfClaim[scenarioIndex]) {
    tempScenarioPercentagesOfClaim[scenarioIndex + 1] +=
      tempScenarioPercentagesOfClaim[scenarioIndex] - value
    tempScenarioPercentagesOfClaim[scenarioIndex] = value
  }

  return tempScenarioPercentagesOfClaim
}

export function numberTo3DigitFormat(number) {
  if (number < 10) {
    return '00' + number
  } else if (number < 100) {
    return '0' + number
  } else if (number < 1000) {
    return number
  }
}

export function moveChildOneLevelDown(objectId, middleChildId) {
  var currentLevel = objectId.charAt(middleChildId.length - 4)
  var index = middleChildId.length
  var objectIdArray = objectId.split('')

  if (currentLevel === '.') {
    //It means the objectId is a scenarioId
    currentLevel = objectId.charAt(middleChildId.length - 8)
  }
  var levelNum = currentLevel.charCodeAt(0)
  var nextLevel = String.fromCharCode(levelNum + 1)

  objectIdArray.splice(
    index,
    0,
    '.',
    '0',
    '0',
    '1',
    '-',
    nextLevel,
    '0',
    '0',
    '1',
  )

  for (let i = index + 9; i < objectIdArray.length; i++) {
    if (
      objectIdArray[i].charCodeAt(0) >= 65 &&
      objectIdArray[i].charCodeAt(0) <= 122
    ) {
      var nextLevelLetter = String.fromCharCode(
        objectIdArray[i].charCodeAt(0) + 1,
      )
      objectIdArray[i] = nextLevelLetter
    }
  }

  var newClaimObjectId = objectIdArray.join('')

  return newClaimObjectId
}

export const keyExists = (obj, key) => {
  if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
    return false
  } else if (obj.hasOwnProperty(key)) {
    return true
  } else if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      const result = keyExists(obj[i], key)
      if (result) {
        return result
      }
    }
  } else {
    for (const k in obj) {
      const result = keyExists(obj[k], key)
      if (result) {
        return result
      }
    }
  }

  return false
}

export function resetClaimObjectsPositions(claimsTree) {
  for (let claimName in claimsTree) {
    if (claimName.startsWith('A')) {
      claimsTree[claimName]['properties'] = { position: [0, 0] }
      for (let scenario in claimsTree[claimName]) {
        if (scenario.startsWith('A')) {
          resetClaimObjectsPositions(claimsTree[claimName][scenario])
        }
      }
    }
  }
  return claimsTree
}
