import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { useRecoilState, useRecoilValue } from 'recoil'
import { AccessRole, ClaimType, VideoPostName } from '../../../../models/enums'
import { getText } from '../../../../services/textFunctions'
import VideoInfoButton from '../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { TreeClaim } from '../../../../models/treeModels/treeClaim'
import { userState } from '../../../../states/UserState'
import NewLegalFeesDistributionApp from '../../../../Components/LegalFeesDistribution/NewLegalFeesDistributionApp'
import { recoveryModeState } from '../../../../states/RecoveryModeState'
import { viewerState } from '../../../../states/ViewerState'
import NoLegalCostsIncluded from '../../../../Components/LegalFeesDistribution/LegalFeesDistributionComponents/NoLegalCostsIncluded'
import {
  hasRecoverableLegalCosts,
  legalFeesZero,
} from '../../../../services/legalCostsFunctions'
import { freemiumState } from '../../../../states/FreemiumState'
import { logActivity } from '../../../../services/commonFunctions'

type Props = {
  calculateAllTrees: () => Promise<void>
  setVideoPostName: (param: VideoPostName | undefined) => void
  ownRole: AccessRole
}

export default function LegalFeesDistribution(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)

  const hasAllDisputedAmounts =
    scenarioSnapshot.currentSnapshot.claims.filter(
      (claim) =>
        claim.type === ClaimType.tree &&
        ((claim as TreeClaim).totalClaimedAmount === undefined ||
          (claim as TreeClaim).totalCounterClaimedAmount === undefined ||
          (claim as TreeClaim).treeWeightedValue === undefined),
    ).length === 0

  return (
    <div className="tool-component" id="legalFeesDistributionComp">
      <h2
        className="tool-component-title"
        id="legalCostsDistribution-title"
        data-textattribute="title-37"
      >
        {getText('title-37', user.settings)}
        <VideoInfoButton
          textAttribute="tooltip-19"
          postName={VideoPostName.legalCostsDistribution}
          setVideoPostName={props.setVideoPostName}
        />
      </h2>
      <p
        className="tool-component-subtitle marginLeft1"
        id="legalCostsDistribution-description"
        data-textattribute="description-43"
      >
        {getText('description-43', user.settings)}
      </p>
      {hasAllDisputedAmounts ? (
        <>
          {props.ownRole === AccessRole.VIEWER && (
            <div
              className="legalFeesDistributionTransparentLayer"
              onClick={() => {
                if (recoveryMode.recoveryPreview !== 'none') {
                  setRecoveryMode({ ...recoveryMode, shaking: true })
                } else {
                  setIsViewer({ ...isViewer, shaking: true })
                }
              }}
            ></div>
          )}
          <div className="legalFeesDistributionDetails">
            <NewLegalFeesDistributionApp ownRole={props.ownRole} />
          </div>
          {legalFeesZero(scenarioSnapshot.currentSnapshot) ||
          hasRecoverableLegalCosts(scenarioSnapshot.currentSnapshot) ===
            false ? (
            <NoLegalCostsIncluded />
          ) : null}
        </>
      ) : (
        <button
          type="button"
          className="legalFeesDistributionAnalysisButton"
          onClick={() => {
            props.calculateAllTrees()
            //Mixpanel 16 (All)
            logActivity(
              freemium.isFreemium,
              'Calculated disputed amount for legal cost distribution',
            )
          }}
        >
          <p
            className={`legalFeesDistributionAnalysisButtonText`}
            id="legalFeesDistributionAnalysisButtonText"
            data-textattribute="button-32"
          >
            {getText('button-32', user.settings)}
          </p>
        </button>
      )}
    </div>
  )
}
