import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Button from '../../Components/Buttons/Button/Button'
import ListPageTemplate from '../../Components/pageTemplates/ListPageTemplate'

import {
  SnapshotStatus,
  snapshotStatusFromString,
  UserRole,
  UserRoleKeys,
} from '../../models/enums'
import {
  ListItem,
  Params,
  ScenarioIdentity,
  UserForAccess,
} from '../../models/generalTypes'
import { cancelJob, getScenario } from '../../services/requests'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import ProgressBar from '../../Components/ProgressBar/ProgressBar'
import {
  createIdentityFromDb,
  createScenarioFromDb,
  findHandlingErrorState,
  hasWarningMessage,
  logActivity,
} from '../../services/commonFunctions'
import { pageDepthState } from '../../states/PageDepthState'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import { onlineState } from '../../states/OnlineState'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'
import { freemiumState } from '../../states/FreemiumState'
import FreemiumListPageTemplate from '../../Components/pageTemplates/FreemiumListPageTemplate'
import { FreemiumListItem } from '../../models/freemiumGeneralTypes'
import {
  createIdentityFromStorage,
  freemiumCreateIdentityFromDb,
  getScenarioFromStorage,
  saveFreemiumResults,
} from '../../freemiumServices/freemiumServices'
import {
  cancelFreemiumJob,
  getFreemiumJob,
} from '../../freemiumServices/freemiumRequests'
import { FREEMIUM_CASE_ID } from '../../freemiumServices/freemiumConstants'

type Props = {
  showDeleted?: boolean
}

const MyScenarios = (props: Props) => {
  const user = useRecoilValue(userState)

  const [scenarioIdentities, setScenarioIdentities] = useState<
    ScenarioIdentity[]
  >([])
  const [cancellingMessage, setCancellingMessage] = useState<
    string | undefined
  >()
  const [caseName, setCaseName] = useState('')
  const [tutorialId, setTutorialId] = useState<string | undefined>(undefined)
  const [usersListWhenNoScenarios, setUsersListWhenNoScenarios] = useState<
    UserForAccess[] | undefined
  >(undefined)
  const statusIntervals = useRef<(NodeJS.Timeout | null)[]>([])
  const navigate = useNavigate()
  const setPageDepth = useSetRecoilState(pageDepthState)
  const [online, setOnline] = useRecoilState(onlineState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [cancelBecauseOfUpdateMessage, setCancelBecauseOfUpdateMessage] =
    useState(false)
  const freemium = useRecoilValue(freemiumState)
  const params = useParams<Params>()
  const [freemiumGetJob, setFreemiumGetJob] = useState(false)

  useEffect(() => {
    if (props.showDeleted) {
      setPageDepth(2.1)
    } else {
      setPageDepth(2)
    }
    // eslint-disable-next-line
  }, [props.showDeleted])

  useEffect(() => {
    statusIntervals.current = scenarioIdentities.map((item, index) => {
      if (!freemium.isFreemium) {
        return addInterval(item, index)
      }
      if (params.caseId === FREEMIUM_CASE_ID) {
        return addFreemiumInterval(item, index)
      }

      return null
    })

    return () => {
      statusIntervals.current = statusIntervals.current.map((inter) => {
        if (inter) {
          clearInterval(inter)
        }

        return null
      })
    }
    // eslint-disable-next-line
  }, [scenarioIdentities, freemiumGetJob])

  const navigateToNextPage = (uuid: string) => {
    navigate(`${uuid}/tool`)
  }

  const getScenarioStatus = async (item: ScenarioIdentity) => {
    const res = await getScenario(item.caseId, item.scenarioId)
    /*  setHandlingErrors(
      findHandlingErrorState(res, handlingErrors, 'getScenarioStatus'),
    ) */
    if ('errorCode' in res) {
      return undefined
    }

    return res
  }

  const addInterval = (item: ScenarioIdentity, index: number) => {
    if (
      statusIntervals.current[index] &&
      (item.snapshotStatus === SnapshotStatus.Queued ||
        item.snapshotStatus === SnapshotStatus.Processing ||
        item.snapshotStatus === SnapshotStatus.ProcessingReversed)
    ) {
      return statusIntervals.current[index]
    }

    if (
      item.snapshotStatus === SnapshotStatus.Queued ||
      item.snapshotStatus === SnapshotStatus.Processing ||
      item.snapshotStatus === SnapshotStatus.ProcessingReversed
    ) {
      return setInterval(() => {
        getScenarioStatus(item).then((res) => {
          if (res) {
            updateIdentity(res)
            if (
              res.data.job_status === 'Queued' &&
              hasWarningMessage(
                res,
                'Job restarted due to server maintenance script.',
              )
            ) {
              setCancelBecauseOfUpdateMessage(true)
            }
          }
        })
      }, 2000)
    } else {
      if (statusIntervals.current[index]) {
        clearInterval(statusIntervals.current[index]!)
      }
      return null
    }
  }

  const addFreemiumInterval = (item: ScenarioIdentity, index: number) => {
    if (
      statusIntervals.current[index] &&
      (item.snapshotStatus === SnapshotStatus.Queued ||
        item.snapshotStatus === SnapshotStatus.Processing ||
        item.snapshotStatus === SnapshotStatus.ProcessingReversed)
    ) {
      return statusIntervals.current[index]
    }

    if (
      item.snapshotStatus === SnapshotStatus.Queued ||
      item.snapshotStatus === SnapshotStatus.Processing ||
      item.snapshotStatus === SnapshotStatus.ProcessingReversed ||
      !freemiumGetJob
    ) {
      return setInterval(() => {
        getFreemiumJob().then((res) => {
          setFreemiumGetJob(true)
          if (!('errorCode' in res)) {
            updateIdentity(res, index)
            if (res.data.result_data && res.data.status === 'Done') {
              saveFreemiumResults(res.data.result_data)
            }

            if (
              res.data.status === 'Queued' &&
              hasWarningMessage(
                res,
                'Job restarted due to server maintenance script.',
              )
            ) {
              setCancelBecauseOfUpdateMessage(true)
            }
          }
        })
      }, 2000)
    } else {
      if (statusIntervals.current[index]) {
        clearInterval(statusIntervals.current[index]!)
      }
      return null
    }
  }

  const nestedLink = (uuid: string) => {
    return (
      <Link to={`${uuid}/tool`}>
        <Button
          id={`myScenariosResultsButton${uuid}`}
          buttonText={getText('button-20', user.settings)}
          buttonTextAttribute="button-20"
          buttonType="contained"
          type="button"
          onClick={() => {}}
          className="saveCaseButton"
          small
          side="left"
          NoUpperCase={true}
        />
      </Link>
    )
  }

  const createScenariosList = (res: any, role: UserRole) => {
    const identities: ScenarioIdentity[] = []
    const items: ListItem[] = []

    if (res.data.caseName) {
      setCaseName(res.data.caseName)
      setTutorialId(res.data.tutorial_id)
      if (!props.showDeleted) {
        setUsersListWhenNoScenarios(
          res.data.users.map((userObj: any) => ({
            first_name: userObj.first_name,
            last_name: userObj.last_name,
            user_role: UserRole[userObj.user_role as UserRoleKeys],
            username: userObj.username,
            access_role: userObj.access_role,
            self: user.email === userObj.username,
          })),
        )
      }
    } else {
      res.data.forEach((item: any) => {
        items.push(createScenarioFromDb(item, role, user))
        identities.push(createIdentityFromDb(item))
      })

      setScenarioIdentities(identities)
    }

    return items
  }

  const createScenario = (
    res: any,
    role: UserRole,
    type: 'Case' | 'Scenario',
    noIdentity?: boolean,
  ) => {
    if (noIdentity) {
      return createScenarioFromDb(res.data, role, user)
    }

    const tempIdentities = [
      ...scenarioIdentities,
      createIdentityFromDb(res.data),
    ]

    setScenarioIdentities(tempIdentities)

    return createScenarioFromDb(res.data, role, user)
  }

  const createFreemiumScenarioFromDb = (item: any) => {
    return {
      uuid: item.caseid,
      title: item.name,
      dateCreated: item.time_case_created,
      status: item.job_status
        ? snapshotStatusFromString(item.job_status)
        : SnapshotStatus.None,
      version: item.version,
      tutorialId: item.tutorial_id,
    }
  }

  const createFreemiumScenario = (res: any, noIdentity?: boolean) => {
    if (noIdentity) {
      return createFreemiumScenarioFromDb(res.data)
    }

    const tempIdentities = [
      ...scenarioIdentities,
      createIdentityFromDb(res.data),
    ]

    setScenarioIdentities(tempIdentities)

    return createFreemiumScenarioFromDb(res.data)
  }

  const createFreemiumScenariosList = (res: any) => {
    const identities: ScenarioIdentity[] = []
    const items: FreemiumListItem[] = []

    if (res.data && res.data.caseName) {
      setCaseName(res.data.caseName)
      setTutorialId(res.data.tutorial_id)
    } else {
      if (res.data) {
        res.data.forEach((item: any) => {
          items.push(createFreemiumScenarioFromDb(item))
          identities.push(createIdentityFromDb(item))
        })
      } else {
        const storageScenario = getScenarioFromStorage()
        if (storageScenario) {
          items.push({
            uuid: storageScenario.scenarioId!,
            title: storageScenario.scenarioName!,
            dateCreated: storageScenario.scenarioDateCreated!,
            status: SnapshotStatus.None,
            version: storageScenario.snapshot!.version,
          })

          identities.push(createIdentityFromStorage(storageScenario))
        }
      }

      setScenarioIdentities(identities)
    }

    return items
  }

  const updateIdentity = (res: any, givenIndex?: number) => {
    const newIdentity = freemium.isFreemium
      ? freemiumCreateIdentityFromDb(
          res.data,
          scenarioIdentities[givenIndex ?? 0],
        )
      : createIdentityFromDb(res.data)

    const index = scenarioIdentities.findIndex(
      (item) => item.scenarioId === newIdentity.scenarioId,
    )

    if (
      scenarioIdentities[index].snapshotStatus !== newIdentity.snapshotStatus ||
      scenarioIdentities[index].snapshotProgress !==
        newIdentity.snapshotProgress
    ) {
      const tempIdentities = [...scenarioIdentities]
      tempIdentities[index] = newIdentity

      setScenarioIdentities(tempIdentities)
    }
  }

  const stopAnalysis = async (scenarioId: string, snapshotId: string) => {
    if (online.networkOn) {
      //id='stoppingAnalysisMessage-MyScenarios'
      //data-textattribute='message-1'
      setCancellingMessage(getText('message-1', user.settings))

      const res = freemium.isFreemium
        ? await cancelFreemiumJob()
        : await cancelJob(scenarioIdentities[0].caseId, scenarioId, snapshotId)
      setHandlingErrors(
        findHandlingErrorState(res, handlingErrors, 'cancelJob', true),
      )
      if (!('errorCode' in res)) {
        //id='stoppedAnalysisMessage-myScenarios'
        //data-textattribute='message-3'
        setCancellingMessage(getText('message-3', user.settings))
        //Mixpanel 68 (All)
        logActivity(freemium.isFreemium, 'Stopped a scenario analysis', {
          'Stopped Analysis Location': 'My Scenarios',
        })
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const getNavigateLink = (uuid: string) => {
    return `${uuid}/tool`
  }

  const statusDiv = (uuid: string) => {
    const identity = scenarioIdentities.find(
      (value) => value.scenarioId === uuid,
    )

    if (identity) {
      switch (identity.snapshotStatus) {
        case SnapshotStatus.Done:
          return nestedLink(uuid)
        case SnapshotStatus.Processing:
        case SnapshotStatus.ProcessingReversed:
        case SnapshotStatus.Queued:
          return (
            <ProgressBar
              stopAnalysis={stopAnalysis}
              cancellingMessage={cancellingMessage}
              setCancellingMessage={setCancellingMessage}
              scenarioIdentity={identity}
              cancelBecauseOfUpdateMessage={cancelBecauseOfUpdateMessage}
            />
          )
        case SnapshotStatus.Failed:
          return (
            <>
              <Link to={`${uuid}/tool`}>
                <Button
                  id={`myScenariosFailedButton${uuid}`}
                  buttonText={getText('button-21', user.settings)}
                  buttonTextAttribute="button-21"
                  buttonType="contained"
                  backGroundColor="#FEDDDD" //laCandyfloss
                  textColor="#331a5b" //lakersUniform
                  type="button"
                  onClick={() => {}}
                  className="saveCaseButton"
                  small
                  side="left"
                  NoUpperCase={true}
                />
              </Link>
            </>
          )
        case SnapshotStatus.None:
          return (
            <p
              className="scenarioDetail"
              id="scenarioDetailWithNoResults"
              data-textattribute="description-8"
            >
              {getText('description-8', user.settings)}
            </p>
          )
        default:
          return (
            <p className="scenarioDetail">
              {identity.snapshotStatus.toString()}
            </p>
          )
      }
    }

    return <></>
  }

  return freemium.isFreemium ? (
    <FreemiumListPageTemplate
      itemName="Scenario"
      getNavigateLink={getNavigateLink}
      createItem={createFreemiumScenario}
      createItemList={createFreemiumScenariosList}
      isMyScenariosPage
      statusDiv={statusDiv}
      caseName={
        caseName !== ''
          ? caseName
          : scenarioIdentities.length > 0
          ? scenarioIdentities[0].caseName
          : undefined
      }
      tutorialId={tutorialId}
      navigateToNextPage={navigateToNextPage}
    />
  ) : (
    <ListPageTemplate
      itemName="Scenario"
      getNavigateLink={getNavigateLink}
      createItem={createScenario}
      createItemList={createScenariosList}
      isMyScenariosPage
      statusDiv={statusDiv}
      caseName={
        caseName !== ''
          ? caseName
          : scenarioIdentities.length > 0
          ? scenarioIdentities[0].caseName
          : undefined
      }
      tutorialId={tutorialId}
      usersListWhenNoScenarios={usersListWhenNoScenarios}
      setUsersListWhenNoScenarios={setUsersListWhenNoScenarios}
      navigateToNextPage={navigateToNextPage}
      showDeleted={props.showDeleted}
    />
  )
}

export default MyScenarios
