import React, { useEffect, useRef, useState } from 'react'
import Button from '../../Buttons/Button/Button'
import plus from '../../../resources/images/003-plusSignThinWhite.svg'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { useRecoilValue } from 'recoil'
import InputText from '../../Inputs/InputText/InputText'
import { CircularProgress } from '@mui/material'
import smallDeleteImg from '../../../resources/images/219-smallDeleteImg.svg'
import createTutorialsImg from '../../../resources/images/220-createTutorialsImg.svg'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import DeleteWindow from '../../DeleteWindow/DeleteWindow'
import PasteClaimButton from '../../../pages/toolPage/tabs/2_claims/claimsTile/PasteClaimButton'

type Props = {
  isAddDisabled: boolean
  addNewItem: boolean
  setAddNewItem: (param: boolean) => void
  handleAddNewItem: () => void
  handleCreateTutorials: () => void
  nameInput: string
  setNameInput: (param: string) => void
  itemName: 'Scenario' | 'Case'
  isMyScenariosPage?: boolean
  addNewCaseContainerRef: React.RefObject<HTMLDivElement>
  requestLoading: string | undefined
  navigateToDeleted: () => void
  widthOfCaseContainer?: number
  tutorialId?: string
  nothingToPaste: boolean
  handlePasteItem: () => {}
}

const BottomPart = (props: Props) => {
  const user = useRecoilValue(userState)
  const [createTutorialCoordinates, setCreateTutorialCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const deletedButtonRef = useRef<undefined | HTMLButtonElement>(undefined)
  const tutorialsButtonRef = useRef<undefined | HTMLButtonElement>(undefined)
  const [isPasteHovered, setIsPasteHovered] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [createTutorialCoordinates])

  const handleBottomActionsTooltips = (forDelete?: boolean) => {
    const button = document.getElementById(
      forDelete ? 'deletedCasesButton' : 'createTutorialsButton',
    )
    if (button) {
      let viewportOffset = button.getBoundingClientRect()

      if (viewportOffset) {
        // these are relative to the viewport, i.e. the window
        let top = viewportOffset.top + 30
        let left = viewportOffset.left - 320
        let h = window.innerHeight
        if (h - top < 200) {
          top -= 130
        }
        setCreateTutorialCoordinates([top, left])
      }
    }
  }

  const onMouseDown = (e: MouseEvent) => {
    if (e.target) {
      const id = (e.target as HTMLImageElement).id
      if (createTutorialCoordinates && !id.includes('tutorial')) {
        setCreateTutorialCoordinates(undefined)
      }
    }
  }

  return (
    <>
      <div
        className={`addNewCaseContainer ${
          props.isAddDisabled ? 'onlyContainer' : ''
        }`}
        style={{ width: props.widthOfCaseContainer }}
        ref={props.addNewCaseContainerRef}
      >
        {!props.addNewItem ? (
          <>
            {!props.isAddDisabled && (
              <div className="createCaseContainer">
                <Button
                  id={'addNewCaseButton'}
                  buttonText={
                    props.itemName === 'Case'
                      ? getText('button-9', user.settings)
                      : getText('button-10', user.settings)
                  }
                  buttonTextAttribute={
                    props.itemName === 'Case' ? 'button-9' : 'button-10'
                  }
                  buttonType="contained"
                  type="button"
                  icon={plus}
                  className="addNewCaseButton"
                  style={!props.addNewItem ? undefined : { display: 'none' }}
                  onClick={() => {
                    props.setAddNewItem(true)
                    setTimeout(() => {
                      if (document.getElementById('newCaseName')) {
                        document.getElementById('newCaseName')?.focus()
                      }
                    }, 100)
                  }}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
                {props.isMyScenariosPage && (
                  <PasteClaimButton
                    onClick={props.handlePasteItem}
                    onMouseEnter={() => {
                      setIsPasteHovered(true)
                    }}
                    onMouseLeave={() => {
                      setIsPasteHovered(false)
                    }}
                    nothingToPaste={props.nothingToPaste}
                    isHovered={isPasteHovered}
                    forScenario
                  />
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className="createCaseContainer">
              <div className="newCaseInputContainer">
                <InputText
                  label={
                    props.isMyScenariosPage
                      ? getText('label-1', user.settings)
                      : getText('label-2', user.settings)
                  }
                  labelTextAttribute={
                    props.isMyScenariosPage ? 'label-1' : 'label-2'
                  }
                  id="newCaseName"
                  className="newCaseInput"
                  name="newCaseName"
                  value={props.nameInput}
                  onChange={(e: any) => {
                    props.setNameInput(e.target.value)
                  }}
                  smallField
                  width={300}
                  maxLength={70}
                  noDelay
                />
              </div>
              <Button
                id="createCaseButton"
                buttonText={
                  props.requestLoading === 'createCaseButton' ? (
                    <div className="circularProgressDiv" id={'popUp7'}>
                      <div id={'popUp8'}>
                        <CircularProgress size={14} />
                        <div
                          className="hiddenTextForSize"
                          style={{ height: 0, visibility: 'hidden' }}
                          id="hiddenCreateCaseButtonText"
                          data-textattribute={
                            props.itemName === 'Case'
                              ? 'button-11'
                              : 'button-12'
                          }
                        >
                          {props.itemName === 'Case'
                            ? getText('button-11', user.settings)
                            : getText('button-12', user.settings)}
                        </div>
                      </div>
                    </div>
                  ) : props.itemName === 'Case' ? (
                    getText('button-11', user.settings)
                  ) : (
                    getText('button-12', user.settings)
                  )
                }
                buttonTextAttribute={
                  props.itemName === 'Case' ? 'button-11' : 'button-12'
                }
                buttonType="contained"
                className="createCaseButton"
                type="button"
                onClick={
                  props.requestLoading === 'createCaseButton'
                    ? undefined
                    : props.handleAddNewItem
                }
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </div>
          </>
        )}

        <div className="actionsContainer">
          {!props.isAddDisabled &&
            ((props.isMyScenariosPage && props.tutorialId) ||
              !props.isMyScenariosPage) && (
              <>
                <WhiteTooltip
                  textAttribute={
                    props.isMyScenariosPage ? 'tooltip-37' : 'tooltip-38'
                  }
                  id={'createTutorialsButtonTooltip'}
                  title={
                    createTutorialCoordinates
                      ? ''
                      : props.isMyScenariosPage
                      ? getText('tooltip-37', user.settings)
                      : getText('tooltip-38', user.settings)
                  }
                >
                  <div>
                    <Button
                      id={'createTutorialsButton'}
                      buttonText={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                          }}
                        >
                          <img
                            src={createTutorialsImg}
                            height={23}
                            width={26}
                            alt="createTutorialsImg"
                          />
                          {props.requestLoading?.includes('tutorial') && (
                            <p
                              style={{
                                paddingLeft: '8px',
                                fontSize: '14px',
                                fontWeight: 'normal',
                              }}
                            >
                              Reseting...
                            </p>
                          )}
                        </div>
                      }
                      buttonTextAttribute={'button-'}
                      buttonType="outlined"
                      type="button"
                      className="addNewCaseButton"
                      onClick={handleBottomActionsTooltips}
                      verySmall
                      reference={tutorialsButtonRef}
                      side="left"
                      NoUpperCase={true}
                      small={false}
                    />
                  </div>
                </WhiteTooltip>
                <div style={{ width: '10px' }} />
              </>
            )}
          <WhiteTooltip
            textAttribute={
              props.isMyScenariosPage ? 'tooltip-39' : 'tooltip-40'
            }
            id={'deletedCasesButtonTooltip'}
            title={
              props.isMyScenariosPage
                ? getText('tooltip-39', user.settings)
                : getText('tooltip-40', user.settings)
            }
          >
            <div>
              <Button
                id={'deletedCasesButton'}
                buttonText={
                  <img
                    src={smallDeleteImg}
                    height={23}
                    width={26}
                    alt="smallDeleteImg"
                  />
                }
                buttonTextAttribute={'button-'}
                buttonType="outlined"
                type="button"
                className="addNewCaseButton"
                onClick={props.navigateToDeleted}
                verySmall
                reference={deletedButtonRef}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </div>
          </WhiteTooltip>
        </div>
      </div>
      {createTutorialCoordinates && (
        <DeleteWindow
          id={props.isMyScenariosPage ? 'scenario_tutorial' : 'case_tutorial'}
          claimType={
            props.isMyScenariosPage ? 'scenario_tutorial' : 'case_tutorial'
          }
          deleteFunction={() => {
            setCreateTutorialCoordinates(undefined)
            props.handleCreateTutorials()
          }}
          cancelFunction={() => setCreateTutorialCoordinates(undefined)}
          position={createTutorialCoordinates ?? [0, 0]}
          fixed
        />
      )}
    </>
  )
}

export default BottomPart
