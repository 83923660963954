import React from 'react'
import { UserForAccess } from '../../../models/generalTypes'
import { User } from '../../../models/user'
type Props = {
  usersList: UserForAccess[]
  user: User
}

export default function UserThumbnailHover(props: Props) {
  return props.usersList.length > 0 ? (
    <div className="userThumbnailHoverContainermyScenarios">
      {props.usersList
        .filter((userObject) => userObject.username !== props.user.email)
        .map((userObject: UserForAccess, index: number) => (
          <p
            className="userThumbnailHoverText"
            key={`userThumbnailText${index}`}
          >
            {userObject.first_name} {userObject.last_name}
          </p>
        ))}
    </div>
  ) : (
    <></>
  )
}
