import React, { useEffect, useRef, useState } from 'react'
import {
  AccessRole,
  ClaimType,
  InterestViewOption,
  UndoRedoType,
  VideoPostName,
} from '../../../../../models/enums'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'
import { getText } from '../../../../../services/textFunctions'
import Button from '../../../../../Components/Buttons/Button/Button'
import VideoInfoButton from '../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { scenarioSnapshotState } from '../../../../../states/ScenarioSnapshotState'
import { IndependentClaim } from '../../../../../models/independentClaim'
import { findNextClaimTypeId } from '../../../../../services/claimFunctions'
import { SnapshotSelectorObject } from '../../../../../models/generalTypes'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../services/changeGlobalSnapshot'
import { v4 as uuid } from 'uuid'
import { TreeClaim } from '../../../../../models/treeModels/treeClaim'
import { treeTablesState } from '../../../../../states/TreeTablesState'
import PasteClaimButton from './PasteClaimButton'
import { freemiumState } from '../../../../../states/FreemiumState'
import Symbol from '../../../../../Components/symbol/Symbol'

type Props = {
  error: boolean
  ownRole: AccessRole
  setVideoPostName: (param: VideoPostName | undefined) => void
  setActiveClaim: (claimIndex: number) => void
  handlePasteClaim: () => void
  nothingToPaste: boolean
}

export default function EmptyClaimsComponent(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const [addClaimMenuOn, setAddClaimMenuOn] = useState(false)
  const hoverPaste = useRef<boolean>(false)
  const [showHoverMessage, setShowHoverMessage] = useState<boolean>(false)

  const onMouseDown = (e: any) => {
    if (
      !e.target.id.includes('addCounterClientClaim') &&
      !e.target.id.includes('addClientClaimButton')
    ) {
      setAddClaimMenuOn(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [])

  function handleAddClaim(typeOfClaim: ClaimType) {
    setAddClaimMenuOn(false)
    const newClaim = IndependentClaim.InitialClaim(
      `Claim ${currentSnapshot.claims.length + 1}`,
      typeOfClaim,
      findNextClaimTypeId(scenarioSnapshot.currentSnapshot, typeOfClaim) as
        | `Client Claim ${number}`
        | `Opposing Party Claim ${number}`,
    )
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'addClaimContainer',
      undoRedoType: UndoRedoType.button,
      value: newClaim,
      key: 'claims',
      key2: 'add',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    setTimeout(() => {
      props.setActiveClaim(
        tempScenarioSnapshot.currentSnapshot.claims.length - 1,
      )
    }, 30)

    //Mixpanel 33 (All)
    logActivity(freemium.isFreemium, 'Added independent claim')
  }

  function handleAddLegalTree() {
    let nextTree =
      currentSnapshot.claims.filter((c) => c.type === ClaimType.tree).length + 1

    let treeName = `Tree ${nextTree}`

    while (
      currentSnapshot.claims.filter(
        // eslint-disable-next-line
        (c) =>
          c.type === ClaimType.tree &&
          c.name.toLowerCase() === treeName.toLowerCase(),
      ).length > 0
    ) {
      nextTree++
      treeName = `Tree ${nextTree}`
    }

    const newTree = TreeClaim.initialTree(
      treeName,
      {
        firstTrialDate: currentSnapshot.firstTrialDate,
        secondTrialDate: currentSnapshot.secondTrialDate,
      },
      findNextClaimTypeId(
        currentSnapshot,
        ClaimType.tree,
      ) as `Decision Tree ${number}`,
      `node${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
      `event${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
      `event${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
    )

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'addClaimContainer',
      undoRedoType: UndoRedoType.button,
      value: newTree,
      key: 'claims',
      key2: 'add',
    }

    setTreeTables({
      ...treeTables,
      treeTablesInfo: [
        ...treeTables.treeTablesInfo,
        {
          treeId: newTree.id,
          results: undefined,
          currentValueOfTree: undefined,
          previousValueOfTree: undefined,
          showSelectionGraph: false,
          showTreeTable: false,
          treeInterestViewOption: InterestViewOption.interest1st,
        },
      ],
    })

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    setTimeout(() => {
      props.setActiveClaim(
        tempScenarioSnapshot.currentSnapshot.claims.length - 1,
      )
    }, 30)
    //Mixpanel 35 (All)
    logActivity(freemium.isFreemium, 'Added legal tree')
  }

  return (
    <>
      <div className="emptyClaimsPreviewContainer">
        <div className="emptyClaimContainer">
          {props.ownRole === AccessRole.VIEWER ? (
            <p
              className="emptyClaimsTitle"
              data-textattribute="description-140"
            >
              {getText('description-140', user.settings)}
            </p>
          ) : (
            <>
              <div className="emptyClaimsTitleContainer">
                <p
                  className={`emptyClaimsTitle ${props.error ? 'error' : ''}`}
                  id="emptyClaimsTitle"
                  data-textattribute="title-227"
                >
                  {getText(
                    props.error ? 'error-66' : 'title-227',
                    user.settings,
                  )}
                </p>
                <PasteClaimButton
                  onClick={() => {
                    props.handlePasteClaim()
                    hoverPaste.current = false
                    setShowHoverMessage(false)
                  }}
                  onMouseEnter={() => {
                    hoverPaste.current = true
                    setTimeout(() => {
                      if (hoverPaste.current) {
                        setShowHoverMessage(true)
                      }
                    }, 100)
                  }}
                  onMouseLeave={() => {
                    hoverPaste.current = false
                    setShowHoverMessage(false)
                  }}
                  nothingToPaste={props.nothingToPaste}
                  isHovered={showHoverMessage}
                />
                <div className="videoInfoButtonClaimsContainer">
                  <VideoInfoButton
                    textAttribute="tooltip-19"
                    postName={VideoPostName.claims}
                    setVideoPostName={props.setVideoPostName}
                  />
                </div>
              </div>
              <div className="emptyClaimsChoiceDiv">
                {addClaimMenuOn ? (
                  <>
                    <Button
                      buttonText={getText(
                        'button-25',
                        user.settings,
                        partiesFormat,
                      )}
                      buttonTextAttribute="button-25"
                      buttonType="outlined"
                      type="button"
                      onClick={() => {
                        handleAddClaim(ClaimType.claim)
                      }}
                      className="addClientClaim"
                      id="addClientClaimButton"
                      data-openreplay-obscured
                      side="left"
                      NoUpperCase={true}
                      small={false}
                    />
                    <Symbol
                      symbolType={'client'}
                      targetId={'addClientClaimButton'}
                      targetType={'button'}
                    />
                    <div style={{ width: '20px' }} />
                    <Button
                      buttonText={getText(
                        'button-26',
                        user.settings,
                        partiesFormat,
                      )}
                      buttonTextAttribute="button-26"
                      buttonType="outlined"
                      type="button"
                      onClick={() => {
                        handleAddClaim(ClaimType.counterclaim)
                      }}
                      className="addCounterClientClaim"
                      id="addCounterClientClaim"
                      data-openreplay-obscured
                      side="left"
                      NoUpperCase={true}
                      small={false}
                    />
                    <Symbol
                      symbolType={'opposingParty'}
                      targetId={'addCounterClientClaim'}
                      targetType={'button'}
                    />
                  </>
                ) : (
                  <>
                    <div className="emptyClaimsSingleChoice">
                      <div className="emptyClaimsButtonContainer">
                        <Button
                          buttonText={getText('button-23', user.settings)}
                          buttonTextAttribute="button-23"
                          buttonType="outlined"
                          type="button"
                          onClick={() => {
                            setAddClaimMenuOn(true)
                          }}
                          className="addClaimButton"
                          id="addClaimButton"
                          side="left"
                          NoUpperCase={true}
                          small={false}
                        />
                        <VideoInfoButton
                          textAttribute="tooltip-19"
                          postName={VideoPostName.claimsSingleClaim}
                          setVideoPostName={props.setVideoPostName}
                        />
                      </div>
                      <ul className="emptyClaimsInfoBulletList">
                        <li
                          id="emptyClaimsInfoBulletList-1"
                          data-textattribute="description-180"
                        >
                          {getText('description-180', user.settings)}
                        </li>
                        <li
                          id="emptyClaimsInfoBulletList-2"
                          data-textattribute="description-181"
                        >
                          {getText('description-181', user.settings)}
                        </li>
                        <li
                          id="emptyClaimsInfoBulletList-3"
                          data-textattribute="description-182"
                        >
                          {getText('description-182', user.settings)}
                        </li>
                      </ul>
                    </div>
                    <div className="emptyClaimsSingleChoice">
                      <div className="emptyClaimsButtonContainer">
                        <Button
                          buttonText={getText('button-24', user.settings)}
                          buttonTextAttribute="button-24"
                          buttonType="outlined"
                          type="button"
                          onClick={handleAddLegalTree}
                          className="addLegalTreeButton"
                          id="addLegalTreeButton"
                          side="left"
                          NoUpperCase={true}
                          small={false}
                        />
                        <VideoInfoButton
                          textAttribute="tooltip-19"
                          postName={VideoPostName.claimsLegalTree}
                          setVideoPostName={props.setVideoPostName}
                        />
                      </div>
                      <ul className="emptyClaimsInfoBulletList">
                        <li
                          id="emptyClaimsInfoBulletList-4"
                          data-textattribute="description-183"
                        >
                          {getText('description-183', user.settings)}
                        </li>
                        <li
                          id="emptyClaimsInfoBulletList-5"
                          data-textattribute="description-184"
                        >
                          {getText('description-184', user.settings)}
                        </li>
                        <li
                          id="emptyClaimsInfoBulletList-6"
                          data-textattribute="description-185"
                        >
                          {getText('description-185', user.settings)}
                        </li>
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}
