//import { claimPreviewIdArrayFunction } from '../../services/commonFunctions'
import { ScenarioSnapshot } from '../scenarioSnapshot'
import { SectionId } from '../treeModels/treeTypes'
import { SectionTitleKeys } from './reportEnums'
import { ReportSectionContents } from './reportGeneralTypes'
import { v4 as uuid } from 'uuid'

export class ReportSectionClass {
  title: SectionTitleKeys | string
  customTitle: string
  contents: ReportSectionContents
  show: boolean
  sectionOrder: number
  sectionId: SectionId

  constructor(
    title: SectionTitleKeys | string,
    customTitle: string,
    contents: ReportSectionContents,
    show: boolean,
    sectionOrder: number,
    sectionId: SectionId,
  ) {
    this.title = title
    this.customTitle = customTitle
    this.contents = contents
    this.show = show
    this.sectionOrder = sectionOrder
    this.sectionId = sectionId
  }

  static defaultDescription(sectionType: SectionTitleKeys): string {
    let descriptionText = ''
    switch (sectionType) {
      case SectionTitleKeys.ExecutiveSummary:
        descriptionText = ''
        break
      case SectionTitleKeys.Proceedings:
        descriptionText = ''
        break
      case SectionTitleKeys.ClaimsOverview:
        descriptionText = ''
        break
      case SectionTitleKeys.Claims:
        descriptionText = ''
        break
      case SectionTitleKeys.LegalCosts:
        descriptionText = ''
        break
      case SectionTitleKeys.LegalCostsDistribution:
        descriptionText = ''
        break
      case SectionTitleKeys.EnforcementRisk:
        descriptionText = ''
        break
      case SectionTitleKeys.SelectionGraph:
        descriptionText = ''
        break
      case SectionTitleKeys.ProbabilityDistributionGraph:
        descriptionText = ''
        break
      case SectionTitleKeys.GetPayGraph:
        descriptionText = ''
        break
      case SectionTitleKeys.ExpectedValueBreakdown:
        descriptionText = ''
        break
      case SectionTitleKeys.BestWorstScenario:
        descriptionText = ''
        break
      case SectionTitleKeys.CustomSection:
        descriptionText = ''
        break
      default:
        descriptionText = ''
        break
    }
    return descriptionText
  }

  static defaultClaimDescription(
    currentSnapshot: ScenarioSnapshot,
    index: number,
  ) {
    /* if (currentSnapshot.claims[index].type === 'tree') {
      return `${
        claimPreviewIdArrayFunction(currentSnapshot)[index]
      } default text`
    } else {
      return `${
        claimPreviewIdArrayFunction(currentSnapshot)[index]
      } default text`
    } */
    return ''
  }

  static ExecutiveSummaryDefault(sectionOrder: number): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.ExecutiveSummary,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.ExecutiveSummary),
        executiveSummary: {
          visibleElements: {
            valueOfCase: true,
            settlement: true,
            requestedAmounts: true,
            proceedings: true,
            legalCosts: true,
            enforcement: true,
            bestWorstScenario: true,
            zopa: true,
            claimsAndLegalCosts: true,
            expectedValue: true,
            newBestWorstScenario: true,
            newZopa: true,
          },
        },
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static ProceedingsDefault(
    firstCourtName: string,
    secondCourtName: string,
    sectionOrder: number,
  ): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.Proceedings,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.Proceedings),
        proceedings: {
          firstCourtName: firstCourtName,
          secondCourtName: secondCourtName,
          firstCourtDateDescription: '',
          secondCourtDateDescription: '',
        },
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static ClaimsOverviewDefault(sectionOrder: number): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.ClaimsOverview,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.ClaimsOverview),
        claimsOverview: true,
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static ClaimsDefault(
    currentSnapshot: ScenarioSnapshot,
    sectionOrder: number,
  ): ReportSectionClass {
    let tempClaimsArray = []
    for (let i = 0; i < currentSnapshot.claims.length; i++) {
      tempClaimsArray.push({
        description: this.defaultClaimDescription(currentSnapshot, i),
        claimOrTreeId: currentSnapshot.claims[i].id,
      })
    }
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.Claims,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.Claims),
        claims: tempClaimsArray,
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static LegalCostsDefault(
    sectionOrder: number,
    show: boolean,
  ): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.LegalCosts,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.LegalCosts),
        legalCosts: {
          firstOwnLegalCostsDescription: '',
          secondOwnLegalCostsDescription: '',
          firstOtherLegalCostsDescription: '',
          secondOtherLegalCostsDescription: '',
        },
      },
      show,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static LegalCostsDistributionDefault(
    sectionOrder: number,
    show: boolean,
  ): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.LegalCostsDistribution,
      '',
      {
        description: this.defaultDescription(
          SectionTitleKeys.LegalCostsDistribution,
        ),
      },
      show,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static EnforcementRisk(
    sectionOrder: number,
    show: boolean,
  ): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.EnforcementRisk,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.EnforcementRisk),
      },
      show,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static SelectionGraphDefault(sectionOrder: number): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.SelectionGraph,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.SelectionGraph),
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static ProbabilityDistributionGraphDefault(
    sectionOrder: number,
  ): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.ProbabilityDistributionGraph,
      '',
      {
        description: this.defaultDescription(
          SectionTitleKeys.ProbabilityDistributionGraph,
        ),
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }
  static GetPayGraphDefault(sectionOrder: number): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.GetPayGraph,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.GetPayGraph),
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static ExpectedValueBreakdownDefault(
    sectionOrder: number,
  ): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.ExpectedValueBreakdown,
      '',
      {
        description: this.defaultDescription(
          SectionTitleKeys.ExpectedValueBreakdown,
        ),
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static BestWorstScenarioDefault(sectionOrder: number): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.BestWorstScenario,
      '',
      {
        description: this.defaultDescription(
          SectionTitleKeys.BestWorstScenario,
        ),
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }

  static CustomSectionDefault(sectionOrder: number): ReportSectionClass {
    let tempReportSection = new ReportSectionClass(
      SectionTitleKeys.CustomSection,
      '',
      {
        description: this.defaultDescription(SectionTitleKeys.CustomSection),
      },
      true,
      sectionOrder,
      `section${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
    )
    return tempReportSection
  }
}
