import React from 'react'
import QRCode from 'qrcode.react'
import { PageType } from '../UserPopUp'
import backCircleImg from '../../../resources/images/134-backLogin.svg'
import OtpInput from './OtpInput'
import CircularProgress from '@mui/material/CircularProgress'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  setPageType: (type: PageType) => void
  url: string
  setOtp: (otp: string) => void
  validationMessage: string | undefined
  onSubmit: () => void
  loading: boolean
}

const QRCodeSetup = (props: Props) => {
  const user = useRecoilValue(userState)

  const message = props.validationMessage ? (
    <>
      <div className="lettersContainer errorClass">
        {props.validationMessage.split('\\n')[0]}
        <span style={{ color: '#331a5b' }}>
          {props.validationMessage.split('\\n')[1]}
        </span>
      </div>
    </>
  ) : (
    <div
      className="lettersContainer"
      id="enterCodeMessageQr"
      data-textattribute="message-43"
    >
      {getText('message-43', user.settings)}
    </div>
  )

  return (
    <>
      <img
        className="backImg"
        src={backCircleImg}
        alt="backImg"
        onClick={() => props.setPageType('SetupTwoFactor')}
      />
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          style={{ fontSize: '21px' }}
          id="qrCodeTitle"
          data-textattribute="title-115"
        >
          {getText('title-115', user.settings)}
        </h2>
        <p
          className="instructions"
          id="qrCodeInstructions"
          data-textattribute="description-87a,description-87b "
        >
          {getText('description-87a', user.settings)}{' '}
          <a
            className="link"
            href="https://authy.com/what-is-2fa/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {getText('description-87b', user.settings)}
          </a>
        </p>
      </div>
      <div className="inputsContainer">
        <div className="inputsCenterContainer">
          <div className="qrContainer">
            <div>{props.url !== '' && <QRCode value={props.url} />}</div>
          </div>
          <div className="lettersContainer">{message}</div>
          <OtpInput setOtp={props.setOtp} otp={''} />
        </div>
      </div>
      <div className="submitButtonContainer">
        <button
          type="submit"
          className="submitButton"
          disabled={props.loading}
          id="submitQRCodeButton"
          data-textattribute="button-55"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : (
            getText('button-55', user.settings)
          )}
        </button>
      </div>
    </>
  )
}

export default QRCodeSetup
