import React, { useEffect } from 'react'

type Props = {
  menuHighlight: number
  listOfTitles: string[][]
  scrollTo: (id: string) => void
}

export default function TutorialTemplateSideMenu(props: Props) {
  useEffect(() => {
    const offset = 200

    const element = document.getElementById(`title${props.menuHighlight}`)
    if (element) {
      const topPosition = element!.offsetTop - offset
      document.getElementById('userManualSideMenuContainer')!.scroll({
        top: topPosition,
        behavior: 'smooth',
      })
    }
  }, [props.menuHighlight])

  return (
    <>
      <div
        className="userManualSideMenuContainer"
        id="userManualSideMenuContainer"
      >
        {props.listOfTitles.map((titleArray, index) => {
          if (titleArray[0] === 'h1') {
            return (
              <div
                className="h1Title"
                id={`title${index}`}
                key={`title${index}`}
                onClick={() => props.scrollTo(titleArray[2])}
              >
                <p
                  className="titleText"
                  onClick={() => props.scrollTo(titleArray[2])}
                  style={
                    props.menuHighlight === index
                      ? { color: '#ff703e' }
                      : props.menuHighlight > index
                      ? { color: '#8e8e8e' }
                      : undefined
                  }
                >
                  {titleArray[1]}
                </p>
              </div>
            )
          } else {
            return (
              <div
                className="h2Title"
                id={`title${index}`}
                key={`title${index}`}
                onClick={() => props.scrollTo(titleArray[2])}
              >
                <p
                  className="titleText"
                  onClick={() => props.scrollTo(titleArray[2])}
                  style={
                    props.menuHighlight === index
                      ? { color: '#ff703e' }
                      : props.menuHighlight > index
                      ? { color: '#8e8e8e' }
                      : undefined
                  }
                >
                  {titleArray[1]}
                </p>
              </div>
            )
          }
        })}
      </div>
    </>
  )
}
