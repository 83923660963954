import CircularProgress from '@mui/material/CircularProgress'
import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { featuresState } from '../../../states/FeaturesState'

type Props = {
  onSetupSms: () => void
  onSetupTotp: () => void
  loading: boolean
  setupTwoFactorError: string | undefined
}

const SetupTwoFactor = (props: Props) => {
  const user = useRecoilValue(userState)
  const features = useRecoilValue(featuresState)

  const [selected, setSelected] = useState<string | undefined>(undefined)
  const [hasSms, setHasSms] = useState(false)

  const onSetup = () => {
    if (selected === 'authenticator') {
      props.onSetupTotp()
    } else if (selected === 'mobile') {
      props.onSetupSms()
    }
  }

  useEffect(() => {
    setHasSms(features.use_sms)
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="setupTwoFactorTitle"
          data-textattribute="title-116"
        >
          {getText('title-116', user.settings)}
        </h2>
        <p
          className="instructions"
          id="setupTwoFactorInstruction"
          data-textattribute="description-88"
        >
          {getText('description-88', user.settings)}
        </p>
        {props.setupTwoFactorError !== undefined ? (
          <p className="errorClass">{props.setupTwoFactorError}</p>
        ) : null}
      </div>
      <div className="inputsContainer">
        <div className="inputsCenterContainer">
          <button
            className={`radioButton ${
              selected === 'mobile' ? 'radioButtonSelected' : ''
            } ${!hasSms ? 'disabled' : ''}`}
            onClick={() => {
              if (hasSms) {
                setSelected('mobile')
              }
            }}
            id="smsRadioButton"
            data-textattribute="button-56"
          >
            {getText('button-56', user.settings)}
          </button>
          <div
            className={`orContainer ${!hasSms ? 'disabled-sms' : ''}`}
            id="setupTwoFactorOr"
            data-textattribute={hasSms ? 'description-89' : 'description-142'}
          >
            {hasSms
              ? getText('description-89', user.settings)
              : getText('description-142', user.settings)}
          </div>
          <button
            className={`radioButton ${
              selected === 'authenticator' ? 'radioButtonSelected' : ''
            }`}
            onClick={() => setSelected('authenticator')}
            id="authAppRadioButton"
            data-textattribute="button-57"
          >
            {getText('button-57', user.settings)}
          </button>
        </div>
      </div>
      <div className="submitButtonContainer">
        <button
          className={`submitButton ${selected ? '' : 'disabledButton'}`}
          onClick={selected !== undefined ? onSetup : undefined}
          id="submitSetupTwoFactorButton"
          data-textattribute="button-58"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : (
            getText('button-58', user.settings)
          )}
        </button>
      </div>
    </>
  )
}

export default SetupTwoFactor
