import React, { useState, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { AccessRole } from '../../../../models/enums'
import { LegalFeesDistributionObject } from '../../../../models/legalFeesDistributionObject'
import { getText } from '../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../states/UserState'

import LegalFeesRangeSlider from './LegalFeesRangeSlider'
import { ReportWhitelabel } from '../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../services/reportFunctions'

type Props = {
  singleSegmentIndex: number
  changeEffect: boolean
  feeDistributions: LegalFeesDistributionObject[]
  setChangeEffect: (param1: boolean) => void
  pdfPreview?: boolean
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  ownRole?: AccessRole
  reportWhitelabel?: ReportWhitelabel
}

export default function LegalFeesRange(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const feeDistributions = currentSnapshot.legalFeesDistribution

  const [rangeValue, setRangeValue] = useState<number>(0) // the state that holds the value of the range slider -- integer from -10 to 10
  const [legalFeesRangeText, setlegalFeesRangeText] = useState('') // the state that holds the text next to the slider -- str

  useEffect(() => {
    setRangeValue(feeDistributions[props.singleSegmentIndex].value)
    // eslint-disable-next-line
  }, [feeDistributions])

  useEffect(() => {
    findLegalFeesRangeText(feeDistributions[props.singleSegmentIndex].value)
    // eslint-disable-next-line
  }, [feeDistributions])

  function findLegalFeesRangeText(rangeValue: number) {
    if (rangeValue > 0) {
      //data-textattribute="description-30"
      setlegalFeesRangeText(
        `${rangeValue * 10}${getText(
          'description-30',
          user.settings,
          partiesFormat,
        )}`,
      )
    } else if (rangeValue < 0) {
      //data-textattribute="description-31"
      setlegalFeesRangeText(
        `${-rangeValue * 10}${getText(
          'description-31',
          user.settings,
          partiesFormat,
        )}`,
      )
    } else {
      //data-textattribute="description-32"
      setlegalFeesRangeText(getText('description-32', user.settings))
    }
  }

  return (
    <>
      <div className="legalFeesRangeContainer">
        <div
          className={
            props.pdfPreview
              ? 'legalFeesRangeTextContainer pdfPreviewlegalFeesRangeTextContainer'
              : 'legalFeesRangeTextContainer'
          }
        >
          <p
            className={
              props.pdfPreview
                ? 'legalFeesRangeText pdfPreviewLegalFeesRangeText'
                : props.changeEffect
                ? 'legalFeesRangeText bold'
                : 'legalFeesRangeText'
            }
            id={`legalFeesRangeText-${props.singleSegmentIndex}`}
            data-openreplay-obscured
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {legalFeesRangeText}
          </p>
        </div>
        {!props.pdfPreview && (
          <div className="legalFeesRangeSliderContainer">
            <LegalFeesRangeSlider
              defaultRangeValue={rangeValue}
              singleSegmentIndex={props.singleSegmentIndex}
              setChangeEffect={props.setChangeEffect}
              isFirstLoad={props.isFirstLoad}
              setIsFirstLoad={props.setIsFirstLoad}
              findLegalFeesRangeText={findLegalFeesRangeText}
              ownRole={props.ownRole}
            />
          </div>
        )}
      </div>
    </>
  )
}
