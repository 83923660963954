import React, { useEffect, useState } from 'react'
import Button from '../Buttons/Button/Button'
import { ClaimType, ClaimsPreviewStyle } from '../../models/enums'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'
import { useRecoilValue } from 'recoil'

type Props = {
  claimIndex: number
  claimType: ClaimType
  deleteFunction: () => void
  cancelFunction: () => void
  previewStyle: ClaimsPreviewStyle
}

export default function DeleteClaimWindow(props: Props) {
  const user = useRecoilValue(userState)
  const [claimTypeName, setClaimTypeName] = useState('')

  useEffect(() => {
    if (props.claimType === 'claim' || props.claimType === 'counterclaim') {
      //id='typeForDeletePreviewQuestion-claim'
      //data-textattribute='message-13'
      setClaimTypeName(getText('message-13', user.settings))
    } else if (props.claimType === 'tree') {
      //id='typeForDeletePreviewQuestion-tree'
      //data-textattribute='message-14'
      setClaimTypeName(getText('message-14', user.settings))
    }

    // eslint-disable-next-line
  }, [props.claimType])

  useEffect(() => {
    window.addEventListener('mousedown', closeDeleteWindow)

    return () => {
      window.removeEventListener('mousedown', closeDeleteWindow)
    }
    // eslint-disable-next-line
  }, [])

  function closeDeleteWindow(e: any) {
    if (
      !e.target.id.includes(`delete`) &&
      !e.target.id.includes('windowForPreview')
    ) {
      props.cancelFunction()
    }
  }

  return (
    <>
      <div
        id="deleteMessageContainerForPreview"
        className={`deleteMessageContainerForPreview ${
          props.previewStyle === ClaimsPreviewStyle.list
            ? 'list'
            : props.previewStyle === ClaimsPreviewStyle.expanded
            ? 'expanded'
            : ''
        }`}
      >
        <div
          id="deleteQuestion"
          className={`deleteQuestion ${
            props.previewStyle === ClaimsPreviewStyle.list
              ? 'list'
              : props.previewStyle === ClaimsPreviewStyle.expanded
              ? 'expanded'
              : ''
          }`}
        >
          <>
            <p
              id={`deletePreviewQuestionText-${props.claimIndex}`}
              data-textattribute="message-15"
            >
              {getText('message-15', user.settings)} {claimTypeName}?
            </p>
          </>
        </div>
        <div
          id="deleteButtonsContainer"
          className={`buttonsContainer ${
            props.previewStyle === ClaimsPreviewStyle.list
              ? 'list'
              : props.previewStyle === ClaimsPreviewStyle.expanded
              ? 'expanded'
              : ''
          }`}
        >
          <Button
            id={'windowForPreviewCancelButton'}
            buttonText={getText('button-3', user.settings)}
            buttonTextAttribute="button-3"
            buttonType="text"
            type="button"
            className="cancelButton"
            onMouseUp={props.cancelFunction}
            side="left"
            NoUpperCase={true}
            small={false}
          />
          <Button
            id={'windowForPreviewDeleteButton'}
            buttonText={getText('button-4', user.settings)}
            buttonTextAttribute="button-4"
            buttonType="contained"
            type="button"
            className="DeleteButton list"
            onMouseUp={() => props.deleteFunction()}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        </div>
      </div>
    </>
  )
}
