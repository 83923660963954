import { useState, useEffect } from 'react'

type Props = {
  svgImages: string[]
  imagesChangeSpeed: number
  className: string
}

export function AnimateSVG(props: Props) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      // Increment the current image index and loop back to the first image when reaching the end
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % props.svgImages.length,
      )
    }, props.imagesChangeSpeed)

    return () => {
      clearInterval(timer)
    }
  }, [props.imagesChangeSpeed, props.svgImages.length])

  return (
    <>
      {props.svgImages.map((svgImage, index) => (
        <img
          key={index} // Make sure to provide a unique key for each image element in the array
          src={svgImage}
          alt={svgImage}
          className={props.className}
          style={{
            display: index === currentImageIndex ? 'inline-block' : 'none', // Show the image if its index matches the currentImageIndex, otherwise hide it
          }}
        />
      ))}
    </>
  )
}
