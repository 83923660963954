import Cookies from 'universal-cookie'

export const saveTokens = (token: string, refreshToken: string) => {
  if (process.env.REACT_APP_STORAGE_OPTION === 'cookie') {
    const cookies = new Cookies()
    cookies.set('token', token, {
      path: '/',
      secure: true,
      sameSite: 'strict',
      expires: getExpirationDate(refreshToken)
        ? new Date(getExpirationDate(refreshToken) as number)
        : undefined,
    })
    cookies.set('refreshToken', refreshToken, {
      path: '/',
      secure: true,
      sameSite: 'strict',
      expires: getExpirationDate(refreshToken)
        ? new Date(getExpirationDate(refreshToken) as number)
        : undefined,
    })
  } else {
    ;(process.env.REACT_APP_STORAGE_OPTION === 'session'
      ? sessionStorage
      : localStorage
    ).setItem('token', token)
    ;(process.env.REACT_APP_STORAGE_OPTION === 'session'
      ? sessionStorage
      : localStorage
    ).setItem('refreshToken', refreshToken)
  }
}

export const saveToken = (token: string) => {
  if (process.env.REACT_APP_STORAGE_OPTION === 'cookie') {
    const cookies = new Cookies()
    cookies.set('token', token, {
      path: '/',
      secure: true,
      sameSite: 'strict',
      expires: getExpirationDate(getRefreshToken())
        ? new Date(getExpirationDate(getRefreshToken()) as number)
        : undefined,
    })
  } else {
    ;(process.env.REACT_APP_STORAGE_OPTION === 'session'
      ? sessionStorage
      : localStorage
    ).setItem('token', token)
  }
}

export const getToken = () => {
  if (process.env.REACT_APP_STORAGE_OPTION === 'cookie') {
    const cookies = new Cookies()
    return cookies.get('token')
  } else {
    return (
      process.env.REACT_APP_STORAGE_OPTION === 'session'
        ? sessionStorage
        : localStorage
    ).getItem('token')
  }
}

export const getRefreshToken = () => {
  if (process.env.REACT_APP_STORAGE_OPTION === 'cookie') {
    const cookies = new Cookies()
    return cookies.get('refreshToken')
  } else {
    return (
      process.env.REACT_APP_STORAGE_OPTION === 'session'
        ? sessionStorage
        : localStorage
    ).getItem('refreshToken')
  }
}

export const clearTokens = () => {
  if (process.env.REACT_APP_STORAGE_OPTION === 'cookie') {
    const cookies = new Cookies()
    cookies.remove('token')
    cookies.remove('refreshToken')
  } else {
    ;(process.env.REACT_APP_STORAGE_OPTION === 'session'
      ? sessionStorage
      : localStorage
    ).clear()
  }
}

const getExpirationDate = (token: string) => {
  try {
    const res = JSON.parse(atob(token.split('.')[1]))
    return res.exp * 1000
  } catch (e) {
    return undefined
  }
}
