import React, { useEffect, useRef, useState } from 'react'
import Draggable from 'react-draggable'
import { useXarrow } from 'react-xarrows'
import { useRecoilState, useRecoilValue } from 'recoil'
import { v4 as uuid } from 'uuid'

import {
  AccessRole,
  SnapshotStatus,
  UndoRedoType,
} from '../../../../../../../models/enums'
import { TreeClaim } from '../../../../../../../models/treeModels/treeClaim'
import { TreeEventClass } from '../../../../../../../models/treeModels/treeEvent'
import { TreeNodeClass } from '../../../../../../../models/treeModels/treeNode'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import {
  DeleteNodeOrEventMenuObject,
  EventId,
  NodeId,
  NodeMode,
  RootNodeType,
} from '../../../../../../../models/treeModels/treeTypes'

import TreeNodeTitleComponent from './treeNodeTitleMenuComponents/TreeNodeTitleComponent'
import TreeEvent from '../treeEventComponents/TreeEvent'
import TreeEventMinimised from '../treeEventComponents/TreeEventMinimised'

import canBeConnectedImg from '../../../../../../../resources/images/205-orangeDetector.svg'
import isConnectedImg from '../../../../../../../resources/images/206-purpleIndicator.svg'
import canBeConnectedImgMinimisedImg from '../../../../../../../resources/images/208-orangeDetectorMinimised.svg'
import isConnectedImgMinimisedImg from '../../../../../../../resources/images/207-purpleIndicatorMinimised.svg'
import emptyImg from '../../../../../../../resources/images/209-empty.svg'

import {
  deepCloneObject,
  isMac,
  logActivity,
  roundNumberTo,
} from '../../../../../../../services/commonFunctions'
import {
  changeProbabilities,
  changeTextAreaHeight,
  changeTreeClaim,
  checkIfNodeHasAtLeastOneAwardedAmount,
  checkIfNodeHasAtLeastOneEventTitle,
  checkIfNodeHasAtLeastOneInterestMenuOn,
  checkIfNodeHasAtLeastOneOutOfCourtAmount,
  checkIfNodeHasAtLeastOneReducedAmount,
  copyNodes,
  duplicateNodes,
  findEventsOfNode,
  findMainTreeAndOrphanTrees,
  findNewEventIndex,
  findZeroProbabilityEventsInMainTree,
  isEventEligibleToConnect,
} from '../../../../../../../services/treeFunctions/treeBasicFunctions'
import { changeNodesPosition } from '../../../../../../../services/treeFunctions/treePositioningFunctions'
import {
  findLeftMostNodeOfMainTree,
  findTopMostNodeOfMainTree,
  heightOfNode,
  nodeX,
  nodeY,
  widthOfNode,
} from '../../../../../../../services/treeFunctions/treeXandYFunctions'

import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import { nodesMovedForEditModeState } from '../../../../../../../states/NodesMovedForEditModeState'
import { editTreeNodeFromUndoState } from '../../../../../../../states/EditTreeNodeFromUndo'
import { treeTablesState } from '../../../../../../../states/TreeTablesState'
import { scenarioIdentityState } from '../../../../../../../states/ScenarioIdentityState'
import { TreeTablesState } from '../../../../../../../models/generalTypes'
import {
  changeEventKeysInNodeSiblingIndex,
  findEventNumber,
} from '../../../../../../../services/twoParentsFunctionsForNumberOfScenarios'
import { allowShortcutsState } from '../../../../../../../states/AllowShortcutsState'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  nodeDetails: TreeNodeClass
  events: { [eventId: EventId]: TreeEventClass }
  nodeId: NodeId
  zoomLevel: number
  treeIndex: number
  root: RootNodeType
  setRenderLines: (param: boolean) => void
  handleSetLineHover: (nodeId: NodeId, eventId: EventId, state: boolean) => void
  handleSetLineClick: (nodeId: NodeId, eventId: EventId, state: boolean) => void
  lineClick: [NodeId, EventId] | undefined
  selectedNodes: NodeId[]
  setSelectedNodes: (selectedNodes: NodeId[]) => void
  mainTree: NodeId[]
  orphanTrees: NodeId[][]
  elementsToBeDeletedForAnimation: (NodeId | EventId)[]
  setElementsToBeDeletedForAnimation: (param: (NodeId | EventId)[]) => void
  setMainTree: (mainTree: NodeId[]) => void
  setOrphanTrees: (orphanTrees: NodeId[][]) => void
  nodeCreatingLine: NodeId | undefined
  setNodeCreatingLine: (nodeCreatingLine: NodeId | undefined) => void
  availableTargetsForLine: (NodeId | EventId)[]
  setAvailableTargetsForLine: (
    availableEventsForLine: (NodeId | EventId)[],
  ) => void
  setDragPosition: (param: any) => void
  setChangingLineStartId: (changingLineStartId: string | undefined) => void
  showTreeInfo: boolean
  editMode: NodeId | undefined
  setEditMode: (editMode: NodeId | undefined) => void
  resetActivePath: boolean
  selectedPath: number[]
  debugging: boolean
  shakeScenario: [NodeId, number] | undefined
  openDeleteNodeOrEventMenu: DeleteNodeOrEventMenuObject | undefined
  setOpenDeleteNodeOrEventMenu: (
    openDeleteNodeorEventMenu: DeleteNodeOrEventMenuObject | undefined,
  ) => void
  nodeProbabilities: { [nodeId: NodeId]: number[] }
  handleDeleteTreeEvent: (
    withChildren: boolean,
    nodeId: NodeId,
    eventId: EventId,
    eventIndex: number,
  ) => void
  handleDeleteTreeNode: (
    withChildren: boolean,
    nodeId: NodeId,
    selectedNodes?: NodeId[],
  ) => void
  connectedDot: EventId | NodeId | undefined
  setConnectedDot: (connectedDot: EventId | NodeId | undefined) => void
  openOrphanNodesWarningPopUp: boolean
  openZeroProbabilityWarningPopUp: boolean
  ownRole: AccessRole
  toggleEditMode: (e: any) => void
  errors: string[]
  connectionCreatesLoop: EventId | NodeId | undefined
  setConnectionCreatesLoop: (
    connectionCreatesLoop: EventId | NodeId | undefined,
  ) => void
  differenceFromTop?: number
  differenceFromLeft?: number
  nodesUnderZeroProbability: NodeId[]
  copiedNodes?: NodeId[]
  setCopiedNodes?: (value: NodeId[]) => void
}

export default function TreeNode(props: Props) {
  const img = new Image()
  img.src = emptyImg
  const [dragTimeoutOn, setDragTimeoutOn] = useState(true)
  const updateXarrow = useXarrow()
  const nodeRef = useRef<HTMLDivElement>(null)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const treeNodeMode = (
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).nodeMode
  const treeDetails = (
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).treeDetails
  const [localNodeMode, setLocalNodeMode] = useState(treeNodeMode)
  const [nodeHasChildren, setNodeHasChildren] = useState(false)

  const [nodeHasAtLeastOneEventTitle, setNodeHasAtLeastOneEventTitle] =
    useState(false)
  const [nodeHasAtLeastOneAwardedAmount, setNodeHasAtLeastOneAwardedAmount] =
    useState(false)
  const [nodeHasAtLeastOneReducedAmount, setNodeHasAtLeastOneReducedAmount] =
    useState(false)
  const [
    nodeHasAtLeastOneOutOfCourtAmount,
    setNodeHasAtLeastOneOutOfCourtAmount,
  ] = useState(false)
  const [nodeHasAtLeastOneInterestMenuOn, setNodeHasAtLeastOneInterestMenuOn] =
    useState(false)

  const [startAnimation, setStartAnimation] = useState(false)
  const editTreeNodeFromUndo = useRecoilValue(editTreeNodeFromUndoState)
  const [nodesMovedForEditMode, setNodesMovedForEditMode] = useRecoilState(
    nodesMovedForEditModeState,
  )
  const [hoverOrphanNode, setHoverOrphanNode] = useState(false)
  const treeAnalysis = useRecoilValue(treeTablesState)
  const freemium = useRecoilValue(freemiumState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  useEffect(() => {
    if (editTreeNodeFromUndo === props.nodeId) {
      props.setEditMode(props.nodeId)
      if (treeNodeMode === NodeMode.minimised) {
        props.toggleEditMode({
          target: {
            id: `undo-${props.nodeId}`,
            classList: `undo-${props.nodeId}`,
          },
        })
      }
    } else {
      setLocalNodeMode(treeNodeMode)
    } // eslint-disable-next-line
  }, [editTreeNodeFromUndo])

  useEffect(() => {
    if (props.editMode && props.editMode === props.nodeId) {
      setLocalNodeMode(NodeMode.maximised)
    } else {
      setLocalNodeMode(treeNodeMode)
    }
    // eslint-disable-next-line
  }, [props.editMode])

  useEffect(() => {
    if (
      editTreeNodeFromUndo !== props.nodeId ||
      treeNodeMode === NodeMode.maximised
    ) {
      setLocalNodeMode(treeNodeMode)
    }
    // eslint-disable-next-line
  }, [treeNodeMode])

  useEffect(() => {
    if (props.elementsToBeDeletedForAnimation.includes(props.nodeId)) {
      setStartAnimation(true)
    }
    // eslint-disable-next-line
  }, [props.elementsToBeDeletedForAnimation])

  useEffect(() => {
    setNodeHasAtLeastOneEventTitle(
      checkIfNodeHasAtLeastOneEventTitle(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneAwardedAmount(
      checkIfNodeHasAtLeastOneAwardedAmount(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneReducedAmount(
      checkIfNodeHasAtLeastOneReducedAmount(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneOutOfCourtAmount(
      checkIfNodeHasAtLeastOneOutOfCourtAmount(props.nodeId, treeDetails),
    )
    setNodeHasAtLeastOneInterestMenuOn(
      checkIfNodeHasAtLeastOneInterestMenuOn(props.nodeId, treeDetails),
    )

    // eslint-disable-next-line
  }, [treeDetails.events])

  useEffect(() => {
    let tempNodeHasChildren = false
    for (let eventId of Object.keys(treeDetails.events)) {
      if (
        treeDetails.events[eventId as EventId].nodeOfEventId === props.nodeId
      ) {
        if (treeDetails.events[eventId as EventId].childrenNodes.length > 0) {
          tempNodeHasChildren = true
        }
      }
    }
    setNodeHasChildren(tempNodeHasChildren)
    // eslint-disable-next-line
  }, [treeDetails.events])

  useEffect(() => {
    if (props.ownRole !== AccessRole.VIEWER) {
      document.addEventListener('keydown', duplicateSelectedNodes)
      document.addEventListener('keydown', copySelectedNodes)
    }

    return () => {
      document.removeEventListener('keydown', duplicateSelectedNodes)
      document.removeEventListener('keydown', copySelectedNodes)
    }
    // eslint-disable-next-line
  }, [props.selectedNodes, allowShortcuts, treeDetails])

  function handleChangeEventProbability(e: any, eventIndex: number) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    let tempNodeProbabilities = changeProbabilities(
      props.nodeProbabilities[props.nodeId],
      eventIndex,
      e.target.value,
    )
    let eventsOfNode = findEventsOfNode(props.nodeId, treeDetails)
    const undoRedoType =
      treeNodeMode === NodeMode.maximised
        ? UndoRedoType.input
        : UndoRedoType.minimisedInput

    for (let eventId of eventsOfNode) {
      tempTreeClaim.treeDetails.events[eventId].eventDetails.probability =
        tempNodeProbabilities[
          tempTreeClaim.treeDetails.events[eventId].eventIndex
        ]
    }

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      e.target.id,
      undoRedoType,
      props.treeIndex,
      false,
      props.ownRole,
      nodesMovedForEditMode,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    //hide Treetable
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
      setTreeTables(tempTreeTables)
    }
    // updateXarrow()
  }

  function handleChangeNodePosition(
    targetId: string[],
    position: [number, number],
  ) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)
    let changeMadeToNodesMovedForEdit = false
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    const xDifference =
      position[0] -
      tempTreeClaim.treeDetails.nodes[props.nodeId].properties.position[0]
    const yDifference =
      position[1] -
      tempTreeClaim.treeDetails.nodes[props.nodeId].properties.position[1]
    const tempUndoRedoType = UndoRedoType.button

    tempTreeClaim.treeDetails.nodes[props.nodeId].properties.position = position
    if (
      tempNodesMovedForEditMode &&
      Object.keys(tempNodesMovedForEditMode).includes(props.nodeId)
    ) {
      tempNodesMovedForEditMode[props.nodeId].newPosition = position
      tempNodesMovedForEditMode[props.nodeId].previousPosition = position
      changeMadeToNodesMovedForEdit = true
    }
    if (props.selectedNodes.includes(props.nodeId)) {
      for (let selectedNodeId of props.selectedNodes.filter(
        (nodeId) => nodeId !== props.nodeId,
      )) {
        tempTreeClaim.treeDetails.nodes[
          selectedNodeId as NodeId
        ].properties.position[0] += xDifference
        tempTreeClaim.treeDetails.nodes[
          selectedNodeId as NodeId
        ].properties.position[1] += yDifference
        let selectedNodeNewPosition: [number, number] = [
          tempTreeClaim.treeDetails.nodes[selectedNodeId as NodeId].properties
            .position[0],
          tempTreeClaim.treeDetails.nodes[selectedNodeId as NodeId].properties
            .position[1],
        ]
        if (
          tempNodesMovedForEditMode &&
          Object.keys(tempNodesMovedForEditMode).includes(selectedNodeId)
        ) {
          tempNodesMovedForEditMode[selectedNodeId].newPosition =
            selectedNodeNewPosition
          tempNodesMovedForEditMode[selectedNodeId].previousPosition =
            selectedNodeNewPosition
          changeMadeToNodesMovedForEdit = true
        }
      }
    }

    if (changeMadeToNodesMovedForEdit) {
      setNodesMovedForEditMode(tempNodesMovedForEditMode)
    }
    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      targetId,
      tempUndoRedoType,
      props.treeIndex,
      tempTreeClaim.analysisResults,
      props.ownRole,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    // updateXarrow()
  }

  function handleAddEvent(e: any) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    const newEventId: EventId = `event${uuid().substring(0, 8)}${parseInt(
      Date.now().toString(),
    )}`
    const newEventIndex = findNewEventIndex(props.nodeId, tempTreeClaim)
    const undoRedoType =
      treeNodeMode === NodeMode.maximised
        ? UndoRedoType.button
        : UndoRedoType.minimisedButton

    tempTreeClaim.treeDetails.nodes[props.nodeId].numberOfEvents++
    tempTreeClaim.treeDetails.events[newEventId] =
      TreeEventClass.defaultTreeEvent(
        props.nodeId,
        newEventIndex,
        EventDetails.defaultEventDetails(0),
      )

    tempTreeClaim.treeDetails = changeNodesPosition(
      props.nodeId,
      tempTreeClaim.treeDetails,
      treeNodeMode,
      'addEvent',
      props.treeIndex,
      props.editMode,
    )

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      e.target.id,
      undoRedoType,
      props.treeIndex,
      false,
      props.ownRole,
      nodesMovedForEditMode,
    )

    if (nodesMovedForEditMode) {
      let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)
      let xDifference = 0

      for (let tempNodeId of Object.keys(tempNodesMovedForEditMode)) {
        if (
          tempNodesMovedForEditMode[tempNodeId].newPosition[0] -
            tempNodesMovedForEditMode[tempNodeId].previousPosition[0] >
          0
        ) {
          xDifference =
            tempNodesMovedForEditMode[tempNodeId].newPosition[0] -
            tempNodesMovedForEditMode[tempNodeId].previousPosition[0]
          tempNodesMovedForEditMode[tempNodeId].previousPosition[0] =
            tempTreeClaim.treeDetails.nodes[tempNodeId as NodeId].properties
              .position[0] - xDifference
          tempNodesMovedForEditMode[tempNodeId].previousPosition[1] =
            tempTreeClaim.treeDetails.nodes[
              tempNodeId as NodeId
            ].properties.position[1]
        }
      }

      setNodesMovedForEditMode(tempNodesMovedForEditMode)
    }

    setTimeout(() => {
      changeTextAreaHeight(
        document.getElementById(
          `treeNodeTitleTextarea-${props.treeIndex}_${props.nodeId}`,
        )!,
      )
      updateXarrow()
    }, 10)

    setScenarioSnapshot(tempScenarioSnapshot)
    //hide Treetable
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
      setTreeTables(tempTreeTables)
    }
  }

  function handleNodeTitleChange(e: any) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim

    const tempUndoRedoType =
      treeNodeMode === NodeMode.maximised
        ? UndoRedoType.input
        : UndoRedoType.minimisedInput

    tempTreeClaim.treeDetails.nodes[props.nodeId].nodeTitle = e.target.value

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      e.target.id,
      tempUndoRedoType,
      props.treeIndex,
      tempTreeClaim.analysisResults,
      props.ownRole,
      nodesMovedForEditMode,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    // updateXarrow()
  }

  function duplicateSelectedNodes(e: any) {
    if (props.selectedNodes.length > 0 && allowShortcuts) {
      if (
        ((e.code === 'KeyD' && e.ctrlKey && !isMac()) ||
          (e.code === 'KeyD' && e.metaKey && isMac())) &&
        e.target.tagName.toLowerCase() !== 'textarea' &&
        e.target.tagName.toLowerCase() !== 'input'
      ) {
        e.preventDefault()
        handleDuplicateNodes()
      }
    }
  }

  function handleDuplicateNodes() {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    let tempTreeDetails = tempTreeClaim.treeDetails
    let tempSelectedNodes: NodeId[] = []
    const nodesForDuplication =
      props.selectedNodes.length > 0 ? props.selectedNodes : [props.nodeId]
    //props.setRenderLines(false)
    let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)
    ;[tempTreeClaim.treeDetails, tempSelectedNodes, tempNodesMovedForEditMode] =
      duplicateNodes(
        nodesForDuplication,
        tempTreeDetails,
        treeNodeMode,
        props.treeIndex,
        tempNodesMovedForEditMode,
        props.editMode,
      )

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      `treeNode-${props.treeIndex}_${props.nodeId}`,
      UndoRedoType.button,
      props.treeIndex,
      tempTreeClaim.analysisResults,
      props.ownRole,
    )

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
    if (tempMainTree.includes('node123')) return
    props.setMainTree(tempMainTree)
    props.setOrphanTrees(tempOrphanTrees)

    props.setSelectedNodes(tempSelectedNodes)
    setNodesMovedForEditMode(tempNodesMovedForEditMode)
    setScenarioSnapshot(tempScenarioSnapshot)
    // updateXarrow()
    if (props.selectedNodes.length > 0) {
      //Mixpanel 121 (All)
      logActivity(
        freemium.isFreemium,
        'Duplicated selected nodes in legal tree',
      )
    } else {
      //Mixpanel 122 (All)
      logActivity(freemium.isFreemium, 'Duplicated node in legal tree')
    }
    setTimeout(() => {
      props.setRenderLines(true)
      for (let tempNodeId of Object.keys(tempTreeClaim.treeDetails.nodes)) {
        changeTextAreaHeight(
          document.getElementById(
            `treeNodeTitleTextarea-${props.treeIndex}_${tempNodeId}`,
          )!,
        )
      }
    }, 100)
  }

  function copySelectedNodes(e: any) {
    if (props.selectedNodes.length > 0 && allowShortcuts) {
      if (
        ((e.code === 'KeyC' && e.ctrlKey && !isMac()) ||
          (e.code === 'KeyC' && e.metaKey && isMac())) &&
        e.target.tagName.toLowerCase() !== 'textarea' &&
        e.target.tagName.toLowerCase() !== 'input'
      ) {
        e.preventDefault()
        handleCopyNodes()
      }
    }
  }

  async function handleCopyNodes() {
    const nodesForCopy =
      props.selectedNodes.length > 0 ? props.selectedNodes : [props.nodeId]

    const copyResult = copyNodes(nodesForCopy, treeDetails, treeNodeMode)

    await navigator.clipboard.writeText(JSON.stringify(copyResult))
    //Mixpanel 178 (All)
    logActivity(freemium.isFreemium, 'Copied tree nodes to clipboard')
    if (props.setCopiedNodes) {
      props.setCopiedNodes(nodesForCopy)
    }

    setTimeout(() => {
      if (props.setCopiedNodes) {
        props.setCopiedNodes([])
      }
    }, 1000)
  }

  function handleConnectNewLineEvent(
    eventId: EventId,
    connectionNodeId: NodeId,
  ) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    let treeDetails = tempTreeClaim.treeDetails
    if (
      isEventEligibleToConnect(eventId, connectionNodeId, treeDetails) === false
    ) {
      props.setConnectionCreatesLoop(eventId)
      setTimeout(() => {
        props.setRenderLines(true)
      }, 20)
      setTimeout(() => {
        props.setConnectionCreatesLoop(undefined)
      }, 2500)
      return
    }

    //Add the connectionNodeId to the childrenNodes of the new eventId
    treeDetails.events[eventId].childrenNodes.push(connectionNodeId)
    treeDetails.nodes[connectionNodeId].root = RootNodeType.noRoot

    if (props.lineClick) {
      let previousEventId = props.lineClick![1]
      //Remove the connectionNodeId from the childrenNodes of the previousEvent
      treeDetails.events[previousEventId].childrenNodes = treeDetails.events[
        previousEventId
      ].childrenNodes.filter((nodeId) => nodeId !== connectionNodeId)
      props.handleSetLineClick(`noded3`, 'eventF2', false)
    }

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      `treeNode-${props.treeIndex}_${props.nodeId}`,
      UndoRedoType.addRemove,
      props.treeIndex,
      false,
      props.ownRole,
    )

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
    if (tempMainTree.includes('node123')) {
      props.setConnectionCreatesLoop(eventId)
      setTimeout(() => {
        props.setRenderLines(true)
      }, 100)
      setTimeout(() => {
        props.setConnectionCreatesLoop(undefined)
      }, 1500)
      return
    }
    //props.setRenderLines(false)
    props.setMainTree(tempMainTree)
    props.setOrphanTrees(tempOrphanTrees)
    props.setNodeCreatingLine(undefined)
    props.setDragPosition(undefined)
    setScenarioSnapshot(tempScenarioSnapshot)
    //hide Treetable
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
      setTreeTables(tempTreeTables)
    }
    // updateXarrow()

    setTimeout(() => {
      props.setRenderLines(true)
    }, 20)
  }

  function handleChangeLineNode(nodeIdToConnect: NodeId) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    let treeDetails = tempTreeClaim.treeDetails

    if (
      isEventEligibleToConnect(
        props.lineClick![1],
        nodeIdToConnect,
        treeDetails,
      ) === false
    ) {
      props.setConnectionCreatesLoop(nodeIdToConnect)
      setTimeout(() => {
        props.setRenderLines(true)
      }, 20)
      setTimeout(() => {
        props.setConnectionCreatesLoop(undefined)
      }, 2500)
      return
    }

    // props.setRenderLines(false)
    if (props.lineClick) {
      const previousNodeId = props.lineClick![0]
      const parentEventId = props.lineClick![1]
      //add the nodeIdToConnect to the ChildrenNodes of the parentEventId
      treeDetails.events[parentEventId].childrenNodes.push(nodeIdToConnect)

      //remove the previousNodeId from the ChildrenNodes of the parentEventId
      treeDetails.events[parentEventId].childrenNodes = treeDetails.events[
        parentEventId
      ].childrenNodes.filter((nodeId) => nodeId !== previousNodeId)
      treeDetails.nodes[nodeIdToConnect].root = RootNodeType.noRoot

      const previousNode = tempTreeClaim.treeDetails.nodes[previousNodeId]

      const siblingIndex = previousNode.nodeSiblingIndex as {
        [parentEventId: string]: number
      }
      if (Object.keys(siblingIndex).length > 1) {
        delete siblingIndex[parentEventId]
      } else {
        previousNode.root = RootNodeType.orphanTreeRoot
        previousNode.nodeSiblingIndex = {}
      }
    }

    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      `treeNode-${props.treeIndex}_${props.nodeId}`,
      UndoRedoType.addRemove,
      props.treeIndex,
      false,
      props.ownRole,
    )

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    try {
      ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
        findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
      if (
        tempMainTree.includes('node123') ||
        tempOrphanTrees.includes(['node123'])
      ) {
        setTimeout(() => {
          props.setRenderLines(true)
        }, 20)

        return
      }
      props.setMainTree(tempMainTree)
      props.setOrphanTrees(tempOrphanTrees)
      props.setNodeCreatingLine(undefined)
      props.setDragPosition(undefined)
      setScenarioSnapshot(tempScenarioSnapshot)
      //hide Treetable
      const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
        (treeTableInfo) =>
          treeTableInfo.treeId ===
          scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
      )

      let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
      if (
        tempTreeTables.treeTablesInfo &&
        tempTreeTables.treeTablesInfo[treeTableInfoIndex]
      ) {
        tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
        setTreeTables(tempTreeTables)
      }
      props.handleSetLineClick(`noded3`, 'eventF2', false)
      // updateXarrow()

      //Mixpanel 128 (All)
      logActivity(
        freemium.isFreemium,
        'Changed a line from one node to another',
      )
      setTimeout(() => {
        props.setRenderLines(true)
      }, 100)
    } catch (error) {
      if (
        error instanceof RangeError &&
        error.message === 'Maximum call stack size exceeded'
      ) {
        // Handle the error here
        console.error(
          'Maximum call stack size exceeded. There may be a recursive function or infinite loop.',
        )
        // You can also add code to gracefully exit or handle the situation as needed.
      } else {
        // Handle other types of errors
        console.error('An unexpected error occurred:', error)
      }
    }
  }

  function isEventHighlighted(eventId: EventId) {
    if (props.openOrphanNodesWarningPopUp) {
      if (props.orphanTrees.flat().includes(props.nodeId)) {
        return true
      }
    }
    if (props.openZeroProbabilityWarningPopUp) {
      if (
        findZeroProbabilityEventsInMainTree(
          treeDetails,
          props.orphanTrees.flat(),
        ).includes(eventId)
      ) {
        return true
      }
    }
    return false
  }

  function isNodeHighlighted() {
    if (props.openOrphanNodesWarningPopUp) {
      if (props.orphanTrees.flat().includes(props.nodeId)) {
        return true
      }
    }
    if (props.openZeroProbabilityWarningPopUp) {
      if (
        findZeroProbabilityEventsInMainTree(
          treeDetails,
          props.orphanTrees.flat(),
        ).filter(
          (eventId) =>
            treeDetails.events[eventId as EventId].nodeOfEventId ===
            props.nodeId,
        ).length > 0
      ) {
        return true
      }
    }
    return false
  }

  const lowerOpacity = () => {
    const hasAnalysisResults =
      (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
        .analysisResults &&
      treeAnalysis.treeTablesInfo.filter(
        (treeAnalysisObject) =>
          treeAnalysisObject.treeId ===
          (
            scenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ] as TreeClaim
          ).id,
      )[0] &&
      treeAnalysis.treeTablesInfo.filter(
        (treeAnalysisObject) =>
          treeAnalysisObject.treeId ===
          (
            scenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ] as TreeClaim
          ).id,
      )[0].results !== undefined

    if (hoverOrphanNode) {
      return false
    }

    if (
      hasAnalysisResults ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Done ||
      props.ownRole === AccessRole.VIEWER
    ) {
      return true
    }

    return false
  }

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        handle=".treeNodeMoveHandle"
        position={{
          x: props.differenceFromLeft
            ? props.nodeDetails.properties.position[0] -
              props.differenceFromLeft
            : props.nodeDetails.properties.position[0],
          y: props.differenceFromTop
            ? props.nodeDetails.properties.position[1] - props.differenceFromTop
            : props.nodeDetails.properties.position[1],
        }}
        onStart={() => {
          // updateXarrow()
        }}
        onDrag={(e: any, data) => {
          if (props.selectedNodes.includes(props.nodeId)) {
            for (let selectedNodeId of props.selectedNodes.filter(
              (nodeId) => nodeId !== props.nodeId,
            )) {
              const nodeElement = document.getElementById(
                `treeNode-${props.treeIndex}_${selectedNodeId}`,
              )
              if (nodeElement) {
                // Get the computed style of the element
                let style = nodeElement.getAttribute('style')

                const translateMatch = style!.match(
                  /transform:\s*translate\(([^,]+),\s*([^)]+)\)/,
                )
                const translateX = translateMatch
                  ? parseFloat(translateMatch[1])
                  : null
                const translateY = translateMatch
                  ? parseFloat(translateMatch[2])
                  : null
                if (
                  translateX !== null &&
                  translateY !== null &&
                  style !== null
                ) {
                  const newPositionX = translateX + data.deltaX
                  const newPositionY = translateY + data.deltaY
                  if (newPositionX > 0) {
                    style = style!.replace(
                      translateX?.toString()!,
                      newPositionX.toString()!,
                    )
                  }

                  if (newPositionY > 0) {
                    style = style!.replace(
                      translateY?.toString()!,
                      newPositionY.toString()!,
                    )
                  }
                  nodeElement.setAttribute('style', style)
                }
              }
            }
          }
          updateXarrow()
        }}
        onStop={(e, data) => {
          // updateXarrow()
          handleChangeNodePosition(
            [
              `treeNodeMoveHandle-${props.treeIndex}_${props.nodeId}`,
              `treeNodeMoveHandle-${props.treeIndex}_${props.nodeId}`,
            ],
            [roundNumberTo(data.x, 2), roundNumberTo(data.y, 2)],
          )
        }}
        scale={props.zoomLevel}
        bounds={{
          left:
            props.selectedNodes.length > 0 &&
            typeof (findLeftMostNodeOfMainTree(
              treeDetails,
              props.selectedNodes,
            ) as NodeId) === 'string'
              ? nodeX(props.nodeId, treeDetails) -
                nodeX(
                  findLeftMostNodeOfMainTree(
                    treeDetails,
                    props.selectedNodes,
                  ) as NodeId,
                  treeDetails,
                )
              : 0,
          top:
            props.selectedNodes.length > 0 &&
            typeof (findTopMostNodeOfMainTree(
              treeDetails,
              props.selectedNodes,
              props.treeIndex,
              treeNodeMode,
            ) as NodeId) === 'string'
              ? nodeY(props.nodeId, treeDetails) -
                nodeY(
                  findTopMostNodeOfMainTree(
                    treeDetails,
                    props.selectedNodes,
                    props.treeIndex,
                    treeNodeMode,
                  ) as NodeId,
                  treeDetails,
                )
              : 0,
        }}
      >
        <div
          className={`treeNode ${
            localNodeMode === NodeMode.maximised ? 'minimised' : null
          } ${startAnimation ? 'fadeout' : ''}`}
          ref={nodeRef}
          id={`treeNode-${props.treeIndex}_${props.nodeId}`}
          style={
            lowerOpacity()
              ? props.mainTree.includes(props.nodeId)
                ? props.nodesUnderZeroProbability.includes(props.nodeId)
                  ? { opacity: 0.2 }
                  : { opacity: 1 }
                : { opacity: 0.2 }
              : { opacity: 1 }
          }
          onMouseEnter={() => setHoverOrphanNode(true)}
          onMouseLeave={() => setHoverOrphanNode(false)}
        >
          <TreeNodeTitleComponent
            nodeId={props.nodeId}
            root={props.root}
            events={props.events}
            treeIndex={props.treeIndex}
            nodeDetails={props.nodeDetails}
            localNodeMode={localNodeMode}
            nodeHasChildren={nodeHasChildren}
            handleAddEvent={handleAddEvent}
            handleNodeTitleChange={handleNodeTitleChange}
            handleDeleteTreeNode={props.handleDeleteTreeNode}
            selectedNodes={props.selectedNodes}
            setSelectedNodes={props.setSelectedNodes}
            zoomLevel={props.zoomLevel}
            handleDuplicateNodes={handleDuplicateNodes}
            handleCopyNodes={handleCopyNodes}
            nodeCreatingLine={props.nodeCreatingLine}
            setNodeCreatingLine={props.setNodeCreatingLine}
            setDragPosition={props.setDragPosition}
            lineClick={props.lineClick}
            setChangingLineStartId={props.setChangingLineStartId}
            availableTargetsForLine={props.availableTargetsForLine}
            setAvailableTargetsForLine={props.setAvailableTargetsForLine}
            handleChangeLineNode={handleChangeLineNode}
            showTreeInfo={props.showTreeInfo}
            scenarioSnapshot={scenarioSnapshot}
            treeDetails={treeDetails}
            debugging={props.debugging}
            setOpenDeleteNodeOrEventMenu={props.setOpenDeleteNodeOrEventMenu}
            orphanTrees={props.orphanTrees}
            connectedDot={props.connectedDot}
            setConnectedDot={props.setConnectedDot}
            handleConnectNewLineEvent={handleConnectNewLineEvent}
            highlighted={isNodeHighlighted()}
            ownRole={props.ownRole}
            connectionCreatesLoop={props.connectionCreatesLoop}
            copied={
              props.copiedNodes !== undefined &&
              props.copiedNodes.includes(props.nodeId)
            }
          >
            {Object.keys(props.events)
              .filter(
                (eventId) =>
                  props.events[eventId as EventId].nodeOfEventId ===
                  props.nodeId,
              )
              .map((eventId, index) => (
                <React.Fragment key={`treeEvent-${eventId}`}>
                  {treeNodeMode === NodeMode.minimised &&
                  localNodeMode === NodeMode.minimised ? (
                    <>
                      <div
                        className="treeEventMinimisedShadow"
                        style={{ left: index * 157 }}
                      ></div>
                      <TreeEventMinimised
                        eventDetails={
                          props.events[eventId as EventId].eventDetails
                        }
                        key={eventId}
                        nodeId={props.nodeId}
                        eventId={eventId as EventId}
                        eventIndex={props.events[eventId as EventId].eventIndex}
                        treeIndex={props.treeIndex}
                        nodeSelected={props.selectedNodes.includes(
                          props.nodeId,
                        )}
                        nodeHasAtLeastOneAwardedAmount={
                          nodeHasAtLeastOneAwardedAmount
                        }
                        nodeHasAtLeastOneOutOfCourtAmount={
                          nodeHasAtLeastOneOutOfCourtAmount
                        }
                        nodeHasAtLeastOneInterestMenuOn={
                          nodeHasAtLeastOneInterestMenuOn
                        }
                        nodeHasAtLeastOneReducedAmount={
                          nodeHasAtLeastOneReducedAmount
                        }
                        nodeHasAtLeastOneEventTitle={
                          nodeHasAtLeastOneEventTitle
                        }
                        showTreeInfo={props.showTreeInfo}
                        scenarioSnapshot={scenarioSnapshot}
                        resetActivePath={props.resetActivePath}
                        selectedPath={props.selectedPath}
                        debugging={props.debugging}
                        highlighted={isEventHighlighted(eventId as EventId)}
                        zoomLevel={props.zoomLevel}
                        connectionCreatesLoop={props.connectionCreatesLoop}
                        ownRole={props.ownRole}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="treeEventShadow"
                        style={{ left: index * 261 }}
                      ></div>
                      <TreeEvent
                        childrenNodes={
                          props.events[eventId as EventId].childrenNodes
                        }
                        eventDetails={
                          props.events[eventId as EventId].eventDetails
                        }
                        nodeMode={localNodeMode}
                        nodeId={props.nodeId}
                        eventId={eventId as EventId}
                        eventIndex={props.events[eventId as EventId].eventIndex}
                        eventsInNode={props.nodeDetails.numberOfEvents}
                        treeIndex={props.treeIndex}
                        handleChangeEventProbability={
                          handleChangeEventProbability
                        }
                        nodeProbabilities={
                          props.nodeProbabilities[props.nodeId]
                        }
                        shakeScenario={
                          props.shakeScenario
                            ? props.shakeScenario[0] === props.nodeId
                              ? props.shakeScenario[1]
                              : undefined
                            : undefined
                        }
                        key={eventId}
                        editMode={props.editMode}
                        setEditMode={props.setEditMode}
                        zoomLevel={props.zoomLevel}
                        nodeSelected={props.selectedNodes.includes(
                          props.nodeId,
                        )}
                        elementsToBeDeletedForAnimation={
                          props.elementsToBeDeletedForAnimation
                        }
                        setElementsToBeDeletedForAnimation={
                          props.setElementsToBeDeletedForAnimation
                        }
                        setMainTree={props.setMainTree}
                        setOrphanTrees={props.setOrphanTrees}
                        showTreeInfo={props.showTreeInfo}
                        resetActivePath={props.resetActivePath}
                        selectedPath={props.selectedPath}
                        debugging={props.debugging}
                        nodeHasAtLeastOneInterestMenuOn={
                          nodeHasAtLeastOneInterestMenuOn
                        }
                        nodeHasAtLeastOneAwardedAmount={
                          nodeHasAtLeastOneAwardedAmount
                        }
                        setOpenDeleteNodeOrEventMenu={
                          props.setOpenDeleteNodeOrEventMenu
                        }
                        handleDeleteTreeEvent={props.handleDeleteTreeEvent}
                        highlighted={isEventHighlighted(eventId as EventId)}
                        errors={props.errors}
                        connectionCreatesLoop={props.connectionCreatesLoop}
                        ownRole={props.ownRole}
                      />
                    </>
                  )}
                  <div
                    className={`treeAddNodeDotContainer  ${
                      localNodeMode === NodeMode.minimised ? 'minimised' : ''
                    }`}
                    id={`treeAddNodeDotContainer-${props.treeIndex}_${
                      props.nodeId
                    }!${props.events[eventId as EventId].eventIndex}`}
                  >
                    {props.availableTargetsForLine.includes(
                      eventId as EventId,
                    ) ? (
                      <div
                        className="treeAddNodeDotEventAvailable"
                        id={`treeAddChildDotEventAvailable-${props.treeIndex}_${
                          props.nodeId
                        }!${props.events[eventId as EventId].eventIndex}`}
                        onDragEnter={(e: any) =>
                          props.setConnectedDot(eventId as EventId)
                        }
                      >
                        {localNodeMode === NodeMode.maximised ? (
                          <img
                            src={
                              props.connectedDot === eventId
                                ? isConnectedImg
                                : canBeConnectedImg
                            }
                            alt="dotConnectionImg"
                            className="treeAddNodeDotEventAvailableImg"
                          />
                        ) : (
                          <img
                            src={
                              props.connectedDot === eventId
                                ? isConnectedImgMinimisedImg
                                : canBeConnectedImgMinimisedImg
                            }
                            alt="dotConnectionImg"
                            className="treeAddNodeDotEventAvailableImgMinimised"
                          />
                        )}
                      </div>
                    ) : null}
                    <div
                      className={`treeAddNodeDot ${
                        props.selectedNodes.includes(props.nodeId)
                          ? 'selected'
                          : ''
                      } ${
                        props.lineClick?.includes(eventId as EventId)
                          ? 'clicked'
                          : ''
                      }
                      ${localNodeMode === NodeMode.minimised ? 'minimised' : ''}
                      ${
                        props.selectedPath.includes(
                          findEventNumber(
                            eventId as EventId,
                            treeDetails,
                          ) as number,
                        ) &&
                        props.resetActivePath &&
                        localNodeMode === NodeMode.maximised
                          ? 'active'
                          : ''
                      } ${krogerusColors ? 'krogerus' : ''}`}
                      id={`treeAddNodeDot-${props.treeIndex}_${props.nodeId}!${
                        props.events[eventId as EventId].eventIndex
                      }`}
                      draggable={props.lineClick?.includes(eventId as EventId)}
                      onDragStart={(e: any) => {
                        props.setChangingLineStartId(
                          `connectionCircle-${props.treeIndex}_${
                            props.lineClick![0]
                          }`,
                        )
                        if (e && e.dataTransfer) {
                          e.dataTransfer.setDragImage(img, 0, 0)
                        }
                      }}
                      onDrag={(e) => {
                        if (dragTimeoutOn) {
                          setDragTimeoutOn(false)
                          setTimeout(() => {
                            // updateXarrow()
                            props.setDragPosition({
                              position: 'fixed',
                              left: e.clientX,
                              top: e.clientY,
                              transform: 'none',
                              opacity: 0,
                            })
                            setDragTimeoutOn(true)
                          }, 60)
                        }
                      }}
                      onDragEnd={() => {
                        props.setChangingLineStartId(undefined)
                        props.setDragPosition(undefined)
                        if (props.connectedDot) {
                          //Mixpanel 129 (All)
                          logActivity(
                            freemium.isFreemium,
                            'Changed a line from an event parent to another event parent in legal tree',
                          )
                          handleConnectNewLineEvent(
                            props.connectedDot as EventId,
                            props.lineClick![0],
                          )
                        }
                        props.setConnectedDot(undefined)
                      }}
                    ></div>
                  </div>
                </React.Fragment>
              ))}
          </TreeNodeTitleComponent>
          {props.showTreeInfo && props.debugging ? (
            <div
              id={`showNodeInfo-${props.treeIndex}-${props.nodeId}`}
              style={{ zIndex: -1 }}
            >
              <br />
              <strong>
                {treeDetails.nodes[props.nodeId].root} - HEIGHT:{' '}
                {heightOfNode(
                  localNodeMode,
                  props.nodeId,
                  props.treeIndex,
                  treeDetails,
                  props.editMode === props.nodeId,
                )}
              </strong>
              <br />
              {props.nodeId} <br />
              {`[left:${nodeX(props.nodeId, treeDetails)},right: ${
                nodeX(props.nodeId, treeDetails) +
                widthOfNode(props.nodeId, localNodeMode, treeDetails)
              }, up:${nodeY(props.nodeId, treeDetails)}, down: ${
                nodeY(props.nodeId, treeDetails) +
                heightOfNode(
                  localNodeMode,
                  props.nodeId,
                  props.treeIndex,
                  treeDetails,
                  props.editMode === props.nodeId,
                )
              }]`}
              <br />
              {' SiblingIndex:'}
              {JSON.stringify(
                changeEventKeysInNodeSiblingIndex(
                  treeDetails.nodes[props.nodeId].nodeSiblingIndex,
                  treeDetails,
                ),
              )}
            </div>
          ) : null}
        </div>
      </Draggable>
    </>
  )
}
