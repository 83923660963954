import { atom } from 'recoil'
import { SnapshotStatus } from '../models/enums'
import { ScenarioIdentity } from '../models/generalTypes'

export const scenarioIdentityState = atom<ScenarioIdentity>({
  key: 'scenarioIdentityState',
  default: {
    caseId: '',
    scenarioId: '',
    snapshotId: '',
    caseName: '',
    scenarioName: '',
    snapshotStatus: SnapshotStatus.None,
    snapshotProgress: 0,
    results: undefined,
    hasReport: false,
  },
})
