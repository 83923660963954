import React from 'react'
import { getText } from '../../../../../../../services/textFunctions'
import englishTexts from '../../../../../../../resources/texts/englishTexts.json'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../states/UserState'

type Props = {
  onClick: () => void
  sorting: string | boolean
  columnClass: string
  dataTextAttribute: `title-${number}`
  id: string
}

export default function TreeTableTitle(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <div className="treeTableTitleTextContainer">
      <p
        className={`treeTableTitleText ${props.columnClass}`}
        onClick={props.onClick}
        id={`treeTableTitleText-${props.id}`}
        data-textattribute={props.dataTextAttribute}
      >
        {getText(
          props.dataTextAttribute as keyof typeof englishTexts,
          user.settings,
        )}
      </p>
      <span
        className={`treeTableSortingArrowImg ${props.columnClass}`}
        onClick={props.onClick}
      >
        {props.sorting === 'asc' ? ' ⬆' : props.sorting === 'des' ? ' ⬇' : null}
      </span>
    </div>
  )
}
