import { ClaimType } from '../enums'
import { EventDetails } from './eventDetails'
import { TreeEventClass } from './treeEvent'
import { TreeNodeClass } from './treeNode'
import {
  EventId,
  NodeId,
  NodeMode,
  RootNodeType,
  TreeId,
  TreeLines,
} from './treeTypes'
import { v4 as uuid } from 'uuid'

export type TreeDetails = {
  nodes: {
    [nodeId: NodeId]: TreeNodeClass
  }
  events: {
    [eventId: EventId]: TreeEventClass
  }
}
export class TreeClaim {
  name: string
  type: ClaimType.tree // 'tree'
  id: TreeId
  claimtypeId: `Decision Tree ${number}`
  numOfTreeScenarios: number
  totalClaimedAmount: number | undefined
  totalCounterClaimedAmount: number | undefined
  totalClaimedAmountInterest1st: number | undefined
  totalCounterClaimedAmountInterest1st: number | undefined
  totalClaimedAmountInterest2nd: number | undefined
  totalCounterClaimedAmountInterest2nd: number | undefined
  treeWeightedValue: number | undefined
  treeWeightedValueInterest1st: number | undefined
  treeWeightedValueInterest2nd: number | undefined
  maxAmountIndex: number | undefined
  minAmountIndex: number | undefined
  nodeMode: NodeMode
  treeLines: TreeLines
  zoomLevel: number // number between 0,3 and 1,2
  analysisResults: boolean
  trialDates: {
    firstTrialDate: string | undefined
    secondTrialDate: string | undefined
  }
  treeDetails: TreeDetails

  constructor(
    name: string,
    type: ClaimType.tree,
    id: TreeId,
    claimtypeId: `Decision Tree ${number}`,
    numOfTreeScenarios: number,
    totalClaimedAmount: number | undefined,
    totalCounterClaimedAmount: number | undefined,
    totalClaimedAmountInterest1st: number | undefined,
    totalCounterClaimedAmountInterest1st: number | undefined,
    totalClaimedAmountInterest2nd: number | undefined,
    totalCounterClaimedAmountInterest2nd: number | undefined,
    treeWeightedValue: number | undefined,
    treeWeightedValueInterest1st: number | undefined,
    treeWeightedValueInterest2nd: number | undefined,
    maxAmountIndex: number | undefined,
    minAmountIndex: number | undefined,
    nodeMode: NodeMode,
    treeLines: TreeLines,
    zoomLevel: number,
    analysisResults: boolean,
    trialDates: {
      firstTrialDate: string | undefined
      secondTrialDate: string | undefined
    },
    treeDetails: TreeDetails,
  ) {
    this.name = name
    this.type = type
    this.id = id
    this.claimtypeId = claimtypeId
    this.numOfTreeScenarios = numOfTreeScenarios
    this.totalClaimedAmount = totalClaimedAmount
    this.totalCounterClaimedAmount = totalCounterClaimedAmount
    this.totalClaimedAmountInterest1st = totalClaimedAmountInterest1st
    this.totalCounterClaimedAmountInterest1st =
      totalCounterClaimedAmountInterest1st
    this.totalClaimedAmountInterest2nd = totalClaimedAmountInterest2nd
    this.totalCounterClaimedAmountInterest2nd =
      totalCounterClaimedAmountInterest2nd
    this.treeWeightedValue = treeWeightedValue
    this.treeWeightedValueInterest1st = treeWeightedValueInterest1st
    this.treeWeightedValueInterest2nd = treeWeightedValueInterest2nd
    this.maxAmountIndex = maxAmountIndex
    this.minAmountIndex = minAmountIndex
    this.nodeMode = nodeMode
    this.treeLines = treeLines
    this.zoomLevel = zoomLevel
    this.analysisResults = analysisResults
    this.trialDates = trialDates
    this.name = name
    this.claimtypeId = claimtypeId
    this.treeDetails = treeDetails
  }

  static initialTree(
    name: string,
    trialDates: {
      firstTrialDate: string | undefined
      secondTrialDate: string | undefined
    },
    claimtypeId: `Decision Tree ${number}`,
    nodeId: NodeId,
    eventId1: EventId,
    eventId2: EventId,
  ): TreeClaim {
    return new TreeClaim(
      name, //name
      ClaimType.tree, //type
      `tree${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`, // id (change it to something more clever)
      claimtypeId, // claimtypeId
      2, // numOfTreeScenarios
      0, // totalClaimedAmount
      0, // totalCounterClaimedAmount
      0, // totalClaimedAmountInterest1st
      0, // totalCounterClaimedAmountInterest1st
      0, // totalClaimedAmountInterest2nd
      0, // totalCounterClaimedAmountInterest2nd
      0, // treeWeightedValue
      0, // treeWeightedValueInterest1st
      0, // treeWeightedValueInterest2nd
      undefined, //maxAmountIndex
      undefined, //minAmountIndex
      NodeMode.maximised, // maximised
      TreeLines.smooth,
      0.9, // zoomLevel (maybe set to 1)
      false, // analysisResults
      trialDates, // trialDates
      {
        // treeDetails
        nodes: {
          [nodeId]: TreeNodeClass.defaultTreeNode(
            {},
            [40, 40],
            RootNodeType.mainTreeRoot,
          ),
        },
        events: {
          [eventId1]: TreeEventClass.defaultTreeEvent(
            nodeId,
            0,
            EventDetails.defaultEventDetails(0.5),
          ),
          [eventId2]: TreeEventClass.defaultTreeEvent(
            nodeId,
            1,
            EventDetails.defaultEventDetails(0.5),
          ),
        },
      },
    )
  }
}
