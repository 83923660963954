import { TreeDetails } from './treeClaim'

export enum NodeMode {
  maximised = 'maximised',
  minimised = 'minimised',
}

export enum TreeLines {
  grid = 'grid',
  smooth = 'smooth',
}

export type TreeNodeProperties = {
  position: [x: number, y: number]
}

export enum RootNodeType {
  mainTreeRoot = 'mainTreeRoot',
  orphanTreeRoot = 'orhanTreeRoot',
  noRoot = 'noRoot',
}

export type NodesMovedForEditMode = {
  [nodeId: string]: {
    previousPosition: [number, number]
    newPosition: [number, number]
  }
}

export enum DeleteMenuType {
  event = 'event',
  node = 'node',
  selectedNodesWithChildren = 'selectedNodesWithChildren',
  selectedNodesLastRoot = 'selectedNodesLastRoot',
  deleteOnlySelectedNodes = 'deleteOnlySelectedNodes',
  lastRoot = 'lastRoot',
  lastNode = 'lastNode',
}

export type DeleteNodeOrEventMenuObject = {
  deleteMenuType: DeleteMenuType
  nodeId: NodeId
  nodeMode: NodeMode
  eventIndex?: number
  eventId?: EventId
}

export type DefaultHeightsOfNode = {
  maximised: {
    state: {
      [key in HeightOfNodeState]: number
    }
  }
  minimised: {
    state: {
      [key in HeightOfNodeState]: number
    }
  }
  editMode: {
    state: {
      [key in HeightOfNodeState]: number
    }
  }
}

export enum HeightOfNodeState {
  // 1.awardedAmount: Off, interest: Off, reducedAmount: Off, externalValue: Off [0 0 0 0]
  noAmounts_1 = 'noAmounts',
  // 2.awardedAmount: On, interest: Off, reducedAmount: Off, externalValue: Off [1 0 0 0]
  awardedAmountOnly_2 = 'awardedAmountOnly',
  // 3.awardedAmount: On, interest: On, reducedAmount: Off, externalValue: Off [1 1 0 0]
  awardedAmountAndInterestOnly_3 = 'awardedAmountAndInterestOnly',
  // 4.awardedAmount: On, interest: Off, reducedAmount: On, externalValue: Off [1 0 1 0]
  awardedAmountAndReduced_4 = 'awardedAmountAndReduced',
  // 5.awardedAmount: On, interest: On, reducedAmount: On, externalValue: Off [1 1 1 0]
  awardedAmountAndInterestAndReduced_5 = 'awardedAmountAndInterestAndReduced',
  // 6.awardedAmount: On, interest: Off, reducedAmount: Off, externalValue: On [1 0 0 1]
  awardedAmountAndExternal_6 = 'awardedAmountAndExternal',
  // 7.awardedAmount: On, interest: On, reducedAmount: Off, externalValue: On [1 1 0 1]
  awardedAmountAndInterestAndExternal_7 = 'awardedAmountAndInterestAndExternal',
  // 8.awardedAmount: On, interest: Off, reducedAmount: On, externalValue: On [1 0 1 1]
  awardedAmountAndReducedAndExternal_8 = 'awardedAmountAndReducedAndExternal',
  // 9.awardedAmount: Off, interest: Off, reducedAmount: On, externalValue: Off [0 0 1 0]
  reducedAmountOnly_9 = 'reducedAmountOnly',
  // 10.awardedAmount: Off, interest: Off, reducedAmount: On, externalValue: On [0 0 1 1]
  reducedAmountAndExternal_10 = 'reducedAmountAndExternal',
  // 11.awardedAmount: Off, interest: Off, reducedAmount: Off, externalValue: On [0 0 0 1]
  externalOnly_11 = 'externalOnly',
  // 12.awardedAmount: On, interest: On, reducedAmount: On, externalValue: On [1 1 1 1]
  fullAmounts_12 = 'fullAmounts',
}

export type EventId = `event${string}${number}`
export type NodeId = `node${string}${number}`
export type TreeId = `tree${string}${number}`
export type ClaimId = `claim${string}${number}`
export type SectionId = `section${string}${number}`

export type TreeObjectForCalculation = {
  firstTrialDate: string | undefined
  secondTrialDate: string | undefined
  treeDetails: TreeDetails
  snapshotid: string
  treeid: TreeId
}
