import { ClaimType } from '../../models/enums'

export function dropDownValidation(name: string) {
  return name !== ''
}

export function validateProbs(value: number) {
  return value <= 100 && value >= 0
}

export const trialsValidationRule = (
  firstTrialDate: string | undefined,
  secondTrialDate: string | undefined,
  probSecondTrial: number | undefined,
) => {
  if (firstTrialDate && secondTrialDate && probSecondTrial) {
    if (probSecondTrial > 0) {
      return (
        new Date(firstTrialDate)!.getTime() <
        new Date(secondTrialDate)!.getTime()
      )
    }
  }

  return true
}

export const isProperDate = (
  interestDate: string | undefined,
  firstTrialDate: string | undefined,
) => {
  if (firstTrialDate && interestDate) {
    return new Date(interestDate).getTime() < new Date(firstTrialDate).getTime()
  }

  return false
}

export function maxAmountValidation(maxAmount: number, amount: number) {
  return maxAmount >= amount
}

export function treeNameValidation(
  state: any,
  treeName: string,
  claimIndex: number,
) {
  var nameExists = false
  if (state.claims) {
    for (let i = 0; i < state.claims.length; i++) {
      if (i === claimIndex) {
      } else {
        if (
          state.claims[i]['type'] === ClaimType.tree &&
          state.claims[i]['name'].toLowerCase() === treeName.toLowerCase()
        ) {
          nameExists = true
        }
      }
    }
  }

  return !nameExists
}
