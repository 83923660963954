import React from 'react'
import { useRecoilValue } from 'recoil'
import { AccessRole, AccessRoleKeys, UserRole } from '../../../models/enums'
import { UserForAccess } from '../../../models/generalTypes'
import emptyCaseArrow from '../../../resources/images/124-blueBigArrowDown.svg'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'

type Props = {
  type: 'Scenario' | 'Case'
  usersListWhenNoScenarios: UserForAccess[] | undefined
  showDeleted?: boolean
}

export default function EmptyMessage(props: Props) {
  const user = useRecoilValue(userState)

  const isViewerForScenario = () => {
    if (props.type === 'Scenario' && props.usersListWhenNoScenarios) {
      const filtered = props.usersListWhenNoScenarios.filter(
        (userObj) => userObj.username === user.email,
      )

      if (filtered && filtered.length > 0) {
        const ownRole =
          AccessRole[filtered[0].access_role!.toString() as AccessRoleKeys]

        return ownRole === AccessRole.VIEWER
      }
    }

    return user.role === UserRole.APP_VIEWER
  }

  return (
    <>
      <div className="emptyCaseMessageContainer">
        <p
          className="emptyCaseMessageTitle"
          id="emptyCaseMessageTitle"
          data-textattribute={
            props.showDeleted
              ? 'description-147'
              : props.type === 'Scenario'
              ? isViewerForScenario()
                ? 'title-167'
                : 'title-70'
              : user.role === UserRole.APP_VIEWER
              ? 'title-168'
              : 'title-71'
          }
        >
          {props.showDeleted
            ? getText('description-147', user.settings)
            : props.type === 'Scenario'
            ? isViewerForScenario()
              ? getText('title-167', user.settings)
              : getText('title-70', user.settings)
            : user.role === UserRole.APP_VIEWER
            ? getText('title-168', user.settings)
            : getText('title-71', user.settings)}
        </p>
        <p
          className="emptyCaseMessageSubTitle"
          id="emptyCaseMessageSubTitle"
          data-textattribute={
            props.showDeleted
              ? undefined
              : props.type === 'Scenario'
              ? isViewerForScenario()
                ? undefined
                : 'description-1'
              : user.role === UserRole.APP_VIEWER
              ? 'description-129'
              : undefined
          }
        >
          {props.showDeleted
            ? undefined
            : props.type === 'Scenario'
            ? isViewerForScenario()
              ? undefined
              : getText('description-1', user.settings)
            : user.role === UserRole.APP_VIEWER
            ? getText('description-129', user.settings)
            : undefined}
        </p>
        {user.role !== UserRole.APP_VIEWER &&
          !isViewerForScenario() &&
          !props.showDeleted && (
            <img
              src={emptyCaseArrow}
              alt="emptyCaseArrow"
              className="emptyCaseArrow"
            />
          )}
      </div>
    </>
  )
}
