import React from 'react'
import InfoClickComponent from '../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'

type Props = {
  id: string
  descriptionText?: string
  coloredDescriptionText?: string
  secondaryDescriptionText?: string
  valueText?: string
  infoButtonText?: string
  descriptionTextAttribute?:
    | `description-${number}`
    | `message-${number}`
    | undefined
  coloredDescriptionTextAttribute?: `description-${number}`
  secondaryDescriptionTextAttribute?: `description-${number}`
  valueTextAttribute?: `message-${number}` | undefined
  infoButtonTextAttribute?:
    | `infoButton-${number}`
    | undefined
    | `description-${number}`
  withColor?: boolean
  isFirst?: boolean
  isClickable?: boolean
  onClick?: () => void
  disabled?: boolean
  clickableId?: string
  forDetails?: boolean
  justBlack?: boolean
  showInfoButton?: boolean
  hoverElement?: string
  setHoverElement?: (value: string) => void
  obscureDescriptionText?: boolean
}

const SettingInfoRow = (props: Props) => {
  const getSecondaryDescriptionTextElement = () => {
    if (!props.obscureDescriptionText) {
      return (
        <div
          className={`infoDescriptionText withColor`}
          id={`${props.id}secondaryDescriptionText`}
          data-textattribute={`${props.secondaryDescriptionTextAttribute}`}
        >
          {`${props.secondaryDescriptionText} `}
        </div>
      )
    } else {
      return (
        <div
          className={`infoDescriptionText withColor`}
          id={`${props.id}secondaryDescriptionText`}
          data-textattribute={`${props.secondaryDescriptionTextAttribute}`}
          data-openreplay-obscured
        >
          {`${props.secondaryDescriptionText} `}
        </div>
      )
    }
  }

  return (
    <div
      className={
        props.forDetails
          ? `rowContainer ${props.isFirst ? 'first-container' : ''}`
          : `tool-component-input-container marginLeft3 ${
              props.isFirst ? 'first-container' : ''
            }`
      }
    >
      {props.descriptionText && (
        <div
          id={`${props.id}descriptionText`}
          className={`infoDescriptionText ${
            props.withColor ? 'withColor' : ''
          }`}
          data-textattribute={`${props.descriptionTextAttribute}`}
          data-textattribute2={`${props.coloredDescriptionTextAttribute}`}
        >
          {`${props.descriptionText} `}
          {props.coloredDescriptionText ? (
            <div style={{ color: '#ff703e' }}>
              {props.coloredDescriptionText}
            </div>
          ) : null}
        </div>
      )}
      {props.secondaryDescriptionText && getSecondaryDescriptionTextElement()}
      {props.valueText && (
        <div
          className={`infoValueText ${props.isClickable ? 'clickable' : ''} ${
            props.disabled ? 'disabled' : ''
          } ${!props.descriptionText ? 'only' : ''} ${
            props.justBlack ? 'justBlack' : ''
          }`}
          onClick={props.disabled ? undefined : props.onClick}
          id={props.clickableId ?? `${props.id}infoValueText`}
          data-textattribute={`${props.valueTextAttribute}`}
        >
          {props.valueText}
        </div>
      )}
      {props.showInfoButton && (
        <div style={{ marginLeft: '10px' }}>
          <InfoClickComponent
            infoClickText={props.infoButtonText!}
            infoClickTextAttribute={props.infoButtonTextAttribute!}
            idName={props.id}
            hoverElement={props.hoverElement!}
            setHoverElement={props.setHoverElement!}
          />
        </div>
      )}
    </div>
  )
}

export default SettingInfoRow
