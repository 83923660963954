import React, { useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import Button from '../../../../../../../../Components/Buttons/Button/Button'
import { reportState } from '../../../../../../../../states/ReportState'
import addReportSectionImg from '../../../../../../../../resources/images/213-addReportSectionImg.svg'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { ReportSectionClass } from '../../../../../../../../models/reportModels/reportSection'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  sectionOrder: number
}

export default function ReportAddSection(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const [hover, setHover] = useState(false)
  const [report, setReport] = useRecoilState(reportState)
  const user = useRecoilValue(userState)

  function addReportSection() {
    //Mixpanel 146 (All)
    logActivity(freemium.isFreemium, 'Added a custom section in the report')
    let tempReport = deepCloneObject(report)
    for (let i = 0; i < tempReport.reportData.sections.length; i++) {
      if (tempReport.reportData.sections[i].sectionOrder >= props.sectionOrder)
        tempReport.reportData.sections[i].sectionOrder++
    }
    tempReport.reportData.sections.push(
      ReportSectionClass.CustomSectionDefault(props.sectionOrder),
    )
    setReport(tempReport)
  }

  return (
    <>
      <div
        className="reportAddSectionContainer"
        id={`reportAddSectionContainer-${props.sectionOrder}`}
        onMouseLeave={() => setHover(false)}
      >
        <div
          className="reportAddSectionBackgroundLine"
          onMouseLeave={() => setHover(false)}
        >
          <div
            className="reportAddSectionButtonContainer"
            id={`reportAddSectionButtonContainer-${props.sectionOrder}`}
            onMouseLeave={() => setHover(false)}
          >
            <Button
              buttonText={getText('button-134', user.settings)}
              buttonTextAttribute={'button-134'}
              id={`addSectionButton-${props.sectionOrder}`}
              buttonType="outlined"
              type="button"
              icon={addReportSectionImg}
              side="left"
              onClick={addReportSection}
              NoUpperCase={true}
              small={false}
            />
          </div>
        </div>
        {hover === false && (
          <div
            className="lowOpacityLayer"
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          ></div>
        )}
      </div>
    </>
  )
}
