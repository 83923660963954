import React, { useEffect, useState } from 'react'
import { isDevEnv } from '../../../services/commonFunctions'

type Props = {
  setOtp: Function
  otp: string
}

const OtpInput = (props: Props) => {
  const [digits, setDigits] = useState<number[]>([-1, -1, -1, -1, -1, -1])

  useEffect(() => {
    if (isDevEnv() && props.otp.length === 6) {
      setDigits([
        parseInt(props.otp[0]),
        parseInt(props.otp[1]),
        parseInt(props.otp[2]),
        parseInt(props.otp[3]),
        parseInt(props.otp[4]),
        parseInt(props.otp[5]),
      ])
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    document.getElementById(`digit-0`)?.focus()
  }, [])

  const onDigitChange = (index: number, value: string) => {
    value = value.length === 0 ? '' : value[0]
    const number = value === '' ? -1 : parseInt(value)
    if (isNaN(number)) {
      return
    }

    let tempDigits = [...digits]
    tempDigits[index] = number
    setDigits(tempDigits)

    sendOtp(tempDigits)

    if (value.length === 1 && index < digits.length - 1) {
      document.getElementById(`digit-${index + 1}`)?.focus()
    }
  }

  const sendOtp = (tempDigits?: number[]) => {
    const digitsToSend = tempDigits ?? digits

    if (digitsToSend.filter((digit) => digit === -1).length === 0) {
      let otpCode = ''
      digitsToSend.forEach((digit) => {
        otpCode += `${digit}`
      })
      props.setOtp(otpCode)
    } else {
      props.setOtp('')
    }
  }

  const onKeyDown = (e: any, index: number) => {
    if (
      !e.code.includes('Digit') &&
      e.code !== 'Backspace' &&
      e.code !== 'Enter' &&
      !e.code.includes('Numpad') &&
      !e.ctrlKey &&
      !e.metaKey &&
      e.code !== 'KeyV'
    ) {
      e.preventDefault()
    }

    if (e.code === 'ArrowLeft' && index > 0) {
      document.getElementById(`digit-${index - 1}`)?.focus()
    } else if (e.code === 'ArrowRight' && index < digits.length - 1) {
      document.getElementById(`digit-${index + 1}`)?.focus()
    } else if (e.code.includes('Digit') && index < digits.length - 1) {
      const numPressed = parseInt(e.code.substring(5))
      if (numPressed === digits[index]) {
        e.preventDefault()
        document.getElementById(`digit-${index + 1}`)?.focus()
      }
    } else if (e.code.includes('Numpad') && index < digits.length - 1) {
      const numPressed = parseInt(e.code.substring(6))
      if (numPressed === digits[index]) {
        e.preventDefault()
        document.getElementById(`digit-${index + 1}`)?.focus()
      }
    } else if (e.code === 'Backspace' && digits[index] === -1 && index > 0) {
      document.getElementById(`digit-${index - 1}`)?.focus()
    }
  }

  const onPaste = (e: any, index: number) => {
    e.stopPropagation()
    e.preventDefault()

    const stringValue = (e.clipboardData.getData('Text') as string)
      .trim()
      .replaceAll(' ', '')
    const value = parseInt(stringValue)

    if (!isNaN(value)) {
      let tempDigits = [...digits]
      for (let i = 0; i < stringValue.length; i++) {
        if (i + index < digits.length) {
          tempDigits[i + index] = parseInt(stringValue[i])
        }
      }
      setDigits(tempDigits)
      sendOtp(tempDigits)
    }
  }

  return (
    <div className="digitsContainer">
      {digits.map((digit, index) => (
        <input
          id={`digit-${index}`}
          key={`digit-${index}`}
          type="text"
          value={digit === -1 ? '' : digit}
          onChange={(e) => {
            onDigitChange(index, e.target.value)
          }}
          onKeyDown={(e) => onKeyDown(e, index)}
          onFocus={() => {
            if (document.getElementById(`digit-${index}`)) {
              ;(
                document.getElementById(`digit-${index}`) as HTMLInputElement
              ).setSelectionRange(0, digits[index] === -1 ? 0 : 1)
            }
          }}
          tabIndex={index + 1}
          onPaste={(e) => onPaste(e, index)}
          className="oneDigitInput"
        />
      ))}
    </div>
  )
}

export default OtpInput
