import React from 'react'

import { ResultsObject } from '../../../../../../models/generalTypes'
import ValueBreakdownGraphContainer from './ValueBreakdownGraphContainer'
import { userState } from '../../../../../../states/UserState'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { stringAndRoundBigNumbers } from '../../../../../../Modules/DisputeModules/AppFunctionsNew'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../models/reportModels/reportEnums'
import { ReportWhitelabel } from '../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../services/reportFunctions'
import { breakDownValueFontSize } from '../../../../../../services/commonFunctions'
import useWindowSize from '../../../../../../customHooks/useWindowSize'
import { getText } from '../../../../../../services/textFunctions'

type Props = {
  resultsFromBackend: ResultsObject
  roundedResults: boolean
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
  resultsViewParty: 'client' | 'opposing'
}

export default function ExpectedValueBreakdownForReport(props: Props) {
  const user = useRecoilValue(userState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const { width } = useWindowSize()

  return (
    <div
      className={`expectedValueBreakdownGraphContainer ${
        props.pdfPreview ? 'forReport' : ''
      }`}
    >
      {props.pdfPreview && (
        <div
          className="expectedValueBreakdownSubtitleForReport"
          id={`expectedValueBreakdownSubtitleForReport`}
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
            false,
            false,
          )}
          data-textattribute="description-279"
          //newText DONE
        >
          {getText('description-279', user.settings)}{' '}
          <span
            className="robotoNumbers"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'roboto',
              false,
              false,
            )}
          >
            {stringAndRoundBigNumbers(
              props.resultsFromBackend.value_of_claim,
              user.settings,
              props.roundedResults,
              0,
            )}
            {props.resultsFromBackend.model_used === 'statistical' ? '*' : ''}{' '}
            {scenarioSnapshot.currentSnapshot.currency}.
          </span>
        </div>
      )}
      <div
        className={`expectedValueBreakdownContainer tab-content ${
          props.pdfPreview ? 'forReport' : ''
        }`}
        id={`expectedValueBreakdownContainer${
          props.pdfPreview ? '-forReport' : ''
        }`}
        data-openreplay-obscured
      >
        <ValueBreakdownGraphContainer
          resultsFromBackend={props.resultsFromBackend}
          type={'positive'}
          roundedResults={props.roundedResults}
          pdfPreview={props.pdfPreview}
          reportWhitelabel={props.reportWhitelabel}
          resultsViewParty={props.resultsViewParty}
        />
        <ValueBreakdownGraphContainer
          resultsFromBackend={props.resultsFromBackend}
          type={'negative'}
          roundedResults={props.roundedResults}
          pdfPreview={props.pdfPreview}
          reportWhitelabel={props.reportWhitelabel}
          resultsViewParty={props.resultsViewParty}
        />
        <div
          className="valueBreakdownContainer"
          id={`valueBreakdownContainer-expectedValue${
            props.pdfPreview ? '-forReport' : ''
          }`}
        >
          <div className="valueBreakdownComponent">
            <div className="valueBreakdownTextContainer">
              <p
                className="valueBreakdownText expectedValue"
                id={`valueBreakdownText-expectedValue${
                  props.pdfPreview ? '-forReport' : ''
                }`}
                data-textattribute="title-00"
                style={getStyleFromWhitelabel(
                  props.reportWhitelabel,
                  ReportWhitelabelKeys.sectionDescription,
                  FontLabels.medium,
                  'noRoboto',
                )}
                //data-textattribute="title-275"
                //newText DONE
              >
                {getText('title-275', user.settings)}
              </p>
            </div>
            <div
              className={`valueBreakdownGraph expectedValue`}
              id={`valueBreakdownGraph-expectedValue${
                props.pdfPreview ? '-forReport' : ''
              }`}
            >
              <div
                className="breakdownValuesContainer"
                id={`breakdownValuesContainer-expectedValue${
                  props.pdfPreview ? '-forReport' : ''
                }`}
              >
                <p
                  className={`breakdownValue expectedValue robotoNumbers`}
                  key={`breakdownValue-expectedValue`}
                  id={`breakdownValue-expectedValue${
                    props.pdfPreview ? '-forReport' : ''
                  }`}
                  style={{
                    ...getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.medium,
                      'roboto',
                    ),
                    ...breakDownValueFontSize(
                      `${stringAndRoundBigNumbers(
                        props.resultsFromBackend.value_of_claim,
                        user.settings,
                        props.roundedResults,
                        0,
                      )}${
                        props.resultsFromBackend.model_used === 'statistical'
                          ? '*'
                          : ''
                      } ${scenarioSnapshot.currentSnapshot.currency}`,
                      width,
                    ),
                  }}
                >
                  {stringAndRoundBigNumbers(
                    props.resultsFromBackend.value_of_claim,
                    user.settings,
                    props.roundedResults,
                    0,
                  )}
                  {props.resultsFromBackend.model_used === 'statistical'
                    ? '*'
                    : ''}{' '}
                  {scenarioSnapshot.currentSnapshot.currency}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
