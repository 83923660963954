import React, { useState } from 'react'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import { getText } from '../../../services/textFunctions'
import { VideoPostName } from '../../../models/enums'
import englishTexts from '../../../resources/texts/englishTexts.json'
import videoInfoButtonImg from '../../../resources/images/185-videoInfoButton.svg'
import videoInfoButtonImgHover from '../../../resources/images/186-videoInfoButtonHover.svg'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { freemiumState } from '../../../states/FreemiumState'
import { logActivity } from '../../../services/commonFunctions'

type Props = {
  textAttribute: `tooltip-${number}`
  postName: VideoPostName
  setVideoPostName: (param: VideoPostName | undefined) => void
}

export default function VideoInfoButton(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [hover, setHover] = useState(false)

  return (
    <WhiteTooltip
      title={getText(
        props.textAttribute as keyof typeof englishTexts,
        user.settings,
      )}
      textAttribute={props.textAttribute}
      id={`videoInfoButtonTooltip-${props.postName}"`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img
        src={hover ? videoInfoButtonImgHover : videoInfoButtonImg}
        alt="videoInfoButtonImg"
        className="videoInfoButtonImg"
        id={`videoInfoButtonImg-${props.postName}`}
        onClick={() => {
          props.setVideoPostName(props.postName)
          logActivity(freemium.isFreemium, `Pressed a videoInfoButton`, {
            Video: props.postName,
          })
        }}
      />
    </WhiteTooltip>
  )
}
