import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import { useEffect, useState } from 'react'
import { userState } from '../../../../states/UserState'
import deleteSegmentImg from '../../../../resources/images/234-delete-segment.svg'
import InputNum from '../../../Inputs/InputNum/InputNum'
import {
  deepCloneObject,
  logActivity,
  roundNumberTo,
} from '../../../../services/commonFunctions'
import { LegalFeesDistributionObject } from '../../../../models/legalFeesDistributionObject'
import { formattedNumToString } from '../../../../services/formatNum'
import {
  findDistributionPercentage,
  findSegmentAmount,
} from '../../../../services/disputedAmountFunctions'
import { SnapshotSelectorObject } from '../../../../models/generalTypes'
import { UndoRedoType } from '../../../../models/enums'
import { changeGlobalSnapshot } from '../../../../services/changeGlobalSnapshot'
import { allowShortcutsState } from '../../../../states/AllowShortcutsState'
import { freemiumState } from '../../../../states/FreemiumState'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
  KROGERUS_OPPOSING_PARTY_COLOR,
  OPPOSING_PARTY_COLOR,
} from '../../../../services/constants'
import { krogerusColorsState } from '../../../../states/KrogerusColorsState'

type Props = {
  previousSegmentHeights:
    | { segmentIndex: number; top: number; height: number }[]
    | undefined
  totalCounterClaimedAmount: number
  disputedAmount: number
  tempSegmentHeight: number | undefined
  tempSegmentAmount: number | undefined
  handleChangeSegment: (action: 'add' | 'remove', index: number) => void
  rangeBarIndex: number
  singleSegment: LegalFeesDistributionObject
  segmentAmountHover: number | undefined
  setSegmentAmountHover: (segmentAmountHover: number | undefined) => void
  segmentEditMode: number | undefined
  setSegmentEditMode: (segmentEditMode: number | undefined) => void
}

export default function RangesBarAmount(props: Props) {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const freemium = useRecoilValue(freemiumState)

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const allowShortcuts = useRecoilValue(allowShortcutsState)
  const currentSnapshot = scenarioSnapshot.currentSnapshot
  const feeDistributions = currentSnapshot.legalFeesDistribution

  const [roundingNumber, setRoundingNumber] = useState<number>(2) // a number that defines the number of decimals, depending on the currency settings -- number 100 or 1
  const [rangeBarValue, setRangeBarValue] = useState(0)
  const hoverable =
    props.rangeBarIndex !== 0 &&
    props.rangeBarIndex !== feeDistributions.length - 2 &&
    props.tempSegmentHeight === undefined

  useEffect(() => {
    let nonMillionCurrnecy = currentSnapshot.currency.length === 3

    if (nonMillionCurrnecy) {
      setRoundingNumber(3)
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutOfSegmentAmount)
    document.addEventListener('keydown', handleClickOutOfSegmentAmount)

    return () => {
      document.removeEventListener('keydown', handleClickOutOfSegmentAmount)
      document.removeEventListener('mousedown', handleClickOutOfSegmentAmount)
    }

    // eslint-disable-next-line
  }, [rangeBarValue, props.segmentEditMode, allowShortcuts])

  function handleClickOutOfSegmentAmount(e: any | KeyboardEvent) {
    if (props.segmentEditMode === props.rangeBarIndex && allowShortcuts) {
      if (e instanceof KeyboardEvent) {
        if (e.key === 'Enter') {
          handleChangeSegmentAmount()
          props.setSegmentEditMode(undefined)
        } else if (e.key === 'Escape') {
          props.setSegmentEditMode(undefined)
        }
      } else {
        if (!e.target!.id.includes('rangeBarAmount')) {
          handleChangeSegmentAmount()
          props.setSegmentEditMode(undefined)
        }
      }
    }

    function handleChangeSegmentAmount() {
      if (
        !isNaN(rangeBarValue) &&
        rangeBarValue !==
          roundNumberTo(
            findSegmentAmount(
              props.disputedAmount,
              props.totalCounterClaimedAmount,
              props.previousSegmentHeights !== undefined &&
                props.previousSegmentHeights[0].segmentIndex ===
                  feeDistributions.length - 2 - props.rangeBarIndex
                ? props.tempSegmentAmount!
                : props.singleSegment.range[1],
            ),
            roundingNumber,
          )
      ) {
        let valueInPercentage = findDistributionPercentage(
          rangeBarValue,
          props.disputedAmount,
          props.totalCounterClaimedAmount,
        )
        const snapshotSelectorObject: SnapshotSelectorObject = {
          targetId: `rangeBarAmountText-${
            feeDistributions.length - 2 - props.rangeBarIndex
          }`,
          undoRedoType: UndoRedoType.button,
          value: [
            'changeSegmentAmount',
            props.rangeBarIndex,
            valueInPercentage,
          ],
          key: 'legalFeesDistribution',
        }

        let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

        tempScenarioSnapshot = changeGlobalSnapshot(
          snapshotSelectorObject,
          tempScenarioSnapshot,
        )
        setScenarioSnapshot(tempScenarioSnapshot)

        //Mixpanel 30 (All)
        logActivity(
          freemium.isFreemium,
          `typed a segment amount in legal costs distribution`,
        )
      }
    }
  }

  const getSegmentAmount = () =>
    roundNumberTo(
      findSegmentAmount(
        props.disputedAmount,
        props.totalCounterClaimedAmount,
        props.previousSegmentHeights !== undefined &&
          props.previousSegmentHeights[0].segmentIndex ===
            feeDistributions.length - 2 - props.rangeBarIndex
          ? props.tempSegmentAmount!
          : props.singleSegment.range[1],
      ),
      roundingNumber,
    )

  return (
    <>
      <div
        className="rangeBarAmountContainer"
        style={
          props.previousSegmentHeights !== undefined &&
          props.previousSegmentHeights[0].segmentIndex !==
            feeDistributions.length - 2 - props.rangeBarIndex
            ? undefined
            : {
                top: props.tempSegmentHeight,
              }
        }
        id={`rangeBarAmountContainer-${
          feeDistributions.length - 2 - props.rangeBarIndex
        }`}
        data-openreplay-obscured
      >
        <div
          className={
            hoverable
              ? 'rangeBarAmountTextContainer hoverable'
              : 'rangeBarAmountTextContainer'
          }
          style={
            props.segmentAmountHover === props.rangeBarIndex &&
            props.segmentEditMode !== props.rangeBarIndex
              ? {
                  backgroundColor: 'white',
                  boxShadow: '0px 3px 6px #00000029',
                }
              : {
                  backgroundColor: 'transparent',
                  boxShadow: undefined,
                }
          }
          onMouseEnter={() => {
            if (hoverable && props.segmentEditMode === undefined) {
              props.setSegmentAmountHover(props.rangeBarIndex)
            }
          }}
          onMouseLeave={() => props.setSegmentAmountHover(undefined)}
          data-openreplay-obscured
        >
          <div
            className="deleteSegmentImgContainer"
            onClick={() =>
              props.handleChangeSegment('remove', props.rangeBarIndex)
            }
            style={
              props.segmentAmountHover === props.rangeBarIndex
                ? undefined
                : {
                    visibility: 'hidden',
                  }
            }
            id={`deleteSegmentImgContainer-${
              feeDistributions.length - 2 - props.rangeBarIndex
            }`}
          >
            <img
              src={deleteSegmentImg}
              alt="deleteSegmentImg"
              className="deleteSegmentImg"
              style={
                props.segmentAmountHover === props.rangeBarIndex
                  ? undefined
                  : {
                      display: 'none',
                    }
              }
              id={`deleteSegmentImg-${
                feeDistributions.length - 2 - props.rangeBarIndex
              }`}
            />
          </div>
          {props.segmentEditMode === props.rangeBarIndex ? (
            <InputNum
              id={`rangeBarAmountTextInput-${
                feeDistributions.length - 2 - props.rangeBarIndex
              }`}
              name={`rangeBarAmountTextInput-${
                feeDistributions.length - 2 - props.rangeBarIndex
              }`}
              value={getSegmentAmount()}
              maxNumberOfDecimals={3}
              maxValue={props.disputedAmount + props.totalCounterClaimedAmount}
              minValue={props.totalCounterClaimedAmount}
              width={170}
              labelTextAttribute={'label-00'}
              allowNegative
              className={'rangeBarAmountTextInput robotoNumbers'}
              smallField
              onChange={(e: any) => setRangeBarValue(e.target.value)}
              hideHelperText
              localStyle={{
                zIndex: 1,
                color:
                  isNaN(rangeBarValue) || rangeBarValue >= 0
                    ? krogerusColors
                      ? KROGERUS_CLIENT_COLOR
                      : CLIENT_COLOR
                    : krogerusColors
                    ? KROGERUS_OPPOSING_PARTY_COLOR
                    : OPPOSING_PARTY_COLOR,
              }}
              noDelay
            />
          ) : (
            <p
              className={`${
                krogerusColors ? 'krogerus' : ''
              } rangeBarAmountText robotoNumbers ${
                hoverable ? 'hoverable' : ''
              } ${getSegmentAmount() >= 0 ? 'positive' : 'negative'}`}
              id={`rangeBarAmountText-${
                feeDistributions.length - 2 - props.rangeBarIndex
              }`}
              onMouseEnter={() => {
                if (hoverable && props.segmentEditMode === undefined) {
                  props.setSegmentAmountHover(props.rangeBarIndex)
                }
              }}
              style={
                props.segmentEditMode === props.rangeBarIndex
                  ? undefined
                  : { zIndex: 0 }
              }
              onClick={(e: any) => {
                if (!e.target.id.includes('deleteSegmentImg') && hoverable) {
                  props.setSegmentEditMode(props.rangeBarIndex)

                  setRangeBarValue(
                    roundNumberTo(
                      findSegmentAmount(
                        props.disputedAmount,
                        props.totalCounterClaimedAmount,
                        props.previousSegmentHeights !== undefined &&
                          props.previousSegmentHeights[0].segmentIndex ===
                            feeDistributions.length - 2 - props.rangeBarIndex
                          ? props.tempSegmentAmount!
                          : props.singleSegment.range[1],
                      ),
                      roundingNumber,
                    ),
                  )
                  props.setSegmentAmountHover(undefined)
                  setTimeout(() => {
                    if (props.segmentEditMode !== props.rangeBarIndex) {
                      let inputElement = document.getElementById(
                        `rangeBarAmountTextInput-${
                          feeDistributions.length - 2 - props.rangeBarIndex
                        }`,
                      ) as HTMLInputElement
                      if (inputElement) {
                        inputElement.focus()
                        inputElement.select()
                      }
                    }
                  }, 20)
                }
              }}
              data-openreplay-obscured
            >
              {formattedNumToString(
                Math.abs(getSegmentAmount()),
                user.settings,
              )}
            </p>
          )}
        </div>
      </div>
    </>
  )
}
