import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../../../../states/ScenarioSnapshotState'
import { TreeClaim } from '../../../../../../../../../models/treeModels/treeClaim'

import TreeCanvasForImage from '../../../../../../2_claims/tree/treeCanvasAndMainComponents/TreeCanvasForImage'
import {
  changeTextAreaHeight,
  findMainTreeAndOrphanTrees,
} from '../../../../../../../../../services/treeFunctions/treeBasicFunctions'
import {
  NodeId,
  NodeMode,
} from '../../../../../../../../../models/treeModels/treeTypes'
import { deepCloneObject } from '../../../../../../../../../services/commonFunctions'
import {
  findBottomMostNodeOfMainTree,
  findHeightOfTitle,
  findLeftMostNodeOfMainTree,
  findRightMostNodeOfMainTree,
  findTopMostNodeOfMainTree,
  heightOfNode,
  widthOfNode,
} from '../../../../../../../../../services/treeFunctions/treeXandYFunctions'
import { changeNodesPositionsMinMax } from '../../../../../../../../../services/treeFunctions/treePositioningFunctions'
import { ReportWhitelabel } from '../../../../../../../../../models/reportModels/reportGeneralTypes'

type Props = {
  treeIndex: number
  reportWhitelabel?: ReportWhitelabel
}

export default function TreePdfPreview(props: Props) {
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot
  const treeClaim = currentSnapshot.claims[props.treeIndex] as TreeClaim
  const [tempTreeClaim, setTempTreeClaim] = useState(deepCloneObject(treeClaim))
  const [canvasHeight, setCanvasHeight] = useState(400)
  const [canvasWidth, setCanvasWidth] = useState(700)

  const [zoomScale, setZoomScale] = useState(1)
  const [mainTree, setMainTree] = useState<NodeId[]>([])
  // const [orphanTrees, setOrphanTrees] = useState<NodeId[][]>([])

  const nodesUnderZeroProbability: NodeId[] = []

  const [differenceFromTop, setDifferenceFromTop] = useState<
    undefined | number
  >()

  const [differenceFromLeft, setDifferenceFromLeft] = useState<
    undefined | number
  >()
  useEffect(() => {
    if (treeClaim.nodeMode === NodeMode.maximised) {
      let localTempTreeClaim = deepCloneObject(treeClaim)

      localTempTreeClaim.treeDetails = changeNodesPositionsMinMax(
        NodeMode.minimised,
        localTempTreeClaim.treeDetails,
      )
      setTempTreeClaim(localTempTreeClaim)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    // eslint-disable-next-line
    const [, tempMainTree] = findMainTreeAndOrphanTrees(
      tempTreeClaim.treeDetails,
    )
    if (tempMainTree.includes('node123')) return

    const topNodeOfMainTree = findTopMostNodeOfMainTree(
      tempTreeClaim.treeDetails,
      tempMainTree,
      props.treeIndex,
      NodeMode.minimised,
    )

    const leftNodeOfMainTree = findLeftMostNodeOfMainTree(
      tempTreeClaim.treeDetails,
      tempMainTree,
    )
    const bottomNodeOfMainTree = findBottomMostNodeOfMainTree(
      tempTreeClaim.treeDetails,
      tempMainTree,
      NodeMode.minimised,
      props.treeIndex,
    )

    const rightNodeOfMainTree = findRightMostNodeOfMainTree(
      tempTreeClaim.treeDetails,
      tempMainTree,
      NodeMode.minimised,
    )

    const tempCanvasWidth =
      tempTreeClaim.treeDetails.nodes[rightNodeOfMainTree as NodeId].properties
        .position[0] +
      widthOfNode(
        rightNodeOfMainTree as NodeId,
        NodeMode.minimised,
        tempTreeClaim.treeDetails,
      ) -
      tempTreeClaim.treeDetails.nodes[leftNodeOfMainTree as NodeId].properties
        .position[0] +
      100

    const tempCanvasHeight =
      tempTreeClaim.treeDetails.nodes[bottomNodeOfMainTree as NodeId].properties
        .position[1] +
      heightOfNode(
        NodeMode.minimised,
        bottomNodeOfMainTree as NodeId,
        props.treeIndex,
        tempTreeClaim.treeDetails,
        false,
      ) -
      tempTreeClaim.treeDetails.nodes[topNodeOfMainTree as NodeId].properties
        .position[1] +
      findHeightOfTitle(
        topNodeOfMainTree as NodeId,
        props.treeIndex,
        NodeMode.minimised,
      )
    if (
      differenceFromTop !==
      tempTreeClaim.treeDetails.nodes[topNodeOfMainTree as NodeId].properties
        .position[1] -
        findHeightOfTitle(
          topNodeOfMainTree as NodeId,
          props.treeIndex,
          NodeMode.minimised,
        )
    ) {
      setDifferenceFromTop(
        tempTreeClaim.treeDetails.nodes[topNodeOfMainTree as NodeId].properties
          .position[1] -
          findHeightOfTitle(
            topNodeOfMainTree as NodeId,
            props.treeIndex,
            NodeMode.minimised,
          ),
      )
    }

    setDifferenceFromLeft(
      tempTreeClaim.treeDetails.nodes[leftNodeOfMainTree as NodeId].properties
        .position[0],
    )

    setMainTree(tempMainTree)
    // setOrphanTrees(tempOrphanTrees)

    let scale = 1050 / tempCanvasWidth
    if (scale > 0.8) {
      scale = 0.8
    }
    setZoomScale(scale)
    setCanvasHeight(tempCanvasHeight * scale + 150)
    setCanvasWidth(tempCanvasWidth)
    // eslint-disable-next-line
  }, [tempTreeClaim, differenceFromTop])

  // nodesUnderZeroProbability
  //We decided to turn this feature off. I am keeping the code here in case we decide to bring it back

  // useEffect(() => {
  //  if(mainTree.length === 0) return
  // let eventsWithZeroProbability = findZeroProbabilityEventsInMainTree(
  //   treeClaim.treeDetails,
  //   orphanTrees.flat(),
  // )
  // let tempNodesUnderZeroProbability: NodeId[] = []
  // for (let eventId of eventsWithZeroProbability) {
  //   tempNodesUnderZeroProbability = tempNodesUnderZeroProbability.concat(
  //     findChildrenNodesFromEvent(
  //       eventId as EventId,
  //       treeClaim.treeDetails,
  //       [],
  //       false,
  //     ),
  //   )
  // }

  // tempNodesUnderZeroProbability = [...new Set(tempNodesUnderZeroProbability)]

  // let nodesWith2Parents = []
  // for (let nodeId of tempNodesUnderZeroProbability) {
  //   if (
  //     Object.keys(treeClaim.treeDetails.nodes[nodeId].nodeSiblingIndex)
  //       .length > 1
  //   ) {
  //     nodesWith2Parents.push(nodeId)
  //   }
  // }

  // let loop = 2
  // while (loop > 0) {
  //   loop--

  //   for (let nodeId of nodesWith2Parents) {
  //     let parentEvents = findParentEvents(
  //       nodeId as NodeId,
  //       treeClaim.treeDetails,
  //     )
  //     if (parentEvents) {
  //       for (let parentEvent of parentEvents) {
  //         if (
  //           !tempNodesUnderZeroProbability.includes(
  //             treeClaim.treeDetails.events[parentEvent as EventId]
  //               .nodeOfEventId,
  //           ) &&
  //           mainTree.includes(
  //             treeClaim.treeDetails.events[parentEvent as EventId]
  //               .nodeOfEventId,
  //           ) &&
  //           !eventsWithZeroProbability.includes(parentEvent)
  //         ) {
  //           let childrenNodes = findNodesOfTreeFromRoot(
  //             nodeId as NodeId,
  //             treeClaim.treeDetails,
  //             [],
  //             [],
  //             false,
  //           )
  //           tempNodesUnderZeroProbability =
  //             tempNodesUnderZeroProbability.filter(
  //               (tempNodeId) =>
  //                 tempNodeId !== nodeId &&
  //                 !childrenNodes.includes(tempNodeId),
  //             )
  //         }
  //       }
  //     }
  //   }
  // }

  //setNodesUnderZeroProbability(tempNodesUnderZeroProbability)

  // eslint-disable-next-line
  // }, [tempTreeClaim, differenceFromTop])

  useEffect(() => {
    const tx = document.getElementsByClassName('treeNodeTitleTextarea')
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute(
        'style',
        'height:' + tx[i].scrollHeight + 'px;overflow-y:hidden;',
      )
      tx[i].addEventListener('input', () => changeTextAreaHeight(tx[i]), false)
    }
  }, [])

  return (
    <>
      <div
        className="treeComponent"
        style={{ height: canvasHeight < 300 ? 300 : canvasHeight, left: -100 }}
      >
        <div className="treeContainer">
          <TreeCanvasForImage
            treeIndex={props.treeIndex}
            zoomLevel={zoomScale}
            mainTree={mainTree}
            differenceFromTop={differenceFromTop ? differenceFromTop - 50 : 0}
            differenceFromLeft={differenceFromLeft ?? 0}
            canvasType="report"
            canvasHeight={canvasHeight}
            canvasWidth={canvasWidth}
            nodesUnderZeroProbability={nodesUnderZeroProbability}
            reportWhitelabel={props.reportWhitelabel}
          />
        </div>
      </div>
    </>
  )
}
