import React, { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import {
  AccessRole,
  AllowedAccessRole,
  AllowedAccessRoleFromString,
} from '../../../models/enums'
import { UserForAccess } from '../../../models/generalTypes'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { AccessMenuAction } from '../AccessComponent'
import AddRemoveAccessMenu from '../AddRemoveAccessMenu'
import UserAccessRole from './UserAccessRole'

type Props = {
  listOfUsers: UserForAccess[] | undefined
  id: string
  onButtonClick: (username: string, type: AccessMenuAction) => void
  accessMenu: [string | undefined, AccessMenuAction | undefined]
  accessMenuCancelFunction: () => void
  actionForAccess: (
    type: AccessMenuAction,
    username: string,
    role: AllowedAccessRole,
  ) => void
  changeAccepted: boolean
  setChangeAccepted: (param: boolean) => void
  changeForbidden: boolean
  loadingAccess: boolean
  setLoadingAccess: (param: boolean) => void
  ownRole: AccessRole
  forInviting?: boolean
}

export default function UsersForAccess(props: Props) {
  const appUser = useRecoilValue(userState)

  const initAccessButtonTypes = () => {
    let res: (AccessMenuAction | undefined)[] = []
    if (props.listOfUsers) {
      if (props.forInviting) {
        props.listOfUsers.forEach((user) => res.push(undefined))
      } else {
        props.listOfUsers.forEach((user) =>
          res.push(user.self ? 'leave' : 'remove'),
        )
      }
    }

    return res
  }

  const initTempAccessRoles = () => {
    let res: AllowedAccessRole[] = []
    if (props.listOfUsers) {
      if (props.forInviting) {
        props.listOfUsers.forEach((user) => res.push(AllowedAccessRole.NONE))
      } else {
        props.listOfUsers.forEach((user) =>
          res.push(
            user.access_role === AccessRole.ADMIN
              ? AllowedAccessRole.NONE
              : AllowedAccessRoleFromString(user.access_role!.toString()),
          ),
        )
      }
    }

    return res
  }

  const [accessButtonTypes, setAccessButtonTypes] = useState<
    (AccessMenuAction | undefined)[]
  >(initAccessButtonTypes())

  const [tempAccessRoles, setTempAccessRoles] = useState<AllowedAccessRole[]>(
    initTempAccessRoles(),
  )

  const changeAccessButtonType = (
    newValue: AccessMenuAction,
    index: number,
  ) => {
    let values = [...accessButtonTypes]
    values[index] = newValue
    setAccessButtonTypes([...values])
  }

  const changeTempAccessRole = (newValue: AllowedAccessRole, index: number) => {
    let values = [...tempAccessRoles]
    values[index] = newValue
    setTempAccessRoles([...values])
  }

  useEffect(() => {
    setAccessButtonTypes([...initAccessButtonTypes()])
    setTempAccessRoles([...initTempAccessRoles()])

    // eslint-disable-next-line
  }, [props.listOfUsers])

  return (
    <>
      {props.listOfUsers!.length > 0 ? (
        props.listOfUsers!.map((user, index) => (
          <div
            className="userContainer"
            key={`listOfUsers-${props.id}-${index}`}
            id={`popUpuserContainer-${props.id}-${index}`}
            data-openreplay-obscured
          >
            <div
              className="userNameContainer"
              id={`popUpuserNameContainer-${props.id}${index}`}
            >
              <p id={`popUpuserNameText-${props.id}-${index}`}>
                {`${user.first_name} ${user.last_name}`}
              </p>
            </div>
            <div
              className="userRoleContainer"
              id={`popUpuserRoleContainer-${props.id}${index}`}
            >
              {(props.ownRole === AccessRole.ADMIN ||
                props.ownRole === AccessRole.OWNER) &&
              tempAccessRoles[index] ? (
                <UserAccessRole
                  tempAccessRole={tempAccessRoles[index]}
                  changeAccessButtonType={changeAccessButtonType}
                  changeTempAccessRole={changeTempAccessRole}
                  user={user}
                  index={index}
                  forInviting={props.forInviting}
                />
              ) : (
                <p id={`popUpuserRoleText-${props.id}-${index}`}>
                  {user.access_role}
                </p>
              )}
            </div>
            <div
              className="addRemoveAccessButtonContainer"
              id={`popUpaddRemoveAccessButtonContainer-${props.id}-${index}`}
            >
              {accessButtonTypes[index] &&
                tempAccessRoles[index] &&
                tempAccessRoles[index] !== AllowedAccessRole.NONE && (
                  <button
                    className={
                      accessButtonTypes[index] === 'leave'
                        ? 'addRemoveButton invertedDark'
                        : accessButtonTypes[index] === 'add' ||
                          accessButtonTypes[index] === 'change'
                        ? 'addRemoveButton dark'
                        : 'addRemoveButton'
                    }
                    id={`popUpaddRemoveButton-${props.id}-${index}`}
                    onClick={() => {
                      if (accessButtonTypes[index]) {
                        props.onButtonClick(
                          user.username,
                          accessButtonTypes[index]!,
                        )
                      }
                    }}
                    type="button"
                    data-textattribute={
                      accessButtonTypes[index] === 'leave'
                        ? 'button-110'
                        : accessButtonTypes[index] === 'add'
                        ? 'button-16'
                        : accessButtonTypes[index] === 'remove'
                        ? 'button-15'
                        : 'button-111'
                    }
                    disabled={
                      !user.self &&
                      props.ownRole !== AccessRole.ADMIN &&
                      props.ownRole !== AccessRole.OWNER
                    }
                  >
                    {accessButtonTypes[index] === 'leave'
                      ? getText('button-110', appUser.settings)
                      : accessButtonTypes[index] === 'add'
                      ? getText('button-16', appUser.settings)
                      : accessButtonTypes[index] === 'remove'
                      ? getText('button-15', appUser.settings)
                      : getText('button-111', appUser.settings)}
                  </button>
                )}
              {props.accessMenu[0] === user.username && props.accessMenu[1] ? (
                <AddRemoveAccessMenu
                  type={props.accessMenu[1]}
                  user={user}
                  tempAccessRole={tempAccessRoles[index]}
                  cancelFunction={props.accessMenuCancelFunction}
                  addRemoveAccess={props.actionForAccess}
                  changeAccepted={props.changeAccepted}
                  changeForbidden={props.changeForbidden}
                  setChangeAccepted={props.setChangeAccepted}
                  loadingAccess={props.loadingAccess}
                  setLoadingAccess={props.setLoadingAccess}
                  isFirst={index === 0}
                />
              ) : null}
            </div>
          </div>
        ))
      ) : props.forInviting ? (
        <div
          className="noAvailableUsersTextContainer"
          id={'popUpnoAvailableUsersTextContainer'}
        >
          <p
            className="noAvailableUsersText"
            id="popUpnoAvailableUsersText"
            data-textattribute="description-28"
          >
            {getText('description-28', appUser.settings)}
          </p>
        </div>
      ) : null}
    </>
  )
}
