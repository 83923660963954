import { useState } from 'react'

import { AccessRole, UndoRedoType } from '../../../models/enums'
import { LegalFeesDistributionObject } from '../../../models/legalFeesDistributionObject'
import NewLegalFeesRange from './SingleSegmentComponents/NewLegalFeesRange'
import limitSegmentArrowImg from '../../../resources/images/239-arrow.svg'
import { stringAndRoundTo2Decimals } from '../../../Modules/DisputeModules/AppFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'
import { scenarioSnapshotState } from '../../../states/ScenarioSnapshotState'
import { WhiteTooltip } from '../../tooltip/TooltipComponent'
import deleteSegmentImg from '../../../resources/images/234-delete-segment.svg'
import { SnapshotSelectorObject } from '../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../models/scenarioSnapshot'
import { changeGlobalSnapshot } from '../../../services/changeGlobalSnapshot'
import { deepCloneObject, logActivity } from '../../../services/commonFunctions'
import { getText } from '../../../services/textFunctions'
import { freemiumState } from '../../../states/FreemiumState'

type Props = {
  feeDistributions: LegalFeesDistributionObject[]
  singleSegmentIndex: number
  totalCounterClaimedAmount: number
  disputedAmount: number
  isFirstLoad?: boolean
  setIsFirstLoad?: (param: boolean) => void
  pdfPreview?: boolean
  ownRole?: AccessRole
  limitSegment: 'top' | 'bottom'
  awardedTo: 'hover-62' | 'hover-63'
}

export default function LimitSegment(props: Props) {
  const [changeEffect, setChangeEffect] = useState(false) // a boolean that is responsible for the animation effect when the range is changing -- bool
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const limitSegmentIsOn =
    props.limitSegment === 'top'
      ? scenarioSnapshot.currentSnapshot.includeTopLFDLimit === true
      : scenarioSnapshot.currentSnapshot.includeBottomLFDLimit === true
  const [showLimitSegmentButton, setShowLimitSegmentButton] = useState(false)
  const [limitSegmentDeleteButton, setLimitSegmentDeleteButton] =
    useState(false)
  const awardedAmount =
    props.limitSegment === 'top'
      ? Math.abs(props.disputedAmount + props.totalCounterClaimedAmount)
      : Math.abs(props.totalCounterClaimedAmount)

  function handleToggleLimitSegment(action: boolean) {
    //sets the singleSegmentIndex to be equal with the next or previous segment and sets the state to false
    let topOrBottomKey =
      props.limitSegment === 'top'
        ? 'includeTopLFDLimit'
        : 'includeBottomLFDLimit'
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'addRemoveSegmentContainer',
      undoRedoType: UndoRedoType.button,
      value: action,
      key: topOrBottomKey as keyof ScenarioSnapshot,
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)

    //Mixpanel 166 (All)
    logActivity(
      freemium.isFreemium,
      `turned the legal costs distribution ${props.limitSegment} limit ${
        action === false ? 'off' : 'on'
      }`,
    )
  }

  return (
    <div
      className="limitSegmentContainer"
      style={
        limitSegmentIsOn ? undefined : { position: 'absolute', zIndex: -1 }
      }
      data-openreplay-obscured
    >
      <div
        className={`limitSegmentHoverArea ${
          props.limitSegment === 'top' ? 'top' : ''
        }`}
        onMouseEnter={
          !showLimitSegmentButton && !limitSegmentIsOn
            ? () => setShowLimitSegmentButton(true)
            : undefined
        }
        id={`limitSegmentHoverArea-${props.limitSegment}`}
        onMouseLeave={() => setShowLimitSegmentButton(false)}
      >
        {showLimitSegmentButton && (
          <div className="limitSegmentButtonContainer">
            <img
              src={limitSegmentArrowImg}
              alt="limitSegmentArrowImg"
              className={`limitSegmentArrowImg ${
                props.limitSegment === 'top' ? '' : 'bottom'
              }`}
              id={`limitSegmentArrowImg-${props.limitSegment}`}
            />
            <div
              className="limitSegmentButtonTextContainer"
              id={`limitSegmentButtonTextContainer-${props.limitSegment}`}
              onClick={() => {
                handleToggleLimitSegment(true)
                setShowLimitSegmentButton(false)
              }}
            >
              <p
                className="limitSegmentButtonText"
                data-textattribute="description-167a, description-167b"
                data-openreplay-obscured
              >
                {`${getText('description-167a', user.settings)} ${getText(
                  props.awardedTo,
                  user.settings,
                  partiesFormat,
                )} ${getText(
                  'description-167b',
                  user.settings,
                )} ${stringAndRoundTo2Decimals(awardedAmount, user.settings)} ${
                  scenarioSnapshot.currentSnapshot.currency
                }`}
              </p>
            </div>
          </div>
        )}
      </div>
      {limitSegmentIsOn && (
        <>
          <div
            className={`limitSegmentArrowImgContainer ${
              props.limitSegment === 'top' ? '' : 'bottom'
            }`}
            data-openreplay-obscured
          >
            <img
              src={limitSegmentArrowImg}
              alt="limitSegmentArrowImg"
              className={`limitSegmentArrowImg ${
                props.limitSegment === 'top' ? '' : 'bottom'
              }`}
              id={`limitSegmentArrowImg-${props.limitSegment}`}
            />
          </div>
          <div
            className={`singleSegmentContainerNew ${
              props.pdfPreview ? 'pdfPreviewSingleSegment' : ''
            } limit ${props.limitSegment === 'top' ? '' : 'bottom'}`}
            style={{ height: 50 }}
            onMouseEnter={() => setLimitSegmentDeleteButton(true)}
            onMouseLeave={() => setLimitSegmentDeleteButton(false)}
          >
            <NewLegalFeesRange
              singleSegmentIndex={
                props.limitSegment === 'top'
                  ? props.feeDistributions.length - 1
                  : 0
              }
              feeDistributions={props.feeDistributions}
              changeEffect={changeEffect}
              setChangeEffect={setChangeEffect}
              pdfPreview={props.pdfPreview}
              isFirstLoad={props.isFirstLoad}
              setIsFirstLoad={props.setIsFirstLoad}
              ownRole={props.ownRole}
              disputedAmount={props.disputedAmount}
              totalCounterClaimedAmount={props.totalCounterClaimedAmount}
            />
            {limitSegmentDeleteButton && (
              <WhiteTooltip
                textAttribute={'tooltip-46'}
                id={`deleteLimitSegment-${props.limitSegment}`}
                title={getText('tooltip-46', user.settings)}
              >
                <div
                  className="deleteLimitSegmentImgContainer"
                  onClick={() => handleToggleLimitSegment(false)}
                  id={`deleteLimitSegmentImgContainer-${props.limitSegment}`}
                >
                  <img
                    src={deleteSegmentImg}
                    alt="deleteSegmentImg"
                    className="deleteSegmentImg"
                    id={`deleteSegmentImg-${props.limitSegment}`}
                  />
                </div>
              </WhiteTooltip>
            )}
          </div>
        </>
      )}
    </div>
  )
}
