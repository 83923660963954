import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import {
  findPlaceholderForClaim,
  FontLabels,
  ReportWhitelabelKeys,
  SectionTitleKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  ReportSectionContents,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ReportSectionClass } from '../../../../../../../../models/reportModels/reportSection'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import ReportParagraph from './ReportParagraph'
import DateOfTrialComponent from '../smallComponents/DateOfTrialComponent'
import SecondTrialProbsComponent from '../smallComponents/SecondTrialProbsComponent'
import { reportState } from '../../../../../../../../states/ReportState'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import {
  findDisputedAmount,
  findTotalCounterClaimedAmount,
} from '../../../../../../../../services/disputedAmountFunctions'
import IndependentClaimTile from '../../../../../2_claims/claimsTile/independentClaimTile/IndependentClaimTile'
import { IndependentClaim } from '../../../../../../../../models/independentClaim'
import TreePdfPreview from '../smallComponents/TreePdfPreviewComponents/TreePdfPreview'
import LegalCostsPreviewComponent from '../smallComponents/LegalCostsPreviewComponent'
import SingleSegment from '../../../../../../../../Components/LegalFeesDistribution/LegalFeesDistributionComponents/SingleSegment'
import SelectionGraph from '../../../2_graphs/graphComponents/selectionGraph/SelectionGraph'
import DistributionGraph from '../../../2_graphs/graphComponents/distributionGraph/DistributionGraph'
import BestWorstScenario from '../../../3_detailedResults/resultsList/BestWorstScenario'
import Button from '../../../../../../../../Components/Buttons/Button/Button'
import {
  getText,
  reportSectionPlaceholders,
} from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { SectionId } from '../../../../../../../../models/treeModels/treeTypes'
import ReportSectionTitle from './ReportSectionTitle'
import ReportAddSection from './ReportAddSection'
import ReportSectionRemoveMove from './ReportSectionRemoveMove'
import { claimPreviewIdArrayFunction } from '../../../../../../../../services/claimFunctions'
import { MAX_REPORT_SECTIONS } from '../../../../../../../../services/constants'
import fitty from 'fitty'
import ClaimsOverviewComponent from '../smallComponents/ClaimsOverviewComponent'
import TextForExecutiveSummary from '../../../1_executiveSummry/TextForExecutiveSummary'
import {
  getStyleFromWhitelabel,
  snapshotHasEnforcementRisk,
} from '../../../../../../../../services/reportFunctions'
import { freemiumState } from '../../../../../../../../states/FreemiumState'
import ClaimsLegendPreview from '../smallComponents/ClaimsLegendPreview'
import GetPayGraph from '../../../2_graphs/graphComponents/getPayGraph/GetPayGraph'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import ExecutiveSummaryNew3Container from '../../../1_executiveSummry/executiveSummary3/ExecutiveSummaryNew3Container'
import ExpectedValueBreakdownForReport from '../../../4_expectedValueBreakdown/ExpectedValueBreakdownForReport'
import AwaLegendComponent from '../../../../../../../../Components/common/AwaLegendComponent'

type Props = {
  sectionId: SectionId
  sectionIndex: number
  sectionOrder: number
  titleKey: SectionTitleKeys
  contents: ReportSectionContents
  visible: boolean
  resultsFromBackend: ResultsObject
  customTitle: string
  numberOfSections: number
  animationPair: { sectionId: SectionId; sectionHeight: number }[]
  setAnimationPair: (
    animationPair: { sectionId: SectionId; sectionHeight: number }[],
  ) => void
  reportWhitelabel: ReportWhitelabel | undefined
}

export default function ReportSection(props: Props) {
  const user = useRecoilValue(userState)
  const resultsSettings = useRecoilValue(resultsSettingsState)
  const freemium = useRecoilValue(freemiumState)
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot

  const [hover, setHover] = useState(false)
  const [report, setReport] = useRecoilState(reportState)
  const [showIncludeOption, setShowIncludeOption] = useState<
    SectionTitleKeys | undefined
  >(undefined)
  const [animationDelete, setAnimationDelete] = useState(false)
  const [isAnimation, setIsAnimation] = useState<
    undefined | 'animateUp' | 'animateDown'
  >(undefined)
  const [flexDirectionOfParagraph, setFlexDirectionOfParagraph] = useState<
    string[]
  >(initialiseFlexDirectionOfParagraphs())
  const [totalCounterClaimedAmount, setTotalCounterClaimedAmount] = useState(0)
  const [disputedAmount, setDisputedAmount] = useState(0)

  useMemo(() => {
    setTotalCounterClaimedAmount(findTotalCounterClaimedAmount(currentSnapshot))
    setDisputedAmount(findDisputedAmount(currentSnapshot))
  }, [currentSnapshot])

  const partiesFormat = [
    currentSnapshot.partyFormatOwn,
    currentSnapshot.partyFormatOther,
  ]

  function initialiseFlexDirectionOfParagraphs() {
    let tempFlexDirectionOfParagraph = []
    if (
      report.reportData.sections[props.sectionIndex] &&
      report.reportData.sections[props.sectionIndex].contents.claims
    ) {
      for (let claimIndex in currentSnapshot.claims) {
        if (
          report.reportData.sections[props.sectionIndex].contents.claims![
            claimIndex
          ].description.length > 0
        ) {
          tempFlexDirectionOfParagraph.push('row')
        } else {
          tempFlexDirectionOfParagraph.push('column')
        }
      }
      return tempFlexDirectionOfParagraph
    } else {
      return ['column']
    }
  }

  const animateUp = {
    animation: 'animateSectionUp 0.5s forwards',
    WebkitAnimation: 'animateSectionUp 0.5s forwards',
    MozAnimation: 'animateSectionUp 0.5s forwards',
  }
  const animateDown = {
    animation: 'animateSectionDown 0.5s forwards',
    WebkitAnimation: 'animateSectionDown 0.5s forwards',
    MozAnimation: 'animateSectionDown 0.5s forwards',
  }

  const keyframes = `
  @keyframes animateSectionUp {
    0% {
      transform: translateY(${
        props.animationPair.length > 0
          ? props.animationPair[0].sectionHeight
          : 0
      }px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @-webkit-keyframes animateSectionUp {
    from {
      -webkit-transform: translateY(${
        props.animationPair.length > 0
          ? props.animationPair[0].sectionHeight
          : 0
      }px);
    }
    to {
      -webkit-transform: translateY(0px);
    }
  }
  @-moz-keyframes animateSectionUp {
    from {
      -moz-transform: translateY(${
        props.animationPair.length > 0
          ? props.animationPair[0].sectionHeight
          : 0
      }px);
    }
    to {
      -moz-transform: translateY(0px);
    }
  }
  @keyframes animateSectionDown {
    0% {
      transform: translateY(-${
        props.animationPair.length > 0
          ? props.animationPair[1].sectionHeight
          : 0
      }px);
    }
    100% {
      transform: translateY(0px);
    }
  }
   
    @-webkit-keyframes animateSectionDown {
      from {
        -webkit-transform: translateY(-${
          props.animationPair.length > 0
            ? props.animationPair[1].sectionHeight
            : 0
        }px);
      }
      to {
        -webkit-transform: translateY(0px);
      }
    }
    @-moz-keyframes animateSectionDown {
      from {
        -moz-transform: translateY(-${
          props.animationPair.length > 0
            ? props.animationPair[1].sectionHeight
            : 0
        }px);
      }
      to {
        -moz-transform: translateY(0px);
      }
    }
  `

  useEffect(() => {
    fitty('.listTitleRightText', {
      minSize: 8,
      maxSize: 16,
    })
    fitty('.listPreviewTableName', {
      minSize: 8,
      maxSize: 16,
    })
  }, [])

  useEffect(() => {
    if (
      props.animationPair.length > 0 &&
      props.animationPair[0].sectionId === props.sectionId
    ) {
      setIsAnimation('animateUp')
    }
    if (
      props.animationPair.length > 0 &&
      props.animationPair[1].sectionId === props.sectionId
    ) {
      setIsAnimation('animateDown')
    }
    setTimeout(() => {
      setIsAnimation(undefined)
      props.setAnimationPair([])
    }, 600)
    // eslint-disable-next-line
  }, [report])

  function handleIncludeExcludeSection() {
    let tempReportData = deepCloneObject(report.reportData)
    if (tempReportData.sections[props.sectionIndex!].show) {
      //Mixpanel 150 (All)
      logActivity(freemium.isFreemium, 'Hid a section in the report', {
        'Hide from': 'Preview Pdf',
        section: props.titleKey,
      })
    } else {
      //Mixpanel 151 (All)
      logActivity(freemium.isFreemium, 'Included a section in the report', {
        'Include from': 'Preview Pdf',
        section: props.titleKey,
      })
    }
    tempReportData.sections[props.sectionIndex!].show =
      !tempReportData.sections[props.sectionIndex!].show
    setReport({ ...report, reportData: tempReportData })
  }

  function getLegalCostsDescriptions() {
    return report.reportData.sections.filter(
      (section) => section.title === SectionTitleKeys.LegalCosts,
    )[0].contents.legalCosts!
  }

  function getLCDPartyDescriptions(): ('label-25' | 'label-26')[] {
    return [
      disputedAmount + totalCounterClaimedAmount <= 0 ? 'label-26' : 'label-25',
      totalCounterClaimedAmount < 0 ? 'label-26' : 'label-25',
    ]
  }

  return (
    <>
      {props.numberOfSections < MAX_REPORT_SECTIONS && (
        <ReportAddSection
          sectionOrder={props.sectionOrder}
          key={props.sectionId}
        />
      )}
      <div
        className={`reportSectionContainer ${
          animationDelete ? 'fadeOut' : undefined
        } ${
          props.titleKey === SectionTitleKeys.CustomSection
            ? 'customSectionContainer'
            : undefined
        }`}
        id={`reportSectionContainer-${props.titleKey}-${props.sectionId}`}
        onMouseEnter={() => {
          setHover(true)
          setShowIncludeOption(props.titleKey)
        }}
        onMouseLeave={() => {
          setHover(false)
          setShowIncludeOption(undefined)
        }}
        style={
          isAnimation === 'animateUp'
            ? animateUp
            : isAnimation === 'animateDown'
            ? animateDown
            : undefined
        }
      >
        <style>{keyframes}</style>
        {hover && props.visible && (
          <ReportSectionRemoveMove
            titleKey={props.titleKey}
            sectionId={props.sectionId}
            upArrowVisible={props.sectionOrder !== 0}
            downArrowVisible={props.sectionOrder !== props.numberOfSections - 1}
            setAnimationPair={props.setAnimationPair}
            setAnimationDelete={setAnimationDelete}
          />
        )}
        <ReportSectionTitle
          titleKey={props.titleKey}
          customTitle={props.customTitle}
          visible={props.visible}
          sectionIndex={props.sectionIndex}
          sectionId={props.sectionId}
          showIncludeOption={showIncludeOption}
          handleIncludeExcludeSection={handleIncludeExcludeSection}
          reportWhitelabel={props.reportWhitelabel}
        />
        {report.reportData.sections[props.sectionIndex] &&
          Object.keys(
            report.reportData.sections[props.sectionIndex].contents,
          ).map((content, index) => {
            if (
              content === 'description' &&
              Object.keys(props.contents).includes('description')
            ) {
              return (
                <ReportParagraph
                  key={`content${index}`}
                  textFromSnapshot={props.contents.description!}
                  sectionIndex={props.sectionIndex}
                  id={
                    report.reportData.sections[props.sectionIndex].title ===
                    'CustomSection'
                      ? `CustomSection${props.sectionIndex}`
                      : report.reportData.sections[
                          props.sectionIndex
                        ].title.replaceAll(' ', '')
                  }
                  placeholder={
                    reportSectionPlaceholders(user.settings)[props.titleKey]
                  }
                  defaultText={ReportSectionClass.defaultDescription(
                    props.titleKey,
                  )}
                  sectionTitle={props.titleKey}
                  visible={props.visible}
                  reportWhitelabel={props.reportWhitelabel}
                />
              )
            } else if (
              content === 'executiveSummary' &&
              Object.keys(props.contents).includes('executiveSummary')
            ) {
              return resultsSettings.settings.executiveSummary === 'new' ? (
                <ExecutiveSummaryNew3Container
                  key={`content${index}`}
                  resultsFromBackend={props.resultsFromBackend!}
                  visibleElements={
                    props.contents.executiveSummary!.visibleElements
                  }
                  sectionIndex={props.sectionIndex}
                  resultsViewParty={'client'}
                  reportWhitelabel={props.reportWhitelabel}
                  legalCostsDescriptions={getLegalCostsDescriptions()}
                />
              ) : (
                <TextForExecutiveSummary
                  key={`content${index}`}
                  resultsFromBackend={props.resultsFromBackend}
                  visibleElements={
                    props.contents.executiveSummary!.visibleElements
                  }
                  sectionIndex={props.sectionIndex}
                  reportWhitelabel={props.reportWhitelabel}
                  legalCostsDescriptions={getLegalCostsDescriptions()}
                  resultsViewParty="client"
                />
              )
            } else if (
              content === 'proceedings' &&
              Object.keys(props.contents).includes('proceedings')
            ) {
              return (
                <div key={`proceedingsReport${index}`}>
                  <DateOfTrialComponent
                    defaultInstance={currentSnapshot.court!}
                    trial="first"
                    date={currentSnapshot.firstTrialDate!}
                    proceedings={
                      report.reportData.sections[props.sectionIndex].contents
                        .proceedings!
                    }
                    reportWhitelabel={props.reportWhitelabel}
                  />
                  {currentSnapshot.probSecondTrial! > 0 ? (
                    <>
                      <DateOfTrialComponent
                        defaultInstance={currentSnapshot.court!}
                        trial="second"
                        date={currentSnapshot.secondTrialDate!}
                        proceedings={
                          report.reportData.sections[props.sectionIndex]
                            .contents.proceedings!
                        }
                        reportWhitelabel={props.reportWhitelabel}
                      />
                      <SecondTrialProbsComponent
                        reportWhitelabel={props.reportWhitelabel}
                      />
                    </>
                  ) : null}
                </div>
              )
            } else if (
              content === 'claimsOverview' &&
              Object.keys(props.contents).includes('claimsOverview')
            ) {
              return (
                <ClaimsOverviewComponent
                  key={'claimsOverviewKey'}
                  reportWhitelabel={props.reportWhitelabel}
                />
              )
            } else if (
              content === 'claims' &&
              Object.keys(props.contents).includes('claims')
            ) {
              return (
                <div className="claimsContainer" key={`claims${index}`}>
                  {currentSnapshot.claims.map((claim, index2) =>
                    claim.type !== 'tree' &&
                    claim.id ===
                      props.contents.claims![index2].claimOrTreeId ? (
                      <div
                        className="independentClaimPreviewContainer"
                        key={`independentClaimPreviewContainer${index}-${index2}`}
                        style={{
                          flexDirection:
                            flexDirectionOfParagraph[index2] === 'row'
                              ? 'row'
                              : 'column',
                        }}
                      >
                        <div
                          className="independentClaimPreview"
                          style={{ marginRight: 0 }}
                          id={`claimPreview${index2}`}
                        >
                          <div className="independentClaimTileInnerContainer">
                            <IndependentClaimTile
                              claimInfo={claim as IndependentClaim}
                              claimIndex={index2}
                              claimType={claim.type}
                              currency={currentSnapshot.currency}
                              isActive
                              setActiveClaim={() => {}}
                              claimPreviewId={
                                claimPreviewIdArrayFunction(
                                  currentSnapshot,
                                  user.settings,
                                )[index2]
                              }
                              errors={0}
                              removeClaim={() => {}}
                              forReport
                              claimPositionHighlight={undefined}
                              claimIsDragging={undefined}
                              handleDraggingClaim={() => {}}
                              handleChangeClaimOrder={() => {}}
                              reportWhitelabel={props.reportWhitelabel}
                              partiesNames={partiesFormat}
                            />
                          </div>
                        </div>
                        <ReportParagraph
                          textFromSnapshot={
                            props.contents.claims![index2].description
                          }
                          sectionIndex={props.sectionIndex}
                          id={`claim_${index2}`}
                          placeholder={findPlaceholderForClaim(
                            claimPreviewIdArrayFunction(
                              currentSnapshot,
                              user.settings,
                            )[index2],
                            user.settings,
                          )}
                          defaultText={ReportSectionClass.defaultClaimDescription(
                            currentSnapshot,
                            index2,
                          )}
                          sectionTitle={props.titleKey}
                          claimIndex={index2}
                          visible={props.visible}
                          flexDirectionOfParagraph={flexDirectionOfParagraph}
                          setFlexDirectionOfParagraph={
                            setFlexDirectionOfParagraph
                          }
                          reportWhitelabel={props.reportWhitelabel}
                        />
                      </div>
                    ) : claim.type === 'tree' &&
                      claim.id ===
                        props.contents.claims![index2].claimOrTreeId ? (
                      <div
                        className="treePreviewOuterContainer"
                        key={`treePreviewOuterContainer${index2}`}
                        id={`treePreviewOuterContainer${index2}`}
                      >
                        <div
                          className="reportTreeParagraphContainer"
                          id={`reportTreeParagraphContainer${index2}`}
                          style={{ marginLeft: -114 }}
                        >
                          <ReportParagraph
                            textFromSnapshot={
                              props.contents.claims![index2].description
                            }
                            sectionIndex={props.sectionIndex}
                            id={`claim_${index2}`}
                            placeholder={findPlaceholderForClaim(
                              claimPreviewIdArrayFunction(
                                currentSnapshot,
                                user.settings,
                              )[index2],
                              user.settings,
                            )}
                            defaultText={ReportSectionClass.defaultClaimDescription(
                              currentSnapshot,
                              index2,
                            )}
                            sectionTitle={props.titleKey}
                            claimIndex={index2}
                            treeParagraph
                            visible={props.visible}
                            reportWhitelabel={props.reportWhitelabel}
                          />
                        </div>
                        <div
                          className="treePreviewContainer"
                          id={`treePreviewContainer${index2}`}
                        >
                          <TreePdfPreview
                            treeIndex={index2}
                            reportWhitelabel={props.reportWhitelabel}
                          />
                        </div>
                        <div
                          className="treePreviewContainerTransparentLayer"
                          style={{
                            top: document.getElementById(
                              `reportTreeParagraphContainer${index2}`,
                            )
                              ? document.getElementById(
                                  `reportTreeParagraphContainer${index2}`,
                                )!.offsetHeight + 50
                              : 0,
                            height: document.getElementById(
                              `treePreviewOuterContainer${index2}`,
                            )
                              ? document.getElementById(
                                  `treePreviewOuterContainer${index2}`,
                                )!.offsetHeight - 60
                              : 0,
                          }}
                        ></div>
                      </div>
                    ) : null,
                  )}
                  <ClaimsLegendPreview
                    reportWhitelabel={props.reportWhitelabel}
                  />
                </div>
              )
            }

            return null
          })}

        {props.titleKey === SectionTitleKeys.LegalCosts ? (
          <LegalCostsPreviewComponent
            legalCostsDescriptions={props.contents.legalCosts!}
            reportWhitelabel={props.reportWhitelabel}
          />
        ) : null}
        {props.titleKey === SectionTitleKeys.LegalCostsDistribution ? (
          <div className="legalCostsDistributionPreviewAppContainer">
            <div
              className="legalCostsDistributionPreviewAppInnerContainer"
              id="legalCostsDistributionPreviewAppContainer"
            >
              <div className="lFDAppHeader" style={{ marginBottom: 20 }}>
                <p
                  className="lFDAppTitle"
                  id="lFDAppTitle"
                  data-attribute="label-25"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  {`${getText(
                    getLCDPartyDescriptions()[0],
                    user.settings,
                    partiesFormat,
                  )} (${currentSnapshot.currency})`}
                </p>
              </div>
              {currentSnapshot.legalFeesDistribution.map(
                (feeDistribution, index) => (
                  <SingleSegment
                    key={feeDistribution.id}
                    singleSegmentIndex={
                      currentSnapshot.legalFeesDistribution.length - 1 - index
                    }
                    totalCounterClaimedAmount={totalCounterClaimedAmount}
                    disputedAmount={disputedAmount}
                    pdfPreview
                    reportWhitelabel={props.reportWhitelabel}
                  />
                ),
              )}
              {getLCDPartyDescriptions()[0] !==
                getLCDPartyDescriptions()[1] && (
                <div className="lFDAppHeader" style={{ marginBottom: 20 }}>
                  <p
                    className="lFDAppTitle"
                    id="lFDAppTitle"
                    data-attribute="title-..."
                    style={getStyleFromWhitelabel(
                      props.reportWhitelabel,
                      ReportWhitelabelKeys.sectionDescription,
                      FontLabels.regular,
                      'noRoboto',
                    )}
                  >
                    {`${getText(
                      getLCDPartyDescriptions()[1],
                      user.settings,
                      partiesFormat,
                    )} (${currentSnapshot.currency})`}
                  </p>
                </div>
              )}
            </div>
          </div>
        ) : null}
        {props.titleKey === SectionTitleKeys.EnforcementRisk ? (
          <div className="enforcementDetailsContainer">
            <p
              className="enforcementPercentage"
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
              )}
            >
              {snapshotHasEnforcementRisk(currentSnapshot)
                ? currentSnapshot.bankruptcyPercentage
                : 0}
              %
            </p>
            <p
              className="enformentText"
              id="enforcementForReportTitle"
              data-textattribute="title-143"
              style={getStyleFromWhitelabel(
                props.reportWhitelabel,
                ReportWhitelabelKeys.sectionDescription,
                FontLabels.regular,
                'noRoboto',
              )}
            >
              {getText('title-143', user.settings)}
            </p>
          </div>
        ) : null}
        {props.titleKey === SectionTitleKeys.SelectionGraph ? (
          <div className="graphContainer selectionGraphReportContainer">
            <div
              className="graphInnerContainer"
              id={'selectionGraphPreviewContainer'}
            >
              <div
                className={`freeSpace ${
                  resultsSettings.settings.selectionGraphSettings &&
                  resultsSettings.settings.selectionGraphSettings.showRanges
                    ? ''
                    : 'noRanges'
                }`}
              ></div>

              <SelectionGraph
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={currentSnapshot}
                pdfPreview
                reportWhitelabel={props.reportWhitelabel}
                loadingResultsSettings={false}
                resultsViewParty="client"
              />
              <div className="transparentLayer"></div>
            </div>
            <AwaLegendComponent
              resultsFromBackend={props.resultsFromBackend}
              reportWhitelabel={props.reportWhitelabel}
              user={user}
              currentSnapshot={currentSnapshot}
              type="selectionGraph"
              pdfReport
            />
          </div>
        ) : null}
        {props.titleKey === SectionTitleKeys.ProbabilityDistributionGraph ? (
          <div className="graphContainer">
            <div
              className="graphInnerContainer"
              id={'distributionGraphPreviewContainer'}
            >
              <DistributionGraph
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={currentSnapshot}
                pdfPreview
                reportWhitelabel={props.reportWhitelabel}
              />
            </div>
            <AwaLegendComponent
              resultsFromBackend={props.resultsFromBackend}
              reportWhitelabel={props.reportWhitelabel}
              user={user}
              currentSnapshot={currentSnapshot}
              type="distributionGraph"
              pdfReport
            />
          </div>
        ) : null}
        {props.titleKey === SectionTitleKeys.GetPayGraph ? (
          <div className="graphContainer">
            <div className="graphInnerContainer" id={'getPayPreviewContainer'}>
              <GetPayGraph
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={currentSnapshot}
                pdfPreview
                reportWhitelabel={props.reportWhitelabel}
              />
            </div>
            <AwaLegendComponent
              resultsFromBackend={props.resultsFromBackend}
              reportWhitelabel={props.reportWhitelabel}
              user={user}
              currentSnapshot={currentSnapshot}
              type="getPayGraph"
              pdfReport
            />
          </div>
        ) : null}
        {props.titleKey === SectionTitleKeys.ExpectedValueBreakdown ? (
          <div className="graphContainer">
            <div
              className="graphInnerContainer"
              id={'expectedValueBreakdownPreviewContainer'}
            >
              <ExpectedValueBreakdownForReport
                resultsFromBackend={props.resultsFromBackend}
                roundedResults={resultsSettings.settings.roundedResults}
                pdfPreview
                reportWhitelabel={props.reportWhitelabel}
                resultsViewParty="client"
              />
            </div>
            <AwaLegendComponent
              resultsFromBackend={props.resultsFromBackend}
              reportWhitelabel={props.reportWhitelabel}
              user={user}
              currentSnapshot={currentSnapshot}
              type="expectedValueBreakdown"
              pdfReport
              style={{ marginLeft: '0px' }}
            />
          </div>
        ) : null}
        {props.titleKey === SectionTitleKeys.BestWorstScenario ? (
          <div className="bestAndWorstScenarioContainer">
            <div
              className="bestAndWorstScenarioInnerContainer"
              id={'bestAndWorstScenarioPreviewContainer'}
            >
              <BestWorstScenario
                widthOfDiv={2000}
                heightOfDiv={1000}
                resultsFromBackend={props.resultsFromBackend!}
                currentSnapshot={currentSnapshot}
                previewPdf
                reportWhitelabel={props.reportWhitelabel}
                resultsViewParty="opposing"
              />
              <div className="transparentLayer"></div>
            </div>
          </div>
        ) : null}
        {props.visible === false ? (
          <>
            <div
              className="transparentSectionLayerContainer hiddenSectionLayerContainer"
              id={`hiddenSectionLayerContainer-${props.titleKey}-${props.sectionId}`}
            ></div>
            <div
              className="transparentSectionLayerContainer"
              onMouseEnter={() => setShowIncludeOption(props.titleKey)}
            >
              {showIncludeOption ? (
                <div className="inlcudeMessageContainer">
                  <div className="notIncludedMessage">
                    <p
                      className="notIncludedMessageReport"
                      id={`notIncludedMessageReport-${props.titleKey}-${props.sectionId}`}
                      data-textattribute="message-62"
                    >
                      {getText('message-62', user.settings)}
                    </p>
                  </div>
                  <Button
                    id={`includeSectionButton-${props.titleKey}-${props.sectionId}`}
                    buttonText={getText('button-44', user.settings)}
                    buttonTextAttribute="button-44"
                    buttonType="outlined"
                    type="button"
                    onClick={handleIncludeExcludeSection}
                    className="includeSectionButton"
                    side="left"
                    NoUpperCase={true}
                    small={false}
                  />
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}
