//Libraries
import React, { useState, useRef, useEffect, FormEvent } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
//Components
import Proceedings from './tabs/1_proceedings/Proceedings'
import Claims from './tabs/2_claims/Claims'
import LegalCosts from './tabs/3_legalCosts/LegalCosts'
import LegalCostsDistribution from './tabs/5_legalCostsDistribution/LegalFeesDistribution'
import LoadingResults from './tabs/6_loadingResults/LoadingResults'
//Images
import dangerImg from '../../resources/images/122-danger.svg'
//Functions
import {
  calculateExpectedResults,
  deepCloneObject,
  createIdentityFromDb,
  findHandlingErrorState,
  transformDateToString,
  hasWarningMessage,
  userIsAdmin,
  logActivity,
  getRandomInt,
} from '../../services/commonFunctions'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { scenarioSnapshotState } from '../../states/ScenarioSnapshotState'
import { ScenarioSnapshot } from '../../models/scenarioSnapshot'
import {
  AccessRole,
  AccessRoleKeys,
  ClaimType,
  InterestViewOption,
  partyFormatFromString,
  RequestError,
  SnapshotStatus,
  snapshotStatusFromString,
  ToolTabs,
  TypeOfInstance,
  UndoRedoType,
} from '../../models/enums'
import { scenarioIdentityState } from '../../states/ScenarioIdentityState'
import {
  DefaultSnapshotState,
  Params,
  ResultsObject,
  ScenarioIdentity,
  SnapshotSelectorObject,
  TabsErrors,
  TreeTableInfo,
  TreeTablesState,
} from '../../models/generalTypes'
import { savedState } from '../../states/SavedState'
import {
  analyze,
  calculateTree,
  getScenario,
  newToken,
  saveScenarioSnapshot,
  updateUserChecklist,
} from '../../services/requests'
import { changeGlobalSnapshot } from '../../services/changeGlobalSnapshot'
import Results from './tabs/7_results/Results'
import ProceedingsPlaceholder from './tabs/1_proceedings/ProceedingsPlaceholder'
import FailedResults from '../../Components/failedResultsComponent/FailedResults'
import { activeTabsState } from '../../states/ActiveTabsState'
import {
  firstClaimWithError,
  firstTabWithErrors,
  focusOnFirstError,
  hasTabsErrors,
  secondTrialDateIsValid,
  validateGlobalState,
} from '../../services/validateGlobalState'
import LowScreenMessage from '../../Components/popUps/LowScreenMessage'
import FullScreenPopMessage from '../../Components/popUps/FullScreenPopMessage'
import { formattedNumToString } from '../../services/formatNum'
import { treeTablesState } from '../../states/TreeTablesState'
import {
  APP_CALCULATION_LIMIT,
  AWA_CALCULATION_LIMIT,
  TREE_CALCULATION_LIMIT,
} from '../../services/constants'
import { pageDepthState } from '../../states/PageDepthState'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import { reportMenuState } from '../../states/ReportMenuState'
import { onlineState } from '../../states/OnlineState'
import { doValidationState } from '../../states/DoValidationState'
import { userState } from '../../states/UserState'
import { getText } from '../../services/textFunctions'
import VideoInfoButtonPopUp from '../../Components/InfoHover/videoInfoButton/VideoInfoButtonPopUp'
import Tabs from '../../Components/tabs/Tabs'
import { TreeClaim } from '../../models/treeModels/treeClaim'
import { editTreeNodeFromUndoState } from '../../states/EditTreeNodeFromUndo'
import { viewerState } from '../../states/ViewerState'
import {
  calculateValueOfTree,
  findMainTreeAndOrphanTrees,
  findTreeClaimedAndWeightedValues,
  findZeroProbabilityEventsInMainTree,
} from '../../services/treeFunctions/treeBasicFunctions'
import {
  claimtypeIdArray,
  handleClaimtypeIdChange,
} from '../../services/claimFunctions'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { getSocketUrl } from '../../services/socket_services'
import Button from '../../Components/Buttons/Button/Button'
import ReportChangesMessageComponent from './tabs/7_results/resultsComponents/5_pdfReport/pdfReportComponents/pdfReportMenuComponents/ReportChangesMessageComponent'
import { UserChecklist } from '../../models/userChecklist'
import { User } from '../../models/user'
import { featuresState } from '../../states/FeaturesState'
import {
  TreeId,
  TreeObjectForCalculation,
} from '../../models/treeModels/treeTypes'
import { resetNodesPositions } from '../../services/treeFunctions/treePositioningFunctions'
import { recoveryModeState } from '../../states/RecoveryModeState'
import RecoveryScenarioPopUp from '../../Components/popUps/RecoveryScenarioPopUp'
import { allowShortcutsState } from '../../states/AllowShortcutsState'
import WarningSubTextHandlingErrors from '../../Components/popUps/popUpSmallComponents/WarningSubTextHandlingErrors'
import { saveToken } from '../../services/storage'
import { snapshotChangeKillsResults } from '../../services/killingTheResultsFunctions'
import Advanced from './tabs/4_advanced/Advanced'
import { freemiumState } from '../../states/FreemiumState'
import {
  freemiumCalculateSnapshot,
  freemiumCalculateTree,
  freemiumUpdateSnapshot,
  getFreemiumJob,
  getFreemiumQueue,
  getFreemiumScenario,
} from '../../freemiumServices/freemiumRequests'
import {
  createIdentityFromStorage,
  freemiumCreateIdentityFromDb,
  getScenarioFromStorage,
  saveFreemiumResults,
  saveNewFreemiumSnapshot,
} from '../../freemiumServices/freemiumServices'
import { StorageCase } from '../../models/freemiumGeneralTypes'
import {
  FREEMIUM_APP_CALCULATION_LIMIT,
  FREEMIUM_SCENARIO_ID,
} from '../../freemiumServices/freemiumConstants'
import { saveFreemiumPreferences } from '../../freemiumServices/freemiumStorage'
import { FreemiumMessageType } from '../../models/freemiumEnums'
import { reverseCase } from '../../services/reverseCaseFunctions'
import useWindowSize from '../../customHooks/useWindowSize'
import { checkIfTreeHasInterest } from '../../services/resultsFunctions'
import { checkInWithMicrosoft } from '../../services/sso_functions'

export default function ToolPage() {
  const params = useParams<Params>()
  const navigate = useNavigate()
  const windowSize = useWindowSize()

  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const features = useRecoilValue(featuresState)

  const [scenarioIdentity, setScenarioIdentity] = useRecoilState(
    scenarioIdentityState,
  )
  const [savedSnapshot, setSavedSnapshot] = useRecoilState(savedState)
  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const [user, setUser] = useRecoilState(userState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const calcLimit = freemium.isFreemium
    ? FREEMIUM_APP_CALCULATION_LIMIT
    : APP_CALCULATION_LIMIT // The limit of the scenario combinations that are allowed to be calculated. This number comes after testing the server with the current backend and calculation configuration
  const treeLimit = TREE_CALCULATION_LIMIT // The limit of the TREE scenario combinations that are allowed to be calculated.

  const [treeLimitReached, setTreeLimitReached] = useState<string | undefined>(
    undefined,
  )
  const [firstTimeLoad, setFirstTimeLoad] = useState(true)
  const [loadingScenario, setLoadingScenario] = useState(true) // a state that remains true until the case is fetched from Django -- bool
  const [inactiveTabs, setInactiveTabs] = useState(false)
  const [tabsErrors, setTabsErrors] = useState<TabsErrors | undefined>(
    undefined,
  )
  const [previousTab, setPreviousTab] = useState(ToolTabs.proceedings)
  const [doValidation, setDoValidation] = useRecoilState(doValidationState)
  const [validatingTreeId, setValidatingTreeId] = useState<undefined | TreeId>(
    undefined,
  )
  const [calculationLimitMessage, setCalculationLimitMessage] = useState(false)
  const [calculationLimitPopUp, setCalculationLimitPopUp] = useState(false)
  const [calculatingAllTrees, setCalculatingAllTrees] = useState(-1)
  const setPageDepth = useSetRecoilState(pageDepthState)
  const setReportMenu = useSetRecoilState(reportMenuState)
  const setEditTreeNodeFromUndo = useSetRecoilState(editTreeNodeFromUndoState)

  const statusInterval = useRef<NodeJS.Timeout | undefined>(undefined)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [online, setOnline] = useRecoilState(onlineState)
  const [cancelBecauseOfUpdateMessage, setCancelBecauseOfUpdateMessage] =
    useState(false)
  const [videoPostName, setVideoPostName] = useState<undefined | string>(
    undefined,
  )
  const [ownRole, setOwnRole] = useState<AccessRole>(AccessRole.NONE)
  const [treeWarningsIndex, setTreeWarningsIndex] = useState(-1)
  const [checkingAllTrees, setCheckingAllTrees] = useState(-1)
  const [errors, setErrors] = useState<string[]>([])
  const [errorsIdTabs, setErrorsIdTabs] = useState<string[]>([])
  const [anotherUserEditing, setAnotherUserEditing] = useState('')
  const [openInOtherTab, setOpenInOtherTab] = useState(false)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [recoveryModePopUp, setRecoveryModePopUp] = useState(false)
  const [tempResultsForRecovery, setTempResultsForRecovery] = useState<
    ResultsObject | undefined
  >(undefined)
  const setAllowShortcuts = useSetRecoilState(allowShortcutsState)
  const [failedSocketConnectionPopUp, setFailedSocketConnectionPopUp] =
    useState(false)
  const [socketUrl, setSocketUrl] = useState(
    getSocketUrl(freemium.isFreemium ? 'freemium' : 'groups'),
  )
  const [fakeQueue, setFakeQueue] = useState<number | undefined>(undefined)
  const fakeQueueTimeout = useRef<NodeJS.Timeout | undefined>(undefined)

  const isStatusAnalysing =
    scenarioIdentity.snapshotStatus === SnapshotStatus.Queued ||
    scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
    scenarioIdentity.snapshotStatus === SnapshotStatus.ProcessingReversed ||
    scenarioIdentity.snapshotStatus === SnapshotStatus.CalculatingTrees ||
    scenarioIdentity.snapshotStatus === SnapshotStatus.Saving ||
    scenarioIdentity.snapshotStatus === SnapshotStatus.Queuing

  const isStatusDone = scenarioIdentity.snapshotStatus === SnapshotStatus.Done

  const isStatusFailed =
    activeTabs.tab === ToolTabs.results &&
    scenarioIdentity.snapshotStatus === SnapshotStatus.Failed
  const isLoadingResultsTab =
    activeTabs.tab === ToolTabs.results && isStatusAnalysing

  const isResultsTab = activeTabs.tab === ToolTabs.results && isStatusDone

  const isFailedTab = activeTabs.tab === ToolTabs.results && isStatusFailed

  const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
    socketUrl,
    {
      onOpen: () => {
        if (features.use_microsoft_sso) {
          console.log('websocket opened, checking microsoft sso')

          checkInWithMicrosoft(user.email).then((res) => {
            if (res === false) {
              setUser(User.EmptyUser())
            }
          })
        }
        console.log('opened')
      },
      reconnectInterval: 3000,
      reconnectAttempts: 3,
      onError(event) {
        if (!freemium.isFreemium) {
          newToken().then((res) => {
            if (!('errorCode' in res)) {
              saveToken(res.data.token)
            }
          })
        }

        console.log(event)
      },
      shouldReconnect: () => true,
      onReconnectStop: () => {
        setFailedSocketConnectionPopUp(true)
      },
    },
    socketUrl !== 'wss://none',
  )

  useEffect(() => {
    if (socketUrl === 'wss://none') {
      setSocketUrl(getSocketUrl(freemium.isFreemium ? 'freemium' : 'groups'))
    }

    // eslint-disable-next-line
  }, [socketUrl])

  useEffect(() => {
    if (freemium.isFreemium) {
      setFreemiumStatus().then((res) => {
        if (res) {
          //Mixpanel 11 (Freemium)
          logActivity(true, 'Opened a scenario')
        }
      })
      return
    }

    if (
      readyState === ReadyState.OPEN &&
      params.scenarioId &&
      scenarioIdentity.scenarioId !== params.scenarioId
    ) {
      sendJsonMessage({
        type: 'enter_tool_page',
        caseid: params.scenarioId,
      })
    }

    // eslint-disable-next-line
  }, [params.caseId, params.scenarioId, readyState])

  useEffect(() => {
    if (freemium.isFreemium) {
      return
    }

    if (
      lastJsonMessage &&
      (lastJsonMessage as any).type === 'enter_tool_page'
    ) {
      if ((lastJsonMessage as any).accept) {
        setFirstTimeLoad(true)
        setStatus().then((res) => {
          if (res) {
            //Mixpanel 11
            logActivity(false, 'Opened a scenario')
          }
        })
      } else {
        if ((lastJsonMessage as any).reason) {
          setAnotherUserEditing((lastJsonMessage as any).reason)
        } else {
          if (!handlingErrors.forbidden && !handlingErrors.not_exist) {
            setHandlingErrors(
              findHandlingErrorState(
                { errorCode: RequestError.BAD_REQUEST },
                handlingErrors,
                'openScenarioSocket',
                true,
              ),
            )
          }
        }
      }
    } else if (
      lastJsonMessage &&
      (lastJsonMessage as any).type === 'channel_group_kicked'
    ) {
      setAnotherUserEditing((lastJsonMessage as any).user)
    } else if (
      lastJsonMessage &&
      (lastJsonMessage as any).type === 'toolpage_access' &&
      (lastJsonMessage as any).username === user.email
    ) {
      setOpenInOtherTab(
        (lastJsonMessage as any).num_of_sessions > 1 &&
          recoveryMode.recoveryPreview === 'none' &&
          !recoveryModePopUp,
      )
    }

    // eslint-disable-next-line
  }, [lastJsonMessage])

  useEffect(() => {
    setPageDepth(3)
    setEditTreeNodeFromUndo(undefined)

    let tempState: DefaultSnapshotState | undefined = undefined
    for (let i = 0; i < scenarioSnapshot.currentSnapshot.claims.length; i++) {
      const claim = scenarioSnapshot.currentSnapshot.claims[i]
      if (claim.type === ClaimType.tree) {
        let shouldResetPosition = true
        for (const nodeId in (claim as TreeClaim).treeDetails.nodes) {
          if (
            (claim as TreeClaim).treeDetails.nodes[
              nodeId as `node${string}${number}`
            ].properties.position[0] !== 0 &&
            (claim as TreeClaim).treeDetails.nodes[
              nodeId as `node${string}${number}`
            ].properties.position[1] !== 0
          ) {
            shouldResetPosition = false
            break
          }
        }

        if (shouldResetPosition) {
          tempState = resetTreePosition(i, tempState ?? scenarioSnapshot)
        }
      }
    }

    if (tempState) {
      setScenarioSnapshot(tempState)
    }

    // eslint-disable-next-line
  }, [scenarioIdentity.scenarioId])

  useEffect(() => {
    if (activeTabs.tab !== ToolTabs.claims) {
      setEditTreeNodeFromUndo(undefined)
    }
    // eslint-disable-next-line
  }, [activeTabs.tab])

  useEffect(() => {
    if (
      scenarioSnapshot.currentSnapshot.expectedResults! > calcLimit &&
      (!features.use_statistical_model || freemium.isFreemium)
    ) {
      setCalculationLimitMessage(true)
    } else {
      setCalculationLimitMessage(false)
    }
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot.expectedResults])

  useEffect(() => {
    if (isStatusAnalysing) {
      setActiveTabs({ tab: ToolTabs.results, claim: activeTabs.claim })
      setInactiveTabs(true)
    } else if (isStatusFailed) {
      setCancelBecauseOfUpdateMessage(false)
      setActiveTabs({ tab: ToolTabs.results, claim: activeTabs.claim })
      setInactiveTabs(false)
    } else if (isStatusDone) {
      setCancelBecauseOfUpdateMessage(false)
      if (scenarioIdentity.results) {
        setActiveTabs({ tab: ToolTabs.results, claim: activeTabs.claim })
        setInactiveTabs(false)
      }
    } else {
      setActiveTabs({ tab: ToolTabs.proceedings, claim: 0 })
      setInactiveTabs(false)
    }

    // eslint-disable-next-line
  }, [scenarioIdentity.scenarioId])

  useEffect(() => {
    if (isStatusAnalysing) {
      setActiveTabs({ tab: ToolTabs.results, claim: activeTabs.claim })
      setInactiveTabs(true)
      statusInterval.current = freemium.isFreemium
        ? freemiumAddInterval(scenarioIdentity)
        : addInterval(scenarioIdentity)
    } else if (isStatusDone && firstTimeLoad) {
      setActiveTabs({ tab: ToolTabs.results, claim: activeTabs.claim })
      setInactiveTabs(false)
    } else {
      setInactiveTabs(false)
    }

    return () => {
      if (statusInterval.current) {
        clearInterval(statusInterval.current)
        statusInterval.current = undefined
      }
    }
    // eslint-disable-next-line
  }, [scenarioIdentity.snapshotStatus])

  function resetTreePosition(
    treeIndex: number,
    tempState: DefaultSnapshotState,
  ) {
    let tempScenarioSnapshot = deepCloneObject(tempState)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      treeIndex
    ] as TreeClaim

    tempTreeClaim.treeDetails = resetNodesPositions(
      tempTreeClaim.nodeMode,
      tempTreeClaim.treeDetails,
      treeIndex,
    )

    return tempScenarioSnapshot
  }

  const addInterval = (scenarioIdentity: ScenarioIdentity) => {
    if (
      statusInterval.current &&
      (scenarioIdentity.snapshotStatus === SnapshotStatus.Queued ||
        scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
        scenarioIdentity.snapshotStatus === SnapshotStatus.ProcessingReversed)
    ) {
      return statusInterval.current
    }

    if (
      scenarioIdentity.snapshotStatus === SnapshotStatus.Queued ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.ProcessingReversed
    ) {
      setInactiveTabs(true)

      //THE INTERVAL FOR CHECKING THE JOB STATUS
      return setInterval(() => {
        getScenarioStatus().then((res: any) => {
          if (res) {
            if (res.data.job_status === SnapshotStatus.Failed) {
              if (res.data.result_data.includes('error')) {
                console.error(
                  'Schemas Failure message:',
                  JSON.parse(res.data.result_data)['error'],
                )
              }
            }

            console.log('Progress of Calculation: ', res.data.job_progress)
            let tempScenarioIdentity = createIdentityFromDb(res.data)
            //Check for failed jobs due to server update
            if (
              tempScenarioIdentity.snapshotStatus === SnapshotStatus.Queued &&
              hasWarningMessage(
                res,
                'Job restarted due to server maintenance script.',
              )
            ) {
              setCancelBecauseOfUpdateMessage(true)
            }
            setScenarioIdentity(tempScenarioIdentity)
          }
        })
      }, 2000)
    } else if (
      scenarioIdentity.snapshotStatus === SnapshotStatus.Failed ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Cancelled
    ) {
      setCancelBecauseOfUpdateMessage(false)
      setInactiveTabs(false)
      return undefined
    }
  }

  const freemiumAddInterval = (scenarioIdentity: ScenarioIdentity) => {
    if (
      statusInterval.current &&
      (scenarioIdentity.snapshotStatus === SnapshotStatus.Queued ||
        scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
        scenarioIdentity.snapshotStatus === SnapshotStatus.ProcessingReversed)
    ) {
      return statusInterval.current
    }

    if (
      scenarioIdentity.snapshotStatus === SnapshotStatus.Queued ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Processing ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.ProcessingReversed
    ) {
      setInactiveTabs(true)

      //THE INTERVAL FOR CHECKING THE JOB STATUS
      return setInterval(() => {
        getFreemiumJob().then((res: any) => {
          if (res.data) {
            if (res.data.status === SnapshotStatus.Failed) {
              if (res.data.result_data.includes('error')) {
                console.error(
                  'Schemas Failure message:',
                  JSON.parse(res.data.result_data)['error'],
                )
              }
            }

            console.log('Progress of Calculation: ', res.data.progress)
            let tempScenarioIdentity = freemiumCreateIdentityFromDb(
              res.data,
              scenarioIdentity,
            )
            //Check for failed jobs due to server update
            if (
              tempScenarioIdentity.snapshotStatus === SnapshotStatus.Queued &&
              hasWarningMessage(
                res,
                'Job restarted due to server maintenance script.',
              )
            ) {
              setCancelBecauseOfUpdateMessage(true)
            }

            if (
              tempScenarioIdentity.results &&
              params.scenarioId === FREEMIUM_SCENARIO_ID
            ) {
              saveFreemiumResults(tempScenarioIdentity.results)
            }
            setScenarioIdentity(tempScenarioIdentity)
          }
        })
      }, 2000)
    } else if (
      scenarioIdentity.snapshotStatus === SnapshotStatus.Failed ||
      scenarioIdentity.snapshotStatus === SnapshotStatus.Cancelled
    ) {
      setCancelBecauseOfUpdateMessage(false)
      setInactiveTabs(false)
      return undefined
    }
  }

  useEffect(() => {
    //compares the state with the savedState
    if (
      !firstTimeLoad &&
      ownRole !== AccessRole.VIEWER &&
      recoveryMode.recoveryPreview === 'none'
    ) {
      let tempSavedSnapshot = deepCloneObject(savedSnapshot)
      let tempScenarioIdentity = deepCloneObject(scenarioIdentity)

      if (
        JSON.stringify(scenarioSnapshot.currentSnapshot) ===
          JSON.stringify(scenarioSnapshot.savedSnapshot) &&
        tempScenarioIdentity.snapshotStatus !==
          SnapshotStatus.CalculatingTrees &&
        tempScenarioIdentity.snapshotStatus !== SnapshotStatus.Saving &&
        tempScenarioIdentity.snapshotStatus !== SnapshotStatus.Queuing
      ) {
        if (!freemium.isFreemium) {
          sendJsonMessage({
            type: 'recovery_snapshot_delete',
            caseid: params.scenarioId,
            data: scenarioSnapshot.currentSnapshot,
          })
        }

        tempSavedSnapshot.saved = true
        if (scenarioIdentity.results) {
          if (scenarioIdentity.results.value_of_claim !== undefined) {
            tempScenarioIdentity.snapshotStatus = SnapshotStatus.Done
          } else {
            tempScenarioIdentity.snapshotStatus = SnapshotStatus.Failed
          }
        } else {
          tempScenarioIdentity.snapshotStatus = SnapshotStatus.None
        }

        setScenarioIdentity(tempScenarioIdentity)
        setInactiveTabs(false)
        //If the analyze button was pressed and there are results, go to results
        if (
          document.activeElement?.id === 'analyseCaseButton' &&
          scenarioIdentity.results
        ) {
          setActiveTabs({ tab: ToolTabs.results, claim: 0 })
        }
      } else {
        if (!freemium.isFreemium) {
          tempSavedSnapshot.saved = false
          sendJsonMessage({
            type: 'recovery_snapshot_changed',
            caseid: params.scenarioId,
            data: scenarioSnapshot.currentSnapshot,
          })
        }

        if (tempScenarioIdentity.caseId !== '') {
          if (
            (tempScenarioIdentity.snapshotStatus === SnapshotStatus.Done ||
              tempScenarioIdentity.snapshotStatus === SnapshotStatus.Failed) &&
            snapshotChangeKillsResults(
              scenarioSnapshot.savedSnapshot,
              scenarioSnapshot.currentSnapshot,
            )
          ) {
            tempScenarioIdentity.snapshotStatus = SnapshotStatus.None
            tempSavedSnapshot.keepResults = false
            setScenarioIdentity(tempScenarioIdentity)
          } else if (
            scenarioIdentity.results !== undefined &&
            !snapshotChangeKillsResults(
              scenarioSnapshot.savedSnapshot,
              scenarioSnapshot.currentSnapshot,
            ) &&
            tempScenarioIdentity.snapshotStatus !== SnapshotStatus.Queued &&
            tempScenarioIdentity.snapshotStatus !== SnapshotStatus.Queuing &&
            tempScenarioIdentity.snapshotStatus !== SnapshotStatus.Processing &&
            tempScenarioIdentity.snapshotStatus !==
              SnapshotStatus.CalculatingTrees &&
            tempScenarioIdentity.snapshotStatus !==
              SnapshotStatus.ProcessingReversed
          ) {
            if (scenarioIdentity.results.no_of_outcomes !== undefined) {
              tempScenarioIdentity.snapshotStatus = SnapshotStatus.Done
            } else {
              tempScenarioIdentity.snapshotStatus = SnapshotStatus.Failed
            }
            setScenarioIdentity(tempScenarioIdentity)
            tempSavedSnapshot.keepResults = true
          } else {
            tempSavedSnapshot.keepResults = false
          }
        }
      }

      if (freemium.isFreemium && params.scenarioId === FREEMIUM_SCENARIO_ID) {
        saveNewFreemiumSnapshot(
          scenarioSnapshot.currentSnapshot,
          tempSavedSnapshot.keepResults,
        )
      }
      setSavedSnapshot(tempSavedSnapshot)
    }
    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot])

  useEffect(() => {
    if (doValidation) {
      if (
        activeTabs.tab === ToolTabs.claims &&
        document.getElementsByClassName('singleClaimPreviewContainer')
          .length === 0 &&
        document.getElementsByClassName('listPreviewMainContainer').length === 0
      ) {
        return
      }

      let treeIndex = undefined
      if (validatingTreeId) {
        treeIndex = scenarioSnapshot.currentSnapshot.claims.findIndex(
          (claim) =>
            claim.type === ClaimType.tree &&
            (claim.id as TreeId) === validatingTreeId,
        )

        if (treeIndex === -1) {
          treeIndex = undefined
          setValidatingTreeId(undefined)
        }
      }

      let newTabErrors = validateGlobalState(
        scenarioSnapshot.currentSnapshot,
        treeIndex,
      )

      setTabsErrors(newTabErrors[0])
      setErrors([...newTabErrors[1]])
      setErrorsIdTabs([...newTabErrors[2]])
    }

    // eslint-disable-next-line
  }, [scenarioSnapshot.currentSnapshot, doValidation, validatingTreeId])

  useEffect(() => {
    if (tabsErrors && !hasTabsErrors(tabsErrors)) {
      setDoValidation(false)
    }
    // eslint-disable-next-line
  }, [tabsErrors])

  useEffect(() => {
    if (doValidation) {
      if (
        activeTabs.tab === ToolTabs.claims &&
        previousTab !== ToolTabs.claims
      ) {
        if (tabsErrors !== undefined) {
          const claimIndex = firstClaimWithError(tabsErrors!)
          if (claimIndex !== undefined) {
            goAndValidateTab(ToolTabs.claims, claimIndex)
          }
        }
      } else {
        focusOnFirstError(errors, errorsIdTabs, activeTabs)
      }
    }

    setTreeWarningsIndex(-1)

    setPreviousTab(activeTabs.tab)
    // eslint-disable-next-line
  }, [JSON.stringify(activeTabs)])

  useEffect(() => {
    if (freemium.isFreemium) {
      return
    }

    if (savedSnapshot.saved && !loadingScenario) {
      sendJsonMessage({
        type: 'recovery_snapshot_delete',
        caseid: params.scenarioId,
        data: scenarioSnapshot.currentSnapshot,
      })
    }

    // eslint-disable-next-line
  }, [savedSnapshot.saved])

  async function setStatus() {
    setLoadingScenario(true)
    //reset the report state when a new case is loaded
    setReportMenu({
      downloadReportFixedHeaderOn: false,
      orderMenuOn: false,
      downloadPdf: false,
      reportSaveMenuOn: undefined,
      reportWithDifferentResults: false,
      previewPdfOn: false,
    })
    const res2 = await getScenario(params.caseId!, params.scenarioId!)
    setHandlingErrors(
      findHandlingErrorState(res2, handlingErrors, 'getScenario', true),
    )

    if ('errorCode' in res2) {
      return undefined
    } else {
      setOwnRole(AccessRole[res2.data.own_role as AccessRoleKeys])
      setOpenInOtherTab(
        res2.data.accessed_by_users.filter(
          (userObj: any) => userObj.username === user.email,
        ).length > 1 && !res2.data.recovery_snapshot,
      )

      if (
        AccessRole[res2.data.own_role as AccessRoleKeys] === AccessRole.VIEWER
      ) {
        setIsViewer({ ...isViewer, isViewer: true })
      } else {
        setIsViewer({ ...isViewer, isViewer: false })
      }

      let tempSnapshot: ScenarioSnapshot
      if (res2.data.data) {
        tempSnapshot = res2.data.data.data
      } else {
        tempSnapshot = userIsAdmin(user)
          ? ScenarioSnapshot.EmptySnapshotWithDateAndCourt(
              user.settings.formats.partyFormat,
              user.settings.formats.opposingPartyFormat,
              user.settings.formats.currency,
            )
          : ScenarioSnapshot.EmptySnapshot(
              user.settings.formats.partyFormat,
              user.settings.formats.opposingPartyFormat,
              user.settings.formats.currency,
            )
      }

      let tempScenarioSnapshot: DefaultSnapshotState = {
        savedSnapshot: deepCloneObject(tempSnapshot),
        undoRedo: [
          {
            id: 'currencyDropdown',
            type: UndoRedoType.input,
            snapshot: deepCloneObject(tempSnapshot),
            time: new Date(),
          },
        ],
        undoRedoIndex: 0,
        currentSnapshot: deepCloneObject(tempSnapshot),
      }

      if (
        res2.data.recovery_snapshot &&
        AccessRole[res2.data.own_role as AccessRoleKeys] !== AccessRole.VIEWER
      ) {
        setRecoveryMode({
          recoveryPreview: 'none',
          shaking: false,
          showLowScreenMessage: false,
          keepResults: !snapshotChangeKillsResults(
            tempSnapshot,
            res2.data.recovery_snapshot.data.data,
          ),
          old: tempSnapshot,
          new: res2.data.recovery_snapshot.data.data,
        })
        setRecoveryModePopUp(true)
        setAllowShortcuts(false)

        if (res2.data.result_data) {
          setTempResultsForRecovery(res2.data.result_data)
        }
      }

      setScenarioSnapshot(tempScenarioSnapshot)

      let tempScenarioIdentity = createIdentityFromDb(res2.data)
      let tempSavedSnapshot = deepCloneObject(savedSnapshot)
      tempSavedSnapshot.saved = true

      setSavedSnapshot(tempSavedSnapshot)
      setScenarioIdentity(tempScenarioIdentity)

      const tempTreeTablesInfo: TreeTableInfo[] = []

      tempSnapshot.claims.forEach((claim) => {
        if (claim.type === ClaimType.tree) {
          tempTreeTablesInfo.push({
            results: undefined,
            treeId: (claim as TreeClaim).id,
            currentValueOfTree: undefined,
            previousValueOfTree: undefined,
            showSelectionGraph: false,
            showTreeTable: false,
            treeInterestViewOption: InterestViewOption.interest1st,
          })
        }
      })

      setTreeTables({
        loadingMessage: undefined,
        treeTablesInfo: [...tempTreeTablesInfo],
      })

      setTimeout(() => {
        setLoadingScenario(false)
        setFirstTimeLoad(false)
      }, 200)

      return tempScenarioSnapshot as DefaultSnapshotState
    }
  }

  async function setFreemiumStatus() {
    setLoadingScenario(true)
    //reset the report state when a new case is loaded
    setReportMenu({
      downloadReportFixedHeaderOn: false,
      orderMenuOn: false,
      downloadPdf: false,
      reportSaveMenuOn: undefined,
      reportWithDifferentResults: false,
      previewPdfOn: false,
    })
    const res2 =
      params.scenarioId! === FREEMIUM_SCENARIO_ID
        ? getScenarioFromStorage()
        : await getFreemiumScenario(params.caseId!, params.scenarioId!)

    if ((res2 && 'errorCode' in res2) || res2 === undefined) {
      if (res2) {
        setHandlingErrors(
          findHandlingErrorState(
            res2,
            handlingErrors,
            'getFreemiumScenario',
            true,
          ),
        )
      }
      return undefined
    } else {
      setOwnRole(AccessRole.OWNER)

      let tempSnapshot: ScenarioSnapshot
      if ('data' in res2 && res2.data.data) {
        tempSnapshot = res2.data.data.data
      } else if (res2 !== undefined) {
        tempSnapshot = (res2 as StorageCase).snapshot!
        const resUpdate = await freemiumUpdateSnapshot({
          data: tempSnapshot,
        })
        if (!('errorCode' in resUpdate)) {
          tempSnapshot = resUpdate.data.data
          saveNewFreemiumSnapshot(
            tempSnapshot,
            (res2 as StorageCase).validResults ?? false,
          )
        } else {
          setHandlingErrors(
            findHandlingErrorState(
              resUpdate,
              handlingErrors,
              'freemiumUpdateSnapshot',
              true,
            ),
          )
        }
      } else {
        tempSnapshot = ScenarioSnapshot.EmptySnapshotFreemium(
          user.settings.formats.partyFormat,
          user.settings.formats.opposingPartyFormat,
          user.settings.formats.currency,
        )
      }

      let tempScenarioSnapshot: DefaultSnapshotState = {
        savedSnapshot: deepCloneObject(tempSnapshot),
        undoRedo: [
          {
            id: 'currencyDropdown',
            type: UndoRedoType.input,
            snapshot: deepCloneObject(tempSnapshot),
            time: new Date(),
          },
        ],
        undoRedoIndex: 0,
        currentSnapshot: deepCloneObject(tempSnapshot),
      }

      setScenarioSnapshot(tempScenarioSnapshot)

      let tempScenarioIdentity =
        'data' in res2
          ? createIdentityFromDb(res2.data)
          : createIdentityFromStorage(res2)

      if (!('data' in res2)) {
        const jobRes = await getFreemiumJob()
        if (!('errorCode' in jobRes)) {
          tempScenarioIdentity.snapshotStatus = snapshotStatusFromString(
            jobRes.data.status,
          )
        }
      }

      let tempSavedSnapshot = deepCloneObject(savedSnapshot)
      tempSavedSnapshot.saved = true

      setSavedSnapshot(tempSavedSnapshot)
      setScenarioIdentity(tempScenarioIdentity)

      const tempTreeTablesInfo: TreeTableInfo[] = []

      tempSnapshot.claims.forEach((claim) => {
        if (claim.type === ClaimType.tree) {
          tempTreeTablesInfo.push({
            results: undefined,
            treeId: (claim as TreeClaim).id,
            currentValueOfTree: undefined,
            previousValueOfTree: undefined,
            showSelectionGraph: false,
            showTreeTable: false,
            treeInterestViewOption: InterestViewOption.interest1st,
          })
        }
      })

      setTreeTables({
        loadingMessage: undefined,
        treeTablesInfo: [...tempTreeTablesInfo],
      })

      setTimeout(() => {
        setLoadingScenario(false)
        setFirstTimeLoad(false)
      }, 200)

      return tempScenarioSnapshot as DefaultSnapshotState
    }
  }

  useEffect(() => {
    if (calculatingAllTrees > -1 && checkingAllTrees === -2) {
      treeDirectCalculation(
        calculatingAllTrees,
        //id='calculatingMessage'
        //data-textattribute='message-59'
        getText('message-59', user.settings),
        true,
      )
    } else {
      setTreeTables({ ...treeTables, loadingMessage: undefined })
    }
    // eslint-disable-next-line
  }, [calculatingAllTrees])

  useEffect(() => {
    if (recoveryMode.recoveryPreview !== 'none') {
      changeSnapshotPreview(recoveryMode.recoveryPreview)
      setAllowShortcuts(false)
    } else {
      if (!recoveryModePopUp) {
        setAllowShortcuts(true)
      }

      setOpenInOtherTab(
        lastJsonMessage &&
          (lastJsonMessage as any).num_of_sessions &&
          (lastJsonMessage as any).num_of_sessions > 1,
      )
    }

    // eslint-disable-next-line
  }, [recoveryMode.recoveryPreview])

  useEffect(() => {
    if (fakeQueue !== undefined) {
      if (fakeQueue > 0) {
        fakeQueueTimeout.current = setTimeout(() => {
          setFakeQueue((prevState) => (prevState ? prevState - 1 : 0))
          setScenarioIdentity({
            ...scenarioIdentity,
            queueNumber: scenarioIdentity.queueNumber! - 1,
          })
        }, getRandomInt(1500, 4000))
      } else {
        setFakeQueue(undefined)
        freemiumAnalyze()
      }
    }

    return () => {
      clearTimeout(fakeQueueTimeout.current)
    }

    // eslint-disable-next-line
  }, [fakeQueue])

  const getScenarioStatus = async () => {
    const res = await getScenario(params.caseId!, params.scenarioId!)
    setHandlingErrors(
      findHandlingErrorState(res, handlingErrors, 'getScenario', true),
    )
    if ('errorCode' in res) {
      return null
    }

    return res
  }

  // Find the type for keyEvent in Typescript
  function onKeyDown(keyEvent: any) {
    /* Prevents submitting form when pressing Enter */
    if (
      (keyEvent.charCode || keyEvent.keyCode) === 13 &&
      !keyEvent.target.id.includes('reportParagraphTextArea')
    ) {
      keyEvent.preventDefault()
    }
  }

  function handleChangeKeyValue(e: any, key: keyof ScenarioSnapshot) {
    let tempValue = e.target.value
    let tempId = e.target.id
    if (key === 'firstTrialDate' || key === 'secondTrialDate') {
      tempValue = transformDateToString(e.target.value)
      tempId = [e.target.id, e.target.id, true]
    }

    if (key === 'court') {
      if (tempValue === TypeOfInstance.Arbitration) {
        //Mixpanel 27 (All)
        logActivity(freemium.isFreemium, 'Chose type of instance', {
          'Type of Instance': 'Arbitration',
        })
      } else if (tempValue === TypeOfInstance.Public_Court) {
        //Mixpanel 27 (All)
        logActivity(freemium.isFreemium, 'Chose type of instance', {
          'Type of Instance': 'Public Court',
        })
      } else if (tempValue === TypeOfInstance.Other) {
        //Mixpanel 27 (All)
        logActivity(freemium.isFreemium, 'Chose type of instance', {
          'Type of Instance': 'Other',
        })
      }
    }

    if (key === 'partyFormatOwn' || key === 'partyFormatOther') {
      if (tempValue === 'Other (add party description)') {
        tempValue = ''
      } else if (partyFormatFromString(tempValue)) {
        tempValue = partyFormatFromString(tempValue)
      }
    }

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: tempId,
      undoRedoType: UndoRedoType.input,
      value: tempValue,
      key: key,
    }
    let tempScenarioSnapshot = deepCloneObject(
      scenarioSnapshot,
    ) as DefaultSnapshotState
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )
    setScenarioSnapshot(tempScenarioSnapshot)

    if (key === 'firstTrialDate' || key === 'secondTrialDate') {
      let tempTreeTables = deepCloneObject(treeTables) as TreeTablesState
      if (!secondTrialDateIsValid(tempScenarioSnapshot.currentSnapshot)) {
        for (let treeTablesInfo of tempTreeTables.treeTablesInfo) {
          if (
            treeTablesInfo.treeInterestViewOption ===
            InterestViewOption.interest1st
          ) {
            if (
              tempScenarioSnapshot.currentSnapshot.firstTrialDate === undefined
            ) {
              treeTablesInfo.treeInterestViewOption =
                InterestViewOption.noInterest
            }
          } else {
            treeTablesInfo.treeInterestViewOption =
              InterestViewOption.noInterest
          }
        }
      }
      setTreeTables(tempTreeTables)
    }
  }

  const goAndValidateTab = (
    tab: ToolTabs,
    claimIndex?: number,
    tempErrors?: string[],
    tempErrorsIdTabs?: string[],
  ) => {
    if (claimIndex !== undefined) {
      setActiveTabs({ tab: tab, claim: claimIndex })
    } else {
      setActiveTabs({ ...activeTabs, tab: tab })
    }
    setTimeout(() => {
      focusOnFirstError(
        tempErrors ?? errors,
        tempErrorsIdTabs ?? errorsIdTabs,
        {
          tab: tab,
          claim: claimIndex ?? 0,
        },
      )
    }, 100)
  }

  const treeOverLimit = () => {
    for (const claim of scenarioSnapshot.currentSnapshot.claims) {
      if (
        claim.type === ClaimType.tree &&
        (claim as TreeClaim).numOfTreeScenarios >= treeLimit
      ) {
        return claim.name
      }
    }

    return undefined
  }

  function handleSubmit(
    e?: FormEvent<HTMLFormElement>,
    lastCheckedIndex?: number,
    calculatingZopa?: boolean,
  ) {
    e?.preventDefault()
    if (online.networkOn) {
      let tempActiveTabs = activeTabs
      setTreeWarningsIndex(-1)
      setValidatingTreeId(undefined)
      const tempTabsErrors = validateGlobalState(
        scenarioSnapshot.currentSnapshot,
      )
      setTabsErrors(tempTabsErrors[0])
      if (hasTabsErrors(tempTabsErrors[0])) {
        setDoValidation(true)
        setErrors([...tempTabsErrors[1]])
        setErrorsIdTabs([...tempTabsErrors[2]])

        const errorInfo = firstTabWithErrors(tempTabsErrors[0])
        goAndValidateTab(
          errorInfo[0] as ToolTabs,
          errorInfo.length > 1 ? (errorInfo[1] as number) : undefined,
          tempTabsErrors[1],
          tempTabsErrors[2],
        )
        return
      }

      let tempScenarioSnapshot = deepCloneObject(
        scenarioSnapshot,
      ) as DefaultSnapshotState
      let tempExpectedResults = calculateExpectedResults(
        scenarioSnapshot.currentSnapshot,
      )

      tempScenarioSnapshot.currentSnapshot.expectedResults = tempExpectedResults

      let data = tempScenarioSnapshot.currentSnapshot
      let snapshotidForResults = scenarioIdentity.snapshotId

      if (checkTreesForWarnings(tempScenarioSnapshot, lastCheckedIndex)[0]) {
        return
      }

      if (
        features.use_statistical_model === false &&
        data.expectedResults! > calcLimit
      ) {
        if (freemium.isFreemium) {
          setFreemium({ ...freemium, showMessage: FreemiumMessageType.Analyze })
        } else {
          setCalculationLimitPopUp(true)
        }
      } else if (treeOverLimit() !== undefined) {
        setTreeLimitReached(treeOverLimit())
      } else {
        setScenarioIdentity({
          ...scenarioIdentity,
          snapshotProgress: 1,
          results: undefined,
          snapshotStatus: SnapshotStatus.CalculatingTrees,
        })
        setActiveTabs({ ...activeTabs, tab: ToolTabs.results })
        calculateRemainingTrees(tempScenarioSnapshot.currentSnapshot).then(
          async (calculations) => {
            if (
              calculations === undefined ||
              typeof calculations === 'number'
            ) {
              setScenarioIdentity({
                ...scenarioIdentity,
                snapshotProgress: 0,
                results: undefined,
                snapshotStatus: SnapshotStatus.None,
              })
              if (calculations === undefined) {
                setActiveTabs(tempActiveTabs)
              } else {
                setActiveTabs({ tab: ToolTabs.claims, claim: calculations })
              }
              return
            }

            data = calculations[0]
            tempScenarioSnapshot.currentSnapshot = calculations[0]

            if (freemium.isFreemium) {
              tempScenarioSnapshot.savedSnapshot = deepCloneObject(data)
              setScenarioSnapshot(tempScenarioSnapshot)
              freemiumCreateFakeQueue(calculations)
              return
            }

            const isSaved =
              savedSnapshot.saved &&
              !calculations[1] &&
              scenarioSnapshot.currentSnapshot.version ===
                scenarioSnapshot.savedSnapshot.version

            if (!isSaved) {
              if (calculations[1]) {
                setTimeout(() => {
                  setScenarioIdentity({
                    ...scenarioIdentity,
                    results: undefined,
                    snapshotProgress: 2,
                    snapshotStatus: SnapshotStatus.Saving,
                  })
                }, 1200)
              } else {
                setScenarioIdentity({
                  ...scenarioIdentity,
                  results: undefined,
                  snapshotProgress: 2,
                  snapshotStatus: SnapshotStatus.Saving,
                })
              }

              saveScenarioSnapshot(params.caseId!, params.scenarioId!, {
                data: data,
                keep_results: savedSnapshot.keepResults,
                log_entry: 'Save scenario',
              }).then(async (res) => {
                setHandlingErrors(
                  findHandlingErrorState(
                    res,
                    handlingErrors,
                    'saveScenarioSnapshot',
                  ),
                )
                if (!('errorCode' in res)) {
                  let tempSavedSnapshotState = { ...savedSnapshot }
                  tempScenarioSnapshot.savedSnapshot = deepCloneObject(data)
                  tempSavedSnapshotState.saved = true
                  snapshotidForResults = res.data.snapshotid

                  setScenarioSnapshot(tempScenarioSnapshot)
                  setTimeout(() => {
                    setScenarioIdentity({
                      ...scenarioIdentity,
                      snapshotProgress: 3,
                      snapshotStatus: SnapshotStatus.Queuing,
                    })
                  }, 2400)
                  const reversedCurrentSnapshot = reverseCase(
                    tempScenarioSnapshot.currentSnapshot,
                  )

                  const reversedCalculationsOfTrees =
                    ((await calculateRemainingTrees(
                      reversedCurrentSnapshot,
                      true, // the trees are calculated for the reversed and the results on the tree table should not update
                    )) as [ScenarioSnapshot, boolean])![0]

                  analyze(
                    params.caseId!,
                    params.scenarioId!,
                    snapshotidForResults,
                    reversedCalculationsOfTrees,
                    calculatingZopa,
                  ).then((res2) => {
                    setHandlingErrors(
                      findHandlingErrorState(res2, handlingErrors, 'analyze'),
                    )
                    if (!('errorCode' in res2)) {
                      setSavedSnapshot(tempSavedSnapshotState)
                      console.log(
                        'I am saving the new snapshotid',
                        JSON.stringify(snapshotidForResults),
                      )

                      setTimeout(() => {
                        setScenarioIdentity({
                          ...scenarioIdentity,
                          snapshotId: snapshotidForResults,
                          snapshotStatus: SnapshotStatus.Queued,
                          snapshotProgress: 4,
                        })
                      }, 3600)
                      if (!user.checklist.analyzeScenario) {
                        updateUserChecklist(
                          new UserChecklist(
                            user.checklist.introVideo,
                            user.checklist.accessTutorial,
                            user.checklist.createCase,
                            true,
                            user.checklist.downloadReport,
                          ),
                        ).then((res) => {
                          if (!('errorCode' in res)) {
                            let newUser = deepCloneObject(user) as User
                            newUser.checklist = UserChecklist.FromJson(res.data)
                            setUser(newUser)
                          }
                        })
                      }
                      if (
                        tempScenarioSnapshot.currentSnapshot.expectedResults! >
                          AWA_CALCULATION_LIMIT &&
                        features.use_statistical_model
                      ) {
                        //Mixpanel 167
                        logActivity(false, 'Analysed a scenario using AWA')
                      } else {
                        //Mixpanel 67
                        logActivity(false, 'Analysed a scenario')
                      }
                    } else {
                      setActiveTabs({
                        ...activeTabs,
                        tab: ToolTabs.proceedings,
                      })
                      setScenarioIdentity({
                        ...scenarioIdentity,
                        snapshotProgress: 0,
                        snapshotStatus: SnapshotStatus.None,
                      })
                    }
                  })
                } else {
                  setActiveTabs({
                    ...activeTabs,
                    tab: ToolTabs.proceedings,
                  })
                  setScenarioIdentity({
                    ...scenarioIdentity,
                    snapshotProgress: 0,
                    snapshotStatus: SnapshotStatus.None,
                  })
                }
              })
            } else {
              setScenarioIdentity({
                ...scenarioIdentity,
                snapshotProgress: 3,
                snapshotStatus: SnapshotStatus.Queuing,
              })
              const reversedCurrentSnapshot = reverseCase(
                tempScenarioSnapshot.currentSnapshot,
              )

              const reversedCalculationsOfTrees =
                ((await calculateRemainingTrees(
                  reversedCurrentSnapshot,
                  true, // the trees are calculated for the reversed and the results on the tree table should not update
                )) as [ScenarioSnapshot, boolean])![0]

              analyze(
                params.caseId!,
                params.scenarioId!,
                scenarioIdentity.snapshotId,
                reversedCalculationsOfTrees,
                calculatingZopa,
              ).then((res) => {
                setHandlingErrors(
                  findHandlingErrorState(res, handlingErrors, 'analyze'),
                )
                if (!('errorCode' in res)) {
                  setTimeout(() => {
                    setScenarioIdentity({
                      ...scenarioIdentity,
                      snapshotStatus: SnapshotStatus.Queued,
                      snapshotProgress: 4,
                    })
                  }, 1200)
                  if (!user.checklist.analyzeScenario) {
                    updateUserChecklist(
                      new UserChecklist(
                        user.checklist.introVideo,
                        user.checklist.accessTutorial,
                        user.checklist.createCase,
                        true,
                        user.checklist.downloadReport,
                      ),
                    ).then((res) => {
                      if (!('errorCode' in res)) {
                        let newUser = deepCloneObject(user) as User
                        newUser.checklist = UserChecklist.FromJson(res.data)
                        setUser(newUser)
                      }
                    })
                  }
                  //Mixpanel 67
                  logActivity(false, 'Analysed a scenario')
                } else {
                  setScenarioIdentity({
                    ...scenarioIdentity,
                    snapshotProgress: 0,
                    snapshotStatus: SnapshotStatus.None,
                  })
                }
              })
            }
          },
        )
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const freemiumCreateFakeQueue = async (
    calculations: [ScenarioSnapshot, boolean],
  ) => {
    const isSaved =
      !calculations[1] &&
      scenarioSnapshot.currentSnapshot.version ===
        scenarioSnapshot.savedSnapshot.version

    if (!isSaved && params.scenarioId === FREEMIUM_SCENARIO_ID) {
      saveNewFreemiumSnapshot(calculations[0], savedSnapshot.keepResults)
    } else if (!isSaved) {
      setScenarioSnapshot({
        ...scenarioSnapshot,
        currentSnapshot: calculations[0],
      })
    }

    setScenarioIdentity({
      ...scenarioIdentity,
      snapshotProgress: 3,
      snapshotStatus: SnapshotStatus.Queuing,
    })

    let queueNumber = 0
    const queueNumberRes = await getFreemiumQueue()
    if (!('errorCode' in queueNumberRes)) {
      queueNumber = queueNumberRes.data
    }

    const fakeQueueResults = [0, 1, 0, 2, 0, 3, 0, 4, 0, 5, 0, 6]
    if (queueNumber < 2) {
      queueNumber = fakeQueueResults[getRandomInt(0, 11)]
      setFakeQueue(queueNumber)
    } else {
      freemiumAnalyze()
    }

    setScenarioIdentity({
      ...scenarioIdentity,
      snapshotProgress: 4,
      snapshotStatus: SnapshotStatus.Queued,
      queueNumber: queueNumber,
    })
  }

  const freemiumAnalyze = async () => {
    let snapshotToCalculate: ScenarioSnapshot | undefined = undefined
    if (params.scenarioId === FREEMIUM_SCENARIO_ID) {
      const storageScenario = getScenarioFromStorage()
      if (!storageScenario) {
        setActiveTabs({
          ...activeTabs,
          tab: ToolTabs.proceedings,
        })
        setScenarioIdentity({
          ...scenarioIdentity,
          snapshotProgress: 0,
          snapshotStatus: SnapshotStatus.None,
        })

        return
      }

      snapshotToCalculate = storageScenario.snapshot
    } else {
      snapshotToCalculate = scenarioSnapshot.currentSnapshot
    }

    const reversedSnapshot = reverseCase(snapshotToCalculate!)

    const reversedCalculationsOfTrees = ((await calculateRemainingTrees(
      reversedSnapshot,
      true, // the trees are calculated for the reversed and the results on the tree table should not update
    )) as [ScenarioSnapshot, boolean])![0]

    const res = await freemiumCalculateSnapshot(
      {
        data: snapshotToCalculate,
        reversed_data: reversedCalculationsOfTrees,
      },
      scenarioIdentity.caseId,
      scenarioIdentity.scenarioId,
    )
    setHandlingErrors(
      findHandlingErrorState(res, handlingErrors, 'freemiumCalculateSnapshot'),
    )
    if ('errorCode' in res) {
      setScenarioIdentity({
        ...scenarioIdentity,
        snapshotProgress: 0,
        snapshotStatus: SnapshotStatus.None,
      })
      setActiveTabs({ tab: ToolTabs.proceedings, claim: 0 })
      return
    }

    if (!user.checklist.analyzeScenario) {
      const newChecklist = new UserChecklist(
        user.checklist.introVideo,
        user.checklist.accessTutorial,
        user.checklist.createCase,
        true,
        user.checklist.downloadReport,
      )
      saveFreemiumPreferences({
        settings: user.settings,
        checklist: newChecklist,
      })

      let newUser = deepCloneObject(user) as User
      newUser.checklist = newChecklist
      setUser(newUser)
    }

    //Mixpanel 67 (Freemium)
    logActivity(true, 'Analysed a scenario')
  }

  function removeClaim(
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: [focusId, highlightId],
      undoRedoType: UndoRedoType.div,
      value: 'remove',
      key: 'claims',
      claimIndex: claimIndex,
      key2: 'remove',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    if (
      tempScenarioSnapshot.currentSnapshot.claims[claimIndex].type === 'tree'
    ) {
      //Mixpanel 36 (All)
      logActivity(freemium.isFreemium, 'Removed legal tree')
    } else {
      //Mixpanel 34 (All)
      logActivity(freemium.isFreemium, 'Removed independent claim')
    }
    tempScenarioSnapshot.currentSnapshot.claims.splice(claimIndex, 1)

    const claimsArray = claimtypeIdArray(tempScenarioSnapshot.currentSnapshot)

    tempScenarioSnapshot.currentSnapshot = handleClaimtypeIdChange(
      claimsArray[0],
      tempScenarioSnapshot.currentSnapshot,
    )

    if (
      scenarioSnapshot.currentSnapshot.claims[claimIndex].type ===
      ClaimType.tree
    ) {
      const treeTablesInfoIndex = treeTables.treeTablesInfo.findIndex(
        (el) =>
          el.treeId ===
          (scenarioSnapshot.currentSnapshot.claims[claimIndex] as TreeClaim).id,
      )
      let tempTreeTablesInfo: TreeTableInfo[] = deepCloneObject(
        treeTables.treeTablesInfo,
      )
      if (treeTablesInfoIndex > -1) {
        tempTreeTablesInfo.splice(treeTablesInfoIndex, 1)
      }
      setTreeTables({
        ...treeTables,
        treeTablesInfo: [...tempTreeTablesInfo],
      })
    }

    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
  }

  const changeTreeTablesInfo = (
    treeTableInfo: TreeTableInfo,
    loadingMessage?: string,
  ) => {
    const tempTreeTablesInfo = [...treeTables.treeTablesInfo]
    const index = tempTreeTablesInfo.findIndex(
      (res) => res.treeId === treeTableInfo.treeId,
    )
    if (index >= 0) {
      if (treeTables.treeTablesInfo[index].currentValueOfTree !== undefined) {
        treeTableInfo.previousValueOfTree =
          treeTables.treeTablesInfo[index].currentValueOfTree
      }
    }
    tempTreeTablesInfo[index] = {
      ...treeTableInfo,
    }
    setTreeTables({
      loadingMessage: loadingMessage ?? treeTables.loadingMessage,
      treeTablesInfo: [...tempTreeTablesInfo],
    })
  }

  function checkIfTreeHasErrors(treeIndex: number): boolean {
    const tempTabsErrors = validateGlobalState(
      scenarioSnapshot.currentSnapshot,
      treeIndex,
    )

    if (
      ownRole !== AccessRole.VIEWER &&
      recoveryMode.recoveryPreview === 'none'
    ) {
      setTabsErrors(tempTabsErrors[0])
    }
    if (hasTabsErrors(tempTabsErrors[0])) {
      if (
        ownRole !== AccessRole.VIEWER &&
        recoveryMode.recoveryPreview === 'none'
      ) {
        setDoValidation(true)
        const errorInfo = firstTabWithErrors(tempTabsErrors[0], true)
        goAndValidateTab(
          errorInfo[0] as ToolTabs,
          errorInfo.length > 1 ? (errorInfo[1] as number) : undefined,
          tempTabsErrors[1],
          tempTabsErrors[2],
        )

        const treeId = scenarioSnapshot.currentSnapshot.claims[treeIndex]
          .id as TreeId
        setValidatingTreeId(treeId)
      } else {
        if (ownRole === AccessRole.VIEWER) {
          setIsViewer({ ...isViewer, shaking: true })
        } else {
          setRecoveryMode({ ...recoveryMode, shaking: true })
        }
      }

      setCalculatingAllTrees(-1)
      return true
    } else {
      setValidatingTreeId(undefined)
    }
    return false
  }

  async function treeDirectCalculation(
    treeIndex: number,
    message: string,
    calculatingAll?: boolean,
  ) {
    if (online.networkOn) {
      const treeHasErrors = checkIfTreeHasErrors(treeIndex)
      if (treeHasErrors) return

      if (
        (scenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim)
          .numOfTreeScenarios >= treeLimit
      ) {
        setCalculatingAllTrees(-1)
        setTreeLimitReached(
          (scenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim)!
            .name,
        )
      } else {
        setTreeTables({ ...treeTables, loadingMessage: message })

        const treeObjectForCalculation: TreeObjectForCalculation = {
          firstTrialDate: scenarioSnapshot.currentSnapshot.firstTrialDate,
          secondTrialDate: scenarioSnapshot.currentSnapshot.secondTrialDate,
          treeDetails: (
            scenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim
          ).treeDetails,
          snapshotid: scenarioIdentity.snapshotId,
          treeid: (
            scenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim
          ).id,
        }

        const res = freemium.isFreemium
          ? await freemiumCalculateTree(treeObjectForCalculation)
          : await calculateTree(treeObjectForCalculation)

        setTimeout(() => {
          setHandlingErrors(
            findHandlingErrorState(res, handlingErrors, 'calculateTree'),
          )
        }, 800)

        if ('errorCode' in res) {
          if (res['errorCode'] === 598) {
            setCalculatingAllTrees(-1)
            setCheckingAllTrees(-1)
            setActiveTabs({ tab: ToolTabs.claims, claim: treeIndex })
          }
          setTreeTables({ ...treeTables, loadingMessage: undefined })
          return undefined
        } else {
          //id='calculatingMessage'
          //data-textattribute='message-60'
          if (message === getText('message-60', user.settings)) {
            //Mixpanel 15 (All)
            logActivity(
              freemium.isFreemium,
              "Calculated a tree using 'Calculate Amounts' button",
            )
            //id='calculatingMessage'
            //data-textattribute='message-61'
          } else if (message === getText('message-61', user.settings)) {
            //Mixpanel 14 (All)
            logActivity(
              freemium.isFreemium,
              "Calculated a tree using 'CALCULATE POSSIBLE OUTCOMES' button",
            )
          }
        }

        const valueOfTree = calculateValueOfTree(
          scenarioSnapshot.currentSnapshot,
          res.data.results,
        )

        const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
          (treeTableInfo) =>
            treeTableInfo.treeId ===
            scenarioSnapshot.currentSnapshot.claims[treeIndex].id,
        )

        changeTreeTablesInfo(
          {
            treeId: (
              scenarioSnapshot.currentSnapshot.claims[treeIndex] as TreeClaim
            ).id,
            results: [res.data.results[0], res.data.results[1]],
            currentValueOfTree: { ...valueOfTree },
            previousValueOfTree: undefined,
            showSelectionGraph:
              treeTables.treeTablesInfo[treeTableInfoIndex].showSelectionGraph,
            showTreeTable: true,
            treeInterestViewOption: checkIfTreeHasInterest(
              res.data.results[0],
              scenarioSnapshot.currentSnapshot,
            )
              ? treeTables.treeTablesInfo[treeTableInfoIndex]
                  .treeInterestViewOption
              : InterestViewOption.noInterest,
          },
          calculatingAll ? message : undefined,
        )

        const treeClaimedAndWeightedValues = findTreeClaimedAndWeightedValues(
          res.data.results,
        )

        let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
        let tempClaimInfo =
          tempScenarioSnapshot.currentSnapshot.claims[treeIndex]

        tempClaimInfo.numOfTreeScenarios = res.data.results[0].length
        tempClaimInfo.totalClaimedAmount =
          treeClaimedAndWeightedValues.totalClaimedAmount
        tempClaimInfo.totalCounterClaimedAmount =
          treeClaimedAndWeightedValues.totalCounterClaimedAmount
        tempClaimInfo.totalClaimedAmountInterest1st =
          treeClaimedAndWeightedValues.totalClaimedAmountInterest1st
        tempClaimInfo.totalCounterClaimedAmountInterest1st =
          treeClaimedAndWeightedValues.totalCounterClaimedAmountInterest1st
        tempClaimInfo.totalClaimedAmountInterest2nd =
          treeClaimedAndWeightedValues.totalClaimedAmountInterest2nd
        tempClaimInfo.totalCounterClaimedAmountInterest2nd =
          treeClaimedAndWeightedValues.totalCounterClaimedAmountInterest2nd
        tempClaimInfo.treeWeightedValue =
          treeClaimedAndWeightedValues.weightedValue
        tempClaimInfo.treeWeightedValueInterest1st =
          treeClaimedAndWeightedValues.weightedValueInterest1st
        tempClaimInfo.treeWeightedValueInterest2nd =
          treeClaimedAndWeightedValues.weightedValueInterest2nd
        tempClaimInfo.analysisResults = true
        tempScenarioSnapshot.currentSnapshot.claims[treeIndex] = tempClaimInfo
        tempClaimInfo.maxAmountIndex = res.data.tree_max_and_min_indexes.max
        tempClaimInfo.minAmountIndex = res.data.tree_max_and_min_indexes.min
        tempScenarioSnapshot.undoRedo[
          tempScenarioSnapshot.undoRedoIndex
        ].snapshot.claims[treeIndex] = deepCloneObject(tempClaimInfo)

        setScenarioSnapshot(deepCloneObject(tempScenarioSnapshot))
        await new Promise<void>((resolve) => setTimeout(() => resolve(), 200))
        if (calculatingAll) {
          calculateAllTrees(treeIndex)
        }

        return [res.data.results[0], res.data.results[1]]
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  const calculateAllTrees = async (indexAfter?: number) => {
    const tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    const checkRes: [boolean, number] =
      checkingAllTrees > -2
        ? checkTreesForWarnings(tempScenarioSnapshot, indexAfter)
        : [false, 0]
    if (checkRes[0]) {
      setCheckingAllTrees(checkRes[1])
      return
    }

    let index = -1
    if (indexAfter !== undefined && checkingAllTrees < 0) {
      index = scenarioSnapshot.currentSnapshot.claims
        .slice(indexAfter + 1)
        .findIndex(
          (claim) =>
            claim.type === ClaimType.tree &&
            (!(claim as TreeClaim).analysisResults ||
              (claim as TreeClaim).totalClaimedAmountInterest1st === undefined),
        )

      if (index > -1) {
        index += indexAfter + 1
      }
    } else {
      index = scenarioSnapshot.currentSnapshot.claims.findIndex(
        (claim) =>
          claim.type === ClaimType.tree &&
          (!(claim as TreeClaim).analysisResults ||
            (claim as TreeClaim).totalClaimedAmountInterest1st === undefined),
      )
    }

    // -2 means that the user has chosen an action for all trees and now all trees can be calculated
    if (checkingAllTrees > -2) {
      setCheckingAllTrees(-2)
    }

    if (index === -1) {
      setCheckingAllTrees(index)
    }

    setCalculatingAllTrees(index)
  }

  const calculateRemainingTrees = async (
    tempCurrentSnapshot: ScenarioSnapshot,
    reversedCase?: boolean,
  ): Promise<[ScenarioSnapshot, boolean] | undefined | number> => {
    const indexes = []
    const newTreeClaims = []
    const givenTreeTablesInfo: TreeTableInfo[] = []

    for (let i = 0; i < tempCurrentSnapshot.claims.length; i++) {
      const claim = tempCurrentSnapshot.claims[i]
      if (
        claim.type === ClaimType.tree &&
        (!(claim as TreeClaim).analysisResults ||
          (claim as TreeClaim).maxAmountIndex === undefined)
      ) {
        const treeObjectForCalculation: TreeObjectForCalculation = {
          firstTrialDate: tempCurrentSnapshot.firstTrialDate,
          secondTrialDate: tempCurrentSnapshot.secondTrialDate,
          treeDetails: (claim as TreeClaim).treeDetails,
          snapshotid: scenarioIdentity.snapshotId,
          treeid: (tempCurrentSnapshot.claims[i] as TreeClaim).id,
        }

        const res = freemium.isFreemium
          ? await freemiumCalculateTree(treeObjectForCalculation, true)
          : await calculateTree(treeObjectForCalculation, true)
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'calculateTree'),
        )
        if ('errorCode' in res) {
          if (res['errorCode'] === 598) {
            return i
          }
          setTreeTables({ ...treeTables, loadingMessage: undefined })

          return undefined
        }

        if (!reversedCase) {
          const index = treeTables.treeTablesInfo.findIndex(
            (tempTableInfo) => tempTableInfo.treeId === claim.id,
          )
          let tempPreviousValueOfTree = undefined
          if (
            treeTables.treeTablesInfo[index].currentValueOfTree !== undefined
          ) {
            tempPreviousValueOfTree =
              treeTables.treeTablesInfo[index].currentValueOfTree
          }

          const valueOfTree = calculateValueOfTree(
            tempCurrentSnapshot,
            res.data.results,
          )

          givenTreeTablesInfo.push({
            treeId: (claim as TreeClaim).id,
            results: [res.data.results[0], res.data.results[1]],
            currentValueOfTree: { ...valueOfTree },
            previousValueOfTree: tempPreviousValueOfTree,
            showSelectionGraph: false,
            showTreeTable: true,
            treeInterestViewOption: InterestViewOption.interest1st,
          })
        }
        const treeClaimedAndWeightedValues = findTreeClaimedAndWeightedValues(
          res.data.results,
        )

        indexes.push(i)
        newTreeClaims.push({
          ...claim,
          numOfTreeScenarios: res.data.results[0].length,
          totalClaimedAmount: treeClaimedAndWeightedValues.totalClaimedAmount,
          totalCounterClaimedAmount:
            treeClaimedAndWeightedValues.totalCounterClaimedAmount,
          totalClaimedAmountInterest1st:
            treeClaimedAndWeightedValues.totalClaimedAmountInterest1st,
          totalCounterClaimedAmountInterest1st:
            treeClaimedAndWeightedValues.totalCounterClaimedAmountInterest1st,
          totalClaimedAmountInterest2nd:
            treeClaimedAndWeightedValues.totalClaimedAmountInterest2nd,
          totalCounterClaimedAmountInterest2nd:
            treeClaimedAndWeightedValues.totalCounterClaimedAmountInterest2nd,
          treeWeightedValue: treeClaimedAndWeightedValues.weightedValue,
          treeWeightedValueInterest1st:
            treeClaimedAndWeightedValues.weightedValueInterest1st,
          treeWeightedValueInterest2nd:
            treeClaimedAndWeightedValues.weightedValueInterest2nd,
          maxAmountIndex: res.data.tree_max_and_min_indexes.max,
          minAmountIndex: res.data.tree_max_and_min_indexes.min,
          analysisResults: true,
        })
      }
    }

    let tempSnapshot: ScenarioSnapshot = deepCloneObject(tempCurrentSnapshot)
    let hasChanges = false
    if (indexes.length > 0) {
      if (!reversedCase) {
        hasChanges = true
      }
      let tempTreeTableInfo = [...treeTables.treeTablesInfo]

      for (let i = 0; i < indexes.length; i++) {
        tempSnapshot.claims[indexes[i]] = {
          ...newTreeClaims[i],
        }
        if (!reversedCase) {
          const index = tempTreeTableInfo.findIndex(
            (res) => res.treeId === givenTreeTablesInfo[i].treeId,
          )

          tempTreeTableInfo[index] = {
            ...givenTreeTablesInfo[i],
          }
        }
      }
      if (!reversedCase) {
        setTreeTables({
          loadingMessage: undefined,
          treeTablesInfo: [...tempTreeTableInfo],
        })
      }
    }

    return [tempSnapshot, hasChanges]
  }

  const checkTreesForWarnings = (
    tempSnapshotState: DefaultSnapshotState,
    lastCheckedIndex?: number,
  ): [boolean, number] => {
    for (let i = 0; i < tempSnapshotState.currentSnapshot.claims.length; i++) {
      const claim = tempSnapshotState.currentSnapshot.claims[i]
      if (
        claim.type === ClaimType.tree &&
        (!(claim as TreeClaim).analysisResults ||
          (claim as TreeClaim).maxAmountIndex === undefined) &&
        (lastCheckedIndex === undefined || i > lastCheckedIndex)
      ) {
        const [, , orphanTrees] = findMainTreeAndOrphanTrees(
          (claim as TreeClaim).treeDetails,
        )
        if (orphanTrees.includes(['node123'])) {
          return [false, 0]
        }
        if (orphanTrees.length > 0) {
          setActiveTabs({ tab: ToolTabs.claims, claim: i })
          setTimeout(() => {
            setTreeWarningsIndex(i)
          }, 1)
          return [true, i]
        }

        const zeroProbEvents = findZeroProbabilityEventsInMainTree(
          (claim as TreeClaim).treeDetails,
          orphanTrees.flat(),
        )
        if (zeroProbEvents.length > 0) {
          setActiveTabs({ tab: ToolTabs.claims, claim: i })
          setTimeout(() => {
            setTreeWarningsIndex(i)
          }, 1)
          return [true, i]
        }
      }
    }

    return [false, 0]
  }

  const addCheckedIndex = (value: number) => {
    if (value === -1) {
      setTreeWarningsIndex(-1)
      setCheckingAllTrees(-1)
    } else {
      if (checkingAllTrees === -1) {
        handleSubmit(undefined, value)
      } else {
        calculateAllTrees(checkingAllTrees)
      }
    }
  }

  const changeSnapshotPreview = (value: 'old' | 'new') => {
    setLoadingScenario(true)
    setRecoveryMode({
      ...recoveryMode,
      showLowScreenMessage: false,
    })

    setTimeout(() => {
      const tempSnapshot = value === 'old' ? recoveryMode.old : recoveryMode.new
      const tempScenarioSnapshot: DefaultSnapshotState = {
        savedSnapshot: deepCloneObject(scenarioSnapshot.savedSnapshot),
        undoRedo: [
          {
            id: 'currencyDropdown',
            type: UndoRedoType.input,
            snapshot: deepCloneObject(tempSnapshot),
            time: new Date(),
          },
        ],
        undoRedoIndex: 0,
        currentSnapshot: deepCloneObject(tempSnapshot),
      }
      const tempIdentity = deepCloneObject(scenarioIdentity)
      tempIdentity.results =
        value === 'old'
          ? tempResultsForRecovery
          : recoveryMode.keepResults
          ? tempResultsForRecovery
          : undefined

      tempIdentity.snapshotStatus =
        tempResultsForRecovery && (value === 'old' || recoveryMode.keepResults)
          ? SnapshotStatus.Done
          : SnapshotStatus.None

      setScenarioSnapshot(tempScenarioSnapshot)
      setSavedSnapshot({ saved: false, keepResults: recoveryMode.keepResults })
      setScenarioIdentity(tempIdentity)

      if (!tempIdentity.results && activeTabs.tab === ToolTabs.results) {
        setActiveTabs({ tab: ToolTabs.proceedings, claim: 0 })
      }

      const tempTreeTablesInfo: TreeTableInfo[] = []
      tempSnapshot!.claims.forEach((claim) => {
        if (claim.type === ClaimType.tree) {
          tempTreeTablesInfo.push({
            results: undefined,
            treeId: (claim as TreeClaim).id,
            currentValueOfTree: undefined,
            previousValueOfTree: undefined,
            showSelectionGraph: false,
            showTreeTable: false,
            treeInterestViewOption: InterestViewOption.interest1st,
          })
        }
      })

      setTreeTables({
        loadingMessage: undefined,
        treeTablesInfo: [...tempTreeTablesInfo],
      })

      setRecoveryMode({
        ...recoveryMode,
        showLowScreenMessage: true,
      })
      setLoadingScenario(false)
    }, 300)
  }

  return (
    <>
      <div className="toolPageComponent" id="toolPageComponent">
        {videoPostName ? (
          <VideoInfoButtonPopUp
            id={`videoInfoButton-${videoPostName}`}
            backGroundColor="#6F6F6F" //tuesdayGray
            videoPostName={videoPostName}
            blur
            closePopUpFunction={() => setVideoPostName(undefined)}
            zIndex={2000}
            noConnection={!online.networkOn}
          />
        ) : null}
        {treeLimitReached ? (
          <FullScreenPopMessage
            id="treeCalculationLimit"
            zIndex={1900}
            backGroundColor="#6F6F6F" //tuesdayGray
            blur
            titleTextAttribute="title-75"
            warningTitle={`${getText(
              'title-75',
              user.settings,
            )} ${treeLimitReached}!`}
            warningTextContainer={
              <>
                <p
                  className="warningText"
                  id="popUpWarningText-treeCalculationLimit1"
                  data-textattribute="description-13a, description-13b"
                >
                  {getText('description-13a', user.settings)}
                  <br /> {formattedNumToString(treeLimit, user.settings)}{' '}
                  {getText('description-13b', user.settings)}
                </p>
                <p
                  className="warningText"
                  id="popUpWarningText-treeCalculationLimit2"
                  data-textattribute="description-14"
                >
                  {getText('description-14', user.settings)} {treeLimitReached}.
                </p>
              </>
            }
            warningSubTextContainer={
              <p
                className="warningSubText"
                id="popUpWarningSubText-treeCalculationLimit"
                data-textattribute="description-15a, description-15b"
              >
                {getText('description-15a', user.settings)}{' '}
                <a
                  href="mailto:support@eperoto.com"
                  className="link"
                  id={'popUp11'}
                >
                  {getText('description-15b', user.settings)}
                </a>
              </p>
            }
            cancelFunction={() => setTreeLimitReached(undefined)}
          />
        ) : null}

        {calculationLimitPopUp ? (
          <FullScreenPopMessage
            id="calculationLimit"
            zIndex={1900}
            backGroundColor="#6f6f6f" //tuesdayGray
            blur
            titleTextAttribute="title-76"
            warningTitle={getText('title-76', user.settings)}
            warningTextContainer={
              <>
                <p
                  className="warningText"
                  id="popUpWarningText-calculationLimit1"
                  data-textattribute="description-16a, description-16b"
                >
                  {getText('description-16a', user.settings)} <br />
                  {formattedNumToString(calcLimit, user.settings)}{' '}
                  {getText('description-16b', user.settings)}
                </p>
                <p
                  className="warningText"
                  id="popUpWarningText-calculationLimit2"
                  data-textattribute="description-17"
                >
                  {getText('description-17', user.settings)}
                </p>
              </>
            }
            warningSubTextContainer={
              <p
                className="warningSubText"
                id="popUpWarningSubText-calculationLimit"
                data-textattribute="description-15a, description-15b"
              >
                {getText('description-15a', user.settings)}{' '}
                <a
                  href="mailto:support@eperoto.com"
                  className="link"
                  id={'popUp11'}
                >
                  {getText('description-15b', user.settings)}
                </a>
              </p>
            }
            cancelFunction={() => setCalculationLimitPopUp(false)}
          />
        ) : null}

        {anotherUserEditing && (
          <FullScreenPopMessage
            id="anotherEditingScenarioPopUpToolPage"
            zIndex={2000}
            backGroundColor="#6f6f6f" //tuesdayGray
            blur
            titleTextAttribute="title-217"
            warningTitle={`${anotherUserEditing} ${getText(
              'title-217',
              user.settings,
            )}`}
            warningTextContainer={
              <>
                <p
                  className="warningText"
                  id="popUpWarningText-noInternet"
                  data-textattribute="description-157"
                >
                  {getText('description-157', user.settings)}
                </p>
                <Button
                  id="goToCaseScenariosButton"
                  buttonText={getText('button-147', user.settings)}
                  buttonTextAttribute="button-147"
                  buttonType="contained"
                  type="button"
                  className="warningButton"
                  onClick={() => navigate(`/mycases/${params.caseId}`)}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              </>
            }
            warningSubTextContainer={
              <p
                className="warningSubText"
                id="popUpWarningSubText-noInternet"
                data-textattribute="description-128a, description-128b"
              >
                {getText('description-128a', user.settings)}{' '}
                <a
                  href="mailto:support@eperoto.com"
                  className="link"
                  id={'popUp11'}
                  data-textattribute="description-12b"
                >
                  {getText('description-12b', user.settings)}
                </a>{' '}
                {getText('description-128b', user.settings)}
              </p>
            }
          />
        )}

        {calculationLimitMessage && online.networkOn ? (
          <LowScreenMessage
            backGroundColor="#FFDDDD" //laCandyfloss
            message={getText('message-17', user.settings)}
            messageTextAttribute="message-17"
            id="calculationLimit"
            image={dangerImg}
            hoverColor="hoverPink"
            onClick={() => {
              if (freemium.isFreemium) {
                setFreemium({
                  ...freemium,
                  showMessage: FreemiumMessageType.Analyze,
                })
              } else {
                setCalculationLimitPopUp(true)
              }
            }}
          />
        ) : null}

        {loadingScenario ? (
          <div className="homescreen">
            <Tabs
              type="ToolTabs"
              activeTab={activeTabs.tab}
              setActiveTab={setActiveTabs}
              inactiveTabs={true}
              errors={tabsErrors}
            />
            <ProceedingsPlaceholder />
          </div>
        ) : (
          <>
            <form
              onKeyDown={onKeyDown}
              onSubmit={handleSubmit}
              className="toolpageForm"
            >
              <div className="homescreen">
                <Tabs
                  type="ToolTabs"
                  activeTab={activeTabs.tab}
                  setActiveTab={setActiveTabs}
                  inactiveTabs={inactiveTabs}
                  errors={tabsErrors}
                  ownRole={
                    recoveryMode.recoveryPreview !== 'none'
                      ? AccessRole.VIEWER
                      : ownRole
                  }
                />
                {activeTabs.tab === ToolTabs.proceedings ? (
                  <Proceedings
                    handleChangeKeyValue={handleChangeKeyValue}
                    setVideoPostName={setVideoPostName}
                    errors={errors}
                    ownRole={
                      recoveryMode.recoveryPreview !== 'none'
                        ? AccessRole.VIEWER
                        : ownRole
                    }
                  />
                ) : activeTabs.tab === ToolTabs.claims ? (
                  <Claims
                    numOfErrors={
                      tabsErrors && typeof tabsErrors.Claims !== 'number'
                        ? (tabsErrors.Claims as Record<number, number>)
                        : undefined
                    }
                    errors={errors}
                    errorsIdTabs={errorsIdTabs}
                    removeClaim={removeClaim}
                    doValidation={doValidation}
                    claimExistance={
                      scenarioSnapshot.currentSnapshot.claims.length > 0
                    }
                    treeDirectCalculation={treeDirectCalculation}
                    changeTreeTablesInfo={changeTreeTablesInfo}
                    calculateAllTrees={() => calculateAllTrees()}
                    setVideoPostName={setVideoPostName}
                    ownRole={
                      recoveryMode.recoveryPreview !== 'none'
                        ? AccessRole.VIEWER
                        : ownRole
                    }
                    checkIfTreeHasErrors={checkIfTreeHasErrors}
                    tabsErrors={tabsErrors}
                    treeWarningsIndex={treeWarningsIndex}
                    addCheckedIndex={addCheckedIndex}
                  />
                ) : activeTabs.tab === ToolTabs.legalCosts ? (
                  <LegalCosts
                    handleChangeKeyValue={handleChangeKeyValue}
                    setVideoPostName={setVideoPostName}
                    errors={errors}
                    ownRole={
                      recoveryMode.recoveryPreview !== 'none'
                        ? AccessRole.VIEWER
                        : ownRole
                    }
                  />
                ) : activeTabs.tab === ToolTabs.advanced ? (
                  <Advanced
                    handleChangeKeyValue={handleChangeKeyValue}
                    setVideoPostName={setVideoPostName}
                    errors={errors}
                    ownRole={
                      recoveryMode.recoveryPreview !== 'none'
                        ? AccessRole.VIEWER
                        : ownRole
                    }
                  />
                ) : activeTabs.tab === ToolTabs.legalCostsDistribution ? (
                  <LegalCostsDistribution
                    calculateAllTrees={() => calculateAllTrees()}
                    setVideoPostName={setVideoPostName}
                    ownRole={
                      recoveryMode.recoveryPreview !== 'none'
                        ? AccessRole.VIEWER
                        : ownRole
                    }
                  />
                ) : null}
              </div>
            </form>
            <div className="homescreen results">
              {isLoadingResultsTab ? (
                <LoadingResults
                  scenarioIdentity={scenarioIdentity}
                  cancelBecauseOfUpdateMessage={cancelBecauseOfUpdateMessage}
                  setCancelBecauseOfUpdateMessage={
                    setCancelBecauseOfUpdateMessage
                  }
                  fakeQueue={fakeQueue}
                  setFakeQueue={setFakeQueue}
                />
              ) : isResultsTab ? (
                <form
                  onKeyDown={onKeyDown}
                  onSubmit={(e: any) => handleSubmit(e, undefined, true)}
                  className="resultsForm"
                  style={{
                    width: windowSize.width - 60,
                    marginLeft: -60,
                  }}
                >
                  <Results
                    setVideoPostName={setVideoPostName}
                    ownRole={
                      recoveryMode.recoveryPreview !== 'none'
                        ? AccessRole.VIEWER
                        : ownRole
                    }
                    openInOtherTab={openInOtherTab}
                  />
                </form>
              ) : isFailedTab ? (
                <FailedResults scenarioIdentity={scenarioIdentity} />
              ) : null}
            </div>
          </>
        )}
        <ReportChangesMessageComponent
          show={openInOtherTab}
          text={getText('message-109', user.settings)}
          textAtrribute={'message-109'}
          buttonText={getText('button-148', user.settings)}
          buttonTextAtrribute={'button-148'}
          onClick={() => setOpenInOtherTab(false)}
        />
      </div>
      {recoveryModePopUp && (
        <RecoveryScenarioPopUp
          changeSnapshotPreview={(value) => {
            setRecoveryMode({
              ...recoveryMode,
              recoveryPreview: value,
              showLowScreenMessage: true,
            })
            setTimeout(() => {
              setRecoveryModePopUp(false)
            }, 300)
          }}
        />
      )}
      {failedSocketConnectionPopUp && (
        <FullScreenPopMessage
          id="failedSocketConnection"
          zIndex={6001}
          backGroundColor="#6f6f6f" //tuesdayGray
          titleTextAttribute="title-234"
          warningTitle={getText('title-234', user.settings)}
          warningTextContainer={
            <>
              <p
                className="warningText"
                id="popUpWarningText-failedSocketConnection"
                data-textattribute="description-190"
              >
                {getText('description-190', user.settings)}
              </p>
              <Button
                id="reconnect-popUp08"
                buttonText={getText('button-153', user.settings)}
                buttonTextAttribute="button-153"
                buttonType="contained"
                type="button"
                className="warningButton"
                onClick={() => {
                  setFailedSocketConnectionPopUp(false)
                  setTimeout(() => {
                    setSocketUrl('wss://none')
                  }, 10)
                }}
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </>
          }
          warningSubTextContainer={<WarningSubTextHandlingErrors id="fatal" />}
          blur
        />
      )}
    </>
  )
}
