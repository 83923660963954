import React from 'react'
import { useRecoilValue } from 'recoil'

import breadCrumbImg from '../../../../../resources/images/184-breadCrumbImg.svg'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'

type Props = {
  templateTitle: string
  setOpenTutorial: (param: string | undefined) => void
}

export default function TutorialBreadCrumb(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div className="tutorialBreadCrumpbContainer">
        <div className="breadcrumbTitleLeft">
          <p
            className="breadcrumbTitleLeftText"
            onClick={() => props.setOpenTutorial(undefined)}
            id="breadcrumbTitleLeftText"
            data-textattribute="title-72"
          >
            {getText('title-72', user.settings)}
          </p>
          <p
            className="breadcrumbTitleLeftTextHidden"
            id="breadcrumbTitleLeftTextHidden"
            data-textattribute="title-72"
          >
            {getText('title-72', user.settings)}
          </p>
        </div>
        <img
          className="breadcrumbImage"
          src={breadCrumbImg}
          alt="breadcrumbImg"
        />

        <p className="breadcrumbTitleRight">{props.templateTitle}</p>
      </div>
    </>
  )
}
