import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import Button from '../../../../../Components/Buttons/Button/Button'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import SettingInfoRow from '../../components/SettingInfoRow'
import SettingPopUp from '../../components/SettingPopUp'
import ChangePassword from './ChangePassword'
import { logActivity } from '../../../../../services/commonFunctions'

const Password = () => {
  const user = useRecoilValue(userState)
  const [openPopUp, setOpenPopUp] = useState(false)
  const [loading, setLoading] = useState(false)

  const getDateDiff = () => {
    if (user.passwordDate) {
      const timePassed = DateTime.now()
        .diff(user.passwordDate, ['months', 'days'])
        .toObject()

      if (timePassed.months! < 1 && timePassed.days! < 1) {
        //data-textattribute='description-107'
        return getText('description-107', user.settings)
      }
      if (timePassed.months! < 1 && timePassed.days! >= 1) {
        if (timePassed.days!.toFixed(0) === '1') {
          //data-textattribute='description-108'
          return getText('description-108', user.settings)
        }
        if (timePassed.days!.toFixed(0) !== '1') {
          //data-textattribute='description-109'
          return `${timePassed.days!.toFixed(0)} ${getText(
            'description-109',
            user.settings,
          )}`
        }
      }
      if (timePassed.months! >= 1) {
        if (timePassed.months!.toFixed(0) === '1') {
          //data-textattribute='description-110'
          return getText('description-110', user.settings)
        }
        if (timePassed.months!.toFixed(0) !== '1') {
          //data-textattribute='description-111'
          return `${timePassed.months!.toFixed(0)} ${getText(
            'description-111',
            user.settings,
          )}`
        }
      }
      return ''
    }

    return ''
  }

  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id="passwordTitle"
        data-textattribute="title-174"
      >
        {getText('title-174', user.settings)}
      </h1>
      {user.passwordDate !== undefined && (
        <SettingInfoRow
          id="createdPasswordDateInfo"
          descriptionText={`${getText(
            'description-106',
            user.settings,
          )}${getDateDiff()}`}
          isFirst
          descriptionTextAttribute={'description-106'}
        />
      )}
      <div
        className={`tool-component-input-container marginLeft3 ${
          user.passwordDate !== undefined ? '' : 'first-container'
        }`}
      >
        <Button
          id="changePasswordButton"
          buttonText={getText('button-94', user.settings)}
          buttonTextAttribute={'button-94'}
          buttonType="outlined"
          className="createCaseButton"
          type="button"
          onClick={() => {
            setOpenPopUp(true)
            //Mixpanel 95
            logActivity(false, 'Pressed "CHANGE PASSWORD" in Account Details')
          }}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      </div>
      {openPopUp && (
        <SettingPopUp
          onClose={() => {
            if (!loading) {
              setOpenPopUp(false)
            }
          }}
        >
          <ChangePassword
            setLoading={setLoading}
            loading={loading}
            setOpenPopUp={setOpenPopUp}
          />
        </SettingPopUp>
      )}
    </>
  )
}

export default Password
