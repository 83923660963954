import React, { useEffect, useRef, useState } from 'react'
import '../input.css'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useRecoilState, useRecoilValue } from 'recoil'
import { DateTime } from 'luxon'
import { getText } from '../../../services/textFunctions'
import { TextField } from '@mui/material'
import { userState } from '../../../states/UserState'
import {
  getHelperText,
  getInputDateFormat,
} from '../../../services/dateFunctions'
import DisabledWindow from '../../disabledWindow/DisabledWindow'
import { viewerState } from '../../../states/ViewerState'
import { DateField } from '@mui/x-date-pickers'
import { recoveryModeState } from '../../../states/RecoveryModeState'
import { freemiumState } from '../../../states/FreemiumState'
import { FreemiumMessageType } from '../../../models/freemiumEnums'

type Props = {
  id: string
  onChange: (e: any) => void
  label: string
  name: string
  value?: string
  smallField?: boolean
  error?: boolean
  errorMessage?: string
  width?: string | number
  className?: string
  tabIndex?: number
  tagcolor?: string
  alwaysShrink?: boolean
  onFocus?: (e: any) => void
  errorOutside?: boolean
  labelTextAttribute: `label-${number}`
  errorTextAttribute: `error-${number}`
  disabledMessageTextAttribute?: `message-${number}`
  disabled?: boolean
  disabledMessage?: string
}

export default function InputDate(props: Props) {
  const user = useRecoilValue(userState)
  const [isViewer, setIsViewer] = useRecoilState(viewerState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)

  const minDate = '1950-01-01'
  const maxDate = '2099-12-31'

  const [errorDate, setErrorDate] = useState(false)
  const [disabledMessageCoordinates, setDisabledMessageCoordinates] = useState<
    [number, number] | undefined
  >(undefined)
  const [localValue, setLocalValue] = useState<Date | null>(
    props.value ? new Date(props.value) : null,
  )
  const [changeValue, setChangeValue] = useState<boolean>(false)
  const timer = useRef<NodeJS.Timeout | undefined>(undefined)

  const convertToDate = (value: string) => {
    return new Date(
      DateTime.fromISO(value).toFormat(getInputDateFormat(user.settings)),
    )
  }

  const errorMessage =
    props.error &&
    props.value &&
    (convertToDate(props.value) < new Date(minDate) ||
      convertToDate(props.value) > new Date(maxDate))
      ? //id='invalidDateErrorMessage'
        props.smallField
        ? //data-textattribute='error-4'
          getText('error-4', user.settings)
        : //data-textattribute='error-5'
          getText('error-5', user.settings)
      : //data-textattribute={props.errorTextAttribute}
        props.errorMessage
  const errorTextAttribute =
    props.error &&
    props.value &&
    (convertToDate(props.value) < new Date(minDate) ||
      convertToDate(props.value) > new Date(maxDate))
      ? props.smallField
        ? 'error-4'
        : 'error-5'
      : props.errorTextAttribute

  const disabledMessage =
    //data-textattribute = 'message-67'
    props.disabledMessage ?? getText('message-67', user.settings)

  const handleChange = (value: Date | null, context: any) => {
    let newDate: Date | null = null

    if (
      value &&
      (!context.validationError || context.validationError !== 'invalidDate')
    ) {
      newDate = new Date(
        DateTime.fromISO(value.toISOString()).toFormat('yyyy-MM-dd'),
      )
      if (props.value === undefined && newDate < new Date(minDate)) {
        newDate = null
      }
    }

    setLocalValue(newDate)

    if (timer.current) {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(() => {
      setChangeValue(true)
    }, 200)

    if (newDate === null) {
      setErrorDate(true)
    } else {
      setErrorDate(false)
    }
  }

  useEffect(() => {
    //Sets the tabIndex of datePicker to a negative value
    if (
      document
        .getElementById(`container-${props.id}`)!
        .getElementsByTagName('button')[0]
    ) {
      document
        .getElementById(`container-${props.id}`)!
        .getElementsByTagName('button')[0].tabIndex = -1
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.value) {
      setErrorDate(false)
      setLocalValue(new Date(props.value))
    } else {
      setLocalValue(null)
    }

    // eslint-disable-next-line
  }, [props.value])

  useEffect(() => {
    if (changeValue) {
      if (
        (localValue !== null &&
          props.value !== undefined &&
          DateTime.fromJSDate(localValue).toFormat("yyyy'-'MM'-'dd'") ===
            props.value) ||
        (localValue === null && props.value === undefined)
      ) {
        setChangeValue(false)
        return
      }

      props.onChange({
        target: { value: localValue, id: props.id, name: props.name },
      })
    }

    setChangeValue(false)
    // eslint-disable-next-line
  }, [changeValue])

  const onBlur = () => {
    if (
      (localValue !== null &&
        props.value !== undefined &&
        DateTime.fromJSDate(localValue).toFormat("yyyy'-'MM'-'dd'") ===
          props.value) ||
      (localValue === null && props.value === undefined)
    ) {
      return
    }

    props.onChange({
      target: { value: localValue, id: props.id, name: props.name },
    })
  }

  const onClickDisabled = () => {
    if (props.disabled) {
      const element = document.getElementById(`container-${props.id}`)
      const viewportOffset = element!.getBoundingClientRect()

      let top = viewportOffset.top - 15
      const left = viewportOffset.left + 50
      const h = window.innerHeight
      if (h - top < 200) {
        top -= 130
      }

      if (freemium.isFreemium) {
        setFreemium({ ...freemium, showMessage: FreemiumMessageType.General })
      } else if (recoveryMode.recoveryPreview !== 'none') {
        setRecoveryMode({ ...recoveryMode, shaking: true })
      } else {
        setIsViewer({ ...isViewer, shaking: true })
        setDisabledMessageCoordinates([top, left])
      }
    }
  }

  return (
    <>
      {props.smallField ? (
        <>
          <div
            className="input inputDate smallField"
            id={`container-${props.id}`}
            onClick={onClickDisabled}
            data-errortextattribute={
              props.errorOutside
                ? ' '
                : props.error
                ? errorTextAttribute
                : errorDate
                ? 'error-4'
                : ''
            }
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateField
                label={props.label}
                format={getInputDateFormat(user.settings)}
                value={localValue}
                onChange={handleChange}
                minDate={new Date(minDate)}
                maxDate={new Date(maxDate)}
                disabled={props.disabled}
                slots={TextField as any}
                slotProps={{
                  textField: {
                    sx: {
                      width: props.width,
                      height: 40,
                    },
                    error: props.error || errorDate,
                    helperText: props.errorOutside
                      ? ' '
                      : props.error
                      ? //data-errortextattribute={errorTextAttribute}
                        errorMessage
                      : errorDate
                      ? //data-errortextattribute='error-4'
                        getText('error-4', user.settings)
                      : //data-errortextattribute={props.helperTextAttribute}
                        getHelperText(user.settings),
                    InputLabelProps: { shrink: props.alwaysShrink },
                    InputProps: {
                      size: 'small',
                      sx: {
                        '& input': {
                          fontSize: '14px',
                          paddingTop: '11.5px',
                          fontFamily:
                            props.className &&
                            props.className.includes('robotoNumbers')
                              ? 'roboto-local'
                              : undefined,
                        },
                        '& label': props.disabled
                          ? {
                              color: 'rgba(0, 0, 0, 0.38)',
                              cursor: 'not-allowed',
                            }
                          : {},
                        '& input::placeholder': {
                          fontSize: '13px',
                        },
                        '& .MuiOutlinedInput-root': {
                          height: '40px',
                        },
                        '& MuiFormHelperText-root': {
                          marginTop: '20px',
                        },
                      },
                    },
                    className: props.className,
                    onBlur: onBlur,
                    onFocus: (e) => {
                      if (props.onFocus) {
                        props.onFocus(e)
                      }
                    },
                  },
                }}
              />
            </LocalizationProvider>
            {props.disabled && (
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  width: '100%',
                  height: '100%',
                }}
                onClick={onClickDisabled}
              ></div>
            )}
          </div>
          {props.error && (
            <p className="errorDateMessageText">{errorMessage}</p>
          )}
        </>
      ) : (
        <div
          className="input inputDate"
          id={`container-${props.id}`}
          onClick={onClickDisabled}
          data-errortextattribute={
            props.errorOutside
              ? ' '
              : props.error
              ? errorTextAttribute
              : errorDate
              ? 'error-5'
              : ''
          }
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label={props.label}
              format={getInputDateFormat(user.settings)}
              value={localValue}
              onChange={handleChange}
              minDate={new Date(minDate)}
              maxDate={new Date(maxDate)}
              disabled={props.disabled}
              slots={TextField as any}
              slotProps={{
                textField: {
                  sx: {
                    width: props.width,
                    height: 50,
                    '& input': {
                      fontFamily:
                        props.className &&
                        props.className.includes('robotoNumbers')
                          ? 'roboto-local'
                          : undefined,
                    },
                    '& label': props.disabled
                      ? {
                          color: 'rgba(0, 0, 0, 0.38)',
                          cursor: 'not-allowed',
                        }
                      : {},
                  },
                  error: props.error || errorDate,
                  helperText: props.error
                    ? //data-errortextattribute={errorTextAttribute}
                      errorMessage
                    : errorDate
                    ? //data-errortextattribute='error-5'
                      getText('error-5', user.settings)
                    : //data-errortextattribute={props.helperTextAttribute}
                      getHelperText(user.settings),
                  InputLabelProps: { shrink: props.alwaysShrink },
                  tabIndex: props.tabIndex,
                  className: props.className,
                  onBlur: onBlur,
                  onFocus: (e) => {
                    if (props.onFocus) {
                      props.onFocus(e)
                    }
                  },
                },
              }}
            />
          </LocalizationProvider>
          {props.disabled && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
              }}
              onClick={onClickDisabled}
            ></div>
          )}
        </div>
      )}
      {disabledMessageCoordinates && (
        <DisabledWindow
          id={props.id}
          message={disabledMessage}
          disabledMessageTextAttribute={props.disabledMessageTextAttribute}
          cancelFunction={() => setDisabledMessageCoordinates(undefined)}
          position={disabledMessageCoordinates}
        />
      )}
    </>
  )
}
