import { useEffect, useState } from 'react'
import { UserForAccess } from '../../models/generalTypes'
import exitImg from '../../resources/images/187-newExitImg.svg'
import AccessComponent from './AccessComponent'
import InfoVideo from '../checklistComponent/InfoVideo'
import { logActivity } from '../../services/commonFunctions'

type Props = {
  id: string
  backGroundColor: string
  blur?: boolean
  warningTitle?: string
  forCelebration?: boolean
  warningTextContainer?: any
  warningSubTextContainer?: any
  cancelFunction?: () => void
  accessComponent?: boolean
  caseId?: string
  caseName?: string
  users?: UserForAccess[]
  setUpdateUsersAccess?: (param: number, users: any) => void
  updateUsersAccess?: number
  zIndex: number
  noConnection?: boolean
  titleTextAttribute: `title-${number}` | undefined
  preTitleContainer?: any
  noCloseIcon?: boolean
  infoVideo?: string
  customWidth?: number
}

export default function FullScreenPopMessage(props: Props) {
  const [loading, setLoading] = useState(true)
  const [closing, setClosing] = useState(false)
  const [tempUsersData, setTempUsersData] = useState<any>(undefined)

  useEffect(() => {
    if (props.id === 'AWADetails') {
      //Mixpanel 166
      logActivity(false, 'Watched the AWA Statistical Model description')
    }
  }, [props.id])

  const onClose = () => {
    if (props.cancelFunction) {
      if (props.infoVideo) {
        setClosing(true)
        setTimeout(() => {
          props.cancelFunction!()
        }, 300)
      } else {
        props.cancelFunction()
      }
    }
  }

  function clickOnEmptySpace(e: any) {
    if (!e.target.id.includes('popUp')) {
      if (props.accessComponent) {
        if (
          document.getElementById('menu-select-dropDownAccessRole') ||
          e.target.id.includes('dropDownAccessRole')
        ) {
          return
        }
        if (!loading) {
          onClose()
        }
        if (tempUsersData) {
          props.setUpdateUsersAccess!(
            props.updateUsersAccess! + 1,
            tempUsersData,
          )
          setTempUsersData(undefined)
        }
      } else {
        onClose()
      }
    }
  }

  return (
    <>
      <div
        id="fullScreenPopMessageBackground"
        className="fullScreenPopMessageBackground"
        onMouseDown={clickOnEmptySpace}
        style={
          closing
            ? {
                backgroundColor: props.backGroundColor,
                opacity: 0.0,
                zIndex: 0,
              }
            : props.blur
            ? {
                backgroundColor: props.backGroundColor,
                opacity: 0.8,
                zIndex: props.zIndex,
              }
            : {
                backgroundColor: props.backGroundColor,
                opacity: 1,
                zIndex: props.zIndex,
              }
        }
      ></div>
      <div
        className={`fullScreenPopMessageContainer ${
          props.infoVideo ? 'forInfoVideo' : ''
        } ${closing ? 'close' : ''}`}
        onMouseDown={clickOnEmptySpace}
        style={{ zIndex: props.zIndex + 1, width: '100vw' }}
        id={`fullScreenPopMessageContainer-${props.id}`}
      >
        <div
          className={`popUpContainerGeneral ${
            props.infoVideo ? 'biggerWidth' : ''
          } ${closing ? 'close' : ''}`}
          style={
            props.customWidth
              ? {
                  width: props.customWidth,
                  minWidth: props.customWidth,
                  maxWidth: props.customWidth,
                }
              : undefined
          }
          id={`popUpContainer-${props.id}`}
        >
          {props.infoVideo ? (
            <InfoVideo
              infoVideo={props.infoVideo}
              cancelFunction={onClose}
              closing={closing}
            />
          ) : props.accessComponent ? (
            <AccessComponent
              caseId={props.caseId!}
              caseName={props.caseName!}
              users={props.users!}
              setUpdateUsersAccess={props.setUpdateUsersAccess}
              updateUsersAccess={props.updateUsersAccess}
              cancelFunction={onClose}
              loading={loading}
              setLoading={setLoading}
              setTempUsersData={setTempUsersData}
              tempUsersData={tempUsersData}
            />
          ) : (
            <div className="warningMessageContainerGeneral" id={'popUp3'}>
              {props.preTitleContainer && (
                <div className="preTitleContainer">
                  {props.preTitleContainer}
                </div>
              )}
              <h2
                className={`warningTitle ${
                  props.preTitleContainer ? 'withPreTitle' : ''
                } ${props.forCelebration ? 'celebration' : ''}`}
                id={`popUp4-warningTitle-${props.id}`}
                data-textattribute={props.titleTextAttribute}
              >
                {props.warningTitle}
              </h2>
              <div
                className={`warningTextContainer ${
                  props.forCelebration ? 'celebration' : ''
                }`}
                id={'popUp5'}
              >
                {props.warningTextContainer}
              </div>
              {props.warningSubTextContainer ? (
                <div
                  className={`warningSubTextContainer ${
                    props.preTitleContainer ? 'withPreTitle' : ''
                  }`}
                  id={'popUp6'}
                >
                  {props.warningSubTextContainer}
                </div>
              ) : null}
            </div>
          )}
          {props.cancelFunction && !props.noCloseIcon ? (
            <div
              className="exitButtonContainer"
              onClick={onClose}
              id={'popUp1'}
              style={closing ? { opacity: 0.0 } : {}}
            >
              {props.noConnection ? (
                <p className="exitX">X</p>
              ) : (
                <img
                  src={exitImg}
                  alt="exitImg"
                  className="exitButtonImg"
                  id={'popUp2'}
                />
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  )
}
