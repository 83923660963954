import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'

import SideMenuLogOut from './sideMenuComponents/SideMenuLogOut'
import SideMenuTitle from './sideMenuComponents/SideMenuTitle'

import caseSuitcase from '../../resources/images/091-myCasesBriefcase.svg'
import caseSuitcaseHover from '../../resources/images/172-hover_suitcase.svg'
import caseSuitcaseSoft from '../../resources/images/169-suitcaseSoft.svg'
import owl from '../../resources/images/171-owl.svg'
import owlSoft from '../../resources/images/170-owlSoft.svg'
import owlHover from '../../resources/images/173-hover_owl.svg'
import wheel from '../../resources/images/188-settingsWheelSelected.svg'
import wheelSoft from '../../resources/images/189-settingsWheelSoft.svg'
import wheelHover from '../../resources/images/189-settingsWheelHover.svg'
import cornerFiller from '../../resources/images/101-whitecorner-filler.svg'
import SavePopUp from '../savePopUp/SavePopUp'
import { savedState } from '../../states/SavedState'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { SaveMenuEnum, SideMenuPages } from '../../models/enums'
import { deleteRecoverySnapshot, logout } from '../../services/requests'
import { User } from '../../models/user'

import { manosVersion } from '../../ManosVersion'
import { tikasVersion } from '../../TikasVersion'

import {
  findHandlingErrorState,
  isDevEnv,
  isStagingEnv,
  isTestEnv,
  logActivity,
} from '../../services/commonFunctions'
import ChecklistComponent from '../../Components/checklistComponent/ChecklistComponent'
import { debuggingState } from '../../states/DebuggingState'
import { pageDepthState } from '../../states/PageDepthState'
import { savedReportState } from '../../states/SavedReportState'
import { userState } from '../../states/UserState'
import { handlingErrorsState } from '../../states/HandlingErrorsState'
import { onlineState } from '../../states/OnlineState'
import { roundTo2Decimals } from '../../Modules/DisputeModules/AppFunctions'
import { Params } from '../../models/generalTypes'
import { freemiumState } from '../../states/FreemiumState'

type Props = {
  saveMenuOn?: undefined | string
  setSaveMenuOn?: (param: undefined | string) => void
  handleSave?: (param: boolean) => Promise<'saved' | 'not saved'>
  handleSaveReport?: () => Promise<'report saved' | 'report not saved'>
  saving?: string
  setSavedMessage?: (param: string) => void
  firstTimeLogin?: boolean
  setFirstTimeLogin: (value: boolean) => void
}

export default function SideMenu(props: Props) {
  const params = useParams<Params>()

  const savedSnapshot = useRecoilValue(savedState)
  const debugging = useRecoilValue(debuggingState)
  const [pageDepth, setPageDepth] = useRecoilState(pageDepthState)
  const savedReport = useRecoilValue(savedReportState)
  const setUser = useSetRecoilState(userState)

  const [showHide, setShowHide] = useState(false)
  const [pageBack, setPageBack] = useState(false) // a boolean that is used to define if the back button is pressed -- Not working
  const [pageUpload, setPageUpload] = useState(false) // a boolean that is used to define if the reload button is pressed -- Not working
  const [timeoutDelay, setTimeoutDelay] = useState<any>(undefined)
  const navigate = useNavigate()
  const location = useLocation()
  const [activeSideMenuTitle, setActiveSideMenuTitle] = useState(
    SideMenuPages.MyCases,
  )
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const [loadingRequest, setLoadingRequest] = useState(false)
  const [online, setOnline] = useRecoilState(onlineState)
  const freemium = useRecoilValue(freemiumState)

  useEffect(() => {
    if (location.pathname.includes('academy')) {
      setActiveSideMenuTitle(SideMenuPages.Academy)
    } else if (location.pathname.includes('settings')) {
      setActiveSideMenuTitle(SideMenuPages.Settings)
    } else {
      setActiveSideMenuTitle(SideMenuPages.MyCases)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  function showSideMenu(e: any) {
    if (
      e.target.id !== '_createErrorButton' &&
      e.target.id !== '_expectedResultsButton'
    ) {
      setTimeoutDelay(
        setTimeout(() => {
          setShowHide(true)
        }, 300),
      )
    }
  }
  function hideSideMenu(e: any) {
    if (
      e.target.id !== '_createErrorButton' &&
      e.target.id !== '_expectedResultsButton'
    ) {
      clearTimeout(timeoutDelay)
      setShowHide(false)
    }
  }

  async function saveAndContinue() {
    if (online.networkOn) {
      if (pageUpload) {
        setPageUpload(false)
        window.location.reload()
      } else if (pageBack) {
        setPageBack(false)
        window.history.back()
      } else {
        const res =
          pageDepth === 3
            ? await props.handleSave!(true)
            : await props.handleSaveReport!()

        const resResponse = pageDepth === 3 ? 'saved' : 'report saved'

        if (res === resResponse) {
          if (pageDepth === 3) {
            await deleteRecoverySnapshot(params.caseId!, params.scenarioId!)
          }

          setTimeout(() => {
            if (props.saveMenuOn === SaveMenuEnum.mycasesSide) {
              navigate('/')
            } else if (props.saveMenuOn === SaveMenuEnum.academy) {
              setPageDepth(0.9)
              navigate('/academy')
            } else if (props.saveMenuOn === SaveMenuEnum.settings) {
              setPageDepth(-1)
              navigate('/settings')
            } else if (props.saveMenuOn === SaveMenuEnum.logOut) {
              //Mixpanel 84
              logActivity(false, 'Logged out')
              logout().then((res) => {
                setHandlingErrors(
                  findHandlingErrorState(res, handlingErrors, 'logout', true),
                )
                if (!('errorCode' in res)) {
                  setUser(User.EmptyUser())
                }
              })
            }
          }, 2000)
        } else {
          props.setSavedMessage!('error')
        }
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  function continueWithoutSaving() {
    if (online.networkOn) {
      if (pageDepth === 3) {
        deleteRecoverySnapshot(params.caseId!, params.scenarioId!)
      }

      if (props.saveMenuOn === SaveMenuEnum.mycasesSide) {
        navigate('/')
      } else if (props.saveMenuOn === SaveMenuEnum.academy) {
        setPageDepth(0.9)
        navigate('/academy')
      } else if (props.saveMenuOn === SaveMenuEnum.settings) {
        setPageDepth(-1)
        navigate('/settings')
      } else if (props.saveMenuOn === SaveMenuEnum.logOut) {
        setLoadingRequest(true)
        //Mixpanel 84
        logActivity(false, 'Logged out')
        logout().then((res) => {
          setHandlingErrors(
            findHandlingErrorState(res, handlingErrors, 'logout', true),
          )
          setLoadingRequest(false)

          if (!('errorCode' in res)) {
            setUser(User.EmptyUser())
          }
        })
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  return (
    <>
      {props.saveMenuOn || props.saving ? (
        <div className="transparentSideMenu"></div>
      ) : null}
      <div
        id="sideMenuContainer"
        className={
          showHide
            ? 'sideMenuContainer sideMenuContainerOpen'
            : 'sideMenuContainer'
        }
        onMouseEnter={props.saveMenuOn ? undefined : showSideMenu}
        onMouseLeave={props.saveMenuOn ? undefined : hideSideMenu}
      >
        <div className="upperSideMenu">
          <div
            className={
              activeSideMenuTitle === SideMenuPages.MyCases
                ? 'upperSideMenufiller'
                : 'upperSideMenufiller nocorner'
            }
          >
            <img
              src={cornerFiller}
              alt="cornerFiller"
              className="cornerFiller"
            />
          </div>
          <SideMenuTitle
            title={SideMenuPages.MyCases}
            position={'top'}
            bottomActive={activeSideMenuTitle === SideMenuPages.Settings}
            thumbnailActive={caseSuitcase}
            thumbnailHover={caseSuitcaseHover}
            thumbnailInactive={caseSuitcaseSoft}
            isSideMenuTitleActive={
              activeSideMenuTitle === SideMenuPages.MyCases
            }
            saveMenuOn={props.saveMenuOn}
            saved={savedSnapshot.saved}
            reportSaved={savedReport.saved}
            setSaveMenuOn={props.setSaveMenuOn}
            showHide={showHide}
          />
          <SideMenuTitle
            title={SideMenuPages.Academy}
            position={'middle'}
            bottomActive={activeSideMenuTitle === SideMenuPages.Settings}
            thumbnailActive={owl}
            thumbnailHover={owlHover}
            thumbnailInactive={owlSoft}
            isSideMenuTitleActive={
              activeSideMenuTitle === SideMenuPages.Academy
            }
            saveMenuOn={props.saveMenuOn}
            saved={savedSnapshot.saved}
            reportSaved={savedReport.saved}
            setSaveMenuOn={props.setSaveMenuOn}
            showHide={showHide}
          />
          <SideMenuTitle
            title={SideMenuPages.Settings}
            position={'bottom'}
            topActive={activeSideMenuTitle === SideMenuPages.MyCases}
            thumbnailActive={wheel}
            thumbnailHover={wheelHover}
            thumbnailInactive={wheelSoft}
            isSideMenuTitleActive={
              activeSideMenuTitle === SideMenuPages.Settings
            }
            saveMenuOn={props.saveMenuOn}
            saved={savedSnapshot.saved}
            reportSaved={savedReport.saved}
            setSaveMenuOn={props.setSaveMenuOn}
            showHide={showHide}
          />
        </div>

        <div className="downSideMenu">
          <div
            className={
              activeSideMenuTitle === SideMenuPages.Settings
                ? 'downSideMenufiller'
                : 'downSideMenufiller nocorner'
            }
          ></div>
          {(isDevEnv() || isStagingEnv() || isTestEnv()) && debugging ? (
            <>
              <div className="appVersion">
                <p className="appVersionText">T:{tikasVersion}</p>
                <p className="appVersionText">M:{manosVersion}</p>
                <p className="appVersionText" style={{ fontSize: 10 }}>
                  {roundTo2Decimals(manosVersion + tikasVersion)}
                </p>
              </div>
            </>
          ) : null}
          {!freemium.isFreemium && (
            <SideMenuLogOut
              showHide={showHide}
              saveMenuOn={props.saveMenuOn}
              setSaveMenuOn={props.setSaveMenuOn}
              setShowHide={setShowHide}
            />
          )}
        </div>
        {(props.saveMenuOn === SaveMenuEnum.mycasesSide ||
          props.saveMenuOn === SaveMenuEnum.academy ||
          props.saveMenuOn === SaveMenuEnum.settings ||
          props.saveMenuOn === SaveMenuEnum.logOut) &&
        !freemium.isFreemium ? (
          <SavePopUp
            id={`savePopUp${props.saveMenuOn} - Side`}
            style={
              props.saveMenuOn === SaveMenuEnum.mycasesSide ||
              props.saveMenuOn === SaveMenuEnum.settings ||
              props.saveMenuOn === SaveMenuEnum.academy
                ? { left: '177px', top: '60px' }
                : props.saveMenuOn === SaveMenuEnum.logOut
                ? { left: '174px', bottom: '110px', top: '' }
                : { left: '280px', top: '60px' }
            }
            typeOfMenu={props.saveMenuOn}
            setSaveMenuOn={props.setSaveMenuOn!}
            continueWithoutSaving={continueWithoutSaving}
            saveAndContinue={saveAndContinue}
            loadingRequest={loadingRequest}
            setShowHide={setShowHide}
          />
        ) : null}
      </div>
      <ChecklistComponent
        showHide={showHide}
        firstTimeLogin={props.firstTimeLogin}
        setFirstTimeLogin={props.setFirstTimeLogin}
      />
    </>
  )
}
