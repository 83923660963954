import { useEffect } from 'react'
import Xarrow from 'react-xarrows'
import { TreeEventClass } from '../../../../../../../models/treeModels/treeEvent'
import {
  EventId,
  NodeId,
  TreeLines,
} from '../../../../../../../models/treeModels/treeTypes'

type Props = {
  startId: string
  endId: string
  nodeId: NodeId
  eventId: EventId
  events: {
    [eventId: EventId]: TreeEventClass
  }
  zoomLevel: number
  hanldeSetLineHover: (nodeId: NodeId, eventId: EventId, state: boolean) => void
  handleSetLineClick: (nodeId: NodeId, eventId: EventId, state: boolean) => void
  lineClick: boolean
  treeIndex: number
  treeLines: TreeLines
}
export default function HoverLine(props: Props) {
  // const updateXarrow = useXarrow()
  useEffect(() => {
    document.addEventListener('mousedown', removeLineClick, false)
    return () => {
      document.removeEventListener('mousedown', removeLineClick, false)
    }
    // eslint-disable-next-line
  }, [props.lineClick])

  function removeLineClick(e: any) {
    if (
      !e.target.id.includes(
        `Line-${props.nodeId}-${
          props.events[props.eventId as EventId].nodeOfEventId
        }!${props.events[props.eventId as EventId].eventIndex}`,
      )
    ) {
      if (
        e.target.id.includes('connectionCircle') ||
        e.target.id.includes('treeEventAddNodeButton') ||
        e.target.id.includes('treeAddNodeDot')
      ) {
        if (
          e.target.id.includes('connectionCircle') &&
          props.nodeId === e.target.id.split('_')[1] &&
          e.target.id !== `connectionCircle-${props.treeIndex}_${props.nodeId}`
        ) {
          props.handleSetLineClick(props.nodeId, props.eventId, false)
        }
        if (
          e.target.id.includes('treeEventAddNodeButton') &&
          props.nodeId === e.target.id.split('_')[1].split('!')[0] &&
          e.target.id !==
            `treeAddNodeDot-${props.treeIndex}_${props.nodeId}!${
              props.events[props.eventId].eventIndex
            }`
        ) {
          props.handleSetLineClick(props.nodeId, props.eventId, false)
        }
      } else {
        props.handleSetLineClick(props.nodeId, props.eventId, false)
      }
    }
  }

  return (
    <>
      <Xarrow
        start={props.startId}
        startAnchor={'top'}
        end={props.endId}
        endAnchor={'bottom'}
        color={'transparent'}
        strokeWidth={40}
        showHead={false}
        showTail={false}
        passProps={{
          onMouseEnter: () => {
            // updateXarrow()
            props.hanldeSetLineHover(props.nodeId, props.eventId, true)
          },
          onClick: () => {
            props.handleSetLineClick(
              props.nodeId,
              props.eventId,
              !props.lineClick,
            )
          },
          onMouseLeave: (e: any) => {
            const divsOfTreeAppBackground = document.querySelectorAll(
              '.treeAppBackground > div:has(svg)',
            )

            for (let element of divsOfTreeAppBackground) {
              element.classList.add('lineElement')
            }

            const divsOfLine = document.querySelectorAll('.lineElement > div')

            for (let element of divsOfLine) {
              element.classList.add('deleteLineContainerBackground')
            }
            if (
              e.relatedTarget &&
              typeof e.relatedTarget.className === 'string'
            ) {
              if (!e.relatedTarget.className.includes('deleteLine')) {
                props.hanldeSetLineHover(props.nodeId, props.eventId, false)
              }
            }
          },
          id: `hoverLine-${props.nodeId}-${
            props.events[props.eventId as EventId].nodeOfEventId
          }!${props.events[props.eventId as EventId].eventIndex}`,
          cursor: 'pointer',
        }}
        path={props.treeLines}
        curveness={0.3}
        /* SVGcanvasStyle={{
          transform: `scale(${1 / props.zoomLevel})`,
          zIndex: -1,
        }} */
      />
    </>
  )
}
