import {
  FreemiumPreferences,
  StorageCase,
} from '../models/freemiumGeneralTypes'
import { UserChecklist } from '../models/userChecklist'
import { UserSettings } from '../models/userSettings'
import Cookies from 'universal-cookie'

export const saveUseridToCookies = (userid: string) => {
  const cookies = new Cookies()
  cookies.set('userid', userid, {
    path: '/',
    secure: true,
    sameSite: 'strict',
  })
}

export const getUseridFromCookies = () => {
  const cookies = new Cookies()
  return cookies.get('userid')
}

export const saveUseridToStorage = (userid: string) => {
  localStorage.setItem('userid', userid)
}

export const getUseridFromStorage = () => {
  return localStorage.getItem('userid')
}

export const saveFreemiumCase = (value: StorageCase) => {
  localStorage.setItem('case', JSON.stringify(value))
}

export const getFreemiumCase = () => {
  return localStorage.getItem('case')
}

export const deleteFreemiumCase = () => {
  localStorage.removeItem('case')
}

export const saveFreemiumPreferences = (value: FreemiumPreferences) => {
  localStorage.setItem(
    'preferences',
    JSON.stringify({
      settings: UserSettings.ToJson(value.settings),
      checklist: UserChecklist.ToJson(value.checklist),
    }),
  )
}

export const getFreemiumPreferences = () => {
  return localStorage.getItem('preferences')
}
