export const FREEMIUM_CASE_ID = 'my_first_case'
export const FREEMIUM_SCENARIO_ID = 'my_first_scenario'
export const FREEMIUM_SNAPSHOT_ID = 'my_first_snapshot'
export const FREEMIUM_APP_CALCULATION_LIMIT = 1000

const FREEMIUM_BASE_URL = 'freemium/freemium_base/'
const FREEMIUM_LOGIN_URL = 'freemium/login/'
const FREEMIUM_CASES_URL = 'freemium/groups/'
const FREEMIUM_SCENARIOS_URL = 'freemium/groups/group_id/scenarios/'
const FREEMIUM_SINGLE_SCENARIO_URL =
  'freemium/groups/group_id/scenarios/scenario_id/'
const FREEMIUM_REPORT_DETAIL_URL =
  'freemium/groups/group_id/scenarios/scenario_id/reportdata/'
const FREEMIUM_CALCULATE_SNAPSHOT_URL =
  'freemium/groups/group_id/scenarios/scenario_id/calculate_snapshot/'
const FREEMIUM_GET_JOB_URL = 'freemium/job/'
const FREEMIUM_CANCEL_JOB_URL = 'freemium/job/cancel/'
const FREEMIUM_CALCULATE_TREE_URL = 'freemium/calculate_tree/'
const FREEMIUM_WORDPRESS_URL = 'freemium/wordpress/post_type/slug/'
const FREEMIUM_GET_TUTORIAL_URL = 'freemium/get_tutorials/tutorial_id/'
const FREEMIUM_UPDATE_SNAPSHOT_URL = 'freemium/update_snapshot/'
const FREEMIUM_LOG_URL = 'freemium/log/'
const FREEMIUM_GET_QUEUE_URL = 'freemium/get_queue/'

export type FreemiumUrlOption =
  | 'FREEMIUM_BASE_URL'
  | 'FREEMIUM_LOGIN_URL'
  | 'FREEMIUM_CASES_URL'
  | 'FREEMIUM_SCENARIOS_URL'
  | 'FREEMIUM_SINGLE_SCENARIO_URL'
  | 'FREEMIUM_CALCULATE_SNAPSHOT_URL'
  | 'FREEMIUM_GET_JOB_URL'
  | 'FREEMIUM_CALCULATE_TREE_URL'
  | 'FREEMIUM_WORDPRESS_URL'
  | 'FREEMIUM_GET_TUTORIAL_URL'
  | 'FREEMIUM_REPORT_DETAIL_URL'
  | 'FREEMIUM_CANCEL_JOB_URL'
  | 'FREEMIUM_UPDATE_SNAPSHOT_URL'
  | 'FREEMIUM_LOG_URL'
  | 'FREEMIUM_GET_QUEUE_URL'

export const getFreemiumUrl = (option: FreemiumUrlOption, ids?: string[]) => {
  let resUrl = ''

  switch (option) {
    case 'FREEMIUM_BASE_URL':
      resUrl += FREEMIUM_BASE_URL
      break
    case 'FREEMIUM_LOGIN_URL':
      resUrl += FREEMIUM_LOGIN_URL
      break
    case 'FREEMIUM_CASES_URL':
      resUrl += FREEMIUM_CASES_URL
      break
    case 'FREEMIUM_SCENARIOS_URL':
      resUrl += FREEMIUM_SCENARIOS_URL.replace('group_id', ids![0])
      break
    case 'FREEMIUM_SINGLE_SCENARIO_URL':
      resUrl += FREEMIUM_SINGLE_SCENARIO_URL.replace(
        'group_id',
        ids![0],
      ).replace('scenario_id', ids![1])
      break
    case 'FREEMIUM_REPORT_DETAIL_URL':
      resUrl += FREEMIUM_REPORT_DETAIL_URL.replace('group_id', ids![0]).replace(
        'scenario_id',
        ids![1],
      )
      break
    case 'FREEMIUM_CALCULATE_SNAPSHOT_URL':
      resUrl += FREEMIUM_CALCULATE_SNAPSHOT_URL.replace(
        'group_id',
        ids![0],
      ).replace('scenario_id', ids![1])
      break
    case 'FREEMIUM_GET_JOB_URL':
      resUrl += FREEMIUM_GET_JOB_URL
      break
    case 'FREEMIUM_CANCEL_JOB_URL':
      resUrl += FREEMIUM_CANCEL_JOB_URL
      break
    case 'FREEMIUM_CALCULATE_TREE_URL':
      resUrl += FREEMIUM_CALCULATE_TREE_URL
      break
    case 'FREEMIUM_WORDPRESS_URL':
      resUrl += FREEMIUM_WORDPRESS_URL.replace('post_type', ids![0]).replace(
        'slug',
        ids![1],
      )
      break
    case 'FREEMIUM_GET_TUTORIAL_URL':
      resUrl += FREEMIUM_GET_TUTORIAL_URL.replace('tutorial_id', ids![0])
      break
    case 'FREEMIUM_UPDATE_SNAPSHOT_URL':
      resUrl += FREEMIUM_UPDATE_SNAPSHOT_URL
      break
    case 'FREEMIUM_LOG_URL':
      resUrl += FREEMIUM_LOG_URL
      break
    case 'FREEMIUM_GET_QUEUE_URL':
      resUrl += FREEMIUM_GET_QUEUE_URL
      break
  }

  return process.env.REACT_APP_API_BASIC_URL + resUrl
}
