import { useState, useRef, useEffect } from 'react'
import Button from '../../../../../../Components/Buttons/Button/Button'
import addClaimButtonImg from '../../../../../../resources/images/103-addButton.svg'
import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import {
  ClaimType,
  InterestViewOption,
  UndoRedoType,
  VideoPostName,
} from '../../../../../../models/enums'
import { SnapshotSelectorObject } from '../../../../../../models/generalTypes'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../services/commonFunctions'

import { IndependentClaim } from '../../../../../../models/independentClaim'
import { changeGlobalSnapshot } from '../../../../../../services/changeGlobalSnapshot'
import HoverComponent from '../../../../../../Components/InfoHover/HoverComponent/HoverComponent'
import { treeTablesState } from '../../../../../../states/TreeTablesState'
import { getText } from '../../../../../../services/textFunctions'
import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { TreeClaim } from '../../../../../../models/treeModels/treeClaim'
import { v4 as uuid } from 'uuid'
import { userState } from '../../../../../../states/UserState'
import { findNextClaimTypeId } from '../../../../../../services/claimFunctions'
import PasteClaimButton from '../PasteClaimButton'
import { freemiumState } from '../../../../../../states/FreemiumState'
import Symbol from '../../../../../../Components/symbol/Symbol'

type Props = {
  setActiveClaim: (claimIndex: number) => void
  setVideoPostName: (param: VideoPostName | undefined) => void
  addClaimMenu1On: boolean
  addClaimMenu2On: boolean
  addClaimMenu3On: boolean
  setAddClaimMenu1On: (value: boolean) => void
  setAddClaimMenu2On: (value: boolean) => void
  setAddClaimMenu3On: (value: boolean) => void
  nothingToPaste: boolean
  setNothingToPaste: (value: boolean) => void
  handlePasteClaim: () => void
}

export default function AddClaims(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const currentSnapshot = scenarioSnapshot.currentSnapshot

  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)

  const onMouseDown = (e: any) => {
    if (
      !e.target.id.includes('addClaim') &&
      !e.target.id.includes('addLegalTree') &&
      !e.target.id.includes(VideoPostName.claimsLegalTree) &&
      !e.target.id.includes(VideoPostName.claimsSingleClaim)
    ) {
      props.setAddClaimMenu1On(false)
    }

    if (
      !e.target.id.includes('addClientClaimButton') &&
      !e.target.id.includes('addCounterClientClaim')
    ) {
      props.setAddClaimMenu2On(false)
    }

    setHoverElement('')

    // eslint-disable-next-line
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  function handleAddLegalTree() {
    props.setAddClaimMenu3On(false)

    let nextTree =
      currentSnapshot.claims.filter((c) => c.type === ClaimType.tree).length + 1

    let treeName = `Tree ${nextTree}`

    while (
      currentSnapshot.claims.filter(
        // eslint-disable-next-line
        (c) =>
          c.type === ClaimType.tree &&
          c.name.toLowerCase() === treeName.toLowerCase(),
      ).length > 0
    ) {
      nextTree++
      treeName = `Tree ${nextTree}`
    }

    const newTree = TreeClaim.initialTree(
      treeName,
      {
        firstTrialDate: currentSnapshot.firstTrialDate,
        secondTrialDate: currentSnapshot.secondTrialDate,
      },
      findNextClaimTypeId(
        currentSnapshot,
        ClaimType.tree,
      ) as `Decision Tree ${number}`,
      `node${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
      `event${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
      `event${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
    )

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'addClaimContainer',
      undoRedoType: UndoRedoType.button,
      value: newTree,
      key: 'claims',
      key2: 'add',
    }

    setTreeTables({
      ...treeTables,
      treeTablesInfo: [
        ...treeTables.treeTablesInfo,
        {
          treeId: newTree.id,
          results: undefined,
          currentValueOfTree: undefined,
          previousValueOfTree: undefined,
          showSelectionGraph: false,
          showTreeTable: false,
          treeInterestViewOption: InterestViewOption.interest1st,
        },
      ],
    })

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    setTimeout(() => {
      props.setActiveClaim(
        tempScenarioSnapshot.currentSnapshot.claims.length - 1,
      )
      props.setAddClaimMenu3On(true)
    }, 30)
    //Mixpanel 35 (All)
    logActivity(freemium.isFreemium, 'Added legal tree')
  }

  function handleAddClaim(typeOfClaim: ClaimType) {
    props.setAddClaimMenu3On(false)
    const newClaim = IndependentClaim.InitialClaim(
      `Claim ${currentSnapshot.claims.length + 1}`,
      typeOfClaim,
      findNextClaimTypeId(scenarioSnapshot.currentSnapshot, typeOfClaim) as
        | `Client Claim ${number}`
        | `Opposing Party Claim ${number}`,
    )
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: 'addClaimContainer',
      undoRedoType: UndoRedoType.button,
      value: newClaim,
      key: 'claims',
      key2: 'add',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)

    props.setAddClaimMenu2On(false)
    props.setAddClaimMenu1On(false)

    setTimeout(() => {
      props.setActiveClaim(
        tempScenarioSnapshot.currentSnapshot.claims.length - 1,
      )
      props.setAddClaimMenu3On(true)
    }, 30)

    //Mixpanel 33 (All)
    logActivity(freemium.isFreemium, 'Added independent claim')
  }

  return (
    <>
      <div className="addClaimContainer" id="addClaimContainer">
        {props.addClaimMenu1On ? (
          <div className="addClaimMenu1Container">
            <div className="addClaimButtonContainer">
              <Button
                buttonText={getText('button-23', user.settings)}
                buttonTextAttribute="button-23"
                buttonType="outlined"
                type="button"
                onClick={() => {
                  props.setAddClaimMenu1On(false)
                  props.setAddClaimMenu2On(true)
                }}
                className="addClaimButton"
                id="addClaimButton"
                side="left"
                NoUpperCase={true}
                small={false}
              />
              <VideoInfoButton
                textAttribute="tooltip-19"
                postName={VideoPostName.claimsSingleClaim}
                setVideoPostName={props.setVideoPostName}
              />
            </div>
            <div className="addClaimButtonContainer">
              <Button
                buttonText={getText('button-24', user.settings)}
                buttonTextAttribute="button-24"
                buttonType="outlined"
                type="button"
                onClick={() => {
                  handleAddLegalTree()
                  props.setAddClaimMenu1On(false)
                }}
                className="addLegalTreeButton"
                id="addLegalTreeButton"
                side="left"
                NoUpperCase={true}
                small={false}
              />
              <VideoInfoButton
                textAttribute="tooltip-19"
                postName={VideoPostName.claimsLegalTree}
                setVideoPostName={props.setVideoPostName}
              />
            </div>
          </div>
        ) : props.addClaimMenu2On ? (
          <div className="addClaimMenu2Container" data-openreplay-obscured>
            <Button
              buttonText={getText('button-25', user.settings, partiesFormat)}
              buttonTextAttribute="button-25"
              buttonType="outlined"
              type="button"
              onClick={() => {
                handleAddClaim(ClaimType.claim)
              }}
              className="addClientClaim"
              id="addClientClaimButton"
              data-openreplay-obscured
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Symbol
              symbolType={'client'}
              targetId={'addClientClaimButton'}
              targetType={'button'}
            />
            <Button
              buttonText={getText('button-26', user.settings, partiesFormat)}
              buttonTextAttribute="button-26"
              buttonType="outlined"
              type="button"
              onClick={() => {
                handleAddClaim(ClaimType.counterclaim)
              }}
              className="addCounterClientClaim"
              id="addCounterClientClaim"
              data-openreplay-obscured
              side="left"
              NoUpperCase={true}
              small={false}
            />
            <Symbol
              symbolType={'opposingParty'}
              targetId={'addCounterClientClaim'}
              targetType={'button'}
            />
          </div>
        ) : props.addClaimMenu3On ? (
          <>
            <div
              className="addClaimsMenuButtonContainer"
              id="addClaimsMenuButtonContainer"
              onClick={() => props.setAddClaimMenu1On(true)}
              onMouseEnter={() => {
                secondaryHover.current = 'addClaimsMenuButtonContainer'
                setTimeout(() => {
                  if (
                    secondaryHover.current === 'addClaimsMenuButtonContainer'
                  ) {
                    setHoverElement('addClaimsMenuButtonContainer')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            >
              {hoverElement === 'addClaimsMenuButtonContainer' ? (
                <div
                  className="hoverComponentContainer"
                  style={{ top: '10px', left: '80px' }}
                >
                  <HoverComponent
                    hoverText={getText('hover-6', user.settings)}
                    textAttribute="hover-6"
                    id="addClaimsMenuButtonContainer"
                  />
                </div>
              ) : null}
              <img
                src={addClaimButtonImg}
                alt="addClaimButtonImg"
                className="addClaimButtonImg"
                onClick={() => props.setAddClaimMenu1On(true)}
              />
            </div>
            <PasteClaimButton
              onClick={() => {
                props.handlePasteClaim()
                props.setAddClaimMenu1On(false)
                setHoverElement('')
              }}
              onMouseEnter={() => {
                secondaryHover.current = 'pasteFromClipboardContainer'
                setTimeout(() => {
                  if (
                    secondaryHover.current === 'pasteFromClipboardContainer'
                  ) {
                    setHoverElement('pasteFromClipboardContainer')
                  }
                }, 100)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
              nothingToPaste={props.nothingToPaste}
              isHovered={hoverElement === 'pasteFromClipboardContainer'}
            />
          </>
        ) : null}
      </div>
    </>
  )
}
