import { CircularProgress, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from '../../../../../Components/Buttons/Button/Button'

type Props = {
  id: string
  originalValue: boolean
  loading: boolean
  showSaved: boolean
  titleText: string
  buttonText: string
  confirmationText: string
  titleTextAttribute: `title-${number}`
  buttonTextAttribute: `button-${number}`
  confirmationTextAttribute: `button-${number}`
  tabIndex: number
  onSave: (id: string, value: boolean) => void
  isFirst?: boolean
}

const SettingSwitchRow = (props: Props) => {
  const [showSaveButton, setShowSaveButton] = useState(false)
  const [checked, setChecked] = useState(props.originalValue)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked === props.originalValue) {
      setShowSaveButton(false)
    } else {
      setShowSaveButton(true)
    }

    setChecked(event.target.checked)
  }

  useEffect(() => {
    if (!props.loading) {
      setShowSaveButton(false)
    }
  }, [props.loading])

  return (
    <div className="tool-component-input-container marginLeft3">
      <div className={`switchContainer ${props.isFirst ? 'first' : ''}`}>
        <Switch
          size="small"
          id={props.id}
          checked={checked}
          onChange={handleChange}
        />
        <div
          className={`switchText ${!checked ? 'disabled' : ''}`}
          data-textattribute={props.titleTextAttribute}
        >
          {props.titleText}
        </div>
        {props.showSaved && !showSaveButton && (
          <Button
            id={`saved${props.id}`}
            buttonText={props.confirmationText}
            buttonTextAttribute={props.confirmationTextAttribute}
            buttonType="nonactiveText"
            className="createCaseButton"
            type="button"
            onClick={() => {}}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        )}
        {showSaveButton && (
          <Button
            id={`save${props.id}`}
            buttonText={
              props.loading ? (
                <div className="circularProgressDiv" id={'popUp7'}>
                  <div id={'popUp8'}>
                    <CircularProgress size={14} />
                    <div
                      className="hiddenTextForSize"
                      style={{ height: 0, visibility: 'hidden' }}
                      id="hiddenCreateCaseButtonText"
                      data-textattribute={props.buttonTextAttribute}
                    >
                      {props.buttonText}
                    </div>
                  </div>
                </div>
              ) : (
                props.buttonText
              )
            }
            buttonTextAttribute={props.buttonTextAttribute}
            buttonType="contained"
            className="createCaseButton"
            type="button"
            onClick={() => props.onSave(props.id, checked)}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        )}
      </div>
    </div>
  )
}

export default SettingSwitchRow
