import { TreeDetails } from '../../models/treeModels/treeClaim'
import {
  DefaultHeightsOfNode,
  DeleteMenuType,
  DeleteNodeOrEventMenuObject,
  EventId,
  NodeId,
  NodeMode,
  NodesMovedForEditMode,
  RootNodeType,
} from '../../models/treeModels/treeTypes'
import { deepCloneObject } from '../commonFunctions'
import {
  findDirectChildrenNodesOfNode,
  findMainTreeAndOrphanTrees,
  findMainTreeRoot,
  findOrphanTreeRoots,
  findParentEvents,
  findParentNodes,
} from './treeBasicFunctions'
import {
  calculateHeightDiffForMinMax,
  eventXFromNodeX,
  findHeightOfTitle,
  findLeftPointOfNode,
  findLimitsOfNode,
  findRightMostNodeOfSubTree,
  findTopPointOfNode,
  heightOfNode,
  nodeX,
  nodeY,
  widthOfNode,
} from './treeXandYFunctions'

export const defaultRootNodePosition: [number, number] = [150, 150]
export const defaultEventWidth = 261
export const defaultEventWidthMinimised = 157
export const defaultNodeHandlerWidth = 44
export const defaultMaximisedRightSpace = 37
export const defaultSpaceForFirstChild = 200
export const defaultExtraSpace = 110

export const defaultHeightsOfNode: DefaultHeightsOfNode = {
  maximised: {
    state: {
      noAmounts: 442,
      awardedAmountOnly: 454,
      awardedAmountAndInterestOnly: 519,
      awardedAmountAndReduced: 454,
      awardedAmountAndInterestAndReduced: 519,
      awardedAmountAndExternal: 454,
      awardedAmountAndInterestAndExternal: 519,
      awardedAmountAndReducedAndExternal: 454,
      reducedAmountOnly: 442,
      reducedAmountAndExternal: 442,
      externalOnly: 442,
      fullAmounts: 519,
    },
  },
  minimised: {
    state: {
      noAmounts: 131,
      awardedAmountOnly: 175,
      awardedAmountAndInterestOnly: 198,
      awardedAmountAndReduced: 203,
      awardedAmountAndInterestAndReduced: 226,
      awardedAmountAndExternal: 207,
      awardedAmountAndInterestAndExternal: 230,
      awardedAmountAndReducedAndExternal: 203,
      reducedAmountOnly: 171,
      reducedAmountAndExternal: 195,
      externalOnly: 175,
      fullAmounts: 250,
    },
  },
  editMode: {
    state: {
      noAmounts: 442,
      awardedAmountOnly: 542,
      awardedAmountAndInterestOnly: 607,
      awardedAmountAndReduced: 581,
      awardedAmountAndInterestAndReduced: 646,
      awardedAmountAndExternal: 582,
      awardedAmountAndInterestAndExternal: 647,
      awardedAmountAndReducedAndExternal: 581,
      reducedAmountOnly: 481,
      reducedAmountAndExternal: 521,
      externalOnly: 482,
      fullAmounts: 686,
    },
  },
}

export function findWidthOfCanvas(
  nodeMode: NodeMode,
  treeDetails: TreeDetails,
  mainTree?: NodeId[],
) {
  let rightestNodeId
  //console.log(mainTree)

  if (mainTree) {
    rightestNodeId = Object.keys(treeDetails.nodes)
      .filter((nodeId) => mainTree.includes(nodeId as NodeId))
      .reduce((a, b) =>
        treeDetails.nodes[a as NodeId].properties.position[0] +
          widthOfNode(a as NodeId, nodeMode, treeDetails) >
        treeDetails.nodes[b as NodeId].properties.position[0] +
          widthOfNode(b as NodeId, nodeMode, treeDetails)
          ? a
          : b,
      )
  } else {
    rightestNodeId = Object.keys(treeDetails.nodes).reduce((a, b) =>
      treeDetails.nodes[a as NodeId].properties.position[0] +
        widthOfNode(a as NodeId, nodeMode, treeDetails) >
      treeDetails.nodes[b as NodeId].properties.position[0] +
        widthOfNode(b as NodeId, nodeMode, treeDetails)
        ? a
        : b,
    )
  }
  return (
    treeDetails.nodes[rightestNodeId as NodeId].properties.position[0] +
    widthOfNode(rightestNodeId as NodeId, nodeMode, treeDetails) +
    defaultExtraSpace
  )
}

export function findHeightOfCanvas(
  nodeMode: NodeMode,
  treeDetails: TreeDetails,
  treeIndex: number,
  mainTree?: NodeId[],
) {
  let lowestNodeId
  if (mainTree) {
    lowestNodeId = Object.keys(treeDetails.nodes)
      .filter((nodeId) => mainTree.includes(nodeId as NodeId))
      .reduce((a, b) =>
        treeDetails.nodes[a as NodeId].properties.position[1] >
        treeDetails.nodes[b as NodeId].properties.position[1]
          ? a
          : b,
      )
  } else {
    lowestNodeId = Object.keys(treeDetails.nodes).reduce((a, b) =>
      treeDetails.nodes[a as NodeId].properties.position[1] >
      treeDetails.nodes[b as NodeId].properties.position[1]
        ? a
        : b,
    )
  }

  return (
    treeDetails.nodes[lowestNodeId as NodeId].properties.position[1] +
    heightOfNode(
      nodeMode,
      lowestNodeId as NodeId,
      treeIndex,
      treeDetails,
      false,
    )
  )
}

export function findNewNodePosition(
  parentEventId: EventId,
  parentNodeId: NodeId,
  newNodeId: NodeId,
  treeDetails: TreeDetails,
  parentNodeMode: NodeMode,
  treeNodeMode: NodeMode,
  treeIndex: number,
  editMode: boolean,
): [number, number] {
  const siblingNodes = treeDetails.events[parentEventId].childrenNodes.filter(
    (nodeId) => nodeId !== newNodeId,
  )

  let newNodeX =
    findLeftPointOfNode(parentNodeId as NodeId, treeDetails, treeNodeMode) +
    eventXFromNodeX(parentEventId, treeNodeMode, treeDetails) -
    defaultSpaceForFirstChild

  if (newNodeX < 80) {
    newNodeX = 80
  }

  let newNodeY =
    findTopPointOfNode(
      parentNodeId,
      treeDetails,
      findHeightOfTitle(parentNodeId as NodeId, treeIndex, treeNodeMode),
    ) +
    heightOfNode(
      parentNodeMode,
      parentNodeId,
      treeIndex,
      treeDetails,
      editMode,
    ) +
    defaultExtraSpace

  /* console.log(
    'I added new node',
    newNodeId,
    'with initial position x:',
    newNodeX,
    'and y:',
    newNodeY,
  )

  console.log(
    'I will now check if the node',
    newNodeId,
    'overrides other Nodes',
  ) */

  const newNodePosition = findProperNewNodePosition(
    newNodeX,
    newNodeY,
    parentEventId,
    parentNodeId,
    siblingNodes,
    treeDetails,
    treeNodeMode,
    treeIndex,
  )

  // console.log('The position of the node has been found')

  return newNodePosition
}

export function findProperNewNodePosition(
  newNodeX: number,
  newNodeY: number,
  parentEventId: EventId,
  parentNodeId: NodeId,
  siblingNodes: NodeId[],
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  treeIndex: number,
  excludeSelfNodeId?: NodeId,
): [number, number] {
  const listOfNodesToCheck = Object.keys(treeDetails.nodes).filter(
    (nodeId) =>
      nodeId !== excludeSelfNodeId &&
      treeDetails.nodes[nodeId as NodeId].properties.position[0] !== -600,
  )

  //console.log('nodeMode in findProperNewNodePosition:', nodeMode)

  const newNodeXRight = excludeSelfNodeId
    ? newNodeX +
      widthOfNode(excludeSelfNodeId, nodeMode, treeDetails) +
      defaultNodeHandlerWidth
    : newNodeX + widthOfNode('defaultNewNode', nodeMode, treeDetails)

  const newNodeYBottom = excludeSelfNodeId
    ? newNodeY +
      heightOfNode(nodeMode, excludeSelfNodeId, treeIndex, treeDetails, false)
    : newNodeY + heightOfNode(nodeMode, 'defaultNewNode', 0, treeDetails, false)

  for (let checkNodeId of listOfNodesToCheck) {
    // console.warn(
    //   'I am checking if new Node overrides node',
    //   findNodeNumber(checkNodeId as NodeId, treeDetails),
    // )
    if (
      checkIfNodeOverrides(
        newNodeX,
        newNodeXRight,
        newNodeY,
        newNodeYBottom,
        checkNodeId as NodeId,
        treeDetails,
        nodeMode,
        treeIndex,
        false,
      )
    ) {
      // console.error('TRUE')
      /*
      console.log(
        checkNodeId,
        "'s limits are: x: ",
        findLimitsOfNode(
          checkNodeId as NodeId,
          treeDetails,
          'x',
          nodeMode,
          treeIndex,
          false,
        ),
        'and y:',
        findLimitsOfNode(
          checkNodeId as NodeId,
          treeDetails,
          'y',
          nodeMode,
          treeIndex,
          false,
        ),
      )
      console.log(
        'The new node limits are left:',
        newNodeX,
        'right:',
        newNodeXRight,
        'up:',
        newNodeY,
        'down: ',
        newNodeYBottom,
      ) */

      if (
        findParentNodes(checkNodeId as NodeId, treeDetails) !== undefined &&
        parentNodeId === findParentNodes(checkNodeId as NodeId, treeDetails)![0]
      ) {
        //console.log('Nodes have the same parentNode')
        // console.log('parentEventId')
        // console.log(parentEventId)

        if (
          treeDetails.events[parentEventId].eventIndex >
          treeDetails.events[
            findParentEvents(checkNodeId as NodeId, treeDetails)[0] as EventId
          ].eventIndex
        ) {
          /* console.log(
            'newNode has bigger parentEventIndex than ',
            checkNodeId,
            'so it moves to the right',
          ) */
          newNodeX =
            findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) +
            widthOfNode(checkNodeId as NodeId, nodeMode, treeDetails) +
            defaultExtraSpace
          newNodeY = nodeY(checkNodeId as NodeId, treeDetails)
          return findProperNewNodePosition(
            newNodeX,
            newNodeY,
            parentEventId,
            parentNodeId,
            siblingNodes,
            treeDetails,
            nodeMode,
            treeIndex,
            excludeSelfNodeId,
          )
        } else if (
          treeDetails.events[parentEventId].eventIndex ===
            treeDetails.events[
              findParentEvents(checkNodeId as NodeId, treeDetails)[0] as EventId
            ].eventIndex &&
          !excludeSelfNodeId
        ) {
          /*  console.log(
            'newNode has the same parentEventIndex as ',
            checkNodeId,
            'so we check the sibilngNodes',
          )
          */
          for (let siblingNodeId of siblingNodes) {
            /* console.log(
              'I compare newNodeX: ',
              newNodeX,
              'with siblingNode: ',
              siblingNodeId,
              'x: ',
              nodeX(siblingNodeId as NodeId, treeDetails),
            ) */

            if (
              checkIfNodeOverrides(
                newNodeX,
                newNodeXRight,
                newNodeY,
                newNodeYBottom,
                siblingNodeId as NodeId,
                treeDetails,
                nodeMode,
                treeIndex,
                false,
              )
            ) {
              /* console.log(
                'newNode moves next to its siblingNode:',
                siblingNodeId,
              ) */

              newNodeX =
                findLeftPointOfNode(
                  siblingNodeId as NodeId,
                  treeDetails,
                  nodeMode,
                ) +
                widthOfNode(siblingNodeId, nodeMode, treeDetails) +
                defaultExtraSpace
              newNodeY = nodeY(siblingNodeId as NodeId, treeDetails)

              return findProperNewNodePosition(
                newNodeX,
                newNodeY,
                parentEventId,
                parentNodeId,
                siblingNodes,
                treeDetails,
                nodeMode,
                treeIndex,
                excludeSelfNodeId,
              )
            }
          }
          //console.log(`1: newNode final position found ${[newNodeX, newNodeY]}`)

          return [newNodeX, newNodeY]
        } else {
          /*  console.log(
            'newNode has the smaller parentEventIndex than',
            checkNodeId,
            'so it stays here',
          )

          console.log(`2: newNode final position found ${[newNodeX, newNodeY]}`)
 */
          return [newNodeX, newNodeY]
        }
      } else {
        /* console.log(
          'Nodes have different parentNode, so we check the parentNode positions',
        )
 */
        let checkNodeParentNodeId = undefined
        if (findParentNodes(checkNodeId as NodeId, treeDetails) !== undefined) {
          checkNodeParentNodeId = findParentNodes(
            checkNodeId as NodeId,
            treeDetails,
          )![0]
        }

        if (
          checkNodeParentNodeId !== undefined &&
          nodeX(parentNodeId, treeDetails) >=
            nodeX(checkNodeParentNodeId as NodeId, treeDetails)
        ) {
          /* console.log(
            'new Node has bigger parentNodePosition than',
            checkNodeId,
            'so it has to move',
          )
          console.log(
            checkNodeId,
            treeDetails.nodes[checkNodeId as NodeId].properties.position[0],
          ) */
          newNodeX =
            findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) +
            widthOfNode(checkNodeId as NodeId, nodeMode, treeDetails) +
            defaultExtraSpace

          newNodeY = nodeY(checkNodeId as NodeId, treeDetails)

          return findProperNewNodePosition(
            newNodeX,
            newNodeY,
            parentEventId,
            parentNodeId,
            siblingNodes,
            treeDetails,
            nodeMode,
            treeIndex,
            excludeSelfNodeId,
          )
        } else {
          /* console.log(
            'new Node has smaller parentNodePosition than',
            checkNodeId,
            'so it stays here',
          )

          console.log(`3: newNode final position found ${[newNodeX, newNodeY]}`) */

          return [newNodeX, newNodeY]
        }
      }
    }
  }
  /*  console.error(`No overriding with other Nodes`)
   */
  // console.log(`4: newNode final position found ${[newNodeX, newNodeY]}`)
  return [newNodeX, newNodeY]
}

export function changeNodesPosition(
  newNodeId: NodeId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  typeOfRearrangment:
    | 'addNode'
    | 'addEvent'
    | 'duplicateNodes'
    | 'changeEditMode',
  treeIndex: number,
  editModeNodeId: NodeId | undefined,
  selectedNodes?: boolean,
): TreeDetails {
  let parentNodeId = ''
  let localNodeMode = nodeMode
  let tempEditMode = false
  treeDetails = deepCloneObject(treeDetails) as TreeDetails
  if (typeOfRearrangment === 'addNode') {
    parentNodeId = findParentNodes(newNodeId, treeDetails)
      ? findParentNodes(newNodeId, treeDetails)![0]
      : ''
  } else if (
    typeOfRearrangment === 'addEvent' &&
    newNodeId === editModeNodeId
  ) {
    localNodeMode = NodeMode.maximised
    tempEditMode = true
  }
  let newNodeX = nodeX(newNodeId, treeDetails)
  let newNodeY = nodeY(newNodeId, treeDetails)

  const newNodeXRight =
    findLeftPointOfNode(newNodeId as NodeId, treeDetails, nodeMode) +
    widthOfNode(newNodeId, localNodeMode, treeDetails)

  const newNodeYBottom =
    findTopPointOfNode(
      newNodeId,
      treeDetails,
      findHeightOfTitle(newNodeId, treeIndex, nodeMode),
    ) +
    heightOfNode(localNodeMode, newNodeId, treeIndex, treeDetails, tempEditMode)

  /* console.log(
    newNodeId,
    'has limits x:[',
    newNodeX,
    ',',
    newNodeXRight,
    '], and y: [',
    newNodeY,
    ',',
    newNodeYBottom,
    ']',
  ) */

  for (let checkNodeId of Object.keys(treeDetails.nodes).filter(
    (nodeId) => nodeId !== newNodeId,
  )) {
    let tempNodeMode =
      typeOfRearrangment === 'addNode' &&
      checkNodeId === parentNodeId &&
      parentNodeId === editModeNodeId
        ? NodeMode.maximised
        : nodeMode

    let tempEditMode =
      typeOfRearrangment === 'addNode' &&
      checkNodeId === parentNodeId &&
      parentNodeId === editModeNodeId

    /*  console.log('tempNodeMode', tempNodeMode)
    console.log('tempEditMode', tempEditMode) */

    /*  console.warn(
      'I am checking if',
      newNodeId,
      'overrides',
      checkNodeId,
      'with limits x:',
      findLimitsOfNode(
        checkNodeId as NodeId,
        treeDetails,
        'x',
        tempNodeMode,
        treeIndex,
        false,
      ),
      'and y:',
      findLimitsOfNode(
        checkNodeId as NodeId,
        treeDetails,
        'y',
        tempNodeMode,
        treeIndex,
        false,
      ),
    ) */

    if (
      checkIfNodeOverrides(
        newNodeX,
        newNodeXRight,
        newNodeY,
        newNodeYBottom,
        checkNodeId as NodeId,
        treeDetails,
        tempNodeMode,
        treeIndex,
        tempEditMode,
      )
    ) {
      /* console.log(
        newNodeId,
        'has the same position as',
        checkNodeId,
        'so',
        checkNodeId,
        'has to move',
      ) */
      if (typeOfRearrangment === 'duplicateNodes' && selectedNodes) {
        treeDetails.nodes[newNodeId].properties.position[0] =
          findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) +
          widthOfNode(checkNodeId as NodeId, nodeMode, treeDetails) +
          defaultExtraSpace
        //console.log(newNodeId, 'was here for duplication')

        treeDetails = changeNodesPosition(
          newNodeId,
          treeDetails,
          nodeMode,
          typeOfRearrangment,
          treeIndex,
          editModeNodeId,
          selectedNodes,
        )
      } else {
        treeDetails.nodes[checkNodeId as NodeId].properties.position[0] =
          findLeftPointOfNode(newNodeId, treeDetails, nodeMode) +
          widthOfNode(
            newNodeId,
            typeOfRearrangment === 'addEvent' ? localNodeMode : nodeMode,
            treeDetails,
          ) +
          defaultExtraSpace
        treeDetails = changeNodesPosition(
          checkNodeId as NodeId,
          treeDetails,
          nodeMode,
          typeOfRearrangment,
          treeIndex,
          editModeNodeId,
        )
      }
    }
  }
  return treeDetails
}

export function changeNodesPositionsMinMax(
  nodeMode: NodeMode,
  treeDetails: TreeDetails,
) {
  treeDetails = deepCloneObject(treeDetails)
  let xRatio = 3 / 5

  const [, tempMainTree, tempOrphanTrees] =
    findMainTreeAndOrphanTrees(treeDetails)

  tempOrphanTrees.unshift(tempMainTree)

  const trees = tempOrphanTrees
  let alreadyCheckedNodes: NodeId[] = []

  for (let tree of trees) {
    // console.log('%cChecking tree', 'color: green;')
    // console.log(tree.map((child) => findNodeNumber(child, treeDetails)))

    const treeRoot = tree.filter(
      (nodeId) =>
        treeDetails.nodes[nodeId as NodeId].root !== RootNodeType.noRoot,
    )[0]

    treeDetails.nodes[treeRoot as NodeId].properties.position[0] *=
      nodeMode === NodeMode.maximised ? 1 / xRatio : xRatio
    const childrenNodes = tree
      .filter((nodeId) => nodeId !== treeRoot)
      .sort((a, b) =>
        treeDetails.nodes[a].properties.position[1] >=
        treeDetails.nodes[b].properties.position[1]
          ? 1
          : -1,
      )

    let childrenTransformY: { [childNode: NodeId]: number } = {}
    for (let childNode of childrenNodes) {
      // console.log(
      //   'I am checking child node',
      //   findNodeNumber(childNode as NodeId, treeDetails),
      // )

      if (!alreadyCheckedNodes.includes(childNode)) {
        alreadyCheckedNodes.push(childNode)

        const parentNodes = findParentNodes(childNode as NodeId, treeDetails)
        treeDetails.nodes[childNode as NodeId].properties.position[0] *=
          nodeMode === NodeMode.maximised ? 1 / xRatio : xRatio
        let childNodeCurrentY =
          treeDetails.nodes[childNode as NodeId].properties.position[1]
        // console.log('start position')
        // console.log(JSON.stringify(childNodeCurrentY))

        if (parentNodes !== undefined) {
          let yTransformOfParent = 0
          let parentThatMovesTheChild = undefined
          for (let parentNode of parentNodes) {
            // console.log(
            //   'I am checking for its parent',
            //   findNodeNumber(parentNode as NodeId, treeDetails),
            // )
            let tempYTransformOfParent = calculateHeightDiffForMinMax(
              parentNode,
              treeDetails,
            )

            if (tempYTransformOfParent > yTransformOfParent) {
              yTransformOfParent = tempYTransformOfParent
              parentThatMovesTheChild = parentNode
            }
          }

          // console.log('yTransformOfParent')
          // console.log(yTransformOfParent)

          if (nodeMode === NodeMode.minimised) {
            yTransformOfParent *= -1
          }
          if (
            Object.keys(childrenTransformY).includes(parentThatMovesTheChild!)
          ) {
            yTransformOfParent +=
              childrenTransformY[parentThatMovesTheChild as NodeId]
          }
          treeDetails.nodes[childNode as NodeId].properties.position[1] +=
            yTransformOfParent

          if (
            treeDetails.nodes[childNode as NodeId].properties.position[1] <= 0
          ) {
            treeDetails.nodes[childNode as NodeId].properties.position[1] = 10
          }

          let childNodeNextY = deepCloneObject(
            treeDetails.nodes[childNode as NodeId].properties.position[1],
          )
          if (!Object.keys(childrenTransformY).includes(childNode)) {
            childrenTransformY[childNode as NodeId] =
              childNodeNextY - childNodeCurrentY
          }
        }
      } else {
        // console.log('I have already checked this Node')
      }
    }
  }

  return treeDetails
}

export function resetNodesPositions(
  nodeMode: NodeMode,
  treeDetails: TreeDetails,
  treeIndex: number,
) {
  const mainRootNode = findMainTreeRoot(treeDetails)
  const orphanTreeRoots = findOrphanTreeRoots(treeDetails)

  for (let nodeId of Object.keys(treeDetails.nodes)) {
    treeDetails.nodes[nodeId as NodeId].properties.position = [-600, -600]
  }

  const [, tempMainTree, tempOrphanTrees] =
    findMainTreeAndOrphanTrees(treeDetails)

  tempOrphanTrees.unshift(tempMainTree)

  const trees = tempOrphanTrees

  treeDetails.nodes[mainRootNode].properties.position = defaultRootNodePosition

  treeDetails = resetChildrenNodesPosition(
    mainRootNode,
    treeDetails,
    NodeMode.maximised,
    treeIndex,
    trees,
  )

  // Object.keys(treeDetails.nodes).forEach((nodeId) => {
  //   console.log(
  //     findNodeNumber(nodeId as NodeId, treeDetails),
  //     treeDetails.nodes[nodeId as NodeId].properties.position,
  //   )
  // })

  for (let [
    orphanNodeIndex,
    orphanTreeRootNodeId,
  ] of orphanTreeRoots.entries()) {
    const rightestNodeId =
      orphanNodeIndex === 0
        ? findRightMostNodeOfSubTree(mainRootNode, treeDetails)
        : findRightMostNodeOfSubTree(
            orphanTreeRoots[orphanNodeIndex - 1],
            treeDetails,
          )
    /*  console.log(
      'I check',
      orphanTreeRootNodeId,
      'and the rightestNode of the tree is',
      rightestNodeId,
    ) */

    treeDetails.nodes[orphanTreeRootNodeId as NodeId].properties.position[0] =
      findLeftPointOfNode(
        rightestNodeId as NodeId,
        treeDetails,
        NodeMode.maximised,
      ) +
      widthOfNode(rightestNodeId as NodeId, NodeMode.maximised, treeDetails) +
      4 * defaultExtraSpace
    if (
      findDirectChildrenNodesOfNode(orphanTreeRootNodeId, treeDetails).length >
      0
    ) {
      treeDetails.nodes[
        orphanTreeRootNodeId as NodeId
      ].properties.position[0] += defaultSpaceForFirstChild
    }
    treeDetails.nodes[orphanTreeRootNodeId as NodeId].properties.position[1] =
      defaultRootNodePosition[1]
    treeDetails = resetChildrenNodesPosition(
      orphanTreeRootNodeId,
      treeDetails,
      NodeMode.maximised,
      treeIndex,
      trees,
    )
  }

  //THIS IS FOR THE MAIN TREE ROOT TO GO TO THE CENTER BUT IT NEEDS ADJUSTMENT
  /*  const rightestNodeOfMainTree = findRightMostNodeOfSubTree(
    rootNodeId,
    treeDetails,
  )
  //console.log('rightestNodeOfMainTree', rightestNodeOfMainTree)
  if (rightestNodeOfMainTree !== rootNodeId) {
    treeDetails = deepCloneObject(treeDetails)
    treeDetails.nodes[rootNodeId].properties.position[0] =
      (findLeftPointOfNode(
        rightestNodeOfMainTree,
        treeDetails,
        NodeMode.maximised,
      ) +
        widthOfNode(rightestNodeOfMainTree, NodeMode.maximised, treeDetails) -
        80 -
        widthOfNode(rootNodeId, NodeMode.maximised, treeDetails)) /
      2
  } */

  if (nodeMode === NodeMode.minimised) {
    treeDetails = changeNodesPositionsMinMax(NodeMode.minimised, treeDetails)
  }

  // Object.keys(treeDetails.nodes).forEach((nodeId) => {
  //   console.log(
  //     findNodeNumber(nodeId as NodeId, treeDetails),
  //     treeDetails.nodes[nodeId as NodeId].properties.position,
  //   )
  // })
  return treeDetails
}

export function resetChildrenNodesPosition(
  parentNodeId: NodeId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  treeIndex: number,
  trees: NodeId[][],
): TreeDetails {
  let childrenNodes = findDirectChildrenNodesOfNode(parentNodeId, treeDetails)
  // console.log('Children nodes for', findNodeNumber(parentNodeId, treeDetails))

  // console.log(
  //   childrenNodes.map((nodeId) => findNodeNumber(nodeId, treeDetails)),
  // )

  childrenNodes = childrenNodes.sort((nodeA: string, nodeB: string) => {
    let parentEventIdForA = (
      findParentEvents(nodeA as NodeId, treeDetails) as EventId[]
    ).filter(
      (eventId) => treeDetails.events[eventId].nodeOfEventId === parentNodeId,
    )[0] as `event${string}${number}`

    let parentEventIdForB = (
      findParentEvents(nodeB as NodeId, treeDetails) as EventId[]
    ).filter(
      (eventId) => treeDetails.events[eventId].nodeOfEventId === parentNodeId,
    )[0] as `event${string}${number}`

    let nodeSiblingIndexForA = (
      treeDetails.nodes[nodeA as NodeId].nodeSiblingIndex as {
        [parentEventId: EventId]: number
      }
    )[parentEventIdForA]
    let nodeSiblingIndexForB = (
      treeDetails.nodes[nodeB as NodeId].nodeSiblingIndex as {
        [parentEventId: EventId]: number
      }
    )[parentEventIdForB]

    return nodeSiblingIndexForA +
      (treeDetails.events[parentEventIdForA].eventIndex + 1) * 100 <
      nodeSiblingIndexForB +
        (treeDetails.events[parentEventIdForB].eventIndex + 1) * 100
      ? -1
      : 1
  })

  const firstChildren = firstChildrenInTheirTree(childrenNodes, trees)
  // console.log('firstChildren')
  // console.log(firstChildren)

  for (let [childNodeIndex, childNodeId] of childrenNodes.entries()) {
    if (
      treeDetails.nodes[childNodeId as NodeId].properties.position[0] === -600
    ) {
      // console.log(
      //   'I am positioning Node',
      //   findNodeNumber(childNodeId as NodeId, treeDetails),
      // )
      if (firstChildren[childNodeId]) {
        // console.log('It is the first child for that tree')

        const parentEventIds: EventId[] = findParentEvents(
          childNodeId as NodeId,
          treeDetails,
        ) as EventId[]

        let parentEventId = undefined

        for (let parentEventToCheck of parentEventIds) {
          if (
            treeDetails.events[parentEventToCheck as EventId].nodeOfEventId ===
            parentNodeId
          ) {
            parentEventId = parentEventToCheck
          }
        }

        treeDetails.nodes[childNodeId as NodeId].properties.position[0] =
          findLeftPointOfNode(parentNodeId as NodeId, treeDetails, nodeMode) +
          eventXFromNodeX(
            parentEventId as EventId,
            NodeMode.maximised,
            treeDetails,
          ) -
          defaultSpaceForFirstChild
      } else {
        // console.log('It is not the first child')
        const previousChildNodeId = childrenNodes[childNodeIndex - 1]
        treeDetails.nodes[childNodeId as NodeId].properties.position[0] =
          findLeftPointOfNode(
            previousChildNodeId as NodeId,
            treeDetails,
            nodeMode,
          ) +
          widthOfNode(
            previousChildNodeId as NodeId,
            NodeMode.maximised,
            treeDetails,
          ) +
          defaultExtraSpace
      }

      if (nodeX(childNodeId as NodeId, treeDetails) <= 80) {
        treeDetails.nodes[childNodeId as NodeId].properties.position[0] = 80
      }
      treeDetails.nodes[childNodeId as NodeId].properties.position[1] =
        findTopPointOfNode(
          parentNodeId,
          treeDetails,
          findHeightOfTitle(parentNodeId as NodeId, treeIndex, nodeMode),
        ) +
        heightOfNode(nodeMode, parentNodeId, treeIndex, treeDetails, false) +
        defaultExtraSpace
      // console.error('I am checking proper position of', childNodeId)
      // console.log('The position before proper position')
      // console.log(treeDetails.nodes[childNodeId as NodeId].properties.position)

      treeDetails.nodes[childNodeId as NodeId].properties.position =
        findProperNewNodePosition(
          treeDetails.nodes[childNodeId as NodeId].properties.position[0],
          treeDetails.nodes[childNodeId as NodeId].properties.position[1],
          findParentEvents(childNodeId as NodeId, treeDetails)[0] as EventId,
          findParentNodes(childNodeId as NodeId, treeDetails)![0] as NodeId,
          childrenNodes.filter((nodeId) => nodeId !== childNodeId) as NodeId[],
          treeDetails,
          NodeMode.maximised,
          treeIndex,
          childNodeId as NodeId,
        )
    } else {
      // console.log(
      //   'Already positioned node',
      //   findNodeNumber(childNodeId as NodeId, treeDetails),
      // )
    }
    treeDetails = resetChildrenNodesPosition(
      childNodeId as NodeId,
      treeDetails,
      nodeMode,
      treeIndex,
      trees,
    )
  }

  return treeDetails
}

export function findNodesMovedForEdtiMode(
  nodeId: NodeId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  nodeForEdit: boolean,
  treeIndex: number,
  tempNodesMovedForEditMode: NodesMovedForEditMode = {},
  alreadyCheckedNodes: NodeId[] = [],
  direction?: 'down' | 'right',
): NodesMovedForEditMode {
  let nodesNeededToMove: [NodeId, 'down' | 'right'][] = []
  const newNodeX = findLeftPointOfNode(nodeId, treeDetails, nodeMode)
  const newNodeXRight = newNodeX + widthOfNode(nodeId, nodeMode, treeDetails)
  const newNodeXRightExtended = !nodeForEdit
    ? newNodeXRight
    : newNodeX + widthOfNode(nodeId, NodeMode.maximised, treeDetails)

  const newNodeY = findTopPointOfNode(
    nodeId,
    treeDetails,
    findHeightOfTitle(nodeId as NodeId, treeIndex, nodeMode),
  )
  const newNodeYBottom =
    newNodeY + heightOfNode(nodeMode, nodeId, treeIndex, treeDetails, false)
  const newNodeYBottomExtended =
    newNodeY +
    heightOfNode(nodeMode, nodeId, treeIndex, treeDetails, nodeForEdit)
  //console.log('I examine', nodeId, 'with x:', newNodeX, 'and y:', newNodeY)
  let tempTreeDetails = deepCloneObject(treeDetails)

  for (let checkNodeId of Object.keys(tempTreeDetails.nodes).filter(
    (tempNodeId) => tempNodeId !== nodeId,
  )) {
    const yUnderAndxAroundCriteria =
      newNodeYBottom <=
        findTopPointOfNode(
          checkNodeId as NodeId,
          treeDetails,
          findHeightOfTitle(checkNodeId as NodeId, treeIndex, nodeMode),
        ) &&
      newNodeYBottomExtended >=
        findTopPointOfNode(
          checkNodeId as NodeId,
          treeDetails,
          findHeightOfTitle(checkNodeId as NodeId, treeIndex, nodeMode),
        ) &&
      newNodeX <=
        findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) +
          widthOfNode(checkNodeId as NodeId, nodeMode, treeDetails) &&
      newNodeXRightExtended >=
        findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode)

    const xRightAndYAroundCriteria =
      newNodeXRightExtended >=
        findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) &&
      newNodeX <=
        findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) &&
      newNodeY <=
        findTopPointOfNode(
          checkNodeId as NodeId,
          treeDetails,
          findHeightOfTitle(checkNodeId as NodeId, treeIndex, nodeMode),
        ) +
          heightOfNode(
            nodeMode,
            checkNodeId as NodeId,
            treeIndex,
            treeDetails,
            false,
          ) &&
      newNodeYBottom >=
        findTopPointOfNode(
          checkNodeId as NodeId,
          treeDetails,
          findHeightOfTitle(checkNodeId as NodeId, treeIndex, nodeMode),
        )
    /* console.warn(
      `I compare 
      ${nodeId} with x: [${newNodeX},${
        !nodeForEdit ? newNodeXRight : newNodeXRightExtended
      }] and y: [${newNodeY},${
        !nodeForEdit ? newNodeYBottom : newNodeYBottomExtended
      }] with ${checkNodeId}
      with x: [
      ${findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode)},
      ${
        findLeftPointOfNode(checkNodeId as NodeId, treeDetails, nodeMode) +
        widthOfNode(checkNodeId as NodeId, nodeMode, treeDetails)
      }]
      and y: [${findTopPointOfNode(
        checkNodeId as NodeId,
        treeDetails,
        findHeightOfTitle(checkNodeId as NodeId, treeIndex, nodeMode),
      )}, ${
        findTopPointOfNode(
          checkNodeId as NodeId,
          treeDetails,
          findHeightOfTitle(checkNodeId as NodeId, treeIndex, nodeMode),
        ) +
        heightOfNodeNew(
          nodeMode,
          checkNodeId as NodeId,
          treeIndex,
          treeDetails,
          false,
        )
      }]`,
    ) */

    const comparisonCriteria = nodeForEdit
      ? yUnderAndxAroundCriteria || xRightAndYAroundCriteria
      : checkIfNodeOverrides(
          newNodeX,
          newNodeXRight,
          newNodeY,
          newNodeYBottom,
          checkNodeId as NodeId,
          treeDetails,
          nodeMode,
          treeIndex,
          false,
        )

    if (
      !alreadyCheckedNodes.includes(checkNodeId as NodeId) &&
      comparisonCriteria
    ) {
      if (!Object.keys(tempNodesMovedForEditMode).includes(checkNodeId)) {
        tempNodesMovedForEditMode[checkNodeId] = {
          previousPosition:
            treeDetails.nodes[checkNodeId as NodeId].properties.position,
          newPosition: tempTreeDetails.nodes[checkNodeId].properties.position,
        }
      }
      if (direction) {
        if (direction === 'down') {
          /* console.error('DIRECTION DOWN')
          console.log(
            'difference:',
            tempNodesMovedForEditMode[nodeId].newPosition[1] -
              tempNodesMovedForEditMode[nodeId].previousPosition[1],
          )

          console.log(
            `${nodeId} has the same position as ${checkNodeId} so ${checkNodeId} has to move down to y: ${
              tempTreeDetails.nodes[checkNodeId].properties.position[1] +
              tempNodesMovedForEditMode[nodeId].newPosition[1] -
              tempNodesMovedForEditMode[nodeId].previousPosition[1]
            }`,
          ) */
          tempTreeDetails.nodes[checkNodeId].properties.position[1] +=
            (tempNodesMovedForEditMode[nodeId].newPosition[1] -
              tempNodesMovedForEditMode[nodeId].previousPosition[1]) /
            2
          tempNodesMovedForEditMode[checkNodeId].newPosition[1] +=
            (tempNodesMovedForEditMode[nodeId].newPosition[1] -
              tempNodesMovedForEditMode[nodeId].previousPosition[1]) /
            2
          nodesNeededToMove.push([checkNodeId as NodeId, 'down'])
          continue
        } else {
          /* console.error('DIRECTION RIGHT')

          console.log(
            `${nodeId} has the same position as ${checkNodeId} so ${checkNodeId} has to move right to x:${
              tempTreeDetails.nodes[checkNodeId].properties.position[0] +
              tempNodesMovedForEditMode[nodeId].newPosition[0] -
              tempNodesMovedForEditMode[nodeId].previousPosition[0]
            }`,
          ) */
          tempTreeDetails.nodes[checkNodeId].properties.position[0] +=
            (tempNodesMovedForEditMode[nodeId].newPosition[0] -
              tempNodesMovedForEditMode[nodeId].previousPosition[0]) /
            2
          tempNodesMovedForEditMode[checkNodeId].newPosition[0] +=
            (tempNodesMovedForEditMode[nodeId].newPosition[0] -
              tempNodesMovedForEditMode[nodeId].previousPosition[0]) /
            2
          nodesNeededToMove.push([checkNodeId as NodeId, 'right'])
          continue
        }
      } else {
        if (yUnderAndxAroundCriteria) {
          /* console.log(
            `${nodeId} has the same position as ${checkNodeId} so ${checkNodeId} has to move down to y:${
              findTopPointOfNode(
                nodeId as NodeId,
                treeDetails,
                findHeightOfTitle(nodeId as NodeId, treeIndex, nodeMode),
              ) +
              heightOfNodeNew(
                nodeMode,
                nodeId as NodeId,
                treeIndex,
                treeDetails,
                true,
              ) +
              100
            }`,
          ) */
          tempTreeDetails.nodes[checkNodeId].properties.position[1] =
            findTopPointOfNode(
              nodeId as NodeId,
              treeDetails,
              findHeightOfTitle(nodeId as NodeId, treeIndex, nodeMode),
            ) +
            heightOfNode(
              nodeForEdit ? NodeMode.maximised : nodeMode,
              nodeId as NodeId,
              treeIndex,
              treeDetails,
              true,
            ) +
            100
          tempNodesMovedForEditMode[checkNodeId].newPosition[1] =
            findTopPointOfNode(
              nodeId as NodeId,
              treeDetails,
              findHeightOfTitle(nodeId as NodeId, treeIndex, nodeMode),
            ) +
            heightOfNode(
              nodeForEdit ? NodeMode.maximised : nodeMode,
              nodeId as NodeId,
              treeIndex,
              treeDetails,
              true,
            ) +
            100
          nodesNeededToMove.push([checkNodeId as NodeId, 'down'])
          continue
        }
        if (xRightAndYAroundCriteria) {
          /* console.log(
            `${nodeId} has the same position as ${checkNodeId} so ${checkNodeId} has to move right to x:${
              findLeftPointOfNode(nodeId, treeDetails, nodeMode) +
              widthOfNode(nodeId, NodeMode.maximised, treeDetails) +
              defaultExtraSpace
            }`,
          ) */
          tempTreeDetails.nodes[checkNodeId].properties.position[0] =
            findLeftPointOfNode(nodeId, treeDetails, nodeMode) +
            widthOfNode(nodeId, NodeMode.maximised, treeDetails) +
            defaultExtraSpace

          tempNodesMovedForEditMode[checkNodeId].newPosition[0] =
            findLeftPointOfNode(nodeId, treeDetails, nodeMode) +
            widthOfNode(nodeId, NodeMode.maximised, treeDetails) +
            defaultExtraSpace
          nodesNeededToMove.push([checkNodeId as NodeId, 'right'])
          continue
        }
      }
    }
  }
  alreadyCheckedNodes.push(nodeId)

  for (let nodeToBeMoved of nodesNeededToMove) {
    /* console.log(
      `loop through nodesNeededToMove from ${nodeId}: ${nodeToBeMoved}`,
    ) */

    tempNodesMovedForEditMode = findNodesMovedForEdtiMode(
      nodeToBeMoved[0] as NodeId,
      tempTreeDetails,
      nodeMode,
      false,
      treeIndex,
      tempNodesMovedForEditMode,
      alreadyCheckedNodes,
      nodeToBeMoved[1],
    )
    alreadyCheckedNodes.push(nodeToBeMoved[0])
  }

  return tempNodesMovedForEditMode
}

export function checkIfNodeOverrides(
  nodeXLeft: number,
  nodeXright: number,
  nodeYUp: number,
  nodeYBottom: number,
  checkNodeId: NodeId,
  treeDetails: TreeDetails,
  nodeMode: NodeMode,
  treeIndex: number,
  editMode: boolean,
): boolean {
  return (
    nodeXright >=
      findLimitsOfNode(
        checkNodeId,
        treeDetails,
        'x',
        nodeMode,
        treeIndex,
        editMode,
      )[0] &&
    nodeXLeft <=
      findLimitsOfNode(
        checkNodeId,
        treeDetails,
        'x',
        nodeMode,
        treeIndex,
        editMode,
      )[1] &&
    nodeYBottom >
      findLimitsOfNode(
        checkNodeId,
        treeDetails,
        'y',
        nodeMode,
        treeIndex,
        editMode,
      )[0] &&
    nodeYUp <
      findLimitsOfNode(
        checkNodeId,
        treeDetails,
        'y',
        nodeMode,
        treeIndex,
        editMode,
      )[1]
  )
}

export function findDeleteNodeOrEventMenuPosition(
  openDeleteNodeOrEventMenu: DeleteNodeOrEventMenuObject,
  treeDetails: TreeDetails,
): [number, number] {
  const nodePosition =
    treeDetails.nodes[openDeleteNodeOrEventMenu.nodeId].properties.position
  if (openDeleteNodeOrEventMenu.deleteMenuType === DeleteMenuType.event) {
    const eventRightPosition =
      nodePosition[0] +
      defaultNodeHandlerWidth +
      (openDeleteNodeOrEventMenu.eventIndex! + 1) * defaultEventWidth
    const top = nodePosition[1] + 75
    const left = eventRightPosition + 6
    const position: [number, number] = [top, left]
    return position
  } else {
    const top = nodePosition[1]
    const left =
      nodePosition[0] +
      widthOfNode(
        openDeleteNodeOrEventMenu.nodeId,
        openDeleteNodeOrEventMenu.nodeMode,
        treeDetails,
      ) +
      20
    const position: [number, number] = [top, left]
    return position
  }
}

export function firstChildrenInTheirTree(
  childrenNodes: NodeId[],
  trees: NodeId[][],
): { [childNodeId: NodeId]: boolean } {
  let firstChildren: { [childNodeId: NodeId]: boolean } = {}
  const numberOfTrees = trees.length
  let childrenIndexInTrees: NodeId[][] = Array.from(
    { length: numberOfTrees },
    () => [],
  )

  for (let childNode of childrenNodes) {
    for (let [treeIndex, tree] of trees.entries()) {
      if (tree.includes(childNode)) {
        childrenIndexInTrees[treeIndex].push(childNode)
        break
      }
    }
  }

  for (let childrenNodesInTree of childrenIndexInTrees) {
    for (let [childIndex, childNode] of childrenNodesInTree.entries()) {
      if (childIndex === 0) {
        firstChildren[childNode] = true
      } else {
        firstChildren[childNode] = false
      }
    }
  }
  return firstChildren
}
