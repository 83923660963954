import { AxiosResponse } from 'axios'
import { RequestError } from '../models/enums'
import { RequestErrorType } from '../models/generalTypes'

import { get, post } from '../services/requestsTemplate'
import { getFreemiumUrl } from './freemiumConstants'
import { TreeObjectForCalculation } from '../models/treeModels/treeTypes'
import { syncAndGetUserid } from './freemiumServices'

//GET @freemium_base
export const getFreemium = async () => {
  try {
    return await get(getFreemiumUrl('FREEMIUM_BASE_URL'), {
      Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_login
export const freemiumLogin = async (email?: string) => {
  try {
    return await post(
      getFreemiumUrl('FREEMIUM_LOGIN_URL'),
      {},
      email ? { email: email } : {},
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_grouplist
export const getFreemiumCases = async () => {
  try {
    return await get(getFreemiumUrl('FREEMIUM_CASES_URL'), {
      Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_caselist
export const getFreemiumScenarios = async (caseId: string) => {
  try {
    return await get(getFreemiumUrl('FREEMIUM_SCENARIOS_URL', [caseId]), {
      Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_casedetails
export const getFreemiumScenario = async (
  caseId: string,
  scenarioId: string,
) => {
  try {
    return await get(
      getFreemiumUrl('FREEMIUM_SINGLE_SCENARIO_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_reportdata
export const getFreemiumReportDetail = async (
  caseId: string,
  scenarioId: string,
) => {
  try {
    return await get(
      getFreemiumUrl('FREEMIUM_REPORT_DETAIL_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
    )
  } catch (error) {
    return handleError(error)
  }
}

//POST @freemium_calculate_snapshot
export const freemiumCalculateSnapshot = async (
  body: any,
  caseId: string,
  scenarioId: string,
) => {
  try {
    return await post(
      getFreemiumUrl('FREEMIUM_CALCULATE_SNAPSHOT_URL', [caseId, scenarioId]),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_get_job
export const getFreemiumJob = async () => {
  try {
    return await get(getFreemiumUrl('FREEMIUM_GET_JOB_URL'), {
      Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_cancel_job
export const cancelFreemiumJob = async () => {
  try {
    return await get(getFreemiumUrl('FREEMIUM_CANCEL_JOB_URL'), {
      Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

//POST @freemium_calculate_tree
export const freemiumCalculateTree = async (
  body: TreeObjectForCalculation,
  bigTimeout?: boolean,
) => {
  try {
    return await post(
      getFreemiumUrl('FREEMIUM_CALCULATE_TREE_URL'),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
      body,
      bigTimeout ? 60000 : 20000,
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_wordpress
export const getFreemiumWordpress = async (
  post_type: string,
  slug?: string,
) => {
  try {
    return await get(
      getFreemiumUrl('FREEMIUM_WORDPRESS_URL', [
        post_type,
        slug ? slug : 'no-slug',
      ]),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_get_tutorial
export const getFreemiumTutorial = async (tutorialId: string) => {
  try {
    return await get(
      getFreemiumUrl('FREEMIUM_GET_TUTORIAL_URL', [tutorialId]),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
    )
  } catch (error) {
    return handleError(error)
  }
}

//POST @freemium_log
export const createFreemiumLogs = async (eventName: string, props?: any) => {
  let body: { eventName: string; props?: any } = { eventName }
  if (props) {
    body = { ...body, props: props }
  }
  try {
    return await post(
      getFreemiumUrl('FREEMIUM_LOG_URL'),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
      body,
    )
  } catch (error: any) {
    return
  }
}

//POST @freemium_update_snapshot
export const freemiumUpdateSnapshot = async (body: { data: any }) => {
  try {
    return await post(
      getFreemiumUrl('FREEMIUM_UPDATE_SNAPSHOT_URL'),
      {
        Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
      },
      body,
    )
  } catch (error) {
    return handleError(error)
  }
}

//GET @freemium_get_queue
export const getFreemiumQueue = async () => {
  try {
    return await get(getFreemiumUrl('FREEMIUM_GET_QUEUE_URL'), {
      Authorization: `Bearer ${syncAndGetUserid() ? syncAndGetUserid() : ''}`,
    })
  } catch (error) {
    return handleError(error)
  }
}

const handleError = async (
  error: any,
): Promise<AxiosResponse<any> | RequestErrorType> => {
  switch (error.response.status) {
    case 401:
      return {
        errorCode: RequestError.UNAUTHORIZED,
        message: error.response.data,
      }
    case 403:
      return { errorCode: RequestError.FORBIDDEN, message: error.response.data }
    case 404:
      return { errorCode: RequestError.NOT_EXIST, message: error.response.data }
    case 400:
      return {
        errorCode: RequestError.BAD_REQUEST,
        message: error.response.data,
      }
    case 500:
      return {
        errorCode: RequestError.SERVER_ERROR,
        message: error.response.data,
      }
    case 599:
      return {
        errorCode: RequestError.WRONG_REDUCED_DATES,
        message:
          'Wrong Interest Dates for Reduced Awarded Amounts in Legal Tree',
      }
    default:
      return {
        errorCode: RequestError.UNKNOWN_ERROR,
        message: error.response.data,
      }
  }
}
