import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import SavePopUp from '../../../savePopUp/SavePopUp'
import PathComponent from './PathComponent'

import logo from '../../../../resources/images/100-eperoto_Headlines.svg'

import { Params } from '../../../../models/generalTypes'
import { SaveMenuEnum, SideMenuPages } from '../../../../models/enums'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { debuggingState } from '../../../../states/DebuggingState'
import {
  isDevEnv,
  isStagingEnv,
  isTestEnv,
  logActivity,
} from '../../../../services/commonFunctions'
import { pageDepthState } from '../../../../states/PageDepthState'
import { onlineState } from '../../../../states/OnlineState'
import { deleteRecoverySnapshot } from '../../../../services/requests'
import { getText } from '../../../../services/textFunctions'
import { userState } from '../../../../states/UserState'
import { reportMenuState } from '../../../../states/ReportMenuState'
import { recoveryModeState } from '../../../../states/RecoveryModeState'

type Props = {
  caseName: string
  scenarioName: string
  saved: boolean
  reportSaved: boolean
  pageDepth: number
  handleSave?: (param: boolean) => Promise<'saved' | 'not saved'>
  handleSaveReport?: () => Promise<'report saved' | 'report not saved'>
  scenariosList: { [key: string]: string }[]
  saving: string
  saveMenuOn?: undefined | string
  setSaveMenuOn?: (param: undefined | string) => void
  setSavedMessage?: (param: string) => void
  headerTutorialTitle?: string
  isTutorial: boolean
}

export default function HeaderPath(props: Props) {
  const params = useParams<Params>()
  const user = useRecoilValue(userState)
  const recoveryMode = useRecoilValue(recoveryModeState)

  const navigate = useNavigate()
  const [debugging, setDebugging] = useRecoilState(debuggingState)
  const [pageBack, setPageBack] = useState(false) // a boolean that is used to define if the back button is pressed -- Not working
  const [pageUpload, setPageUpload] = useState(false) // a boolean that is used to define if the reload button is pressed -- Not working
  const [pageDepth, setPageDepth] = useRecoilState(pageDepthState)
  const setReportMenu = useSetRecoilState(reportMenuState)
  const [online, setOnline] = useRecoilState(onlineState)

  //This is not working the way we want -- Try to fix it
  const beforeunloadFunction = (e: any) => {
    if (
      pageDepth < 3 ||
      (pageDepth === 3 &&
        (props.saved || recoveryMode.recoveryPreview !== 'none')) ||
      (pageDepth === 3.1 && props.reportSaved)
    ) {
      return
    } else {
      e.preventDefault(e)
      e.returnValue = 'Are you sure to reload the page?'
    }
  }

  //This is not working the way we want -- Try to fix it
  useEffect(() => {
    window.addEventListener('beforeunload', beforeunloadFunction, false)
    window.addEventListener('hashchange', beforeunloadFunction)

    return () => {
      window.removeEventListener('beforeunload', beforeunloadFunction, false)
      window.removeEventListener('hashchange', beforeunloadFunction)
    }
    // eslint-disable-next-line
  }, [props.saved, pageBack, pageUpload, pageDepth, props.reportSaved])

  async function saveAndContinue() {
    if (online.networkOn) {
      if (pageUpload) {
        setPageUpload(false)
        window.location.reload()
      } else if (pageBack) {
        setPageBack(false)
        window.history.back()
      } else {
        const res =
          pageDepth === 3
            ? await props.handleSave!(true)
            : await props.handleSaveReport!()

        const resResponse = pageDepth === 3 ? 'saved' : 'report saved'

        if (res === resResponse) {
          //Mixpanel 61
          logActivity(false, 'Pressed save and continue')
          if (pageDepth === 3) {
            await deleteRecoverySnapshot(params.caseId!, params.scenarioId!)
          }

          setTimeout(() => {
            if (props.saveMenuOn === SaveMenuEnum.mycases) {
              navigate('/')
            } else if (props.saveMenuOn === SaveMenuEnum.myscenarios) {
              navigate(`/mycases/${params.caseId}`)
            } else if (props.saveMenuOn) {
              navigate(`/mycases/${params.caseId}/${props.saveMenuOn}/tool`)
              setPageDepth(3)
              setReportMenu({
                downloadReportFixedHeaderOn: false,
                orderMenuOn: false,
                downloadPdf: false,
                reportSaveMenuOn: undefined,
                reportWithDifferentResults: false,
                previewPdfOn: false,
              })
            }
            if (props.setSaveMenuOn) props.setSaveMenuOn(undefined)
          }, 2000)
        } else {
          props.setSavedMessage!('error')
        }
      }
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  function continueWithoutSaving() {
    if (online.networkOn) {
      //Mixpanel 62
      logActivity(false, 'Pressed continue without saving')
      if (pageDepth === 3) {
        deleteRecoverySnapshot(params.caseId!, params.scenarioId!)
      }

      if (props.saveMenuOn === SaveMenuEnum.mycases) {
        navigate('/')
      } else if (props.saveMenuOn === SaveMenuEnum.myscenarios) {
        navigate(`/mycases/${params.caseId}`)
      } else if (props.saveMenuOn) {
        navigate(`/mycases/${params.caseId}/${props.saveMenuOn}/tool`)
        setPageDepth(3)
        setReportMenu({
          downloadReportFixedHeaderOn: false,
          orderMenuOn: false,
          downloadPdf: false,
          reportSaveMenuOn: undefined,
          reportWithDifferentResults: false,
          previewPdfOn: false,
        })
      }
      if (props.setSaveMenuOn) props.setSaveMenuOn(undefined)
    } else {
      setOnline({ ...online, shaking: true })
    }
  }

  return (
    <div className="headerPathContainer">
      {/* Basic Info */}
      <div className="logoContainer">
        <img
          src={logo}
          alt="logo"
          className="logo"
          onClick={
            isDevEnv() || isStagingEnv() || isTestEnv()
              ? () => setDebugging(!debugging)
              : undefined
          }
          style={
            isDevEnv() || isStagingEnv() || isTestEnv()
              ? { cursor: 'pointer' }
              : undefined
          }
        />
      </div>
      {props.saving === '' ? (
        <>
          {props.pageDepth < 0 ? (
            <PathComponent
              pathTitle={SideMenuPages.Settings}
              isFinal={true}
              link={undefined}
              setSaveMenuOn={props.setSaveMenuOn!}
            />
          ) : null}
          {props.pageDepth >= 0.9 && props.pageDepth < 1 ? (
            <PathComponent
              pathTitle={SideMenuPages.Academy}
              isFinal={props.pageDepth === 0.9}
              link={undefined}
              setSaveMenuOn={props.setSaveMenuOn!}
            />
          ) : null}
          {props.pageDepth >= 1 ? (
            <PathComponent
              pathTitle={SideMenuPages.MyCases}
              isFinal={props.pageDepth === 1}
              link={'/'}
              setSaveMenuOn={props.setSaveMenuOn!}
            />
          ) : null}
          {props.pageDepth === 1.1 ? (
            <PathComponent
              //data-textattribute = 'title-198'
              pathTitle={getText('title-198', user.settings)}
              isFinal
              link={'/deleted'}
              setSaveMenuOn={props.setSaveMenuOn!}
            />
          ) : null}
          {props.pageDepth >= 2 ? (
            <PathComponent
              pathTitle={props.caseName}
              isTutorial={props.isTutorial}
              isFinal={props.pageDepth === 2}
              link={`/mycases/${params.caseId}`}
              setSaveMenuOn={props.setSaveMenuOn!}
            />
          ) : null}
          {props.pageDepth === 2.1 ? (
            <PathComponent
              //data-textattribute = 'title-199'
              pathTitle={getText('title-199', user.settings)}
              isFinal
              isTutorial={props.isTutorial}
              link={`/mycases/${params.caseId}/deleted`}
              setSaveMenuOn={props.setSaveMenuOn!}
            />
          ) : null}
          {props.pageDepth >= 3 ? (
            <PathComponent
              isTutorial={props.isTutorial}
              pathTitle={props.scenarioName}
              isFinal={props.pageDepth >= 3}
              setSaveMenuOn={props.setSaveMenuOn!}
              isScenario
              scenariosList={props.scenariosList}
              saveMenuOn={props.saveMenuOn}
            />
          ) : null}
        </>
      ) : null}

      {props.saveMenuOn &&
      props.saveMenuOn !== SaveMenuEnum.mycasesSide &&
      props.saveMenuOn !== SaveMenuEnum.academy &&
      props.saveMenuOn !== SaveMenuEnum.settings &&
      props.saveMenuOn !== SaveMenuEnum.logOut ? (
        <SavePopUp
          id={`savePopUp${props.saveMenuOn} - Header`}
          style={
            props.saveMenuOn === SaveMenuEnum.mycases
              ? { left: '140px', top: '60px' }
              : props.saveMenuOn === SaveMenuEnum.myscenarios
              ? { left: '190px', top: '60px' }
              : props.saveMenuOn === SaveMenuEnum.report
              ? { left: '670px', top: '120px' }
              : { left: '280px', top: '60px' }
          }
          typeOfMenu={props.saveMenuOn}
          setSaveMenuOn={props.setSaveMenuOn!}
          continueWithoutSaving={continueWithoutSaving}
          saveAndContinue={saveAndContinue}
        />
      ) : null}
    </div>
  )
}
