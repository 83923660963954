import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'

import {
  NodeId,
  RootNodeType,
} from '../../../../../../../../models/treeModels/treeTypes'

import binImg from '../../../../../../../../resources/images/199-deleteNode.svg'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'

type Props = {
  nodeId: NodeId
  root: RootNodeType
  treeIndex: number
  handleDeleteNodeClick: (e: any) => void
}

export default function DeleteNodeButton(props: Props) {
  const user = useRecoilValue(userState)

  const [hoverOn, setHoverOn] = useState(false)

  return (
    <>
      <div
        className="deleteNodeButtonContainer"
        id={`deleteNodeButtonContainer-${props.treeIndex}_${props.nodeId}`}
        onClick={props.handleDeleteNodeClick}
        onMouseEnter={() => setHoverOn(true)}
        onMouseLeave={() => setHoverOn(false)}
      >
        <div
          className={`deleteNodeButtonImgContainer ${hoverOn ? 'hover' : ''}`}
          id={`deleteNodeButtonImgContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <img
            src={binImg}
            alt="binImg"
            className="deleteNodeButtonImg"
            id={`deleteNodeButtonImg-${props.treeIndex}_${props.nodeId}`}
          />
        </div>
        <div
          className="deleteNodeButtonTextContainer"
          id={`deleteNodeButtonTextContainer-${props.treeIndex}_${props.nodeId}`}
        >
          <p
            className={`deleteNodeButtonText ${hoverOn ? 'hover' : ''}`}
            id={`deleteNodeButtonText-${props.treeIndex}_${props.nodeId}`}
            data-textattribute="button-120"
          >
            {getText('button-120', user.settings)}
          </p>
        </div>
      </div>
    </>
  )
}
