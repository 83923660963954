import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Button from '../../../../../Components/Buttons/Button/Button'
import InfoClickComponent from '../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import InputText from '../../../../../Components/Inputs/InputText/InputText'

type Props = {
  id: string
  originalValue: string
  loading: boolean
  showSaved: boolean
  errorText: string
  labelText: string
  showInfoButton?: boolean
  infoButtonText?: string
  buttonText: string
  confirmationText: string
  errorTextAttribute?: `error-${number}`
  labelTextAttribute: `label-${number}`
  infoButtonTextAttribute?: `infoButton-${number}`
  buttonTextAttribute?: `button-${number}`
  confirmationTextAttribute?: `button-${number}`
  tabIndex: number
  hoverElement?: string
  setHoverElement?: (value: string) => void
  onSave: (id: string, value: string) => void
  readOnly?: boolean
}

const SettingInputRow = (props: Props) => {
  const [value, setValue] = useState(props.originalValue)
  const [errorOpen, setErrorOpen] = useState(false)
  const [showSaveButton, setShowSaveButton] = useState(false)

  useEffect(() => {
    if (!props.loading && props.originalValue === value.trim()) {
      setShowSaveButton(false)
    }

    // eslint-disable-next-line
  }, [props.loading])

  function saveOnEnter(e: any) {
    if (e.key === 'Enter' && !errorOpen && showSaveButton) {
      props.onSave(props.id, value)
      document.getElementById(props.id)?.blur()
    }
  }

  return (
    <div
      className={`tool-component-input-container marginLeft3 first-container ${
        !props.showInfoButton ? 'no-info' : ' '
      }`}
    >
      <InputText
        label={props.labelText}
        labelTextAttribute={props.labelTextAttribute}
        value={value}
        className="form-control"
        name={props.id}
        onChange={(e) => {
          if (e.target.value.trim().length === 0) {
            setErrorOpen(true)
          } else {
            setErrorOpen(false)
          }

          if (e.target.value.trim() === props.originalValue) {
            setShowSaveButton(false)
          } else {
            setShowSaveButton(true)
          }

          setValue(e.target.value)
        }}
        onKeyDown={saveOnEnter}
        id={props.id}
        tabIndex={props.tabIndex}
        width={275}
        error={errorOpen}
        errorMessage={props.errorText}
        errorTextAttribute={props.errorTextAttribute}
        alwaysShrink
        disabled={props.readOnly}
        withoutDisabledMessage
        noDelay
      />
      {props.showInfoButton ? (
        <InfoClickComponent
          infoClickText={props.infoButtonText!}
          infoClickTextAttribute={props.infoButtonTextAttribute!}
          idName={props.id}
          hoverElement={props.hoverElement!}
          setHoverElement={props.setHoverElement!}
        />
      ) : (
        <div style={{ width: '30px' }}></div>
      )}
      {props.showSaved && !showSaveButton && (
        <Button
          id={`saved${props.id}`}
          buttonText={props.confirmationText}
          buttonTextAttribute={props.confirmationTextAttribute ?? undefined}
          buttonType="nonactiveText"
          className="createCaseButton"
          type="button"
          onClick={() => {}}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      )}
      {showSaveButton && !errorOpen && (
        <Button
          id={`save${props.id}`}
          buttonText={
            props.loading ? (
              <div className="circularProgressDiv" id={'popUp7'}>
                <div id={'popUp8'}>
                  <CircularProgress size={14} />
                  <div
                    className="hiddenTextForSize"
                    style={{ height: 0, visibility: 'hidden' }}
                    id="hiddenCreateCaseButtonText"
                    data-textattribute={props.buttonTextAttribute}
                  >
                    {props.buttonText}
                  </div>
                </div>
              </div>
            ) : (
              props.buttonText
            )
          }
          buttonTextAttribute={props.buttonTextAttribute ?? undefined}
          buttonType="contained"
          className="createCaseButton"
          type="button"
          onClick={() => props.onSave(props.id, value)}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      )}
    </div>
  )
}

export default SettingInputRow
