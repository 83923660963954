import { ClaimType, claimTypeFromString } from './enums'
import { LiabilityRequirement } from './liabilityRequirement'
import { QuantumScenario } from './quantumScenario'
import { v4 as uuid } from 'uuid'
import { ClaimId } from './treeModels/treeTypes'

export class IndependentClaim {
  name: string
  type: ClaimType
  id: ClaimId
  claimtypeId: `Client Claim ${number}` | `Opposing Party Claim ${number}`
  amount: number | undefined
  isInterest: boolean
  interestRate: number | undefined
  interestDate: string | undefined
  liabilityRequirements: LiabilityRequirement[]
  liability: number
  quantumProbabilities: number[]
  hasOutOfCourt: boolean
  quantumScenarios: QuantumScenario[]

  constructor(
    name: string,
    type: ClaimType,
    id: ClaimId,
    claimtypeId: `Client Claim ${number}` | `Opposing Party Claim ${number}`,
    amount: number | undefined,
    isInterest: boolean,
    interestRate: number | undefined,
    interestDate: string | undefined,
    liabilityRequirements: LiabilityRequirement[],
    liability: number,
    quantumProbabilities: number[],
    hasOutOfCourt: boolean,
    quantumScenarios: QuantumScenario[],
  ) {
    this.name = name
    this.type = type
    this.id = id
    this.claimtypeId = claimtypeId
    this.amount = amount
    this.isInterest = isInterest
    this.interestRate = interestRate
    this.interestDate = interestDate
    this.liabilityRequirements = [...liabilityRequirements]
    this.liability = liability
    this.quantumProbabilities = [...quantumProbabilities]
    this.hasOutOfCourt = hasOutOfCourt
    this.quantumScenarios = [...quantumScenarios]
  }

  static InitialClaim(
    name: string,
    type: ClaimType,
    claimtypeId: `Client Claim ${number}` | `Opposing Party Claim ${number}`,
  ): IndependentClaim {
    let tempClaim = new IndependentClaim(
      name,
      type,
      `claim${uuid().substring(0, 8)}${parseInt(Date.now().toString())}`,
      claimtypeId,
      undefined,
      false,
      undefined,
      undefined,
      [],
      1,
      [100],
      false,
      [QuantumScenario.InitialQuantumScenario()],
    )

    return tempClaim
  }

  static toJson(data: IndependentClaim): any {
    return {
      name: data.name,
      type: data.type,
      claimtypeId: data.claimtypeId,
      amount: data.amount,
      isInterest: data.isInterest,
      interestRate: data.interestRate,
      interestDate: data.interestDate,
      liabilityRequirements: data.liabilityRequirements.map((lr) =>
        LiabilityRequirement.toJson(lr),
      ),
      liability: data.liability,
      quantumProbabilities: data.quantumProbabilities,
      hasOutOfCourt: data.hasOutOfCourt,
      quantumScenarios: data.quantumScenarios.map((qs) =>
        QuantumScenario.toJson(qs),
      ),
    }
  }

  static FromJson(data: any): IndependentClaim {
    return new IndependentClaim(
      data.name,
      claimTypeFromString(data.type),
      data.id,
      data.claimtypeId,
      data.amount,
      data.isInterest,
      data.interestRate,
      data.interestDate,
      data.liabilityRequirements.map((lr: any) =>
        LiabilityRequirement.FromJson(lr),
      ),
      data.liability,
      data.quantumProbabilities,
      data.hasOutOfCourt,
      data.quantumScenarios.map((qs: any) => QuantumScenario.FromJson(qs)),
    )
  }
}
