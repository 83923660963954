import deleteBucketSignRed from '../../../../../../../../resources/images/214-deleteSection.svg'
import sortOrderArrowImg from '../../../../../../../../resources/images/212-sortOrderArrow.svg'
import { SectionTitleKeys } from '../../../../../../../../models/reportModels/reportEnums'
import { reportState } from '../../../../../../../../states/ReportState'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { SectionId } from '../../../../../../../../models/treeModels/treeTypes'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  titleKey: SectionTitleKeys
  sectionId: SectionId
  upArrowVisible: boolean
  downArrowVisible: boolean
  setAnimationPair: (
    animationPair: { sectionId: SectionId; sectionHeight: number }[],
  ) => void
  setAnimationDelete: (animationDelete: boolean) => void
}

export default function ReportSectionRemoveMove(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const [report, setReport] = useRecoilState(reportState)
  const sectionFromReport = report.reportData.sections.filter(
    (section) => section.sectionId === props.sectionId,
  )[0]

  function deleteSection() {
    //Mixpanel 147 (All)
    logActivity(freemium.isFreemium, 'Deleted a custom section in the report')
    props.setAnimationDelete(true)
    let tempReport = deepCloneObject(report)
    const sectionIndex = report.reportData.sections.findIndex(
      (section) => section.sectionId === sectionFromReport.sectionId,
    )
    const sectionOrder =
      tempReport.reportData.sections[sectionIndex].sectionOrder
    for (let i = 0; i < tempReport.reportData.sections.length; i++) {
      if (tempReport.reportData.sections[i].sectionOrder >= sectionOrder) {
        tempReport.reportData.sections[i].sectionOrder--
      }
    }
    tempReport.reportData.sections.splice(sectionIndex, 1)
    setTimeout(() => {
      setReport(tempReport)
    }, 400)
  }

  function handleChangeSectionOrder(value: 'up' | 'down') {
    //Mixpanel 145 (All)
    logActivity(freemium.isFreemium, 'Reordered a section in the report', {
      'Reorder from': `Pdf Preview`,
    })
    let tempReport = deepCloneObject(report)
    const sectionIndex = report.reportData.sections.findIndex(
      (section) => section.sectionId === sectionFromReport.sectionId,
    )
    const sectionOrder =
      tempReport.reportData.sections[sectionIndex].sectionOrder
    if (value === 'up') {
      tempReport.reportData.sections[sectionIndex].sectionOrder -= 1
      const upSectionIndex = report.reportData.sections.findIndex(
        (section) => section.sectionOrder === sectionOrder - 1,
      )
      tempReport.reportData.sections[upSectionIndex].sectionOrder += 1
      props.setAnimationPair([
        {
          sectionId: props.sectionId,
          sectionHeight: document.getElementById(
            `reportSectionContainer-${tempReport.reportData.sections[upSectionIndex].title}-${tempReport.reportData.sections[upSectionIndex].sectionId}`,
          )!.offsetHeight,
        },
        {
          sectionId: tempReport.reportData.sections[upSectionIndex].sectionId,
          sectionHeight: document.getElementById(
            `reportSectionContainer-${props.titleKey}-${props.sectionId}`,
          )!.offsetHeight,
        },
      ])
    } else if (value === 'down') {
      tempReport.reportData.sections[sectionIndex].sectionOrder += 1
      const downSectionIndex = report.reportData.sections.findIndex(
        (section) => section.sectionOrder === sectionOrder + 1,
      )
      tempReport.reportData.sections[downSectionIndex].sectionOrder -= 1
      props.setAnimationPair([
        {
          sectionId: tempReport.reportData.sections[downSectionIndex].sectionId,
          sectionHeight: document.getElementById(
            `reportSectionContainer-${props.titleKey}-${props.sectionId}`,
          )!.offsetHeight,
        },
        {
          sectionId: props.sectionId,
          sectionHeight: document.getElementById(
            `reportSectionContainer-${tempReport.reportData.sections[downSectionIndex].title}-${tempReport.reportData.sections[downSectionIndex].sectionId}`,
          )!.offsetHeight,
        },
      ])
    }
    setReport(tempReport)
  }
  return (
    <>
      <div className="reportSectionRemoveMoveContainer">
        <img
          src={deleteBucketSignRed}
          alt="deleteBucketSignRed"
          className={`deleteSectionImg ${
            props.titleKey === SectionTitleKeys.CustomSection ? '' : 'hidden'
          }`}
          onClick={
            props.titleKey === SectionTitleKeys.CustomSection
              ? (e: any) => {
                  deleteSection()
                  e.preventDefault()
                }
              : undefined
          }
        />
        <div className="reportMoveSectionContainer">
          <div className="reportMoveSectionImgContainer">
            <img
              src={sortOrderArrowImg}
              alt="sortOrderArrowImgUp"
              className="moveImgUp"
              style={
                props.upArrowVisible ? undefined : { visibility: 'hidden' }
              }
              onClick={
                props.upArrowVisible
                  ? (e: any) => {
                      handleChangeSectionOrder('up')
                      e.preventDefault()
                    }
                  : undefined
              }
            />
            <img
              src={sortOrderArrowImg}
              alt="sortOrderArrowImgDown"
              className="moveImgDown"
              style={
                props.downArrowVisible ? undefined : { visibility: 'hidden' }
              }
              onClick={
                props.downArrowVisible
                  ? (e: any) => {
                      handleChangeSectionOrder('down')
                      e.preventDefault()
                    }
                  : undefined
              }
            />
          </div>
          <p className="reportMoveSectionText">Move</p>
        </div>
      </div>
    </>
  )
}
