import exitImg from '../../resources/images/272-freemiumExitImg.svg'
import freemiumImg from '../../resources/images/271-freemium.png'
import { FreemiumMessageType } from '../../models/freemiumEnums'
import { getText } from '../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'

type Props = {
  onClose: () => void
  messageType: FreemiumMessageType
}

const FreemiumMessage = (props: Props) => {
  const user = useRecoilValue(userState)

  const onGeneralClick = (e: any) => {
    if (!e.target.id.includes('freemiumPopUp')) {
      props.onClose()
    }
  }

  const getDescription = () => {
    switch (props.messageType) {
      case FreemiumMessageType.General:
        //data-textattribute="message-124"
        return getText('message-124', user.settings)
      case FreemiumMessageType.Add_Case:
        //data-textattribute="message-125"
        return getText('message-125', user.settings)
      case FreemiumMessageType.Add_Scenario:
        //data-textattribute="message-126"
        return getText('message-126', user.settings)
      case FreemiumMessageType.Analyze:
        //data-textattribute="message-127"
        return getText('message-127', user.settings)
    }
  }

  return (
    <div className="freemiumBackgroundPopUp" onClick={onGeneralClick}>
      <div className="freemiumPopUp" id="freemiumPopUp">
        <p
          className="title"
          id="freemiumPopUpTitle"
          data-textattribute="title-252"
        >
          {getText('title-252', user.settings)}
        </p>
        <p className="description" id="freemiumPopUpDescription">
          {getDescription()}
        </p>
        <p
          className="mainContent"
          id="freemiumPopUpMainContent"
          data-textattribute="description-220"
        >
          {getText('description-220', user.settings)}
        </p>
        <div className="contentRow" id="freemiumPopUpRow1">
          <div className="bullet" id="freemiumPopUpBullet1" />
          <p
            className="text"
            id="freemiumPopUpBulletText1"
            data-textattribute="description-221"
          >
            {getText('description-221', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="freemiumPopUpRow2">
          <div className="bullet" id="freemiumPopUpBullet2" />
          <p
            className="text"
            id="freemiumPopUpBulletText2"
            data-textattribute="description-222"
          >
            {getText('description-222', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="freemiumPopUpRow3">
          <div className="bullet" id="freemiumPopUpBullet3" />
          <p
            className="text"
            id="freemiumPopUpBulletText3"
            data-textattribute="description-223"
          >
            {getText('description-223', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="freemiumPopUpRow3">
          <div className="bullet" id="freemiumPopUpBullet3" />
          <p
            className="text"
            id="freemiumPopUpBulletText3"
            data-textattribute="description-223"
          >
            {getText('description-223b', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="freemiumPopUpRow4">
          <div className="bullet" id="freemiumPopUpBullet4" />
          <p
            className="text"
            id="freemiumPopUpBulletText4"
            data-textattribute="description-224"
          >
            {getText('description-224', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="freemiumPopUpRow5">
          <div className="bullet" id="freemiumPopUpBullet5" />
          <p
            className="text"
            id="freemiumPopUpBulletText5"
            data-textattribute="description-225"
          >
            {getText('description-225', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="freemiumPopUpRow6">
          <div className="bullet" id="freemiumPopUpBullet6" />
          <p
            className="text"
            id="freemiumPopUpBulletText6"
            data-textattribute="description-226"
          >
            {getText('description-226', user.settings)}
          </p>
        </div>
        <div className="closeIcon" id="freemiumPopUpClose">
          <img src={exitImg} alt="exitImg" onClick={props.onClose} />
        </div>
        <img
          src={freemiumImg}
          alt="freemiumImg"
          className="freemiumIcon"
          id="freemiumPopUpImg"
        />
        <button
          className="contactButton"
          id="freemiumPopUpContact"
          onClick={() => {
            window.open('https://eperoto.com/pricing/', '_blank')
            props.onClose()
          }}
        >
          <p className="text" data-textattribute="button-156">
            {getText('button-156', user.settings)}
          </p>
        </button>
      </div>
    </div>
  )
}

export default FreemiumMessage
