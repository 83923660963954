import React from 'react'
import Button from '../Buttons/Button/Button'
import './CancelCaseWindow.css'
import { getText } from '../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../states/UserState'
import { AnimationDots } from '../Animations/AnimationDots'
import { ANIMATION_DOTS_SPEED } from '../../services/constants'
type Props = {
  stopAnalysis: () => void
  cancelFunction: () => void
  position: [number, number]
  reference?: React.RefObject<HTMLHeadingElement>
  cancellingMessage?: string
}
export default function CancelCaseWindow(props: Props) {
  const user = useRecoilValue(userState)

  return (
    <>
      <div
        className="deleteMessageContainer"
        style={props.reference ? { position: 'relative' } : undefined}
      >
        <div
          className="deleteMessage"
          style={{
            top: props.position[0],
            left: props.position[1],
            position: 'absolute',
          }}
          ref={props.reference}
        >
          {props.cancellingMessage ? (
            <div className="cancellingMessageContainer">
              {props.cancellingMessage}
              {props.cancellingMessage ===
              getText('message-1', user.settings) ? (
                <AnimationDots speed={ANIMATION_DOTS_SPEED} />
              ) : null}
            </div>
          ) : (
            <>
              <div className="deleteQuestion">
                <p
                  style={{ fontSize: '20px' }}
                  id="stopAnalysisQuestionMessage"
                  data-textattribute="message-2"
                >
                  {getText('message-2', user.settings)}
                </p>
              </div>
              <div className="buttonsContainer">
                <Button
                  id="cancelCaseCancelButton"
                  buttonText={getText('button-1', user.settings)}
                  buttonTextAttribute="button-1"
                  buttonType="text"
                  type="button"
                  className="cancelButton"
                  onClick={props.cancelFunction}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
                <Button
                  id="cancelCaseStopAnalysisButton"
                  buttonText={getText('button-2', user.settings)}
                  buttonTextAttribute="button-2"
                  buttonType="contained"
                  type="button"
                  className="DeleteButton"
                  onMouseUp={() => props.stopAnalysis()}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              </div>
            </>
          )}
        </div>
        <div
          className="deleteMessage-background"
          onMouseUp={props.cancelFunction}
        ></div>
      </div>
    </>
  )
}
