import React, { useEffect, useState } from 'react'
import backCircleImg from '../../../resources/images/134-backLogin.svg'
import OtpInput from './OtpInput'
import CircularProgress from '@mui/material/CircularProgress'
import InputText from '../../../Components/Inputs/InputText/InputText'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  setOtp: (otp: string) => void
  otp: string
  validationMessage: string | undefined
  changeBackupCode?: (index: number, value: string) => void
  backupCodes?: string[]
  setForLostDevice?: Function
  forLostDevice?: boolean
  firstTimeSms?: boolean
  forSms?: boolean
  onBackButton?: () => void
  loading: boolean
  timeFromLastSms: number | undefined
  sendAgainSms: () => void
  errors: string[]
}

const OtpForm = (props: Props) => {
  const user = useRecoilValue(userState)

  //For now typescript can't accept computed values in enums, that is why I changed this enum to an object
  //id='smsTextState'
  const smsTextStateObject = {
    //data-textattribute='message-45'
    not_received: getText('message-45', user.settings),
    //data-textattribute='message-46'
    timer: getText('message-46', user.settings),
    //data-textattribute='message-47'
    new_code: getText('message-47', user.settings),
    //data-textattribute='message-48'
    sent: getText('message-48', user.settings),
  }

  const [smsTextState, setSmsTextState] = useState<string>(
    smsTextStateObject.not_received,
  )

  const onSendSmsAgain = () => {
    if (props.timeFromLastSms === undefined) {
      props.sendAgainSms()
      setSmsTextState(smsTextStateObject.sent)
      setTimeout(() => {
        setSmsTextState(smsTextStateObject.not_received)
      }, 7000)
    } else {
      setSmsTextState(smsTextStateObject.timer)
    }
  }

  useEffect(() => {
    if (
      props.timeFromLastSms === undefined &&
      smsTextState === smsTextStateObject.timer
    ) {
      setSmsTextState(smsTextStateObject.new_code)
    }

    // eslint-disable-next-line
  }, [props.timeFromLastSms])

  const message = () => {
    if (props.validationMessage) {
      if (props.validationMessage.includes('\\n')) {
        const mes1 = props.validationMessage.split('\\n')[0]
        const mes2 = props.validationMessage.split('\\n')[1]

        return (
          <div className="lettersContainer errorClass">
            {mes1}
            <span style={{ color: '#331a5b' }}>{' ' + mes2}</span>
          </div>
        )
      }

      return (
        <div className="lettersContainer errorClass">
          {props.validationMessage}
        </div>
      )
    }
    return (
      <div
        className="lettersContainer"
        id="enterCodeMessage"
        data-textattribute="message-43"
      >
        {getText('message-43', user.settings)}
      </div>
    )
  }

  const backupMessage = () => {
    if (props.validationMessage) {
      return (
        <>
          <p
            className="errorClass"
            id="errorRetrievalCodes"
            data-textattribute="error-67a,error-67b"
          >
            {getText('error-67a', user.settings)}{' '}
            <span>{getText('error-67b', user.settings)}</span>
          </p>
          <p
            className="errorClass"
            id="lostRetrievalCodes"
            data-textattribute="error-68a,error-68b, error-68c"
          >
            {getText('error-68a', user.settings)}
            <br />
            {getText('error-68b', user.settings)}{' '}
            <a className="mailTo" href="mailto: support@eperoto.com">
              {getText('error-68c', user.settings)}
            </a>
          </p>
        </>
      )
    } else {
      return (
        <>
          <p id="enterRetrievalCodes" data-textattribute="message-44">
            {getText('message-44', user.settings)}
          </p>
          <p
            id="lostRetrievalCodes"
            data-textattribute="error-68a,error-68b, error-68c"
          >
            {getText('error-68a', user.settings)}
            <br />
            {getText('error-68b', user.settings)}{' '}
            <a className="mailTo" href="mailto: support@eperoto.com">
              {getText('error-68c', user.settings)}
            </a>
          </p>
        </>
      )
    }
  }

  return (
    <>
      {(props.forLostDevice || props.firstTimeSms) && (
        <img
          className="backImg"
          src={backCircleImg}
          alt="backImg"
          onClick={props.onBackButton}
        />
      )}
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="otpFormTitle"
          data-textattribute={
            props.forLostDevice
              ? 'title-112'
              : props.forSms
              ? 'title-113'
              : 'title-114'
          }
        >
          {props.forLostDevice
            ? getText('title-112', user.settings)
            : props.forSms
            ? getText('title-113', user.settings)
            : getText('title-114', user.settings)}
        </h2>
        <div
          className="instructions"
          id="otpFormInstructions"
          data-textattribute={
            props.forLostDevice
              ? ''
              : props.forSms
              ? 'description-85'
              : 'description-86'
          }
        >
          {props.forLostDevice
            ? backupMessage()
            : props.forSms
            ? getText('description-85', user.settings)
            : getText('description-86', user.settings)}
        </div>
      </div>

      <div className="inputsContainer">
        {props.forLostDevice ? (
          <div className="lower">
            {[0, 1, 2].map((index) => (
              <div className="inputCode" key={`Code ${index + 1}`}>
                <InputText
                  label={`${getText('label-57', user.settings)} ${index + 1}`}
                  labelTextAttribute="label-57"
                  id={`otp${index}`}
                  name={`otp${index}`}
                  width={470}
                  onChange={(e: any) =>
                    props.changeBackupCode!(index, e.target.value)
                  }
                  value={props.backupCodes![index]}
                  helperText={getText('helperText-5', user.settings)}
                  helperTextAttribute="helperText-5"
                  error={
                    props.errors.includes(`otp${index}`) ||
                    props.validationMessage
                      ? true
                      : false
                  }
                  tabIndex={index + 1}
                  errorMessage={
                    props.errors.includes(`otp${index}`)
                      ? getText('error-43', user.settings)
                      : ' '
                  }
                  errorTextAttribute={
                    props.errors.includes(`otp${index}`)
                      ? 'error-43'
                      : 'error-nothing'
                  }
                  alwaysShrink
                  noDelay
                />
              </div>
            ))}
          </div>
        ) : (
          <>
            <div className="inputsContainer">
              <div className="inputsCenterContainer">
                {message()}
                <OtpInput setOtp={props.setOtp} otp={props.otp} />
                <div className="forgotContainer">
                  {props.firstTimeSms ? (
                    <p
                      id="noCodeMessage"
                      data-textattribute="message-40a, message-40b"
                    >
                      {getText('message-40a', user.settings)}{' '}
                      <span
                        className="forgot"
                        onClick={() => props.onBackButton!()}
                      >
                        {getText('message-40b', user.settings)}
                      </span>
                    </p>
                  ) : props.forSms ? (
                    <>
                      {props.timeFromLastSms &&
                      smsTextState === smsTextStateObject.timer ? (
                        <p className="centered">
                          {props.timeFromLastSms >= 10
                            ? smsTextState + ` 0:${props.timeFromLastSms}`
                            : smsTextState + ` 0:0${props.timeFromLastSms}`}
                        </p>
                      ) : smsTextState === smsTextStateObject.not_received ||
                        smsTextState === smsTextStateObject.new_code ? (
                        <p className="forgot centered" onClick={onSendSmsAgain}>
                          {smsTextState}
                        </p>
                      ) : (
                        <p className="centered">{smsTextState}</p>
                      )}
                      {props.setForLostDevice && (
                        <p
                          className="forgot centered"
                          onClick={() => props.setForLostDevice!(true)}
                          style={{ marginTop: '60px' }}
                          id="noAccessToMobileMessage"
                          data-textattribute="message-41"
                        >
                          {getText('message-41', user.settings)}
                        </p>
                      )}
                    </>
                  ) : (
                    props.setForLostDevice && (
                      <p
                        className="forgot"
                        onClick={() => props.setForLostDevice!(true)}
                        id="lostDeviceMessage"
                        data-textattribute="message-42"
                      >
                        {getText('message-42', user.settings)}
                      </p>
                    )
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="submitButtonContainer">
        <button
          type="submit"
          className="submitButton"
          disabled={props.loading}
          id="submitOtpButton"
          data-textattribute="button-54"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : (
            getText('button-54', user.settings)
          )}
        </button>
      </div>
    </>
  )
}

export default OtpForm
