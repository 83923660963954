import { CircularProgress } from '@mui/material'
import React, { FormEvent, useState } from 'react'
import InfoClickComponent from '../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import DropDown from '../../../../../Components/Inputs/DropDown/DropDown'
import InputText from '../../../../../Components/Inputs/InputText/InputText'
import {
  RequestError,
  UserRole,
  UserRoleKeys,
  userRoleStringToKeyString,
} from '../../../../../models/enums'
import { dropDownValidation } from '../../../../../Modules/AppModules/ValidationFunctions'
import { addSSOUser, addUser } from '../../../../../services/requests'
import { getText } from '../../../../../services/textFunctions'
import greenTick from '../../../../../resources/images/191-greenTick.svg'
import {
  findHandlingErrorState,
  getFullUserRolesAndDescriptions,
  getFullUserRolesAndDescriptionsTetxAttributes,
  getUserRoleDescription,
  logActivity,
} from '../../../../../services/commonFunctions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'
import { SubscriptionPlan } from '../../../../../models/generalTypes'
import { handlingErrorsState } from '../../../../../states/HandlingErrorsState'
import { featuresState } from '../../../../../states/FeaturesState'

type Props = {
  loading: boolean
  setLoading: (value: boolean) => void
  hoverElement: string
  setHoverElement: (value: string) => void
  addUser: (userData: any) => void
  subscriptionPlan: SubscriptionPlan
}

const CreateUser = (props: Props) => {
  const user = useRecoilValue(userState)
  const features = useRecoilValue(featuresState)
  const [handlingErrors, setHandlingErrors] =
    useRecoilState(handlingErrorsState)
  const emailPattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [userRole, setUserRole] = useState(UserRole.SIMPLE_USER.toString())
  const [success, setSuccess] = useState(false)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [errors, setErrors] = useState<string[]>([])

  const validation = () => {
    let numOfErrors = 0
    const newErrors: string[] = []

    if (!email) {
      numOfErrors++
      newErrors.push('email')
    } else if (!emailPattern.test(email)) {
      numOfErrors++
      newErrors.push('email:validation')
    }

    if (!firstName && features.use_microsoft_sso === false) {
      numOfErrors++
      newErrors.push('firstName')
    }

    if (!lastName && features.use_microsoft_sso === false) {
      numOfErrors++
      newErrors.push('lastName')
    }

    setErrors([...newErrors])
    return numOfErrors === 0
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!validation()) {
      return
    }

    props.setLoading(true)
    setEmailError(undefined)

    let res

    if (features.use_microsoft_sso) {
      res = await addSSOUser({
        email: email,
        role: userRoleStringToKeyString(userRole),
      })
    } else {
      res = await addUser({
        email: email,
        first_name: firstName,
        last_name: lastName,
        role: userRoleStringToKeyString(userRole),
      })
    }

    if (!('errorCode' in res)) {
      props.addUser(res.data)
      setSuccess(true)
      //Mixpanel 104
      logActivity(
        false,
        `Created new user with role: ${userRole} in Add New User pop up`,
      )
    } else {
      if (res.errorCode === RequestError.BAD_REQUEST) {
        setEmailError(res.message)
      } else {
        setHandlingErrors(
          findHandlingErrorState(res, handlingErrors, 'addUser'),
        )
      }
    }

    props.setLoading(false)
  }

  const getAvailableOptions = () => {
    const availableOptions = [
      UserRole.APP_VIEWER.toString(),
      UserRole.SIMPLE_USER.toString(),
      UserRole.COMPANY_ADMIN.toString(),
    ]
    if (
      user.role === UserRole.GLOBAL_ADMIN ||
      user.role === UserRole.EPEROTO_ADMIN
    ) {
      availableOptions.push(UserRole.GLOBAL_ADMIN.toString())
    }

    return availableOptions
  }

  const checkFullUsers = () => {
    return (
      props.subscriptionPlan.allowed_number_of_users <=
        props.subscriptionPlan.number_of_users &&
      userRole !== UserRole.APP_VIEWER.toString()
    )
  }

  const checkFullViewers = () => {
    return (
      props.subscriptionPlan.allowed_number_of_spectators <=
        props.subscriptionPlan.number_of_spectators &&
      userRole === UserRole.APP_VIEWER.toString()
    )
  }

  return (
    <form className="form" onSubmit={onSubmit}>
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="addNewUserTitle"
          data-textattribute="title-180"
        >
          {getText('title-180', user.settings)}
        </h2>
      </div>
      <div className="inputsContainer">
        <div className="inputResetPassword1 for-settings">
          <InputText
            label={getText('label-80', user.settings)}
            labelTextAttribute="label-80"
            id="email"
            width={470}
            onChange={(e: any) => {
              setEmail(e.target.value)
            }}
            value={email}
            autoComplete
            name="email"
            error={
              errors.includes('email') ||
              errors.includes('email:validation') ||
              emailError
                ? true
                : false
            }
            errorMessage={
              errors.includes('email:validation')
                ? getText('error-38', user.settings)
                : emailError
                ? emailError
                : getText('error-79', user.settings)
            }
            errorTextAttribute={
              errors.includes('email:validation')
                ? 'error-38'
                : emailError
                ? 'error-00'
                : 'error-79'
            }
            tabIndex={1}
            alwaysShrink
            noDelay
          />
        </div>
        {features.use_microsoft_sso === false && (
          <div className="inputResetPassword2 for-settings">
            <InputText
              label={getText('label-81', user.settings)}
              labelTextAttribute="label-81"
              id="firstName"
              width={470}
              onChange={(e: any) => {
                setFirstName(e.target.value)
              }}
              value={firstName}
              autoComplete
              name="firstName"
              error={errors.includes('firstName')}
              errorMessage={getText('error-80', user.settings)}
              errorTextAttribute={'error-80'}
              tabIndex={2}
              alwaysShrink
              noDelay
            />
          </div>
        )}
        {features.use_microsoft_sso === false && (
          <div className="inputResetPassword2 for-settings">
            <InputText
              label={getText('label-82', user.settings)}
              labelTextAttribute="label-82"
              id="lastName"
              width={470}
              onChange={(e: any) => {
                setLastName(e.target.value)
              }}
              value={lastName}
              autoComplete
              name="lastName"
              error={errors.includes('lastName')}
              errorMessage={getText('error-81', user.settings)}
              errorTextAttribute={'error-81'}
              tabIndex={3}
              alwaysShrink
              noDelay
            />
          </div>
        )}
        <div className="inputResetPassword3 for-settings">
          <DropDown
            id={'userRole'}
            name={'userRole'}
            label={getText('label-74', user.settings)}
            labelTextAttribute={'label-74'}
            value={userRole}
            width={440}
            options={getAvailableOptions()}
            validateFunction={(value: string) => dropDownValidation(value)}
            onChange={(e: any) => {
              setUserRole(e.target.value)
            }}
            helperText={getText(
              getUserRoleDescription(
                UserRole[userRoleStringToKeyString(userRole) as UserRoleKeys],
              ),
              user.settings,
            )}
            helperTextAttribute={getUserRoleDescription(
              UserRole[userRoleStringToKeyString(userRole) as UserRoleKeys],
            )}
            tabIndex={4}
          />
          <div
            style={{
              marginTop: userRole === 'Simple User' ? '-25px' : '-70px',
            }}
          >
            <InfoClickComponent
              infoClickText={''}
              infoClickTextAttribute={undefined}
              idName={'userRole'}
              hoverElement={props.hoverElement}
              setHoverElement={props.setHoverElement}
              withTitles
              //pass getText() in titlesAndTexts
              titlesAndTexts={getFullUserRolesAndDescriptions()}
              titlesAndTextsAttributes={getFullUserRolesAndDescriptionsTetxAttributes()}
            />
          </div>
        </div>
      </div>
      <div style={{ height: '50px' }}></div>
      <div className="submitButtonContainer withHelper">
        {success ? (
          <div
            id="successfullyCreatedAccountText"
            data-textattribute="message-82"
          >
            <img
              src={greenTick}
              alt="greenTick"
              style={{ marginRight: '15px' }}
            />
            {getText('message-82', user.settings)}
          </div>
        ) : (
          <>
            <button
              type="submit"
              className={`submitButton ${
                checkFullUsers() || checkFullViewers() ? 'disabledButton' : ''
              }`}
              disabled={props.loading || checkFullUsers() || checkFullViewers()}
              id="createNewUserButton"
              data-textattribute="button-114"
            >
              {props.loading ? (
                <CircularProgress size={22} />
              ) : (
                getText('button-114', user.settings)
              )}
            </button>
            {(checkFullUsers() || checkFullViewers()) && (
              <div
                className="helperText"
                id="numberOfAccountsHelperText"
                data-textattribute={
                  checkFullUsers() ? 'description-135' : 'description-136'
                }
              >
                {checkFullUsers()
                  ? `${props.subscriptionPlan.number_of_users}/${
                      props.subscriptionPlan.allowed_number_of_users
                    } ${getText('description-135', user.settings)}`
                  : `${props.subscriptionPlan.allowed_number_of_spectators}/${
                      props.subscriptionPlan.allowed_number_of_spectators
                    } ${getText('description-136', user.settings)}`}
              </div>
            )}
          </>
        )}
      </div>
    </form>
  )
}

export default CreateUser
