import React from 'react'
import { WhiteTooltip } from '../../../../../../../Components/tooltip/TooltipComponent'

type Props = {
  treeIndex: number
  thumbnail: string
  id: string
  selected: boolean
  onClick: () => void
  textAttribute: `tooltip-${number}`
  tooltipText: string
}

export default function TreeSideMenuButton(props: Props) {
  return (
    <WhiteTooltip
      title={props.tooltipText}
      textAttribute={props.textAttribute}
      id={`treeSideMenuTooltip-${props.id}`}
      placement="right-end"
    >
      <div
        className={`treeSideMenuButton ${props.selected ? 'selected' : ''}`}
        id={`treeSideMenuButton-${props.treeIndex}_${props.id}`}
        onClick={props.onClick}
      >
        <img
          src={props.thumbnail}
          alt="sideMenuButtonImg"
          className="treeSideMenuButtonImg"
          id={`treeSideMenuButtonImg-${props.treeIndex}_${props.id}`}
          style={props.id === 'pasteTreeNodes' ? { width: '20px' } : {}}
        />
      </div>
    </WhiteTooltip>
  )
}
