import React, { useEffect } from 'react'
import Button from '../Buttons/Button/Button'
import useWindowSize from '../../customHooks/useWindowSize'

type Props = {
  backGroundColor: string
  message: string
  messageTextAttribute: `message-${number}`
  clickableMessage?: string
  clickableMessageTextAttribute?: `message-${number}`
  buttonText?: string
  buttonTextAttribute?: `button-${number}`
  image?: string
  hoverColor?: string
  onClick?: () => void
  onButtonClick?: () => void
  onMessageClick?: () => void
  shaking?: boolean
  removeShaking?: () => void
  color?: string
  bold?: boolean
  id: string
}

export default function LowScreenMessage(props: Props) {
  const windowSize = useWindowSize()

  useEffect(() => {
    setTimeout(() => {
      if (props.removeShaking) {
        props.removeShaking()
      }
    }, 300)

    // eslint-disable-next-line
  }, [props.shaking])

  return (
    <>
      <div className="lowScreenMessageContainer">
        <div
          className={`lowScreenMessageBox ${props.hoverColor} ${
            props.shaking ? 'shake' : undefined
          } ${props.buttonText ? 'no-margin' : undefined} ${
            props.clickableMessage ? 'non-clickable' : ''
          }`}
          style={{ backgroundColor: props.backGroundColor }}
          onClick={props.onClick}
        >
          {props.image ? (
            <div className="lowScreenMessageImgContainer">
              <img
                src={props.image}
                alt="lowScreenMessageImg"
                className="lowScreenMessageImg"
              />
            </div>
          ) : null}
          <div
            className={`lowScreenMessageTextContainer ${
              props.clickableMessage ? 'non-clickable' : ''
            }`}
          >
            <p
              className={`lowScreenMessageText ${
                props.clickableMessage ? 'non-clickable' : ''
              }`}
              style={{
                color: props.color,
                fontWeight: props.bold ? 'bold' : '',
                fontSize: windowSize.width <= 1000 ? '12px' : undefined,
              }}
              data-textattribute={props.messageTextAttribute}
              id={`lowScreenMessageText-${props.id}`}
            >
              {props.message}
            </p>
            {props.clickableMessage && (
              <p
                onClick={props.onMessageClick}
                className="lowScreenMessageText"
                style={{
                  color: '#FF703E',
                  marginLeft: '5px',
                  textDecoration: 'underline',
                  fontSize: windowSize.width <= 1000 ? '12px' : undefined,
                }}
                data-textattribute={props.messageTextAttribute}
                id={`lowScreenClickableMessageText-${props.id}`}
              >
                {props.clickableMessage}
              </p>
            )}
          </div>
        </div>
        {props.buttonText && (
          <Button
            id={'keepThisVersionButton'}
            buttonText={props.buttonText}
            buttonTextAttribute={props.buttonTextAttribute}
            type={'button'}
            buttonType="contained"
            style={{ height: '41px' }}
            onClick={props.onButtonClick}
            className={`${props.shaking ? 'shake' : ''}`}
            side="left"
            NoUpperCase={true}
            small={false}
          />
        )}
      </div>
    </>
  )
}
