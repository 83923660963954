import { UserSettings } from '../../models/userSettings'
import { roundNumberTo } from '../../services/commonFunctions'
import { formattedNumToString } from '../../services/formatNum'
import { stringAndRoundTo2Decimals } from './AppFunctions'

export function stringAndRoundBigNumbers(
  value: number,
  settings: UserSettings,
  roundedResults: boolean,
  disputedAmount: number,
  absoluteValue?: boolean,
  isPercentage?: boolean,
) {
  if (roundedResults) {
    if (isPercentage) {
      if (value < 1 || value > 99) {
        return formattedNumToString(roundNumberTo(value, 1), settings)
      } else {
        return formattedNumToString(roundNumberTo(value, 0), settings)
      }
    }
    if (absoluteValue) {
      return formattedNumToString(
        roundToNearestCustom(Math.abs(value), disputedAmount),
        settings,
      )
    } else {
      return formattedNumToString(
        roundToNearestCustom(value, disputedAmount),
        settings,
      )
    }
  } else {
    return stringAndRoundTo2Decimals(value, settings, absoluteValue)
  }
}

function roundToNearestCustom(number: number, disputedAmount: number) {
  /**
   * Rounds a given number to the nearest significant number.
   * Numbers below 10,000 are returned as is, rounded to the nearest integer,
   * unless they are less than 10, in which case they are rounded to 1 decimal place.
   * For numbers less than 1, it ensures that at least one significant digit is shown.
   * This function also correctly handles negative numbers.
   */
  const absNumber = Math.abs(Math.round(number))
  const numberOfdigits = JSON.stringify(absNumber).length

  if (absNumber >= 10) {
    return (
      Math.round(number / 10 ** (numberOfdigits - 2)) *
      10 ** (numberOfdigits - 2)
    )
  } else {
    return roundNumberTo(number, 1)
  }
}
