import React from 'react'
import { getText } from '../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../states/UserState'
import termsImg from '../../../../../resources/images/163-termsOfUse.svg'
import privacyImg from '../../../../../resources/images/161-privacy-policy.svg'
import cookiesImg from '../../../../../resources/images/154-cookie-policy.svg'
import { freemiumState } from '../../../../../states/FreemiumState'
import { logActivity } from '../../../../../services/commonFunctions'

const TermsOfUse = () => {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  return (
    <div className="termsContainer">
      <img src={termsImg} alt="termsImg" />
      <a
        className="termsText"
        onClick={() => {
          //Mixpanel 22a (All)
          logActivity(freemium.isFreemium, "Pressed 'Terms of use'")
        }}
        href={
          freemium.isFreemium
            ? 'https://eperoto.com/starter-terms/'
            : 'https://www.eperoto.com/terms-of-use'
        }
        target="_blank"
        rel="noreferrer"
        data-textattribute="title-24"
        id="helpMenu-termsOfUse"
      >
        {getText('title-24', user.settings)}
      </a>
      <img src={privacyImg} alt="privacyImg" />
      <a
        className="termsText"
        onClick={() => {
          //Mixpanel 23 (All)
          logActivity(freemium.isFreemium, "Pressed 'Privacy Policy'")
        }}
        href="https://www.eperoto.com/privacy-policy"
        target="_blank"
        rel="noreferrer"
        data-textattribute="title-25"
        id="helpMenu-privacyPolicy"
      >
        {getText('title-25', user.settings)}
      </a>
      <img src={cookiesImg} alt="cookiesImg" />
      <a
        className="termsText"
        onClick={() => {
          //Mixpanel 24 (All)
          logActivity(freemium.isFreemium, "Pressed 'Cookies Policy'")
        }}
        href="https://www.eperoto.com/cookies-policy"
        target="_blank"
        rel="noreferrer"
        data-textattribute="title-26"
        id="helpMenu-cookiesPolicy"
      >
        {getText('title-26', user.settings)}
      </a>
    </div>
  )
}

export default TermsOfUse
