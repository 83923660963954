export class UserChecklist {
  introVideo: boolean
  accessTutorial: boolean
  createCase: boolean
  analyzeScenario: boolean
  downloadReport: boolean

  constructor(
    introVideo: boolean,
    accessTutorial: boolean,
    createCase: boolean,
    analyzeScenario: boolean,
    downloadReport: boolean,
  ) {
    this.introVideo = introVideo
    this.accessTutorial = accessTutorial
    this.createCase = createCase
    this.analyzeScenario = analyzeScenario
    this.downloadReport = downloadReport
  }

  static FromJson(checklist_data?: any): UserChecklist {
    if (checklist_data) {
      return {
        introVideo: checklist_data.intro_video,
        accessTutorial: checklist_data.access_tutorial,
        createCase: checklist_data.create_case,
        analyzeScenario: checklist_data.analyze_scenario,
        downloadReport: checklist_data.download_report,
      }
    }

    return {
      introVideo: false,
      accessTutorial: false,
      createCase: false,
      analyzeScenario: false,
      downloadReport: false,
    }
  }

  static ToJson(checklist: UserChecklist) {
    return {
      intro_video: checklist.introVideo,
      access_tutorial: checklist.accessTutorial,
      create_case: checklist.createCase,
      analyze_scenario: checklist.analyzeScenario,
      download_report: checklist.downloadReport,
    }
  }
}
