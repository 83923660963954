import React, { useState } from 'react'
import { useRecoilValue } from 'recoil'
import Button from '../../../../../Components/Buttons/Button/Button'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import SettingInfoRow from '../../components/SettingInfoRow'
import ChangeTwoFactorAuth from './ChangeTwoFactorAuth'
import ShowRetrievalCodes from './ShowRetrievalCodes'
import { logActivity } from '../../../../../services/commonFunctions'

const TwoFactorAuth = () => {
  const user = useRecoilValue(userState)
  const [showBackupCodesPage, setShowBackupCodesPage] = useState(false)
  const [showChangeAuthPage, setShowChangeAuthPage] = useState(false)
  const deviceTypeText = user.tfaType
    ? user.tfaType === 'totp'
      ? //data-textattribute = 'message-68'
        getText('message-68', user.settings)
      : user.tfaType === 'sms'
      ? //data-textattribute = 'message-69'
        getText('message-69', user.settings)
      : ''
    : ''

  const onClose = () => {
    setShowBackupCodesPage(false)
    setShowChangeAuthPage(false)
  }

  return (
    <>
      <h1
        className="tool-component-second-title for-settings marginLeft4"
        id="twoFactorAuthTitle"
        data-textattribute="title-176"
      >
        {getText('title-176', user.settings)}
      </h1>
      <SettingInfoRow
        id="showBackupCodesInfo"
        descriptionText={deviceTypeText}
        descriptionTextAttribute={
          user.tfaType
            ? user.tfaType === 'totp'
              ? 'message-68'
              : user.tfaType === 'sms'
              ? 'message-69'
              : undefined
            : undefined
        }
        valueText={getText('message-70', user.settings)}
        valueTextAttribute={'message-70'}
        isClickable
        onClick={() => {
          setShowBackupCodesPage(true)
          //Mixpanel 97
          logActivity(
            false,
            'Pressed "Show retrieval codes" in Account Details',
          )
        }}
        isFirst
      />
      <div className="tool-component-input-container marginLeft3">
        <Button
          id="changeDeviceOrSmsNumber"
          buttonText={getText('button-95', user.settings)}
          buttonTextAttribute={'button-95'}
          buttonType="outlined"
          className="createCaseButton"
          type="button"
          onClick={() => {
            setShowChangeAuthPage(true)
            //Mixpanel 98
            logActivity(
              false,
              'Pressed "CHANGE AUTHENTICATION METHOD" in Account Details',
            )
          }}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      </div>
      {showBackupCodesPage && <ShowRetrievalCodes onClose={onClose} />}
      {showChangeAuthPage && <ChangeTwoFactorAuth onClose={onClose} />}
    </>
  )
}

export default TwoFactorAuth
