import { useRecoilValue } from 'recoil'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import removeImg from '../../../../../../../resources/images/201-deleteBucketWIthBorder.svg'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import {
  AmountSignum,
  PartySignum,
  TypeOfInstance,
} from '../../../../../../../models/enums'
import { useXarrow } from 'react-xarrows'
import { useState } from 'react'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import {
  logActivity,
  roundNumberTo,
} from '../../../../../../../services/commonFunctions'
import addAwardedAmountGray from '../../../../../../../resources/images/146-addAwardedAmount.svg'
import addAwardedAmountPurple from '../../../../../../../resources/images/149-addEvent.svg'
import InputNum from '../../../../../../../Components/Inputs/InputNum/InputNum'
import SwitchButton from '../../../../../../../Components/Buttons/SwitchButton/SwitchButton'
import tickedImg from '../../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../../resources/images/018-untickedGrayBlue.svg'
import InputDate from '../../../../../../../Components/Inputs/InputDate/InputDate'
import PremiumFeatureIcon from '../../../../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import Symbol from '../../../../../../../Components/symbol/Symbol'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
  KROGERUS_OPPOSING_PARTY_COLOR,
  OPPOSING_PARTY_COLOR,
} from '../../../../../../../services/constants'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  active: boolean
  highlighted: boolean
  nodeHasAtLeastOneInterestMenuOn: boolean
  nodeHasAtLeastOneAwardedAmount: boolean
  treeIndex: number
  nodeId: NodeId
  eventIndex: number
  eventDetails: EventDetails
  editMode: NodeId | undefined
  errors: string[]
  nodeSelected: boolean
  startAnimation: boolean
  eventId: EventId
  handleChangeTreeEventDetail: (
    targetId: string,
    eventDetailKey: keyof EventDetails,
    value:
      | never
      | AmountSignum
      | PartySignum
      | string
      | number
      | boolean
      | undefined
      | Date,
  ) => void
  setEditMode: (editMode: NodeId | undefined) => void
}

export default function EventAwardedAmountContainer(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const freemium = useRecoilValue(freemiumState)
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  const updateXarrow = useXarrow()

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]
  const [awardedAmountButtonHover, setAwardedAmountButtonHover] =
    useState(false)
  const [showRemoveAwardedAmount, setShowRemoveAwardedAmount] = useState(false)

  return (
    <>
      <div
        className={`treeEventAwardedAmountContainer ${
          props.nodeSelected || props.highlighted ? 'selected' : ''
        } ${props.active ? 'active' : ''} ${
          props.startAnimation ? 'fadeout' : ''
        } ${
          !props.nodeHasAtLeastOneInterestMenuOn &&
          props.nodeHasAtLeastOneAwardedAmount
            ? 'noInterest'
            : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventAwardedAmountContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        {props.eventDetails.awardedAmountSignum === AmountSignum.off ? (
          <>
            <div className="treeEventAddSectionContainer">
              <div
                className={
                  awardedAmountButtonHover
                    ? 'treeEventAddSectionButton darkButton'
                    : 'treeEventAddSectionButton'
                }
                onMouseEnter={() => setAwardedAmountButtonHover(true)}
                onMouseLeave={() => setAwardedAmountButtonHover(false)}
                onClick={(e: any) => {
                  setAwardedAmountButtonHover(false)

                  props.handleChangeTreeEventDetail(
                    e.target.id,
                    'awardedAmountSignum',
                    AmountSignum.get,
                  )
                  //Mixpanel 53 (All)
                  logActivity(
                    freemium.isFreemium,
                    'Added awarded amount in legal tree',
                  )
                  setTimeout(() => {
                    if (
                      document.getElementById(
                        `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                      )
                    ) {
                      document
                        .getElementById(
                          `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        )!
                        .focus()
                      props.setEditMode(props.nodeId)
                    }
                  }, 200)
                }}
                id={`treeEventAddSectionButtonAwardedAmount-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                <img
                  src={
                    awardedAmountButtonHover
                      ? addAwardedAmountPurple
                      : addAwardedAmountGray
                  }
                  alt="plusImg"
                  className="treeEventAddSectionButtonImg"
                  id={`treeEventAddSectionButtonAwardedAmountImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                />
                <p
                  className={
                    awardedAmountButtonHover
                      ? 'treeEventAddSectionButtonText dark'
                      : 'treeEventAddSectionButtonText'
                  }
                  id={`treeEventAddSectionButtonAwardedAmountText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  data-textattribute="button-82"
                >
                  {getText('button-82', user.settings)}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="treeEventAmountInterestSection"
              id={`treeEventAmountInterestSection-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              {props.eventDetails.awardedAmountSignum === AmountSignum.get ||
              props.eventDetails.awardedAmountSignum === AmountSignum.pay ? (
                <>
                  <div
                    className="treeEventAmountSection"
                    onMouseEnter={() => setShowRemoveAwardedAmount(true)}
                    onMouseLeave={() => setShowRemoveAwardedAmount(false)}
                  >
                    <InputNum
                      label={
                        props.eventDetails.awardedAmountSignum === 'get'
                          ? getText('label-25', user.settings, partiesFormat)
                              .length > 27
                            ? getText('label-25', user.settings, partiesFormat)
                                .substring(0, 27)
                                .trim() + '...'
                            : getText('label-25', user.settings, partiesFormat)
                          : getText('label-26', user.settings, partiesFormat)
                              .length > 27
                          ? getText('label-26', user.settings, partiesFormat)
                              .substring(0, 27)
                              .trim() + '...'
                          : getText('label-26', user.settings, partiesFormat)
                      }
                      labelTextAttribute={
                        props.eventDetails.awardedAmountSignum === 'get'
                          ? 'label-25'
                          : 'label-26'
                      }
                      stickySuffix={<></>}
                      value={props.eventDetails.awardedAmount}
                      defaultZero
                      localStyle={{
                        color:
                          props.eventDetails.awardedAmountSignum ===
                          AmountSignum.get
                            ? krogerusColors
                              ? KROGERUS_CLIENT_COLOR
                              : CLIENT_COLOR
                            : krogerusColors
                            ? KROGERUS_OPPOSING_PARTY_COLOR
                            : OPPOSING_PARTY_COLOR,
                      }}
                      maxValue={99999999999}
                      maxNumberOfDecimals={3}
                      smallField
                      id={`treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      width={212}
                      onChange={(e: any) =>
                        props.handleChangeTreeEventDetail(
                          e.target.id,
                          'awardedAmount',
                          e.target.value,
                        )
                      }
                      alwaysShrink
                      onFocus={() => {
                        setTimeout(() => {
                          updateXarrow()
                        }, 100)
                      }}
                      name={`treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      className="treeEventAwardedAmountInput robotoNumbers"
                      suffix={scenarioSnapshot.currentSnapshot.currency}
                      prefix={
                        <div
                          style={{ width: '8px' }}
                          onClick={() =>
                            document
                              .getElementById(
                                `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                              )!
                              .focus()
                          }
                        >
                          <Symbol
                            symbolType={
                              props.eventDetails.awardedAmountSignum ===
                              AmountSignum.get
                                ? 'client'
                                : 'opposingParty'
                            }
                            targetId={`treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            targetType={'treeAwardedInput'}
                          />
                        </div>
                      }
                      tabIndex={10 * props.eventIndex + 3}
                    />
                    {showRemoveAwardedAmount ? (
                      <>
                        <div
                          className="treeEventRemoveSectionContainer"
                          id={`treeEventRemoveAmountInterestSectionContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                          onClick={(e: any) => {
                            props.handleChangeTreeEventDetail(
                              e.target.id,
                              'awardedAmountSignum',
                              AmountSignum.off,
                            )
                            //Mixpanel 54 (All)
                            logActivity(
                              freemium.isFreemium,
                              'Removed awarded amount in legal tree',
                            )
                          }}
                        >
                          <img
                            src={removeImg}
                            alt="removeBigImg"
                            className="treeEventRemoveSectionImg"
                            id={`treeEventRemoveAmountInterestSectionImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                  {props.editMode === props.nodeId ? (
                    <div
                      className="treeEventAwardSignumSection"
                      id={`treeEventAwardSignumSection-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    >
                      <SwitchButton
                        width="212px"
                        label1={
                          getText(
                            'switchButton-5',
                            user.settings,
                            partiesFormat,
                          ).length > 26
                            ? getText(
                                'switchButton-5',
                                user.settings,
                                partiesFormat,
                              )
                                .substring(0, 26)
                                .trim() + '...'
                            : getText(
                                'switchButton-5',
                                user.settings,
                                partiesFormat,
                              )
                        }
                        label1TextAttribute="switchButton-5"
                        label2={
                          getText(
                            'switchButton-6',
                            user.settings,
                            partiesFormat,
                          ).length > 26
                            ? getText(
                                'switchButton-6',
                                user.settings,
                                partiesFormat,
                              )
                                .substring(0, 26)
                                .trim() + '...'
                            : getText(
                                'switchButton-6',
                                user.settings,
                                partiesFormat,
                              )
                        }
                        label2TextAttribute="switchButton-6"
                        onClick={(num: number) => {
                          if (num === 1) {
                            props.handleChangeTreeEventDetail(
                              `treeEventAwardedAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                              'awardedAmountSignum',
                              AmountSignum.get,
                            )
                          } else {
                            props.handleChangeTreeEventDetail(
                              `treeEventAwardedAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                              'awardedAmountSignum',
                              AmountSignum.pay,
                            )
                          }
                          if (
                            document.getElementById(
                              `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                            )
                          ) {
                            document
                              .getElementById(
                                `treeEventAwardedAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                              )!
                              .focus()
                          }
                        }}
                        backgroundColor1="white"
                        backgroundColor2="white"
                        color1={
                          krogerusColors ? KROGERUS_CLIENT_COLOR : CLIENT_COLOR
                        }
                        color2={
                          krogerusColors
                            ? KROGERUS_OPPOSING_PARTY_COLOR
                            : OPPOSING_PARTY_COLOR
                        }
                        id={`treeEventAwardedAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        id1={`treeEventAwardedAmountSignumSwitch1-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        id2={`treeEventAwardedAmountSignumSwitch2-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        defaultState={
                          props.eventDetails.awardedAmountSignum ===
                          AmountSignum.get
                            ? 1
                            : 2
                        }
                        marginLabel1={
                          getText(
                            'switchButton-5',
                            user.settings,
                            partiesFormat,
                          ).length > 19
                            ? '0 0 0 40px'
                            : '0 0 0 20px'
                        }
                        marginLabel2={
                          getText(
                            'switchButton-6',
                            user.settings,
                            partiesFormat,
                          ).length > 19
                            ? '0 30px 0 10px'
                            : '0 30px 0 10px'
                        }
                        inTree
                      />
                    </div>
                  ) : null}
                  <div
                    id={`treeEventInterestSection-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                    className={`treeEventInterestSection ${
                      props.editMode === props.nodeId ? 'editMode' : ''
                    }`}
                  >
                    {props.editMode === props.nodeId ? (
                      <>
                        <div
                          className="treeEventIncludeInterestButtonContainer"
                          id={`treeEventIncludeInterestButtonContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        >
                          <img
                            src={
                              props.eventDetails.hasInterest
                                ? tickedImg
                                : unTickedImg
                            }
                            alt="treeEventIncludeInterestButtonImg"
                            className={`treeEventIncludeInterestButtonImg ${
                              props.eventDetails.hasInterest
                                ? 'ticked'
                                : 'unticked'
                            }`}
                            id={`treeEventIncludeInterestButtonImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            onClick={(e: any) =>
                              props.handleChangeTreeEventDetail(
                                e.target.id,
                                'hasInterest',
                                !props.eventDetails.hasInterest,
                              )
                            }
                          />
                          <p
                            className="treeEventIncludeInterestButtonText"
                            id={`treeEventIncludeInterestButtonText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            data-textattribute="submenu-14"
                            onClick={(e: any) =>
                              props.handleChangeTreeEventDetail(
                                e.target.id,
                                'hasInterest',
                                !props.eventDetails.hasInterest,
                              )
                            }
                          >
                            {getText('submenu-14', user.settings)}
                          </p>
                        </div>
                        {freemium.isFreemium &&
                          !props.eventDetails.hasInterest && (
                            <PremiumFeatureIcon
                              targetId={`treeEventIncludeInterestButtonContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                              forTreeInterest
                            />
                          )}
                      </>
                    ) : null}
                    {props.eventDetails.hasInterest ? (
                      <>
                        <div
                          className="treeEventInterestContainer"
                          id={`treeEventInterestContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        >
                          <InputNum
                            label={getText('label-27', user.settings)}
                            labelTextAttribute="label-27"
                            defaultZero
                            value={roundNumberTo(
                              props.eventDetails.interestRate * 100,
                              1,
                            )}
                            maxValue={100}
                            maxNumberOfDecimals={1}
                            smallField
                            id={`treeEventInterestRateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            width={99}
                            onChange={(e: any) =>
                              props.handleChangeTreeEventDetail(
                                e.target.id,
                                'interestRate',
                                e.target.value,
                              )
                            }
                            onFocus={() => {
                              setTimeout(() => {
                                updateXarrow()
                              }, 100)
                            }}
                            name={`treeEventInterestRateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            className="treeEventInterestRateInput robotoNumbers"
                            suffix="%"
                            tabIndex={10 * props.eventIndex + 4}
                            disabled={freemium.isFreemium}
                            readOnly={freemium.isFreemium}
                          />
                          <InputDate
                            label={getText('label-28', user.settings)}
                            labelTextAttribute="label-28"
                            width="120px"
                            className={
                              'treeEventInterestDateInput robotoNumbers'
                            }
                            onChange={(e: any) =>
                              props.handleChangeTreeEventDetail(
                                e.target.id,
                                'interestDate',
                                e.target.value,
                              )
                            }
                            smallField
                            alwaysShrink
                            value={props.eventDetails.interestDate}
                            onFocus={(e: any) => {
                              setTimeout(() => {
                                updateXarrow()
                              }, 100)
                            }}
                            id={`treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            name={`treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                            tabIndex={10 * props.eventIndex + 5}
                            error={
                              props.errors.includes(
                                `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                              ) ||
                              props.errors.includes(
                                `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}:validation`,
                              )
                            }
                            errorOutside={
                              props.errors.includes(
                                `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                              ) ||
                              props.errors.includes(
                                `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}:validation`,
                              )
                            }
                            errorMessage={
                              props.errors.includes(
                                `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}:validation`,
                              )
                                ? scenarioSnapshot.currentSnapshot.court ===
                                  TypeOfInstance.Public_Court
                                  ? getText('error-9', user.settings)
                                  : scenarioSnapshot.currentSnapshot.court ===
                                    TypeOfInstance.Arbitration
                                  ? getText('error-10', user.settings)
                                  : getText('error-11', user.settings)
                                : getText('error-4', user.settings)
                            }
                            errorTextAttribute={
                              props.errors.includes(
                                `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}:validation`,
                              )
                                ? scenarioSnapshot.currentSnapshot.court ===
                                  TypeOfInstance.Public_Court
                                  ? 'error-9'
                                  : scenarioSnapshot.currentSnapshot.court ===
                                    TypeOfInstance.Arbitration
                                  ? 'error-10'
                                  : 'error-11'
                                : 'error-4'
                            }
                            disabled={freemium.isFreemium}
                          />
                        </div>
                        {props.errors.includes(
                          `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        ) ||
                        props.errors.includes(
                          `treeEventInterestDateInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}:validation`,
                        ) ? (
                          <div className="treeEventErrorEmptySpace"></div>
                        ) : null}
                      </>
                    ) : null}
                  </div>
                </>
              ) : null}
            </div>
          </>
        )}
      </div>
    </>
  )
}
