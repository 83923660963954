import React, { useState } from 'react'
import SettingInfoRow from '../../components/SettingInfoRow'
import tickedImg from '../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../resources/images/018-untickedGrayBlue.svg'
import greenTick from '../../../../../resources/images/191-greenTick.svg'
import { User } from '../../../../../models/user'
import { CircularProgress } from '@mui/material'
import { userState } from '../../../../../states/UserState'
import { useRecoilValue } from 'recoil'
import { UserRole } from '../../../../../models/enums'
import { getText } from '../../../../../services/textFunctions'

type Props = {
  user: User
  onClose: () => void
  onDeleteAccount: () => void
  success: boolean
  loading: boolean
}

const DeleteUser = (props: Props) => {
  const adminUser = useRecoilValue(userState)
  const [agree, setAgree] = useState(false)

  const onClick = () => {
    setAgree((prevState) => !prevState)
  }

  const allowedToDelete = () => {
    if (
      adminUser.role === UserRole.COMPANY_ADMIN &&
      props.user.role === UserRole.GLOBAL_ADMIN
    ) {
      return false
    }

    return true
  }

  return (
    <>
      {' '}
      <div className="titleContainer no-first">
        <h2
          className="welcome"
          id="deleteUserTitle"
          data-textattribute="title-181"
        >
          {getText('title-181', adminUser.settings)}
        </h2>
      </div>
      <SettingInfoRow
        id="deleteUserInfo"
        descriptionText={
          allowedToDelete()
            ? getText('description-118', adminUser.settings)
            : getText('description-119', adminUser.settings)
        }
        descriptionTextAttribute={
          allowedToDelete() ? 'description-118' : 'description-119'
        }
        isFirst
        forDetails
      />
      {!props.success && allowedToDelete() && (
        <div className={`rowContainer`}>
          <img
            src={agree ? tickedImg : unTickedImg}
            alt={agree ? 'tickedImg' : 'unTickedImg'}
            className="tickImg"
            id="agreeDeleteAccount"
            onClick={onClick}
            style={{ cursor: 'pointer' }}
          />
          <p
            onClick={onClick}
            id="agreeDeleteAccountText"
            data-textattribute="message-83"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
          >
            {getText('message-83', adminUser.settings)}
          </p>
        </div>
      )}
      <div className={`rowContainer`}>
        {props.success ? (
          <div
            style={{ marginTop: '50px', marginBottom: '40px' }}
            id="accountSuccessfullyDeleted"
            data-textattribute="message-84"
          >
            <img
              src={greenTick}
              alt="greenTick"
              style={{ marginRight: '15px' }}
            />
            {getText('message-84', adminUser.settings)}
          </div>
        ) : (
          <button
            type="button"
            className={`button ${
              agree ? 'button-type-contained' : 'button-type-nonactive'
            }  danger`}
            disabled={!agree}
            id="deleteAccountButton"
            data-textattribute="button-115"
            onClick={
              props.loading || !allowedToDelete()
                ? () => {}
                : props.onDeleteAccount
            }
            style={{
              width: '200px',
              height: '38px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {props.loading ? (
              <CircularProgress size={22} />
            ) : (
              <div className="button-text">
                {getText('button-115', adminUser.settings)}
              </div>
            )}
          </button>
        )}
      </div>
    </>
  )
}

export default DeleteUser
