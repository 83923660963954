import React from 'react'

import firstTrialImg from '../../../../../../../../resources/images/137-first.svg'
import secondTrialImg from '../../../../../../../../resources/images/140-second.svg'
import bankruptcyFirstTrialImg from '../../../../../../../../resources/images/138-firstCourtBunkruptcy.svg'
import bankruptcySecondTrialImg from '../../../../../../../../resources/images/139-secondCourtBunkruptcy.svg'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { getText } from '../../../../../../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'

type Props = {
  id: number
  type: number
  state: ScenarioSnapshot
}
export default function Description(props: Props) {
  const user = useRecoilValue(userState)

  /* 
    sortedDataForTable Indexes
    0 - id
    1 - Description : it can be 1,2,3 or 4
    2 - probability_of_outcome
    3 - amount_received
    4 - interest_received
    5 - own_legal_fees
    6 - ownlegal_fees_covered_by_other_legal_fees > 0
    7 - ownlegal_fees_covered_by_other_legal_fees < 0
    8 - legal_fees_covered_by_insurance
    9 - amount_received_out_of_court
    10 - Unsuccessful enforcement
    11 - financial_outcome
    12 - probability_value
    13 - percentage_of_legal_fees
    14 - percentage_of_legal_fees_to_pay
    15 - percentage_of_total_claim
    16 - breakdown
    17 - name
    */

  //id='descriptionTextForDetailedResults'
  const descriptionText = [
    //data-textattribute='hover-34'
    getText('hover-34', user.settings),
    //data-textattribute='hover-35'
    getText('hover-35', user.settings),
    //data-textattribute='hover-36'
    getText('hover-36', user.settings),
    //data-textattribute='hover-37'
    getText('hover-37', user.settings),
  ]

  return (
    <>
      <div className="hoverBackground" data-openreplay-obscured>
        <div className="descriptionContainer">
          {props.type === 0 ? (
            <img
              src={firstTrialImg}
              alt="firstTrialImg"
              id={`firstTrialImgHover-${props.id}`}
            />
          ) : props.type === 1 ? (
            <img
              src={secondTrialImg}
              alt="secondTrialImg"
              id={`secondTrialImgHover-${props.id}`}
            />
          ) : props.type === 2 ? (
            <img
              src={bankruptcyFirstTrialImg}
              alt="bankruptcyFirstTrialImg"
              id={`bankruptcyFirstTrialImgHover-${props.id}`}
            />
          ) : (
            <img
              src={bankruptcySecondTrialImg}
              alt="bankruptcySecondTrialImg"
              id={`bankruptcySecondTrialImgHover-${props.id}`}
            />
          )}
          <p className="descriptionText">{descriptionText[props.type]}</p>
        </div>
      </div>
    </>
  )
}
