import React, { useState } from 'react'
import Button from '../../../../../../../../Components/Buttons/Button/Button'

type Props = {
  show: boolean
  text: string
  textAtrribute: string
  buttonText: string
  buttonTextAtrribute: `button-${string}` | `button-${number}`
  onClick: () => void
}

export default function ReportChangesMessageComponent(props: Props) {
  const [hoverForText, setHoverForText] = useState(false)

  return (
    <>
      {props.show && (
        <div
          className="textForReportWithDifferentResultsContainer"
          onMouseEnter={() => setHoverForText(true)}
          onMouseLeave={() => setHoverForText(false)}
        >
          <p
            className={`textForReportWithDifferentResults ${
              hoverForText ? 'grayText' : undefined
            }`}
            id="textForReportWithDifferentResults"
            data-textattribute="message-100"
          >
            {props.text}
          </p>
          {hoverForText ? (
            <>
              <div className="textForReportWithDifferentResultsButtonContainer"></div>
              <div className="textForReportWithDifferentResultsButtonContainer noOpacity">
                <Button
                  buttonText={props.buttonText}
                  buttonTextAttribute={props.buttonTextAtrribute}
                  buttonType="outlined"
                  type="button"
                  onClick={props.onClick}
                  className="hideTextForReportWithDifferentResultsButton"
                  id="hideTextForReportWithDifferentResultsButton"
                  small
                  side="left"
                  NoUpperCase={true}
                />
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  )
}
