import React, { useEffect, useState } from 'react'
import {
  ResultsObject,
  ResultsSettings,
} from '../../../../../../models/generalTypes'
import { getText } from '../../../../../../services/textFunctions'
import { ActionMessage, VideoPostName } from '../../../../../../models/enums'
import VideoInfoButton from '../../../../../../Components/InfoHover/videoInfoButton/VideoInfoButton'
import { useRecoilState, useRecoilValue } from 'recoil'
import { userState } from '../../../../../../states/UserState'
import DownloadImage from '../../../../../../Components/downloadImages/DownloadImage'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { pageDepthState } from '../../../../../../states/PageDepthState'
import TextForExecutiveSummary from './TextForExecutiveSummary'
import { freemiumState } from '../../../../../../states/FreemiumState'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../services/commonFunctions'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { resultsSettingsState } from '../../../../../../states/ResultsSettingsState'
import { saveResultsSettings } from '../../../../../../services/requests'
import ExecutiveSummaryNew3Container from './executiveSummary3/ExecutiveSummaryNew3Container'

type Props = {
  resultsFromBackend: ResultsObject | undefined
  setVideoPostName: (param: VideoPostName | undefined) => void
  setShowAWADetails: (showAWADetails: boolean) => void
  setShowSettlementDetails: (showSettlementDetails: boolean) => void
  resultsViewParty: 'client' | 'opposing'
}

export default function ExecutiveSummary(props: Props) {
  const user = useRecoilValue(userState)
  const [resultsSettings, setResultsSettings] =
    useRecoilState(resultsSettingsState)
  const freemium = useRecoilValue(freemiumState)
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)

  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [saveExecutiveSummaryImageName, setSaveExecutiveSummaryImageName] =
    useState('')
  const pageDepth = useRecoilValue(pageDepthState)

  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  useEffect(() => {
    let tempSaveTreeImageName = `${scenarioIdentity.caseName} - ${
      scenarioIdentity.scenarioName
    } - ${getText('title-38', user.settings)} - ${
      props.resultsViewParty === 'client'
        ? getText('title-260', user.settings, partiesFormat)
        : getText('title-261', user.settings, partiesFormat)
    }`
    setSaveExecutiveSummaryImageName(tempSaveTreeImageName)
    // eslint-disable-next-line
  }, [scenarioIdentity])

  useEffect(() => {
    if (pageDepth === 3) {
      //Mixpanel 69 (All)
      logActivity(freemium.isFreemium, 'Watched results')
    }
    // eslint-disable-next-line
  }, [])

  function switchExecutiveSummary(version: 'new' | 'old') {
    let tempResultsSettings = deepCloneObject(
      resultsSettings,
    ) as ResultsSettings
    tempResultsSettings.settings.executiveSummary = version
    setResultsSettings(tempResultsSettings)
    if (!freemium.isFreemium) {
      saveResultsSettings(
        scenarioIdentity.caseId,
        scenarioIdentity.scenarioId,
        tempResultsSettings,
      )
    }
    logActivity(freemium.isFreemium, 'Changed executive summary to ' + version)
  }

  return (
    <div
      className="tool-component results-tool-component"
      id="eReportComp"
      style={
        fullscreenActionMessage === ActionMessage.downloadingImage
          ? { marginTop: 0 }
          : { marginTop: 20 }
      }
    >
      <div
        className="downloadImageComponentContainer"
        style={
          fullscreenActionMessage === ActionMessage.downloadingImage
            ? { visibility: 'hidden' }
            : undefined
        }
      >
        <DownloadImage
          id={`executiveSummary`}
          tooltipText={getText('tooltip-35', user.settings)}
          tooltipTextAttribute={'tooltip-35'}
          imageElementId={`eReportComp`}
          saveName={saveExecutiveSummaryImageName}
          downloadImageType="results"
        />
      </div>

      <h2
        className="tool-component-title"
        id="executiveSummary-title"
        data-textattribute="title-38"
      >
        {getText('title-38', user.settings)}
        <div
          className="videoInfoButtonComponentContainer executiveSummary"
          style={
            fullscreenActionMessage === ActionMessage.downloadingImage ||
            resultsSettings.settings.executiveSummary === 'new'
              ? { display: 'none' }
              : undefined
          }
        >
          <VideoInfoButton
            textAttribute="tooltip-19"
            postName={VideoPostName.resultsExecutiveSummary}
            setVideoPostName={props.setVideoPostName}
          />
        </div>
        <div
          className="oldNewSwitch"
          style={
            fullscreenActionMessage === ActionMessage.downloadingImage
              ? { visibility: 'hidden' }
              : resultsSettings.settings.executiveSummary === 'new'
              ? { marginLeft: 10 }
              : undefined
          }
        >
          {resultsSettings.settings.executiveSummary === 'old' ? (
            <p
              className="oldNewSwitchText"
              id="oldNewSwitchText1"
              data-textattribute="button-163"
              onClick={() => {
                switchExecutiveSummary('new')
              }}
            >
              {getText('button-163', user.settings)}
            </p>
          ) : (
            <p
              className="oldNewSwitchText"
              id="oldNewSwitchText2"
              data-textattribute="button-164"
              onClick={() => {
                switchExecutiveSummary('old')
              }}
            >
              {getText('button-164', user.settings)}
            </p>
          )}
        </div>
      </h2>
      {resultsSettings.settings.executiveSummary === 'old' ? (
        <TextForExecutiveSummary
          resultsFromBackend={props.resultsFromBackend!}
          setShowAWADetails={props.setShowAWADetails}
          setShowSettlementDetails={props.setShowSettlementDetails}
          resultsViewParty={props.resultsViewParty}
          downloadingImage={
            fullscreenActionMessage === ActionMessage.downloadingImage
          }
        />
      ) : (
        <ExecutiveSummaryNew3Container
          resultsFromBackend={props.resultsFromBackend!}
          setShowAWADetails={props.setShowAWADetails}
          resultsViewParty={props.resultsViewParty}
          downloadingImage={
            fullscreenActionMessage === ActionMessage.downloadingImage
          }
        />
      )}
    </div>
  )
}
