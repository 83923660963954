import { atom } from 'recoil'
import { ReportMenu } from '../models/generalTypes'

export const reportMenuState = atom<ReportMenu>({
  key: 'reportMenuState',
  default: {
    downloadReportFixedHeaderOn: false,
    orderMenuOn: false,
    downloadPdf: false,
    reportSaveMenuOn: undefined,
    reportWithDifferentResults: false,
    previewPdfOn: false,
  },
})
