import React from 'react'
import { useRecoilState } from 'recoil'
import { TutorialTileObject } from '../../../../../models/generalTypes'
import estimatedTimeImg from '../../../../../resources/images/175-time-period.svg'
import { onlineState } from '../../../../../states/OnlineState'

type Props = {
  tutorialObject: TutorialTileObject
  setHeaderTutorialTitle: (param: string) => void
  setOpenTutorial: (param: string | undefined) => void
}

export default function TutorialTile(props: Props) {
  const [online, setOnline] = useRecoilState(onlineState)

  function openTutorialInfo() {
    if (online.networkOn) {
      props.setOpenTutorial(props.tutorialObject.slug)
      props.setHeaderTutorialTitle(props.tutorialObject.title)
    } else {
      setOnline({ ...online, shaking: true })
    }
  }
  return (
    <>
      <div className="tutorialTileBackground" onClick={openTutorialInfo}>
        <div className="tutorialTileTitleContainer">
          <div className="thumbnailContainer">
            <img
              src={props.tutorialObject.thumbnail}
              alt="tutorialTileThumbnail"
              className="tutorialTileThumbnail"
            />
          </div>
          <div className="titleContainer">
            <p className="tutorialTileTitle">{props.tutorialObject.title}</p>
          </div>
        </div>
        <div className="tutorialTileSummaryContainer">
          <p className="tutorialTileSummary">{props.tutorialObject.summary}</p>
        </div>
        <div className="tutorialTileEstimatedTimeContainer">
          <img
            src={estimatedTimeImg}
            alt="estimatedTimeImg"
            className="estimatedTimeImg"
          />
          <p className="tutorialTileEstimatedTimeText">
            {props.tutorialObject.estimatedTime}
          </p>
        </div>
      </div>
    </>
  )
}
