import fitty from 'fitty'
import React, { useEffect } from 'react'
import { useRecoilValue } from 'recoil'

import { roundNumberTo } from '../../../../services/commonFunctions'
import {
  formattedNumToString,
  stringToNumForCalculations,
} from '../../../../services/formatNum'
import { userState } from '../../../../states/UserState'
import { ReportWhitelabel } from '../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../services/reportFunctions'
import { krogerusColorsState } from '../../../../states/KrogerusColorsState'

type Props = {
  segmentMax: string
  segmentMaxPercentage: number | undefined
  segmentMin: string
  segmentMinPercentage: number | undefined
  openZero: boolean
  singleSegmentIndex: number
  reportWhitelabel?: ReportWhitelabel
  totalCounterClaimedAmount: number
  disputedAmount: number
}

export default function PossibleOutcomeRange(props: Props) {
  useEffect(() => {
    fitty('.outcomeRangeAmount', {
      minSize: 9,
      maxSize: 18,
      multiLine: false,
    })
  }, [props.segmentMax])

  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)

  function topOutcomeRedAtZero() {
    return props.disputedAmount + props.totalCounterClaimedAmount <= 0
  }

  return (
    <>
      {props.segmentMax && props.segmentMin ? (
        <div className="possibleOutcomeRangeContainer" data-openreplay-obscured>
          {props.segmentMax === props.segmentMin ? (
            <>
              <div className="outcomeContainer maxmin">
                <p
                  className={`outcomeRangeAmount robotoNumbers ${
                    krogerusColors ? 'krogerus' : ''
                  } ${
                    stringToNumForCalculations(
                      props.segmentMax,
                      user.settings,
                    ) < 0 || topOutcomeRedAtZero()
                      ? 'red'
                      : ''
                  }`}
                  id={`outcomeRangeAmountMax-${props.singleSegmentIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'roboto',
                    false,
                    true,
                  )}
                >
                  {formattedNumToString(
                    Math.abs(
                      stringToNumForCalculations(
                        props.segmentMax,
                        user.settings,
                      ),
                    ),
                    user.settings,
                  )}
                </p>
                {/* <p
                  className="outcomeRangePercentage"
                  id={`outcomeRangePercentageMax-${props.singleSegmentIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                  )}
                >
                  {stringAndRoundTo2Decimals(
                    props.segmentMaxPercentage,
                    user.settings,
                  )}
                  %
                </p> */}
              </div>
            </>
          ) : (
            <>
              <div className="outcomeContainer max">
                <p
                  className={`outcomeRangeAmount robotoNumbers ${
                    krogerusColors ? 'krogerus' : ''
                  } ${
                    stringToNumForCalculations(
                      props.segmentMax,
                      user.settings,
                    ) -
                      0.01 <
                    0
                      ? 'red'
                      : ''
                  }`}
                  id={`outcomeRangeAmountMax-${props.singleSegmentIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'roboto',
                    false,
                    true,
                  )}
                >
                  {formattedNumToString(
                    Math.abs(
                      roundNumberTo(
                        (stringToNumForCalculations(
                          props.segmentMax,
                          user.settings,
                        ) as number) - 0.01,
                        2,
                      ),
                    ),
                    user.settings,
                  )}
                </p>
                {/* <p
                  className="outcomeRangePercentage"
                  id={`outcomeRangePercentageMax-${props.singleSegmentIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                  )}
                >
                  {stringAndRoundTo2Decimals(
                    (props.segmentMaxPercentage as number) - 0.01,
                    user.settings,
                  )}
                  %
                </p> */}
              </div>
              <div className="dashContainer">
                <p
                  className="dash"
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'noRoboto',
                  )}
                >
                  -
                </p>
              </div>
              <div className="outcomeContainer min">
                <p
                  className={`outcomeRangeAmount robotoNumbers ${
                    krogerusColors ? 'krogerus' : ''
                  } ${
                    stringToNumForCalculations(
                      props.segmentMin,
                      user.settings,
                    ) +
                      0.01 <
                    0
                      ? 'red'
                      : ''
                  }`}
                  id={`outcomeRangeAmountMin-${props.singleSegmentIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                    'roboto',
                    false,
                    true,
                  )}
                >
                  {props.openZero
                    ? formattedNumToString(
                        Math.abs(
                          roundNumberTo(
                            (stringToNumForCalculations(
                              props.segmentMin,
                              user.settings,
                            ) as number) + 0.01,
                            2,
                          ),
                        ),
                        user.settings,
                      )
                    : formattedNumToString(
                        Math.abs(
                          stringToNumForCalculations(
                            props.segmentMin,
                            user.settings,
                          ),
                        ),
                        user.settings,
                      )}
                </p>
                {/* <p
                  className="outcomeRangePercentage"
                  id={`outcomeRangePercentageMin-${props.singleSegmentIndex}`}
                  style={getStyleFromWhitelabel(
                    props.reportWhitelabel,
                    ReportWhitelabelKeys.sectionDescription,
                    FontLabels.regular,
                  )}
                >
                  {props.openZero
                    ? stringAndRoundTo2Decimals(
                        (props.segmentMinPercentage as number) + 0.01,
                        user.settings,
                      )
                    : stringAndRoundTo2Decimals(
                        props.segmentMinPercentage,
                        user.settings,
                      )}
                  %
                </p> */}
              </div>
            </>
          )}
        </div>
      ) : null}
    </>
  )
}
