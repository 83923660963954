import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  AccessRole,
  ClaimsPreviewStyle,
  VideoPostName,
} from '../../../../models/enums'
import {
  TabsErrors,
  TreeTableInfo,
  TreeAnalysisResults,
} from '../../../../models/generalTypes'
import { activeTabsState } from '../../../../states/ActiveTabsState'
import { scenarioSnapshotState } from '../../../../states/ScenarioSnapshotState'
import ClaimTilesContainer from './claimsTile/ClaimTilesContainer'
import DisputedAmountInfo from './claimsTile/DisputedAmountInfo'
import SingleClaim from './SingleClaim'
import { focusOnFirstError } from '../../../../services/validateGlobalState'
import useWindowSize from '../../../../customHooks/useWindowSize'

type Props = {
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  numOfErrors: Record<number, number> | undefined
  doValidation: boolean
  claimExistance: boolean
  treeDirectCalculation: (
    treeIndex: number,
    message: string,
  ) => Promise<TreeAnalysisResults[][] | undefined>
  changeTreeTablesInfo: (treeTableInfo: TreeTableInfo) => void
  calculateAllTrees: () => Promise<void>
  setVideoPostName: (param: VideoPostName | undefined) => void
  ownRole: AccessRole
  checkIfTreeHasErrors: (treeIndex: number) => boolean
  tabsErrors: TabsErrors | undefined
  treeWarningsIndex: number
  addCheckedIndex: (value: number) => void
  errors: string[]
  errorsIdTabs: string[]
}

export default function Claims(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)
  const { width } = useWindowSize()
  // In the first render width is 0
  const [forceTiles, setForceTiles] = useState(width < 1020 && width > 10)
  const [updateClaim, setUpdateClaim] = useState(true)

  function setActiveClaimFunction(claimIndex: number, noRerender?: boolean) {
    if (
      claimIndex >= scenarioSnapshot.currentSnapshot.claims.length ||
      activeTabs.claim >= scenarioSnapshot.currentSnapshot.claims.length ||
      (scenarioSnapshot.currentSnapshot.claims[claimIndex].id !==
        scenarioSnapshot.currentSnapshot.claims[activeTabs.claim].id &&
        !noRerender)
    ) {
      setUpdateClaim(false)
    }

    setActiveTabs({
      tab: activeTabs.tab,
      claim: claimIndex,
    })

    setTimeout(() => {
      setUpdateClaim(true)
      if (props.doValidation) {
        setTimeout(() => {
          focusOnFirstError(props.errors, props.errorsIdTabs, {
            tab: activeTabs.tab,
            claim: claimIndex,
          })
        }, 100)
      }
    }, 50)
  }

  function removeClaim(
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) {
    setUpdateClaim(false)
    let tempActiveClaim = activeTabs.claim
    if (
      tempActiveClaim ===
      scenarioSnapshot.currentSnapshot.claims.length - 1
    ) {
      tempActiveClaim--
    }

    setActiveTabs({
      tab: activeTabs.tab,
      claim: tempActiveClaim === -1 ? 0 : tempActiveClaim,
    })

    props.removeClaim(claimIndex, focusId, highlightId)
    setTimeout(() => {
      setUpdateClaim(true)
    }, 50)
  }

  const onScroll = (e: any) => {
    const topHeight =
      document.getElementById('claimsPreviewContainer') &&
      document.getElementById('tabsContainer')
        ? document.getElementById('claimsPreviewContainer')!.offsetHeight +
          document.getElementById('tabsContainer')!.offsetHeight +
          53 +
          33
        : undefined
    if (window.innerWidth < 836) {
      if (document.getElementById('disputedAmountInfoComponentContainer')) {
        document.getElementById(
          'disputedAmountInfoComponentContainer',
        )!.style.transform = `translateX(0px)`
      }
    } else if (
      document.getElementById(`disputedAmountInfoComponentContainer`) &&
      topHeight !== undefined &&
      topHeight - window.scrollY < 0
    ) {
      document.getElementById(
        `disputedAmountInfoComponentContainer`,
      )!.style.transform = `translateX(0px)`
    } else if (
      document.getElementById(`disputedAmountInfoComponentContainer`)
    ) {
      document.getElementById(
        `disputedAmountInfoComponentContainer`,
      )!.style.transform = `translateX(${window.scrollX}px)`
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setForceTiles(width < 1020 && width > 10)
  }, [width])

  return (
    <>
      <ClaimTilesContainer
        errors={props.numOfErrors}
        activeClaim={activeTabs.claim}
        setActiveClaim={setActiveClaimFunction}
        removeClaim={removeClaim}
        doValidation={props.doValidation}
        treeDirectCalculation={props.treeDirectCalculation}
        setVideoPostName={props.setVideoPostName}
        ownRole={props.ownRole}
        forceTiles={forceTiles}
        calculateAllTrees={props.calculateAllTrees}
      />
      {props.claimExistance &&
        (scenarioSnapshot.currentSnapshot.claimsPreviewStyle ===
          ClaimsPreviewStyle.tiles ||
          forceTiles) && (
          <div
            className="disputedAmountInfoComponentContainer"
            id="disputedAmountInfoComponentContainer"
          >
            <DisputedAmountInfo calculateAllTrees={props.calculateAllTrees} />
          </div>
        )}

      <SingleClaim
        claimIndex={activeTabs.claim}
        removeClaim={removeClaim}
        updateRef={updateClaim}
        treeDirectCalculation={props.treeDirectCalculation}
        changeTreeTablesInfo={props.changeTreeTablesInfo}
        ownRole={props.ownRole}
        checkIfTreeHasErrors={props.checkIfTreeHasErrors}
        tabsErrors={props.tabsErrors}
        treeWarningsIndex={props.treeWarningsIndex}
        addCheckedIndex={props.addCheckedIndex}
        errors={props.errors}
      />
    </>
  )
}
