import { EventDetails } from './eventDetails'
import { NodeId } from './treeTypes'

export class TreeEventClass {
  nodeOfEventId: NodeId // the node that the event belongs to
  eventIndex: number // the index of the event inside the node
  childrenNodes: NodeId[] // the nodeIds of the children of this event
  eventDetails: EventDetails

  constructor(
    nodeOfEventId: NodeId,
    eventIndex: number,
    childrenNodes: NodeId[],
    eventDetails: EventDetails,
  ) {
    this.nodeOfEventId = nodeOfEventId
    this.eventIndex = eventIndex
    this.childrenNodes = childrenNodes
    this.eventDetails = eventDetails
  }

  static defaultTreeEvent(
    nodeOfEventId: NodeId,
    eventIndex: number,
    eventDetails: EventDetails,
  ): TreeEventClass {
    return new TreeEventClass(nodeOfEventId, eventIndex, [], eventDetails)
  }
}
