import React from 'react'

import { PageType } from '../UserPopUp'
import backCircleImg from '../../../resources/images/134-backLogin.svg'
import CircularProgress from '@mui/material/CircularProgress'
import InputText from '../../../Components/Inputs/InputText/InputText'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  email: string
  setEmail: (value: string) => void
  setPageType: (value: PageType) => void
  loading: boolean
  validationMessage: string | undefined
  errors: string[]
}

export default function ForgotPassword(props: Props) {
  const user = useRecoilValue(userState)

  const failedMessage = () => {
    const mes1 = props.validationMessage!.split('\\n')[0]
    const mes2 = props.validationMessage!.split('\\n')[1]
    return (
      <>
        {mes1}
        <br />
        {mes2}
      </>
    )
  }

  return (
    <>
      <img
        className="backImg"
        src={backCircleImg}
        alt="backImg"
        onClick={() => {
          props.setPageType('Login')
        }}
      />

      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="forgotPasswordTitle"
          data-textattribute="title-109"
        >
          {getText('title-109', user.settings)}
        </h2>
        <p
          className={`instructions ${
            props.validationMessage ? 'errorClass' : ''
          }`}
          id="forgotPasswordInstructions"
          data-textattribute={
            props.validationMessage ? 'error-00' : 'description-83'
          }
        >
          {props.validationMessage
            ? failedMessage()
            : getText('description-83', user.settings)}
        </p>
      </div>

      <div className="inputsContainer">
        <div className="input">
          <InputText
            label={getText('label-54', user.settings)}
            labelTextAttribute="label-54"
            id="resetEmail"
            name="resetEmail"
            width={400}
            onChange={(e) => props.setEmail(e.target.value)}
            value={props.email}
            autoComplete
            helperText={getText('helperText-4', user.settings)}
            helperTextAttribute="helperText-4"
            error={
              props.errors.includes('resetEmail') ||
              props.errors.includes('resetEmail:validation')
            }
            errorMessage={
              props.errors.includes('resetEmail:validation')
                ? getText('error-38', user.settings)
                : getText('error-39', user.settings)
            }
            errorTextAttribute={
              props.errors.includes('resetEmail:validation')
                ? 'error-38'
                : 'error-39'
            }
            alwaysShrink
            maxLength={50}
            noDelay
          />
        </div>
      </div>
      <div className="submitButtonContainer">
        <button
          type="submit"
          className="submitButton"
          disabled={props.loading}
          id="submitForgotPasswordButton"
          data-textattribute="button-52"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : (
            getText('button-52', user.settings)
          )}
        </button>
      </div>
    </>
  )
}
