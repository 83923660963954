import { keysIn } from 'lodash'
import { AmountSignum, ClaimType, PartySignum } from '../models/enums'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'
import { TreeClaim } from '../models/treeModels/treeClaim'
import { claimtypeIdArray, handleClaimtypeIdChange } from './claimFunctions'
import { deepCloneObject } from './commonFunctions'
import { EventId } from '../models/treeModels/treeTypes'

export const reverseCase = (data: ScenarioSnapshot) => {
  let tempData = deepCloneObject(data) as ScenarioSnapshot

  tempData.partyFormatOwn = data.partyFormatOther
  tempData.partyFormatOther = data.partyFormatOwn

  tempData.legalCosts.clientLegalCosts = data.legalCosts.opposingPartyLegalCosts
  tempData.legalCosts.opposingPartyLegalCosts = data.legalCosts.clientLegalCosts
  tempData.hasInsurance = false
  tempData.maxInsurance = undefined
  tempData.minimumExcessFee = undefined
  tempData.insuranceExcessPercentage = undefined

  tempData.hasBankruptcy = false
  tempData.bankruptcyPercentage = undefined

  for (let claim of tempData.claims) {
    if (claim.type !== ClaimType.tree) {
      claim.type =
        claim.type === ClaimType.claim
          ? ClaimType.counterclaim
          : ClaimType.claim

      // change the amount and each quantumScenario amount to positive if 'claim' or to negative if 'counterclaim'
      if (claim.type === ClaimType.claim) {
        if (claim.amount) {
          claim.amount = Math.abs(claim.amount)
        }

        claim.quantumScenarios = claim.quantumScenarios.map((item) => {
          item.amount =
            item.amount !== undefined ? Math.abs(item.amount) : undefined
          item.outOfCourtAmount = 0
          item.outOfCourtSignum = AmountSignum.off
          return item
        })
      } else {
        if (claim.amount) {
          claim.amount = -Math.abs(claim.amount)
        }
        claim.quantumScenarios = claim.quantumScenarios.map((item) => {
          item.amount =
            item.amount !== undefined ? -Math.abs(item.amount) : undefined
          item.outOfCourtAmount = 0
          item.outOfCourtSignum = AmountSignum.off
          return item
        })
      }

      claim.hasOutOfCourt = false
    } else {
      ;(claim as TreeClaim).totalClaimedAmount = undefined
      ;(claim as TreeClaim).totalClaimedAmountInterest1st = undefined
      ;(claim as TreeClaim).totalClaimedAmountInterest2nd = undefined
      ;(claim as TreeClaim).totalCounterClaimedAmount = undefined
      ;(claim as TreeClaim).totalCounterClaimedAmountInterest1st = undefined
      ;(claim as TreeClaim).totalCounterClaimedAmountInterest2nd = undefined
      ;(claim as TreeClaim).treeWeightedValue = undefined
      ;(claim as TreeClaim).treeWeightedValueInterest1st = undefined
      ;(claim as TreeClaim).treeWeightedValueInterest2nd = undefined
      ;(claim as TreeClaim).analysisResults = false

      for (let event of keysIn((claim as TreeClaim).treeDetails.events)) {
        ;(claim as TreeClaim).treeDetails.events[
          event as EventId
        ].eventDetails.awardedAmount =
          (claim as TreeClaim).treeDetails.events[event as EventId].eventDetails
            .awardedAmount > 0
            ? -(claim as TreeClaim).treeDetails.events[event as EventId]
                .eventDetails.awardedAmount
            : Math.abs(
                (claim as TreeClaim).treeDetails.events[event as EventId]
                  .eventDetails.awardedAmount,
              )
        ///////////////////////////////////////////////
        ;(claim as TreeClaim).treeDetails.events[
          event as EventId
        ].eventDetails.awardedAmountSignum =
          (claim as TreeClaim).treeDetails.events[event as EventId].eventDetails
            .awardedAmountSignum === AmountSignum.pay
            ? AmountSignum.get
            : (claim as TreeClaim).treeDetails.events[event as EventId]
                .eventDetails.awardedAmountSignum === AmountSignum.off
            ? AmountSignum.off
            : AmountSignum.pay
        ///////////////////////////////////////////////
        ;(claim as TreeClaim).treeDetails.events[
          event as EventId
        ].eventDetails.reducedAwardedAmountParty =
          (claim as TreeClaim).treeDetails.events[event as EventId].eventDetails
            .reducedAwardedAmountParty === PartySignum.client
            ? PartySignum.opposingParty
            : (claim as TreeClaim).treeDetails.events[event as EventId]
                .eventDetails.reducedAwardedAmountParty === PartySignum.off
            ? PartySignum.off
            : PartySignum.client
        ///////////////////////////////////////////////
        ;(claim as TreeClaim).treeDetails.events[
          event as EventId
        ].eventDetails.outOfCourtAmount = 0
        ///////////////////////////////////////////////
        ;(claim as TreeClaim).treeDetails.events[
          event as EventId
        ].eventDetails.outOfCourtAmountSignum = AmountSignum.off
      }
    }
  }

  tempData.legalFeesDistribution.reverse()
  for (let i = 0; i < tempData.legalFeesDistribution.length; i++) {
    tempData.legalFeesDistribution[i].id = i
    const coverByMe = tempData.legalFeesDistribution[i].ratioCoveredByMe
    tempData.legalFeesDistribution[i].ratioCoveredByMe =
      tempData.legalFeesDistribution[i].ratioToCover
    tempData.legalFeesDistribution[i].ratioToCover = coverByMe
    tempData.legalFeesDistribution[i].value =
      -tempData.legalFeesDistribution[i].value

    tempData.legalFeesDistribution[i].range = tempData.legalFeesDistribution[
      i
    ].range
      .reverse()
      .map((num) => 100 - num)
  }

  tempData.includeBottomLFDLimit = data.includeTopLFDLimit
  tempData.includeTopLFDLimit = data.includeBottomLFDLimit

  //change the claimtypeId of each claim
  const claimsArray = claimtypeIdArray(tempData)

  tempData = handleClaimtypeIdChange(
    claimsArray[0],
    tempData,
  ) as ScenarioSnapshot

  return tempData
}
