import { CircularProgress } from '@mui/material'
import React from 'react'
import { useRecoilValue } from 'recoil'
import Button from '../../../../../Components/Buttons/Button/Button'
import { getText } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'

type Props = {
  id: string
  resetFunction: () => void
  cancelFunction: () => void
  position: number[]
  type: string
  loading: boolean
  reseted: boolean
  fullname: string
}

const LocalWindow = (props: Props) => {
  const user = useRecoilValue(userState)

  return (
    <>
      <div
        className={`deleteMessage for-reset ${
          props.type === 'tfa' ? 'for-tfa' : ''
        }`}
        style={{
          top: props.position[0],
          left: props.position[1],
          position: 'fixed',
        }}
        id={props.id}
      >
        {props.loading ? (
          <div className="circularProgressDiv" id={'popUp7'}>
            <div id={'popUp8'}>
              <CircularProgress size={40} />
            </div>
          </div>
        ) : (
          <>
            <div className="deleteQuestion">
              <>
                <p
                  id={`resetQuestionText-${props.id}`}
                  data-textattribute={
                    props.type === 'password'
                      ? props.reseted
                        ? 'message-85a, message-85b'
                        : 'message-86'
                      : props.reseted
                      ? 'message-87a, message-87b'
                      : 'message-88'
                  }
                >
                  {props.type === 'password'
                    ? props.reseted
                      ? `${getText('message-85a', user.settings)} ${
                          props.fullname
                        } ${getText('message-85b', user.settings)}`
                      : getText('message-86', user.settings)
                    : props.reseted
                    ? `${getText('message-87a', user.settings)} ${
                        props.fullname
                      } ${getText('message-87b', user.settings)}`
                    : getText('message-88', user.settings)}
                </p>
              </>
            </div>
            <div
              className="buttonsContainer"
              id={`buttonsContainer-${props.id}`}
            >
              {!props.reseted && (
                <Button
                  id={`windowCancelButton-${props.id}`}
                  buttonText={getText('button-3', user.settings)}
                  buttonTextAttribute="button-3"
                  buttonType="text"
                  type="button"
                  className="cancelButton"
                  onMouseUp={props.cancelFunction}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              )}
              <Button
                id={`windowResetButton-${props.id}`}
                buttonText={
                  props.reseted
                    ? getText('button-97', user.settings)
                    : props.type === 'password'
                    ? getText('button-98', user.settings)
                    : getText('button-99', user.settings)
                }
                buttonTextAttribute={
                  props.reseted
                    ? 'button-97'
                    : props.type === 'password'
                    ? 'button-98'
                    : 'button-99'
                }
                buttonType="contained"
                type="button"
                className="DeleteButton"
                onMouseUp={
                  props.reseted ? props.cancelFunction : props.resetFunction
                }
                side="left"
                NoUpperCase={true}
                small={false}
              />
            </div>
          </>
        )}
      </div>
      <div
        className="deleteMessage-background"
        onMouseUp={props.cancelFunction}
      ></div>
    </>
  )
}

export default LocalWindow
