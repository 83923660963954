import { EventDetails } from '../models/treeModels/eventDetails'
import { TreeDetails } from '../models/treeModels/treeClaim'
import { EventId, NodeId, RootNodeType } from '../models/treeModels/treeTypes'
import { deepCloneObject } from './commonFunctions'
import {
  findEventsOfAllNodes,
  findEventsOfNode,
  findMainTreeRoot,
  findNodesOfTreeFromRoot,
  findParentEvents,
} from './treeFunctions/treeBasicFunctions'
import {
  findTreeCombinations,
  generatePermutations,
  sortAndFilterUniqueArrays,
} from './treeNumberOfScenariosCalculation'

export function findEventRelationshipObject(
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  const eventsOfAllNodes = findEventsOfAllNodes(treeDetails)
  let eventRelationshipObject: { [number: number]: number[][] } = {}
  for (let eventId of Object.keys(treeDetails.events)) {
    if (performance.now() - startTime > duration) {
      return 'calculation failed'
    }
    eventRelationshipObject[
      findEventNumber(eventId as EventId, treeDetails) as number
    ] = findCombinationsOfChildrenExcludingParentEvent(
      eventId as EventId,
      treeDetails,
      eventsOfAllNodes,
    )
  }
  //console.log(eventRelationshipObject)

  return eventRelationshipObject
}

export function findEventCombinationsFromMultipleParents(
  treeDetails: TreeDetails,
) {
  let eventCombinationsFromMultipleParents: number[][] = []
  let i = 0
  for (let nodeId of Object.keys(treeDetails.nodes)) {
    // console.log(
    //   'I am checking node',
    //   findNodeNumber(nodeId as NodeId, treeDetails),
    // )
    // console.log(
    //   Object.keys(treeDetails.nodes[nodeId as NodeId].nodeSiblingIndex).length,
    // )

    if (
      Object.keys(treeDetails.nodes[nodeId as NodeId].nodeSiblingIndex).length >
      1
    ) {
      eventCombinationsFromMultipleParents[i] = []
      // console.log(
      //   Object.keys(treeDetails.nodes[nodeId as NodeId].nodeSiblingIndex),
      // )

      for (let parentEvent of Object.keys(
        treeDetails.nodes[nodeId as NodeId].nodeSiblingIndex,
      )) {
        eventCombinationsFromMultipleParents[i].push(
          findEventNumber(parentEvent as EventId, treeDetails) as number,
        )
      }
      i++
    }
  }
  // console.log(eventCombinationsFromMultipleParents)
  return eventCombinationsFromMultipleParents
}

export function findCombinationsOfChildrenExcludingParentEvent(
  eventId: EventId,
  treeDetails: TreeDetails,
  eventsOfAllNodes: {
    [nodeId: NodeId]: EventId[]
  },
): number[][] {
  let eventsOfNodesWithNumbersArray = []
  for (let childNode of treeDetails.events[eventId].childrenNodes) {
    let eventsOfNodeWithNumber: number[] = []
    for (let tempEventId of eventsOfAllNodes[childNode as NodeId]) {
      eventsOfNodeWithNumber.push(
        findEventNumber(tempEventId, treeDetails) as number,
      )
    }
    eventsOfNodesWithNumbersArray.push(eventsOfNodeWithNumber)
  }

  return generatePermutations(eventsOfNodesWithNumbersArray)
}

export function findEffectiveEventCombinations(
  eventRelationshipObject: {
    [number: number]: number[][]
  },
  eventCombinationsFromMultipleParents: number[][],
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  let flatEventRelationshipObject = []
  for (let eventNumber of Object.keys(eventRelationshipObject)) {
    if (performance.now() - startTime > duration) {
      return 'calculation failed'
    }
    for (let combination of eventRelationshipObject[parseInt(eventNumber)]) {
      flatEventRelationshipObject.push(combination)
    }
  }
  let effectiveEventCombinations: number[][] = []
  for (let eventCombinationFromMultipleParents of eventCombinationsFromMultipleParents) {
    if (performance.now() - startTime > duration) {
      return 'calculation failed'
    }
    let effectiveCombinationsArray = findAllCombinationsOfParentEvents(
      eventCombinationFromMultipleParents,
      treeDetails,
      startTime,
      duration,
    )
    if (typeof effectiveCombinationsArray === 'string') {
      return 'calculation failed'
    }
    effectiveEventCombinations = effectiveEventCombinations.concat(
      effectiveCombinationsArray,
    )
  }
  effectiveEventCombinations = sortAndFilterUniqueArrays(
    effectiveEventCombinations,
  )
  let finalEffectiveEventCombinations = []
  for (let combination of effectiveEventCombinations) {
    if (performance.now() - startTime > duration) {
      return 'calculation failed'
    }
    if (checkIfAllNumbersExist(combination, flatEventRelationshipObject)) {
      finalEffectiveEventCombinations.push(combination)
    }
  }

  return finalEffectiveEventCombinations
}

export function findAllCombinationsOfParentEvents(
  eventCombinationFromMultipleParents: number[],
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  let allCombinations: number[][] = []
  for (let [
    index,
    eventNumber,
  ] of eventCombinationFromMultipleParents.entries()) {
    if (performance.now() - startTime > duration) {
      return 'calculation failed'
    }
    allCombinations[index] = []
    let eventId = Object.keys(treeDetails.events)[eventNumber]
    allCombinations[index].push(eventNumber)
    allCombinations[index] = allCombinations[index].concat(
      findParentEventNumbersOfEvent(eventId as EventId, treeDetails, []),
    )
    allCombinations[index] = allCombinations[index].concat(
      findCousinEventsOfEvent(eventId as EventId, treeDetails),
    )
  }

  return getCombinations([...new Set(allCombinations.flat())])
}

export function findParentEventNumbersOfEvent(
  eventId: EventId,
  treeDetails: TreeDetails,
  parentEvents: number[],
) {
  let parentEventsId = findParentEvents(
    treeDetails.events[eventId].nodeOfEventId,
    treeDetails,
  )

  if (parentEventsId !== '') {
    for (let parentEvent of parentEventsId) {
      if (
        !parentEvents.includes(
          findEventNumber(parentEvent, treeDetails) as number,
        )
      ) {
        parentEvents.push(findEventNumber(parentEvent, treeDetails) as number)
      }
      findParentEventNumbersOfEvent(parentEvent, treeDetails, parentEvents)
    }
  }

  return parentEvents
}

function checkIfAllNumbersExist(
  toCheck: number[],
  combinations: number[][],
): boolean {
  for (const subarray of combinations) {
    if (toCheck.every((num) => subarray.includes(num))) {
      return true
    }
  }
  return false
}

export function findReplacementOfEffectiveEventCombinations(
  effectiveEventCombinations: number[][],
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  let replacementOfEffectiveEventCombinations: {
    combination: number[]
    replacement: number[][]
  }[] = []
  const eventsOfAllNodes = findEventsOfAllNodes(treeDetails)
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  for (let combination of effectiveEventCombinations) {
    let replacement: number[][] = []
    let fakeTreeDetails: TreeDetails = {
      nodes: {
        nodef1: {
          nodeTitle: 'fake',
          nodeSiblingIndex: {},
          properties: { position: [0, 0] },
          numberOfEvents: 1,
          root: RootNodeType.mainTreeRoot,
        },
      },
      events: {
        eventf1: {
          nodeOfEventId: 'nodef1',
          eventIndex: 0,
          childrenNodes: [],
          eventDetails: {} as EventDetails,
        },
      },
    }
    let newEventsOfAllNodes = deepCloneObject(eventsOfAllNodes)
    newEventsOfAllNodes['nodef1'] = ['eventf1']
    for (let eventNumber of combination) {
      let eventId = Object.keys(treeDetails.events)[eventNumber]
      let nodeId = treeDetails.events[eventId as EventId].nodeOfEventId
      fakeTreeDetails.nodes[nodeId] = treeDetails.nodes[nodeId]
      fakeTreeDetails.events[eventId as EventId] =
        treeDetails.events[eventId as EventId]
      fakeTreeDetails.events['eventf1'].childrenNodes.push(nodeId)

      let allChildrenNodes = findNodesOfTreeFromRoot(
        nodeId,
        treeDetails,
        [],
        [],
        false,
      )
      if (performance.now() - startTime > duration) {
        return 'calculation failed'
      }
      for (let nodeId of allChildrenNodes) {
        fakeTreeDetails.nodes[nodeId] = treeDetails.nodes[nodeId]
        let eventsOfNode = eventsOfAllNodes[nodeId]
        for (let eventId of eventsOfNode) {
          fakeTreeDetails.events[eventId] = treeDetails.events[eventId]
        }
      }

      newEventsOfAllNodes[nodeId] = [eventId]
    }

    let treeCombinationOfEvent = findTreeCombinations(
      'nodef1',
      'nodef1',
      newEventsOfAllNodes,
      fakeTreeDetails,
      startTime,
      duration,
    )
    if (treeCombinationOfEvent === 'calculation failed') {
      return 'calculation failed'
    }
    replacement = replacement.concat(treeCombinationOfEvent as number[][])
    replacementOfEffectiveEventCombinations.push({
      combination: combination,
      replacement: replacement,
    })
  }

  return replacementOfEffectiveEventCombinations
}

export function findScenarioNumbersForEachEvent(
  eventRelationshipObject: {
    [number: number]: number[][]
  },
  replacementOfEffectiveEventCombinations: {
    combination: number[]
    replacement: number[][]
  }[],
  scenarioNumbersForEachEvent: { [eventNumber: number]: number },
  startTime: number,
  duration: number,
) {
  let tempEventRelationshipObject = deepCloneObject(
    eventRelationshipObject,
  ) as {
    [number: number]: (number | string)[][]
  }

  while (
    Object.keys(scenarioNumbersForEachEvent).length <
    Object.keys(tempEventRelationshipObject).length
  ) {
    if (performance.now() - startTime > duration) {
      return 'calculation failed'
    }
    for (let eventNumber of Object.keys(tempEventRelationshipObject)) {
      if (!Object.keys(scenarioNumbersForEachEvent).includes(eventNumber)) {
        if (tempEventRelationshipObject[parseInt(eventNumber)].length === 0) {
          scenarioNumbersForEachEvent[parseInt(eventNumber)] = 1
        } else {
          for (let [
            combinationIndex,
            combination,
          ] of tempEventRelationshipObject[parseInt(eventNumber)].entries()) {
            // console.log('combination')
            // console.log(combination)

            let eventNumberUsed: number[] = []

            for (let replacementCombination of replacementOfEffectiveEventCombinations) {
              if (
                replacementCombination.combination.every((num) =>
                  combination.includes(num),
                ) &&
                !eventNumberUsed.some((element) =>
                  combination.includes(element),
                )
              ) {
                tempEventRelationshipObject[parseInt(eventNumber)][
                  combinationIndex
                ] = tempEventRelationshipObject[parseInt(eventNumber)][
                  combinationIndex
                ].filter(
                  (event) =>
                    !replacementCombination.combination.includes(
                      event as number,
                    ),
                )
                tempEventRelationshipObject[parseInt(eventNumber)][
                  combinationIndex
                ].push(`f${replacementCombination.replacement.length}`)
                eventNumberUsed = eventNumberUsed.concat(
                  combination as number[],
                )
              }
            }
          }
          for (let [
            combinationIndex,
            combination,
          ] of tempEventRelationshipObject[parseInt(eventNumber)].entries()) {
            for (let eventNumberInCombination of combination) {
              if (
                typeof eventNumberInCombination !== 'string' &&
                scenarioNumbersForEachEvent[eventNumberInCombination]
              ) {
                tempEventRelationshipObject[parseInt(eventNumber)][
                  combinationIndex
                ] = tempEventRelationshipObject[parseInt(eventNumber)][
                  combinationIndex
                ].map((event) =>
                  event === eventNumberInCombination
                    ? `f${
                        scenarioNumbersForEachEvent[
                          eventNumberInCombination as number
                        ]
                      }`
                    : event,
                )
              }
            }
          }
          if (
            allSubarrayElementsStartWithF(
              tempEventRelationshipObject[parseInt(eventNumber)],
            )
          ) {
            let numberOfScenarios = 0
            for (let combination of tempEventRelationshipObject[
              parseInt(eventNumber)
            ]) {
              let combinationScenarios = 1
              for (let fscenario of combination) {
                combinationScenarios *= parseInt(
                  (fscenario as string).replace('f', ''),
                )
              }
              numberOfScenarios += combinationScenarios
              scenarioNumbersForEachEvent[parseInt(eventNumber)] =
                numberOfScenarios
            }
          }
        }
      }
    }
    // console.log('Checking 2')
    // console.log(scenarioNumbersForEachEvent)
    // console.log(JSON.stringify(tempEventRelationshipObject))
  }

  return scenarioNumbersForEachEvent
}

function allSubarrayElementsStartWithF(arr: (string | number)[][]): boolean {
  for (const subarray of arr) {
    for (const element of subarray) {
      if (
        (typeof element === 'string' && !element.startsWith('f')) ||
        typeof element === 'number'
      ) {
        return false
      }
    }
  }
  return true
}

function getCombinations(arr: number[]): number[][] {
  const result: number[][] = []

  function generateCombinations(subarray: number[], start: number) {
    if (subarray.length > 1) {
      result.push(subarray)
    }

    for (let i = start; i < arr.length; i++) {
      generateCombinations(subarray.concat(arr[i]), i + 1)
    }
  }

  generateCombinations([], 0)

  return result
}

export function findParentOfEventsThatAreCousinsAndParents(
  treeDetails: TreeDetails,
): number[] {
  let parentsOfEventsThatAreCousinsAndParents = []
  for (let eventId of Object.keys(treeDetails.events)) {
    if (treeDetails.events[eventId as EventId].childrenNodes.length > 1) {
      for (let childNode of treeDetails.events[eventId as EventId]
        .childrenNodes) {
        let grandchildrenNodes = findNodesOfTreeFromRoot(
          childNode,
          treeDetails,
          [],
          [],
          false,
        ).filter((nodeId) => nodeId !== childNode)

        if (
          findCommonNodes(
            grandchildrenNodes,
            treeDetails.events[eventId as EventId].childrenNodes,
          ).length > 0
        ) {
          parentsOfEventsThatAreCousinsAndParents.push(
            findEventNumber(eventId as EventId, treeDetails) as number,
          )
          break
        }
      }
    }
  }
  return parentsOfEventsThatAreCousinsAndParents
}

export function getTheNumberOfScenariosForParentOfEventsThatAreCousinsAndParents(
  parentOfEventsThatAreCousinsAndParents: number[],
  scenarioNumbersForEachEvent: { [eventNumber: number]: number },
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  const eventsOfAllNodes = findEventsOfAllNodes(treeDetails)
  for (let eventNumber of parentOfEventsThatAreCousinsAndParents) {
    let eventId = Object.keys(treeDetails.events)[eventNumber]
    let nodeId = treeDetails.events[eventId as EventId].nodeOfEventId

    let newEventsOfAllNodes = deepCloneObject(eventsOfAllNodes)
    newEventsOfAllNodes[nodeId] = [eventId]

    let treeCombinationOfEvent = findTreeCombinations(
      nodeId,
      nodeId,
      newEventsOfAllNodes,
      treeDetails,
      startTime,
      duration,
    )
    scenarioNumbersForEachEvent[eventNumber] = treeCombinationOfEvent.length
  }

  return scenarioNumbersForEachEvent
}

export function findCousinEventsOfEvent(
  eventId: EventId,
  treeDetails: TreeDetails,
) {
  let cousinEventsOfEvent: EventId[] = []
  let nodeId = treeDetails.events[eventId].nodeOfEventId
  let parentEventIds = findParentEvents(nodeId, treeDetails)
  if (parentEventIds !== '') {
    for (let parentEvent of parentEventIds) {
      for (let childNode of treeDetails.events[
        parentEvent as EventId
      ].childrenNodes.filter((tempNodeId) => tempNodeId !== nodeId)) {
        cousinEventsOfEvent = cousinEventsOfEvent.concat(
          findEventsOfNode(childNode, treeDetails),
        )
      }
    }
  }
  return cousinEventsOfEvent.map(
    (eventId) => findEventNumber(eventId, treeDetails) as number,
  )
}

export function calculateNumberOfScenarios(
  treeDetails: TreeDetails,
  startTime: number,
  duration: number,
) {
  const eventRelationshipObject = findEventRelationshipObject(
    treeDetails,
    startTime,
    duration,
  )
  // console.log('eventRelationshipObject')
  // console.log(eventRelationshipObject)
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  const eventCombinationsFromMultipleParents =
    findEventCombinationsFromMultipleParents(treeDetails)
  // console.log('eventCombinationsFromMultipleParents')
  // console.log(eventCombinationsFromMultipleParents)
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  const parentOfEventsThatAreCousinsAndParents =
    findParentOfEventsThatAreCousinsAndParents(treeDetails)
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  // console.log('parentOfEventsThatAreCousinsAndParents')
  // console.log(parentOfEventsThatAreCousinsAndParents)

  const effectiveEventCombinations = findEffectiveEventCombinations(
    eventRelationshipObject as { [number: number]: number[][] },
    eventCombinationsFromMultipleParents,
    treeDetails,
    startTime,
    duration,
  )
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  ;(effectiveEventCombinations as number[][]).sort(
    (a, b) => b.length - a.length,
  )
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  // console.log('effectiveEventCombinations')
  // console.log(effectiveEventCombinations)
  const replacementOfEffectiveEventCombinations =
    findReplacementOfEffectiveEventCombinations(
      effectiveEventCombinations as number[][],
      treeDetails,
      startTime,
      duration,
    )

  if (replacementOfEffectiveEventCombinations === 'calculation failed') {
    return 'calculation failed'
  }
  // console.log('replacementOfEffectiveEventCombinations')
  // console.log(replacementOfEffectiveEventCombinations)

  let scenarioNumbersForEachEvent:
    | { [eventNumber: number]: number }
    | 'calculation failed' = {}

  scenarioNumbersForEachEvent =
    getTheNumberOfScenariosForParentOfEventsThatAreCousinsAndParents(
      parentOfEventsThatAreCousinsAndParents,
      scenarioNumbersForEachEvent,
      treeDetails,
      startTime,
      duration,
    )
  if (performance.now() - startTime > duration) {
    return 'calculation failed'
  }
  scenarioNumbersForEachEvent = findScenarioNumbersForEachEvent(
    eventRelationshipObject as { [number: number]: number[][] },
    replacementOfEffectiveEventCombinations,
    scenarioNumbersForEachEvent,
    startTime,
    duration,
  )

  if (scenarioNumbersForEachEvent === 'calculation failed') {
    return scenarioNumbersForEachEvent
  }

  let numberOfScenarios = 0
  let mainTreeRoot = findMainTreeRoot(treeDetails)
  let eventsOfNode = findEventsOfNode(mainTreeRoot, treeDetails)
  for (let eventId of eventsOfNode) {
    numberOfScenarios +=
      scenarioNumbersForEachEvent[
        findEventNumber(eventId, treeDetails) as number
      ]
  }

  return numberOfScenarios
}

export function findCommonNodes(
  nodeArray1: NodeId[],
  nodeArray2: NodeId[],
): NodeId[] {
  const set1 = new Set(nodeArray1)
  const set2 = new Set(nodeArray2)

  return [...new Set([...set1].filter((item) => set2.has(item)))]
}

export function findEventNumber(
  eventId: EventId,
  treeDetails: TreeDetails,
): number | '' {
  let eventNumber: number | '' = ''
  Object.keys(treeDetails.events).forEach((currentEventId, index) => {
    if (eventId === currentEventId) {
      eventNumber = index
    }
  })

  return eventNumber
}

export function findNodeNumber(
  nodeId: NodeId,
  treeDetails: TreeDetails,
): number | '' {
  let nodeNumber: number | '' = ''
  Object.keys(treeDetails.nodes).forEach((currentNodeId, index) => {
    if (nodeId === currentNodeId) {
      nodeNumber = index
    }
  })

  return nodeNumber
}

export function changeEventKeysInNodeSiblingIndex(
  nodeSiblingIndex: { [parentEventId: EventId]: number },
  treeDetails: TreeDetails,
) {
  const tempNodeSiblingIndex: Record<string, any> = {}

  for (const eventId in nodeSiblingIndex) {
    const newKey = `  Event ${findEventNumber(
      eventId as EventId,
      treeDetails,
    )}  `
    tempNodeSiblingIndex[newKey] = nodeSiblingIndex[eventId as EventId]
  }
  return tempNodeSiblingIndex
}
