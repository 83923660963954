import React from 'react'

type Props = {
  forSettings?: boolean
}

export default function LoadingCasesPlaceHolder(props: Props) {
  return (
    <>
      <div className={`myCasesBox ${props.forSettings ? 'for-settings' : ''}`}>
        <div className="myCasesBox-content-container">
          <div
            className="myCasesBox-searchBar"
            style={{ width: 305, height: 50, marginBottom: 60 }}
          >
            <div className="searchBarContainer">
              <div className="searchBarInput"></div>
            </div>
          </div>

          <div className="myCasesListContainer noscroll">
            {[1, 2, 3].map((item, index) => (
              <div key={index} className="caseContainer">
                <div className={`movingShadow${item}`}></div>
              </div>
            ))}
          </div>
          <div className="addNewCaseContainer"></div>
        </div>
      </div>
    </>
  )
}
