import React from 'react'
import {
  ResultsObject,
  ResultsSettings,
  ScenarioIdentity,
} from '../../../../../../../../models/generalTypes'
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from '@react-pdf/renderer'

import DateOfTrialComponentDocument from './DateOfTrialComponentDocument'
import SecondTrialProbsComponentDocument from './SecondTrialProbsComponentDocument'
import TextForExecutiveSummaryDocument from './TextForReportDocument'
import {
  ReportStateType,
  ReportWhitelabel,
} from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import {
  FontLabels,
  ReportWhitelabelKeys,
  SectionTitleKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  getText,
  reportSectionType,
} from '../../../../../../../../services/textFunctions'
import { User } from '../../../../../../../../models/user'
import eperotoLogo from '../../../../../../../../resources/images/216-EperotoLogo_Black_Big.jpg'
import { convertDateTimeToLocal } from '../../../../../../../../services/dateFunctions'
import { DateTime } from 'luxon'
import {
  deepCloneObject,
  getClientDomain,
  isDevEnv,
} from '../../../../../../../../services/commonFunctions'
import { ReportSectionClass } from '../../../../../../../../models/reportModels/reportSection'
import ClaimsOverviewComponentDocument from './ClaimsOverviewComponentDocument'
import {
  snapshotHasEnforcementRisk,
  getStyleFromWhitelabel,
} from '../../../../../../../../services/reportFunctions'
import './all_fonts'
import LegalCostsPreviewComponentDocument from './LegalCostsPreviewComponentDocument'
import ClaimsLegendDocument from './ClaimsLegendDocument'
import ExecutiveSummary3Document from './ExecutiveSummary3Document'
import AwaLegendDocument from './AwaLegendDocument'

type Props = {
  resultsFromBackend: ResultsObject
  claimsImagesArray: string[]
  selectionGraphImg: string
  distributionGraphImg: string
  getPayGraphImg: string
  expectedValueBreakdownImg: string
  bestAndWorsScenarioImg: string
  zopaGraphImg: string
  reportDetails: ReportStateType
  currentSnapshot: ScenarioSnapshot
  scenarioIdentity: ScenarioIdentity
  companyName: string
  user: User
  roundedResults: boolean
  reportWhitelabel?: ReportWhitelabel
  krogerusColors: boolean
  resultsSettings: ResultsSettings
}

const colors = {
  ageanMidnight: '#201a2d',
  popCulture: '#ff7336',
  lightGray: '#828282',
  infantryWest: '#47743d',
  greekEaster: '#ac2828',
  lakersUniform: '#331a5b',
  tuesdayGray: '#6f6f6f',
  mondayGray: '#8e8e8e',
  thursdayGray: '#e9e9e9',
}

export default function PdfDocument(props: Props) {
  /* 
  WE ARE NOT ALLOWED TO USE RECOIL GLOBAL STATE HERE.
  PASS THE GLOBAL STATE AS PROP
  */

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      backgroundColor: 'white',
      padding: '40px',
      margin: '0px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.none,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    caseDetailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      minHeight: '76px',
    },
    caseNameTitle: {
      fontSize: '18px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportTitle,
          FontLabels.medium,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Medium',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportTitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      maxWidth: '420px',
      textAlign: 'left',
      lineHeight: 1.1,
    },
    caseNameSubtitle: {
      width: '400px',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.lightItalic,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light-Italic',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    scenarioNameTitle: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      marginTop: '4px',
      maxWidth: '395px',
    },
    eperotoLogoAndDateContainer: {
      position: 'absolute',
      right: '0px',
      top: '0px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    eperotoLogo: {
      width: '95px',
      height: '61px',
      objectFit: 'contain',
      marginBottom: '1px',
    },
    currentDate: {
      fontSize: '8px',
      marginTop: '2px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.reportSubtitle,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.reportSubtitle,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    containerTitle: {
      marginTop: '28px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.semiBold,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-SemiBold',
      fontSize: '12px',
      color:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionTitle,
          FontLabels.semiBold,
          'noRoboto',
          true,
        ).color ?? colors.ageanMidnight,
    },
    containerParagraph: {
      marginTop: '2px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      fontSize: '8px',
      width: '496px',
      lineHeight: 1.25,
    },
    claimParagraph: {
      marginRight: '0px',
      marginLeft: '0px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      fontSize: '8px',
      width: '326px',
      lineHeight: 1.25,
    },
    independentClaimPreviewContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginTop: '30px',
    },
    independentClaimPreviewImage: {
      width: '160px',
      marginRight: '10px',
    },
    treePreviewContainer: {
      marginLeft: '-20px',

      maxWidth: '550px',
    },
    treePreviewImage: {
      maxHeight: '520px',
      objectFit: 'scale-down',
    },
    enforcementDetailsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginLeft: '13px',
      marginTop: '13px',
    },
    enforcementPercentage: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.semiBold,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-SemiBold',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
    },
    enforcementText: {
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).color,
      marginTop: getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.none,
        'noRoboto',
        true,
      ).marginTop,
      marginLeft: '4px',
    },
    selectionGraphContainer: {
      width: '500px',
      marginTop: '-5px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    distributionGraphContainer: {
      width: '500px',
      marginTop: '30px',
    },
    graphImg: {
      width: '500px',
      marginLeft: '-50px',
    },
    bestAndWorsScenarioImg: {
      marginLeft: '0px',
      width: '400px',
    },
    pageFooter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '10px',
      width: '514px',
      bottom: '-10px',
    },
    caseNameTitleFooter: {
      position: 'absolute',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: colors.lightGray,
      width: '230px',
      bottom: '18px',
      left: '25px',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: '10px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: colors.lightGray,
      left: '292px',
      bottom: '18px',
    },
    eperotoLogoFooter: {
      position: 'absolute',
      width: '33px',
      bottom: '15px',
      left: '524px',
    },
    poweredByFooter: {
      position: 'absolute',
      width: '46px',
      bottom: '18px',
      left: '475px',
      fontSize: '8px',
      fontFamily:
        getStyleFromWhitelabel(
          props.reportWhitelabel,
          ReportWhitelabelKeys.sectionDescription,
          FontLabels.light,
          'noRoboto',
          true,
        ).fontFamily ?? 'Poppins-Light',
      color: colors.lightGray,
    },
  })

  function shouldSectionWrap(sectionToCheck: ReportSectionClass): boolean {
    if (
      sectionToCheck.title !== SectionTitleKeys.Claims &&
      sectionToCheck.title !== SectionTitleKeys.ClaimsOverview &&
      sectionToCheck.title !== SectionTitleKeys.ExecutiveSummary &&
      props.reportDetails.reportData.sections.filter(
        (section) => section.title === sectionToCheck.title,
      )[0].contents.description === ''
    ) {
      return false
    }
    return true
  }

  function wrapText(text: string, fontSize: number, maxWidth: number) {
    let words = text.split(' ')
    let lines = []
    let line = ''

    for (let word of words) {
      if (word.length * fontSize > maxWidth) {
        // If the word is too long to fit within the max width,
        // split it into smaller pieces and add each piece to a new line
        let chars = word.split('')
        let charLine = ''

        for (let char of chars) {
          charLine += char
          if (charLine.length * fontSize > maxWidth) {
            lines.push(charLine)
            charLine = ''
          }
        }

        if (charLine.length > 0) {
          lines.push(charLine)
        }
      } else {
        if (line.length + word.length <= maxWidth / fontSize) {
          line += `${word} `
        } else {
          lines.push(line)
          line = `${word} `
        }
      }
    }

    if (line.length > 0) {
      lines.push(line)
    }

    return lines.join('\n')
  }

  function getLegalCostsDescriptions() {
    return props.reportDetails.reportData.sections.filter(
      (section) => section.title === SectionTitleKeys.LegalCosts,
    )[0].contents.legalCosts!
  }

  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View wrap={false} style={styles.caseDetailsContainer}>
            <View>
              <Text style={styles.caseNameTitle}>
                {wrapText(props.scenarioIdentity.caseName, 17, 800)}
              </Text>
            </View>
            <Text
              style={styles.scenarioNameTitle}
              id="scenarioNameTitle"
              data-textattribute="description-94"
            >
              {getText('description-94', props.user.settings)}{' '}
              {props.scenarioIdentity.scenarioName}
            </Text>
            <Text
              style={styles.caseNameSubtitle}
              id="caseNameSubtitle"
              data-textattribute="description-93"
            >
              {getText('description-93', props.user.settings)}
            </Text>
            <View style={styles.eperotoLogoAndDateContainer}>
              <Image
                src={
                  props.user.companyLogo
                    ? isDevEnv()
                      ? `http://localhost:8000${props.user.companyLogo}`
                      : `${'https://'}${getClientDomain()}${
                          props.user.companyLogo
                        }`
                    : eperotoLogo
                }
                style={styles.eperotoLogo}
              />
              <Text style={styles.currentDate}>
                {props.reportDetails.reportData.date === 'currentDate'
                  ? convertDateTimeToLocal(
                      DateTime.now().toFormat('yyyy-MM-dd'),
                      props.user.settings,
                      true,
                    )
                  : props.reportDetails.reportData.date}
              </Text>
            </View>
          </View>
          {deepCloneObject(props.reportDetails.reportData.sections)
            .sort(
              (sectionA: ReportSectionClass, sectionB: ReportSectionClass) =>
                sectionA.sectionOrder - sectionB.sectionOrder,
            )
            .map((section: ReportSectionClass, index: number) => {
              if (section.show) {
                return (
                  <View
                    wrap={shouldSectionWrap(section)}
                    key={`section${index}`}
                  >
                    <View wrap={false}>
                      <Text style={styles.containerTitle}>
                        {section.customTitle !== ''
                          ? section.customTitle
                          : reportSectionType(props.user.settings)[
                              section.title as SectionTitleKeys
                            ]}
                      </Text>
                      {section.contents.description &&
                      section.contents.description.length > 0 ? (
                        <Text style={styles.containerParagraph}>
                          {section.contents.description}
                        </Text>
                      ) : null}
                    </View>
                    {section.contents.executiveSummary ? (
                      props.resultsSettings.settings.executiveSummary ===
                      'old' ? (
                        <TextForExecutiveSummaryDocument
                          resultsFromBackend={props.resultsFromBackend}
                          visibleElements={
                            section.contents.executiveSummary.visibleElements
                          }
                          currentSnapshot={props.currentSnapshot}
                          user={props.user}
                          reportWhitelabel={props.reportWhitelabel}
                          zopaGraphImg={props.zopaGraphImg}
                          legalCostsDescriptions={getLegalCostsDescriptions()}
                          roundedResults={props.roundedResults}
                        />
                      ) : (
                        <ExecutiveSummary3Document
                          resultsFromBackend={props.resultsFromBackend}
                          visibleElements={
                            section.contents.executiveSummary.visibleElements
                          }
                          currentSnapshot={props.currentSnapshot}
                          user={props.user}
                          reportWhitelabel={props.reportWhitelabel}
                          zopaGraphImg={props.zopaGraphImg}
                          legalCostsDescriptions={getLegalCostsDescriptions()}
                          roundedResults={props.roundedResults}
                        />
                      )
                    ) : null}
                    {section.contents.proceedings ? (
                      <>
                        <DateOfTrialComponentDocument
                          defaultInstance={props.currentSnapshot.court!}
                          trial="first"
                          date={props.currentSnapshot.firstTrialDate!}
                          colors={colors}
                          reportDetails={props.reportDetails}
                          user={props.user}
                          proceedings={section.contents.proceedings}
                          reportWhitelabel={props.reportWhitelabel}
                        />
                        {props.currentSnapshot.probSecondTrial! > 0 ? (
                          <View>
                            <DateOfTrialComponentDocument
                              defaultInstance={props.currentSnapshot.court!}
                              trial="second"
                              date={props.currentSnapshot.secondTrialDate!}
                              colors={colors}
                              reportDetails={props.reportDetails}
                              user={props.user}
                              proceedings={section.contents.proceedings}
                              reportWhitelabel={props.reportWhitelabel}
                            />

                            <SecondTrialProbsComponentDocument
                              colors={colors}
                              currentSnapshot={props.currentSnapshot}
                              user={props.user}
                              reportWhitelabel={props.reportWhitelabel}
                            />
                          </View>
                        ) : null}
                      </>
                    ) : null}
                    {section.contents.claimsOverview ? (
                      <>
                        <ClaimsOverviewComponentDocument
                          colors={colors}
                          currentSnapshot={props.currentSnapshot}
                          user={props.user}
                          reportWhitelabel={props.reportWhitelabel}
                        />
                      </>
                    ) : null}
                    {section.contents.claims ? (
                      <View
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          flexDirection: 'row',
                        }}
                      >
                        {props.currentSnapshot.claims.map((claim, index2) =>
                          claim.type !== 'tree' ? (
                            <View
                              key={`independentClaimPreviewContainer${index2}`}
                              style={
                                section.contents.claims![index2].description
                                  .length > 0
                                  ? {
                                      ...styles.independentClaimPreviewContainer,
                                    }
                                  : {
                                      ...styles.independentClaimPreviewContainer,
                                      flexDirection: 'column',
                                    }
                              }
                              wrap={false}
                            >
                              <View>
                                <Image
                                  src={props.claimsImagesArray[index2]}
                                  style={styles.independentClaimPreviewImage}
                                />
                              </View>

                              <Text
                                style={
                                  section.contents.claims![index2].description
                                    .length > 0
                                    ? {
                                        ...styles.claimParagraph,
                                      }
                                    : {
                                        ...styles.claimParagraph,
                                        width: 0,
                                      }
                                }
                              >
                                {section.contents.claims![index2].description}
                              </Text>
                            </View>
                          ) : (
                            <View
                              key={`treePreviewContainer${index2}`}
                              wrap={false}
                            >
                              <Text
                                style={
                                  section.contents.claims![index2]
                                    .description === ''
                                    ? undefined
                                    : {
                                        ...styles.containerParagraph,
                                      }
                                }
                              >
                                {section.contents.claims![index2].description}
                              </Text>
                              <View
                                style={styles.treePreviewContainer}
                                id={`treePreviewContainer${index2}`}
                                wrap={false}
                              >
                                <Image
                                  src={props.claimsImagesArray[index2]}
                                  style={styles.treePreviewImage}
                                />
                              </View>
                            </View>
                          ),
                        )}
                        <ClaimsLegendDocument
                          currentSnapshot={props.currentSnapshot}
                          reportWhitelabel={props.reportWhitelabel}
                          user={props.user}
                          krogerusColors={props.krogerusColors}
                        />
                      </View>
                    ) : null}

                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.LegalCosts ? (
                      <View wrap={true}>
                        <LegalCostsPreviewComponentDocument
                          currentSnapshot={props.currentSnapshot}
                          user={props.user}
                          legalCostsDescriptions={section.contents.legalCosts!}
                          reportWhitelabel={props.reportWhitelabel}
                          forLegalCostsComponent
                        />
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.LegalCostsDistribution ? (
                      <View
                        wrap={false}
                        style={{
                          width: '450px',
                          marginLeft: '20px',
                          marginBottom: '-10px',
                        }}
                      >
                        <Image
                          src={
                            props.claimsImagesArray[
                              props.claimsImagesArray.length - 1
                            ]
                          }
                          style={{
                            marginLeft: '20px',
                            marginTop: '12px',
                            width: '400px',
                          }}
                        />
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.EnforcementRisk ? (
                      <View wrap={true}>
                        <View style={styles.enforcementDetailsContainer}>
                          <Text style={styles.enforcementPercentage}>
                            {snapshotHasEnforcementRisk(props.currentSnapshot)
                              ? props.currentSnapshot.bankruptcyPercentage
                              : 0}
                            %
                          </Text>
                          <Text
                            style={styles.enforcementText}
                            id="enforcementForReportTitlePdf"
                            data-textattribute="title-143"
                          >
                            {getText('title-143', props.user.settings)}
                          </Text>
                        </View>
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.SelectionGraph ? (
                      <View wrap={false} style={styles.selectionGraphContainer}>
                        <View style={{ marginLeft: 20 }}>
                          <Image
                            style={styles.graphImg}
                            src={props.selectionGraphImg}
                          />
                          <AwaLegendDocument
                            resultsFromBackend={props.resultsFromBackend}
                            reportWhitelabel={props.reportWhitelabel}
                            user={props.user}
                            currentSnapshot={props.currentSnapshot}
                            type="selectionGraph"
                          />
                        </View>
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.ProbabilityDistributionGraph ? (
                      <View
                        wrap={false}
                        style={styles.distributionGraphContainer}
                      >
                        <View>
                          <Image
                            style={{
                              marginLeft: 20,
                              marginBottom: 0,
                            }}
                            src={props.distributionGraphImg}
                          />
                          <AwaLegendDocument
                            resultsFromBackend={props.resultsFromBackend}
                            reportWhitelabel={props.reportWhitelabel}
                            user={props.user}
                            currentSnapshot={props.currentSnapshot}
                            type="distributionGraph"
                          />
                        </View>
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.GetPayGraph ? (
                      <View
                        wrap={false}
                        style={styles.distributionGraphContainer}
                      >
                        <View>
                          <Image
                            style={{
                              marginTop: -20,
                              marginBottom: 0,
                            }}
                            src={props.getPayGraphImg}
                          />
                          <AwaLegendDocument
                            resultsFromBackend={props.resultsFromBackend}
                            reportWhitelabel={props.reportWhitelabel}
                            user={props.user}
                            currentSnapshot={props.currentSnapshot}
                            type="getPayGraph"
                          />
                        </View>
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.ExpectedValueBreakdown ? (
                      <View
                        wrap={false}
                        style={styles.distributionGraphContainer}
                      >
                        <View>
                          <Image
                            style={{
                              marginLeft: 20,
                              marginTop: -10,
                              marginBottom: 0,
                            }}
                            src={props.expectedValueBreakdownImg}
                          />
                          <AwaLegendDocument
                            resultsFromBackend={props.resultsFromBackend}
                            reportWhitelabel={props.reportWhitelabel}
                            user={props.user}
                            currentSnapshot={props.currentSnapshot}
                            type="expectedValueBreakdown"
                          />
                        </View>
                      </View>
                    ) : null}
                    {(section.title as SectionTitleKeys) ===
                    SectionTitleKeys.BestWorstScenario ? (
                      <View wrap={false} style={styles.selectionGraphContainer}>
                        <View
                          style={{ marginLeft: '-20px', marginTop: '10px' }}
                        >
                          <Image
                            style={styles.bestAndWorsScenarioImg}
                            src={props.bestAndWorsScenarioImg}
                          />
                        </View>
                      </View>
                    ) : null}
                  </View>
                )
              } else {
                return null
              }
            })}
          <View wrap={false}>
            <Text
              style={styles.containerTitle}
              id="pdfDisclaimerTitle"
              data-textattribute="title-144"
            >
              {getText('title-144', props.user.settings)}
            </Text>
            {props.reportWhitelabel &&
            props.reportWhitelabel.disclaimer.text !== 'default' ? (
              props.reportWhitelabel.disclaimer.text
                .split('\n')
                .filter((par) => par.trim().length > 0)
                .map((par, index) => (
                  <Text
                    key={index}
                    id="pdfDisclaimerText"
                    style={{
                      ...styles.containerParagraph,
                      marginTop: index === 0 ? '' : '7px',
                    }}
                  >
                    {par}
                  </Text>
                ))
            ) : (
              <>
                <Text
                  style={styles.containerParagraph}
                  id="pdfDisclaimerText1"
                  data-textattribute="description-95a, description-95b"
                >
                  {getText('description-95a', props.user.settings)}{' '}
                  {props.companyName}{' '}
                  {getText('description-95b', props.user.settings)}
                </Text>
                <Text
                  style={{ ...styles.containerParagraph, marginTop: '7px' }}
                  id="pdfDisclaimerText2"
                  data-textattribute="description-96"
                >
                  {getText('description-96', props.user.settings)}{' '}
                  {props.companyName}.
                </Text>
              </>
            )}
            <Text
              style={{ ...styles.containerParagraph, marginTop: '7px' }}
              id="pdfDisclaimerText3"
              data-textattribute="description-254"
            >
              {getText('description-254', props.user.settings)}
            </Text>
          </View>

          <Text
            style={styles.caseNameTitleFooter}
            render={() => `${props.scenarioIdentity.caseName}`}
            fixed
          />
          <Text
            render={({ pageNumber }) => `${pageNumber}`}
            style={styles.pageNumber}
            fixed
          />
          <Text
            render={() => `Powered by`}
            style={styles.poweredByFooter}
            fixed
          />
          <Image src={eperotoLogo} style={styles.eperotoLogoFooter} fixed />
        </Page>
      </Document>
    </>
  )
}
