import React, { useState } from 'react'
import './Chip.css'
import { useRecoilValue } from 'recoil'
import { freemiumState } from '../../../states/FreemiumState'
import { logActivity } from '../../../services/commonFunctions'

type Props = {
  id: string
  img: string
  defaultState: 'off' | 'on'
  onClick: (state: 'off' | 'on') => void
}

export default function Chip(props: Props) {
  const { img, onClick, defaultState, id } = props
  const [chipState, setChipState] = useState(defaultState)
  const freemium = useRecoilValue(freemiumState)

  return (
    <>
      <div
        className="chipContainer"
        onClick={() => {
          //Mixpanel 76 (All)
          logActivity(freemium.isFreemium, 'Clicked results list chip', {
            'Chip Id': props.id,
          })
          if (chipState === 'on') {
            setChipState('off')
            onClick('off')
          } else {
            setChipState('on')
            onClick('on')
          }
        }}
        style={
          chipState === 'on'
            ? { backgroundColor: '#e1d6f4' } //springLevanter
            : { backgroundColor: 'white' }
        }
        id={id}
      >
        <img src={img} alt="chipImage" className="chipImage" />
      </div>
    </>
  )
}
