import React from 'react'
import {
  AllowedAccessRole,
  AllowedAccessRoleFromString,
  UserRole,
} from '../../../models/enums'
import { UserForAccess } from '../../../models/generalTypes'
import { dropDownValidation } from '../../../Modules/AppModules/ValidationFunctions'
import DropDown from '../../Inputs/DropDown/DropDown'
import { AccessMenuAction } from '../AccessComponent'

type Props = {
  user: UserForAccess
  tempAccessRole: AllowedAccessRole
  changeAccessButtonType: (value: AccessMenuAction, index: number) => void
  changeTempAccessRole: (value: AllowedAccessRole, index: number) => void
  index: number
  forInviting?: boolean
}

const UserAccessRole = (props: Props) => {
  const onChange = (e: any) => {
    const newAccessRole = AllowedAccessRoleFromString(e.target.value)
    props.changeTempAccessRole(newAccessRole, props.index)
    if (
      props.user.access_role &&
      newAccessRole.toString() !== props.user.access_role.toString()
    ) {
      props.changeAccessButtonType('change', props.index)
    } else {
      props.changeAccessButtonType(
        props.user.self ? 'leave' : props.forInviting ? 'add' : 'remove',
        props.index,
      )
    }
  }

  const getAvailableOptions = () => {
    const values = props.forInviting ? [AllowedAccessRole.NONE] : []

    if (props.user.user_role === UserRole.GLOBAL_ADMIN) {
      values.push(AllowedAccessRole.OWNER)
    } else if (props.user.user_role !== UserRole.APP_VIEWER) {
      values.push(
        AllowedAccessRole.VIEWER,
        AllowedAccessRole.EDITOR,
        AllowedAccessRole.OWNER,
      )
    } else {
      values.push(AllowedAccessRole.VIEWER)
    }

    return values
  }

  return (
    <>
      <DropDown
        id={'dropDownAccessRole'}
        label={''}
        name={'dropDownAccessRole'}
        value={props.tempAccessRole}
        width={135}
        validateFunction={(value: string) => dropDownValidation(value)}
        options={getAvailableOptions()}
        onChange={onChange}
        labelTextAttribute={undefined}
        forAccess
      />
    </>
  )
}

export default UserAccessRole
