import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { removeUnderscore } from '../../../../../../../../services/commonFunctions'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { getText } from '../../../../../../../../services/textFunctions'
import clientImg from '../../../../../../../../resources/images/279-clientSymbol.svg'
import krogerusClientImg from '../../../../../../../../resources/images/303-clientSymbolKrogerus.svg'
import opposingPartyImg from '../../../../../../../../resources/images/280-opposingPartySymbol.svg'
import krogerusOpposingPartyImg from '../../../../../../../../resources/images/304-krogerusOpposingPartySymbol.svg'
import reduceClientImg from '../../../../../../../../resources/images/295-reducedInputClient-NoParenthesis.svg'
import krogerusReducedClientNoParenthesisImg from '../../../../../../../../resources/images/309-krogerusReducedInputClient-NoParenthesis.svg'
import reduceOpposingPartyImg from '../../../../../../../../resources/images/296-reducedInputOpposingParty-NoParenthesis.svg'
import krogerusReducedOpposingPartyNoParenthesisImg from '../../../../../../../../resources/images/310-krogerusReducedInputOpposingParty-NoParenthesis.svg'
import externalClientImg from '../../../../../../../../resources/images/284-positiveExternalClient.svg'
import krogerusPositiveExternalClientImg from '../../../../../../../../resources/images/305-krogerusPositiveExternalClient.svg'
import externalOpposingPartyImg from '../../../../../../../../resources/images/291-positiveExternalOpposingParty.svg'
import krogerusPositiveExternalOpposingPartyImg from '../../../../../../../../resources/images/317-krogerusPositiveExternalOpposingParty.svg'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../../../../../../states/UserState'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { useMemo, useState } from 'react'
import {
  atLeastOneTreeHasExternalValue,
  atLeastOneTreeHasReducedAmounts,
} from '../../../../../../../../services/resultsFunctions'
import { krogerusColorsState } from '../../../../../../../../states/KrogerusColorsState'

type Props = {
  reportWhitelabel: ReportWhitelabel | undefined
}

const ClaimsLegendPreview = (props: Props) => {
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot
  const [hasExternalValue, setHasExternalValue] = useState<boolean>(false)
  const [hasReducedAmount, setHasReducedAmount] = useState<boolean>(false)

  useMemo(() => {
    setHasExternalValue(atLeastOneTreeHasExternalValue(currentSnapshot))
    setHasReducedAmount(atLeastOneTreeHasReducedAmounts(currentSnapshot))
  }, [currentSnapshot])

  return (
    <div
      className="claimsPreviewMemo"
      style={getStyleFromWhitelabel(
        props.reportWhitelabel,
        ReportWhitelabelKeys.sectionDescription,
        FontLabels.regular,
        'noRoboto',
      )}
    >
      <div
        className={`claimsPreviewMemoColumn ${
          !hasReducedAmount && !hasExternalValue
            ? 'twoColumns'
            : !hasReducedAmount || !hasExternalValue
            ? 'threeColumns'
            : ''
        } descriptions`}
      >
        <p
          className="claimsPreviewMemoColumnText invisible"
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
        >
          No Text
        </p>
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.regular,
            'noRoboto',
          )}
          className="claimsPreviewMemoColumnText"
          data-textattribute="title-258"
        >
          {getText('title-258', user.settings)}:
        </p>
        {hasReducedAmount && (
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className="claimsPreviewMemoColumnText"
            data-textattribute="title-259"
          >
            {getText('title-259', user.settings)}:
          </p>
        )}
        {hasExternalValue && (
          <p
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
            className="claimsPreviewMemoColumnText"
            data-textattribute="title-64"
          >
            {getText('title-64', user.settings)}:
          </p>
        )}
      </div>
      <div
        className={`claimsPreviewMemoColumn ${
          !hasReducedAmount && !hasExternalValue
            ? 'twoColumns'
            : !hasReducedAmount || !hasExternalValue
            ? 'threeColumns'
            : ''
        } `}
      >
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
          )}
          className="claimsPreviewMemoColumnText bold"
        >
          {removeUnderscore(currentSnapshot.partyFormatOwn)}
        </p>
        <img
          className="claimsPreviewMemoColumnImage"
          src={krogerusColors ? krogerusClientImg : clientImg}
          alt="clientImg"
        />
        {hasReducedAmount && (
          <img
            className="claimsPreviewMemoColumnImage bigger"
            src={
              krogerusColors
                ? krogerusReducedClientNoParenthesisImg
                : reduceClientImg
            }
            alt="reduceClientImg"
          />
        )}
        {hasExternalValue && (
          <img
            className="claimsPreviewMemoColumnImage lessMargin"
            src={
              krogerusColors
                ? krogerusPositiveExternalClientImg
                : externalClientImg
            }
            alt="externalClientImg"
          />
        )}
      </div>
      <div
        className={`claimsPreviewMemoColumn ${
          !hasReducedAmount && !hasExternalValue
            ? 'twoColumns'
            : !hasReducedAmount || !hasExternalValue
            ? 'threeColumns'
            : ''
        }`}
      >
        <p
          style={getStyleFromWhitelabel(
            props.reportWhitelabel,
            ReportWhitelabelKeys.sectionDescription,
            FontLabels.bold,
            'noRoboto',
          )}
          className="claimsPreviewMemoColumnText bold"
        >
          {removeUnderscore(currentSnapshot.partyFormatOther)}
        </p>
        <img
          className="claimsPreviewMemoColumnImage"
          src={krogerusColors ? krogerusOpposingPartyImg : opposingPartyImg}
          alt="opposingPartyImg"
        />
        {hasReducedAmount && (
          <img
            className="claimsPreviewMemoColumnImage biggerOpposite"
            src={
              krogerusColors
                ? krogerusReducedOpposingPartyNoParenthesisImg
                : reduceOpposingPartyImg
            }
            alt="reduceOpposingPartyImg"
          />
        )}
        {hasExternalValue && (
          <img
            className="claimsPreviewMemoColumnImage lessMargin"
            src={
              krogerusColors
                ? krogerusPositiveExternalOpposingPartyImg
                : externalOpposingPartyImg
            }
            alt="externalOpposingPartyImg"
          />
        )}
      </div>
    </div>
  )
}

export default ClaimsLegendPreview
