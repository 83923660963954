import React, { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import pathArrow from '../../../../resources/images/099-arrow-blue.svg'
import scenariosArrow from '../../../../resources/images/093-arrow-orange.svg'
import tutorialImg from '../../../../resources/images/221-tutorialImg.svg'
import CutPathName from './CutPathName'
import { Params } from '../../../../models/generalTypes'
import { SaveMenuEnum, SideMenuPages } from '../../../../models/enums'
import { WhiteTooltipMoreDelay } from '../../../../Components/tooltip/TooltipComponent'
import { useRecoilState, useRecoilValue } from 'recoil'
import { pageDepthState } from '../../../../states/PageDepthState'
import { savedReportState } from '../../../../states/SavedReportState'
import { savedState } from '../../../../states/SavedState'
import { onlineState } from '../../../../states/OnlineState'
import { reportMenuState } from '../../../../states/ReportMenuState'
import { getText } from '../../../../services/textFunctions'
import { userState } from '../../../../states/UserState'
import { allowShortcutsState } from '../../../../states/AllowShortcutsState'
import { recoveryModeState } from '../../../../states/RecoveryModeState'
import { freemiumState } from '../../../../states/FreemiumState'
import { logActivity } from '../../../../services/commonFunctions'

type Props = {
  pathTitle: string | SideMenuPages
  isFinal: boolean
  isScenario?: boolean
  link?: string
  scenariosList?: { [key: string]: string }[]
  setSaveMenuOn: (param: string | undefined) => void
  saveMenuOn?: string | undefined
  isTutorial?: boolean
}

export default function PathComponent(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [recoveryMode, setRecoveryMode] = useRecoilState(recoveryModeState)

  const navigate = useNavigate()
  const params = useParams<Params>()
  const [pageDepth, setPageDepth] = useRecoilState(pageDepthState)
  const savedReport = useRecoilValue(savedReportState)
  const savedSnapshot = useRecoilValue(savedState)
  const [online, setOnline] = useRecoilState(onlineState)
  const [reportMenu, setReportMenu] = useRecoilState(reportMenuState)
  const [hovering, setHovering] = useState(false)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const [scenarioSubMenu, setScenarioSubMenu] = useState(false)
  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    document.addEventListener('keydown', onKeyPress, false)

    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
      document.removeEventListener('keydown', onKeyPress, false)
    }
    // eslint-disable-next-line
  }, [scenarioSubMenu, allowShortcuts])

  useEffect(() => {
    if (
      scenarioSubMenu === false &&
      reportMenu.downloadReportFixedHeaderOn === false &&
      props.saveMenuOn === undefined
    ) {
      setReportMenu({ ...reportMenu, downloadReportFixedHeaderOn: true })
    }
    // eslint-disable-next-line
  }, [scenarioSubMenu])

  const onKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape' && allowShortcuts) {
      setScenarioSubMenu(false)
    }
  }

  const onMouseDown = (e: any) => {
    if (!e.target.id.includes('pathTitleScenario')) {
      setScenarioSubMenu(false)
    }
  }

  function navigateTo() {
    if (props.link === '/') {
      //Mixpanel 86 (All)
      logActivity(freemium.isFreemium, 'Accessed My Cases through breadcrumb')
    } else {
      //Mixpanel 87 (All)
      logActivity(
        freemium.isFreemium,
        'Accessed My Scenarios through breadcrumb',
      )
    }
    if (
      pageDepth < 3 ||
      (pageDepth === 3 &&
        (savedSnapshot.saved || recoveryMode.recoveryPreview !== 'none')) ||
      (pageDepth === 3.1 && savedReport.saved)
    ) {
      if (online.networkOn) {
        if (props.link) {
          navigate(props.link)
        }
      } else {
        setOnline({ ...online, shaking: true })
      }
    } else {
      setReportMenu({ ...reportMenu, downloadReportFixedHeaderOn: false })
      if (props.pathTitle === SideMenuPages.MyCases) {
        props.setSaveMenuOn(SaveMenuEnum.mycases)
      } else {
        props.setSaveMenuOn(SaveMenuEnum.myscenarios)
      }
    }
  }

  function navigateToScenario(scenarioId: string) {
    setScenarioSubMenu(false)
    //Mixpanel 89 (All)
    logActivity(
      freemium.isFreemium,
      'Opened a scenario through breadcrumb menu',
    )
    if (
      (pageDepth === 3 &&
        (savedSnapshot.saved || recoveryMode.recoveryPreview !== 'none')) ||
      (pageDepth === 3.1 && savedReport.saved)
    ) {
      if (online.networkOn) {
        navigate(`/mycases/${params.caseId}/${scenarioId}/tool`)
        setPageDepth(3)
        setReportMenu({
          downloadReportFixedHeaderOn: false,
          orderMenuOn: false,
          downloadPdf: false,
          reportSaveMenuOn: undefined,
          reportWithDifferentResults: false,
          previewPdfOn: false,
        })
        setRecoveryMode({
          recoveryPreview: 'none',
          shaking: false,
          showLowScreenMessage: false,
          keepResults: false,
          old: undefined,
          new: undefined,
        })
      } else {
        setOnline({ ...online, shaking: true })
      }
    } else {
      setReportMenu({ ...reportMenu, downloadReportFixedHeaderOn: false })
      props.setSaveMenuOn(scenarioId)
    }
  }

  function toggleScenarioSubMenu() {
    if (scenarioSubMenu) {
      setScenarioSubMenu(false)
    } else {
      //Mixpanel 89 (All)
      logActivity(
        freemium.isFreemium,
        'Opened My Scenarios through breadcrumb menu',
      )
      setScenarioSubMenu(true)
      setReportMenu({ ...reportMenu, downloadReportFixedHeaderOn: false })
    }
  }

  return (
    <div className="pathComponentContainer" data-openreplay-obscured>
      <img src={pathArrow} alt="pathArrow" className="pathArrow" />
      {props.isFinal && props.isScenario ? (
        //scenario path title
        <div className="scenarioPathComponent">
          <div
            className="pathTitle"
            onClick={
              props.scenariosList && props.scenariosList.length > 1
                ? toggleScenarioSubMenu
                : undefined
            }
            id="pathTitleScenario"
          >
            <CutPathName
              pathTitle={
                props.pathTitle === SideMenuPages.MyCases
                  ? getText('title-1', user.settings)
                  : props.pathTitle === SideMenuPages.Academy
                  ? getText('title-2', user.settings)
                  : props.pathTitle === SideMenuPages.Settings
                  ? getText('title-3', user.settings)
                  : props.pathTitle
              }
              isFinal={props.isFinal}
              isLink
              id="pathTitleScenarioText"
              textAttribute={
                props.pathTitle === SideMenuPages.MyCases
                  ? 'title-1'
                  : props.pathTitle === SideMenuPages.Academy
                  ? 'title-2'
                  : props.pathTitle === SideMenuPages.Settings
                  ? 'title-3'
                  : ''
              }
            />
          </div>
          {props.scenariosList && props.scenariosList.length > 1 ? (
            <img
              src={scenariosArrow}
              alt="scenariosArrow"
              className="scenariosArrow"
              id="pathTitleScenarioArrow"
              onClick={toggleScenarioSubMenu}
            />
          ) : null}
          {scenarioSubMenu ? (
            <div
              className="scenarioSubMenuContainer"
              id={`pathTitleScenarioMenu`}
            >
              {props.scenariosList &&
                props.scenariosList.map((scenario, index) => {
                  if (scenario.caseid !== params.scenarioId) {
                    return (
                      <div
                        key={`scenarioName${index}`}
                        className="scenarioNameContainer"
                      >
                        <WhiteTooltipMoreDelay
                          title={scenario.name}
                          textAttribute={undefined}
                          id={`scenarioNameBreadCrumbTooltip`}
                          placement={'bottom-start'}
                        >
                          <Link
                            to={`/mycases/${params.caseId}/${scenario.caseid}/tool`}
                            className="scenarioName"
                            id={`pathTitleScenarioMenuObj${index}`}
                            onClick={(e) => {
                              if (!e.ctrlKey && !e.metaKey) {
                                e.preventDefault()
                                navigateToScenario(scenario.caseid)
                              }
                            }}
                          >
                            {scenario.name}
                          </Link>
                        </WhiteTooltipMoreDelay>
                      </div>
                    )
                  }
                  return null
                })}
            </div>
          ) : null}
        </div>
      ) : props.isFinal ? (
        <div className="finalPathComponent">
          {props.isTutorial && pageDepth === 2 && (
            <img
              src={tutorialImg}
              alt="tutorialImg"
              style={{ marginRight: '10px' }}
            />
          )}
          <div className="pathTitle">
            <CutPathName
              pathTitle={
                props.pathTitle === SideMenuPages.MyCases
                  ? getText('title-1', user.settings)
                  : props.pathTitle === SideMenuPages.Academy
                  ? getText('title-2', user.settings)
                  : props.pathTitle === SideMenuPages.Settings
                  ? getText('title-3', user.settings)
                  : props.pathTitle
              }
              isFinal={props.isFinal}
              textAttribute={
                props.pathTitle === SideMenuPages.MyCases
                  ? 'title-1'
                  : props.pathTitle === SideMenuPages.Academy
                  ? 'title-2'
                  : props.pathTitle === SideMenuPages.Settings
                  ? 'title-3'
                  : ''
              }
              id="finalPathComponentText"
            />
          </div>
        </div>
      ) : (
        <div className="notFinalPathComponent">
          {props.isTutorial && (
            <img
              src={tutorialImg}
              alt="tutorialImg"
              style={{ paddingRight: '10px', cursor: 'pointer' }}
              onClick={() => navigateTo()}
              onMouseEnter={() => setHovering(true)}
              onMouseLeave={() => setHovering(false)}
            />
          )}
          <Link
            to={props.link ? props.link : ''}
            className="pathTitle"
            onClick={(e) => {
              if (!e.ctrlKey && !e.metaKey) {
                e.preventDefault()
                navigateTo()
              }
            }}
          >
            <CutPathName
              pathTitle={
                props.pathTitle === SideMenuPages.MyCases
                  ? getText('title-1', user.settings)
                  : props.pathTitle === SideMenuPages.Academy
                  ? getText('title-2', user.settings)
                  : props.pathTitle === SideMenuPages.Settings
                  ? getText('title-3', user.settings)
                  : props.pathTitle
              }
              isFinal={props.isFinal}
              isLink
              textAttribute={
                props.pathTitle === SideMenuPages.MyCases
                  ? 'title-1'
                  : props.pathTitle === SideMenuPages.Academy
                  ? 'title-2'
                  : props.pathTitle === SideMenuPages.Settings
                  ? 'title-3'
                  : ''
              }
              id="notFinalPathComponentText"
              hovering={hovering}
            />
          </Link>
        </div>
      )}
    </div>
  )
}
