import React, { useState, useEffect, useRef } from 'react'

import SingleQuantum from './SingleQuantum'

import plus from '../../../../../../resources/images/002-plusSignThinBlue.svg'
import tickedImg from '../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../resources/images/018-untickedGrayBlue.svg'

import { useRecoilState, useRecoilValue } from 'recoil'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { IndependentClaim } from '../../../../../../models/independentClaim'
import { SnapshotSelectorObject } from '../../../../../../models/generalTypes'
import {
  AccessRole,
  ClaimType,
  UndoRedoType,
} from '../../../../../../models/enums'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../services/commonFunctions'
import { changeGlobalSnapshot } from '../../../../../../services/changeGlobalSnapshot'
import InfoClickComponent from '../../../../../../Components/InfoHover/InfoClickComponent/InfoClickComponent'
import Button from '../../../../../../Components/Buttons/Button/Button'
import { getText } from '../../../../../../services/textFunctions'
import { userState } from '../../../../../../states/UserState'
import { freemiumState } from '../../../../../../states/FreemiumState'
import Symbol from '../../../../../../Components/symbol/Symbol'

type Props = {
  claimIndex: number
  errors: string[]
  ownRole: AccessRole
  claimType: ClaimType
}

export default function Quantum(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const currentClaim = scenarioSnapshot.currentSnapshot.claims[
    props.claimIndex
  ] as IndependentClaim

  const [smallerWidth, setSmallerWidth] = useState(true) // a boolean that is used to define the width of the <Quantum /> -- bool
  const [innerWidthState, setInnerWidthState] = useState(window.innerWidth)
  const [hoverElement, setHoverElement] = useState('') // a state that is used for the hover feature. It holds the name of the element to be hovered -- str
  const secondaryHover = useRef('open') // a ref that is used for the Hover feature

  const onMouseDown = (e: any) => {
    setHoverElement('')
  }

  useEffect(() => {
    document.addEventListener('mousedown', onMouseDown, false)
    return () => {
      document.removeEventListener('mousedown', onMouseDown, false)
    }
    // eslint-disable-next-line
  }, [hoverElement])

  useEffect(() => {
    window.addEventListener('resize', findWidthOfQuantumContainer)

    return () => {
      window.removeEventListener('resize', findWidthOfQuantumContainer)
    }
    // eslint-disable-next-line
  }, [window.innerWidth])

  function findWidthOfQuantumContainer() {
    setInnerWidthState(window.innerWidth)
  }

  useEffect(() => {
    //checks if all outOfCourtSignums are 'off', to set the smallerWidth
    let tempSmallerWidth = false

    let tempIndex = currentClaim.quantumScenarios.findIndex((item) => {
      return item.outOfCourtSignum !== 'off'
    })

    if (tempIndex === -1) {
      tempSmallerWidth = true
    }

    setSmallerWidth(tempSmallerWidth)

    // eslint-disable-next-line
  }, [currentClaim.quantumScenarios])

  function handleHasOutOfCourt() {
    let tempHasOutOfCourt = !currentClaim.hasOutOfCourt

    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `includeExternalConsequenceContainer-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: tempHasOutOfCourt,
      key: 'claims',
      claimIndex: props.claimIndex,
      key2: 'hasOutOfCourt',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    if (tempHasOutOfCourt === true) {
      //Mixpanel 41 (All)
      logActivity(
        freemium.isFreemium,
        'Added external consequence value submenu',
      )
    } else {
      //Mixpanel 42 (All)
      logActivity(
        freemium.isFreemium,
        'Removed external consequence value submenu',
      )
    }
  }

  function handleAddSingleQuantum(e: any) {
    const snapshotSelectorObject: SnapshotSelectorObject = {
      targetId: `addClaimSingleQuantum-${props.claimIndex}`,
      undoRedoType: UndoRedoType.button,
      value: 'add',
      key: 'claims',
      claimIndex: props.claimIndex,
      key2: 'quantumProbabilities',
    }

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    tempScenarioSnapshot = changeGlobalSnapshot(
      snapshotSelectorObject,
      tempScenarioSnapshot,
    )

    setScenarioSnapshot(tempScenarioSnapshot)
    setTimeout(() => {
      if (
        document.getElementById(
          `claimAltAmount-${props.claimIndex}_${
            tempScenarioSnapshot.currentSnapshot.claims[props.claimIndex]
              .quantumProbabilities.length - 1
          }`,
        )
      ) {
        document
          .getElementById(
            `claimAltAmount-${props.claimIndex}_${
              tempScenarioSnapshot.currentSnapshot.claims[props.claimIndex]
                .quantumProbabilities.length - 1
            }`,
          )!
          .focus()
      }
    }, 10)
    //Mixpanel 44 (All)
    logActivity(freemium.isFreemium, 'Added quantum outcome')
  }

  return (
    <>
      <div
        className="quantumContainer"
        style={
          !currentClaim.hasOutOfCourt && innerWidthState < 1230
            ? { width: '477px' }
            : !currentClaim.hasOutOfCourt
            ? { width: '490px' }
            : smallerWidth && innerWidthState < 1230
            ? { width: '477px' }
            : smallerWidth
            ? { width: '916px' }
            : currentClaim.hasOutOfCourt && innerWidthState < 1230
            ? { width: '584px' }
            : currentClaim.hasOutOfCourt
            ? { width: '100%' }
            : undefined
        }
      >
        <div className="infoElementContainer">
          <InfoClickComponent
            infoClickText={getText('infoButton-18', user.settings)}
            infoClickTextAttribute="infoButton-18"
            idName="quantum"
            hoverElement={hoverElement}
            setHoverElement={setHoverElement}
            whiteCircle
          />
        </div>
        <div className="quantumArrow"></div>

        <div className="quantumTitleContainer">
          <p
            className="quantumTitle"
            id={`quantumTitle-${props.claimIndex}`}
            data-textattribute="title-69"
          >
            {getText('title-69', user.settings)}
          </p>
          <Symbol
            symbolType={
              props.claimType === ClaimType.claim ? 'client' : 'opposingParty'
            }
            targetId={`quantumTitle-${props.claimIndex}`}
            targetType={'quantumText'}
          />
          <p
            className="quantumHelperText"
            id={`quantumHelperText-${props.claimIndex}`}
            data-textattribute="description-40a, description-40b"
          >
            {getText('description-40a', user.settings)}
            <br />
            {getText('description-40b', user.settings)}
          </p>
        </div>
        {props.ownRole !== AccessRole.VIEWER && (
          <div
            className="includeExternalConsequenceContainer"
            id={`includeExternalConsequenceContainer-${props.claimIndex}`}
          >
            <img
              src={currentClaim.hasOutOfCourt ? tickedImg : unTickedImg}
              alt={currentClaim.hasOutOfCourt ? 'tickedImg' : 'unTickedImg'}
              className={
                currentClaim.hasOutOfCourt
                  ? 'includeExternalConsequenceTicked'
                  : 'includeExternalConsequenceUnticked'
              }
              id={`addRemoveClaimOutOfCourt-${props.claimIndex}`}
              onClick={handleHasOutOfCourt}
              onMouseEnter={() => {
                secondaryHover.current = 'includeExternalCheckBox'
                setTimeout(() => {
                  if (secondaryHover.current === 'includeExternalCheckBox') {
                    setHoverElement('includeExternalCheckBox')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
            />

            <p
              className="includeExternalConsequenceText"
              onClick={handleHasOutOfCourt}
              onMouseEnter={() => {
                secondaryHover.current = 'includeExternalCheckBox'
                setTimeout(() => {
                  if (secondaryHover.current === 'includeExternalCheckBox') {
                    setHoverElement('includeExternalCheckBox')
                  }
                }, 1000)
              }}
              onMouseLeave={() => {
                secondaryHover.current = 'close'
                setHoverElement('')
              }}
              id={`includeExternalConsequenceText-${props.claimIndex}`}
              data-textattribute="submenu-12"
            >
              {getText('submenu-12', user.settings)}
            </p>
            <div className="infoElementContainer">
              <InfoClickComponent
                infoClickText={getText('infoButton-19', user.settings)}
                infoClickTextAttribute="infoButton-19"
                idName="extrenalConsequence"
                hoverElement={hoverElement}
                setHoverElement={setHoverElement}
                whiteCircle
              />
            </div>
          </div>
        )}
        <div
          className="singleQuantumContainer"
          id={`singleQuantumContainer-${props.claimIndex}`}
        >
          {currentClaim.quantumProbabilities.map(
            (currentProbability, probabilityIndex) => (
              <SingleQuantum
                key={`singleQuantum${props.claimIndex}-${probabilityIndex}`}
                claimIndex={props.claimIndex}
                currentProbability={currentProbability}
                probabilityIndex={probabilityIndex}
                smallerWidth={smallerWidth}
                innerWidthState={innerWidthState}
                errors={props.errors}
                ownRole={props.ownRole}
              />
            ),
          )}
        </div>

        {props.ownRole !== AccessRole.VIEWER && (
          <div
            className="addQuantumContainer"
            style={
              currentClaim.quantumProbabilities.length < 5
                ? undefined
                : { display: 'none' }
            }
          >
            <Button
              buttonText={getText('button-29', user.settings)}
              buttonTextAttribute="button-29"
              buttonType="outlined"
              type="button"
              id={`addClaimSingleQuantum-${props.claimIndex}`}
              icon={plus}
              className="addQuantumButton"
              onClick={handleAddSingleQuantum}
              side="left"
              NoUpperCase={true}
              small={false}
            />
          </div>
        )}
      </div>
    </>
  )
}
