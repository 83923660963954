import { useRecoilValue } from 'recoil'
import exitImg from '../../resources/images/187-newExitImg.svg'
import { isMac } from '../../services/commonFunctions'
import { getText } from '../../services/textFunctions'
import { userState } from '../../states/UserState'

type Props = {
  onClose: () => void
}

const AppScalingPopUp = (props: Props) => {
  const user = useRecoilValue(userState)
  const onGeneralClick = (e: any) => {
    if (!e.target.id.includes('zoomMessagePopUp')) {
      props.onClose()
    }
  }

  return (
    <div className="zoomMessageBackgroundPopUp" onClick={onGeneralClick}>
      <div className="zoomMessagePopUp" id="zoomMessagePopUp">
        <p
          className="title"
          id="zoomMessagePopUpTitle"
          data-textattribute="title-257"
        >
          {getText('title-257', user.settings)}
        </p>
        <p
          className="subtitle"
          id="zoomMessagePopUpSubtitle"
          data-textattribute="description-235"
        >
          {getText('description-235', user.settings)}
        </p>
        <div className="contentRow" id="zoomMessagePopUpRow1">
          <p className="bullet" id="zoomMessagePopUpBullet1">
            1.
          </p>
          <p
            className="text"
            id="zoomMessagePopUpBulletText1"
            data-textattribute={isMac() ? 'description-236' : 'description-237'}
          >
            {isMac()
              ? getText('description-236', user.settings)
              : getText('description-237', user.settings)}
          </p>
        </div>
        <div className="contentRow" id="zoomMessagePopUpRow1">
          <p className="bullet" id="zoomMessagePopUpBullet1">
            2.
          </p>
          <p
            className="text"
            id="zoomMessagePopUpBulletText1"
            data-textattribute="description-238"
          >
            {getText('description-238', user.settings)}
          </p>
        </div>
        <div className="closeIcon" id="zoomMessagePopUpClose">
          <img src={exitImg} alt="exitImg" onClick={props.onClose} />
        </div>
      </div>
    </div>
  )
}

export default AppScalingPopUp
