import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  FontLabels,
  ReportWhitelabelKeys,
  SectionTitleKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import {
  getText,
  reportSectionType,
} from '../../../../../../../../services/textFunctions'
import { reportState } from '../../../../../../../../states/ReportState'
import { userState } from '../../../../../../../../states/UserState'
import IncludeOptionComponentForSections from '../../../1_executiveSummry/IncludeOptionComponentForSections'
import editButtonImg from '../../../../../../../../resources/images/027-renameSign.svg'
import { SectionId } from '../../../../../../../../models/treeModels/treeTypes'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import { freemiumState } from '../../../../../../../../states/FreemiumState'

type Props = {
  titleKey: SectionTitleKeys
  customTitle: string
  sectionId: SectionId
  visible: boolean
  sectionIndex: number
  showIncludeOption: SectionTitleKeys | undefined
  handleIncludeExcludeSection: () => void
  reportWhitelabel: ReportWhitelabel | undefined
}

export default function ReportSectionTitle(props: Props) {
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)
  const [report, setReport] = useRecoilState(reportState)
  const [editMode, setEditMode] = useState(false)
  const [showEditButton, setShowEditButton] = useState(false)
  const [editableText, setEditableText] = useState(
    props.customTitle !== ''
      ? props.customTitle
      : reportSectionType(user.settings)[props.titleKey],
  )
  const [isEmpty, setIsEmpty] = useState(false)

  useEffect(() => {
    if (editMode) {
      setTimeout(() => {
        if (document.getElementById(`sectionTitleInput-${props.sectionId}`)) {
          document
            .getElementById(`sectionTitleInput-${props.sectionId}`)!
            .focus()
        }
      }, 20)
    } else {
      if (
        editableText !==
        report.reportData.sections[props.sectionIndex].customTitle
      ) {
        let tempReport = deepCloneObject(report)
        if (editableText === reportSectionType(user.settings)[props.titleKey]) {
          tempReport.reportData.sections[props.sectionIndex].customTitle = ''
        } else {
          tempReport.reportData.sections[props.sectionIndex].customTitle =
            editableText
          //Mixpanel 152 (All)
          logActivity(
            freemium.isFreemium,
            'Renamed a section title in the report',
            {
              'Title renamed': props.titleKey,
            },
          )
        }

        setReport(tempReport)
      }
    }
    // eslint-disable-next-line
  }, [editMode])

  useEffect(() => {
    setIsEmpty(editableText.length === 0)
  }, [editableText])

  useEffect(() => {
    window.addEventListener('mousedown', mouseDownLeaveEditMode)

    return () => {
      window.removeEventListener('mousedown', mouseDownLeaveEditMode)
    }
    // eslint-disable-next-line
  }, [editableText, editMode])

  function mouseDownLeaveEditMode(e: any) {
    if (editMode && !e.target.id.includes(`sectionTitleInput`)) {
      setEditMode(false)
    }
  }
  return (
    <>
      <div
        className="reportSectionTitleContainer"
        onMouseEnter={() => {
          setShowEditButton(true)
        }}
        onMouseLeave={() => {
          setShowEditButton(false)
        }}
        id={`reportSectionTitleContainer-${props.titleKey}-${
          props.titleKey === SectionTitleKeys.CustomSection
            ? props.customTitle.length > 0
              ? props.customTitle.replaceAll(' ', '')
              : props.sectionId
            : ''
        }`}
      >
        {/* Fix the hideable Sections - Css that makes everything gray */}
        {props.showIncludeOption ? (
          <div className="hideSectionContainer">
            <IncludeOptionComponentForSections
              includeElement={props.visible}
              includeElementName={props.titleKey}
              handleIncludeExcludeElement={props.handleIncludeExcludeSection}
            />
          </div>
        ) : null}
        {editMode ? (
          <input
            type="text"
            className="sectionTitleInput"
            value={editableText}
            placeholder={reportSectionType(user.settings)[props.titleKey]}
            id={`sectionTitleInput-${props.sectionId}`}
            onChange={(e) => setEditableText(e.target.value)}
            maxLength={70}
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'noRoboto',
            )}
          />
        ) : (
          <p
            className="containerTitle"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionTitle,
              FontLabels.regular,
              'noRoboto',
            )}
            onClick={() => {
              setEditMode(true)
              setShowEditButton(false)
            }}
          >
            {isEmpty
              ? reportSectionType(user.settings)[props.titleKey]
              : editableText}
          </p>
        )}

        {showEditButton && !editMode ? (
          <div
            className="editTitleButtonContainer"
            onClick={() => {
              setEditMode(true)
              setShowEditButton(false)
            }}
          >
            <img src={editButtonImg} alt="" className="editButtonImg" />
            <p
              className="editButtonText"
              id={`pdfPreviewEditButtonText-trialDates`}
              data-textattribute="button-88"
            >
              {getText('button-88', user.settings)}
            </p>
          </div>
        ) : null}
      </div>
    </>
  )
}
