import React, { useEffect } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import Button from '../../../../../../../../Components/Buttons/Button/Button'
import { getText } from '../../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../../states/UserState'
import downloadImg from '../../../../../../../../resources/images/217-downloadImgWhite.svg'
import exitButtonImg from '../../../../../../../../resources/images/187-newExitImg.svg'
import { reportState } from '../../../../../../../../states/ReportState'
import { savedReportState } from '../../../../../../../../states/SavedReportState'
import { reportMenuState } from '../../../../../../../../states/ReportMenuState'
import savePurpleImg from '../../../../../../../../resources/images/109-saveIconPurple.svg'
import changeOrderImg from '../../../../../../../../resources/images/210-changeOrderImg.svg'
import { pageDepthState } from '../../../../../../../../states/PageDepthState'
import { SaveMenuEnum } from '../../../../../../../../models/enums'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../../../../services/commonFunctions'
import { WhiteTooltip } from '../../../../../../../../Components/tooltip/TooltipComponent'
import { freemiumState } from '../../../../../../../../states/FreemiumState'
import PremiumFeatureIcon from '../../../../../../../../Components/PremiumFeatureIcon/PremiumFeatureIcon'
import { FreemiumMessageType } from '../../../../../../../../models/freemiumEnums'

type Props = {
  handleSaveReport: () => Promise<'report saved' | 'report not saved'>
}

export default function PdfReportMenu(props: Props) {
  const user = useRecoilValue(userState)
  const report = useRecoilValue(reportState)
  const [savedReport, setSavedReport] = useRecoilState(savedReportState)
  const [reportMenu, setReportMenu] = useRecoilState(reportMenuState)
  const [freemium, setFreemium] = useRecoilState(freemiumState)
  const setPageDepth = useSetRecoilState(pageDepthState)

  useEffect(() => {
    if (freemium.isFreemium) {
      return
    }

    if (
      JSON.stringify(report.reportData) ===
      JSON.stringify(savedReport.savedReportState.reportData)
    ) {
      setSavedReport({ ...savedReport, saved: true })
    } else {
      setSavedReport({ ...savedReport, saved: false })
    }
    // eslint-disable-next-line
  }, [report])

  function closePdfMenu() {
    setReportMenu({
      ...reportMenu,
      previewPdfOn: false,
      orderMenuOn: false,
      downloadReportFixedHeaderOn: false,
      reportSaveMenuOn: undefined,
      reportWithDifferentResults: false,
    })
    setPageDepth(3)
  }

  function handleOpenSectionOrderMenu() {
    let tempReportMenu = deepCloneObject(reportMenu)
    if (reportMenu.orderMenuOn) {
      //Mixpanel 142 (All)
      logActivity(freemium.isFreemium, 'Closed report section order menu')
      tempReportMenu.orderMenuOn = false
    } else {
      //Mixpanel 143 (All)
      logActivity(freemium.isFreemium, 'Opened report section order menu')
      tempReportMenu.orderMenuOn = true
      let pdfMenuOuterElement = document.getElementById('pdfMenuOuterContainer')
      if (pdfMenuOuterElement) {
        pdfMenuOuterElement?.scroll({
          top: pdfMenuOuterElement!.offsetTop - 400,
          behavior: 'smooth',
        })
      }
    }
    setReportMenu(tempReportMenu)
  }

  return (
    <>
      <div className="fixedReportMenu">
        <div className="downloadReportMenuContainer">
          <div className="downloadReportLeftSide">
            <Button
              buttonText={getText('button-62', user.settings)}
              buttonTextAttribute="button-62"
              buttonType="contained"
              type="button"
              icon={downloadImg}
              onClick={() => {
                if (freemium.isFreemium) {
                  setFreemium({
                    ...freemium,
                    showMessage: FreemiumMessageType.General,
                  })
                } else {
                  setReportMenu({ ...reportMenu, downloadPdf: true })
                  //Mixpanel 80
                  logActivity(false, 'Downloaded pdf report')
                }
              }}
              className="generateReportButton"
              id="downloadReportButton"
              side="left"
              NoUpperCase={true}
              small={false}
            />
            {freemium.isFreemium && (
              <PremiumFeatureIcon targetId={`downloadReportButton`} />
            )}
            {!freemium.isFreemium && (
              <Button
                buttonText={
                  savedReport.saved
                    ? getText('button-63', user.settings)
                    : getText('button-64', user.settings)
                }
                buttonType={savedReport.saved ? 'nonactiveText' : 'outlined'}
                id={savedReport.saved ? 'savedReport' : 'saveReport'}
                icon={savePurpleImg}
                type="button"
                className="saveButton"
                onClick={
                  savedReport.saved ? undefined : () => props.handleSaveReport()
                }
                buttonTextAttribute={
                  savedReport.saved ? 'button-63' : 'button-64'
                }
                side="left"
                NoUpperCase={true}
                small={false}
              />
            )}
            <WhiteTooltip
              title={getText('tooltip-36', user.settings)}
              textAttribute={'tooltip-36'}
              id="reportSortOrderButtonTooltip"
            >
              <div>
                <Button
                  buttonText={''}
                  buttonType={'outlined'}
                  id={'sectionOrderMenuButton'}
                  backGroundColor={reportMenu.orderMenuOn ? '#e1d6f4' : ''}
                  icon={changeOrderImg}
                  type="button"
                  className="saveButton"
                  onClick={handleOpenSectionOrderMenu}
                  buttonTextAttribute={'button-00'}
                  side="left"
                  NoUpperCase={true}
                  small={false}
                />
              </div>
            </WhiteTooltip>
          </div>
          <img
            src={exitButtonImg}
            alt="exitButtonImg"
            className="exitButtonImg"
            onClick={
              savedReport.saved
                ? closePdfMenu
                : () =>
                    setReportMenu({
                      ...reportMenu,
                      reportSaveMenuOn: SaveMenuEnum.report,
                    })
            }
          />
        </div>
      </div>
    </>
  )
}
