import React, { FormEvent, useEffect, useRef, useState } from 'react'
import { useRecoilState } from 'recoil'
import { RequestError } from '../../../../../models/enums'
import { User } from '../../../../../models/user'
import {
  deepCloneObject,
  logActivity,
} from '../../../../../services/commonFunctions'
import {
  changeTfaDevice,
  confirmOtp,
  getBackupCodes,
  getTwoFactorDeviceType,
  loggedInSendSms,
  loggedInSetupSmsDevice,
  loggedInSetupTotpDevice,
  sendSms,
  setSavedBackupCodes,
  setupSmsDevice,
  setupTotpDevice,
} from '../../../../../services/requests'
import { errorMessages } from '../../../../../services/textFunctions'
import { userState } from '../../../../../states/UserState'
import BackupCodes from '../../../../userLoginWorkflow/loginComponents/BackupCodes'
import DoneScreen from '../../../../userLoginWorkflow/loginComponents/DoneScreen'
import OtpForm from '../../../../userLoginWorkflow/loginComponents/OtpForm'
import QRCodeSetup from '../../../../userLoginWorkflow/loginComponents/QRCode'
import SetupTwoFactor from '../../../../userLoginWorkflow/loginComponents/SetupTwoFactor'
import SmsForm from '../../../../userLoginWorkflow/loginComponents/SmsForm'
import { PageType } from '../../../../userLoginWorkflow/UserPopUp'
import SettingPopUp from '../../components/SettingPopUp'

type Props = {
  onClose: () => void
}

const ChangeTwoFactorAuth = (props: Props) => {
  const [user, setUser] = useRecoilState(userState)
  const [backupCodes, setBackupCodes] = useState<string[]>(['', '', ''])
  const [otp, setOtp] = useState('')
  const [lastSmsTimer, setLastSmsTimer] = useState<number | undefined>(
    undefined,
  )
  const [validationMessage, setValidationMessage] = useState<
    string | undefined
  >(undefined)
  const [loading, setLoading] = useState(false)
  const [loggedOut, setLoggedOut] = useState(false)
  const [forLostDevice, setForLostDevice] = useState(false)
  const [tfaToken, setTfaToken] = useState('')
  const [pageType, setPageType] = useState<PageType>('OtpForm')
  const [initialPage, setInitialPage] = useState(true)
  const [url, setUrl] = useState('')
  const [firstTimeSms, setFirstTimeSms] = useState(false)
  const [forSms, setForSms] = useState(user.tfaType === 'sms')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [phoneRegion, setPhoneRegion] = useState('')
  const [tempUser, setTempUser] = useState<User | undefined>(undefined)
  const [tempTokens, setTempTokens] = useState(['', ''])
  const fakeWait = 200
  const initSmsSent = useRef(false)
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    if (forSms && !initSmsSent.current) {
      loggedInSendSms('change')
      initSmsSent.current = true
    }

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!lastSmsTimer) {
      setLastSmsTimer(undefined)
    } else {
      setTimeout(() => {
        setLastSmsTimer((prevState) => (prevState ? prevState - 1 : undefined))
      }, 1000)
    }

    // eslint-disable-next-line
  }, [lastSmsTimer])

  const sendAgainSms = () => {
    if (loggedOut) {
      sendSms(tfaToken)
    } else {
      loggedInSendSms(initialPage ? 'change' : 'create')
    }
    setLastSmsTimer(59)
  }

  const changeBackupCode = (index: number, value: string) => {
    const tempTokens = [...backupCodes]
    tempTokens[index] = value.trim()
    setBackupCodes(tempTokens)
  }

  const setPage = (type: PageType, other?: any) => {
    switch (type) {
      case 'OtpForm':
        setBackupCodes(['', '', ''])
        setOtp('')
        setForSms(other)
        if (other) {
          setLastSmsTimer(59)
        }
        break
      case 'SetupTwoFactor':
        setForLostDevice(false)
        setForSms(false)
        setFirstTimeSms(false)
        break
      case 'QRCode':
        setOtp('')
        setUrl(other ?? '')
        break
      case 'SmsForm':
        setFirstTimeSms(true)
        setPhoneNumber('')
        setPhoneRegion('')
        break
      case 'BackupCodes':
        setBackupCodes(other ?? ['', '', ''])
        break
    }
    setLoading(false)
    setValidationMessage(undefined)
    setPageType(type)
  }

  const otpFormSubmit = () => {
    if (!forLostDevice && otp === '') {
      setValidationMessage(errorMessages(user.settings).NOT_ENOUGH_DIGITS)
      return
    }

    setLoading(true)
    setValidationMessage(undefined)
    if (initialPage) {
      changeTfaDevice(
        forLostDevice ? { codes: [...backupCodes] } : { otp, mode: 'change' },
      ).then((res) => {
        if ('errorCode' in res) {
          if (
            res.errorCode === RequestError.TIMEOUT ||
            res.errorCode === RequestError.SERVER_ERROR
          ) {
            setValidationMessage(
              errorMessages(user.settings).OTP_REQUEST_FAILED,
            )
          } else {
            setLoading(false)
            if (forLostDevice) {
              setValidationMessage(errorMessages(user.settings).WRONG_BACKUP)
            } else {
              setValidationMessage(
                forSms
                  ? errorMessages(user.settings).WRONG_CODE_SMS
                  : errorMessages(user.settings).WRONG_CODE,
              )
            }
          }
        } else {
          setPage('SetupTwoFactor')
          setInitialPage(false)
          if (forLostDevice) {
            setLoggedOut(true)
            setTfaToken(res.data.tfaToken)
          }
        }
        setLoading(false)
      })
    } else {
      setTimeout(() => {
        if (loggedOut) {
          confirmOtp(
            {
              otp,
            },
            tfaToken,
          ).then((res) => {
            if ('errorCode' in res) {
              if (
                res.errorCode === RequestError.TIMEOUT ||
                res.errorCode === RequestError.SERVER_ERROR
              ) {
                setLoading(false)
                setValidationMessage(
                  errorMessages(user.settings).OTP_REQUEST_FAILED,
                )
              } else {
                setLoading(false)
                setValidationMessage(
                  forSms
                    ? errorMessages(user.settings).WRONG_CODE_SMS
                    : errorMessages(user.settings).WRONG_CODE,
                )
              }
            } else {
              const newUser = User.UserFromDB(res.data)
              setFirstTimeSms(false)
              setTempUser(newUser)
              setTempTokens([res.data.token, res.data.refresh_token])
              if (!res.data.has_backup) {
                getBackupCodes(res.data.token).then((res2) => {
                  if ('errorCode' in res2) {
                    setUser(newUser)
                    setLoading(false)
                    setLoggedOut(false)
                    setValidationMessage(
                      errorMessages(user.settings).GET_BACK_UP_CODES_FAILED,
                    )
                  } else {
                    setPage('BackupCodes', res2.data.codes)
                  }
                })
              } else {
                console.log('Never HERE!!!!!!!!!!!')
              }
            }
          })
        } else {
          changeTfaDevice({ otp, mode: 'create' }).then((res) => {
            if ('errorCode' in res) {
              if (
                res.errorCode === RequestError.TIMEOUT ||
                res.errorCode === RequestError.SERVER_ERROR
              ) {
                setLoading(false)
                setValidationMessage(
                  errorMessages(user.settings).OTP_REQUEST_FAILED,
                )
              } else {
                setLoading(false)
                setValidationMessage(
                  forSms
                    ? errorMessages(user.settings).WRONG_CODE_SMS
                    : errorMessages(user.settings).WRONG_CODE,
                )
              }
            } else {
              setLoading(false)
              setFirstTimeSms(false)
              setPage('Done')
              setValidationMessage(undefined)
              //Mixpanel 100
              logActivity(
                false,
                'Changed authentication method in Account Details',
              )
            }
            setLoading(false)
          })
        }
      }, fakeWait)
    }
  }

  const SmsFormSubmit = () => {
    setLoading(true)
    setValidationMessage(undefined)
    setTimeout(() => {
      ;(loggedOut
        ? setupSmsDevice(
            {
              phoneNumber: phoneNumber,
              phoneRegion: phoneRegion,
            },
            tfaToken,
          )
        : loggedInSetupSmsDevice({
            phoneNumber: phoneNumber,
            phoneRegion: phoneRegion,
          })
      ).then((res) => {
        if (!('errorCode' in res)) {
          ;(loggedOut ? sendSms(tfaToken) : loggedInSendSms('create')).then(
            (res2) => {
              if (!('errorCode' in res2)) {
                setPage('OtpForm', true)
              } else if (
                res2.errorCode === RequestError.TIMEOUT ||
                res2.errorCode === RequestError.SERVER_ERROR
              ) {
                setLoading(false)
                setValidationMessage(
                  errorMessages(user.settings).SEND_SMS_FAILED,
                )
              } else {
                setLoading(false)
                setValidationMessage(errorMessages(user.settings).WRONG_NUMBER)
              }
            },
          )
        } else if (
          res.errorCode === RequestError.TIMEOUT ||
          res.errorCode === RequestError.SERVER_ERROR
        ) {
          setLoading(false)
          setValidationMessage(
            errorMessages(user.settings).SETUP_SMS_DEVICE_FAILED,
          )
        } else {
          setLoading(false)
          setValidationMessage(errorMessages(user.settings).WRONG_NUMBER)
        }
      })
    }, fakeWait)
  }

  const onBackButton = () => {
    setValidationMessage(undefined)
    setForLostDevice(false)
    setOtp('')
    setBackupCodes(['', '', ''])
    if (firstTimeSms) {
      setPage('SmsForm')
    }
  }

  const onClose = () => {
    if (!loading && !loggedOut) {
      if (!tempUser) {
        getTwoFactorDeviceType().then((res) => {
          if (!('errorCode' in res)) {
            let newUser = deepCloneObject(user)
            newUser.tfaType = res.data.deviceType
            setUser(newUser)
          }
        })
      }

      setPage('OtpForm')
      setOtp('')
      setValidationMessage(undefined)
      setLastSmsTimer(undefined)
      setForLostDevice(false)
      setBackupCodes(['', '', ''])
      setTempTokens(['', ''])
      setTempUser(undefined)

      props.onClose()
    }
  }

  const onSetupTotp = () => {
    setLoading(true)
    setValidationMessage(undefined)
    setTimeout(() => {
      ;(loggedOut ? setupTotpDevice(tfaToken) : loggedInSetupTotpDevice()).then(
        (res) => {
          if ('errorCode' in res) {
            setLoading(false)
            setValidationMessage(
              errorMessages(user.settings).SETUP_TWO_FACTOR_ERROR,
            )
          } else {
            setValidationMessage(undefined)
            setPage('QRCode')
            setUrl(res.data.url)
          }
        },
      )
    }, fakeWait)
  }

  const onSetupSms = () => {
    setLoading(true)
    setTimeout(() => {
      setPage('SmsForm')
    }, fakeWait)
  }

  const onBackupCodesDone = () => {
    setLoading(true)
    setValidationMessage(undefined)

    setTimeout(() => {
      setSavedBackupCodes(tempTokens[0]).then((res) => {
        if ('errorCode' in res) {
          setLoading(false)
          setValidationMessage(
            errorMessages(user.settings).SETUP_TWO_FACTOR_ERROR,
          )
        } else {
          setValidationMessage(undefined)
          setPage('Done')
          setLoggedOut(false)
          if (tempUser) {
            const newUser = deepCloneObject(tempUser)
            setUser(newUser)
          }
        }
      })
    }, fakeWait)
  }

  const validation = () => {
    let numOfErrors = 0
    const newErrors: string[] = []

    switch (pageType) {
      case 'OtpForm':
        if (forLostDevice) {
          backupCodes.forEach((code, index) => {
            if (!code) {
              newErrors.push(`otp${index}`)
              numOfErrors++
            }
          })
        }
        break
      case 'SmsForm':
        if (!phoneNumber) {
          newErrors.push('phoneNumber')
          numOfErrors++
        }
        break
    }

    setErrors([...newErrors])
    return numOfErrors === 0
  }

  const handleSubmit = (e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault()
    if (!validation()) {
      return
    }

    switch (pageType) {
      case 'QRCode':
      case 'OtpForm':
        otpFormSubmit()
        break
      case 'SmsForm':
        SmsFormSubmit()
        break
    }
  }

  return (
    <SettingPopUp onClose={loggedOut ? undefined : onClose}>
      <form className="form" onSubmit={handleSubmit}>
        {pageType === 'OtpForm' ? (
          <OtpForm
            backupCodes={backupCodes}
            setOtp={setOtp}
            otp={otp}
            forSms={forSms}
            validationMessage={validationMessage}
            sendAgainSms={sendAgainSms}
            timeFromLastSms={lastSmsTimer}
            loading={loading}
            changeBackupCode={changeBackupCode}
            setForLostDevice={
              initialPage
                ? () => {
                    setValidationMessage(undefined)
                    setForLostDevice(true)
                  }
                : undefined
            }
            forLostDevice={forLostDevice}
            onBackButton={onBackButton}
            errors={errors}
          />
        ) : pageType === 'SetupTwoFactor' ? (
          <SetupTwoFactor
            onSetupTotp={onSetupTotp}
            onSetupSms={onSetupSms}
            loading={loading}
            setupTwoFactorError={validationMessage}
          />
        ) : pageType === 'SmsForm' ? (
          <SmsForm
            validationMessage={validationMessage}
            setPhoneNumber={setPhoneNumber}
            setPhoneRegion={setPhoneRegion}
            setPageType={setPage}
            loading={loading}
            errors={errors}
          />
        ) : pageType === 'QRCode' ? (
          <QRCodeSetup
            onSubmit={handleSubmit}
            setPageType={setPage}
            url={url}
            setOtp={setOtp}
            validationMessage={validationMessage}
            loading={loading}
          />
        ) : pageType === 'BackupCodes' ? (
          <BackupCodes
            loading={loading}
            onDone={onBackupCodesDone}
            backupCodes={backupCodes}
            savedBackUpCodesFailed={validationMessage}
          />
        ) : pageType === 'Done' ? (
          <DoneScreen forSettings onFinishSetup={onClose} />
        ) : null}
      </form>
    </SettingPopUp>
  )
}

export default ChangeTwoFactorAuth
