import { atom } from 'recoil'
import {
  Currency,
  PartyFormat,
  partyFormatFromString,
  UndoRedoType,
} from '../models/enums'
import { DefaultSnapshotState } from '../models/generalTypes'
import { ScenarioSnapshot } from '../models/scenarioSnapshot'

export const scenarioSnapshotState = atom<DefaultSnapshotState>({
  key: 'scenarioSnapshotState',
  default: {
    currentSnapshot: ScenarioSnapshot.EmptySnapshot(
      partyFormatFromString(PartyFormat.Client)!,
      partyFormatFromString(PartyFormat.Opposing_Party)!,
      Currency.SEK,
    ),
    undoRedoIndex: 0,
    undoRedo: [
      {
        id: 'currencyDropdown',
        type: UndoRedoType.input,
        snapshot: ScenarioSnapshot.EmptySnapshot(
          partyFormatFromString(PartyFormat.Client)!,
          partyFormatFromString(PartyFormat.Opposing_Party)!,
          Currency.SEK,
        ),
        time: new Date(),
      },
    ],
    savedSnapshot: ScenarioSnapshot.EmptySnapshot(
      partyFormatFromString(PartyFormat.Client)!,
      partyFormatFromString(PartyFormat.Opposing_Party)!,
      Currency.SEK,
    ),
  },
})
