import { useRecoilValue } from 'recoil'
import {
  EventId,
  NodeId,
} from '../../../../../../../models/treeModels/treeTypes'
import { scenarioSnapshotState } from '../../../../../../../states/ScenarioSnapshotState'
import removeImg from '../../../../../../../resources/images/201-deleteBucketWIthBorder.svg'
import { getText } from '../../../../../../../services/textFunctions'
import { userState } from '../../../../../../../states/UserState'
import { EventDetails } from '../../../../../../../models/treeModels/eventDetails'
import { AmountSignum, PartySignum } from '../../../../../../../models/enums'
import { freemiumState } from '../../../../../../../states/FreemiumState'
import { logActivity } from '../../../../../../../services/commonFunctions'
import addAwardedAmountGray from '../../../../../../../resources/images/146-addAwardedAmount.svg'
import addAwardedAmountPurple from '../../../../../../../resources/images/149-addEvent.svg'
import InputNum from '../../../../../../../Components/Inputs/InputNum/InputNum'
import SwitchButton from '../../../../../../../Components/Buttons/SwitchButton/SwitchButton'
import { useState } from 'react'
import Symbol from '../../../../../../../Components/symbol/Symbol'
import { useXarrow } from 'react-xarrows'
import {
  CLIENT_COLOR,
  KROGERUS_CLIENT_COLOR,
} from '../../../../../../../services/constants'
import { krogerusColorsState } from '../../../../../../../states/KrogerusColorsState'

type Props = {
  active: boolean
  highlighted: boolean
  treeIndex: number
  nodeId: NodeId
  eventIndex: number
  eventDetails: EventDetails
  editMode: NodeId | undefined
  nodeSelected: boolean
  startAnimation: boolean
  eventId: EventId
  handleChangeTreeEventDetail: (
    targetId: string,
    eventDetailKey: keyof EventDetails,
    value:
      | never
      | AmountSignum
      | PartySignum
      | string
      | number
      | boolean
      | undefined
      | Date,
  ) => void
  setEditMode: (editMode: NodeId | undefined) => void
}

export default function EventAwardedAmountContainer(props: Props) {
  const scenarioSnapshot = useRecoilValue(scenarioSnapshotState)
  const freemium = useRecoilValue(freemiumState)
  const user = useRecoilValue(userState)
  const krogerusColors = useRecoilValue(krogerusColorsState)
  const partiesFormat = [
    scenarioSnapshot.currentSnapshot.partyFormatOwn,
    scenarioSnapshot.currentSnapshot.partyFormatOther,
  ]

  const [outOfCourtAmountButtonHover, setOutOfCourtAmountButtonHover] =
    useState(false)
  const [showRemoveOutOfCourtAmount, setShowRemoveOutOfCourtAmount] =
    useState(false)
  const updateXarrow = useXarrow()

  return (
    <>
      <div
        className={`treeEventOutOfCourtSection ${
          props.nodeSelected || props.highlighted ? 'selected' : ''
        } ${props.active ? 'active' : ''} ${
          props.startAnimation ? 'fadeout' : ''
        } ${krogerusColors ? 'krogerus' : ''}`}
        id={`treeEventOutOfCourtSection-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
      >
        {props.eventDetails.outOfCourtAmountSignum === AmountSignum.off ? (
          <>
            <div className="treeEventAddSectionContainer">
              <div
                className={
                  outOfCourtAmountButtonHover
                    ? 'treeEventAddSectionButton darkButton'
                    : 'treeEventAddSectionButton '
                }
                onMouseEnter={() => setOutOfCourtAmountButtonHover(true)}
                onMouseLeave={() => setOutOfCourtAmountButtonHover(false)}
                onClick={(e: any) => {
                  setOutOfCourtAmountButtonHover(false)

                  props.handleChangeTreeEventDetail(
                    `treeEventAddSectionButtonOutOfCourtAmount-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                    'outOfCourtAmountSignum',
                    AmountSignum.get,
                  )

                  //Mixpanel 57 (All)
                  logActivity(
                    freemium.isFreemium,
                    'Added external consequence value in legal tree',
                  )
                  setTimeout(() => {
                    if (
                      document.getElementById(
                        `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                      )
                    ) {
                      document
                        .getElementById(
                          `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        )!
                        .focus()
                      props.setEditMode(props.nodeId)
                    }
                  }, 200)
                }}
                id={`treeEventAddSectionButtonOutOfCourtAmount-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
              >
                <img
                  src={
                    outOfCourtAmountButtonHover
                      ? addAwardedAmountPurple
                      : addAwardedAmountGray
                  }
                  alt="plusImg"
                  className="treeEventAddSectionButtonImg"
                  id={`treeEventAddSectionButtonOutOfCourtAmountImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                />
                <p
                  className={
                    outOfCourtAmountButtonHover
                      ? 'treeEventAddSectionButtonText dark'
                      : 'treeEventAddSectionButtonText'
                  }
                  id={`treeEventAddSectionButtonOutOfCourtAmountText-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  data-textattribute="button-83"
                >
                  {getText('button-83', user.settings)}
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className={'treeEventAmountInterestSection'}
              id={`treeEventAddSectionButtonOutOfCourtAmount-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
            >
              <div
                className="treeEventAmountSection"
                onMouseEnter={() => setShowRemoveOutOfCourtAmount(true)}
                onMouseLeave={() => setShowRemoveOutOfCourtAmount(false)}
              >
                <InputNum
                  label={getText('label-95', user.settings, partiesFormat)}
                  labelTextAttribute={'label-95'}
                  value={props.eventDetails.outOfCourtAmount}
                  defaultZero
                  maxValue={99999999999}
                  maxNumberOfDecimals={3}
                  smallField
                  stickySuffix={<></>}
                  id={`treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  width={212}
                  onChange={(e: any) =>
                    props.handleChangeTreeEventDetail(
                      e.target.id,
                      'outOfCourtAmount',
                      e.target.value,
                    )
                  }
                  alwaysShrink
                  name={`treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  className="scenarioOutOfCourtAmount robotoNumbers"
                  localStyle={{
                    color: krogerusColors
                      ? KROGERUS_CLIENT_COLOR
                      : CLIENT_COLOR,
                  }}
                  prefix={
                    <div
                      style={{ width: '12px' }}
                      onClick={() =>
                        document
                          .getElementById(
                            `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                          )!
                          .focus()
                      }
                    >
                      <Symbol
                        symbolType={
                          props.eventDetails.outOfCourtAmountSignum ===
                          AmountSignum.get
                            ? 'positiveExternalClient'
                            : 'negativeExternalClient'
                        }
                        targetId={`treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                        targetType={'treeExternalInput'}
                      />
                    </div>
                  }
                  onFocus={() => {
                    setTimeout(() => {
                      if (
                        document.activeElement !==
                        document.getElementById(
                          `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        )
                      ) {
                        document
                          .getElementById(
                            `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                          )!
                          .focus()
                      }
                      updateXarrow()
                    }, 100)
                  }}
                  suffix={scenarioSnapshot.currentSnapshot.currency}
                  tabIndex={10 * props.eventIndex + 6}
                />
                {showRemoveOutOfCourtAmount ? (
                  <>
                    <div
                      className="treeEventRemoveSectionContainer"
                      id={`treeEventRemoveOutOfCourtAmountSectionContainer-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      onClick={(e: any) => {
                        props.handleChangeTreeEventDetail(
                          e.target.id,
                          'outOfCourtAmountSignum',
                          AmountSignum.off,
                        )
                        //Mixpanel 58 (All)
                        logActivity(
                          freemium.isFreemium,
                          'Removed external consequence value in legal tree',
                        )
                      }}
                    >
                      <img
                        src={removeImg}
                        alt="removeBigImg"
                        className="treeEventRemoveSectionImg"
                        id={`treeEventRemoveOutOfCourtAmountSectionImg-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {props.editMode === props.nodeId ? (
              <div className={'treeEventAwardSignumSection outOfCourt'}>
                <SwitchButton
                  width="212px"
                  label1={
                    getText('switchButton-3', user.settings, partiesFormat)
                      .length > 26
                      ? getText('switchButton-3', user.settings, partiesFormat)
                          .substring(0, 26)
                          .trim() + '...'
                      : getText('switchButton-3', user.settings, partiesFormat)
                  }
                  label1TextAttribute="switchButton-3"
                  label2={
                    getText('switchButton-4', user.settings, partiesFormat)
                      .length > 26
                      ? getText('switchButton-4', user.settings, partiesFormat)
                          .substring(0, 26)
                          .trim() + '...'
                      : getText('switchButton-4', user.settings, partiesFormat)
                  }
                  label2TextAttribute="switchButton-4"
                  onClick={(num: number) => {
                    if (num === 1) {
                      props.handleChangeTreeEventDetail(
                        `treeEventOutOfCourtAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        'outOfCourtAmountSignum',
                        AmountSignum.get,
                      )
                    } else {
                      props.handleChangeTreeEventDetail(
                        `treeEventOutOfCourtAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        'outOfCourtAmountSignum',
                        AmountSignum.pay,
                      )
                    }
                    if (
                      document.getElementById(
                        `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                      )
                    ) {
                      document
                        .getElementById(
                          `treeEventOutOfCourtAmountInput-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`,
                        )!
                        .focus()
                    }
                  }}
                  backgroundColor1="white"
                  backgroundColor2="white"
                  color1="#201a2d" //ageanMidnight
                  color2="#201a2d" //ageanMidnight
                  id={`treeEventOutOfCourtAmountSignumSwitch-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  id1={`treeEventOutOfCourtAmountSignumSwitch1-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  id2={`treeEventOutOfCourtAmountSignumSwitch2-${props.treeIndex}_${props.nodeId}!${props.eventIndex}`}
                  defaultState={
                    props.eventDetails.outOfCourtAmountSignum === 'get' ? 1 : 2
                  }
                  marginLabel1="0 0 0 35px"
                  marginLabel2="0 0 0 15px"
                  inTree
                />
              </div>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}
