import React, { useEffect, useRef, useState } from 'react'
import { useXarrow, Xwrapper } from 'react-xarrows'
import {
  TreeClaim,
  TreeDetails,
} from '../../../../../../models/treeModels/treeClaim'
import TreeNode from './treeNodeComponents/TreeNode'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  deepCloneObject,
  isMac,
  logActivity,
} from '../../../../../../services/commonFunctions'
import {
  AccessRole,
  ActionMessage,
  SnapshotStatus,
  UndoRedoType,
} from '../../../../../../models/enums'
import {
  changeTextAreaHeight,
  changeTreeClaim,
  deleteTreeEvent,
  deleteTreeNode,
  showAvailableEventsForLine,
  showAvailableNodesForLine,
  findChildrenNodesFromEvent,
  findMainTreeAndOrphanTrees,
  findNewNodeProbabilities,
  findNodesOfTreeFromRoot,
  focusOnEditMode,
  onlyUnique,
  redistributeNodeProbabilities,
  shouldEditModeToggle,
} from '../../../../../../services/treeFunctions/treeBasicFunctions'
import ConnectedLine from './treeLineComponents/ConnectedLine'
import {
  changeNodesPosition,
  findDeleteNodeOrEventMenuPosition,
  findHeightOfCanvas,
  findNodesMovedForEdtiMode,
  findWidthOfCanvas,
} from '../../../../../../services/treeFunctions/treePositioningFunctions'
import {
  DeleteNodeOrEventMenuObject,
  EventId,
  NodeId,
  NodeMode,
  NodesMovedForEditMode,
  RootNodeType,
} from '../../../../../../models/treeModels/treeTypes'
import ConnectingLine from './treeLineComponents/ConnectingLine'
import TreeInfo from '../treeMenusComponents/TreeInfo'
import { scenarioSnapshotState } from '../../../../../../states/ScenarioSnapshotState'
import { debuggingState } from '../../../../../../states/DebuggingState'
import { nodesMovedForEditModeState } from '../../../../../../states/NodesMovedForEditModeState'
import DeleteNodeOrEventMenu from './treeNodeComponents/treeNodeTitleMenuComponents/DeleteNodeOrEventMenu'
import HoverLine from './treeLineComponents/HoverLine'
import {
  findHeightOfTitle,
  findLeftPointOfNode,
  findTopPointOfNode,
  heightOfNode,
  widthOfNode,
} from '../../../../../../services/treeFunctions/treeXandYFunctions'
import { treeTablesState } from '../../../../../../states/TreeTablesState'
import { scenarioIdentityState } from '../../../../../../states/ScenarioIdentityState'
import { editTreeNodeFromUndoState } from '../../../../../../states/EditTreeNodeFromUndo'
import { fullscreenActionMessageState } from '../../../../../../states/FullScreenActionMessageState'
import TreePreviewForImage from './TreePreviewForImage'
import { TreeTablesState } from '../../../../../../models/generalTypes'
import { allowShortcutsState } from '../../../../../../states/AllowShortcutsState'
import { freemiumState } from '../../../../../../states/FreemiumState'

type Props = {
  treeIndex: number
  selectedPath: number[]
  resetActivePath: boolean
  renderLines: boolean
  setRenderLines: (renderLines: boolean) => void
  mainTree: NodeId[]
  setMainTree: (mainTree: NodeId[]) => void
  orphanTrees: NodeId[][]
  setOrphanTrees: (orphanTrees: NodeId[][]) => void
  openOrphanNodesWarningPopUp: boolean
  openZeroProbabilityWarningPopUp: boolean
  ownRole: AccessRole
  editMode: NodeId | undefined
  setEditMode: (editMode: NodeId | undefined) => void
  removeClaim: (
    claimIndex: number,
    focusId: string,
    highlightId: string,
  ) => void
  errors: string[]
  selectedNodes: NodeId[]
  setSelectedNodes: (selectedNodes: NodeId[]) => void
  nodesUnderZeroProbability: NodeId[]
}

export default function TreeCanvas(props: Props) {
  const freemium = useRecoilValue(freemiumState)
  const debugging = useRecoilValue(debuggingState)
  const updateXarrow = useXarrow()
  const draggableLineRef = useRef(null)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const [scenarioSnapshot, setScenarioSnapshot] = useRecoilState(
    scenarioSnapshotState,
  )
  const treeAnalysis = useRecoilValue(treeTablesState)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)
  const [treeTables, setTreeTables] = useRecoilState(treeTablesState)
  const allowShortcuts = useRecoilValue(allowShortcutsState)

  const treeClaim = scenarioSnapshot.currentSnapshot.claims[
    props.treeIndex
  ] as TreeClaim

  const treeNodeMode = (
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).nodeMode

  const treeLines = (
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim
  ).treeLines

  const [canvasWidth, setCanvasWidth] = useState(700)
  const [canvasHeight, setCanvasHeight] = useState(800)

  const [lineHover, setLineHover] = useState<[NodeId, EventId] | undefined>(
    undefined,
  )
  const [lineClick, setLineClick] = useState<[NodeId, EventId] | undefined>(
    undefined,
  )
  const [showTreeInfo, setShowTreeInfo] = useState(false)

  const treeDetails = deepCloneObject(
    scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim,
  ).treeDetails as TreeDetails
  const [previousEditMode, setPreviousEditMode] = useState<NodeId | undefined>(
    undefined,
  )
  const [editTreeNodeFromUndo, setEditTreeNodeFromUndo] = useRecoilState(
    editTreeNodeFromUndoState,
  )

  const [elementsToBeDeletedForAnimation, setElementsToBeDeletedForAnimation] =
    useState<(NodeId | EventId)[]>([])

  const [nodesMovedForEditMode, setNodesMovedForEditMode] = useRecoilState(
    nodesMovedForEditModeState,
  )

  const [nodeCreatingLine, setNodeCreatingLine] = useState<NodeId | undefined>(
    undefined,
  )
  const [changingLineStartId, setChangingLineStartId] = useState<
    string | undefined
  >(undefined)
  const [availableTargetsForLine, setAvailableTargetsForLine] = useState<
    (NodeId | EventId)[]
  >([])

  const [dragPosition, setDragPosition] = useState<any>(undefined)
  const [openDeleteNodeOrEventMenu, setOpenDeleteNodeOrEventMenu] = useState<
    DeleteNodeOrEventMenuObject | undefined
  >(undefined)
  const [shakeScenario, setShakeScenario] = useState<
    [NodeId, number] | undefined
  >(undefined)

  const [nodeProbabilities, setNodeProbabilities] = useState<{
    [nodeId: NodeId]: number[]
  }>({})

  const [connectedDot, setConnectedDot] = useState<
    EventId | NodeId | undefined
  >(undefined)

  const [connectionCreatesLoop, setConnectionCreatesLoop] = useState<
    EventId | NodeId | undefined
  >(undefined)

  const [copiedNodes, setCopiedNodes] = useState<NodeId[]>([])

  useEffect(() => {
    setTimeout(() => {
      updateXarrow()
    }, 10)
    // eslint-disable-next-line
  }, [treeClaim])

  useEffect(() => {
    if (props.ownRole !== AccessRole.VIEWER)
      document.addEventListener('mousedown', toggleEditMode)

    return () => {
      document.removeEventListener('mousedown', toggleEditMode)
    }
    // eslint-disable-next-line
  }, [props.editMode, treeDetails])

  useEffect(() => {
    if (props.ownRole !== AccessRole.VIEWER) {
      document.addEventListener('keydown', selectAllnodes)
      document.addEventListener('keydown', deleteSelectedNodes)
    }

    return () => {
      document.removeEventListener('keydown', selectAllnodes)
      document.removeEventListener('keydown', deleteSelectedNodes)
    }
    // eslint-disable-next-line
  }, [treeDetails, props.selectedNodes, allowShortcuts])

  useEffect(() => {
    let tempNodeProbabilities = { ...nodeProbabilities }

    for (let eventId of Object.keys(treeDetails.events)) {
      if (
        !Object.keys(tempNodeProbabilities).includes(
          treeDetails.events[eventId as EventId].nodeOfEventId,
        )
      ) {
        tempNodeProbabilities[
          treeDetails.events[eventId as EventId].nodeOfEventId
        ] = []
      }
      tempNodeProbabilities[
        treeDetails.events[eventId as EventId].nodeOfEventId
      ][treeDetails.events[eventId as EventId].eventIndex] =
        treeDetails.events[eventId as EventId].eventDetails.probability
    }
    setNodeProbabilities(tempNodeProbabilities)
    // eslint-disable-next-line
  }, [
    // eslint-disable-next-line
    (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
      .treeDetails.events,
  ])

  useEffect(() => {
    //This useEffect fixes the textarea height when changing from tree to tree, since rerendering of react doesn't work properly
    setTimeout(() => {
      const tx = document.getElementsByClassName('treeNodeTitleTextarea')
      for (let i = 0; i < tx.length; i++) {
        if (tx[i]) {
          changeTextAreaHeight(tx[i])
        }
      }
    }, 100)
    const tx = document.getElementsByClassName('treeNodeTitleTextarea')
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute(
        'style',
        `height: ${tx[i].scrollHeight}px;overflow-y:hidden;`,
      )
      tx[i].addEventListener('input', () => changeTextAreaHeight(tx[i]), false)
    }

    setTimeout(() => {
      props.setRenderLines(true)
      updateXarrow()
    }, 300)
    // eslint-disable-next-line
  }, [props.treeIndex, treeClaim.treeDetails, props.editMode])

  useEffect(() => {
    if (
      (scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex].id.includes(
        'treeNode',
      ) &&
        scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex - 1] &&
        Object.keys(
          (
            scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex].snapshot
              .claims[props.treeIndex] as TreeClaim
          ).treeDetails.nodes,
        ).length !==
          Object.keys(
            (
              scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex - 1]
                .snapshot.claims[props.treeIndex] as TreeClaim
            ).treeDetails.nodes,
          ).length) ||
      (scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex + 1] &&
        scenarioSnapshot.undoRedo[
          scenarioSnapshot.undoRedoIndex + 1
        ].id.includes('treeNode') &&
        Object.keys(
          (
            scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex].snapshot
              .claims[props.treeIndex] as TreeClaim
          ).treeDetails.nodes,
        ).length !==
          Object.keys(
            (
              scenarioSnapshot.undoRedo[scenarioSnapshot.undoRedoIndex + 1]
                .snapshot.claims[props.treeIndex] as TreeClaim
            ).treeDetails.nodes,
          ).length)
    ) {
      props.setRenderLines(false)
      setTimeout(() => {
        props.setRenderLines(true)
      }, 10)
    }
    // eslint-disable-next-line
  }, [scenarioSnapshot.undoRedo])

  useEffect(() => {
    findDimensions()
    // eslint-disable-next-line
  }, [treeClaim])

  useEffect(() => {
    document.addEventListener('mousedown', deleteFakeLine)
    document.addEventListener('mousedown', deleteNodeOrEventMenu)
    return () => {
      document.removeEventListener('mousedown', deleteFakeLine)
      document.removeEventListener('mousedown', deleteNodeOrEventMenu)
    }
    // eslint-disable-next-line
  }, [props.treeIndex, openDeleteNodeOrEventMenu])

  useEffect(() => {
    let tempAvailableTargetsForLine: (NodeId | EventId)[] = []

    if (nodeCreatingLine) {
      tempAvailableTargetsForLine = showAvailableEventsForLine(
        nodeCreatingLine,
        treeDetails,
      )
      //console.log('nodeCreatingLine', nodeCreatingLine)
    } else if (changingLineStartId) {
      if (changingLineStartId.includes('connectionCircle')) {
        tempAvailableTargetsForLine = showAvailableEventsForLine(
          lineClick![0],
          treeDetails,
        )
      } else {
        tempAvailableTargetsForLine = showAvailableNodesForLine(
          lineClick![1],
          treeDetails,
        )
      }

      //console.log('changingLineStartId', changingLineStartId)
    }
    //console.log('tempAvailableEventsForLine', tempAvailableTargetsForLine)
    setAvailableTargetsForLine(tempAvailableTargetsForLine)

    // eslint-disable-next-line
  }, [nodeCreatingLine, changingLineStartId])

  useEffect(() => {
    if (nodesMovedForEditMode) {
      let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)

      for (const nodeId of Object.keys(tempNodesMovedForEditMode)) {
        if (!Object.keys(treeDetails.nodes).includes(nodeId as NodeId)) {
          delete tempNodesMovedForEditMode[nodeId]
        }
      }
      setNodesMovedForEditMode(tempNodesMovedForEditMode)
    }
    // eslint-disable-next-line
  }, [treeClaim.treeDetails.nodes])

  useEffect(() => {
    if (props.editMode) {
      setPreviousEditMode(props.editMode)
    }
    if (
      nodesMovedForEditMode !== undefined &&
      props.editMode === undefined &&
      previousEditMode &&
      Object.keys(
        (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
          .treeDetails.nodes,
      ).includes(previousEditMode)
    ) {
      let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
      let tempTreeDetails = tempScenarioSnapshot.currentSnapshot.claims[
        props.treeIndex
      ].treeDetails as TreeDetails
      let tempNodeMode =
        tempScenarioSnapshot.currentSnapshot.claims[props.treeIndex].nodeMode

      for (let nodeId of Object.keys(nodesMovedForEditMode)) {
        if (Object.keys(tempTreeDetails.nodes).includes(nodeId)) {
          tempTreeDetails.nodes[nodeId as NodeId].properties.position =
            nodesMovedForEditMode[nodeId].previousPosition
        }
      }

      tempScenarioSnapshot.currentSnapshot.claims[props.treeIndex].treeDetails =
        changeNodesPosition(
          previousEditMode as NodeId,
          tempTreeDetails,
          tempNodeMode,
          'changeEditMode',
          props.treeIndex,
          props.editMode,
        )

      setScenarioSnapshot(tempScenarioSnapshot)
      setNodesMovedForEditMode(undefined)
      setPreviousEditMode(undefined)
    }
    // eslint-disable-next-line
  }, [props.editMode])

  function selectAllnodes(e: any) {
    if (allowShortcuts) {
      if (
        ((e.code === 'KeyA' && e.ctrlKey && !isMac()) ||
          (e.code === 'KeyA' && e.metaKey && isMac())) &&
        e.target.tagName.toLowerCase() !== 'textarea' &&
        e.target.tagName.toLowerCase() !== 'input'
      ) {
        e.preventDefault()
        let allNodesArray = Object.keys(treeDetails.nodes) as NodeId[]
        props.setSelectedNodes(allNodesArray)
      }
    }
  }

  function deleteSelectedNodes(e: any) {
    if (allowShortcuts) {
      if (
        props.selectedNodes.length > 0 &&
        props.selectedNodes.length !== Object.keys(treeDetails.nodes).length &&
        (e.code === 'Delete' || e.code === 'Backspace') &&
        e.target.tagName.toLowerCase() !== 'textarea' &&
        e.target.tagName.toLowerCase() !== 'input'
      ) {
        e.preventDefault()
        handleDeleteTreeNode(false, props.selectedNodes[0], props.selectedNodes)
      }
    }
  }

  const toggleEditMode = (e: any) => {
    let nodeClicked: undefined | NodeId = undefined
    let tempNodesMovedForEditMode: NodesMovedForEditMode | undefined

    //I Should toggle Edit Mode
    if (
      shouldEditModeToggle(
        e.target.id,
        editTreeNodeFromUndo,
        props.editMode,
        e.target.classList,
      )
    ) {
      for (let nodeId of Object.keys(treeDetails.nodes)) {
        const treeNodeElement = document.getElementById(
          `treeNode-${props.treeIndex}_${nodeId}`,
        )
        if (treeNodeElement) {
          const allElementsUnderNode = treeNodeElement.querySelectorAll(
            'input, label, div, p, img, textarea, fieldset, legend, span',
          )
          for (let element of allElementsUnderNode) {
            if (!JSON.stringify(element.classList).includes(nodeId)) {
              element.classList.add(nodeId)
            }
          }
        }
        if (
          JSON.stringify(e.target.classList).includes(nodeId) ||
          e.target.id.includes(nodeId)
        ) {
          nodeClicked = nodeId as NodeId
          break
        }
      }

      //There is already a node in Edit Mode
      if (props.editMode !== undefined) {
        //The click comes from outside a Node
        if (nodeClicked === undefined) {
          let timeoutSpeed = treeNodeMode === NodeMode.minimised ? 100 : 0
          let tempEditMode = props.editMode
          setTimeout(() => {
            props.setEditMode(undefined)
            changeTextAreaHeight(
              document.getElementById(
                `treeNodeTitleTextarea-${props.treeIndex}_${tempEditMode}`,
              )!,
            )
          }, timeoutSpeed)
          setEditTreeNodeFromUndo(undefined)
        }
        //The click comes from another Node
        else if (nodeClicked && nodeClicked !== props.editMode) {
          // And the tree is maximised , so we can have the Edit to Edit Mode
          if (treeNodeMode === NodeMode.maximised) {
            let tempNodesMovedForEditMode: NodesMovedForEditMode | undefined
            let tempTreeDetailsWithEditModeOn: TreeDetails | undefined =
              undefined
            if (nodesMovedForEditMode) {
              let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
              tempTreeDetailsWithEditModeOn = tempScenarioSnapshot
                .currentSnapshot.claims[props.treeIndex]
                .treeDetails as TreeDetails

              let currentNodesMovedForEditMode = deepCloneObject(
                nodesMovedForEditMode,
              )

              tempNodesMovedForEditMode = findNodesMovedForEdtiMode(
                nodeClicked,
                tempTreeDetailsWithEditModeOn,
                treeNodeMode,
                true,
                props.treeIndex,
              )

              for (let commonNode of Object.keys(
                currentNodesMovedForEditMode,
              ).filter((nodeId) =>
                Object.keys(tempNodesMovedForEditMode!).includes(nodeId),
              )) {
                tempNodesMovedForEditMode[commonNode].previousPosition =
                  currentNodesMovedForEditMode[commonNode].previousPosition
              }

              for (let uniqueNode of Object.keys(
                currentNodesMovedForEditMode,
              ).filter(
                (nodeId) =>
                  !Object.keys(tempNodesMovedForEditMode!).includes(nodeId) &&
                  nodeClicked !== nodeId,
              )) {
                tempTreeDetailsWithEditModeOn.nodes[
                  uniqueNode as NodeId
                ].properties.position =
                  currentNodesMovedForEditMode[uniqueNode].previousPosition
              }

              if (
                Object.keys(currentNodesMovedForEditMode).includes(nodeClicked)
              ) {
                tempNodesMovedForEditMode[nodeClicked] =
                  currentNodesMovedForEditMode[nodeClicked]
              }

              tempNodesMovedForEditMode = {
                ...currentNodesMovedForEditMode,
                ...tempNodesMovedForEditMode,
              }
            } else {
              tempNodesMovedForEditMode = findNodesMovedForEdtiMode(
                nodeClicked,
                treeDetails,
                treeNodeMode,
                true,
                props.treeIndex,
              )
            }
            props.setEditMode(nodeClicked)

            if (
              tempNodesMovedForEditMode &&
              Object.keys(tempNodesMovedForEditMode).length === 0
            ) {
              tempNodesMovedForEditMode = undefined
            }

            if (tempNodesMovedForEditMode !== undefined) {
              setNodesMovedForEditMode(tempNodesMovedForEditMode)
              let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

              let tempTreeDetails =
                tempTreeDetailsWithEditModeOn !== undefined
                  ? tempTreeDetailsWithEditModeOn
                  : (tempScenarioSnapshot.currentSnapshot.claims[
                      props.treeIndex
                    ].treeDetails as TreeDetails)

              for (let nodeId of Object.keys(tempNodesMovedForEditMode)) {
                tempTreeDetails.nodes[nodeId as NodeId].properties.position =
                  tempNodesMovedForEditMode[nodeId].newPosition
              }
              ;(tempScenarioSnapshot.currentSnapshot.claims[props.treeIndex]
                .treeDetails as TreeDetails) = tempTreeDetails
              changeTextAreaHeight(
                document.getElementById(
                  `treeNodeTitleTextarea-${props.treeIndex}_${nodeClicked}`,
                )!,
              )
              setScenarioSnapshot(tempScenarioSnapshot)
            }
          }
          //The tree is minimised so we close the Edit Mode and trigger it again
          else {
            let tempEditMode = props.editMode
            setTimeout(() => {
              props.setEditMode(undefined)
              changeTextAreaHeight(
                document.getElementById(
                  `treeNodeTitleTextarea-${props.treeIndex}_${tempEditMode}`,
                )!,
              )
            }, 100)

            setEditTreeNodeFromUndo(undefined)
          }
        }
      }
      //There is no node in Edit Modeclick comes from a Node
      else if (props.editMode === undefined) {
        if (nodeClicked) {
          tempNodesMovedForEditMode = findNodesMovedForEdtiMode(
            nodeClicked,
            treeDetails,
            treeNodeMode,
            true,
            props.treeIndex,
          )
          props.setEditMode(nodeClicked)
          if (treeNodeMode === NodeMode.minimised) {
            setTimeout(() => {
              focusOnEditMode(e.target.id)
            }, 30)
          }
          if (
            tempNodesMovedForEditMode &&
            Object.keys(tempNodesMovedForEditMode).length === 0
          ) {
            tempNodesMovedForEditMode = undefined
          }

          if (tempNodesMovedForEditMode !== undefined) {
            setNodesMovedForEditMode(tempNodesMovedForEditMode)
            let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)

            let tempTreeDetails = tempScenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ].treeDetails as TreeDetails

            for (let nodeId of Object.keys(tempNodesMovedForEditMode)) {
              tempTreeDetails.nodes[nodeId as NodeId].properties.position =
                tempNodesMovedForEditMode[nodeId].newPosition
            }
            ;(tempScenarioSnapshot.currentSnapshot.claims[props.treeIndex]
              .treeDetails as TreeDetails) = tempTreeDetails
            changeTextAreaHeight(
              document.getElementById(
                `treeNodeTitleTextarea-${props.treeIndex}_${nodeClicked}`,
              )!,
            )
            setScenarioSnapshot(tempScenarioSnapshot)
          }
        } else {
        }
      }
    }
    //updateXarrow()
  }

  function deleteNodeOrEventMenu(e: any) {
    if (
      openDeleteNodeOrEventMenu &&
      !e.target.id.includes('deleteNodeOrEventMenu')
    ) {
      setOpenDeleteNodeOrEventMenu(undefined)
    }
  }

  function deleteFakeLine(e: any) {
    //console.log(e.target.id)

    if (availableTargetsForLine.length > 0) {
      setNodeCreatingLine(undefined)
      setDragPosition(undefined)
      setChangingLineStartId(undefined)
    }
  }

  function deleteLine(nodeId: NodeId, eventId: EventId) {
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim

    let index = tempTreeClaim.treeDetails.events[
      eventId
    ].childrenNodes.findIndex((node) => node === nodeId)
    tempTreeClaim.treeDetails.events[eventId].childrenNodes.splice(index, 1)
    const node = tempTreeClaim.treeDetails.nodes[nodeId]

    const siblingIndex = node.nodeSiblingIndex as {
      [parentEventId: string]: number
    }
    if (Object.keys(siblingIndex).length > 1) {
      delete siblingIndex[eventId]
    } else {
      node.root = RootNodeType.orphanTreeRoot
      node.nodeSiblingIndex = {}
    }

    let tempMainTree: NodeId[]
    let tempOrphanTrees: NodeId[][]
    ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
      findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
    if (tempMainTree.includes('node123')) return
    props.setMainTree(tempMainTree)
    props.setOrphanTrees(tempOrphanTrees)
    //hide Treetable
    const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
      (treeTableInfo) =>
        treeTableInfo.treeId ===
        scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
    )

    let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
    if (
      tempTreeTables.treeTablesInfo &&
      tempTreeTables.treeTablesInfo[treeTableInfoIndex]
    ) {
      tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
      setTreeTables(tempTreeTables)
    }
    tempScenarioSnapshot = changeTreeClaim(
      tempScenarioSnapshot,
      scenarioSnapshot.currentSnapshot,
      tempTreeClaim,
      `connectionCircle-${props.treeIndex}_${nodeId}`,
      UndoRedoType.button,
      props.treeIndex,
      false,
      props.ownRole,
    )
    //Mixpanel 122 (All)
    logActivity(freemium.isFreemium, 'Deleted a line in legal tree')
    setScenarioSnapshot(tempScenarioSnapshot)
  }

  function findDimensions() {
    //props.setRenderLines(false)
    let tempCanvasWidth = findWidthOfCanvas(
      treeClaim.nodeMode,
      treeClaim.treeDetails,
    )
    let tempCanvasHeight = findHeightOfCanvas(
      treeClaim.nodeMode,
      treeClaim.treeDetails,
      props.treeIndex,
    )
    setCanvasWidth(tempCanvasWidth)
    setCanvasHeight(tempCanvasHeight)
    /* setTimeout(() => {
      props.setRenderLines(true)
    }, 100) */
    // eslint-disable-next-line
  }
  function handleSetLineHover(
    nodeId: NodeId,
    eventId: EventId,
    state: boolean,
  ) {
    if (state && props.ownRole !== AccessRole.VIEWER) {
      setLineHover([nodeId, eventId])
    } else {
      setLineHover(undefined)
    }
  }
  function handleSetLineClick(
    nodeId: NodeId,
    eventId: EventId,
    state: boolean,
  ) {
    if (state && props.ownRole !== AccessRole.VIEWER) {
      setLineClick([nodeId, eventId])
      //Mixpanel 127 (All)
      logActivity(freemium.isFreemium, 'Selected a line in legal tree')
    } else {
      setLineClick(undefined)
    }
  }

  function handleDeleteTreeNode(
    withChildren: boolean,
    nodeId: NodeId,
    selectedNodes?: NodeId[],
  ) {
    setOpenDeleteNodeOrEventMenu(undefined)

    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempNodesMovedForEditMode = deepCloneObject(nodesMovedForEditMode)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim

    const rootsToBeDeleted = selectedNodes ? selectedNodes : [nodeId]

    let tempElementsToBeDeletedForAnimation: (NodeId | EventId)[] = []
    for (let rootNodeId of rootsToBeDeleted) {
      if (withChildren) {
        let [, newMainTree, orphanTrees] = findMainTreeAndOrphanTrees(
          treeDetails,
          true,
        )
        if (newMainTree.includes('node123')) return

        let trees = [newMainTree].concat(orphanTrees)

        tempElementsToBeDeletedForAnimation =
          tempElementsToBeDeletedForAnimation.concat(
            findNodesOfTreeFromRoot(
              rootNodeId as NodeId,
              treeDetails,
              [],
              trees,
              true,
            ),
          )
      } else {
        tempElementsToBeDeletedForAnimation =
          tempElementsToBeDeletedForAnimation.concat([rootNodeId])
      }
      if (
        tempNodesMovedForEditMode &&
        Object.keys(tempNodesMovedForEditMode).includes(rootNodeId as NodeId)
      ) {
        delete tempNodesMovedForEditMode[rootNodeId]
      }
    }
    setElementsToBeDeletedForAnimation(
      tempElementsToBeDeletedForAnimation.filter(onlyUnique) as NodeId[],
    )
    setTimeout(() => {
      props.setRenderLines(false)
      setElementsToBeDeletedForAnimation([])
      for (let rootNodeId of rootsToBeDeleted) {
        tempScenarioSnapshot = deleteTreeNode(
          tempScenarioSnapshot,
          tempTreeClaim,
          props.treeIndex,
          rootNodeId as NodeId,
          withChildren,
        )
      }

      let tempMainTree: NodeId[]
      let tempOrphanTrees: NodeId[][]
      ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
        findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
      if (tempMainTree.includes('node123')) return
      props.setMainTree(tempMainTree)
      props.setOrphanTrees(tempOrphanTrees)

      tempScenarioSnapshot = changeTreeClaim(
        tempScenarioSnapshot,
        scenarioSnapshot.currentSnapshot,
        tempTreeClaim,
        `treeNode-${props.treeIndex}_${nodeId}`,
        UndoRedoType.button,
        props.treeIndex,
        false,
        props.ownRole,
      )
      setNodesMovedForEditMode(tempNodesMovedForEditMode)
      setScenarioSnapshot(tempScenarioSnapshot)
      //hide Treetable
      const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
        (treeTableInfo) =>
          treeTableInfo.treeId ===
          scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
      )

      let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
      if (
        tempTreeTables.treeTablesInfo &&
        tempTreeTables.treeTablesInfo[treeTableInfoIndex]
      ) {
        tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
        setTreeTables(tempTreeTables)
      }
      updateXarrow()
      props.setSelectedNodes([])
      if (selectedNodes) {
        if (withChildren) {
          //Mixpanel 115 (All)
          logActivity(
            freemium.isFreemium,
            'Deleted selected nodes with children in legal tree',
          )
        } else {
          //Mixpanel 116 (All)
          logActivity(
            freemium.isFreemium,
            'Deleted only selected nodes in legal tree',
          )
        }
      } else {
        if (withChildren) {
          //Mixpanel 117 (All)
          logActivity(
            freemium.isFreemium,
            'Deleted node with children in legal tree',
          )
        } else {
          //Mixpanel 118 (All)
          logActivity(freemium.isFreemium, 'Deleted only node in legal tree')
        }
      }
      setTimeout(() => {
        props.setRenderLines(true)
      }, 100)
    }, 200)
  }

  function handleDeleteTreeEvent(
    withChildren: boolean,
    nodeId: NodeId,
    eventId: EventId,
    eventIndex: number,
  ) {
    setOpenDeleteNodeOrEventMenu(undefined)
    let tempScenarioSnapshot = deepCloneObject(scenarioSnapshot)
    let tempTreeClaim = tempScenarioSnapshot.currentSnapshot.claims[
      props.treeIndex
    ] as TreeClaim
    const tempUndoRedoType =
      treeNodeMode === NodeMode.maximised
        ? UndoRedoType.button
        : UndoRedoType.minimisedButton
    if (withChildren) {
      let tempElementsToBeDeletedForAnimation: (NodeId | EventId)[] =
        findChildrenNodesFromEvent(eventId, tempTreeClaim.treeDetails, [], true)
      tempElementsToBeDeletedForAnimation.push(eventId)
      setElementsToBeDeletedForAnimation(tempElementsToBeDeletedForAnimation)
    } else {
      setElementsToBeDeletedForAnimation([eventId])
    }

    setTimeout(() => {
      if (
        tempTreeClaim.treeDetails.events[eventId].eventDetails.probability !== 0
      ) {
        if (eventIndex === 0) {
          setShakeScenario([nodeId, 0])
        } else {
          setTimeout(() => {
            setShakeScenario([nodeId, eventIndex - 1])
          }, 100)
        }
      }

      tempScenarioSnapshot = deleteTreeEvent(
        tempScenarioSnapshot,
        tempTreeClaim,
        props.treeIndex,
        eventId,
        withChildren,
      )
      tempTreeClaim.treeDetails.nodes[nodeId].numberOfEvents--
      let tempNodeProbabilities = { ...nodeProbabilities }

      tempScenarioSnapshot = redistributeNodeProbabilities(
        tempScenarioSnapshot,
        tempTreeClaim,
        props.treeIndex,
        tempNodeProbabilities[nodeId],
        eventIndex,
        nodeId,
      )
      tempNodeProbabilities[nodeId] = findNewNodeProbabilities(
        tempNodeProbabilities[nodeId],
        eventIndex,
      )

      setNodeProbabilities(tempNodeProbabilities)

      let tempMainTree: NodeId[]
      let tempOrphanTrees: NodeId[][]
      ;[tempTreeClaim.treeDetails, tempMainTree, tempOrphanTrees] =
        findMainTreeAndOrphanTrees(tempTreeClaim.treeDetails)
      if (tempMainTree.includes('node123')) return
      props.setMainTree(tempMainTree)
      props.setOrphanTrees(tempOrphanTrees)

      tempScenarioSnapshot = changeTreeClaim(
        tempScenarioSnapshot,
        scenarioSnapshot.currentSnapshot,
        tempTreeClaim,
        [
          `treeNodeTitle-${props.treeIndex}_${nodeId}`,
          `treeEventAddNodeButton-${props.treeIndex}_${nodeId}!${eventIndex}`,
        ],
        tempUndoRedoType,
        props.treeIndex,
        false,
        props.ownRole,
        nodesMovedForEditMode,
      )

      setScenarioSnapshot(tempScenarioSnapshot)
      //hide Treetable
      const treeTableInfoIndex = treeTables.treeTablesInfo.findIndex(
        (treeTableInfo) =>
          treeTableInfo.treeId ===
          scenarioSnapshot.currentSnapshot.claims[props.treeIndex].id,
      )

      let tempTreeTables: TreeTablesState = deepCloneObject(treeTables)
      if (
        tempTreeTables.treeTablesInfo &&
        tempTreeTables.treeTablesInfo[treeTableInfoIndex]
      ) {
        tempTreeTables.treeTablesInfo[treeTableInfoIndex].showTreeTable = false
        setTreeTables(tempTreeTables)
      }
      setElementsToBeDeletedForAnimation([])

      if (withChildren) {
        //Mixpanel 119 (All)
        logActivity(
          freemium.isFreemium,
          'Deleted event with children in legal tree',
        )
      } else {
        //Mixpanel 120 (All)
        logActivity(freemium.isFreemium, 'Deleted only event in legal tree')
      }

      setTimeout(() => {
        setShakeScenario(undefined)
      }, 600)
      setTimeout(() => {
        changeTextAreaHeight(
          document.getElementById(
            `treeNodeTitleTextarea-${props.treeIndex}_${nodeId}`,
          )!,
        )
        updateXarrow()
      }, 10)
    }, 50)
    props.setRenderLines(false)

    setTimeout(() => {
      changeTextAreaHeight(
        document.getElementById(
          `treeNodeTitleTextarea-${props.treeIndex}_${nodeId}`,
        )!,
      )
      props.setRenderLines(true)
      updateXarrow()
    }, 10)
    // updateXarrow()
  }

  const lowerOpacityForLine = (
    nodeId: NodeId,
    parentNodeId: NodeId,
    parentEventId: EventId,
  ) => {
    const hasAnalysisResults =
      (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
        .analysisResults &&
      treeAnalysis.treeTablesInfo.filter(
        (treeAnalysisObject) =>
          treeAnalysisObject.treeId ===
          (
            scenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ] as TreeClaim
          ).id,
      )[0] &&
      treeAnalysis.treeTablesInfo.filter(
        (treeAnalysisObject) =>
          treeAnalysisObject.treeId ===
          (
            scenarioSnapshot.currentSnapshot.claims[
              props.treeIndex
            ] as TreeClaim
          ).id,
      )[0].results !== undefined

    if (
      (hasAnalysisResults ||
        scenarioIdentity.snapshotStatus === SnapshotStatus.Done ||
        props.ownRole === AccessRole.VIEWER) &&
      (!props.mainTree.includes(nodeId) ||
        !props.mainTree.includes(parentNodeId))
      // ||
      // props.nodesUnderZeroProbability.includes(nodeId) ||
      // props.nodesUnderZeroProbability.includes(parentNodeId) ||
      // (scenarioSnapshot.currentSnapshot.claims[props.treeIndex] as TreeClaim)
      //   .treeDetails.events[parentEventId].eventDetails.probability === 0
      //
    ) {
      return true
    }

    return false
  }

  return (
    <div className="treeAppBackground">
      {fullscreenActionMessage === ActionMessage.downloadingImage && (
        <TreePreviewForImage
          treeClaim={deepCloneObject(treeClaim)}
          treeIndex={props.treeIndex}
          mainTree={deepCloneObject(props.mainTree)}
          setRenderLines={props.setRenderLines}
          nodesUnderZeroProbability={props.nodesUnderZeroProbability}
        />
      )}
      {debugging ? (
        <div className="treeInfoForDebugging">
          <button
            type="button"
            className="tempButton"
            id="tempButton-dimensions"
            onClick={() => setShowTreeInfo(!showTreeInfo)}
          >
            Show/Hide Tree Info
          </button>

          <p className="tempInfo" id="tempButton-info">
            Number Of Main Tree Scenarios: {treeClaim.numOfTreeScenarios}
          </p>
        </div>
      ) : null}
      {showTreeInfo && debugging ? (
        <TreeInfo
          treeDetails={treeDetails}
          selectedNodes={props.selectedNodes}
          nodesMovedForEditMode={nodesMovedForEditMode}
          editMode={props.editMode}
        />
      ) : null}

      <div
        className={`treeCanvasBackground${
          showTreeInfo && debugging ? 'border' : ''
        }`}
        id={`treeCanvasBackgroundAbsolute-${props.treeIndex}`}
        style={{
          position: 'absolute',
          width: canvasWidth,
          height: canvasHeight + 1000,
          transform: `scale(${treeClaim.zoomLevel})`,
          transformOrigin: 'top left',
          left: 100,
        }}
      >
        {showTreeInfo && debugging ? (
          <>
            {Object.keys(treeDetails.nodes).map((nodeId, index) => (
              <div
                key={`positionObject-${index}`}
                id={`positionObject-${nodeId}`}
                style={{
                  backgroundColor: 'pink',
                  position: 'absolute',
                  top: findTopPointOfNode(
                    nodeId as NodeId,
                    treeDetails,
                    findHeightOfTitle(
                      nodeId as NodeId,
                      props.treeIndex,
                      treeNodeMode,
                    ),
                  ),
                  left: findLeftPointOfNode(
                    nodeId as NodeId,
                    treeDetails,
                    treeNodeMode,
                  ),
                  width: widthOfNode(
                    nodeId as NodeId,
                    props.editMode === nodeId
                      ? NodeMode.maximised
                      : treeNodeMode,
                    treeDetails,
                  ),
                  height: heightOfNode(
                    treeNodeMode,
                    nodeId as NodeId,
                    props.treeIndex,
                    treeDetails,
                    props.editMode === nodeId,
                  ),
                }}
              ></div>
            ))}
          </>
        ) : null}
      </div>
      <Xwrapper>
        {props.renderLines &&
          Object.keys(treeClaim.treeDetails.events).map((eventId) =>
            treeClaim.treeDetails.events[eventId as EventId].childrenNodes.map(
              (nodeId) => {
                return (changingLineStartId ===
                  `connectionCircle-${props.treeIndex}_${nodeId}` ||
                  changingLineStartId ===
                    `treeAddNodeDot-${props.treeIndex}_${
                      treeClaim.treeDetails.events[eventId as EventId]
                        .nodeOfEventId
                    }!${
                      treeClaim.treeDetails.events[eventId as EventId]
                        .eventIndex
                    }`) &&
                  lineClick![0] === nodeId &&
                  lineClick![1] === eventId ? null : (
                  <React.Fragment
                    key={`Line-${nodeId}-${
                      treeDetails.events[eventId as EventId].nodeOfEventId
                    }!${treeDetails.events[eventId as EventId].eventIndex}`}
                  >
                    {props.ownRole !== AccessRole.VIEWER && (
                      <HoverLine
                        key={`hoverLine-${nodeId}-${
                          treeDetails.events[eventId as EventId].nodeOfEventId
                        }!${treeDetails.events[eventId as EventId].eventIndex}`}
                        startId={`connectionCircle-${props.treeIndex}_${nodeId}`}
                        endId={`treeAddNodeDot-${props.treeIndex}_${
                          treeDetails.events[eventId as EventId].nodeOfEventId
                        }!${treeDetails.events[eventId as EventId].eventIndex}`}
                        nodeId={nodeId}
                        eventId={eventId as EventId}
                        events={treeDetails.events}
                        zoomLevel={treeClaim.zoomLevel}
                        hanldeSetLineHover={handleSetLineHover}
                        handleSetLineClick={handleSetLineClick}
                        lineClick={
                          JSON.stringify(lineClick) ===
                          JSON.stringify([nodeId, eventId])
                        }
                        treeIndex={props.treeIndex}
                        treeLines={treeLines}
                      />
                    )}
                    <ConnectedLine
                      key={`connectedLine-${nodeId}-${
                        treeDetails.events[eventId as EventId].nodeOfEventId
                      }!${treeDetails.events[eventId as EventId].eventIndex}`}
                      startId={`connectionCircle-${props.treeIndex}_${nodeId}`}
                      endId={`treeAddNodeDot-${props.treeIndex}_${
                        treeDetails.events[eventId as EventId].nodeOfEventId
                      }!${treeDetails.events[eventId as EventId].eventIndex}`}
                      nodeId={nodeId}
                      eventId={eventId as EventId}
                      selectedLine={
                        props.selectedNodes.includes(nodeId as NodeId) &&
                        props.selectedNodes.includes(
                          treeClaim.treeDetails.events[eventId as EventId]
                            .nodeOfEventId as NodeId,
                        )
                      }
                      events={treeClaim.treeDetails.events}
                      deleteLine={deleteLine}
                      zoomLevel={treeClaim.zoomLevel}
                      treeLines={treeLines}
                      lineHover={lineHover}
                      lineClick={lineClick}
                      fadeOut={elementsToBeDeletedForAnimation.includes(nodeId)}
                      treeIndex={props.treeIndex}
                      grayOut={lowerOpacityForLine(
                        nodeId,
                        treeDetails.events[eventId as EventId].nodeOfEventId,
                        eventId as EventId,
                      )}
                      ownRole={props.ownRole}
                      hanldeSetLineHover={handleSetLineHover}
                    />
                  </React.Fragment>
                )
              },
            ),
          )}
        {nodeCreatingLine && dragPosition ? (
          <>
            <div
              className="treeDraggableElement"
              ref={draggableLineRef}
              style={{ ...dragPosition }}
            ></div>
            <ConnectingLine
              startId={`connectionCircle-${props.treeIndex}_${nodeCreatingLine}`}
              endRef={draggableLineRef}
              treeLines={treeLines}
            />
          </>
        ) : null}
        {changingLineStartId && dragPosition ? (
          <>
            <div
              className="treeDraggableElement"
              ref={draggableLineRef}
              style={{ ...dragPosition }}
            ></div>
            <ConnectingLine
              startId={changingLineStartId}
              endRef={draggableLineRef}
              treeLines={treeLines}
            />
          </>
        ) : null}
        <div
          className="treeCanvasBackground"
          id={`treeCanvasBackground-${props.treeIndex}`}
          style={{
            width: 1,
            height: 1,
            transform: `scale(${treeClaim.zoomLevel})`,
            transformOrigin: 'top left',
          }}
        >
          {Object.keys(treeClaim.treeDetails.nodes).map((nodeId, index) => (
            <TreeNode
              nodeDetails={treeClaim.treeDetails.nodes[nodeId as NodeId]}
              events={treeClaim.treeDetails.events}
              nodeId={nodeId as NodeId}
              zoomLevel={treeClaim.zoomLevel}
              treeIndex={props.treeIndex}
              root={treeClaim.treeDetails.nodes[nodeId as NodeId].root}
              key={`nodeId-${nodeId}_${index}`}
              setRenderLines={props.setRenderLines}
              handleSetLineHover={handleSetLineHover}
              handleSetLineClick={handleSetLineClick}
              lineClick={lineClick}
              selectedNodes={props.selectedNodes}
              setSelectedNodes={props.setSelectedNodes}
              mainTree={props.mainTree}
              orphanTrees={props.orphanTrees}
              elementsToBeDeletedForAnimation={elementsToBeDeletedForAnimation}
              setElementsToBeDeletedForAnimation={
                setElementsToBeDeletedForAnimation
              }
              setMainTree={props.setMainTree}
              setOrphanTrees={props.setOrphanTrees}
              nodeCreatingLine={nodeCreatingLine}
              setNodeCreatingLine={setNodeCreatingLine}
              availableTargetsForLine={availableTargetsForLine}
              setAvailableTargetsForLine={setAvailableTargetsForLine}
              setDragPosition={setDragPosition}
              setChangingLineStartId={setChangingLineStartId}
              showTreeInfo={showTreeInfo}
              editMode={props.editMode}
              setEditMode={props.setEditMode}
              resetActivePath={props.resetActivePath}
              selectedPath={props.selectedPath}
              debugging={debugging}
              shakeScenario={shakeScenario}
              openDeleteNodeOrEventMenu={openDeleteNodeOrEventMenu}
              setOpenDeleteNodeOrEventMenu={setOpenDeleteNodeOrEventMenu}
              nodeProbabilities={nodeProbabilities}
              handleDeleteTreeEvent={handleDeleteTreeEvent}
              handleDeleteTreeNode={handleDeleteTreeNode}
              connectedDot={connectedDot}
              setConnectedDot={setConnectedDot}
              openOrphanNodesWarningPopUp={props.openOrphanNodesWarningPopUp}
              openZeroProbabilityWarningPopUp={
                props.openZeroProbabilityWarningPopUp
              }
              ownRole={props.ownRole}
              toggleEditMode={toggleEditMode}
              errors={props.errors}
              connectionCreatesLoop={connectionCreatesLoop}
              setConnectionCreatesLoop={setConnectionCreatesLoop}
              nodesUnderZeroProbability={props.nodesUnderZeroProbability}
              copiedNodes={copiedNodes}
              setCopiedNodes={setCopiedNodes}
            />
          ))}
          {openDeleteNodeOrEventMenu && props.ownRole !== AccessRole.VIEWER && (
            <DeleteNodeOrEventMenu
              treeIndex={props.treeIndex}
              openDeleteNodeOrEventMenu={openDeleteNodeOrEventMenu!}
              setOpenDeleteNodeOrEventMenu={setOpenDeleteNodeOrEventMenu}
              position={findDeleteNodeOrEventMenuPosition(
                openDeleteNodeOrEventMenu,
                treeDetails,
              )}
              handleDeleteTreeEvent={handleDeleteTreeEvent}
              handleDeleteTreeNode={handleDeleteTreeNode}
              selectedNodes={props.selectedNodes}
              removeClaim={props.removeClaim}
            />
          )}
        </div>
      </Xwrapper>
    </div>
  )
}
