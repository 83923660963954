import { EventId, RootNodeType, TreeNodeProperties } from './treeTypes'

export class TreeNodeClass {
  nodeTitle: string
  nodeSiblingIndex: { [parentEventId: EventId]: number } // the index of the node counting the siblingNodes for each of the parents of the node
  properties: TreeNodeProperties
  numberOfEvents: number //This will be used to count the width of a treeNode
  root: RootNodeType

  constructor(
    nodeTitle: string,
    nodeSiblingIndex: { [parentEventId: EventId]: number },
    properties: TreeNodeProperties,
    numberOfEvents: number,
    root: RootNodeType,
  ) {
    this.nodeTitle = nodeTitle
    this.nodeSiblingIndex = nodeSiblingIndex
    this.properties = properties
    this.numberOfEvents = numberOfEvents
    this.root = root
  }

  static defaultTreeNode(
    nodeSiblingIndex: { [parentEventId: EventId]: number },
    position: [number, number],
    root: RootNodeType,
  ): TreeNodeClass {
    return new TreeNodeClass(
      '',
      nodeSiblingIndex,
      { position: position },
      2,
      root,
    )
  }
}
