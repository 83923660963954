import { useRecoilState, useRecoilValue } from 'recoil'
import Button from '../../Buttons/Button/Button'
import { activeTabsState } from '../../../states/ActiveTabsState'
import { ToolTabs } from '../../../models/enums'
import { getText } from '../../../services/textFunctions'
import { userState } from '../../../states/UserState'
import { logActivity } from '../../../services/commonFunctions'
import { freemiumState } from '../../../states/FreemiumState'

type Props = {}

export default function NoLegalCostsIncluded(props: Props) {
  const [activeTabs, setActiveTabs] = useRecoilState(activeTabsState)
  const user = useRecoilValue(userState)
  const freemium = useRecoilValue(freemiumState)

  function navigateToLegalCostsTab() {
    setActiveTabs({ tab: ToolTabs.legalCosts, claim: activeTabs.claim })
    //Mixpanel 172 (All)
    logActivity(
      freemium.isFreemium,
      `Navigates to Legal Costs Tab from LCD Button`,
    )
  }
  return (
    <>
      <div
        className="noLegalCostsIncludedBackground"
        id="noLegalCostsIncludedBackground"
      ></div>

      <div
        className="noLegalCostsIncludedContainer"
        id="noLegalCostsIncludedContainer"
      >
        <div
          className="noLegalCostsIncludedMessageContainer"
          id="noLegalCostsIncludedMessageContainer"
        >
          <p
            className="noLegalCostsIncludedMessage"
            id="noLegalCostsIncludedMessage"
            data-textattribute="message-116"
          >
            {getText('message-116', user.settings)}
          </p>
        </div>
        <Button
          id={'includeLegalCostsForLCDButton'}
          buttonType="contained"
          buttonText={getText('button-154', user.settings)}
          buttonTextAttribute={'button-154'}
          type={'button'}
          onClick={navigateToLegalCostsTab}
          side="left"
          NoUpperCase={true}
          small={false}
        />
      </div>
    </>
  )
}
