import React, { useEffect, useState } from 'react'
import { PageType } from '../UserPopUp'
import phoneData from 'country-telephone-data'
import backCircleImg from '../../../resources/images/134-backLogin.svg'
import CircularProgress from '@mui/material/CircularProgress'
import InputNum from '../../../Components/Inputs/InputNum/InputNum'
import DropDown from '../../../Components/Inputs/DropDown/DropDown'
import { getText } from '../../../services/textFunctions'
import { useRecoilValue } from 'recoil'
import { userState } from '../../../states/UserState'

type Props = {
  validationMessage: string | undefined
  setPhoneNumber: (value: string) => void
  setPhoneRegion: (value: string) => void
  setPageType: (type: PageType) => void
  loading: boolean
  errors: string[]
}

const SmsForm = (props: Props) => {
  const user = useRecoilValue(userState)

  const [prefix, setPrefix] = useState('Sweden (Sverige) +46')
  const [pN, setPN] = useState(undefined)

  useEffect(() => {
    setRegion('eu')
    //eslint-disable-next-line
  }, [])

  const setRegion = (value: string) => {
    switch (value) {
      case 'United States +1':
        props.setPhoneRegion('us')
        break
      case 'Canada +1':
        props.setPhoneRegion('ca')
        break
      default:
        props.setPhoneRegion('eu')
        break
    }
  }

  return (
    <>
      <img
        className="backImg"
        src={backCircleImg}
        alt="backImg"
        onClick={() => props.setPageType('SetupTwoFactor')}
      />
      <div className="welcomeMessageContainer">
        <h2
          className="welcome"
          id="smsFormTitle"
          data-textattribute="title-117"
        >
          {getText('title-117', user.settings)}
        </h2>
        <p
          className="instructions"
          id="smsFormInstruction"
          data-textattribute="description-90"
        >
          {getText('description-90', user.settings)}
        </p>
      </div>
      <div className="inputsContainer">
        <div className="inputEmail">
          <DropDown
            label={getText('label-64', user.settings)}
            labelTextAttribute="label-64"
            id="phonePrefix"
            name="phonePrefix"
            width={470}
            onChange={(e) => {
              setPrefix(e.target.value)
              setRegion(e.target.value)
            }}
            borderColor="#462777" //lakersUniformHover
            error={!prefix}
            validateFunction={(value) => true}
            errorMessage={getText('error-14', user.settings)}
            errorTextAttribute={'error-14'}
            value={prefix}
            options={phoneData.allCountries.map((phone) => {
              return `${phone.name} +${phone.dialCode}`
            })}
          />
        </div>
        <InputNum
          label={getText('label-58', user.settings)}
          labelTextAttribute="label-58"
          id="phoneNumber"
          name="phoneNumber"
          width={470}
          onChange={(e: any) => {
            setPN(e.target.value)
            props.setPhoneNumber(
              prefix.substring(prefix.indexOf('+')) +
                e.target.value?.toString(),
            )
          }}
          alwaysShrink
          helperText={getText('helperText-3', user.settings)}
          helperTextAttribute="helperText-3"
          prefix={prefix.substring(prefix.indexOf('+'))}
          maxValue={999999999999999}
          maxNumberOfDecimals={0}
          error={
            props.errors.includes('phoneNumber') ||
            props.validationMessage !== undefined
          }
          value={pN}
          errorMessage={
            props.validationMessage
              ? props.validationMessage
              : getText('error-31', user.settings)
          }
          errorTextAttribute={props.validationMessage ? 'error-00' : 'error-31'}
          noFormat
          noDelay
        />
      </div>
      <div className="submitButtonContainer">
        <button
          type="submit"
          className="submitButton"
          disabled={props.loading}
          id="submitSmsFormButton"
          data-textattribute="button-59"
        >
          {props.loading ? (
            <CircularProgress size={22} />
          ) : (
            getText('button-59', user.settings)
          )}
        </button>
      </div>
    </>
  )
}

export default SmsForm
