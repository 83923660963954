import React from 'react'
import { useRecoilValue } from 'recoil'
import { TypeOfInstance } from '../../../../../../../../models/enums'
import bracketImg from '../../../../../../../../resources/images/258-prob2ndcourt.svg'
import { formattedNumToString } from '../../../../../../../../services/formatNum'
import { getText } from '../../../../../../../../services/textFunctions'
import { scenarioSnapshotState } from '../../../../../../../../states/ScenarioSnapshotState'
import { userState } from '../../../../../../../../states/UserState'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'

type Props = {
  reportWhitelabel?: ReportWhitelabel
}

export default function SecondTrialProbsComponent(props: Props) {
  const user = useRecoilValue(userState)
  const currentSnapshot = useRecoilValue(scenarioSnapshotState).currentSnapshot

  return (
    <div className="secondTrialProbsContainer">
      <div className="leftPart">
        <div className="row">
          <p
            className="probability"
            id="secondTrialProbsForPreviewProbability1"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {formattedNumToString(
              currentSnapshot.probSecondTrialstep1!,
              user.settings,
            )}{' '}
            %
          </p>
          <p
            className="text"
            id="secondTrialProbsForPreviewTitle1"
            data-textattribute={
              currentSnapshot.court === TypeOfInstance.Public_Court
                ? 'title-145a'
                : 'title-145b'
            }
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {currentSnapshot.court === TypeOfInstance.Public_Court
              ? getText('title-145a', user.settings)
              : getText('title-145b', user.settings)}
          </p>
        </div>
        <div className="row">
          <p
            className="probability"
            id="secondTrialProbsForPreviewProbability2"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {formattedNumToString(
              currentSnapshot.probSecondTrialstep2!,
              user.settings,
            )}{' '}
            %
          </p>
          <p
            className="text"
            id="secondTrialProbsForPreviewTitle2"
            data-textattribute="title-146"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {getText('title-146', user.settings)}
          </p>
        </div>
      </div>
      <div className="middlePart">
        <img src={bracketImg} alt="bracketImg" className="bracketImg" />
      </div>
      <div className="rightPart">
        <div className="row">
          <p
            className="probability"
            id="secondTrialProbsForPreviewProbability3"
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {formattedNumToString(
              currentSnapshot.probSecondTrial!,
              user.settings,
            )}{' '}
            %
          </p>
          <p
            className="text"
            id="secondTrialProbsForPreviewTitle3"
            data-textattribute={
              currentSnapshot.court === TypeOfInstance.Public_Court
                ? 'title-147'
                : 'title-148'
            }
            style={getStyleFromWhitelabel(
              props.reportWhitelabel,
              ReportWhitelabelKeys.sectionDescription,
              FontLabels.regular,
              'noRoboto',
            )}
          >
            {currentSnapshot.court === TypeOfInstance.Public_Court
              ? getText('title-147', user.settings)
              : getText('title-148', user.settings)}
          </p>
        </div>
      </div>
    </div>
  )
}
