import { useState, useEffect, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  VictoryChart,
  VictoryAxis,
  VictoryTooltip,
  VictoryArea,
  VictoryLabel,
  VictoryContainer,
} from 'victory'
import { DomainTuple } from 'victory-core'
import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import { stringAndRoundTo2Decimals } from '../../../../../../../../Modules/DisputeModules/AppFunctions'
import {
  floorNumberTo,
  logActivity,
  roundNumberTo,
} from '../../../../../../../../services/commonFunctions'
import { userState } from '../../../../../../../../states/UserState'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import { getStyleFromWhitelabel } from '../../../../../../../../services/reportFunctions'
import {
  FontLabels,
  ReportWhitelabelKeys,
} from '../../../../../../../../models/reportModels/reportEnums'
import { resultsSettingsState } from '../../../../../../../../states/ResultsSettingsState'
import { stringAndRoundBigNumbers } from '../../../../../../../../Modules/DisputeModules/AppFunctionsNew'
import { findDisputedAmount } from '../../../../../../../../services/disputedAmountFunctions'
import SelectionArea from './SelectionArea'
import useWindowSize from '../../../../../../../../customHooks/useWindowSize'
import { fullscreenActionMessageState } from '../../../../../../../../states/FullScreenActionMessageState'
import { ActionMessage } from '../../../../../../../../models/enums'
import { freemiumState } from '../../../../../../../../states/FreemiumState'
import tickedImg from '../../../../../../../../resources/images/115-tickPurple.svg'
import unTickedImg from '../../../../../../../../resources/images/018-untickedGrayBlue.svg'
import { saveResultsSettings } from '../../../../../../../../services/requests'
import { scenarioIdentityState } from '../../../../../../../../states/ScenarioIdentityState'
import { resultsAreNotZero } from '../../../../../../../../services/resultsFunctions'
import { getText } from '../../../../../../../../services/textFunctions'

type Props = {
  treeSelectionGraph?: [number, number][]
  resultsFromBackend?: ResultsObject
  currentSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
  treeTableColumnsState?:
    | 'onlyAmount'
    | 'onlyInterest'
    | 'onlyExternal'
    | 'externalInterest'
    | undefined
  loadingResultsSettings?: boolean
  resultsViewParty?: 'client' | 'opposing'
}
export default function SelectionGraph(props: Props) {
  const [resultsSettings, setResultsSettings] =
    useRecoilState(resultsSettingsState)
  const freemium = useRecoilValue(freemiumState)
  const targetElementRef = useRef<HTMLDivElement | null>(null)
  const [fullGraphWidth, setFullGraphWidth] = useState(0)
  const windowSize = useWindowSize()
  const [graphScale, setGraphScale] = useState(1)
  const [firstTimeLoad, setFirstTimeLoad] = useState(true)
  const [sampleData, setSampleData] = useState<number[][]>([])
  const [smallGraph, setSmallGraph] = useState(false)
  const [negativeGraph, setNegativeGraph] = useState(false)
  const fullscreenActionMessage = useRecoilValue(fullscreenActionMessageState)
  const [showSelectionAreas, setShowSelectionAreas] = useState(true)
  const scenarioIdentity = useRecoilValue(scenarioIdentityState)

  useEffect(() => {
    // Accessing the target element and passing it to ResizableRectangle component
    const targetElement = document.querySelector(
      `[data-id="victoryArea-ID${props.pdfPreview ? '-preview' : ''}"]`,
    )

    if (targetElement) {
      targetElementRef.current = targetElement as HTMLDivElement
    }
    // eslint-disable-next-line
  }, [sampleData])

  useEffect(() => {
    if (
      resultsSettings.settings &&
      resultsSettings.settings.selectionGraphSettings &&
      resultsSettings.settings.selectionGraphSettings.showRanges !== undefined
    ) {
      setShowSelectionAreas(
        resultsSettings.settings.selectionGraphSettings.showRanges,
      )
    }
  }, [resultsSettings])

  // *** Declare the height of the table depending on the height of the div at the time of generation of the table
  const widthOfDiv = props.treeSelectionGraph
    ? 560
    : props.pdfPreview
    ? 1080
    : 1200
  const barHeight = (widthOfDiv * 4) / 10

  useEffect(() => {
    let tempSampleData = props.treeSelectionGraph
      ? props.treeSelectionGraph
      : props.resultsFromBackend!.result.graph
    if (tempSampleData[tempSampleData.length - 1][1] < 1) {
      tempSampleData[tempSampleData.length - 1][1] = 1.00000001
    }

    if (tempSampleData.length < graphLimit || props.treeSelectionGraph) {
      setSmallGraph(true)
      tempSampleData = [[tempSampleData[0][0], 0], ...tempSampleData]

      let tempSampleData2 = []
      for (let i = 1; i < tempSampleData.length - 1; i++) {
        tempSampleData2.push([tempSampleData[i + 1][0], tempSampleData[i][1]])
      }
      for (let i = 0; i < tempSampleData2.length; i++) {
        tempSampleData.splice(i + 2, 0, tempSampleData2[i])
      }
    }

    function Comparator(a: any, b: any) {
      if (a[1] === b[1]) {
        if (a[0] > b[0]) return -1
        if (a[0] < b[0]) return 1
      }
      if (a[1] < b[1]) return -1
      if (a[1] > b[1]) return 1
      return 0
    }

    tempSampleData.sort(Comparator)

    setSampleData(tempSampleData)
    setNegativeGraph(tempSampleData[0][0] <= 0)

    // eslint-disable-next-line
  }, [props.treeSelectionGraph, props.resultsFromBackend])

  const startingPosition = [0.2, 0.4]

  const [handlesXpercentages, setHandlesXpercentages] =
    useState<number[]>(startingPosition)

  useEffect(() => {
    if (props.loadingResultsSettings === false && sampleData.length > 0) {
      setFirstTimeLoad(false)
      if (
        props.resultsViewParty &&
        resultsSettings.settings.selectionGraphSettings &&
        resultsSettings.settings.selectionGraphSettings.position &&
        resultsSettings.settings.selectionGraphSettings.position.client
      ) {
        setHandlesXpercentages(
          resultsSettings.settings.selectionGraphSettings.position[
            props.resultsViewParty
          ],
        )
      }
    }
    // eslint-disable-next-line
  }, [
    props.loadingResultsSettings,
    resultsSettings,
    props.treeSelectionGraph,
    sampleData,
    props.resultsViewParty,
  ])

  useEffect(() => {
    if (
      targetElementRef.current &&
      targetElementRef.current.getBoundingClientRect()
    ) {
      let scale = 1
      if (!props.treeSelectionGraph && !props.pdfPreview) {
        if (windowSize.width < 1400 && windowSize.width > 850) {
          scale = 1450 / windowSize.width
        } else if (windowSize.width <= 850) {
          scale = 1.667
        }
        setGraphScale(scale)
      }
      setFullGraphWidth(
        targetElementRef.current.getBoundingClientRect().width * scale,
      )
    }
    // eslint-disable-next-line
  }, [targetElementRef.current, props.pdfPreview])

  useEffect(() => {
    if (!props.treeSelectionGraph && !props.pdfPreview) {
      let scale = 1
      if (windowSize.width < 1400 && windowSize.width > 850) {
        scale = 1450 / windowSize.width
      } else if (windowSize.width <= 850) {
        scale = 1.667
      }
      setGraphScale(scale)
    }
    // eslint-disable-next-line
  }, [windowSize])

  const user = useRecoilValue(userState)
  const graphLimit = 601

  const getAxisDomain = () => {
    var start = 0
    var end = 0

    if (sampleData[0][0] <= 0) {
      start = 0
      end = sampleData[sampleData.length - 1][0]
    } else if (sampleData[sampleData.length - 1][0] > 0) {
      start = sampleData[0][0]
      end = 0
    } else {
      start = sampleData[0][0]
      end = sampleData[sampleData.length - 1][0]
    }

    let tempDomain: DomainTuple = [end, start]
    return tempDomain
  }

  const calculateTickValues = () => {
    const tickDistance =
      sampleData[0][0] < 0
        ? sampleData[sampleData.length - 1][0]
        : sampleData[sampleData.length - 1][0] > 0
        ? sampleData[0][0]
        : sampleData[0][0] - sampleData[sampleData.length - 1][0]

    let start =
      sampleData[sampleData.length - 1][0] > 0 || sampleData[0][0] < 0
        ? 0
        : sampleData[sampleData.length - 1][0]
    let tickArray = []
    if (start < roundNumberTo(start + 0.0001, 5)) {
      tickArray.push(roundNumberTo(start + 0.001, 5))
    } else {
      tickArray.push(roundNumberTo(start, 5))
    }
    let tickNumber = props.treeSelectionGraph ? 5 : 10
    for (let i = 0; i < tickNumber - 1; i++) {
      start += tickDistance / tickNumber
      tickArray.push(roundNumberTo(start, 3))
    }

    if (sampleData[0][0] >= 0) {
      if (sampleData[0][0] < roundNumberTo(sampleData[0][0], 5)) {
        tickArray.push(floorNumberTo(sampleData[0][0] - 0.001, 5))
      } else {
        tickArray.push(roundNumberTo(sampleData[0][0], 5))
      }
    } else {
      if (
        sampleData[sampleData.length - 1][0] <
        roundNumberTo(sampleData[0][0], 5)
      ) {
        tickArray.push(
          roundNumberTo(sampleData[sampleData.length - 1][0] + 0.001, 5),
        )
      } else {
        tickArray.push(roundNumberTo(sampleData[sampleData.length - 1][0], 5))
      }
    }

    return tickArray
  }

  function factor() {
    let arrayOfValues = calculateTickValues()
    let maxLength = 0
    for (let i = 0; i < arrayOfValues.length; i++) {
      if (arrayOfValues[i].toString().length > maxLength) {
        maxLength = arrayOfValues[i].toString().length
      }
    }

    let factor = 70
    if (maxLength > 15) {
      factor = 120
    } else if (maxLength > 14) {
      factor = 110
    } else if (maxLength > 10) {
      factor = 100
    } else if (maxLength > 8) {
      factor = 90
    } else if (maxLength > 4) {
      factor = 80
    }
    return factor
  }

  function factorForTree() {
    let arrayOfValues = calculateTickValues()
    let maxLength = 0
    for (let i = 0; i < arrayOfValues.length; i++) {
      if (arrayOfValues[i].toString().length > maxLength) {
        maxLength = arrayOfValues[i].toString().length
      }
    }

    let factor = 50
    if (maxLength > 14) {
      factor = 100
    } else if (maxLength > 10) {
      factor = 85
    } else if (maxLength > 8) {
      factor = 70
    } else if (maxLength > 4) {
      factor = 60
    }

    return factor
  }

  return (
    <>
      <div
        className={props.treeSelectionGraph ? 'chart treeChart' : 'chart'}
        id={`selectionGraphContainer${props.pdfPreview ? '-preview' : ''}`}
        style={
          props.treeSelectionGraph || props.pdfPreview
            ? { width: '100%' }
            : windowSize.width < 1400
            ? windowSize.width > 850
              ? {
                  width: widthOfDiv,
                  transform: `scale(${windowSize.width / 1450})`,
                }
              : { width: widthOfDiv, transform: `scale(0.6)` }
            : { width: widthOfDiv }
        }
        data-openreplay-obscured
      >
        {sampleData.length > 0 && (
          <div
            className={`sub-chart ${
              negativeGraph && props.treeSelectionGraph
                ? 'tree-negativeGraph'
                : ''
            }`}
            id="selectionGraph-subChart"
            style={{ position: 'relative' }}
          >
            <VictoryChart
              domain={{
                y: getAxisDomain(),
              }}
              containerComponent={<VictoryContainer />}
              padding={{
                top: 60,
                left: props.treeSelectionGraph ? 80 : 120,
                bottom: 40,
                right: props.treeSelectionGraph ? 0 : 50,
              }}
              width={widthOfDiv}
              height={barHeight}
            >
              {sampleData.length > graphLimit ? (
                <VictoryArea
                  style={{
                    data: {
                      fill: ({ active }: any) =>
                        active ? '#d0d3e8' : ' #d0d3e8',
                      stroke: '#201a2d',
                      strokeWidth: 1,
                    },
                  }}
                  data={sampleData}
                  y={0}
                  x={1}
                  labels={sampleData.map(
                    (item) =>
                      `${stringAndRoundTo2Decimals(item[0], user.settings)} ${
                        props.currentSnapshot.currency
                      } , ${stringAndRoundTo2Decimals(
                        item[1] * 100,
                        user.settings,
                      )} %`,
                  )}
                  labelComponent={<VictoryTooltip active={false} />}
                  data-openreplay-hidden
                  data-id={`victoryArea-ID${
                    props.pdfPreview ? '-preview' : ''
                  }`}
                />
              ) : (
                <VictoryArea
                  style={{
                    data: {
                      fill: ({ active }: any) =>
                        active ? '#d0d3e8' : ' #d0d3e8',
                      stroke: '#201a2d',
                      strokeWidth: 1,
                    },
                  }}
                  data={sampleData}
                  y={0}
                  x={1}
                  labels={sampleData.map(
                    (item) =>
                      `${stringAndRoundTo2Decimals(item[0], user.settings)} ${
                        props.currentSnapshot.currency
                      } , ${stringAndRoundTo2Decimals(
                        item[1] * 100,
                        user.settings,
                      )} %`,
                  )}
                  labelComponent={
                    <VictoryTooltip
                      labelComponent={<VictoryTooltip active={false} />}
                      style={{
                        fill: '#d0d3e8',
                        fontSize: '22',
                      }}
                      flyoutStyle={{
                        stroke: ' #201a2d',
                        strokeWidth: 1,
                      }}
                    />
                  }
                  data-openreplay-hidden
                  data-id={`victoryArea-ID${
                    props.pdfPreview ? '-preview' : ''
                  }`}
                />
              )}
              <VictoryAxis
                tickCount={10}
                //tickFormat={(x) => `${x * 100} %`}
                tickFormat={(x: any) => `10%`}
                tickLabelComponent={
                  <VictoryLabel
                    dx={-widthOfDiv / 12 / 2}
                    dy={
                      sampleData[0][0] <= 0 ? widthOfDiv / 22 : -widthOfDiv / 30
                    }
                  />
                }
                style={{
                  axis: { stroke: '#756f6a' },
                  ticks: {
                    stroke: 'grey',
                    size: widthOfDiv / 40,
                    transform:
                      sampleData[0][0] <= 0
                        ? `translate(0, ${widthOfDiv / 40}px)`
                        : `translate(0, -${widthOfDiv / 80}px)`,
                  },
                  tickLabels: {
                    fontSize: widthOfDiv / 100,
                    fill:
                      getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ).color ?? 'grey',
                    fontFamily: 'roboto-local',
                  },
                  //grid: { stroke: 'grey', opacity: 0.3, height: '50px' },
                }}
              />
              <VictoryAxis
                tickValues={calculateTickValues()}
                // tickCount="10"
                style={{
                  axis: { stroke: '#756f6a' },
                  //axisLabel: { fontSize: 30, padding: 30 },
                  ticks: {
                    stroke: 'grey',
                    size: 5,
                  },
                  tickLabels: {
                    fontSize: props.treeSelectionGraph
                      ? widthOfDiv / factorForTree()
                      : widthOfDiv / factor(),
                    padding: 5,
                    fontFamily: 'roboto-local',
                    fill:
                      getStyleFromWhitelabel(
                        props.reportWhitelabel,
                        ReportWhitelabelKeys.sectionDescription,
                        FontLabels.regular,
                        'roboto',
                      ).color ?? 'black',
                  },
                }}
                dependentAxis
                tickFormat={(y) =>
                  `${stringAndRoundBigNumbers(
                    y,
                    user.settings,
                    resultsSettings.settings.roundedResults &&
                      !props.treeSelectionGraph,
                    findDisputedAmount(props.currentSnapshot),
                  )}${
                    props.pdfPreview &&
                    y !== calculateTickValues()[0] &&
                    y !== calculateTickValues().reverse()[0] &&
                    props.resultsFromBackend &&
                    props.resultsFromBackend.model_used === 'statistical'
                      ? '*'
                      : ''
                  } ${props.currentSnapshot.currency}`
                }
              />
            </VictoryChart>

            {(showSelectionAreas || props.treeSelectionGraph) &&
              resultsAreNotZero(sampleData) && (
                <SelectionArea
                  targetElement={targetElementRef.current}
                  graphData={sampleData}
                  statisticalModel={
                    props.resultsFromBackend! &&
                    props.resultsFromBackend.model_used === 'statistical'
                  }
                  fullGraphWidth={fullGraphWidth}
                  handlesXpercentages={handlesXpercentages}
                  setHandlesXpercentages={setHandlesXpercentages}
                  tree={props.treeSelectionGraph ? true : false}
                  negativeGraph={negativeGraph}
                  treeTableColumnsState={props.treeTableColumnsState}
                  graphScale={graphScale}
                  loadingResultsSettings={props.loadingResultsSettings}
                  firstTimeLoad={firstTimeLoad}
                  pdfPreview={props.pdfPreview}
                  doubleLimitValues={smallGraph}
                  resultsViewParty={props.resultsViewParty}
                  reportWhitelabel={props.reportWhitelabel}
                />
              )}
          </div>
        )}

        {!props.pdfPreview &&
          !props.treeSelectionGraph &&
          fullscreenActionMessage !== ActionMessage.downloadingImage &&
          sampleData.length > 2 && (
            <div
              className="selection-tabs marginLeft1"
              style={
                props.treeSelectionGraph
                  ? { zIndex: 1, position: 'relative' }
                  : undefined
              }
            >
              <div
                className="showSelectionAreasTickContainer"
                id="showSelectionAreasTickContainer"
                onClick={() => {
                  let tempResultsSettings = {
                    ...resultsSettings,
                    settings: {
                      ...resultsSettings.settings,
                      selectionGraphSettings: {
                        ...resultsSettings.settings.selectionGraphSettings,
                        showRanges: !showSelectionAreas,
                      },
                    },
                  }
                  setResultsSettings(tempResultsSettings)
                  if (!freemium.isFreemium) {
                    saveResultsSettings(
                      scenarioIdentity.caseId,
                      scenarioIdentity.scenarioId,
                      tempResultsSettings,
                    )
                  }
                  logActivity(
                    freemium.isFreemium,
                    'Clicked show selection ranges button',
                  )
                }}
              >
                {showSelectionAreas ? (
                  <img
                    src={tickedImg}
                    alt="tickedImg"
                    className="tickImg"
                    id={`tickedButton-showSelectionAreas`}
                  />
                ) : (
                  <img
                    src={unTickedImg}
                    alt="unTickedImg"
                    className="tickImg"
                    id={`untickedButton-showSelectionAreas`}
                  />
                )}
                <p
                  className="showSelectionAreasText"
                  id="showSelectionAreasText"
                  data-textattribute="button-161"
                >
                  {getText('button-161', user.settings)}
                </p>
              </div>
            </div>
          )}
      </div>
    </>
  )
}
