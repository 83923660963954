import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import { ResultsObject } from '../../../../../../../../models/generalTypes'
import { ScenarioSnapshot } from '../../../../../../../../models/scenarioSnapshot'
import {
  isDevEnv,
  isStagingEnv,
  isTestEnv,
} from '../../../../../../../../services/commonFunctions'
import { debuggingState } from '../../../../../../../../states/DebuggingState'
import { ReportWhitelabel } from '../../../../../../../../models/reportModels/reportGeneralTypes'
import GroupGraphComponent from './GroupGraphComponent'
import SpotGraphComponent from './SpotGraphComponent'

type Props = {
  resultsFromBackend: ResultsObject
  currentSnapshot: ScenarioSnapshot
  pdfPreview?: boolean
  reportWhitelabel?: ReportWhitelabel
}

export default function DistributionGraph(props: Props) {
  // *** Declare the height of the table depending on the height of the div at the time of generation of the table
  const debugging = useRecoilValue(debuggingState)

  const widthOfDiv = 600

  const heightOfDiv = (widthOfDiv * 4) / 10
  const segmentedData = props.resultsFromBackend.bellgraph.graph

  const [analyticalDistribution, setAnalyticalDistribution] = useState<
    number[][] | undefined
  >(undefined)
  const [analyticalDistributionDetailed, setAnalyticalDistributionDetailed] =
    useState<number[][][] | undefined>(undefined)

  const maxAmount = props.resultsFromBackend.bellgraph.max_amount
  const minAmount = props.resultsFromBackend.bellgraph.min_amount

  const stepSize = props.resultsFromBackend.bellgraph.step_size
  const maxProbability = props.resultsFromBackend.bellgraph.max_probability

  useEffect(() => {
    //Allow the analytical distribution
    if (props.resultsFromBackend.table.length <= 40) {
      let tempAnalyticalData: [number, number][] = []
      for (let outcome of props.resultsFromBackend.table) {
        let indexOfOutcomeInTempAnalytiacalData = tempAnalyticalData.findIndex(
          (sampleOutcome) => sampleOutcome[0] === outcome.financial_outcome,
        )
        if (indexOfOutcomeInTempAnalytiacalData !== -1) {
          tempAnalyticalData[indexOfOutcomeInTempAnalytiacalData][1] +=
            outcome.probability_of_outcome
        } else {
          tempAnalyticalData.push([
            outcome.financial_outcome,
            outcome.probability_of_outcome,
          ])
        }
      }

      let analyticalSegmentsLimitsArray = [minAmount]
      let analyticalSegments = 60
      const analyticalStep = (maxAmount - minAmount) / analyticalSegments
      const segmentsOfOutcomes: number[][] = [[]]
      const segmentsOfOutcomesDetailed: number[][][] = [[]]

      for (let i = 1; i < analyticalSegments; i++) {
        analyticalSegmentsLimitsArray.push(minAmount + i * analyticalStep)
        segmentsOfOutcomes.push([])
        segmentsOfOutcomesDetailed.push([])
      }
      analyticalSegmentsLimitsArray.push(maxAmount)
      if (!analyticalSegmentsLimitsArray.includes(0)) {
        analyticalSegmentsLimitsArray.push(0)
        analyticalSegmentsLimitsArray.sort((a, b) => a - b)
        segmentsOfOutcomes.push([])
        segmentsOfOutcomesDetailed.push([])
        analyticalSegments++
      }

      for (let outcome of tempAnalyticalData) {
        for (
          let index = 0;
          index < analyticalSegmentsLimitsArray.length - 1;
          index++
        ) {
          if (index === 0) {
            if (
              outcome[0] <= analyticalSegmentsLimitsArray[1] &&
              outcome[0] !== 0
            ) {
              segmentsOfOutcomesDetailed[index].push([outcome[0], outcome[1]])
              if (segmentsOfOutcomes[index][0] !== undefined) {
                segmentsOfOutcomes[index][1] += outcome[1]
              } else {
                segmentsOfOutcomes[index][0] = outcome[0]
                segmentsOfOutcomes[index][1] = outcome[1]
              }

              break
            }
          } else {
            if (
              outcome[0] > analyticalSegmentsLimitsArray[index] &&
              outcome[0] <= analyticalSegmentsLimitsArray[index + 1] &&
              outcome[0] !== 0
            ) {
              segmentsOfOutcomesDetailed[index].push([outcome[0], outcome[1]])
              if (segmentsOfOutcomes[index][0] !== undefined) {
                segmentsOfOutcomes[index][1] += outcome[1]
              } else {
                segmentsOfOutcomes[index][0] = outcome[0]
                segmentsOfOutcomes[index][1] = outcome[1]
              }
              break
            }
          }
        }
      }

      for (let outcome of tempAnalyticalData) {
        if (outcome[0] === 0) {
          segmentsOfOutcomes.push(outcome)
          segmentsOfOutcomesDetailed.push([[outcome[0], outcome[1]]])
        }
      }

      setAnalyticalDistribution(segmentsOfOutcomes)
      setAnalyticalDistributionDetailed(segmentsOfOutcomesDetailed)
      // let totalProbabilty = 0
      // for (let outcome of tempAnalyticalData) {
      //   totalProbabilty += outcome[1]
      // }
      // console.log('totalProbabilty')
      // console.log(totalProbabilty)
    }
    // eslint-disable-next-line
  }, [props.resultsFromBackend])

  return (
    <>
      <div className="chartBell" id="distributionGraph">
        <div className="sub-chart">
          {analyticalDistribution && analyticalDistributionDetailed ? (
            <SpotGraphComponent
              maxProbability={maxProbability}
              minAmount={minAmount}
              maxAmount={maxAmount}
              resultsFromBackend={props.resultsFromBackend}
              currentSnapshot={props.currentSnapshot}
              widthOfDiv={widthOfDiv}
              heightOfDiv={heightOfDiv}
              stepSize={stepSize}
              graphData={analyticalDistribution}
              tooltipData={analyticalDistributionDetailed}
              pdfPreview={props.pdfPreview}
              reportWhitelabel={props.reportWhitelabel}
            />
          ) : (
            segmentedData && (
              <GroupGraphComponent
                maxProbability={maxProbability}
                minAmount={minAmount}
                maxAmount={maxAmount}
                resultsFromBackend={props.resultsFromBackend}
                currentSnapshot={props.currentSnapshot}
                widthOfDiv={widthOfDiv}
                heightOfDiv={heightOfDiv}
                stepSize={stepSize}
                graphData={segmentedData}
                pdfPreview={props.pdfPreview}
                reportWhitelabel={props.reportWhitelabel}
              />
            )
          )}
        </div>
        {(isDevEnv() || isStagingEnv() || isTestEnv()) && debugging ? (
          <div className="probabilitySum">
            Probability of Outcomes sum ={' '}
            {Math.round(
              props.resultsFromBackend.bellgraph.total_probability * 100,
            )}{' '}
            %
          </div>
        ) : null}
      </div>
    </>
  )
}
